import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useParams } from 'react-router-dom'
import { API } from 'aws-amplify'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { CardContent, Divider, Card } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Tab from '@mui/material/Tab'
import { randomId } from '@mui/x-data-grid-generator'
import * as Sentry from '@sentry/react'
import AccountTitle from '../../components/AccountTitle'
import { useAuth } from '../../contexts/AuthContext'
import { useErrorToast } from '../../hooks/useErrorToast'
import ErrorFallback from '../ErrorFallback'
import Loader from '../Loader'
import CashConstraints from './CashConstraints'
import FactorTilts from './FactorTilts'
import Industry from './Industry'
import OtherConstraints from './OtherConstraints'
import PersonalizationSector from './PersonalizationSector'
import PersonalizationSubstitutionRestrictions from './PersonalizationSubstitutionRestrictions'
import SecurityRestrictions from './SecurityRestrictions'
import TaxConstraints from './TaxConstraints'
import { moduleConfig } from '../../contexts/data'
import { ACCESS_LEVEL } from '../../contstants/constants'

const personalizationTabs = ['Sector', 'Industry', 'Security', 'Factor Tilts', 'Geography', 'Substitutions', 'Cash Constraints', 'Tax Constraints', 'Other Constraints']

export default function Personalization () {
  const [value, setValue] = useState('0')
  const [sectorData, setSectorData] = useState([])
  const { checkAccess } = useAuth()
  const [industryData, setIndustryData] = useState([])
  const [securityData, setSecurityData] = useState([])
  const [factorTiltsData, setFactorTiltsData] = useState([])
  const [cashConstraintsData, setCashConstraintsData] = useState([])
  const [otherConstraintsData, setOtherConstraintsData] = useState([])
  const [objectiveFunctionData, setObjectiveFunctionData] = useState([])
  const [taxConstraintsData, setTaxConstraintsData] = useState([])
  const [substitutionData, setSubstitutionData] = useState([])
  const [allIndustryList, setAllIndustryList] = useState([])
  const [tempOtherConstraintsData, setTempOtherConstraintsData] = useState([])
  const screen = 'not main'
  const { showError } = useErrorToast()
  const { user } = useAuth()
  const params = useParams()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const dataSecure = localStorage.getItem('object')
  const getStoreData = dataSecure ? JSON.parse(dataSecure) : []
  const filterSecureData = getStoreData?.filter((item) => item?.accountId === params?.accountId)
  const strategyId = filterSecureData?.[0]?.strategyId ?? ''
  const isTradingAccount = filterSecureData && filterSecureData[0]?.accountType === 'TRADING'
  const [isLoading, setIsLoading] = useState(true)
  const [initialState, setIntialState] = useState(true)

  const getSectorsList = async () => {
    setIsLoading(true)
    API.get('baseUriAccountOptimization', `account-customization/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/${params.accountId}/sector-restrictions`, {
      queryStringParameters: {}
    })
      ?.then((response) => {
        if (response?.data) {
          if (response?.data) {
            if (response?.data?.data) {
              response?.data?.data?.forEach(element => {
                element.id = randomId()
              })
            }
            setSectorData(response.data)
            setIsLoading(false)
          }
        }
      })
      .catch((error) => {
        setIsLoading(false)
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const handleSubstitutionData = (data) => {
    setSubstitutionData([...data])
  }

  const getSubstitutionList = async () => {
    API.get('baseUriAccountOptimization', `account-customization/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/${params.accountId}/instr-substitution`)
      ?.then((response) => {
        if (response?.data) {
          if (response?.data?.data) {
            response?.data?.data?.forEach(element => {
              element.id = randomId()
            })
          }
          setSubstitutionData(response?.data?.data)
        }
      })
  }

  const handleSecurityData = (data) => {
    setSecurityData([...data])
  }

  const getFactorUtils = async () => {
    setIsLoading(true)
    API.get('baseUriAccountOptimization', `account-customization/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/factor-tilts/${params.accountId}`)
      ?.then((response) => {
        if (response?.data) {
          if (response?.data?.data?.length) {
            response.data?.data?.forEach(element => {
              element.id = randomId()
            })
          }
          setFactorTiltsData(response.data)
        }
      })
      .catch((error) => {
        setIsLoading(false)
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const restrictedSecurityList = async () => {
    API.get('baseUriAccountOptimization', `account-customization/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/${params.accountId}/instr-restrictions`)
      ?.then((response) => {
        if (response?.data) {
          if (response?.data?.data) {
            response?.data?.data?.forEach(element => {
              element.id = randomId()
            })
          }
          setSecurityData(response?.data?.data)
        }
      })
  }

  const getIndustryRestrictions = async () => {
    setIsLoading(true)
    API.get('baseUriAccountOptimization', `account-customization/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/${params.accountId}/industry-restrictions`)
      ?.then((response) => {
        if (response.data) {
          if (response.data) {
            if (response.data?.data) {
              response.data?.data?.forEach(element => {
                element.id = randomId()
              })
              setIndustryData(response.data)
            }
            setIsLoading(false)
          }
        }
      })
      .catch((error) => {
        setIsLoading(false)
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  useEffect(() => {
    if (user) {
      if (
        checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS,
          { subModuleName: moduleConfig.PERSONALIZATION, component_name: moduleConfig.VIEW_PERSONALIZATION })) {
        getSectorsList()
        getFactorUtils()
        restrictedSecurityList()
        getConstraints()
        getTaxConstraints()
        getSubstitutionList()
        getIndustryRestrictions()
        getSectorIndustry()
      } else {
        setIsLoading(false)
      }
    }
  }, [user, params?.accountId])

  useEffect(() => {
    if (sectorData?.data && allIndustryList?.length) {
      if (initialState) {
        const checkedSectors = sectorData?.data.filter((sector) => sector.sectorExclude === 1).map((sector) => { return sector.assetClassDesc })
        const industry = allIndustryList.map((industry) => {
          if (checkedSectors?.length && checkedSectors.includes(industry.sectorName)) {
            return { ...industry, exclude: true, disabled: true }
          } else if (industry.disabled) {
            return { ...industry, exclude: false, disabled: false }
          } else {
            return { ...industry }
          }
        })
        setAllIndustryList(industry)
        setIntialState(false)
      }
    }
  }, [sectorData, allIndustryList])

  const sortData = (data) => {
    return (data || [])
      .slice()
      .sort((a, b) => {
        const customOrder = [
          'Minimum Beta',
          'Maximum Beta',
          'Min Buy Size',
          'Min Buy Weight',
          'Min Trade Weight',
          'Max Holdings'
        ]
        const minIndex = customOrder.indexOf(a?.propertyDesc)
        const maxIndex = customOrder.indexOf(b?.propertyDesc)
        return minIndex - maxIndex
      })
  }

  const getConstraints = async () => {
    setIsLoading(true)
    API.get('baseUriAccountOptimization', `account-customization/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/cash-and-other-constraints/${params.accountId}`)
      ?.then((response) => {
        if (response?.data) {
          if (response?.data?.data?.length) {
            response?.data?.data?.forEach(element => {
              element.id = randomId()
              // if account is trading account, disable action for withdrawal constraint
              if (isTradingAccount && element.propertyType === 'CASH_CONSTRAINT' && element.propertyCode === 'CASH_RAISE') {
                element.disableAction = true
                element.reason = 'Constraint was set at the time of account onboarding for cash withdrawal go to request and raise cash withdrawal request'
              }
            })
            setCashConstraintsData({ ...response.data, data: response.data?.data?.filter(elem => elem.propertyType === 'CASH_CONSTRAINT') })

            const tempOtherConstraintData = sortData(response?.data?.data?.filter(elem =>
              elem.propertyType === 'OTHER_CONSTRAINT' &&
              elem.propertyCode !== 'FIXED_TXN_COST_LAMBDA' &&
              elem.propertyCode !== 'RISK_LAMBDA' &&
              elem.propertyCode !== 'TAX_LAMBDA' &&
              elem.propertyCode !== 'TXN_COST_LAMBDA'
            ))

            setOtherConstraintsData({
              ...response.data,
              data: tempOtherConstraintData
            })

            setObjectiveFunctionData({
              ...response.data,
              data: response?.data?.data?.filter(elem =>
                elem.propertyType === 'OTHER_CONSTRAINT' &&
                (elem.propertyCode === 'FIXED_TXN_COST_LAMBDA' ||
                elem.propertyCode === 'RISK_LAMBDA' ||
                elem.propertyCode === 'TAX_LAMBDA' ||
                elem.propertyCode === 'TXN_COST_LAMBDA')
              )
            })
          }
        }
      })
      .catch((error) => {
        setIsLoading(false)
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const getTaxConstraints = async () => {
    setIsLoading(true)
    API.get('baseUriAccountOptimization', `account-customization/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/tax-constraints/${params.accountId}`)
      ?.then((response) => {
        if (response?.data) {
          if (response?.data?.data?.length) {
            const taxData = []
            Object.entries(response.data?.data[0]).forEach(element => {
              taxData.push({ id: randomId(), propertyCode: element[0], description: response.data?.fieldLabels?.[`${element[0]}`], value: element[1] })
            })
            setTaxConstraintsData({ ...response.data, data: taxData.slice(1) })
          }
        }
      })
      .catch((error) => {
        setIsLoading(false)
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const getSectorIndustry = async () => {
    setIsLoading(true)
    API.get('baseStrategyURL', `strategy/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/sector-industry/${strategyId}`, {
      queryStringParameters: {}
    })
      ?.then((response) => {
        if (response?.data) {
          if (response.data?.sectorDataInd) {
            let industryList = response?.data?.sectorDataInd?.map((item, index) => {
              return {
                assetClass: item?.assetClass,
                sectorName: item?.assetClassDesc,
                industry: item?.subIndName,
                sumWeight: (item?.sumWeight * 100).toFixed(2),
                count: item?.count,
                exclude: false,
                min: '',
                max: ''
              }
            })
            industryList = industryList.filter(item => (item.industry !== 'N/A' && item.assetClass !== 'ALL'))
            setAllIndustryList(industryList)
            // if (sectorData) {
            //   const checkedSectors = sectorData.data.filter((sector) => sector.sectorExclude === 1).map((sector) => { return sector.assetClassDesc })
            //   const industry = industryList.map((industry) => {
            //     if (checkedSectors.length > 0 && checkedSectors.includes(industry.sectorName)) {
            //       return { ...industry, exclude: true, disabled: true }
            //     } else if (industry.disabled) {
            //       return { ...industry, exclude: false, disabled: false }
            //     } else {
            //       return { ...industry }
            //     }
            //   })
            //   setAllIndustryList(industry)
            // }
            setIsLoading(false)
          }
        }
      })
      .catch((error) => {
        setIsLoading(false)
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const updateIndustryList = (sectorPayload, action) => {
    if (action === 'update' && sectorPayload.sectorExclude) {
      const sectorIndustry = allIndustryList.filter((industry) => industry.assetClass === sectorPayload.sectorId)
      const industryPayload = sectorIndustry.map((industry) => {
        return {
          sectorId: industry.assetClass,
          industryId: industry.industry,
          industryExclude: true
        }
      })
      if (industryPayload?.length) {
        API.post('baseUriAccountOptimization', `account-customization/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/${params.accountId}/industry-restrictions`, {
          body: [
            ...industryPayload
          ]
        })
          .then((response) => {
            if (response.success) {
              setAllIndustryList(prevData => prevData.map((industry) => {
                if (industry.assetClass === sectorPayload.sectorId) {
                  return { ...industry, exclude: true, disabled: true }
                } else {
                  return { ...industry }
                }
              }))
              getIndustryRestrictions()
              setIsLoading(false)
            }
          })
          .catch((error) => {
            setIsLoading(false)
            showError(error.response?.data?.errorInfo?.userMessage || error.message)
            Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
          })
      }
    } else if (action === 'disable') {
      const sectorIndustry = (industryData?.data || []).filter((industry) => industry.assetClassCd === sectorPayload.sectorId)
      if (sectorIndustry) {
        const industryPayload = sectorIndustry?.map((industry) => industry?.accountIndRestrId)
        if (industryPayload?.length) {
          API.del('baseUriAccountOptimization', `account-customization/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/${params.accountId}/industry-restrictions`, {
            body: {
              accountIndustryRestrictionId: [...industryPayload]
            }
          })
            .then((response) => {
              if (response.success) {
                setAllIndustryList(prevData => prevData.map((industry) => {
                  if (industry.assetClass === sectorPayload.sectorId) {
                    return { ...industry, exclude: false, disabled: false }
                  } else {
                    return { ...industry }
                  }
                }))
                setIndustryData(prevData => ({ ...prevData, data: prevData?.data?.filter((row) => !industryPayload.includes(row.accountIndRestrId)) }))
                setIsLoading(false)
              }
            })
            .catch((error) => {
              setIsLoading(false)
              showError(error.response?.data?.errorInfo?.userMessage || error.message)
              Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
            })
        }
      }
    } else {
      const industry = allIndustryList.map((industry) => {
        if (industry.assetClass === sectorPayload.sectorId && sectorPayload.sectorExclude) {
          return { ...industry, exclude: true, disabled: true }
        } else if (industry.disabled && !sectorPayload.sectorExclude) {
          return { ...industry, exclude: false, disabled: false }
        } else {
          return { ...industry }
        }
      })
      setAllIndustryList(industry)
    }
  }

  const renderTabPanel = (item) => {
    switch (item) {
      case 'Sector':
        return (
          <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
            <PersonalizationSector data={sectorData} getUpdatedData={(data) => setSectorData(prevData => ({ ...prevData, data }))} updateIndustryList={updateIndustryList} />
          </ErrorBoundary>
        )
      case 'Industry':
        return (
          <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
            <Industry data={industryData} getUpdatedData={(data) => setIndustryData(prevData => ({ ...prevData, data }))} addIndustryData={getIndustryRestrictions} allIndustries={allIndustryList} />
          </ErrorBoundary>
        )
      case 'Security':
        return (
          <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
            <SecurityRestrictions securityData={securityData} onData={handleSecurityData} />
          </ErrorBoundary>
        )
      case 'Factor Tilts':
        return (
          <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
            <FactorTilts data={factorTiltsData} getUpdatedData={(data) => setFactorTiltsData(prevData => ({ ...prevData, data }))} />
          </ErrorBoundary>
        )
      case 'Substitutions':
        return (
          <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
            <PersonalizationSubstitutionRestrictions onData={handleSubstitutionData} substitutionData={substitutionData} />
          </ErrorBoundary>
        )
      case 'Cash Constraints':
        return (
          <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
            <CashConstraints data={cashConstraintsData} getUpdatedData={(data) => setCashConstraintsData(prevData => ({ ...prevData, data }))} />
          </ErrorBoundary>
        )
      case 'Tax Constraints':
        return (
          <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
            <TaxConstraints data={taxConstraintsData} getUpdatedData={(data) => setTaxConstraintsData(prevData => ({ ...prevData, data }))} />
          </ErrorBoundary>
        )
      case 'Geography':
        return (
          <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
            <h6>Geography</h6>
          </ErrorBoundary>
        )
      case 'Other Constraints':
        return (
          <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
            <OtherConstraints
              data={otherConstraintsData} objectiveData={objectiveFunctionData}
              getUpdatedData={(data) => setOtherConstraintsData(prevData => ({ ...prevData, data }))}
              getUpdatedObjectiveData={(data) => setObjectiveFunctionData(prevData => ({ ...prevData, data }))}
            />
          </ErrorBoundary>
        )
      default:
        return 'Something went wrong'
    }
  }

  return (
    <Grid container>
      <Grid xs={12} sm={12} item className='account-dashboard'>
        <AccountTitle title='Personalization' />
      </Grid>
      {(isLoading && initialState)
        ? <Loader />
        : <Grid xs={12} sm={12} item>
          <Box
            className='reportPage' sx={{
              flexGrow: 1,
              maxWidth: { xs: '100%' },
              bgcolor: 'white',
              paddingTop: '0px'
            }}
          >
            <Card className='card-layout'>
              <CardContent>
                <TabContext value={value}>
                  <TabList
                    onChange={handleChange}
                    variant='scrollable'
                    scrollButtons={false}
                    className='report-tab-header'
                    id='personalization-tabs'
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: '#34475A'
                      }
                    }}
                    indicatorColor='#34475A'
                  >
                    {personalizationTabs.map((item, index) => (
                      <Tab
                        className='reportCardNav' key={index} value={index.toString()} label={item} disabled={item === 'Geography'} sx={{
                          opacity: item === 'Geography' ? 0.5 : 1
                        }}
                      />
                    ))}
                  </TabList>
                  <Divider sx={{
                    bgcolor: 'rgba(116, 120, 141, 0.15)'
                  }}
                  />
                  {personalizationTabs.map((item, index) => (
                    <TabPanel
                      key={index}
                      value={index.toString()}
                    >
                      {renderTabPanel(item)}
                    </TabPanel>
                  ))}
                </TabContext>
              </CardContent>
            </Card>
          </Box>
          </Grid>}
    </Grid>
  )
}
