import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import * as Sentry from '@sentry/react'
import { Add, ArrowDownward, ArrowUpward, Close, Remove } from '@mui/icons-material'
import { TabContext, TabPanel } from '@mui/lab'
import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, Menu, MenuItem, Radio, RadioGroup, Skeleton, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Tooltip, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { DataGridPro, gridClasses, gridFilteredDescendantCountLookupSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro'
import { useAuth } from '../../../contexts/AuthContext'
import { moduleConfig } from '../../../contexts/data'
import { ACCESS_LEVEL } from '../../../contstants/constants'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { formatCurrency } from '../../Report/components/dataProcess/DataProcess'
import StyledTradePopupBox from '../components/StyledTradePopupBox'
import TradeFeasibilityReportTab from './TradeFeasibilityReportTab'

const CustomHeader = ({ title, text }) => {
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Tooltip title={title} placement='bottom'>
          <span style={{ lineHeight: '30px' }}>{title}</span>
        </Tooltip>
        <span style={{ lineHeight: '16px', minHeight: '20px', fontSize: '12px' }}>{text}</span>
      </Box>
    </>
  )
}

const ViewTradeDetails = ({ selectedItem, index, setIsShown, accountCode, optId }) => {
  const { user, checkAccess } = useAuth()
  const [value, setValue] = useState('Trade')
  const [optmRunSumPro, setOptmRunSPro] = useState()
  const [positionData, setPositionData] = useState()
  const [optPropAssetData, setOptPropAssetData] = useState()
  const [optMapId] = useState({ selectedItem, index })
  const [currentPositionValue, setCurrentPositionValue] = useState('company')
  const [loading, setLoading] = useState(true)
  const [positionDataLoading, setPositionDataLoading] = useState(true)
  const [otherPropertiesLoading, setOtherPropertiesLoading] = useState(true)
  const [optPropAssetDataLoading, setOptPropAssetDataLoading] = useState(true)
  const [sectorData, setSectorData] = useState()
  const [tradeBlotterData, setTradeBlotterData] = useState([])
  const [optData, setOptData] = useState([])
  const [tradeDataLoading, setTradeDataLoading] = useState(true)
  const [industryData, setIndustryData] = useState()
  const [subIndustryData, setSubIndustryData] = useState()
  const [companyWiseData, setCompanyWiseData] = useState()
  const { showError } = useErrorToast()
  const tradeDetailsTab = ['Trade', 'Taxlot', 'Position', 'Blotter', 'Feasibility Report', 'Model Variance']
  const [securityLevelTradeData, setSecurityLevelTradeData] = useState()
  const [liveCash, setLiveCash] = useState(0)
  const [liveCashInPercent, setLiveCashInPercent] = useState(null)
  const [selectedTrdCode, setSelectedTrdCode] = useState({ buy: true, sell: true })
  const [exportCSVAnchorEl, setExportCSVAnchorEl] = useState(null)
  const [optFeasibilityData, setOptFeasibilityData] = useState(null)
  const [optFeasibilityLoading, setOptFeasibilityLoading] = useState(true)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    if (user) {
      getoptimSummaryPro(optMapId)
      getPositionData(optMapId)
      getOptPropAssetDriftData(optMapId)
      getOptimizationPersonalization(optId)
      getTradeBlotterData()
      getOptFeasibilityReport(optMapId)
    }
  }, [user])

  useEffect(() => {
    setSecurityLevelTradeData(positionData ? positionData?.filter(row => selectedTrdCode[row?.trdCode?.toLowerCase()]) : [])
  }, [selectedTrdCode])

  const getPositionData = (optMapId) => {
    const accMapId = optMapId.index

    API.post(
      'baseOptimizationURL',
      `optimization/v1/${user?.userGroup}/opt-prop-drift/${accMapId}`,
      { body: { accOptMapId: [accMapId] } }
    )
      .then((response) => {
        setPositionData(response?.data ? response?.data.map((row) => ({ ...row, id: randomId() })) : [])
        setSecurityLevelTradeData(response?.data ? response?.data?.filter(row => row.trdCode !== 'NOP').map((row) => ({ ...row, id: randomId() })) : [])
        setPositionDataLoading(false)
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const getOptimizationPersonalization = (optId) => {
    if (checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.MODEL_VARIANCE })) {
      // const accMapId = optMapId.index
      API.get('baseOptimizationURL', `optimization/v1/${user?.userGroup}/personalization/${optId}?source=MODEL_VARIANCE`)
        .then((response) => {
          if (response && response?.data) {
            setOptData(response?.data && response?.data?.length ? response?.data[0]?.value?.map((row) => ({ ...row, id: randomId() })) : [])
            setOtherPropertiesLoading(false)
          }
        })
        .catch((error) => {
          setOtherPropertiesLoading(false)
          showError(error.response?.data?.errorInfo?.userMessage || error.message)
          Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
        })
    } else {
      setOtherPropertiesLoading(false)
    }
  }

  const getoptimSummaryPro = (optMapId) => {
    if (checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.TRADE_OPT_TRADE_DETAILS })) {
      const accMapId = optMapId.index
      API.post(
        'baseOptimizationURL',
        `optimization/v1/${user?.userGroup}/opt-prop-details`,
        { body: { accOptMapId: [accMapId] } }
      )
        .then((response) => {
          setOptmRunSPro(response?.data?.data ? response?.data?.data?.map((row) => ({ ...row, id: randomId() })) : [])
          setLoading(false)
        })
        .catch((error) => {
          showError(error.response?.data?.errorInfo?.userMessage || error.message)
          Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
        })
    }
  }

  const getOptPropAssetDriftData = (optMapId) => {
    const accMapId = optMapId.index

    API.post(
      'baseOptimizationURL',
      `optimization/v1/${user?.userGroup}/opt-prop-asset-drift/${accMapId}`,
      { body: { accOptMapId: [accMapId] } }
    )
      .then((response) => {
        setOptPropAssetData(response?.data ? response?.data.map((row) => ({ ...row, id: randomId() })) : [])
        setOptPropAssetDataLoading(false)
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const getTradeBlotterData = () => {
    API.get('baseUriTrade', `trade/v1/${user?.userGroup}/trade-blotter/accounts/${optMapId?.index}`).then((res) => {
      setLiveCash(res?.data?.liveCashInPortfolio?.liveCashInPortfolio || null)
      setLiveCashInPercent(res?.data?.liveCashInPortfolio?.liveCashInPortfolioInPercentage || null)
      setTradeBlotterData(res?.data ? res?.data?.blotterData?.map((row) => ({ ...row, id: randomId() })) : [])
      setTradeDataLoading(false)
    }).catch((error) => {
      setTradeDataLoading(false)
      showError(error.response?.data?.errorInfo?.userMessage || error.message)
      Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
    })
  }

  const getOptFeasibilityReport = async (optMapId) => {
    if (checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.FEASIBILITY_REPORT })) {
      try {
        const response = await API.post('baseOptimizationURL', `optimization/v1/${user?.userGroup}/opt-feasibility-report/`, { body: { accOptMapId: [optMapId?.index] } })
        if (response && response?.data?.length) {
          setOptFeasibilityData(response?.data[0]?.report)
        }
        setOptFeasibilityLoading(false)
      } catch (error) {
        setOptFeasibilityLoading(false)
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      }
    }
  }

  const getData = (tableName) => {
    const parentRow = {}
    optPropAssetData?.forEach(item => {
      const currItem = item[tableName]
      if (parentRow?.hasOwnProperty(currItem)) {
        parentRow[currItem].tradeDrift += (item.propDrift - item.initDrift)
        parentRow[currItem].modelWeight += item.modelWeight
        parentRow[currItem].initWeight += item.initWeight
        parentRow[currItem].propDrift += item.propDrift
        parentRow[currItem].initDrift += item.initDrift
        parentRow[currItem].propWeight += item.propWeight
      } else {
        const hierarchy = [item[tableName]]
        const modelWeight = item.modelWeight
        const initWeight = item.initWeight
        const propDrift = item.propDrift
        const initDrift = item.initDrift
        const propWeight = item.propWeight
        const tradeDrift = (item.propDrift - item.initDrift)
        parentRow[currItem] = { hierarchy, initWeight, propDrift, initDrift, propWeight, modelWeight, tradeDrift }
      }
    })

    const childRow = {}
    optPropAssetData?.forEach(item => {
      if (childRow.hasOwnProperty(item[tableName])) {
        // if this field is available then add up all the values
        let isCurrentObjectAdded = 0
        if (tableName !== 'companyName') {
          childRow[item[tableName]].forEach(obj => {
            // but add up fields only if companyName matches
            // e.g: one sector can have one company multiple times
            if (obj.companyName === item.companyName) {
              obj.modelWeight += item.modelWeight
              obj.initWeight += item.initWeight
              obj.propWeight += item.propWeight
              obj.initDrift += item.initDrift
              obj.propDrift += item.propDrift
              obj.tradeDrift += (item.propDrift - item.initDrift)
              isCurrentObjectAdded = 1
            }
          })
        } else {
          // search item.instrId in childRow's item.companyName array
          childRow[item[tableName]].forEach(obj => {
            // one company can have one or more instrument Id
            if (obj.instrId === item.instrId) {
              obj.modelWeight += item.modelWeight
              obj.initWeight += item.initWeight
              obj.propWeight += item.propWeight
              obj.initDrift += item.initDrift
              obj.propDrift += item.propDrift
              obj.tradeDrift += (item.propDrift - item.initDrift)
              isCurrentObjectAdded = 1
            }
          })
        }
        if (isCurrentObjectAdded === 0) {
          // if field matches but companyName doesn't match (flag != 1) then it's a new company
          // add that into item[tableName]
          childRow[item[tableName]].push({
            instrId: item.instrId,
            hierarchy: tableName === 'companyName' ? [item.companyName, item.instrId] : [item[tableName], item.companyName],
            companyName: item.companyName,
            modelWeight: item.modelWeight,
            initWeight: item.initWeight,
            propWeight: item.propWeight,
            initDrift: item.initDrift,
            propDrift: item.propDrift,
            tradeDrift: item.propDrift - item.initDrift
          })
        }
      } else {
        // if this field is not available then add that into array of object
        const temp = {
          instrId: item.instrId,
          hierarchy: [item[tableName], tableName === 'companyName' ? item.instrId : item.companyName],
          companyName: item.companyName,
          modelWeight: item.modelWeight,
          initWeight: item.initWeight,
          propWeight: item.propWeight,
          initDrift: item.initDrift,
          propDrift: item.propDrift,
          tradeDrift: item.propDrift - item.initDrift
        }
        childRow[item[tableName]] = [temp]
      }
    })

    let finalArray = []
    for (const key in parentRow) {
      finalArray.push(parentRow[key])
      finalArray = finalArray.concat(childRow[key])
    }

    finalArray = finalArray.map(obj => {
      return {
        ...obj,
        id: randomId(),
        modelWeight: obj.modelWeight?.toFixed(7),
        initWeight: obj.initWeight?.toFixed(7),
        tradeDrift: obj.tradeDrift?.toFixed(7),
        initDrift: obj.initDrift.toFixed(7),
        propDrift: obj.propDrift.toFixed(7),
        propWeight: obj.propWeight.toFixed(7)
      }
    })
    return finalArray
  }

  useEffect(() => {
    setSectorData(getData('sector'))
    setIndustryData(getData('industry'))
    setSubIndustryData(getData('subIndustry'))
    setCompanyWiseData(getData('companyName'))
  }, [optPropAssetData])

  const tableHeaders = [
    { field: 'instrId', headerName: 'Instrument Id' },
    { field: 'localSymbol', headerName: 'Local Symbol' },
    {
      field: 'tradeTags',
      headerName: 'Trade Reason',
      width: 300,
      valueGetter: (params) => {
        const tradeTags = params?.value
        if (Array.isArray(tradeTags) && tradeTags.length > 0) {
          return tradeTags?.map(tag => tag.reasonCd).join(', ')
        }
        return 'N/A'
      },
      renderCell: (params) => {
        const tradeTags = params?.row?.tradeTags
        if (tradeTags && tradeTags?.length > 0) {
          const reasonCodes = tradeTags?.map(tag => tag.reasonCd)?.join(', ')
          const reasonLabels = tradeTags?.map(tag => tag.reasonLabel)?.join(', ')

          return (
            <Tooltip title={reasonLabels}>
              <span>{reasonCodes}</span>
            </Tooltip>
          )
        }
        return <span>N/A</span>
      }
    },
    {
      field: 'trdCode',
      headerName: 'Trade Code',
      renderCell: (params) => (<>
        <span style={{ color: params.value === 'SELL' ? '#FF6161' : params.value === 'BUY' ? '#3BBFA3' : '#34475A' }}>{params.value}</span>
      </>)
    },
    { field: 'trdQty', headerName: 'Trade Quantity', type: 'number', valueFormatter: (params) => params?.value?.toFixed(2), align: 'right' },
    { field: 'initShares', headerName: 'Initial Shares', type: 'number', align: 'right', headerAlign: 'right' },
    { field: 'initWeight', headerName: 'Initial Weight', type: 'number', align: 'right', headerAlign: 'right' },
    {
      field: 'initMarketValue',
      headerName: 'Initial Market Value',
      type: 'number',
      valueGetter: (params) => params?.value?.toFixed(2),
      renderCell: (params) => !isNaN(params?.row?.initMarketValue)
        ? params?.row?.initMarketValue < 0
          ? `-$${formatCurrency(Math.abs(params?.row?.initMarketValue), 2)}`
          : `$${formatCurrency(params?.row?.initMarketValue, 2)}`
        : 'N/A',
      align: 'right'
    },
    { field: 'propShares', headerName: 'Proposed Shares', type: 'number', align: 'right', headerAlign: 'right' },
    { field: 'propWeight', headerName: 'Proposed Weight', type: 'number', align: 'right', headerAlign: 'right' },
    {
      field: 'propMarketValue',
      headerName: 'Proposed Market Value',
      type: 'number',
      valueGetter: (params) => params?.value?.toFixed(2),
      renderCell: (params) => !isNaN(params?.row?.propMarketValue)
        ? params?.row?.propMarketValue < 0
          ? `-$${formatCurrency(Math.abs(params?.row?.propMarketValue), 2)}`
          : `$${formatCurrency(params?.row?.propMarketValue, 2)}`
        : 'N/A',
      align: 'right',
      headerAlign: 'right'
    },
    { field: 'age', headerName: 'Age', align: 'right', headerAlign: 'right', type: 'number' },
    { field: 'costBasis', headerName: 'Cost Basis', type: 'number', align: 'right', headerAlign: 'right' },
    { field: 'purchaseDate', headerName: 'Purchase Date', valueFormatter: (params) => params?.value ? params?.value?.split('T')[0] : '' },
    { field: 'orgPurchasePrice', headerName: 'Original Purchase Price', type: 'number', valueFormatter: (params) => params?.value?.toFixed(2), align: 'right', headerAlign: 'right' },
    {
      field: 'lastClosePrice',
      headerName: 'Last Closed Price',
      type: 'number',
      valueGetter: (params) => parseFloat(params?.value?.toFixed(2)),
      renderCell: (params) => {
        const cellStyle = {
          color: params?.row?.priceSource === 'last close price' ? 'rgba(0, 0, 0, 0.4)' : 'inherit',
          textAlign: 'right'
        }

        return (
          <div style={cellStyle}>
            {!isNaN(params?.row?.lastClosePrice) && params?.row?.lastClosePrice !== undefined && params?.row?.lastClosePrice !== null
              ? params?.row?.lastClosePrice < 0
                ? `-$${formatCurrency(Math.abs(params?.row?.lastClosePrice, 2))}`
                : `$${formatCurrency(params?.row?.lastClosePrice, 2)}`
              : 'N/A'}
          </div>
        )
      },
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'initUrgl',
      headerName: 'Initial Unrealized gain-loss',
      type: 'number',
      valueGetter: (params) => params?.value?.toFixed(2),
      renderCell: (params) =>
        !isNaN(params?.row?.initUrgl)
          ? params?.row?.initUrgl < 0
            ? `-$${formatCurrency(Math.abs(params?.row?.initUrgl), 2)}`
            : `$${formatCurrency(params?.row?.initUrgl, 2)}`
          : 'N/A',
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'liveMarketPrice',
      headerName: 'Live Price',
      type: 'number',
      valueGetter: (params) => parseFloat(params?.value?.toFixed(2)),
      renderCell: (params) =>
        !isNaN(params?.row?.liveMarketPrice) && params?.row?.liveMarketPrice !== undefined && params?.row?.liveMarketPrice !== null
          ? params?.row?.liveMarketPrice < 0
            ? `-$${formatCurrency(Math.abs(params?.row?.liveMarketPrice, 2))}`
            : `$${formatCurrency(params?.row?.liveMarketPrice, 2)}`
          : 'N/A',
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'rgl',
      headerName: 'Realized gain-loss',
      type: 'number',
      valueGetter: (params) => params?.value?.toFixed(2),
      renderCell: (params) => !isNaN(params?.row?.rgl)
        ? params?.row?.rgl < 0
          ? `-$${formatCurrency(Math.abs(params?.row?.rgl), 2)}`
          : `$${formatCurrency(params?.row?.rgl, 2)}`
        : 'N/A',
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'rglLive',
      headerName: 'RGL (Live)',
      type: 'number',
      valueGetter: (params) => parseFloat(params?.value?.toFixed(2)),
      renderCell: (params) => {
        const cellStyle = {
          color: params?.row?.priceSource === 'last close price' ? 'rgba(0, 0, 0, 0.4)' : 'inherit',
          textAlign: 'right'
        }

        return (
          <div style={cellStyle}>
            {
              !isNaN(params?.row?.rglLive) && params?.row?.rglLive !== undefined && params?.row?.rglLive !== null
                ? params?.row?.rglLive < 0
                  ? `-$${formatCurrency(Math.abs(params?.row?.rglLive), 2)}`
                  : `$${formatCurrency(params?.row?.rglLive, 2)}`
                : 'N/A'
            }
          </div>
        )
      },
      align: 'right',
      headerAlign: 'right'
    },
    { field: 'trdDate', headerName: 'Trade Date', valueFormatter: (params) => params?.value ? params?.value?.split('T')[0] : '' }
  ]

  const positionSecurityTableHeader = [
    { field: 'instr_id', headerName: 'Instrument Id', flex: 1, renderHeader: (params) => <CustomHeader title='Instrument Id' /> },
    {
      field: 'trdCode',
      headerName: 'Trade Code',
      flex: 1,
      renderHeader: (params) => <CustomHeader title='Trade Code' />,
      renderCell: (params) => (<>
        <span style={{ color: params.value === 'SELL' ? '#FF6161' : params.value === 'BUY' ? '#3BBFA3' : '#34475A' }}>{params.value}</span>
      </>)
    },
    {
      field: 'modelWeight',
      headerName: 'Model Weight (%)',
      type: 'number',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => (params.value * 100).toFixed(2),
      renderCell: (params) => (params?.row?.modelWeight * 100).toFixed(2),
      renderHeader: (params) => <CustomHeader title='Model Weight (%)' text='M.W' />
    },
    {
      field: 'priceByMktVal',
      headerName: 'Price By Market Value (%)',
      type: 'number',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => (params.value * 100).toFixed(2),
      renderHeader: (params) => <CustomHeader title='Price By Market Value (%)' />,
      renderCell: (params) => (params?.row?.priceByMktVal * 100).toFixed(2)
    },
    {
      field: 'initWeight',
      headerName: 'Initial Weight (%)',
      type: 'number',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => (params.value * 100).toFixed(2),
      renderCell: (params) => (params?.row?.initWeight * 100).toFixed(2),
      renderHeader: (params) => {
        return <CustomHeader title='Initial Weight (%)' text='I.W' />
      }
    },
    {
      field: 'propWeight',
      headerName: 'Proposed Weight (%)',
      type: 'number',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => (params.value * 100).toFixed(2),
      renderCell: (params) => parseFloat(params?.row?.propWeight * 100).toFixed(2),
      renderHeader: (params) => <CustomHeader title='Proposed Weight (%)' text='P.W' />
    },
    {
      field: 'propDrift',
      headerName: 'Proposed Drift (%)',
      type: 'number',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => (params.value * 100).toFixed(2),
      renderHeader: (params) => <CustomHeader title='Proposed Drift (%)' text='P.D = M.W - P.W' />,
      renderCell: (params) => parseFloat(params.row.propDrift * 100).toFixed(2)
    },
    {
      field: 'initDrift',
      headerName: 'Initial Drift (%)',
      type: 'number',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => (params.value * 100).toFixed(2),
      renderCell: (params) => (params?.row?.initDrift * 100).toFixed(2),
      renderHeader: (params) => <CustomHeader title='Initial Drift (%)' text='I.D = M.W - I.W' />
    },
    {
      field: 'tradeDrift',
      headerName: 'Trade Drift (%)',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      flex: 1,
      valueGetter: (params) => parseFloat((Math.abs(params?.row?.propDrift) - Math.abs(params?.row?.initDrift)) * 100)?.toFixed(2),
      renderHeader: (params) => <CustomHeader title='Trade Drift (%)' text='T.D = abs(P.D) - abs(I.D)' />,
      renderCell: (params) => {
        const absDiff = parseFloat(((Math.abs(params?.row?.propDrift) - Math.abs(params?.row?.initDrift)) * 100)?.toFixed(2))
        return (<>{absDiff < 0 ? <ArrowDownward fontSize='small' sx={{ color: '#3BBFA3' }} /> : (absDiff > 0) ? <ArrowUpward fontSize='small' sx={{ color: '#FF6161' }} /> : ''}<Typography fontSize='14px' fontWeight='600' color={absDiff < 0 ? '#3BBFA3' : (absDiff > 0) ? '#FF6161' : '#34475A'}>{absDiff?.toFixed(2)}</Typography></>)
      }
    }
  ]

  const getRowId = (row) => row.id
  const tableHeaderHTML = tableHeaders.map(({ headerName }, index) => <TableCell key={index}>{headerName}</TableCell>)
  const rowSkeleton = tableHeaders.map(({ headerName }, index) => <TableCell key={index}><Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} /></TableCell>)

  // security level trade table
  const [tradeTableHeaders] = useState([
    { field: 'instr_id', headerName: 'Instrument Id', flex: 1 },
    {
      field: 'trdCode',
      headerName: 'Trade Code',
      renderCell: (params) => (<>
        <span style={{ color: params.value === 'SELL' ? '#FF6161' : params.value === 'BUY' ? '#3BBFA3' : '#34475A' }}>{params.value}</span>
      </>),
      flex: 1
    },
    { field: 'initShares', headerName: 'Initial Shares', type: 'number', valueFormatter: (params) => params?.value?.toFixed(2), align: 'right', headerAlign: 'right', flex: 1 },
    { field: 'propShares', headerName: 'Proposed Shares', type: 'number', valueFormatter: (params) => params?.value?.toFixed(2), align: 'right', headerAlign: 'right', flex: 1 },
    {
      field: 'trdQty',
      headerAlign: 'right',
      headerName: 'Trade Quantity',
      type: 'number',
      valueFormatter: (params) => params?.value?.toFixed(2),
      align: 'right',
      flex: 1
    }
  ])

  const tradeBlotterHeaders = [
    {
      field: 'instrId',
      headerName: 'Instrument Id',
      renderHeader: (params) => <CustomHeader title='Instrument Id' />,
      flex: 1
    },
    {
      field: 'localSymbol',
      headerName: 'Local Symbol',
      renderHeader: (params) => <CustomHeader title='Local Symbol' />,
      flex: 0.7
    },
    {
      field: 'side',
      headerName: 'Trade Code',
      renderHeader: (params) => <CustomHeader title='Trade Code' text='TC' />,
      renderCell: (params) => (<>
        <span style={{ color: params.formattedValue === 'SELL' ? '#FF6161' : params.formattedValue === 'BUY' ? '#3BBFA3' : '#34475A' }}>{params.formattedValue}</span>
      </>),
      flex: 0.7
    },
    {
      field: 'lastClosePrice',
      headerName: 'Last Close Price',
      type: 'number',
      valueGetter: (params) => params?.value?.toFixed(2),
      renderCell: (params) => !isNaN(params?.row?.lastClosePrice)
        ? params?.row?.lastClosePrice < 0
          ? `-$${formatCurrency(Math.abs(params?.row?.lastClosePrice, 2))}`
          : `$${formatCurrency(params?.row?.lastClosePrice, 2)}`
        : 'N/A',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      renderHeader: (params) => <CustomHeader title='Last Close Price' text='LCP' />
    },
    {
      field: 'livePrice',
      headerName: 'Live Price',
      type: 'number',
      renderHeader: (params) => <CustomHeader title='Live Price' text='LP' />,
      renderCell: (params) => {
        const isLivePrice = params?.row?.isLivePrice === 1

        const cellStyle = {
          color: !isLivePrice ? 'rgba(0, 0, 0, 0.4)' : 'inherit',
          textAlign: 'right'
        }

        return (
          <div style={cellStyle}>
            {params?.row?.livePrice !== null
              ? params?.row?.livePrice < 0
                ? `-$${formatCurrency(Math.abs(params?.row?.livePrice, 2))}`
                : `$${formatCurrency(params?.row?.livePrice, 2)}`
              : 'N/A'}
          </div>
        )
      },
      flex: 0.6,
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'marketValue',
      headerName: 'Market Value',
      type: 'number',
      renderHeader: (params) => <CustomHeader title='Market Value' text='MV' />,
      renderCell: (params) => !isNaN(params?.row?.marketValue)
        ? params?.row?.marketValue < 0
          ? `-$${formatCurrency(Math.abs(params?.row?.marketValue, 2))}`
          : `$${formatCurrency(params?.row?.marketValue, 2)}`
        : 'N/A',
      flex: 0.8,
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'liveMarketValue',
      headerName: 'Live Market Value',
      type: 'number',
      renderHeader: (params) => <CustomHeader title='Live Market Value' text='LMV' />,
      renderCell: (params) => {
        const isLivePrice = params?.row?.isLivePrice === 1

        const cellStyle = {
          color: !isLivePrice ? 'rgba(0, 0, 0, 0.4)' : 'inherit',
          textAlign: 'right'
        }

        return (
          <div style={cellStyle}>
            {params?.row?.liveMarketValue !== null
              ? params?.row?.liveMarketValue < 0
                ? `-$${formatCurrency(Math.abs(params?.row?.liveMarketValue, 2))}`
                : `$${formatCurrency(params?.row?.liveMarketValue, 2)}`
              : 'N/A'}
          </div>
        )
      },
      flex: 1,
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'priceChange',
      type: 'number',
      renderCell: (params) => {
        const isPriceChangeNull = params?.row?.priceChange === null
        const isLivePrice = params?.row?.isLivePrice === 1

        const cellStyle = {
          color: !isLivePrice ? 'rgba(0, 0, 0, 0.4)' : 'inherit',
          textAlign: 'right'
        }
        return (
          <div style={cellStyle}>
            {!isPriceChangeNull
              ? `${(params?.row?.priceChange * 100).toFixed(2)}`
              : 'N/A'}
          </div>
        )
      },
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      headerName: 'Price Change (%)',
      valueGetter: (params) => (params.value * 100).toFixed(2),
      renderHeader: (params) => <CustomHeader title='Price Change (%)' text='PC = (LP - LCP)/LP' />
    },
    {
      field: 'marketValueChange',
      type: 'number',
      renderCell: (params) => {
        const isMarketValueChangeNull = params?.row?.marketValueChange === null
        const isLivePrice = params?.row?.isLivePrice === 1

        const cellStyle = {
          color: !isLivePrice ? 'rgba(0, 0, 0, 0.4)' : 'inherit',
          textAlign: 'right'
        }
        return (
          <div style={cellStyle}>
            {!isMarketValueChangeNull
              ? `${(params?.row?.marketValueChange).toFixed(2)}`
              : 'N/A'}
          </div>
        )
      },
      flex: 1.4,
      align: 'right',
      headerAlign: 'right',
      headerName: 'Trade Value Change',
      renderHeader: (params) => <CustomHeader title='Trade Value Change' text='TVC = TC *(LMV - MV)' />
    },
    {
      field: 'quantity',
      headerName: 'Quantities',
      type: 'number',
      renderHeader: (params) => <CustomHeader title='Quantities' />,
      flex: 0.8,
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'splitEffectiveDate',
      headerName: 'Split Effective Date',
      renderHeader: (params) => <CustomHeader title='Split Effective Date' />,
      flex: 1
    },
    {
      field: 'splitRatio',
      headerName: 'Split Ratio',
      renderHeader: (params) => <CustomHeader title='Split Ratio' />,
      flex: 0.6
    },
    {
      field: 'delistEffectiveDate',
      headerName: 'Delist Effective Date',
      renderHeader: (params) => <CustomHeader title='Delist Effective Date' />,
      flex: 1.1
    }
  ]

  const modelChangesHeaders = [
    { field: 'instrId', headerName: 'Instrument Id', flex: 1 },
    {
      field: 'tradeCode',
      headerName: 'Trade Code',
      flex: 1,
      renderCell: (params) => (<>
        <span style={{ color: params.value === 'SELL' ? '#FF6161' : params.value === 'BUY' ? '#3BBFA3' : '#34475A' }}>{params.value}</span>
                               </>
      )
    },
    {
      field: 'previousSecurityWeight',
      headerName: 'Previous Weight (%)',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      renderCell: (props) => props?.row?.previousSecurityWeight !== undefined && props?.row?.previousSecurityWeight !== null
        ? ((props?.row?.previousSecurityWeight) * 100).toFixed(2)
        : '0.00',
      valueGetter: (props) => props?.row?.previousSecurityWeight !== undefined && props?.row?.previousSecurityWeight !== null
        ? Number(((props?.row?.previousSecurityWeight) * 100).toFixed(2))
        : 0.00,
      flex: 1
    },
    {
      field: 'currentSecurityWeight',
      headerName: 'Current Weight (%)',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      renderCell: (props) => props?.row?.currentSecurityWeight !== undefined && props?.row?.currentSecurityWeight !== null
        ? ((props?.row?.currentSecurityWeight) * 100).toFixed(2)
        : '0.00',
      valueGetter: (props) => props?.row?.currentSecurityWeight !== undefined && props?.row?.currentSecurityWeight !== null
        ? Number(((props?.row?.currentSecurityWeight) * 100).toFixed(2))
        : 0.00,
      flex: 1
    },
    {
      field: 'drift',
      headerName: 'Weight Change(%)',
      align: 'right',
      headerAlign: 'right',
      type: 'number',
      sortComparator: (a, b) => Math.abs(a) - Math.abs(b),
      flex: 1,
      // renderHeader: () => <CustomHeader title='Weight Change(%)' text='WC = UPW - CPW' />,
      renderCell: (params) => {
        const driftVal = params?.row?.drift
        const invalid = Math.abs(parseFloat(driftVal * 100).toFixed(2)) === 0
        return (
          <>
            {driftVal < 0 && !invalid
              ? <ArrowCircleDownIcon fontSize='small' sx={{ color: '#FF6161', marginRight: '2px' }} />
              : driftVal > 0 && !invalid
                ? <ArrowCircleUpIcon fontSize='small' sx={{ color: '#3BBFA3', marginRight: '2px' }} />
                : ''}
            <Typography
              fontSize='14px'
              fontWeight='600'
              color={parseFloat(driftVal * 100).toFixed(2) < 0 && !invalid
                ? '#FF6161'
                : (parseFloat(driftVal * 100).toFixed(2) > 0 && !invalid)
                    ? '#3BBFA3'
                    : ''}
              sx={{ width: '35px', textAlign: 'right', display: 'inline-block' }}
            >
              {Math.abs(parseFloat(driftVal * 100).toFixed(2)) === 0 ? '0.00' : parseFloat(driftVal * 100).toFixed(2)}
            </Typography>
          </>
        )
      },
      valueGetter: (props) => props?.row?.drift !== undefined && props?.row?.drift !== null
        ? Math.abs(parseFloat(props?.row?.drift * 100).toFixed(2)) === 0 ? 0.00 : parseFloat(parseFloat(props?.row?.drift * 100).toFixed(2))
        : 0.00
    }
  ]

  const handlePositionChange = (e) => {
    setCurrentPositionValue(e.target.value)
  }

  const getDetailPanelContent = (params) => {
    const detailColumns = [
      { field: '', headerName: '', width: 50, sortable: false, disableColumnMenu: true, flex: 1 },
      { field: 'instrId', headerName: 'Instrument ID', flex: 1 },
      {
        field: 'trdCode',
        headerName: 'Trade Code',
        flex: 1,
        renderCell: (params) => (<>
          <span style={{ color: params.value === 'SELL' ? '#FF6161' : params.value === 'BUY' ? '#3BBFA3' : '#34475A' }}>{params.value}</span>
        </>)
      },
      { field: 'trdQty', headerName: 'Trade Quantity', type: 'number', align: 'right', headerAlign: 'right', flex: 1 },
      { field: 'trdDate', headerName: 'Trade Date', flex: 1, valueFormatter: (params) => params?.value ? params?.value?.split('T')[0] : '' },
      {
        field: 'initMarketValue',
        flex: 1,
        type: 'number',
        headerName: 'Initial Market Value',
        align: 'right',
        headerAlign: 'right',
        renderCell: (params) => !isNaN(params?.row?.initMarketValue)
          ? params?.row?.initMarketValue < 0
            ? `-$${formatCurrency(params?.row?.initMarketValue, 2)}`
            : `$${formatCurrency(params?.row?.initMarketValue, 2)}`
          : 'N/A'
      },
      { field: 'initShares', headerName: 'Initial Shares', type: 'number', align: 'right', headerAlign: 'right', flex: 1 },
      {
        field: 'propMarketValue',
        headerName: 'Proposed Market Value',
        type: 'number',
        align: 'right',
        headerAlign: 'right',
        flex: 1,
        renderCell: (params) => !isNaN(params?.row?.propMarketValue)
          ? params?.row?.propMarketValue < 0
            ? `-$${formatCurrency(params?.row?.propMarketValue, 2)}`
            : `$${formatCurrency(params?.row?.propMarketValue, 2)}`
          : 'N/A'
      },
      {
        field: 'lastClosePrice',
        headerName: 'Last Close Price',
        type: 'number',
        align: 'right',
        headerAlign: 'right',
        flex: 1,
        renderCell: (params) => !isNaN(params?.row?.lastClosePrice)
          ? params?.row?.lastClosePrice < 0
            ? `-$${formatCurrency(params?.row?.lastClosePrice, 2)}`
            : `$${formatCurrency(params?.row?.lastClosePrice, 2)}`
          : 'N/A'
      },
      {
        field: 'totalMarketValue',
        headerName: 'Total Market Value',
        type: 'number',
        align: 'right',
        headerAlign: 'right',
        flex: 1,
        renderCell: (params) => !isNaN(params?.row?.totalMarketValue)
          ? params?.row?.totalMarketValue < 0
            ? `-$${formatCurrency(params?.row?.totalMarketValue, 2)}`
            : `$${formatCurrency(params?.row?.totalMarketValue, 2)}`
          : 'N/A'
      },
      {
        field: 'orgPurchasePrice',
        headerName: 'Org Purchase Price',
        type: 'number',
        align: 'right',
        headerAlign: 'right',
        flex: 1,
        renderCell: (params) => !isNaN(params?.row?.orgPurchasePrice)
          ? params?.row?.orgPurchasePrice < 0
            ? `-$${formatCurrency(params?.row?.orgPurchasePrice, 2)}`
            : `$${formatCurrency(params?.row?.orgPurchasePrice, 2)}`
          : 'N/A'
      },
      {
        field: 'rgl',
        headerName: 'Realized Gain/Loss',
        type: 'number',
        align: 'right',
        headerAlign: 'right',
        flex: 1,
        renderCell: (params) => !isNaN(params?.row?.rgl)
          ? params?.row?.rgl < 0
            ? `-$${formatCurrency(params?.row?.rgl, 2)}`
            : `$${formatCurrency(params?.row?.rgl, 2)}`
          : 'N/A'
      },
      {
        field: 'initUrgl',
        headerName: 'Initial Unrealized Gain/Loss',
        type: 'number',
        align: 'right',
        headerAlign: 'right',
        flex: 1,
        renderCell: (params) => !isNaN(params?.row?.initUrgl)
          ? params?.row?.initUrgl < 0
            ? `-$${formatCurrency(params?.row?.initUrgl, 2)}`
            : `$${formatCurrency(params?.row?.initUrgl, 2)}`
          : 'N/A'
      },
      { field: 'costBasis', headerName: 'Cost Basis', align: 'right', headerAlign: 'right', flex: 1 },
      { field: 'localSymbol', headerName: 'Local Symbol', flex: 1 }
    ]

    const detailRows = params.row.propDetail.map((details) => ({ ...details, id: randomId() }))

    return (
      <Box sx={{ width: '100%' }}>
        <DataGridPro
          autoHeight
          rows={detailRows}
          columns={detailColumns}
          getRowId={(row) => row.id}
          hideFooter
          disableSelectionOnClick
        />
      </Box>
    )
  }

  const positionGroupTableHeader = [
    {
      field: 'modelWeight',
      headerName: 'Model Weight (%)',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      flex: 1,
      valueGetter: (params) => (params.value * 100).toFixed(2),
      renderHeader: (params) => <CustomHeader title='Model Weight (%)' text='' />,
      renderCell: (params) => parseFloat(params?.row?.modelWeight * 100).toFixed(2)
    },
    {
      field: 'initWeight',
      headerName: 'Initial Weight (%)',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      flex: 1,
      valueGetter: (params) => (params.value * 100).toFixed(2),
      renderCell: (params) => parseFloat(params?.row?.initWeight * 100).toFixed(2),
      renderHeader: (params) => <CustomHeader title='Initial Weight (%)' text='I.W' />
    },
    {
      field: 'propWeight',
      headerName: 'Proposed Weight (%)',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      flex: 1,
      valueGetter: (params) => (params.value * 100).toFixed(2),
      renderCell: (params) => (params?.row?.propWeight * 100).toFixed(2),
      renderHeader: (params) => <CustomHeader title='Proposed Weight (%)' text='P.W' />
    },
    {
      field: 'propDrift',
      headerName: 'Proposed Drift (%)',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      flex: 1,
      valueGetter: (params) => (params.value * 100).toFixed(2),
      renderCell: (params) => (params?.row?.propDrift * 100).toFixed(2),
      renderHeader: (params) => <CustomHeader title='Proposed Drift (%)' text='P.D = M.W - P.W' />
    },
    {
      field: 'initDrift',
      headerName: 'Initial Drift (%)',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      flex: 1,
      valueGetter: (params) => (params.value * 100).toFixed(2),
      renderCell: (params) => parseFloat(params?.row?.initDrift * 100).toFixed(2),
      renderHeader: (params) => <CustomHeader title='Initial Drift (%)' text='I.D = M.W - I.W' />
    },
    {
      field: 'tradeDrift',
      headerName: 'Trade Drift (%)',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      flex: 1,
      valueGetter: (params) => parseFloat((Math.abs(params?.row?.propDrift) - Math.abs(params?.row?.initDrift)) * 100)?.toFixed(2),
      renderHeader: (params) => <CustomHeader title='Trade Drift (%)' text='T.D = abs(P.D) - abs(I.D)' />,
      renderCell: (params) => {
        const absDiff = parseFloat(((Math.abs(params?.row?.propDrift) - Math.abs(params?.row?.initDrift)) * 100)?.toFixed(2))
        return (<>{absDiff < 0 ? <ArrowDownward fontSize='small' sx={{ color: '#3BBFA3' }} /> : (absDiff > 0) ? <ArrowUpward fontSize='small' sx={{ color: '#FF6161' }} /> : ''}<Typography fontSize='14px' fontWeight='600' color={absDiff < 0 ? '#3BBFA3' : (absDiff > 0) ? '#FF6161' : '#34475A'}>{absDiff?.toFixed(2)}</Typography></>)
      }
    }
  ]

  const renderTableSkeleton = (header) => {
    return (
      <TableContainer mt={5}>
        <Table className='risk-page-table'>
          <TableHead>
            <TableRow>
              {header.map(item => {
                return (
                  <TableCell>{item.headerName}</TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from({ length: 5 }).map(_ => (
              <TableRow>
                {Array.from({ length: header.length }).map(_ => (
                  <TableCell>
                    <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                  </TableCell>))}
              </TableRow>))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  const renderOptTableSkeleton = () => {
    return (
      <Box sx={{ my: 3 }}>
        <Skeleton variant='rectangle' sx={{ height: '30px', width: '50%', my: 2 }} />
        <Skeleton variant='rectangle' sx={{ height: '30px', width: '30%', my: 2 }} />
        <Skeleton variant='rectangle' sx={{ height: '30px', width: '40%', my: 2 }} />
        <Skeleton variant='rectangle' sx={{ height: '30px', width: '20%', my: 2 }} />
        <Skeleton variant='rectangle' sx={{ height: '30px', width: '40%', my: 2 }} />
      </Box>
    )
  }

  const renderPositionDatagrid = (rows, columns, treeData) => {
    return (
      <DataGridPro
        density='compact'
        treeData
        getTreeDataPath={(row) => row.hierarchy}
        autoHeight
        rows={rows || []}
        columns={columns}
        getRowId={getRowId}
        disableChildrenFiltering
        disableRowSelectionOnClick
        disableSelectionOnClick
        disableRowSelectionOnClick
        groupingColDef={{
          headerName: '',
          width: 50,
          renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />
        }}
        pagination
        pageSizeOptions={[15]}
        initialState={{
          ...rows?.initialState,
          pagination: { paginationModel: { pageSize: 15 } }
        }}
        sx={(theme) => ({
          [`.${gridClasses.main}`]: {
            overflow: 'unset'
          },
          [`.${gridClasses.columnHeaders}`]: {
            position: 'sticky',
            backgroundColor: theme.palette.background.paper,
            zIndex: 1,
            minHeight: '60px !important'
          },
          [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
            color: '#74788d',
            fontWeight: 600
          },
          [`.${gridClasses.footerContainer}`]: {
            position: 'sticky',
            bottom: -1,
            backgroundColor: theme.palette.background.paper,
            zIndex: 1
          },
          [`.${gridClasses.toolbarContainer}`]: {
            position: 'relative',
            top: 0,
            backgroundColor: theme.palette.background.paper,
            zIndex: 1
          }
        })}
      />
    )
  }

  const renderDataGridPro = (rows, column, header, detailPanel = 0, getDetailPanelContent) => {
    const hiddenColumns = column.filter(column => column.defaultHidden)
    const columnVisibilityModel = {}
    if (hiddenColumns?.length) {
      hiddenColumns?.forEach(columnObj => {
        columnVisibilityModel[columnObj?.field || ''] = false
      })
    }
    return (
      <DataGridPro
        density='compact'
        autoHeight
        rows={rows || []}
        columns={column}
        getRowId={getRowId}
        disableSelectionOnClick
        disableRowSelectionOnClick
        getDetailPanelHeight={detailPanel ? (row) => 'auto' : null}
        getDetailPanelContent={detailPanel ? getDetailPanelContent : null}
        pagination
        pageSizeOptions={[15]}
        initialState={{
          ...rows?.initialState,
          pagination: { paginationModel: { pageSize: 15 } },
          columns: {
            columnVisibilityModel: Object.keys(columnVisibilityModel)?.length ? columnVisibilityModel : {}
          }
        }}
        sx={(theme) => ({
          [`.${gridClasses.main}`]: {
            overflow: 'unset'
          },
          [`.${gridClasses.columnHeaders}`]: {
            position: 'sticky',
            backgroundColor: theme.palette.background.paper,
            zIndex: 1,
            minHeight: (header === 'Blotter') ? '60px !important' : 'unset !important' // Set minHeight conditionally
          },
          [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
            color: '#74788d',
            fontWeight: 600
          },
          [`.${gridClasses.footerContainer}`]: {
            position: 'sticky',
            bottom: -1,
            backgroundColor: theme.palette.background.paper,
            zIndex: 1
          },
          [`.${gridClasses.toolbarContainer}`]: {
            position: 'relative',
            top: 0,
            backgroundColor: theme.palette.background.paper,
            zIndex: 1
          }
        })}
      />
    )
  }

  const renderDataPanel = () => {
    switch (currentPositionValue) {
      case 'security':
        return (
          <>
            {positionDataLoading
              ? renderTableSkeleton(positionSecurityTableHeader)
              : <DataGridPro
                  density='compact'
                  autoHeight
                  rows={positionData || []}
                  columns={positionSecurityTableHeader}
                  getTreeDataPath={(row) => ([getRowId(row)])}
                  getRowId={getRowId}
                  disableSelectionOnClick
                  disableRowSelectionOnClick
                  getDetailPanelHeight={({ row }) => 'auto'}
                  getDetailPanelContent={getDetailPanelContent}
                  pagination
                  pageSizeOptions={[15]}
                  initialState={{
                    ...positionData?.initialState,
                    pagination: { paginationModel: { pageSize: 15 } }
                  }}
                  sx={(theme) => ({
                    [`.${gridClasses.main}`]: {
                      overflow: 'unset'
                    },
                    [`.${gridClasses.columnHeaders}`]: {
                      position: 'sticky',
                      backgroundColor: theme.palette.background.paper,
                      zIndex: 1,
                      minHeight: '60px !important'
                    },
                    [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                      color: '#74788d',
                      fontWeight: 600
                    },
                    [`.${gridClasses.footerContainer}`]: {
                      position: 'sticky',
                      bottom: -1,
                      backgroundColor: theme.palette.background.paper,
                      zIndex: 1
                    }
                  })}
                />}
          </>
        )
      case 'sector':
        let headerSector = [
          {
            field: 'sector',
            headerName: 'Sector',
            flex: 1,
            valueGetter: (params) => {
              const hierarchy = params?.row?.hierarchy
              return hierarchy[hierarchy.length - 1]
            }
          }
        ]
        headerSector = headerSector.concat(positionGroupTableHeader)
        return (
          <>
            {optPropAssetDataLoading
              ? renderTableSkeleton(headerSector)
              : renderPositionDatagrid(sectorData, headerSector, 'Sector')}
          </>
        )
      case 'industry':
        let headerIndustry = [
          {
            field: 'industry',
            headerName: 'Industry',
            flex: 1,
            valueGetter: (params) => {
              const hierarchy = params?.row?.hierarchy
              return hierarchy[hierarchy.length - 1]
            }
          }
        ]
        headerIndustry = headerIndustry.concat(positionGroupTableHeader)
        return (
          <>
            {optPropAssetDataLoading
              ? renderTableSkeleton(headerIndustry)
              : renderPositionDatagrid(industryData, headerIndustry, 'Industry')}
          </>
        )
      case 'sub-industry':
        let headerSubIndustry = [
          {
            field: 'subIndustry',
            headerName: 'Sub-Industry',
            flex: 1,
            valueGetter: (params) => {
              const hierarchy = params?.row?.hierarchy
              return hierarchy[hierarchy.length - 1]
            }
          }
        ]
        headerSubIndustry = headerSubIndustry.concat(positionGroupTableHeader)
        return (
          <>
            {optPropAssetDataLoading
              ? renderTableSkeleton(headerSubIndustry)
              : renderPositionDatagrid(subIndustryData, headerSubIndustry, 'Sub-Industry')}
          </>
        )
      case 'company':
        let headerCompany = [
          {
            field: 'companyName',
            headerName: 'Company',
            flex: 1,
            valueGetter: (params) => {
              const hierarchy = params?.row?.hierarchy
              return hierarchy[hierarchy.length - 1]
            }
          }
        ]
        headerCompany = headerCompany.concat(positionGroupTableHeader)
        return (
          <>
            {optPropAssetDataLoading
              ? renderTableSkeleton(headerCompany)
              : renderPositionDatagrid(companyWiseData, headerCompany, 'Comapny')}
          </>
        )
      default:
    }
  }

  const renderTabPanel = (item) => {
    switch (item) {
      case 'Taxlot':
        return (
          <>
            {!loading
              ? renderDataGridPro(optmRunSumPro, tableHeaders)
              : renderTableSkeleton(tableHeaders)}
          </>
        )
      case 'Position':
        return (
          <>
            {renderDataPanel()}
          </>
        )
      case 'Trade':
        return (
          <>
            {!positionDataLoading
              ? renderDataGridPro(securityLevelTradeData, tradeTableHeaders)
              : renderTableSkeleton(tradeTableHeaders)}
          </>
        )
      case 'Blotter':
        return (
          <>
            {
              !tradeDataLoading ? renderDataGridPro(tradeBlotterData, tradeBlotterHeaders, 'Blotter') : renderTableSkeleton(tradeBlotterHeaders)
            }
          </>
        )
      case 'Feasibility Report':
        return (
          <>
            {optFeasibilityLoading
              ? renderOptTableSkeleton()
              : <TradeFeasibilityReportTab optFeasibilityReport={optFeasibilityData || {}} />}
          </>
        )
      case 'Model Variance':
        return (
          <>
            {!otherPropertiesLoading ? renderDataGridPro(optData, modelChangesHeaders) : renderTableSkeleton(modelChangesHeaders)}
          </>
        )
      default:
        return 'Something went wrong'
    }
  }

  const downloadCsv = (summaryOnly) => {
    let fileName = ''
    let dataCsv = null

    if (value === 'Trade') {
      const headers = ['Instrument ID', 'Trade Code', 'Initial Shares', 'Proposed Shares', 'Trade Quantity']
      dataCsv = [headers.join(',')]

      for (let i = 0; i < securityLevelTradeData?.length; i++) {
        const { instr_id, trdCode, initShares, propShares, trdQty } = securityLevelTradeData[i]
        dataCsv.push([instr_id || '', trdCode || '', initShares, propShares, trdQty].join(','))
      }
      fileName = 'trade'
    } else if (value === 'Taxlot') {
      const headers = ['Instrument ID', 'Local Symbol', 'Trade Reason', 'Trade Code',
        'Trade Quantity', 'Initial Shares', 'Proposed Shares', 'Initial Weight',
        'Proposed Weight', 'Initial Market Value', 'Proposed Market Value',
        'Age', 'Cost Basis', 'Purchase Date', 'Original Purchase Price',
        'Last Closed Price', 'Initial Unrealized Gain', 'Live Price',
        'Realized Gain', 'Trade Date']

      dataCsv = [headers.join(',')]

      for (let i = 0; i < optmRunSumPro?.length; i++) {
        const {
          instrId, localSymbol, tradeTags, trdCode, trdQty, initShares,
          propShares, initWeight, propWeight, initMarketValue, propMarketValue,
          age, costBasis, purchaseDate, orgPurchasePrice, liveMarketPrice,
          lastClosePrice, rglLive, initUrgl, rgl, trdDate
        } = optmRunSumPro[i]

        const tradeTag = tradeTags?.map(tag => tag.reasonCd).join(', ') || ''

        dataCsv.push([
          instrId || '', localSymbol || '', tradeTag, trdCode || '', trdQty || '',
          initShares || '', propShares || '', initWeight || '', propWeight || '',
          initMarketValue || '', propMarketValue || '', age || '',
          costBasis || '', purchaseDate ? purchaseDate.split('T')[0] : '',
          orgPurchasePrice || '',
          lastClosePrice || '', initUrgl || '', liveMarketPrice || '',
          rgl || '', rglLive || '', trdDate ? trdDate.split('T')[0] : ''
        ].join(','))
      }

      fileName = 'taxlot'
    } else if (value === 'Position') {
      if (currentPositionValue === 'security') {
        fileName = currentPositionValue
        const headers = ['Instrument ID', 'Trade Code', 'Model Weight', 'Price By Market Value', 'Initial Weight', 'Proposed Weight', 'Proposed Drift', 'Initial Drift', 'Trade Drift']
        dataCsv = [headers.join(',')]

        for (let i = 0; i < positionData?.length; i++) {
          const { instr_id, trdCode, initDrift, initWeight, modelWeight, priceByMktVal, propDrift, propWeight, tradeDrift } = positionData[i]
          dataCsv.push([instr_id || '', trdCode || '', modelWeight, priceByMktVal, initWeight, propWeight, propDrift, initDrift, tradeDrift].join(','))
        }
      } else {
        fileName = summaryOnly ? currentPositionValue + '-summary' : currentPositionValue
        let headers = []
        if (summaryOnly) {
          headers = ['', 'Model Weight', 'Initial Weight', 'Proposed Weight', 'Proposed Drift', 'Initial Drift', 'Trade Drift']
        } else {
          headers = ['', 'Company Name', 'Model Weight', 'Initial Weight', 'Proposed Weight', 'Proposed Drift', 'Initial Drift', 'Trade Drift']
        }

        if (currentPositionValue === 'sector') {
          headers[0] = 'Sector'
          dataCsv = [headers.join(',')]

          for (let i = 0; i < sectorData?.length; i++) {
            const { hierarchy, modelWeight, initWeight, initDrift, propWeight, propDrift, tradeDrift } = sectorData[i]
            if (summaryOnly && hierarchy.length === 1) { dataCsv.push([hierarchy[0].replace(',', ''), modelWeight, initWeight, propWeight, propDrift, initDrift, tradeDrift]) } else if (!summaryOnly) { dataCsv.push([hierarchy[0].replace(',', ''), hierarchy[hierarchy.length - 1], modelWeight, initWeight, propWeight, propDrift, initDrift, tradeDrift]) }
          }
        } else if (currentPositionValue === 'industry') {
          headers[0] = 'Industry'
          dataCsv = [headers.join(',')]

          for (let i = 0; i < industryData.length; i++) {
            const { hierarchy, modelWeight, initWeight, initDrift, propWeight, propDrift, tradeDrift } = industryData[i]
            if (summaryOnly && hierarchy.length === 1) { dataCsv.push([hierarchy[0].replace(',', ''), modelWeight, initWeight, propWeight, propDrift, initDrift, tradeDrift]) } else if (!summaryOnly) { dataCsv.push([hierarchy[0].replace(',', ''), hierarchy[hierarchy.length - 1].replace(',', ''), modelWeight, initWeight, propWeight, propDrift, initDrift, tradeDrift]) }
          }
        } else if (currentPositionValue === 'sub-industry') {
          headers[0] = 'Sub Industry'
          dataCsv = [headers.join(',')]

          for (let i = 0; i < subIndustryData.length; i++) {
            const { hierarchy, modelWeight, initWeight, initDrift, propWeight, propDrift, tradeDrift } = subIndustryData[i]
            if (summaryOnly && hierarchy.length === 1) { dataCsv.push([hierarchy[0], modelWeight, initWeight, propWeight, propDrift, initDrift, tradeDrift]) } else if (!summaryOnly) { dataCsv.push([hierarchy[0], hierarchy[hierarchy.length - 1] || '', modelWeight, initWeight, propWeight, propDrift, initDrift, tradeDrift]) }
          }
        } else if (currentPositionValue === 'company') {
          headers[0] = 'Company Name'
          if (!summaryOnly) { headers[1] = 'Instrument' }
          dataCsv = [headers.join(',')]
          for (let i = 0; i < companyWiseData.length; i++) {
            const { hierarchy, modelWeight, initWeight, initDrift, propWeight, propDrift, tradeDrift } = companyWiseData[i]
            if (summaryOnly && hierarchy.length === 1) { dataCsv.push([hierarchy[0], modelWeight, initWeight, propWeight, propDrift, initDrift, tradeDrift]) } else if (!summaryOnly) { dataCsv.push([hierarchy[0], hierarchy[hierarchy.length - 1], modelWeight, initWeight, propWeight, propDrift, initDrift, tradeDrift]) }
          }
        }
      }
    } else if (value === 'Blotter') {
      const headers = ['Instrument Id', 'Local Symbol', 'Last Close Price', 'Live Price', 'Market Value',
        'Live Market Value', 'Price Change (%)', 'Trade Value Change', 'Side', 'Quantity', 'Split Effective Date', 'Split Ratio', 'Delist Effective Date']
      dataCsv = [headers.join(',')]

      for (let i = 0; i < tradeBlotterData?.length; i++) {
        const {
          instrId, localSymbol, lastClosePrice, livePrice, marketValue, liveMarketValue, priceChange, marketValueChange, side,
          quantity, splitEffectiveDate, splitRatio, delistEffectiveDate
        } = tradeBlotterData[i]

        // Format the priceChange and marketValueChange values as percentages with two decimal places
        const formattedPriceChange = (priceChange * 100).toFixed(2)
        const formattedMarketValueChange = (marketValueChange * 100).toFixed(2)

        dataCsv.push([instrId, localSymbol || '', lastClosePrice, livePrice, marketValue,
          liveMarketValue, formattedPriceChange, formattedMarketValueChange, side, quantity, splitEffectiveDate || '', splitRatio || '', delistEffectiveDate || ''].join(','))
      }
      fileName = 'blotter'
    } else if (value === 'Model Variance') {
      const headers = ['Instrument Id', 'Trade Code', 'Previous Weight', 'Current Weight', 'Weight Change']
      dataCsv = [headers.join(',')]

      for (let i = 0; i < optData?.length; i++) {
        const {
          instrId, trdCode, previousSecurityWeight, currentSecurityWeight, drift
        } = optData[i]

        dataCsv.push([instrId || '', trdCode || '', previousSecurityWeight || '', currentSecurityWeight || '', drift || ''].join(','))
      }
      fileName = 'model-variance'
    }

    const csvBlob = new Blob([dataCsv.join('\n')], { type: 'text/csv' })
    const csvUrl = URL.createObjectURL(csvBlob)

    // Create a temporary link and trigger the download
    const tempLink = document.createElement('a')
    tempLink.href = csvUrl
    tempLink.setAttribute('download', `${accountCode}-${fileName?.toUpperCase()}.csv`)
    tempLink.click()

    // Clean up the URL object
    URL.revokeObjectURL(csvUrl)
  }

  const showExportCSVButton = () => {
    if ((value === 'Trade') && (securityLevelTradeData?.length > 0)) {
      return {
        summaryExport: false,
        export: true
      }
    } else if ((value === 'Taxlot') && (optmRunSumPro?.length > 0)) {
      return {
        summaryExport: false,
        export: true
      }
    } else if ((value === 'Position')) {
      if ((value === 'Position' && currentPositionValue === 'security') && (positionData?.length > 0)) {
        return {
          summaryExport: false,
          export: true
        }
      } else if ((value === 'Position' && currentPositionValue === 'sector') && (sectorData?.length > 0)) {
        return {
          summaryExport: true,
          export: true
        }
      } else if ((value === 'Position' && currentPositionValue === 'industry') && (industryData?.length > 0)) {
        return {
          summaryExport: true,
          export: true
        }
      } else if ((value === 'Position' && currentPositionValue === 'sub-industry') && (subIndustryData?.length > 0)) {
        return {
          summaryExport: true,
          export: true
        }
      } else if ((value === 'Position' && currentPositionValue === 'company') && (companyWiseData?.length > 0)) {
        return {
          summaryExport: true,
          export: true
        }
      }
    } else if (value === 'Blotter') {
      if (tradeBlotterData?.length > 0) {
        return {
          summaryExport: false,
          export: true
        }
      }
    } else if (value === 'Model Variance') {
      if (optData?.length > 0) {
        return {
          summaryExport: false,
          export: true
        }
      }
    }
    return {
      summaryExport: false,
      export: false
    }
  }

  const filteredTradeDetailsTab = tradeDetailsTab.filter(item => {
    if (item === 'Feasibility Report') {
      return checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.FEASIBILITY_REPORT })
    } else if (item === 'Model Variance') {
      return checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.MODEL_VARIANCE })
    }
    return true
  })

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant='h6' style={{ fontWeight: 400, color: '#34475A' }}>Trade Details</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
          {
            showExportCSVButton()?.export || showExportCSVButton()?.summaryExport
              ? <Button
                  sx={{ textTransform: 'none' }}
                  onClick={(e) => setExportCSVAnchorEl(e.currentTarget)}
                >
                Export CSV
                </Button>
              : ''
          }
          <Menu
            open={Boolean(exportCSVAnchorEl)}
            onClose={() => setExportCSVAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            MenuListProps={{
              sx: { py: '4px' }
            }}
            anchorEl={exportCSVAnchorEl}
          >
            {
              showExportCSVButton()?.summaryExport
                ? <MenuItem
                    sx={{ fontSize: '14px' }}
                    onClick={() => (downloadCsv(true), setExportCSVAnchorEl(null))}
                  >
                  Export Summary CSV
                </MenuItem>
                : ''
            }
            {
              showExportCSVButton()?.export
                ? <MenuItem
                    sx={{ fontSize: '14px' }}
                    onClick={() => (downloadCsv(false), setExportCSVAnchorEl(null))}
                  >
                  Export Detailed CSV
                </MenuItem>
                : ''
            }
          </Menu>
          <IconButton aria-label='close' edge='end' onClick={() => setIsShown()}>
            <Close />
          </IconButton>
        </Box>
      </Box>
      <TabContext value={value}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant='standard'
              className='report-tab-header'
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#34475A'
                }
              }}
              indicatorColor='#34475A'
            >
              {
                filteredTradeDetailsTab?.map((item, index) => (
                  <Tab className='reportCardNav' key={index} value={item} label={item} />
                ))
              }
            </Tabs>
            {
              value === 'Position'
                ? <>
                  <FormControl sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', color: '#34475A' }}>
                    <FormLabel id='position-controlled-radio-buttons-group' />
                    <RadioGroup row name='position-radio-group' value={currentPositionValue} onChange={handlePositionChange}>
                      <FormControlLabel value='security' control={<Radio size='small' />} label='Security' />
                      <FormControlLabel value='sector' control={<Radio size='small' />} label='Sector' />
                      <FormControlLabel value='industry' control={<Radio size='small' />} label='Industry' />
                      <FormControlLabel value='sub-industry' control={<Radio size='small' />} label='Sub-Industry' />
                      <FormControlLabel value='company' control={<Radio size='small' />} label='Company' />
                    </RadioGroup>
                  </FormControl>
                </>
                : value === 'Trade' && optmRunSumPro?.length
                  ? <>
                    <FormControl sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                      <FormLabel id='trade-controlled-checkbox-buttons-group' />
                      <FormGroup row name='trade-checkbox-group'>
                        <FormControlLabel
                          value='SELL' control={<Checkbox
                            checked={selectedTrdCode.sell} onChange={(event) => setSelectedTrdCode({
                              ...selectedTrdCode,
                              [event.target.name]: event.target.checked
                            })}
                                                />} label='Sell' name='sell'
                        />
                        <FormControlLabel
                          value='BUY' control={<Checkbox
                            checked={selectedTrdCode.buy} onChange={(event) => setSelectedTrdCode({
                              ...selectedTrdCode,
                              [event.target.name]: event.target.checked
                            })}
                                               />} label='Buy' name='buy'
                        />
                      </FormGroup>
                    </FormControl>
                  </>
                  : <></>
            }

          </Box>
          {value === 'Blotter' && liveCashInPercent !== null && liveCash !== null
            ? <Typography position='absolute' display='flex' right={30} top={70} fontFamily='Open Sans' fontWeight={600}>
              <Typography color='#74788d'>Live Cash in Port: </Typography>
              <Typography color={liveCashInPercent !== null && liveCashInPercent < 0.5 ? 'red' : '#34475a'} ml={1}>{liveCash !== null ? liveCash < 0 ? `-$${formatCurrency(Math.abs(liveCash))}` : `$${formatCurrency(liveCash)}` : ''}</Typography>
              <Typography color={liveCashInPercent !== null && liveCashInPercent < 0.5 ? 'red' : '#34475a'} ml={1}>{liveCashInPercent !== null ? `(${liveCashInPercent?.toFixed(2)}%)` : ''}</Typography>
            </Typography>
            : ''}
          <Divider sx={{
            bgcolor: 'rgba(116, 120, 141, 0.15)'
          }}
          />
        </Box>
        <StyledTradePopupBox
          className='table-responsive'
          sx={{
            height: '650px',
            maxHeight: '700px',
            scrollbarGutter: 'stable'
          }}
        >
          {filteredTradeDetailsTab?.map((item, index) => (
            <TabPanel
              key={index}
              index={index}
              value={item}
              sx={{ padding: 0 }}
            >
              {renderTabPanel(item)}
            </TabPanel>
          ))}
        </StyledTradePopupBox>
      </TabContext>
    </>
  )
}

const CustomGridTreeDataGroupingCell = (props) => {
  const { id, field, rowNode } = props
  const apiRef = useGridApiContext()
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector
  )
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0

  const handleClick = (event) => {
    if (rowNode.type !== 'group') {
      return
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
    apiRef.current.setCellFocus(id, field)
    event.stopPropagation()
  }

  return (
    <Box sx={{ ml: rowNode.depth * 4 }}>
      <div>
        {filteredDescendantCount > 0
          ? (
            <IconButton onClick={handleClick} tabIndex={-1} size='small'>
              {rowNode.childrenExpanded ? <Remove fontSize='small' /> : <Add fontSize='small' />}
            </IconButton>
            )
          : (
            <span />
            )}
      </div>
    </Box>
  )
}

export default ViewTradeDetails
