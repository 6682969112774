import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { API } from 'aws-amplify'
import { Box, Button, Card, CardContent, Grid, Paper, Typography } from '@mui/material'
import { ThemeProvider, createTheme, styled } from '@mui/material/styles'
import * as Sentry from '@sentry/react'
import { ErrorBoundary } from 'react-error-boundary'
import { v4 as uuidv4 } from 'uuid'
import { RefreshOutlined } from '@mui/icons-material'
import dayjs from 'dayjs'
import { useAuth } from '../../contexts/AuthContext'
import { useErrorToast } from '../../hooks/useErrorToast'
import ErrorFallback from '../ErrorFallback'
import Loader from '../Loader'
import { assetAllocationDataProcess, constraintDataProcess, factorTiltsDataProcess, industryDataProcess, instrRestrictionDataProcess, instrSubstitutionDataProcess, methodologyDataProcess, performanceDataProcess, benchmarkDataProcess, sectorDataProcess, tradeListDataProcess, transitionPortfolioDataProcess } from './DataFormatting'
import './ReportPage.css'
import { AccountInformation } from './components/AccountInformation'
import { AccountTranInformation } from './components/AccountTranInformation'
import { DownloadReport } from './components/DownloadReport'
import { ReportTab } from './components/ReportTab'
import ScenarioSelection from './components/ScenarioSelection'
import { TaxInformation } from './components/TaxInformation'
import AccountTitle from '../../components/AccountTitle'

const theme = createTheme({
  typography: {
    fontFamily: 'Open Sans'
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: 'Open Sans',
          backgroundColor: 'rgba(23, 92, 194, 0.8)',
          color: 'white',
          fontSize: '12px',
          fontWeight: '400'
        }
      }
    }
  }
})

theme.typography.h6 = {
  fontSize: '20px',
  '@media (min-width:600px)': {
    fontSize: '24px'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '30px'
  }
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  boxShadow: 'none'
}))

export const methodologyContext = React.createContext()
export const tradeListContext = React.createContext()
export const allocationContext = React.createContext()
export const instrRestrictionContext = React.createContext()
export const sectorContext = React.createContext()
export const industryContext = React.createContext()
export const instrSubstitutionContext = React.createContext()
export const factorTiltsContext = React.createContext()
export const accountDataContext = React.createContext()
export const assetAllocationContext = React.createContext()
export const performanceDataContext = React.createContext()
export const benchmarkDataContext = React.createContext()
export const metricDataContext = React.createContext()
export const summaryDataContext = React.createContext()
export const portfolioDataContext = React.createContext()
export const portfolioReportDataContext = React.createContext()
export const taxSavingDataContext = React.createContext()
export const positionDataContext = React.createContext()
export const policyDataContext = React.createContext()
export const taxManagementContext = React.createContext()

const transitionReportTabs = ['Summary', 'Allocation', 'Performance', 'Trade List', 'Portfolio', 'Policy', 'Methodology']
const tradingReportTabs = ['Summary', 'Allocation', 'Performance', 'Portfolio', 'Policy', 'Methodology']

const ReportPage = () => {
  const [methodologyData, setMethodologyData] = useState([])
  const [tradeListData, setTradeListData] = useState([])
  const [instrRestrictionData, setInstrRestrictionData] = useState([])
  const [sectorData, setSectorData] = useState([])
  const [industryData, setIndustryData] = useState([])
  const [instrSubstitutionData, setInstrSubstitutionData] = useState([])
  const [factorTiltsData, setFactorTiltsData] = useState([])
  const [policyData, setPolicyData] = useState([])

  const [accountData, setAccountData] = useState({})
  const [assetAllocationData, setAssetAllocationData] = useState([])
  const [performanceData, setPerformanceData] = useState([])
  // const [performanceDate, setPerformanceDate] = useState([dayjs.utc('2023-10-01'), dayjs.utc()])
  const [metricData, setMetricData] = useState([])
  const [benchmarkReturnsData, setBenchmarkReturnsData] = useState([])
  const [taxManagementData, setTaxManagementData] = useState([])
  const [isSummaryDataPresent, SetIsSummaryDataPresent] = useState({ accountData: [], optimizationData: [] })
  const [summaryData, setSummaryData] = useState([])
  const [portfolioData, setPortfolioData] = useState([])
  const [isSummaryLoading, setIsSummaryLoading] = useState(true)
  const [transitionPortfolioData, setTransitionPortfolioData] = useState([])
  const [selectedScenario, setSelectedScenario] = useState('')
  const [taxSavingData, setTaxSavingData] = useState(null)
  const [positionData, setPositionData] = useState(null)
  const [isRetrying, setIsRetrying] = useState(false)
  const [isTaxSavingAPIRetrying, setIsTaxSavingAPIRetrying] = useState(false)
  const [isDoneRetrying, setIsDoneRetrying] = useState(false)
  // const [clearCacheFlag, setClearCacheFlag] = useState(false)
  const { showError } = useErrorToast()
  const navigate = useNavigate()
  const screen = 'not main'
  const { user } = useAuth()
  const params = useParams()

  const [errorMessage, setErrorMessage] = useState('')

  // const changePerformanceDate = (date) => {
  //   setPerformanceDate(date)
  // }
  // const datasecure = localStorage.getItem('object')
  // const getStoreData = JSON.parse(datasecure) || []
  // const filterSecuredata = getStoreData?.filter(
  //   (item) => item?.accountId === params?.accountId
  // ) || []

  // const fetchSummaryData = () => {
  //   if (params.RequestId) {
  //     return API.get('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/get-summary-data/${params?.accountId}`, { queryStringParameters: { requestId: params?.RequestId } })
  //   } else {
  //     return API.get('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/get-summary-data/${params?.accountId}`)
  //   }
  //

  // const isTradingAccount = (filterSecuredata && filterSecuredata[0] && filterSecuredata[0]?.accountType === 'TRADING')

  // const [summaryQuery] = useQueries({
  // queries: [
  //     {
  //       queryKey: ['report-summary', params?.accountId],
  //       queryFn: fetchSummaryData,
  //       enabled: user !== null, // dependent condition
  //       retry: false, // prevent retry if it fails,
  //       ...{
  //         ...isTradingAccount ? {} : { staleTime: 0 } // add stale time for transition account so it will refetch data every time we visit the page
  //       }
  //     },
  // ]
  // })

  // useEffect(() => {
  //   setIsLoading(summaryQuery?.isFetching)
  //   if (summaryQuery?.status === 'success') {
  //     if (summaryQuery?.data) {
  //       if (summaryQuery?.data?.data) {
  //         setSummaryData(summaryQuery?.data)
  //         SetIsSummaryDataPresent(summaryQuery?.data?.data)
  //         if (!summaryQuery?.data?.data?.length) {
  //           setErrorMessage(summaryQuery?.data?.message)
  //         }
  //       }
  //     }
  //   } else if (summaryQuery?.status === 'error') {
  //     showError(summaryQuery?.error?.response?.data?.errorInfo?.userMessage || summaryQuery?.error?.message)
  //     Sentry.captureException(summaryQuery?.error?.response?.data?.errorInfo?.userMessage || summaryQuery?.error)
  //   }
  // }, [summaryQuery?.isFetching])

  const openExpandedView = (chartName, data) => {
    let chartData = {
      data,
      accountId: params?.accountId,
      strategyId: (accountData?.data?.accReportData?.length > 0 && accountData?.data?.accReportData[0]?.strategyId) || '',
      selectedBenchmark: ([accountData?.data?.accReportData?.length > 0 && accountData?.data?.accReportData[0]?.strategyId]) || '',
      accountType: (accountData?.data?.accReportData?.length > 0 && accountData?.data?.accReportData[0]?.accountType) || '',
      startDate: benchmarkReturnsData?.startDate,
      endDate: benchmarkReturnsData?.endDate,
      strategyList: benchmarkReturnsData?.strategyList,
      calculatedMvPlusCashData: metricData?.calculatedMvPlusCashData,
      selectedAccountIds: [{ accountId: params?.accountId }]
    }
    if (chartName === 'MV') {
      // set present annotation data as selected annotation
      const annotationsArray = metricData?.marketData ? metricData?.marketData?.flatMap(entry => ({ annotations: entry.metric })) : []
      const uniqueAnnotations = [...new Set(annotationsArray.flatMap(entry => Object.keys(entry.annotations)))]
      chartData = {
        ...chartData,
        annotationList: uniqueAnnotations,
        selectedAnnotations: uniqueAnnotations
      }
    }
    navigate(`/account-review/expanded-charts/${params?.accountId}`, {
      state: {
        chartName,
        chartData
      }
    })
  }

  const getTradeList = async (clearCacheFlag = false) => {
    try {
      let response
      if (params?.RequestId) {
        response = await API.get('baseUriReport', `reporting/v1/${user?.userGroup}/trade-list/${params?.accountId}`, { queryStringParameters: { requestId: params?.RequestId, ...(clearCacheFlag ? { 'cache-override': true } : {}) } })
      } else {
        response = await API.get('baseUriReport', `reporting/v1/${user?.userGroup}/trade-list/${params?.accountId}`,
          { queryStringParameters: clearCacheFlag ? { 'cache-override': true } : {} }
        )
      }
      setTradeListData(tradeListDataProcess(response?.data))
    } catch (error) {
      showError(error.response?.data?.errorInfo?.userMessage || error.message)
      Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
    }
  }

  const getAccountData = async (clearCacheFlag = false) => {
    setIsSummaryLoading(true)
    try {
      const response = await API.get(
        'baseUriReport',
        `reporting/v1/${user?.userGroup}/account-report/${params?.accountId}`,
        {
          queryStringParameters: {
            ...(params?.RequestId ? { requestId: params?.RequestId } : {}),
            ...(clearCacheFlag ? { 'cache-override': true } : {})
          }
        }
      )
      setAccountData(response)
      if (user && response?.data) {
        const accReportData = response?.data?.accReportData || []
        const optId = (accReportData?.length > 0 && accReportData[0]?.optId) || ''
        const strategyId = (accReportData?.length > 0 && accReportData[0]?.strategyId) || ''
        const accountType = (accReportData?.length > 0 && accReportData[0]?.accountType) || ''
        if (accReportData?.length === 0) {
          setIsSummaryLoading(false)
          setErrorMessage(response?.message)
        }
        if (optId) {
          getSummaryData(optId, clearCacheFlag)
          getPolicyData(clearCacheFlag)
          getPerformanceData(clearCacheFlag)
          getAssetAllocation(clearCacheFlag)
          if (!clearCacheFlag) {
            getTopAssignedModels()
            getBenchmarkData(strategyId)
            getMetricData()
            getTaxManagementData(strategyId)
          }
          if (accountType === 'TRANSITION') {
            getTransitionPortfolioData()
            getTradeList(clearCacheFlag)
          } else if (accountType === 'TRADING') {
            getPortfolioAssetData(clearCacheFlag)
            getPositionReport(clearCacheFlag)
            getTaxSavingReport(clearCacheFlag)
          }
        } else {
          setIsSummaryLoading(false)
        }
      }
    } catch (error) {
      setIsSummaryLoading(false)
      showError(error.response?.data?.errorInfo?.userMessage || error.message)
      Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
    }
  }

  const getAssetAllocation = async (clearCacheFlag = false) => {
    try {
      let response
      if (params?.RequestId) {
        response = await API.get('baseUriReport', `reporting/v1/${user?.userGroup}/asset-allocations/${params?.accountId}`, { queryStringParameters: { requestId: params?.RequestId, ...(clearCacheFlag ? { 'cache-override': true } : {}) } })
      } else {
        response = await API.get('baseUriReport', `reporting/v1/${user?.userGroup}/asset-allocations/${params?.accountId}`,
          { queryStringParameters: clearCacheFlag ? { 'cache-override': true } : {} }
        )
      }
      setAssetAllocationData(assetAllocationDataProcess(response))
    } catch (error) {
      showError(error.message)
      Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
    }
  }

  const getPerformanceData = async (clearCacheFlag = false) => {
    try {
      const response = await API.post('baseUriReport', `reporting/v1/${user?.userGroup}/performance/${params?.accountId}`, {
        queryStringParameters: clearCacheFlag ? { 'cache-override': true } : {},
        body: {}
      }
      )
      if (response?.data) {
        setPerformanceData(performanceDataProcess(response))
      }
    } catch (error) {
      showError(error.response?.data?.errorInfo?.userMessage || error.message)
      Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
    }
  }

  const getBenchmarkData = async (strategyId) => {
    try {
      const response = await API.post('baseUriReport', `reporting/v1/${user?.userGroup}/benchmark-returns/account/${params?.accountId}/strategy/${strategyId}`, { body: {} })
      if (response?.data) {
        setBenchmarkReturnsData(benchmarkDataProcess(response))
      }
    } catch (error) {
      showError(error.response?.data?.errorInfo?.userMessage || error.message)
      Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
    }
  }

  const getMetricData = async () => {
    try {
      const response = await API.post('baseUriReport', `reporting/v1/${user?.userGroup}/metric-data/${params?.accountId}`, { body: {} })
      if (response?.data) {
        setMetricData(response?.data)
      }
    } catch (error) {
      showError(error.response?.data?.errorInfo?.userMessage || error.message)
      Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
    }
  }

  const getTaxManagementData = async (strategyId) => {
    try {
      const response = await API.get('baseUriReport', `reporting/v1/${user?.userGroup}/tax-management/account/${params?.accountId}/strategy/${strategyId}`)
      if (response?.success === true) {
        if (response?.data) {
          setTaxManagementData(response?.data)
        }
      }
    } catch (error) {
      showError(error.response?.data?.errorInfo?.userMessage || error.message)
      Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
    }
  }

  const getSummaryData = async (optId, clearCacheFlag = false) => {
    try {
      setIsSummaryLoading(true)
      const response = await API.get(
        'baseUriReport',
        `reporting/v1/${user?.userGroup}/get-summary-data/${params?.accountId}`,
        {
          queryStringParameters: {
            ...(clearCacheFlag ? { 'cache-override': true } : {}),
            ...(optId ? { 'opt-id': optId } : {})
          }
        }
      )
      setSummaryData(response)
      SetIsSummaryDataPresent(response?.data)
      if (!response?.data?.length) {
        setErrorMessage(response?.message)
      }
    } catch (error) {
      setIsSummaryLoading(false)
      showError(error.message)
      Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
    } finally {
      setIsSummaryLoading(false)
    }
  }

  const getPortfolioAssetData = async (clearCacheFlag = false) => {
    try {
      const response = await API.get('baseUriReport', `reporting/v1/${user?.userGroup}/portfolios/${params?.accountId}`,
        { queryStringParameters: clearCacheFlag ? { 'cache-override': true } : {} }
      )
      setPortfolioData(response?.data?.portfolioData ? ({ ...response?.data, portfolioData: response?.data?.portfolioData?.map((item) => ({ ...item, id: uuidv4() })) }) : ({}))
    } catch (error) {
      showError(error.response?.data?.errorInfo?.userMessage || error.message)
      Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
    }
  }

  const getTopAssignedModels = async () => {
    try {
      const response = await API.get('baseUriReport', `reporting/v1/${user?.userGroup}/report/methodology/TR`
      )
      setMethodologyData(methodologyDataProcess(response))
    } catch (error) {
      showError(error.response?.data?.errorInfo?.userMessage || error.message)
      Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
    }
  }

  // clear the state when the account Id or request Id changes
  useEffect(() => {
    setAccountData({})
    setSummaryData([])
    SetIsSummaryDataPresent({})
  }, [params?.accountId, params?.RequestId])

  const getTransitionPortfolioData = () => {
    const optId = accountData?.data?.accReportData?.length > 0 ? accountData?.data?.accReportData[0].optId : ''
    API
      .get('baseUriReport', `reporting/v1/${user?.userGroup}/transition-portfolio-report/account/${params?.accountId}/opt/${optId}`)
      .then(response => {
        if (response?.data) {
          setTransitionPortfolioData(transitionPortfolioDataProcess(response))
        }
      }).catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const getPolicyData = (clearCacheFlag = false) => {
    API
      .get('baseUriReport', `reporting/v1/${user?.userGroup}/policy/${params?.accountId}`,
        { queryStringParameters: clearCacheFlag ? { 'cache-override': true } : {} }
      )
      .then(response => {
        if (response?.data) {
          setPolicyData(response?.data)
          setSectorData(sectorDataProcess(response?.data?.sectorDataLabel))
          setIndustryData(industryDataProcess(response?.data?.industryDataLabel))
          setInstrRestrictionData(instrRestrictionDataProcess(response?.data?.restrictionDataLabel))
          setFactorTiltsData(factorTiltsDataProcess(response?.data?.accountFactorDataLabel))
          setInstrSubstitutionData(instrSubstitutionDataProcess(response?.data?.substitutionDataLabel))
        }
      }).catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const getTaxSavingReport = (clearCacheFlag = false) => {
    let retryCount = 3
    const retryDelay = 5000
    const fetchData = () => {
      API
        .get('baseUriReport', `reporting/v1/${user?.userGroup}/tax-savings/${params?.accountId}`,
          { queryStringParameters: clearCacheFlag ? { 'cache-override': true } : {} }
        )
        .then(response => {
          if (response?.data) {
            setIsTaxSavingAPIRetrying(false)
            setTaxSavingData(response?.data)
          }
        }).catch(error => {
          setIsTaxSavingAPIRetrying(true)
          showError(error.response?.data?.errorInfo?.userMessage || error.message)
          Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
          // Retry logic
          if (retryCount > 0) {
            retryCount--
            setTimeout(fetchData, retryDelay)
          } else {
            setIsTaxSavingAPIRetrying(false)
          }
        })
    }
    fetchData()
  }

  const getPositionReport = (clearCacheFlag = false) => {
    let retryCount = 3
    const retryDelay = 5000
    const fetchData = () => {
      API
        .get('baseUriReport', `reporting/v1/${user?.userGroup}/position-report/${params?.accountId}`, {
          queryStringParameters: clearCacheFlag ? { 'cache-override': true } : {}
        })
        .then(response => {
          if (response?.data) {
            setIsRetrying(false)
            setPositionData(response?.data)
          }
        })
        .catch(error => {
          setIsRetrying(true)
          showError(error.response?.data?.errorInfo?.userMessage || error.message)
          Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)

          // Retry logic
          if (retryCount > 0) {
            retryCount--
            setTimeout(fetchData, retryDelay)
          } else {
            setIsRetrying(false)
            setIsDoneRetrying(true)
          }
        })
    }
    fetchData()
  }

  const changeScenario = (scenarioName) => {
    setSelectedScenario(scenarioName)
  }
  useEffect(() => {
    if (user) {
      getAccountData()
    }
  }, [user, params?.accountId, params?.requestId])

  // refresh report data
  const refreshReportData = () => {
    getAccountData(true)
  }

  return (
    <>
      <Box sx={{ flexGrow: 1, mt: 2 }} className='reportPage'>
        <AccountTitle title='Report'>
          <Button
            variant='outlined'
            onClick={refreshReportData}
            sx={{
              border: '2px solid #dee2e6',
              padding: 1,
              minWidth: 'auto',
              ':hover': {
                background: 'transparent',
                border: '2px solid #dee2e6'
              },
              marginBottom: '16px',
              marginTop: '8px',
              marginRight: '8px'
            }}
          >
            <RefreshOutlined sx={{ height: 20, width: 20, color: '#74788D' }} />
          </Button>
        </AccountTitle>
        {isSummaryLoading
          ? <Loader />
          : <>
            {isSummaryDataPresent?.optimizationData?.length > 0
              ? <ThemeProvider theme={theme}>
                <Grid container item spacing={3}>
                  <Grid item xs={12} sm={9} order={{ xs: 2, sm: 1 }}>
                    <Grid container item spacing={3}>
                      <Grid item xs={12} order={{ xs: 1, sm: 1 }}>
                        <Item>
                          <summaryDataContext.Provider value={summaryData}>
                            <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
                              <AccountTranInformation />
                            </ErrorBoundary>
                          </summaryDataContext.Provider>
                        </Item>
                      </Grid>
                      <Grid item xs={12} order={{ xs: 4, sm: 3 }}>
                        <Card p={4} className='card-layout'>
                          <CardContent sx={{ backgroundColor: 'white', marginTop: '-0.8em' }}>
                            {accountData?.data?.length === 0
                              ? <Typography mt={2}>No Report Available</Typography>
                              : <Item>
                                <methodologyContext.Provider value={methodologyData}>
                                  <tradeListContext.Provider value={tradeListData}>
                                    <assetAllocationContext.Provider value={assetAllocationData}>
                                      <sectorContext.Provider value={sectorData}>
                                        <industryContext.Provider value={industryData}>
                                          <instrRestrictionContext.Provider value={instrRestrictionData}>
                                            <instrSubstitutionContext.Provider value={instrSubstitutionData}>
                                              <factorTiltsContext.Provider value={factorTiltsData}>
                                                <policyDataContext.Provider value={policyData}>
                                                  <taxManagementContext.Provider value={taxManagementData}>
                                                    <performanceDataContext.Provider value={{ performanceData }}>
                                                      <metricDataContext.Provider value={{ metricData }}>
                                                        <benchmarkDataContext.Provider value={{ benchmarkReturnsData }}>
                                                          <accountDataContext.Provider value={{ ...((accountData?.data?.accReportData && accountData?.data?.accReportData[0]) || {}), openExpandedView }}>
                                                            <summaryDataContext.Provider value={summaryData}>
                                                              <taxSavingDataContext.Provider value={taxSavingData}>
                                                                <portfolioDataContext.Provider value={portfolioData}>
                                                                  <portfolioReportDataContext.Provider value={transitionPortfolioData}>
                                                                    {(accountData?.data?.accReportData && accountData?.data?.accReportData[0]?.accountType === 'TRADING' && (
                                                                      <ReportTab repTabs={tradingReportTabs} selectedScenario={selectedScenario} />
                                                                    ))}
                                                                    {(accountData?.data?.accReportData && accountData?.data?.accReportData[0]?.accountType === 'TRANSITION' && (
                                                                      <ReportTab repTabs={transitionReportTabs} selectedScenario={selectedScenario} />
                                                                    ))}
                                                                  </portfolioReportDataContext.Provider>
                                                                </portfolioDataContext.Provider>
                                                              </taxSavingDataContext.Provider>
                                                            </summaryDataContext.Provider>
                                                          </accountDataContext.Provider>
                                                        </benchmarkDataContext.Provider>
                                                      </metricDataContext.Provider>
                                                    </performanceDataContext.Provider>
                                                  </taxManagementContext.Provider>
                                                </policyDataContext.Provider>
                                              </factorTiltsContext.Provider>
                                            </instrSubstitutionContext.Provider>
                                          </instrRestrictionContext.Provider>
                                        </industryContext.Provider>
                                      </sectorContext.Provider>
                                    </assetAllocationContext.Provider>
                                  </tradeListContext.Provider>
                                </methodologyContext.Provider>
                                </Item>}
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={3} order={{ xs: 1, sm: 2 }}>
                    <Grid container item spacing={3}>
                      <Grid item xs={12} order={{ xs: 2, sm: 2 }}>
                        <Item>
                          <accountDataContext.Provider value={(accountData?.data?.accReportData && accountData?.data?.accReportData[0])}>
                            <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
                              <AccountInformation />
                            </ErrorBoundary>
                          </accountDataContext.Provider>
                        </Item>
                      </Grid>
                      <Grid item xs={12} order={{ xs: 3, sm: 4 }}>
                        <Item>
                          <accountDataContext.Provider value={(accountData?.data?.accReportData && accountData?.data?.accReportData[0])}>
                            <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
                              <TaxInformation />
                            </ErrorBoundary>
                          </accountDataContext.Provider>
                        </Item>
                      </Grid>
                      {(user?.userGroup === 'adv-classic' || user?.userGroup === 'spn-pm') && summaryData?.data?.showOptReport === false
                        ? ''
                        : <Grid item xs={12} order={{ xs: 3, sm: 4 }}>
                          <Item>
                            <accountDataContext.Provider value={(accountData?.data?.accReportData && accountData?.data?.accReportData[0])}>
                              <summaryDataContext.Provider value={summaryData}>
                                <ScenarioSelection changeScenario={changeScenario} />
                              </summaryDataContext.Provider>
                            </accountDataContext.Provider>
                          </Item>
                          </Grid>}
                      <Grid item xs={12} order={{ xs: 3, sm: 4 }}>
                        <Item>
                          <accountDataContext.Provider value={(accountData?.data?.accReportData && accountData?.data?.accReportData[0])}>
                            <taxSavingDataContext.Provider value={taxSavingData}>
                              <positionDataContext.Provider value={positionData}>
                                <DownloadReport isRetrying={isRetrying} isTaxSavingAPIRetrying={isTaxSavingAPIRetrying} isDoneRetrying={isDoneRetrying} optId={accountData?.data?.accReportData?.length > 0 ? accountData?.data?.accReportData[0]?.optId : ''} />
                              </positionDataContext.Provider>
                            </taxSavingDataContext.Provider>
                          </accountDataContext.Provider>
                        </Item>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                </ThemeProvider>
              : <>
                <Box className='reportPage'>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '300px'
                    }}
                  >
                    <Typography variant='h6' className='no-report'>
                      {errorMessage || 'No report available'}
                    </Typography>

                    {errorMessage
                      ? <Link to={'/account-review/request/' + params?.accountId}>
                        <Button
                          variant='contained'
                          sx={{
                            mt: '20px',
                            paddingLeft: '50px',
                            paddingRight: '50px'
                          }}
                        >
                          Go to Requests
                        </Button>
                      </Link>
                      : ''}
                  </Box>
                </Box>
              </>}
          </>}
      </Box>
    </>
  )
}
export default ReportPage
