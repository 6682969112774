import React from 'react'
import { useNavigate } from 'react-router-dom'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Stack, Tooltip, Typography } from '@mui/material'
import { formatCurrency } from '../../../utils/FormateCurrenyInMilion'

const GroupCard = ({ borderColor, setSelectedGroup, data }) => {
  const navigate = useNavigate()
  const handleViewDetails = () => {
    if (data?.groupType === 'ENTITYMAP') {
      navigate(`/aggregate/entity-mapping/${data?.aggGroupId}`)
    }
    else {
      setSelectedGroup({ groupType: data?.groupType, aggGroupCd: data?.aggGroupCd, aggGroupId: data?.aggGroupId, aggGroupName: data?.aggGroupName, groupLabel: data?.groupLabel })
    }
  }

  const renderAccountDataChips = () => {
    const elements = []
    for (let i = 0; i < data?.members?.length; i++) {
      const amount = data.members[i]?.marketValue
      const name = data.members[i]?.accountName
      elements.push(
        <Box key={i} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '10px' }}>
          <Tooltip placement='top' title={name ? name : ''}>
            <Typography sx={{ fontSize: '11px', color: '#34475A', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{name}</Typography>
          </Tooltip>
          <Tooltip placement='top' title={amount !== undefined && amount !== null ? (amount >= 0 ? '$' + amount?.toFixed(2) : '-$' + Math.abs(amount)?.toFixed(2)) : ''}>
            <Typography sx={{ fontSize: '11px', color: '#34475A' }}>{amount !== null && amount !== undefined ? amount >= 0 ? '$' + formatCurrency(amount) : '-$' + formatCurrency(amount) : 'NA'}</Typography>
          </Tooltip>
        </Box>
      )
    }
    return elements
  }

  return (
    <>
      <Box mb={2}>
        <Accordion
          sx={{
            '.MuiAccordionSummary-expandIconWrapper': {
              display: 'none'
            },
            borderLeft: `5px solid ${borderColor}`,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            sx={{ height: '70px' }}
          >
            <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
              <Box
                display='flex' justifyContent='space-between' width='100%'
              >
                <Typography sx={{ color: '#002A59', fontFamily: 'Open Sans', fontSize: '16px', fontWeight: 400 }}>{data?.groupLabel}</Typography>
                <Typography sx={{ color: '#3B79BF', fontFamily: 'Open Sans', fontSize: '16px', fontWeight: 600 }}>{data?.marketValue !== null ? data?.marketValue >= 0 ? '$' + formatCurrency(data?.marketValue) : '-$' + formatCurrency(data?.marketValue) : 'NA'}</Typography>
              </Box>
              <Typography sx={{ fontSize: '12px', color: '#727474', textAlign: 'left' }}>{data?.aggGroupName}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <Stack direction='column' spacing={1}>
                {renderAccountDataChips()}
              </Stack>
              <Button onClick={handleViewDetails} variant='contained' sx={{ marginTop: '5px', width: '100%', textTransform: 'capitalize' }}>View Details</Button>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  )
}

export default GroupCard
