import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { API } from 'aws-amplify'
import AddIcon from '@mui/icons-material/Add'
import { Autocomplete, Box, Button, FormControl, Grid, Modal, TextField, Tooltip, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useGridApiContext } from '@mui/x-data-grid'
import * as Sentry from '@sentry/react'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import utc from 'dayjs/plugin/utc'
import { useAuth } from '../../contexts/AuthContext'
import { useErrorToast } from '../../hooks/useErrorToast'
import Loader from '../Loader'
import DataTable from './components/DataTable'
import { moduleConfig } from '../../contexts/data'
import { ACCESS_LEVEL } from '../../contstants/constants'
import { checkInstrumentSearchQuery } from '../../utils/searchQueryUtils'

dayjs.extend(isSameOrAfter) // Extend dayjs with the isSameOrAfter plugin
dayjs.extend(utc)

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  width: '500px',
  borderRadius: '4px'
}

export default function PersonalizationSubstitutionRestrictions (props) {
  const { showError } = useErrorToast()
  const { user } = useAuth()
  const params = useParams()
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const [startDate, setStartDate] = useState(dayjs.utc().startOf('day')) // Use UTC for initial value
  const [endDate, setEndDate] = useState(dayjs.utc().add(1, 'day').startOf('day')) // Use UTC for initial value
  const [selectedValues, setSelectedValues] = useState([])
  const [customErrors, setCustomErrors] = useState({})
  const [sourceOptions, setSourceOptions] = useState([])
  const [sourceName, setSourceName] = useState([])
  const [isLoadingSource, setIsLoadingSource] = useState(false)
  const [errorName, setErrorName] = useState(false)
  const [errorCode, setErrorCode] = useState(false)
  const [rows, setRows] = React.useState(props.substitutionData || [])
  const [targetValue, setTargetValue] = useState('')
  const [sourceValue, setSourceValue] = useState('')
  const [errorEndDate, setErrorEndDate] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const { checkAccess } = useAuth()
  const dataSecure = localStorage.getItem('object')
  const getStoreData = dataSecure ? JSON.parse(dataSecure) : []
  const filterSecureData = getStoreData?.filter((item) => item?.accountId === params?.accountId)

  const handleClose = () => {
    setOpen(false)
    setErrorName(false)
    setErrorCode(false)
    setSourceValue('')
    setTargetValue('')
    setSelectedValues([])
    setStartDate(dayjs.utc().startOf('day'))
    setEndDate(dayjs.utc().add(1, 'day').startOf('day'))
    setIsLoading(false)
  }
  const addSubstitutionRestrictions = async () => {
    setIsLoading(true)
    const id = randomId()
    try {
      const data = {
        sourceInstrId: sourceValue.instrId,
        targetInstrId: targetValue.instrId,
        startDate: startDate.toISOString().slice(0, 10),
        endDate: endDate.toISOString().slice(0, 10)
      }

      const response = await API.post(
        'baseUriAccountOptimization',
        `account-customization/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/${params.accountId}/instr-substitution`,
        { body: { ...data } }
      )
      if (response.success && response?.data && response?.data?.length) {
        const updatedSubstitutionList = [{ id, ...response?.data[0] }, ...rows]
        setRows(updatedSubstitutionList)
        props?.onData && props?.onData(updatedSubstitutionList)
        handleClose()
      }
    } catch (error) {
      if (Array.isArray(error.response?.data?.errorInfo?.userMessage)) {
        error.response?.data?.errorInfo?.userMessage?.forEach((element) => {
          showError(element.message)
        })
      } else {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
      }
      setIsLoading(false)
    }
  }

  const handleConfirmEdit = (row) => {
    setIsLoading(true)
    if (row.endDate) {
      const payload = {
        startDate: row.startDate?.slice(0, 10),
        endDate: row.endDate
      }
      API
        .patch('baseUriAccountOptimization', `account-customization/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/${row.accountId}/instr-substitution/${row.sourceInstrId}/${row.targetInstrId}`, { body: { ...payload } })
        .then(response => {
          if (response.data && response.success) {
            const updatedList = rows.map(item => {
              if (item.accountId === row.accountId && item.instrId === row.instrId && item.restrictionId === row.restrictionId && item.sourceInstrId === row.sourceInstrId && item.targetInstrId === row.targetInstrId) {
                return { ...item, startDate: row.startDate, endDate: row.endDate }
              }
              return item
            })
            setRows(updatedList)
            props?.onData && props?.onData(updatedList)
          }
          setIsLoading(false)
        }).catch(error => {
          showError(error.response?.data?.errorInfo?.userMessage || error.message)
          Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
    }
  }

  const onInputChange = (event) => {
    const query = (event?.target?.value || '').toString().trim()
    if (query === '') {
      setSourceName([])
    }
    if (sourceName?.length === 0 && query === '') {
      setCustomErrors({ sourceName: { message: 'Source Name required' } })
    } else {
      if (checkInstrumentSearchQuery(query)) {
        setIsLoadingSource(true)
        API.get('baseUriTransactionalMaster', `transactional-master/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/instruments`, {
          queryStringParameters: { search: query }
        })
          .then((response) => {
            if (response?.data) {
              setSourceOptions([...response?.data])
              const temp = customErrors
              delete temp.sourceName
              setCustomErrors(temp)
            }
          })
          .catch((error) => {
            showError(error?.response?.data?.errorInfo?.userMessage || error.message)
            Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
          })
          .finally(() => {
            setIsLoadingSource(false)
          })
      }
    }
  }

  const onSourceNameChange = (query, newValue) => {
    setIsLoadingSource(true)
    setSourceValue(newValue)
    if (newValue?.name) {
      setErrorName(false)
    }
    setSelectedValues([...selectedValues, newValue])
  }

  const onTargetNameChange = (query, newValue) => {
    setIsLoadingSource(true)
    setTargetValue(newValue)
    if (newValue?.name) {
      setErrorCode(false)
    }
    setSelectedValues([...selectedValues, newValue])
  }

  const submitSecurityHandler = (event) => {
    if (sourceValue.name && targetValue.name && (errorEndDate === null)) {
      event.preventDefault()
      setErrorName(false)
      setErrorCode(false)
      addSubstitutionRestrictions()
    } else {
      if (!sourceValue.name) {
        setErrorName(true)
      }
      if (!targetValue.name) {
        setErrorCode(true)
      }
    }
  }

  const columns = [
    {
      field: 'sourceName',
      headerName: 'Securities',
      flex: 1,
      editable: false
    },
    {
      field: 'targetName',
      headerName: 'Target Substitutes',
      flex: 1,
      editable: false
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      flex: 1,
      editable: false,
      valueFormatter: (params) =>
        params.value ? dayjs.utc(params.value).format('ddd MMM DD YYYY') : '--------'
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      flex: 1,
      editable: true,
      valueFormatter: (params) => params.value ? dayjs.utc(params.value).format('ddd MMM DD YYYY') : '--------',
      renderEditCell: (params) => <CustomEditDate {...params} minDate={dayjs.utc().format('YYYY-MM-DD')} />,
      preProcessEditCellProps: (params) => {
        const currentDate = dayjs().startOf('day').unix() * 1000
        const startDate = dayjs(params.row.startDate).startOf('day').unix() * 1000
        const endDate = dayjs(params.props.value).startOf('day').unix() * 1000
        const isEndDateValid = (endDate >= currentDate) && (endDate >= startDate)
        const hasError = !isEndDateValid
        const style = hasError
          ? {
              border: '2px solid red',
              height: '50px'
            }
          : {}
        return { ...params.props, error: hasError, style }
      }
    }
  ]

  return (
    <>
      {isLoading ? <Loader /> : ''}
      <Box
        sx={{
          width: '100%',
          '& .actions': {
            color: 'text.secondary'
          },
          '& .textPrimary': {
            color: 'text.primary'
          },
          '.MuiDataGrid-footerContainer': {
            border: 'none'
          },
          '.MuiDataGrid-root': {
            border: 'none'
          }
        }}
      >
        {
          (
            (filterSecureData && filterSecureData[0]?.accountType === 'TRADING' &&
           checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS,
             { subModuleName: moduleConfig.PERSONALIZATION, component_name: moduleConfig.EDIT_TRADING_PERSONALIZATION })) ||
           ((filterSecureData && filterSecureData[0]?.accountType === 'TRANSITION' &&
            checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS,
              { subModuleName: moduleConfig.PERSONALIZATION, component_name: moduleConfig.EDIT_TRANSITION_PERSONALIZATION })))) && (
                <>
                  <Tooltip
                    disableFocusListener
                    disableTouchListener
                    placement='top'
                    title='Replace securities in the portfolio with certain other preferred securities'
                  >
                    <Button
                      variant='outlined'
                      color='primary'
                      startIcon={<AddIcon />}
                      onClick={handleOpen}
                      sx={{
                        fontFamily: 'Open Sans'
                      }}
                    >
                      Add substitution
                    </Button>
                  </Tooltip>
                  <Modal
                    open={open}
                    onClose={handleClose}
                  >
                    <Box sx={style}>
                      <Typography id='modal-modal-title' variant='h6' sx={{ fontWeight: 400, color: '#34475A', textAlign: 'center' }}>
                        Add Substitution
                      </Typography>
                      <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                        <form>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} sx={{ display: 'flex', alignItems: 'center' }}>
                              <Typography sx={{ paddingRight: '96px', color: '#74788d!important', fontSize: '16px', fontWeight: 500 }}>Security*</Typography>
                              <FormControl error={errorName} fullWidth>
                                <Autocomplete
                                  autoWidth
                                  id='my-autocomplete'
                                  options={sourceOptions}
                                  loading={isLoadingSource}
                                  getOptionLabel={(option) => option.name || ''}
                                  onInputChange={onInputChange}
                                  onChange={onSourceNameChange}
                                  value={sourceValue}
                                  sx={{
                                    width: '250px'
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label='Source Security Name'
                                      variant='outlined'
                                      error={errorCode}
                                      InputProps={{ ...params.InputProps, placeholder: 'Source Security Name' }}
                                      required
                                    />
                                  )}
                                />
                                {customErrors.securityName && (
                                  <Typography color='error' variant='caption'>
                                    {customErrors.securityName.message}
                                  </Typography>
                                )}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} sx={{ display: 'flex', alignItems: 'center' }}>
                              <Typography sx={{ paddingRight: '80px', color: '#74788d!important', fontSize: '16px', fontWeight: 500 }}>Target Substitute*</Typography>
                              <FormControl error={errorCode} fullWidth>
                                <Autocomplete
                                  autoWidth
                                  id='my-autocomplete'
                                  options={sourceOptions}
                                  loading={isLoadingSource}
                                  getOptionLabel={(option) => option.name || ''}
                                  onInputChange={onInputChange}
                                  onChange={onTargetNameChange}
                                  value={targetValue}
                                  sx={{
                                    width: '250px'
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label='Target Security Name'
                                      error={errorCode}
                                      variant='outlined'
                                      InputProps={{ ...params.InputProps, placeholder: 'Target Security Name' }}
                                      required
                                    />
                                  )}
                                />
                                {customErrors.restrictionCode && (
                                  <Typography color='error' variant='caption'>
                                    {customErrors.restrictionCode.message}
                                  </Typography>
                                )}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} sx={{ display: 'flex', alignItems: 'center' }}>
                              <Typography sx={{ paddingRight: '80px', color: '#74788d!important', fontSize: '16px', fontWeight: 500 }}>Start date *</Typography>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  label='Start date *'
                                  value={startDate}
                                  onChange={setStartDate}
                                  required
                                  disablePast
                                  sx={{ width: '250px' }}
                                />
                              </LocalizationProvider>

                            </Grid>
                            <Grid item xs={12} sm={12} sx={{ display: 'flex', alignItems: 'center' }}>
                              <Typography sx={{ paddingRight: '88px', color: '#74788d!important', fontSize: '16px', fontWeight: 500 }}>End date *</Typography>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  label='End date *'
                                  value={endDate}
                                  onChange={
                          setEndDate
                        }
                                  minDate={startDate.add(1, 'day')}
                                  onError={setErrorEndDate}
                                  required
                                  disablePast
                                  sx={{ width: '250px' }}
                                />
                              </LocalizationProvider>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Box sx={{ textAlign: 'center' }} mt={2}>
                              <Button
                                variant='contained'
                                onClick={submitSecurityHandler}
                              >
                                Submit
                              </Button>
                            </Box>
                          </Grid>
                        </form>
                      </Typography>
                    </Box>
                  </Modal>
                </>
          )
        }
        <DataTable data={rows} initialColumns={columns} showPagination editData={handleConfirmEdit} />
      </Box>
    </>
  )
}

const CustomEditDate = (props) => {
  const { id, field, value: valueProp, style, minDate } = props
  const apiRef = useGridApiContext()
  const [value, setValue] = React.useState(valueProp)

  const formatDate = (date) => {
    const parsedDate = date ? date?.split('T')[0] : null
    return parsedDate
  }

  const handleDateChange = (e) => {
    const newValue = e.target.value
    apiRef.current.setEditCellValue({ id, field, value: newValue, debounceMs: 200 })
    setValue(newValue)
  }

  useEffect(() => { setValue(valueProp) }, [valueProp])

  return (
    <input
      type='date'
      value={formatDate(value)}
      onChange={handleDateChange}
      min={minDate}
      style={{ height: '100%', width: '100%', background: '#1976d214', ...style, outline: 'none' }}
    />
  )
}
