import { Box } from '@mui/material'
import React from 'react'
import AllocationChart from '../AllocationChart'
import PortfolioTable from '../PortfolioTable'

const PortfolioAllocationTab = ({ aggregateAccountHierarchy, loading }) => {
  return (
    <Box sx={{ position: 'relative' }}>
      {/* <AllocationChart groupHierarchy={aggregateAccountHierarchy} loading={loading} /> */}
      <PortfolioTable groupHierarchy={aggregateAccountHierarchy} loading={loading} />
    </Box>
  )
}

export default PortfolioAllocationTab