import { Box, Grid } from '@mui/material'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Bar } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
)

export function AllocationVerticalBarChart(props) {
 return (
    <>
      <Grid xs={12} sm={12}>
        <Box className='trade-table-list-header'>{props.chartHeader}</Box>
       <Grid xs={12} container width='100%' justifyContent='center'>
         {Object.values(props.labels).map((label, index) => {
           return (
             <Box alignItems='center' display='flex' justifyContent='center' p={2} key={index}>
               <Box className='label-box' mr={1} sx={{ background: `${props.backgroundColor[index]}` }} />
               <Grid item className='chart-label'>{label}</Grid>
             </Box>
           )
         })}
       </Grid>
        <Bar options={props.options} data={props.data} width={100} height={50} />
      </Grid>
    </>
  )
}
