import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { Box } from '@mui/material'
import { useParams } from 'react-router'
import { API } from 'aws-amplify'
import { useErrorToast } from '../../../../hooks/useErrorToast'
import { useAuth } from '../../../../contexts/AuthContext'
import { useSuccessToast } from '../../../../hooks/useSuccessToast'
import Loader from '../../../Loader'
import TreeComponent from './TreeComponent'
import { harryAndMarryStaticData } from './HarryMarryCallanData'

const AggregateTreeView = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isInitialChart, setIsInitialChart] = useState(false)
  const [aggregateData, setAggregateData] = useState({ nodes: [], edges: [] })
  const { showError } = useErrorToast()
  const { showSuccess } = useSuccessToast()
  const { user } = useAuth()
  const params = useParams()

  const fetchAggregateTreeData = async () => {
    setIsLoading(true)
    if (params.familyId === '55aba481-4927-4505-a562-479c9c2e8c73') {
      setAggregateData(harryAndMarryStaticData)
      setIsLoading(false)
    }
    else {
      API.get(
        'baseUriAggregateMaster',
        `aggregate-master/v1/${user.userGroup}/client/graph/${params?.familyId}`
      )
        .then((response) => {
          if (response?.data && response?.data?.nodes && response?.data?.edges) {
            response?.data?.nodes?.forEach(node => {
              node.height = 120
            });
            setAggregateData(response?.data)
            setIsLoading(false)
          } else {
            setIsInitialChart(true)
            // API.get(
            //   'baseUriAggregateMaster',
            //   `aggregate-master/v1/${user.userGroup}/graph-nodes-edges/${params?.familyId}`,
            //   { queryStringParameters: { animation: false } }
            // )
            //   .then((response) => {
            //     if (response?.data && response?.data?.nodes && response?.data?.edges) {
            //       setAggregateData(response.data)
            //     }
            //   })
            //   .catch((error) => {
            //     showError(error.response?.data?.errorInfo?.userMessage || error?.message)
            //     Sentry.captureException(
            //       error.response?.data?.errorInfo?.userMessage || error
            //     )
            //   })
            //   .finally(() => {
            //     setIsLoading(false)
            //   })
            API.patch(
              'baseUriAggregateMaster',
              `aggregate-master/v1/${user?.userGroup}/client/graph/${params?.familyId}`
            )
              .then((response) => {
                if (response?.data && response?.data?.nodes && response?.data?.edges) {
                  setAggregateData(response.data)
                }
              })
              .catch((error) => {
                showError(error.response?.data?.errorInfo?.userMessage || error?.message)
                Sentry.captureException(
                  error.response?.data?.errorInfo?.userMessage || error
                )
              })
              .finally(() => {
                setIsLoading(false)
                setIsInitialChart(false)
              })
          }
        })
        .catch((error) => {
          showError(error.response?.data?.errorInfo?.userMessage || error?.message)
          Sentry.captureException(
            error.response?.data?.errorInfo?.userMessage || error
          )
          setIsLoading(false)
        })
    }
  }

  const storeAggregateChart = (payload) => {
    API.post(
      'baseUriAggregateMaster',
      `aggregate-master/v1/${user?.userGroup}/client-graph/${params?.familyId}`,
      {
        body: { ...(payload || {}) }
      }
    )
      .then((response) => {
        if (response?.success && response?.message) {
          showSuccess(response?.message)
          setIsInitialChart(false)
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error?.message)
        Sentry.captureException(
          error.response?.data?.errorInfo?.userMessage || error
        )
      })
  }

  const updateAggregateTreeChart = (payload, storeChartInDb) => {
    if (storeChartInDb) {
      storeAggregateChart(payload)
    } else {
      setIsLoading(true)
      API.patch(
        'baseUriAggregateMaster',
        `aggregate-master/v1/${user?.userGroup}/client/graph/${params?.familyId}`
      )
        .then((response) => {
          if (response?.data && response?.data?.nodes && response?.data?.edges) {
            setAggregateData(response.data)
          }
        })
        .catch((error) => {
          showError(error.response?.data?.errorInfo?.userMessage || error?.message)
          Sentry.captureException(
            error.response?.data?.errorInfo?.userMessage || error
          )
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const fetchLatestAggregateTreeChart = () => {
    setIsLoading(true)
    API.patch(
      'baseUriAggregateMaster',
      `aggregate-master/v1/${user?.userGroup}/client/graph/${params?.familyId}`
    )
      .then((response) => {
        if (response?.data && response?.data?.nodes && response?.data?.edges) {
          setAggregateData(response.data)
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error?.message)
        Sentry.captureException(
          error.response?.data?.errorInfo?.userMessage || error
        )
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (user) {
      fetchAggregateTreeData()
    }
  }, [user])

  return (
    <>
      <Box className='tree-view-box'>
        {
          isLoading
            ? <Loader />
            : ''
          }
        {
          aggregateData?.nodes?.length && aggregateData?.edges?.length
            ? <TreeComponent aggregateData={aggregateData} isInitialChart={isInitialChart} updateChart={updateAggregateTreeChart} refreshChart={fetchLatestAggregateTreeChart}/>
            : ''
        }
      </Box>
    </>
  )
}

export default AggregateTreeView
