import React, { memo, useState } from 'react'
import { Handle, Position, useHandleConnections } from '@xyflow/react'
import { Box, Chip, Typography } from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { formatCurrencyWithSymbol } from '../../../../Report/components/dataProcess/DataProcess'

const renderDataPoint = (type, nodeData) => {
  if (type === 'Market Value') {
    return <Typography fontSize='14px' className='row-div'>Market Value <span>{nodeData?.marketValue !== undefined && nodeData?.marketValue !== null ? formatCurrencyWithSymbol(nodeData?.marketValue, 2, '$') : 'NA'}</span></Typography>
  } else if (type === 'Total Risk') {
    return <Typography fontSize='14px' className='row-div'>Total Risk <span>{nodeData?.totalRisk === null || nodeData?.totalRisk === undefined ? 'NA' : `${nodeData?.totalRisk}%`}</span></Typography>
  } else if (type === 'Tracking Error') {
    return <Typography fontSize='14px' className='row-div'>Tracking Error <span>{nodeData?.te === null || nodeData?.te === undefined ? 'NA' : `${nodeData?.te}%`}</span></Typography>
  }
}

const color = {
  family: '#edb2a6',
  entity: '#cbe1f2',
  ips: '#e8e1c5',
  accounts: '#e8c8cf',
  washsales: '#cbe1f2',
  settlement: '#e8c8cf',
  household: '#e8e1c5',
  attribute: '#e8c8cf'
}

export default memo(({ data, isConnectable, id }) => {
  const connections = useHandleConnections({ type: 'source', nodeId: id })
  const [isCollapsed, setIsCollapsed] = useState(data?.expanded || false)
  const operation = (e) => {
    e.preventDefault()
    setIsCollapsed((prev) => !prev)
  }
  return (
    <>
      <Handle
        type='target'
        position={Position.Top}
        style={{ top: '-4px', background: '#555' }}
        isConnectable={isConnectable}
      />
      <Box className='inner-box'>
        <Box className='div-title'>
          <Typography fontSize='14px' fontWeight='bold'>{data?.nodeData?.name}</Typography>
          {data?.nodeData?.category ? <Chip label={data?.nodeData?.category?.toUpperCase()} size='small' variant='outlined' sx={{ marginTop: '8px', marginRight: '8px', backgroundColor: color[data?.nodeData?.category], borderRadius: '5px' }} /> : ''}
          {data?.nodeData && data?.nodeData?.hasOwnProperty('isTaxable') ? <Box className='tax-round'>{data?.nodeData?.isTaxable ? 'T' : 'NT'}</Box> : ''}
        </Box>
        <Box display='flex' justifyContent='space-between'>
          {(data?.nodeData?.leftDataPoint || data?.nodeData?.rightDataPoint)
            ? (
              <>
                {renderDataPoint(data?.nodeData?.leftDataPoint, data?.nodeData)}
                {renderDataPoint(data?.nodeData?.rightDataPoint, data?.nodeData)}
              </>
              )
            : (
              <>
                <Typography fontSize='14px' className='row-div'>
                  Market Value <span>{data?.nodeData?.marketValue !== undefined && data?.nodeData?.marketValue !== null ? formatCurrencyWithSymbol(data?.nodeData?.marketValue, 2, '$') : 'NA'}</span>
                </Typography>
                {data?.nodeData?.category === 'ips' || data?.nodeData?.category === 'accounts'
                  ? (
                    <Typography fontSize='14px' className='row-div'>
                      Tracking Error <span>{data?.nodeData?.te === null || data?.nodeData?.te === undefined ? 'NA' : `${data?.nodeData?.te}%`}</span>
                    </Typography>
                    )
                  : (
                    <Typography fontSize='14px' className='row-div'>
                      Total Risk <span>{data?.nodeData?.totalRisk === null || data?.nodeData?.totalRisk === undefined ? 'NA' : `${data?.nodeData?.totalRisk}%`}</span>
                    </Typography>
                    )}
              </>
              )}
        </Box>
      </Box>
      {
        data?.nodeData?.parentData ? data?.nodeData?.parentData?.map((obj) => (
          <Box key={obj.id} display='flex' alignItems='center' justifyContent='space-between' fontSize='14px' className='tree-padding'>
            <Box display='flex' alignItems='center'><Box sx={{ bgcolor: obj.color, height: '14px', width: '14px', borderRadius: '50%', mr: '5px' }} /> <Typography fontSize='14px' whiteSpace='nowrap'>{obj.name}</Typography></Box>
            <Typography fontSize='14px'>{obj.allocation}</Typography>
          </Box>
        )) : ''
      }
      {connections?.length > 0 && (
        <Box display='flex' justifyContent='center'>
          <button
            style={{
              cursor: 'pointer',
              border: '1px solid gray',
              padding: '2px 8px',
              borderRadius: '5px',
              display: 'flex',
              justifyContent: 'center'
            }}
            onClick={operation}
          >
            {connections.length} {isCollapsed ? <KeyboardArrowUpIcon fontSize='small' /> : <KeyboardArrowDownIcon fontSize='small' />}
          </button>
        </Box>
      )}
      <Handle
        type='source'
        position={Position.Bottom}
        style={{ bottom: '-4px', top: 'auto', background: '#555' }}
        isConnectable={isConnectable}
      />
    </>
  )
})
