import { useEffect, useState } from 'react'
import ReactECharts from 'echarts-for-react'
import { Card, CardContent, Grid, Table, TableBody, TableCell, TableRow } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { formatCurrencyWithSymbol } from '../../../../utils/FormateCurrenyInMilion'
import { summaryData as summary } from '../../IPSAggregate/data'

const summaryCols = [
  {
    field: 'name',
    headerName: 'Accounts',
    flex: 1
  },
  {
    field: 'trackingError',
    headerName: 'Tracking Error',
    type: 'number',
    flex: 1,
    align: 'right',
    headerAlign: 'right'
  },
  {
    field: 'hold',
    headerName: 'Hold',
    type: 'number',
    flex: 1,
    align: 'right'
  },
  {
    field: 'buy',
    headerName: 'Buy',
    type: 'number',
    flex: 1,
    align: 'right'
  },
  {
    field: 'sell',
    headerName: 'Sell',
    type: 'number',
    flex: 1,
    align: 'right'
  }
]

const nodeTooltipFormatter = (params) => {
  if (params.data.type === 'original UMA') {
    return `<div style='background-color: white;font-family: Open Sans;padding: 10px;'>
    <div style='display: flex; justify-content: space-between;align-items: flex-start;'>
        <div>
            <div style='font-size: 14px; font-weight: 500; color: #74788D;'>Market Value</div>
            <div style='font-size: 18px; font-weight: 600; color: #2F2F2F;'>${formatCurrencyWithSymbol(params?.data?.marketValue, 2, '$')}</div>
        </div>
        <div style='display: flex; justify-content: space-between;align-items: center;'>
            <div style='margin-left:50px;text-align: left;'>
                <div style='font-size: 12px; font-weight: 600; color: #3BBFA3;margin-bottom: 2px;'>${params?.data?.transferredShares}</div>
                <div style='width: 50px; height: 5px; border-radius: 5px; background-color: #3BBFA3;'></div>
                <div style='font-size: 12px; font-weight: 400; color: #74788D;'>Transfers</div>
            </div>
            <div style='text-align: right;'>
                <div style='font-size: 12px; font-weight: 600; color: #ff6161;margin-bottom: 2px;'>${params?.data?.sellShares}</div>
                <div style='width: 50px; height: 5px; border-radius: 5px; background-color: #ff6161;'></div>
                <div style='font-size: 12px; font-weight: 400; color: #74788D;'>Sell</div>
            </div>
        </div>
    </div>
    <div style='margin-top:20px;'>
        <div style='display: flex; justify-content: space-between;align-items: center;'>
            <div style='font-size: 12px; font-weight: 400; color: #74788D;'>Tax Cost</div>
            <div style='font-size: 12px; font-weight: 600; color: #2F2F2F;'>${formatCurrencyWithSymbol(params?.data?.taxCost, 2, '$')}</div>
        </div>
        <div style='display: flex; justify-content: space-between;align-items: center;'>
            <div style='font-size: 12px; font-weight: 400; color: #74788D;'>Realized Gain/Loss</div>
            <div style='font-size: 12px; font-weight: 600; color: #2F2F2F;'>${formatCurrencyWithSymbol(params?.data?.rgl, 2, '$')}</div>
        </div>
    </div>

</div>`
  }
  else if (params.data.type === 'scenario UMA') {
    return `<div style='background-color: white;font-family: Open Sans;padding: 10px;'>
    <div style='display: flex; justify-content: space-between;align-items: flex-start;'>
        <div>
            <div style='font-size: 14px; font-weight: 500; color: #74788D;'>Market Value</div>
            <div style='font-size: 18px; font-weight: 600; color: #2F2F2F;'>${formatCurrencyWithSymbol(params?.data?.marketValue, 2, '$')}</div>
        </div>
        <div style='display: flex; justify-content: space-between;align-items: center;'>
            <div style='margin-left:50px;text-align: left;'>
                <div style='font-size: 12px; font-weight: 600; color: #3BBFA3;margin-bottom: 2px;'>${params?.data?.transferredShares}</div>
                <div style='width: 50px; height: 5px; border-radius: 5px; background-color: #3BBFA3;'></div>
                <div style='font-size: 12px; font-weight: 400; color: #74788D;'>Transfers</div>
            </div>
            <div style='text-align: right;'>
                <div style='font-size: 12px; font-weight: 600; color: #6AA6EA;margin-bottom: 2px;'>${params?.data?.buyShares}</div>
                <div style='width: 50px; height: 5px; border-radius: 5px; background-color: #6AA6EA;'></div>
                <div style='font-size: 12px; font-weight: 400; color: #74788D;'>Buy</div>
            </div>
        </div>
    </div>
    <div style='margin-top:20px;'>
        <div style='display: flex; justify-content: space-between;align-items: center;'>
            <div style='font-size: 12px; font-weight: 400; color: #74788D;'>Tracking Error</div>
            <div style='font-size: 12px; font-weight: 600; color: #2F2F2F;'>${params?.data?.trackingError}%</div>
        </div>
        <div style='display: flex; justify-content: space-between;align-items: center;'>
            <div style='font-size: 12px; font-weight: 400; color: #74788D;'>Unrealized Gain/Loss</div>
            <div style='font-size: 12px; font-weight: 600; color: #2F2F2F;'>${formatCurrencyWithSymbol(params?.data?.urgl, 2, '$')}</div>
        </div>
    </div>

</div>`
  }
  return ''
}

const edgeTooltipFormatter = (params) => {
  return `<div style='background-color: white; width: 160px;'>
                    <div style='font-size: 12px; font-weight: 400; color: #74788D;'>Transferred Amount</div>
                    <div style='font-size: 12px; font-weight: 600; color: #34475A;'>${formatCurrencyWithSymbol(params?.data?.value, 2, '$')}</div>
                  </div>`
}

const SummaryTab = () => {
  const [accountsData, setAccountsData] = useState([])

  useEffect(() => {
    setAccountsData(summary)
  }, [summary])

  return (
    <>
      <Grid container justifyContent='center'>
        <Grid item xs={12} lg={9} margin='30px auto'>
          <ReactECharts
            option={{
              tooltip: {
                trigger: 'item',
                triggerOn: 'mousemove',
                formatter: (params) => {
                  if (params.dataType === 'node') {
                    return nodeTooltipFormatter(params)
                  } else if (params.dataType === 'edge') {
                    return edgeTooltipFormatter(params)
                  }
                  return ''
                }
              },
              series: [
                {
                  type: 'sankey',
                  data: [
                    {
                      name: 'Harry Callan',
                      type: 'original UMA',
                      marketValue: 12312.04515,
                      urgl: 120,
                      rgl: 120,
                      taxCost: 1200,
                      transferredShares: 120,
                      sellShares: 20,
                      itemStyle: {
                        color: '#60BAA6'
                      }
                    },
                    {
                      name: 'Marry Callan',
                      type: 'original UMA',
                      marketValue: 12312.04515,
                      urgl: 120,
                      rgl: 120,
                      taxCost: 1200,
                      transferredShares: 120,
                      sellShares: 20,
                      itemStyle: {
                        color: '#6A86A6'
                      }
                    },
                    {
                      name: 'Clara Callan',
                      type: 'original UMA',
                      marketValue: 12312.04515,
                      urgl: 120,
                      rgl: 120,
                      taxCost: 1200,
                      transferredShares: 120,
                      sellShares: 20,
                      itemStyle: {
                        color: '#859DC6'
                      }
                    },
                    {
                      name: 'Austin Callan',
                      type: 'original UMA',
                      marketValue: 12312.04515,
                      urgl: 120,
                      rgl: 120,
                      taxCost: 1200,
                      transferredShares: 120,
                      sellShares: 20,
                      itemStyle: {
                        color: '#C9B088'
                      }
                    },
                    {
                      name: 'Elizabeth Callan',
                      type: 'original UMA',
                      marketValue: 12312.04515,
                      urgl: 120,
                      rgl: 120,
                      taxCost: 1200,
                      transferredShares: 120,
                      sellShares: 20,
                      itemStyle: {
                        color: '#F4CA9D'
                      }
                    },
                    {
                      name: 'Harry Rev Tr',
                      type: 'scenario UMA',
                      marketValue: 12312.04515,
                      urgl: 120,
                      trackingError: 12,
                      transferredShares: 120,
                      buyShares: 20,
                      itemStyle: {
                        color: '#B4CDE3'
                      }
                    },
                    {
                      name: 'H+M jt Acct',
                      type: 'scenario UMA',
                      marketValue: 12312.04515,
                      urgl: 120,
                      trackingError: 12,
                      transferredShares: 120,
                      buyShares: 20,
                      itemStyle: {
                        color: '#DECBBB'
                      }
                    },
                    {
                      name: 'Callan FLP',
                      type: 'scenario UMA',
                      marketValue: 12312.04515,
                      urgl: 120,
                      trackingError: 12,
                      transferredShares: 120,
                      buyShares: 20,
                      itemStyle: {
                        color: '#A0E292'
                      }
                    },
                    {
                      name: 'Trust FBO',
                      type: 'scenario UMA',
                      marketValue: 12312.04515,
                      urgl: 120,
                      trackingError: 12,
                      transferredShares: 120,
                      buyShares: 20,
                      itemStyle: {
                        color: '#DEDABB'
                      }
                    },
                    {
                      name: 'Callan GST Irrev Tr',
                      type: 'scenario UMA',
                      marketValue: 12312.04515,
                      urgl: 120,
                      trackingError: 12,
                      transferredShares: 120,
                      buyShares: 20,
                      itemStyle: {
                        color: '#CCEBC5'
                      }
                    },
                    {
                      name: 'Trst FBO Elizabeth',
                      type: 'scenario UMA',
                      marketValue: 12312.04515,
                      urgl: 120,
                      trackingError: 12,
                      transferredShares: 120,
                      buyShares: 20,
                      itemStyle: {
                        color: '#DFCBE4'
                      }
                    }
                  ],
                  links: [
                    {
                      source: 'Harry Callan',
                      target: 'Harry Rev Tr',
                      value: 100
                    },
                    {
                      source: 'Harry Callan',
                      target: 'Callan FLP ',
                      value: 50
                    },
                    {
                      source: 'Harry Callan',
                      target: 'Callan GST Irrev Tr',
                      value: 100
                    },
                    {
                      source: 'Marry Callan',
                      target: 'H+M jt Acct',
                      value: 20
                    },
                    {
                      source: 'Marry Callan',
                      target: 'Trust FBO',
                      value: 56

                    },
                    {
                      source: 'Marry Callan',
                      target: 'Callan GST Irrev Tr',
                      value: 80
                    },
                    {
                      source: 'Marry Callan',
                      target: 'Trst FBO Elizabeth',
                      value: 10

                    },
                    {
                      source: 'Clara Callan',
                      target: 'Callan FLP',
                      value: 75
                    },
                    {
                      source: 'Clara Callan',
                      target: 'Trust FBO',
                      value: 40
                    },
                    {
                      source: 'Austin Callan',
                      target: 'Callan FLP',
                      value: 30

                    },
                    {
                      source: 'Austin Callan',
                      target: 'Callan GST Irrev Tr',
                      value: 20

                    },
                    {
                      source: 'Elizabeth Callan',
                      target: 'Callan FLP',
                      value: 100

                    },
                    {
                      source: 'Elizabeth Callan',
                      target: 'Trst FBO Elizabeth',
                      value: 80

                    }
                  ],
                  emphasis: {
                    focus: 'adjacency'
                  },

                  lineStyle: {
                    color: 'source',
                    curveness: 0.5
                  },
                  nodeWidth: 10
                }
              ]
            }}
            style={{ width: '100%', height: '400px' }}
            opts={{ renderer: 'svg' }}
          />
        </Grid>
      </Grid>
      <Grid container gap={1} justifyContent='space-between'>
        <Grid item xs={12}>
          <DataGridPro
            autoHeight
            density='compact'
            rows={accountsData}
            getRowId={(row) => row?.id}
            columns={summaryCols}
            hideFooter
          />
        </Grid>

      </Grid>
    </>
  )
}

export default SummaryTab
