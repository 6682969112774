import { Link } from 'react-router-dom'
import { Box, Button, Grid, IconButton, Modal, Step, StepIcon, StepLabel, Stepper, Typography } from '@mui/material'
import { CheckCircle, Circle, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import DoneIcon from '../../../assets/images/Check.svg'
import Success from '../../../assets/images/success.svg'
import { useState } from 'react'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  borderRadius: '4px'
}

const OnboardingStepper = ({ umaDetails, activeIndex, stepLabels, gotoNextStep, gotoPrevStep, changeActiveStep, allStepsVisited }) => {
  const activeTab = stepLabels[activeIndex]
  const [completed, setCompleted] = useState(false)
  const [showSecondMsg, setShowSecondMsg] = useState(false)
  const [showThirdMsg, setShowThirdMsg] = useState(false)
  const [accountTypeMsg, setAccountTypMsg] = useState()
  const [successMessage, setSuccessMessage] = useState('on Boarded')
  const [modalShow, setModalShow] = useState(false)

  const checkAccountType = () => {
    if (umaDetails?.accountType === 'transition') {
      setAccountTypMsg('Account will be live soon in 15 mins')
    } else {
      setAccountTypMsg('Account will be live soon in 24 Hours')
      setShowThirdMsg(true)
    }
  }

  const submitForm = () => {
    setModalShow(true)
    setShowSecondMsg(true)
  }

  const closeModal = () => {
    setModalShow(false)
  }

  return (
    <Grid container alignItems='flex-end' mb={4} pt={3}>
      <Grid item sm={2}>
        <Typography className='page-title' fontSize='24px'>UMA Onboarding</Typography>
      </Grid>
      <Grid item sm={9} mt={3}>
        <Stepper activeStep={activeIndex} alternativeLabel>
          {stepLabels?.map((label, index) => (
            <Step
              key={label}
              completed={index < activeIndex || allStepsVisited} // mark completed if visited or if all steps are done
              className={index === activeIndex ? 'current' : ''}
            >
              <StepLabel
                sx={{
                  '.MuiStepLabel-label': {
                    marginTop: '-50px !important'
                  }
                }}
                StepIconComponent={(props) =>
                  index === activeIndex
                    ? <StepIcon {...props} icon={<Circle style={{ color: '#175cc2' }} />} />
                    : index < activeIndex || allStepsVisited // enable past or all completed steps
                      ? <StepIcon {...props} icon={<CheckCircle color='success' cursor='pointer' onClick={() => changeActiveStep(index)} />} />
                      : <StepIcon {...props} icon={<Circle style={{ color: '#eceff1' }} />} />}
              >
                <Typography
                  variant='subtitle1'
                  sx={{ fontWeight: 700, fontSize: '14px', whiteSpace: 'nowrap' }}
                >
                  {index + 1}.{label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid item sm={1}>
        <Box sx={{ display: 'flex', width: '34px', height: '34px' }}>
          {activeTab === 'UMA Details'
            ? (
              <IconButton className='btn-grey rounded-circle' onClick={gotoNextStep} sx={{ width: '34px', height: '34px' }}>
                <KeyboardArrowRight />
              </IconButton>
              )
            : null}
          {activeTab === 'Upload Portfolio' || activeTab === 'Select Allocation Model'
            ? <>
              <IconButton className='btn-grey rounded-circle' onClick={gotoPrevStep} sx={{ width: '34px', height: '34px', marginRight: '10px' }}>
                <KeyboardArrowLeft />
              </IconButton>
              <IconButton className='btn-grey rounded-circle' onClick={gotoNextStep} sx={{ width: '34px', height: '34px' }}>
                <KeyboardArrowRight />
              </IconButton>
              </>
            : null}
          {activeTab === 'Confirmation'
            ? <IconButton className='btn-grey rounded-circle' onClick={gotoPrevStep} sx={{ width: '34px', height: '34px', marginRight: '10px' }}>
              <KeyboardArrowLeft />
              </IconButton>
            : null}
          {activeTab === 'Confirmation'
            ? <Button
                sx={{
                  padding: '10px 45px'
                }}
                variant='contained'
                onClick={() =>
                  activeIndex === 3
                    ? submitForm() || checkAccountType()
                    : setCompleted(false)}
              >
              Submit
              </Button>
            : null}
        </Box>
      </Grid>
      <Modal
        open={modalShow}
        onClose={closeModal}
        disableEscapeKeyDown
        aria-labelledby='contained-modal-title-vcenter'
        centered
        hideBackdrop
      >
        <Box className='blur-background-parent'>
          <Box sx={style}>
            <Box className='oboardSuccess'>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
                <img src={Success} alt='' height={100} width={100} />
              </Box>
              <Typography variant='h4' align='center' mb={2} sx={{ fontFamily: 'Open Sans', fontSize: '24px', fontWeight: '600' }}>
                {successMessage}
              </Typography>
              {showSecondMsg && (
                <Box className='apimsgbox'>
                  <ul className='apimsg'>
                    <li className='msglist'>
                      <Box sx={{ width: '10%' }} mb={1}>
                        <img
                          src={DoneIcon}
                          alt=''
                          height={25}
                          width={25}
                        />
                      </Box>{' '}
                      <Typography>Update portfolio details</Typography>
                    </li>
                    <li className='msglist'>
                      <Box sx={{ width: '10%' }} mb={1}>
                        <img
                          src={DoneIcon}
                          alt=''
                          height={25}
                          width={25}
                        />
                      </Box>{' '}
                      <Typography>Generate account summary</Typography>
                    </li>
                    <li className='msglist'>
                      <Box sx={{ width: '10%' }} mb={1}>
                        <img
                          src={DoneIcon}
                          alt=''
                          height={25}
                          width={25}
                        />
                      </Box>{' '}
                      <Typography>Calculate Risk Data</Typography>
                    </li>
                    {showThirdMsg && (
                      <li className='msglist'>
                        <Box sx={{ width: '10%' }} mb={1}>
                          <img
                            src={DoneIcon}
                            alt=''
                            height={25}
                            width={25}
                          />
                        </Box>{' '}
                        <Typography>Enable account for optimization</Typography>
                      </li>
                    )}
                    {!showThirdMsg && (
                      <li className='msglist'>
                        <Box sx={{ width: '10%' }} mb={1}>
                          <img
                            src={DoneIcon}
                            alt=''
                            height={25}
                            width={25}
                          />
                        </Box>{' '}
                        <Typography>Initiate transition proposal</Typography>
                      </li>
                    )}
                    <li className='msglist'>
                      <Box sx={{ width: '10%' }} mb={3}>
                        <img
                          src={DoneIcon}
                          alt=''
                          height={25}
                          width={25}
                        />
                      </Box>{' '}
                      <Typography>Check policy breaks on portfolio</Typography>
                    </li>
                  </ul>
                </Box>
              )}
              <Link to='/book-of-business' style={{ display: 'flex', justifyContent: 'center', textDecoration: 'none' }}>
                <Button
                  variant='contained'
                  sx={{
                    padding: '6px 35px',
                    mt: 2
                  }}
                >
                  Go to dashboard
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Grid>
  )
}

export default OnboardingStepper
