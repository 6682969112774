import React, { useEffect, useMemo, useState } from 'react'
import * as Sentry from '@sentry/react'
import { API } from 'aws-amplify'
import { ExpandLess, ExpandMore, Add } from '@mui/icons-material'
import CancelIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import TuneIcon from '@mui/icons-material/Tune'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  debounce,
  Divider,
  IconButton,
  TextField,
  Typography
} from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, GridActionsCellItem, GridRowEditStopReasons, GridRowModes, useGridApiRef, GridToolbarContainer } from '@mui/x-data-grid-pro'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import CustomTooltipIcon from '../../Personalization/components/CustomTooltipIcon'
import CustomStrategyField from './CustomStrategyField'
import PersonalizationModal from './PersonalizationModal'

const AllocationModelCards = (props) => {
  const { defaultAllocationTitleId, allocationId, setAllocationCardData, allocationCardData, accountType, personalizationData, setPersonalizationData, setAllStepsVisited } = props
  const [expanded, setExpanded] = useState(true)
  const apiRef = useGridApiRef()
  const [loading, setLoading] = useState(false)
  const [strategies, setStrategies] = useState([])
  const [allStrategiesList, setAllStrategiesList] = useState([])
  const { showError } = useErrorToast()
  const [selectedStrategyPersonalization, setSelectedStrategyPersonalization] = useState(null)
  const { user } = useAuth()
  const [rowModesModel, setRowModesModel] = useState({})
  const [isModalShown, setIsModalShown] = useState(false)
  const [rowErrors, setRowErrors] = useState({})
  const [selectedRowId, setSelectedRowId] = useState(null)

  const currAllocationData = allocationCardData?.filter((data) => data?.id === allocationId)
  const currSelectedAllocation = currAllocationData && currAllocationData?.length ? currAllocationData[0]?.data : []
  const [allocationTitle, setAllocationTitle] = useState(currAllocationData[0]?.allocationTitle || `Allocation ${defaultAllocationTitleId}`)
  const currentAllocationTitle = currAllocationData && currAllocationData?.length ? currAllocationData[0]?.allocationTitle : `Allocation ${defaultAllocationTitleId}`
  const [allocationGridState, setAllocationGridState] = useState(null)
  const [isNameEditMode, setIsNameEditMode] = useState(false)

  // Function to save the current state of the DataGridPro
  const handleAllocationsTableChange = () => {
    if (apiRef?.current?.exportState) {
      const state = apiRef?.current?.exportState()
      setAllocationGridState(state)
    }
  }

  // Effect to restore the grid state whenever gridState changes
  useEffect(() => {
    if (apiRef?.current?.restoreState && allocationGridState) {
      const resetGridStateObj = {
        columns: allocationGridState?.columns || {},
        pinnedColumns: allocationGridState?.pinnedColumns || {},
        sorting: allocationGridState?.sorting || []
      }
      apiRef?.current?.restoreState(resetGridStateObj)
    }
  }, [allocationGridState, isModalShown, rowModesModel, selectedStrategyPersonalization, selectedRowId, allocationTitle, strategies, expanded, allocationId, rowErrors, allocationCardData, accountType, personalizationData, currAllocationData])

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  useEffect(() => {
    if (user) {
      getStrategyList()
    }
  }, [user])

  useEffect(() => {
    // On screen load, filter out incomplete rows
    setAllocationCardData((prevCards) =>
      prevCards?.map((prev) =>
        prev.id === allocationId
          ? {
              ...prev,
              data: [
                ...prev.data
              ].filter(row => row.strategy && row.allocation) // prevent adding rows with empty fields
            }
          : prev
      )
    )
  }, [])

  const handlePersonalizationClick = (id) => () => {
    setSelectedRowId(id)
    const row = allocationCardData.flatMap(allocation => allocation.data).find(item => item.id === id)
    if (row) {
      setSelectedStrategyPersonalization(row?.strategyId)
      setPersonalizationData(row?.personalizationData)
      setIsModalShown(true)
    }
  }

  const handleCloseModal = () => {
    setIsModalShown(false)
    setSelectedStrategyPersonalization(null)
  }

  const totalAllocation = useMemo(() => {
    const allocation = allocationCardData?.find((alloc) => alloc.id === allocationId)

    return allocation?.data?.reduce(
      (sum, row) => sum + Number(row?.allocation || 0),
      0
    )
  }, [allocationCardData, allocationId])

  const hasAllocationError = useMemo(() => totalAllocation !== 100 || totalAllocation > 100, [totalAllocation])

  // add error key in allocationCardData if below conditions matches
  useEffect(() => {
    const updatedAllocations = allocationCardData?.map((allocation) => {
      if (allocation.id === allocationId) {
        const rowError = rowErrors[allocation.id] || {}

        return {
          ...allocation,
          error: rowError.allocationError || rowError.strategyError || hasAllocationError
        }
      }
      return allocation
    })

    if (updatedAllocations?.some((data) => data?.error) || updatedAllocations?.some((data) => data?.data?.length === 0) || !updatedAllocations?.length) {
      setAllStepsVisited(false)
    }

    if (allocationCardData && updatedAllocations && !areAllocationsEqual(allocationCardData, updatedAllocations)) {
      setAllocationCardData(updatedAllocations)
    }
  }, [rowErrors, allocationCardData, allocationId, hasAllocationError])

  const areAllocationsEqual = (allocationCardData, updatedAllocations) => {
    return allocationCardData.length === updatedAllocations.length &&
      allocationCardData.every((allocation, index) =>
        allocation.id === updatedAllocations[index].id &&
        allocation.error === updatedAllocations[index].error
      )
  }

  const handleSearchRequest = useMemo(
    () => debounce((req) => {
      getStrategyList(req)
    }, 200), [])

  const handleInputChange = (event, newInputValue) => {
    if (newInputValue?.trim()?.length >= 3) {
      handleSearchRequest(newInputValue)
    }
    handleAllocationsTableChange()
  }

  useEffect(() => {
    if (currAllocationData && currAllocationData?.length) {
      setAllocationCardData(
        allocationCardData?.map((allocation) =>
          allocation.id === allocationId && !allocation.allocationTitle
            ? {
                ...allocation,
                allocationTitle // Set default allocation title
              }
            : allocation
        )
      )
    }
  }, [allocationTitle])

  const columns = [
    {
      field: 'strategy',
      headerName: 'Strategy',
      editable: true,
      flex: 2,
      preProcessEditCellProps: (params) => {
        const hasError = !params?.props?.value
        setRowErrors((prevErrors) => ({
          ...prevErrors,
          [params.id]: {
            ...prevErrors[params.id],
            strategyError: hasError
          }
        }))
        return { ...params.props, error: hasError }
      },
      renderEditCell: (params) => (
        <CustomStrategyField
          params={params}
          loading={loading}
          setLoading={setLoading}
          allStrategiesList={allStrategiesList}
          handleSearchRequest={handleSearchRequest}
          currRowsStrategyList={currSelectedAllocation}
          strategies={strategies}
          error={rowErrors[params.id]?.strategyError}
        />
      )
    },
    {
      field: 'allocation',
      headerName: 'Allocation',
      editable: true,
      flex: 1,
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      preProcessEditCellProps: (params) => {
        const hasError = !params?.props?.value
        setRowErrors((prevErrors) => ({
          ...prevErrors,
          [params.id]: {
            ...prevErrors[params.id],
            allocationError: hasError
          }
        }))
        return { ...params.props, error: hasError }
      },
      renderEditCell: (params) => (
        <TextField
          type='number'
          variant='outlined'
          value={params.value || ''}
          sx={{
            ml: 2,
            width: '100%',
            mr: 2,
            height: '40px'
          }}
          error={rowErrors[params.id]?.allocationError}
          InputProps={{
            inputProps: {
              type: 'number',
              step: '1',
              min: 1,
              max: 100
            },
            sx: {
              height: '100%'
            }
          }}
          onChange={(event) => {
            const newValue = event.target.value
            if (newValue === '' || (newValue > 0 && newValue <= 100)) {
              params.api.setEditCellValue({ id: params.id, field: params.field, value: Number(newValue) })
            }
          }}
          // prevent the user from typing the '-' (negative sign)
          onKeyDown={(e) => {
            if (e.key === '-' || e.key === 'e') {
              e.preventDefault()
            }
          }}
        />
      ),
      renderHeader: (params) => {
        return (
          <>
            Allocation
            {(totalAllocation > 100 ||
              totalAllocation < 100) &&
              currSelectedAllocation &&
              currSelectedAllocation?.length
              ? (
                <CustomTooltipIcon action='error' text='Total Allocation Weight Should be 100' />
                )
              : ''}
          </>
        )
      }
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 1,
      cellClassName: 'actions',
      getActions: (params) => {
        const isInEditMode = rowModesModel[params?.id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              key='save'
              disabled={rowErrors[params.id]?.strategyError || rowErrors[params.id]?.allocationError}
              label='Save'
              sx={{
                color: 'primary.main'
              }}
              onClick={handleSaveClick(params?.id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label='Cancel'
              key='cancel'
              className='textPrimary'
              onClick={handleCancelClick(params?.id)}
              color='inherit'
            />
          ]
        }
        return [
          // <GridActionsCellItem
          //   icon={<TuneIcon />}
          //   key='personalize'
          //   disabled={rowErrors[params.id]?.strategyError || rowErrors[params.id]?.allocationError}
          //   label='Personalize'
          //   color='inherit'
          //   onClick={handlePersonalizationClick(params?.id)}
          // />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label='Edit'
            key='edit'
            className='textPrimary'
            onClick={handleEditClick(params?.id)}
            color='inherit'
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            key='delete'
            label='Delete'
            onClick={handleDeleteClick(params?.id)}
            color='inherit'
          />
        ]
      }
    }
  ]

  const getStrategyList = async (strategyName) => {
    setLoading(true)
    API.get('baseStrategyURL', `strategy/v1/${user?.userGroup}/strategy-master`, {
      queryStringParameters: {
        ...{
          ...strategyName
            ? {
                strategyName
              }
            : {}
        },
        resources: encodeURIComponent(JSON.stringify({
          serviceId: 'aggregate',
          resourceId: 'uma-onboarding'
        }))
      }
    })
      .then(response => {
        if (response.data) {
          const strategyListData = response.data.map((item, index) => {
            const result = {
              id: index + 1, ...item
            }
            return result
          })
          if (!strategyName) {
            setAllStrategiesList(strategyListData)
          }
          setStrategies(strategyListData)
          setLoading(false)
        }
      })
      .catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        setLoading(false)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut || params.reason === GridRowEditStopReasons.escapeKeyDown || params.reason === GridRowEditStopReasons.enterKeyDown || params.reason === GridRowEditStopReasons.tabKeyDown) {
      event.defaultMuiPrevented = true
    }
  }

  const handleCellEditStart = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleCellEditStop = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleDeleteClick = (id) => () => {
    setAllocationCardData(allocationCardData.map((allocation) => {
      if (allocation.id === allocationId) {
        return {
          ...allocation,
          data: allocation.data.filter((row) => row.id !== id)
        }
      }
      return allocation
    }))
  }

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })

    const editedRow = currSelectedAllocation?.find((row) => row.id === id)
    if (editedRow?.isNew) {
      setAllocationCardData(allocationCardData.map((allocation) => {
        if (allocation.id === allocationId) {
          return {
            ...allocation,
            data: allocation.data.filter((row) => row.id !== id)

          }
        }
        return allocation
      }))
    }

    setRowErrors((prevErrors) => ({
      ...prevErrors,
      [id]: {
        strategyError: false,
        allocationError: false
      }
    }))
  }

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false }

    const updatedAllocations = allocationCardData?.map((allocation) => {
      if (allocation?.id === allocationId) {
        return {
          ...allocation,
          data: allocation?.data?.map((row) => {
            return row.id === newRow.id ? updatedRow : row
          })
        }
      }
      return allocation
    })

    setAllocationCardData(updatedAllocations)
    return updatedRow
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  // Handle title change and save
  const handleTitleChange = () => {
    const newTitle = allocationTitle?.trim()
    if (newTitle === '') {
      showError('Allocation Title is required') // Show the error
      setAllocationCardData(
        allocationCardData?.map((allocation) =>
          allocation.id === allocationId
            ? {
                ...allocation,
                data: [...allocation.data],
                allocationTitle: ''
              }
            : allocation
        )
      )
      return
    }
    // If the title is not empty, save the title and close the edit mode
    setAllocationCardData(
      allocationCardData?.map((allocation) =>
        allocation.id === allocationId
          ? {
              ...allocation,
              data: [...allocation.data],
              allocationTitle: newTitle
            }
          : allocation
      )
    )
    setIsNameEditMode(false)
  }

  const closeNameEdit = () => {
    if (allocationTitle.trim() === '') {
      // Revert the allocation title to the original one if the input is empty
      const originalTitle = currentAllocationTitle
      // Set the allocation title back to the original in the input field
      setAllocationTitle(originalTitle)
      // Also update the allocationCardData to ensure the original title is saved in the data
      setAllocationCardData(
        allocationCardData?.map((allocation) =>
          allocation.id === allocationId
            ? {
                ...allocation,
                data: [...allocation.data],
                allocationTitle: originalTitle // Reset the title in the data to the original title
              }
            : allocation
        )
      )
      // Keep the edit mode active because the title is empty
      setIsNameEditMode(true)
    } else {
      // If the title isn't empty, exit edit mode
      setIsNameEditMode(false)
    }
  }

  // Effect to update the allocationCardData state when the personalization changes
  useEffect(() => {
    if (personalizationData && allocationId && selectedRowId) {
      const updatedAllocations = allocationCardData?.map((allocation) => {
        if (allocation.id === allocationId) {
          const updatedData = allocation?.data?.map((item) => {
            if (item.id === selectedRowId) {
              return {
                ...item,
                personalizationData: { ...personalizationData }
              }
            }
            return item
          })
          return {
            ...allocation,
            data: updatedData
          }
        }
        return allocation
      })
      setAllocationCardData(updatedAllocations)
    }
  }, [personalizationData, allocationId, selectedRowId])

  const EditToolbar = (props) => {
    const { setAllocationCardData, setRowModesModel } = props

    const handleClick = () => {
      const id = randomId()
      setAllocationCardData((prevCards) =>
        prevCards?.map((prev) =>
          prev.id === allocationId
            ? { ...prev, data: [...prev.data, { id, strategy: '', allocation: '', isNew: true }] }
            : prev
        )
      )
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'strategy' }
      }))
    }

    return (
      <GridToolbarContainer>
        <Button color='primary' startIcon={<Add />} onClick={handleClick} disabled={totalAllocation >= 100}>
          Add Strategy
        </Button>
      </GridToolbarContainer>
    )
  }

  return (
    <Card variant='outlined' sx={{ marginBottom: 2, marginTop: accountType === 'trading' ? '20px' : '', position: 'relative' }}>
      <CardHeader
        action={
          <Box sx={{ visibility: accountType === 'transition' ? 'visible' : 'hidden' }}>
            <IconButton
              onClick={() => {
                setAllocationCardData(allocationCardData?.filter((allocation) => allocation.id !== allocationId))
              }}
            >
              <DeleteIcon fontSize='inherit' />
            </IconButton>
            <IconButton onClick={handleExpandClick}>
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
        }
      />
      <Box sx={{ position: 'absolute', top: '16px', left: '20px' }}>
        {isNameEditMode
          ? (
            <Box sx={{ height: '32px', display: 'flex', position: 'relative' }}>
              <Box sx={{ position: 'relative' }} className='border-animation'>
                <input
                  onChange={(e) => setAllocationTitle(e.target.value)}
                  value={allocationTitle}
                  autoFocus
                  autoComplete='off'
                  id='aggregate-name-inp'
                  style={{
                    border: 'none',
                    outline: 'none',
                    boxShadow: 'none'
                  }}
                />
              </Box>
              <Box sx={{ display: 'flex' }}>
                <IconButton onClick={handleTitleChange} sx={{ p: '4px' }}>
                  <CheckIcon fontSize='small' />
                </IconButton>
                <IconButton onClick={closeNameEdit} sx={{ p: '4px' }}>
                  <ClearIcon fontSize='small' />
                </IconButton>
              </Box>
            </Box>
            )
          : (
            <Box
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px' }}
              id='groupName'
            >
              <Typography
                id='background-change-hover'
                onClick={() => setIsNameEditMode(true)}
                sx={{ cursor: 'pointer' }}
              >
                {allocationTitle === '' ? '' : allocationTitle || `Allocation ${defaultAllocationTitleId}`}
              </Typography>
              <IconButton id='edit-icon' onClick={() => setIsNameEditMode(true)}>
                <EditIcon fontSize='small' />
              </IconButton>
            </Box>
            )}
      </Box>

      <Divider />
      <>
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <CardContent sx={{ paddingTop: '3px' }}>
            <DataGridPro
              autoHeight
              rows={currSelectedAllocation || []}
              columns={columns}
              pageSize={5}
              pagination
              sx={{
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none !important'
                },
                '& .MuiDataGrid-cell--editing': {
                  outline: 'none !important'
                }
              }}
              pageSizeOptions={[5]}
              getRowId={(row) => row?.id}
              rowModesModel={rowModesModel}
              apiRef={apiRef}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              onColumnWidthChange={() => handleAllocationsTableChange()}
              onColumnOrderChange={() => handleAllocationsTableChange()}
              onCellEditStart={handleCellEditStart}
              onCellEditStop={handleCellEditStop}
              onSortModelChange={() => handleAllocationsTableChange()}
              disableRowSelectionOnClick
              slots={{
                toolbar: EditToolbar
              }}
              slotProps={{
                toolbar: { setAllocationCardData, setRowModesModel }
              }}
              initialState={{
                ...allocationGridState?.initialState,
                pagination: { paginationModel: { pageSize: 5 } }
              }}
            />
          </CardContent>
        </Collapse>
        {isModalShown && selectedStrategyPersonalization && (
          <PersonalizationModal
            isShown={isModalShown}
            setIsShown={handleCloseModal}
            strategy={selectedStrategyPersonalization}
            setPersonalizationData={setPersonalizationData}
            personalization={personalizationData}
          />
        )}
      </>
    </Card>
  )
}

export default AllocationModelCards
