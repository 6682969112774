import React, { useEffect, useState } from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import CloseIcon from '@mui/icons-material/Close'
import SaveIcon from '@mui/icons-material/Save'
import { Box, Button, Grid, Icon, IconButton, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import { formatNumbersWithSymbol } from '../../Report/components/dataProcess/DataProcess'
import './taxlot-swapping.css'

const popupStyle = {
  position: 'relative',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  px: 4,
  py: 2,
  width: '100%',
  minWidth: '600px',
  maxHeight: '100vh',
  maxWidth: '1600px',
  borderRadius: '4px',
  '.MuiDataGrid-root': {
    border: 'none'
  },
  '.MuiDataGrid-main': {
    // remove overflow hidden overwise sticky does not work
    overflow: 'unset'
  },
  '.MuiDataGrid-columnHeaders': {
    position: 'sticky',
    top: '-4px',
    zIndex: 99,
    background: 'white'
  },
  '.MuiDataGrid-virtualScroller': {
    // remove the space left for the header
    marginTop: '-4px!important'
  }
}

export default function AccountMarketValueModal ({ accountsList, isMarketValueChange, handleClose, showConfirmButton, submitTaxlotSwap, postTaxlotSwapLoading }) {
  // Calculate the total of all old and new market values
  const totalOriginalMarketValue = accountsList?.reduce((total, account) => total + account?.marketValue, 0)
  const totalUpdatedMarketValue = accountsList?.reduce((total, account) => total + account?.updatedMarketValue, 0)
  // Initialize the account details list with allocation calculations
  const accountDetailsWithAllocation = accountsList?.map(account => {
    const originalMarketValue = parseFloat(account?.marketValue) || 0
    const updatedMarketValue = parseFloat(account?.marketValue) || 0
    return {
      id: randomId(),
      originalMarketValue,
      updatedMarketValue,
      marketValueChange: 0,
      currentAccountAllocation: (originalMarketValue / totalOriginalMarketValue) * 100, // Allocation based on current market value
      newAccountAllocation: (updatedMarketValue / totalUpdatedMarketValue) * 100, // Allocation based on updated market value
      ...account
    }
  })

  const [accountDetailsList, setAccountDetailsList] = useState(accountDetailsWithAllocation)

  // Function to handle change in market value when the quantity value changes
  const handleMarketValueChanges = (rows) => {
    const updatedAccountDetailsList = accountDetailsList?.map((account) => ({
      ...account,
      updatedMarketValue: account?.originalMarketValue
    }))
    // Iterate through each row to process market value changes
    rows?.forEach((row) => {
      if (
        (row?.showSwapOptions && row?.quantity && row?.targetAccount?.accountId) ||
        (row?.showSwapOptions && row?.children?.some(child => child.targetAccount?.accountId && child?.quantity))
      ) {
        const quantity = parseFloat(row?.quantity) || 0
        const lastClosePrice = parseFloat(row?.lastClosePrice) || 0
        const marketValueChange = quantity * lastClosePrice
        if (!row?.quantityError) {
          if (row?.showSwapOptions && row?.quantity && row?.targetAccount?.accountId) {
          // Update source account market value
            const sourceAccountIndex = updatedAccountDetailsList.findIndex((account) => account?.accountCd === row?.accountCd)
            if (sourceAccountIndex !== -1) {
              const sourceAccount = updatedAccountDetailsList[sourceAccountIndex]
              const newUpdatedMarketValue = (sourceAccount?.updatedMarketValue ?? sourceAccount?.originalMarketValue) - marketValueChange
              updatedAccountDetailsList[sourceAccountIndex] = {
                ...sourceAccount,
                updatedMarketValue: newUpdatedMarketValue,
                marketValueChange: newUpdatedMarketValue - sourceAccount?.originalMarketValue
              }
            }
            // Update target account market value
            const targetAccountIndex = updatedAccountDetailsList.findIndex((account) => account?.accountCd === row?.targetAccount?.accountCd)
            if (targetAccountIndex !== -1) {
              const targetAccount = updatedAccountDetailsList[targetAccountIndex]
              const newUpdatedMarketValue = (targetAccount?.updatedMarketValue ?? targetAccount?.originalMarketValue) + marketValueChange
              updatedAccountDetailsList[targetAccountIndex] = {
                ...targetAccount,
                updatedMarketValue: newUpdatedMarketValue,
                marketValueChange: newUpdatedMarketValue - targetAccount?.originalMarketValue
              }
            }
          }
          // Handle children if present
          if (row?.children && row?.children?.length > 0) {
            row?.children?.forEach((child) => {
              if (child?.showSwapOptions && child?.quantity && child?.targetAccount?.accountId) {
                const childQuantity = parseFloat(child?.quantity) || 0
                const childMarketValueChange = childQuantity * lastClosePrice
                // Update child source account market value
                const childSourceAccountIndex = updatedAccountDetailsList.findIndex((account) => account?.accountCd === row?.accountCd)
                if (childSourceAccountIndex !== -1) {
                  const childSourceAccount = updatedAccountDetailsList[childSourceAccountIndex]
                  const newChildUpdatedMarketValue = (childSourceAccount?.updatedMarketValue ?? childSourceAccount?.originalMarketValue) - childMarketValueChange
                  updatedAccountDetailsList[childSourceAccountIndex] = {
                    ...childSourceAccount,
                    updatedMarketValue: newChildUpdatedMarketValue,
                    marketValueChange: newChildUpdatedMarketValue - childSourceAccount?.originalMarketValue
                  }
                }
                // Update child target account market value
                const childTargetAccountIndex = updatedAccountDetailsList.findIndex((account) => account?.accountCd === child?.targetAccount?.accountCd)
                if (childTargetAccountIndex !== -1) {
                  const childTargetAccount = updatedAccountDetailsList[childTargetAccountIndex]
                  const newChildUpdatedMarketValue = (childTargetAccount?.updatedMarketValue ?? childTargetAccount?.originalMarketValue) + childMarketValueChange
                  updatedAccountDetailsList[childTargetAccountIndex] = {
                    ...childTargetAccount,
                    updatedMarketValue: newChildUpdatedMarketValue,
                    marketValueChange: newChildUpdatedMarketValue - childTargetAccount?.originalMarketValue
                  }
                }
              }
            })
          }
        }
      }
    })
    // Calculate the total updated market value of all accounts
    const totalUpdatedMarketValue = updatedAccountDetailsList?.reduce((total, account) => total + account?.updatedMarketValue, 0)
    // Recalculate the account allocation percentage based on updated market values
    const finalAccountDetailsList = updatedAccountDetailsList?.map((account) => ({
      ...account,
      newAccountAllocation: (account?.updatedMarketValue / totalUpdatedMarketValue) * 100 // New allocation based on updated market value
    }))
    return finalAccountDetailsList
  }

  useEffect(() => {
    if (Array.isArray(isMarketValueChange) && isMarketValueChange?.length > 0) {
      setAccountDetailsList(prevList => handleMarketValueChanges(isMarketValueChange))
    }
  }, [isMarketValueChange])

  const columns = [
    {
      field: 'accountName',
      headerName: 'Account Name',
      flex: 1,
      valueFormatter: (params) => params?.value ? params?.value : 'N/A'
    },
    {
      field: 'originalMarketValue',
      headerName: 'Current Market Value',
      flex: 1,
      type: 'number',
      valueGetter: (params) => {
        const value = params?.row?.originalMarketValue
        return value == null || isNaN(value) ? 0.00 : parseFloat(parseFloat(value).toFixed(2))
      },
      valueFormatter: (params) => params?.value
        ? formatNumbersWithSymbol(params?.value, 2, '$')
        : 0.00
    },
    {
      field: 'currentAccountAllocation',
      headerName: 'Current Account Allocation (%)',
      flex: 1,
      type: 'number',
      valueGetter: (params) => {
        const value = params?.row?.currentAccountAllocation
        return value == null || isNaN(value) ? 0.00 : parseFloat(value).toFixed(4)
      },
      valueFormatter: (params) => {
        const value = params?.value
        return value == null || isNaN(value) ? 0.00 : parseFloat(value).toFixed(4)
      }
    },
    {
      field: 'updatedMarketValue',
      headerName: 'New Market Value',
      flex: 1,
      type: 'number',
      valueGetter: (params) => {
        const value = params?.row?.updatedMarketValue
        return value == null || isNaN(value) ? 0.00 : parseFloat(parseFloat(value).toFixed(2))
      },
      renderCell: (params) => {
        const { currentAccountAllocation, newAccountAllocation } = params?.row
        const isDisabled = currentAccountAllocation?.toFixed(4) === newAccountAllocation?.toFixed(4)

        return (
          <Box
            sx={{
              color: isDisabled ? 'rgba(128, 128, 128, 0.5)' : 'inherit',
              pointerEvents: isDisabled ? 'none' : 'auto'
            }}
          >
            {formatNumbersWithSymbol(params?.value, 2, '$')}
          </Box>
        )
      }
    },
    {
      field: 'newAccountAllocation',
      headerName: 'New Account Allocation (%)',
      flex: 1,
      type: 'number',
      valueGetter: (params) => {
        const value = params?.row?.newAccountAllocation
        return value == null || isNaN(value) ? 0.00 : parseFloat(parseFloat(value).toFixed(4))
      },
      renderCell: (params) => {
        const { currentAccountAllocation, newAccountAllocation } = params?.row
        const isDisabled = currentAccountAllocation?.toFixed(4) === newAccountAllocation?.toFixed(4)

        return (
          <Box
            sx={{
              color: isDisabled ? 'rgba(128, 128, 128, 0.5)' : 'inherit',
              pointerEvents: isDisabled ? 'none' : 'auto'
            }}
          >
            {params?.value.toFixed(4)}
          </Box>
        )
      }
    },
    {
      field: 'marketValueChange',
      headerName: 'Market Value Change',
      flex: 1,
      type: 'number',
      valueGetter: (params) => {
        const value = params?.row?.marketValueChange
        return value == null || isNaN(value) ? 0.00 : parseFloat(parseFloat(value).toFixed(4))
      },
      renderCell: (params) => {
        const { currentAccountAllocation, newAccountAllocation } = params?.row
        const isDisabled = currentAccountAllocation?.toFixed(4) === newAccountAllocation?.toFixed(4)

        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'baseline',
              justifyContent: 'space-between',
              color: isDisabled ? 'rgba(128, 128, 128, 0.5)' : 'inherit',
              pointerEvents: isDisabled ? 'none' : 'auto'
            }}
          >
            {params?.value > 0 && (
              <Icon sx={{ color: isDisabled ? 'rgba(128, 128, 128, 0.5)' : '#3BBFA3' }}>
                <ArrowUpwardIcon sx={{ fontSize: '16px' }} />
              </Icon>
            )}
            {params?.value < 0 && (
              <Icon sx={{ color: isDisabled ? 'rgba(128, 128, 128, 0.5)' : '#F46A6A' }}>
                <ArrowDownwardIcon sx={{ fontSize: '16px' }} />
              </Icon>
            )}
            <Typography sx={{ fontFamily: 'Open Sans', fontSize: '16px', fontWeight: 600 }}>
              {formatNumbersWithSymbol(params?.value, 4, '$')}
            </Typography>
          </Box>
        )
      }
    }
  ]

  return (
    <>
      <Box sx={{ ...popupStyle, height: '600px' }}>
        <Grid container>
          <Grid xs={12} item sx={{ position: 'relative' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', mb: 2 }}>
              <Typography
                component='h1'
                sx={{
                  color: '#212529',
                  fontSize: '22px',
                  fontWeight: '400'
                }}
              >
                Market Value Change
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon fontSize='small' />
              </IconButton>
            </Box>
            <Box>
              <DataGridPro
                autoHeight
                density='compact'
                rows={accountDetailsList}
                columns={columns}
                disableRowSelectionOnClick
                getRowId={(row) => row?.id}
                pagination
                initialState={{
                  ...accountDetailsList?.initialState,
                  pagination: { paginationModel: { pageSize: 10 } }
                }}
                pageSizeOptions={[10, 15, 25]}
                sx={(theme) => ({
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none'
                  },
                  [`.${gridClasses.main}`]: {
                    height: `min(100vh - 84px - ${showConfirmButton ? '90px' : '40px'}, 600px - 84px - ${showConfirmButton ? '90px' : '40px'})`
                  },
                  [`.${gridClasses.columnHeaders}`]: {
                    position: 'sticky',
                    backgroundColor: theme.palette.background.paper,
                    top: 0,
                    zIndex: 1
                  },
                  [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                    color: '#74788d',
                    fontWeight: 600
                  },
                  [`.${gridClasses.virtualScroller}`]: {
                    overflowY: 'auto !important',
                    scrollbarGutter: 'stable',
                    scrollbarWidth: 'none'
                  }
                })}
              />
            </Box>
          </Grid>
        </Grid>
        {showConfirmButton
          ? <Box sx={{ position: 'absolute', bottom: '16px', right: '32px' }}>
            {postTaxlotSwapLoading
              ? (
                <LoadingButton loading loadingPosition='start' startIcon={<SaveIcon />} variant='outlined'>
                  Apply
                </LoadingButton>
                )
              : (
                <Button variant='contained' onClick={submitTaxlotSwap}>
                  Apply
                </Button>
                )}
          </Box>
          : ''}
      </Box>
    </>
  )
}
