import { Box, Button, Divider, IconButton, Tooltip, Typography } from '@mui/material'
import AllocationModelCards from './AllocationModelCards'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

const AllocationModelTab = (props) => {
  const { umaDetails, addAllocation, allocationCardData, setAllocationCardData, personalizationData, setPersonalizationData , setAllStepsVisited} = props

  return (
    <Box>
      <Typography sx={{ marginLeft: '5px', marginBottom: '10px', fontWeight: 600, color: '#74788D', fontSize: '17px', fontFamily: 'Open Sans' }}>Create Target Allocation Model</Typography>
      <Divider />
      {umaDetails?.accountType === 'transition' && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '3px', marginTop: '3px' }}>
          <Tooltip
            title='Maximum of 3 allocations allowed.'
            arrow
          >
            <span>
              <Button
                onClick={() => addAllocation()}
                sx={{ alignItems: 'flex-end' }}
                disabled={allocationCardData?.length >= 3}
              >
                <Box display='flex' alignItems='center'>
                  <Typography>
                    Add more Allocation
                  </Typography>
                  <IconButton>
                    <InfoOutlinedIcon fontSize='small' />
                  </IconButton>
                </Box>
              </Button>
            </span>
          </Tooltip>
        </Box>
      )}
      {allocationCardData?.map((allocation, index) => (
        <AllocationModelCards
          key={allocation.id}
          accountType={umaDetails?.accountType}
          defaultAllocationTitleId={index + 1}
          allocationId={allocation.id}
          allocationCardData={allocationCardData}
          setAllStepsVisited={setAllStepsVisited}
          setAllocationCardData={setAllocationCardData}
          personalizationData={personalizationData}
          setPersonalizationData={setPersonalizationData}
        />
      ))}
    </Box>
  )
}

export default AllocationModelTab
