/**
 * 
 * @param {Array} data Array of objects on which group is to be created
 * @param {number} index Current index/depth of groupBy
 * @param {Array} groupBy Field names to group
 * @returns {Object} group on field from current index of groupBy
 */
export function createHierarchy(data, index, groupBy) {
  if (index === groupBy.length) {
    return data
  }
  const groupedObject = groupByField(data, groupBy[index])
  for (const key in groupedObject) {
    groupedObject[key] = createHierarchy(groupedObject[key], index + 1, groupBy)
  }
  return groupedObject
}

function groupByField(data, field) {
  const group = {}
  const visitedFields = []
  data.forEach((element) => {
    if (visitedFields.includes(element[field])) {
      group[element[field]].push(element)
    } else {
      group[element[field]] = [element]
      visitedFields.push(element[field])
    }
  })
  return group
}