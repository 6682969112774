import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import * as Sentry from '@sentry/react'
import { API } from 'aws-amplify'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Divider, Tab, Table, TableBody, TableHead, TableRow } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { CatchError } from '../../../utils/CatchError'
import ErrorFallback from '../../ErrorFallback'
import { propertiesTableHeaders, tableData } from '../components/PersonalizationHeaders'
import { factoryDataFormatter, industryDataFormatter, instrumentDataFormatter, sectorDataFormatter, substitutionDataFormatter } from '../components/TradeDataFormatting'
import TradePersonalizationTab from '../components/TradePersonalizationTab'
import StyledTradePopupBox from '../components/StyledTradePopupBox'

const ViewOptPropertiesHistory = (selectedItem) => {
  const [approvalPropertiesLoading, setApprovalPropertiesLoading] = useState(true)
  const [otherPropertiesLoading, setOtherPropertiesLoading] = useState(true)
  const [approvalProperties, setApprovalProperties] = useState()
  const [optMapId] = useState({ selectedItem })
  const [optData, setOptData] = useState([])
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const [value, setValue] = useState('0')
  const screen = 'not main'

  const viewOptPropertiesTabs =
  ['Properties', 'Sector', 'Industry', 'Factor Tilts', 'Substitutions', 'Instrument']

  const handleTabPanelChange = (event, newValue) => {
    setValue(newValue)
  }

  const getOptimizationPersonalization = async () => {
    try {
      const response = await API.get('baseUriTrade', `trade/v1/${user?.userGroup}/trade-personalization/${optMapId?.selectedItem?.selectedItem?.optId}`)
      if (response) {
        setOptData(response?.data)
        setOtherPropertiesLoading(false)
      }
    } catch (error) {
      if (error) {
        setOtherPropertiesLoading(false)
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      }
    }
  }

  const renderTabPanel = (item) => {
    switch (item) {
      case 'Properties' : {
        if (approvalPropertiesLoading) {
          return (
            <>
              {/* dynamically changes skeleton for all diff personalization tabs */}
              <Table className='security-holdings-table'>
                <TableHead>
                  <TableRow style={{ position: 'sticky', top: '0', background: 'white' }}>
                    {tableData[item]?.tableHeaderHTML}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {tableData[item]?.rowSkeleton}
                  </TableRow>
                  <TableRow>
                    {tableData[item]?.rowSkeleton}
                  </TableRow>
                  <TableRow>
                    {tableData[item]?.rowSkeleton}
                  </TableRow>
                </TableBody>
              </Table>
            </>
          )
        } else {
          return (
            <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
              <DataGridPro
                density='compact'
                autoHeight
                rows={approvalProperties || []}
                columns={propertiesTableHeaders || []}
                loading={approvalPropertiesLoading}
                getRowId={getRowId}
                pagination
                pageSizeOptions={[10]}
                initialState={{
                  ...approvalProperties?.initialState,
                  pagination: { paginationModel: { pageSize: 10 } }
                }}
              />
            </ErrorBoundary>
          )
        }
      }
      // for all other tabs call TradePersonalizationTab to get data or loading screen(skeleton)
      case 'Sector': {
        const sector = sectorDataFormatter(optData, 'SECTOR_RESTRICTIONS')
        return (
          <TradePersonalizationTab
            loading={otherPropertiesLoading}
            header='Sector'
            tableData={tableData}
            item={item}
            type={sector}
            screen={screen}
            rowData={sector?.sectorData}
          />
        )
      }
      case 'Industry': {
        const industry = industryDataFormatter(optData, 'INDUSTRY_RESTRICTIONS')
        return (
          <TradePersonalizationTab
            loading={otherPropertiesLoading}
            header='Industry'
            tableData={tableData}
            item={item}
            type={industry}
            screen={screen}
            rowData={industry?.industryData}
          />
        )
      }
      case 'Factor Tilts': {
        const factorTilts = factoryDataFormatter(optData, 'FACTOR_TILTS')
        return (
          <TradePersonalizationTab
            loading={otherPropertiesLoading}
            header='Factor Tilts'
            tableData={tableData}
            item={item}
            type={factorTilts}
            screen={screen}
            rowData={factorTilts?.factoryData}
          />
        )
      }
      case 'Substitutions': {
        const substitutions = substitutionDataFormatter(optData, 'INSTRUMENT_SUBSTITUTIONS')
        return (
          <TradePersonalizationTab
            loading={otherPropertiesLoading}
            header='Substitutions'
            tableData={tableData}
            item={item}
            type={substitutions}
            screen={screen}
            rowData={substitutions?.subData}
          />
        )
      }
      case 'Instrument': {
        const instrument = instrumentDataFormatter(optData, 'INSTRUMENT_RESTRICTIONS')
        return (
          <TradePersonalizationTab
            loading={otherPropertiesLoading}
            header='Instrument'
            tableData={tableData}
            item={item}
            type={instrument}
            screen={screen}
            rowData={instrument?.instrumentData}
          />
        )
      }
      default:
        return 'Something went wrong'
    }
  }

  const getApprovalProperties = async (optMapId) => {
    const accountOptMapId =
      optMapId.selectedItem.selectedItem.optDetails[0].accOptMapId
    API.post('baseUriTrade', `trade/v1/${user?.userGroup}/trade-properties`, {
      body: {
        accOptMapId: [accountOptMapId]
      }
    })
      .then((response) => {
        setApprovalPropertiesLoading(false)

        if (response?.data?.data && response?.data?.data?.length > 0) {
          setApprovalProperties(response?.data?.data?.map((row) => ({ ...row, id: randomId() })))
        }
      })
      .catch((error) => {
        showError(error?.response?.data?.errorInfo?.userMessage)
        setApprovalPropertiesLoading(false)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      }).finally(() => {
        setApprovalPropertiesLoading(false)
      })
  }

  // useEffect is used here to the get api function to fetch the data intially.
  useEffect(() => {
    if (user) {
      getOptimizationPersonalization()
      getApprovalProperties(optMapId)
    }
  }, [user])

  if (approvalProperties === null) {
    return null
  }

  const getRowId = (row) => row.id

  return (
    <>
      <StyledTradePopupBox
        className='table-responsive' sx={{
          maxHeight: '500px !important'
        }}
      >
        <TabContext value={value}>
          <TabList
            onChange={handleTabPanelChange}
            variant='scrollable'
            scrollButtons={false}
            TabIndicatorProps={{
              style: {
                backgroundColor: '#34475A'
              }
            }}
            indicatorColor='#34475A'
          >
            {viewOptPropertiesTabs?.map((item, index) => (
              <Tab
                className='reportCardNav' key={index} value={index.toString()} label={item} disabled={item === 'Geography'} sx={{
                  opacity: item === 'Geography' ? 0.5 : 1
                }}
              />
            ))}
          </TabList>
          <Divider sx={{
            bgcolor: 'rgba(116, 120, 141, 0.15)'
          }}
          />
          {viewOptPropertiesTabs?.map((item, index) => (
            <TabPanel
              key={index}
              value={index.toString()}
              sx={{ px: 0 }}
            >
              <>
                {renderTabPanel(item)}
              </>
            </TabPanel>
          ))}
        </TabContext>
      </StyledTradePopupBox>
    </>
  )
}
export default ViewOptPropertiesHistory
