import { useEffect, useState } from 'react'
import { Box, Grid, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import { RefreshOutlined } from '@mui/icons-material'
import { FaChartColumn } from 'react-icons/fa6'
import { benchmarkData as benchmark, marketValueData as marketValue, performanceData as performance } from '../../IPSAggregate/data'
import PerformanceChart from './PerformanceChart'
import MarketValueChart from './MarketValueChart'
import BenchmarkChart from './BenchmarkChart'

const PerformanceTab = () => {
  const [isChartZoomed, setIsChartZoomed] = useState(false)
  const [seed, setSeed] = useState(0)
  const [graphTypeAnchorEl, setGraphTypeAnchorEl] = useState(null)
  const [selectedGraph, setSelectedGraph] = useState('PERFORMANCE')
  const [performanceData, setPerformanceData] = useState([])
  const [marketValueData, setMarketValueData] = useState([])
  const [benchmarkData, setBenchmarkData] = useState([])
  const type = 'Scenario Transition'

  useEffect(() => {
    setPerformanceData([
      {
        name: 'Pre Tax',
        data: performance.map(obj => ({
          x: obj?.dataDate,
          y: obj?.cumulatedPreTaxDailyReturns * 100,
        }))
      },
      ...(type === 'Trading' ? [
        {
          name: 'Post Tax',
          data: performance.map(obj => ({
            x: obj?.dataDate,
            y: obj?.cumulatedPostTaxDailyReturns * 100
          }))
        }
      ] : []),
      ...(type === 'Scenario Transition' ? [
        {
          name: 'Pre Tax Original UMA',
          data: performance.map(obj => ({
            x: obj?.dataDate,
            y: obj?.preTaxOriginalUmaReturns * 100
          }))
        }
      ] : [])
    ])
  }, [performance])

  useEffect(() => {
    setMarketValueData([
      {
        name: 'Market Value Returns',
        data: marketValue?.map((obj) => ({
          x: new Date(obj.dataDate).getTime(),
          y: obj?.metric?.NET_MV_PLUS_CASH * 100
        }))
      }
    ])
  }, [marketValue])

  useEffect(() => {
    setBenchmarkData([
      {
        name: 'Portfolio',
        data: benchmark.map(obj => ({
          x: new Date(obj.dataDate).getTime(),
          y: obj?.accCumulatedPreTaxDailyReturns * 100
        }))
      },
      {
        name: 'Strategy',
        data: benchmark?.map((obj) => ({
          x: new Date(obj.dataDate).getTime(),
          y: obj?.strCumulatedPreTaxDailyReturns * 100
        }))
      },
      ...(type === 'Scenario Transition' ? [
        {
          name: 'Original UMA',
          data: benchmark?.map(obj => ({
            x: new Date(obj.dataDate).getTime(),
            y: obj?.strPreTaxDailyReturns * 100
          }))
        }
      ] : [])
    ])
  }, [benchmark])

  function resetZoom() {
    setSeed(Math.random())
    setIsChartZoomed(false)
  }

  const handleGraphChange = (graph) => {
    setSelectedGraph(graph)
    setGraphTypeAnchorEl(null)
    document.body.classList.remove('model-open')
  }

  const formatChartTooltipLabel = (graph) => {
    switch (graph) {
      case 'PERFORMANCE':
        return 'Performance'
      case 'MV':
        return 'Market Value'
      case 'BENCHMARK_RETURNS':
        return 'Benchmark Returns'
      default:
        return graph
    }
  }

  const handleChartClick = (event) => {
    document.body.classList.add('model-open')
    setGraphTypeAnchorEl(event.currentTarget)
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={12} sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', height: '36px' }}>
          <Typography sx={{ fontSize: '16px', color: '#74788D', fontWeight: 600 }}>{formatChartTooltipLabel(selectedGraph)}</Typography>
          <Box sx={{ display: 'flex' }}>
            {
              isChartZoomed ? (
                <Tooltip title='Reset Chart'>
                  <IconButton onClick={resetZoom}>
                    <RefreshOutlined sx={{ height: 20, width: 20, color: '#74788D' }} />
                  </IconButton>
                </Tooltip>
              ) : ''
            }

            <Tooltip title={formatChartTooltipLabel(selectedGraph)}>
              <IconButton onClick={handleChartClick}>
                <FaChartColumn size='15px' color='#74788D' />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={graphTypeAnchorEl}
              open={Boolean(graphTypeAnchorEl)}
              onClose={() => setGraphTypeAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              size='small'
            >
              <MenuItem onClick={() => handleGraphChange('PERFORMANCE')} value='PERFORMANCE'>
                Performance
              </MenuItem>
              <MenuItem onClick={() => handleGraphChange('MV')} value='MV'>
                Market Value
              </MenuItem>
              <MenuItem onClick={() => handleGraphChange('BENCHMARK_RETURNS')} value='BENCHMARK_RETURNS'>
                Benchmark Returns
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} sx={{ height: '500px' }}>
        {selectedGraph === 'PERFORMANCE' ? <PerformanceChart data={performanceData} seed={seed} setIsChartZoomed={setIsChartZoomed} /> : ''}
        {selectedGraph === 'MV' ? <MarketValueChart data={marketValueData} seed={seed} setIsChartZoomed={setIsChartZoomed} /> : ''}
        {selectedGraph === 'BENCHMARK_RETURNS' ? <BenchmarkChart data={benchmarkData} seed={seed} setIsChartZoomed={setIsChartZoomed} /> : ''}
      </Grid>
    </Grid>
  )
}

export default PerformanceTab