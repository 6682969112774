import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ExpandMore } from '@mui/icons-material'
import { Box, Divider, IconButton, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, gridFilteredDescendantCountLookupSelector, gridClasses, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro'
import redirect from '../../../assets/images/pop-out-line.svg'
import { formatCurrencyWithSymbol } from '../../../utils/FormateCurrenyInMilion'
import { taxlotData as taxlot, scenarioData as scenario } from '../IPSAggregate/data'
import { createHierarchy } from '../../../utils/groupData'
import CustomGridTreeDataGroupingCell from './CustomGridTreeDataGroupingCell'

const columns = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1
  },
  {
    field: 'weight',
    headerName: 'Weight',
    type: 'number',
    align: 'right',
    flex: 1,
    valueGetter: (params) => params?.row?.weight !== undefined && params?.row?.weight !== null ? params?.row?.weight?.toFixed(2) : 'NA'
  },
  {
    field: 'marketValue',
    headerName: 'Market Value',
    type: 'number',
    align: 'right',
    flex: 1,
    valueGetter: (params) => params?.value ? params?.value?.toFixed(0) : params?.value,
    valueFormatter: (params) => typeof params?.value !== 'undefined' ? params?.value !== null ? formatCurrencyWithSymbol(params?.value, 0, '$') : 'NA' : '',
  },
  {
    field: 'urgl',
    headerName: 'Unrealized Gain/Loss',
    type: 'number',
    align: 'right',
    flex: 1,
    valueGetter: (params) => params?.value ? params?.value?.toFixed(0) : params?.value,
    valueFormatter: (params) => typeof params?.value !== 'undefined' ? params?.value !== null ? formatCurrencyWithSymbol(params?.value, 0, '$') : 'NA' : '',
  }
]

const scenarioCols = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1
  },
  {
    field: 'weight',
    headerName: 'Weight',
    type: 'number',
    align: 'right',
    flex: 1,
    valueGetter: (params) => typeof params?.row?.weight !== 'undefined' && params?.row?.weight !== null ? params?.row?.weight?.toFixed(2) : 'NA'
  },
  {
    field: 'marketValue',
    headerName: 'Market Value',
    type: 'number',
    align: 'right',
    flex: 1,
    valueGetter: (params) => params?.value ? params?.value?.toFixed(0) : params?.value,
    valueFormatter: (params) => typeof params?.value !== 'undefined' ? params?.value !== null ? formatCurrencyWithSymbol(params?.value, 0, '$') : 'NA' : '',
  },
  {
    field: 'urgl',
    headerName: 'Unrealized Gain/Loss',
    type: 'number',
    align: 'right',
    flex: 1,
    valueGetter: (params) => params?.value ? params?.value?.toFixed(0) : params?.value,
    valueFormatter: (params) => typeof params?.value !== 'undefined' ? params?.value !== null ? formatCurrencyWithSymbol(params?.value, 0, '$') : 'NA' : '',
  },
  {
    field: 'rgl',
    headerName: 'Realized Gain/Loss',
    type: 'number',
    align: 'right',
    flex: 1,
    valueGetter: (params) => params?.value ? params?.value?.toFixed(0) : params?.value,
    valueFormatter: (params) => typeof params?.value !== 'undefined' ? params?.value !== null ? formatCurrencyWithSymbol(params?.value, 0, '$') : 'NA' : '',
  }
]

function generateArray(key, hierarchy, finalArray, index, datagridHierarchy, fieldsRequiredForSummation) {
  const arrayFromCurrentLevel = []
  datagridHierarchy.push(key)
  const aggregatedFieldsFromChild = hierarchyToArray(hierarchy[key], arrayFromCurrentLevel, index + 1, datagridHierarchy)
  if (key !== 'Untagged' && key !== 'null')
    arrayFromCurrentLevel.unshift({ ...aggregatedFieldsFromChild, hierarchy: [...datagridHierarchy], name: key, currLevel: index })
  datagridHierarchy.pop()
  // add the generated child hierarchy array into final array
  arrayFromCurrentLevel.forEach(obj => {
    finalArray.push(obj)
  })
  // add up the required fields properties from sibling to show in parent
  for (let key in fieldsRequiredForSummation) {
    fieldsRequiredForSummation[key] += aggregatedFieldsFromChild[key]
  }
}

function hierarchyToArray(hierarchy, finalArray, index, datagridHierarchy) {
  const fieldsRequiredForSummation = { weight: 0, marketValue: 0, urgl: 0, rgl: 0 }
  if (Array.isArray(hierarchy)) {
    fieldsRequiredForSummation.accountId = hierarchy.length ? hierarchy[0]?.accountId : null
    hierarchy.forEach((object) => {
      fieldsRequiredForSummation.weight += object?.weight || 0
      fieldsRequiredForSummation.marketValue += object?.marketValue || 0
      fieldsRequiredForSummation.urgl += object?.urgl || 0
      fieldsRequiredForSummation.rgl += object?.rgl || 0
    })
    return fieldsRequiredForSummation
  }
  let untaggedFlag = false
  for (const key in hierarchy) {
    if (key === 'Untagged' || key === 'null') {
      untaggedFlag = true
      continue
    }
    generateArray(key, hierarchy, finalArray, index, datagridHierarchy, fieldsRequiredForSummation)
  }
  if (untaggedFlag)
    generateArray('Untagged', hierarchy, finalArray, index, datagridHierarchy, fieldsRequiredForSummation)
  return fieldsRequiredForSummation
}

const PortfolioTable = ({ data, loading }) => {
  const [treeData, setTreeData] = useState([])
  const [scenarioData, setScenarioData] = useState([])

  useEffect(() => {
    const finalArray = []
    hierarchyToArray(createHierarchy(taxlot, 0, ['accountName']), finalArray, 0, [])
    setTreeData(finalArray)
  }, [taxlot])

  useEffect(() => {
    const finalArray = []
    hierarchyToArray(createHierarchy(scenario, 0, ['accountName']), finalArray, 0, [])
    setScenarioData(finalArray)
  }, [scenario])

  return (
    <>
      <Box sx={{ position: 'relative' }} className='aggregate-portfolio-table'>
        <Typography sx={{ fontSize: '16px', fontWeight: 600, color: '#74788D', mt: '10px' }}>Current Portfolio Allocation</Typography>
        {
          loading
            ? <TableContainer mt={10}>
              <Table className='risk-page-table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    {columns.map((item, index) => {
                      return (
                        <TableCell key={index}>{item.headerName}</TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.from({ length: 5 }).map((_, i) => (
                    <TableRow key={i}>
                      {Array.from({ length: columns.length }).map((_, j) => (
                        <TableCell key={j}>
                          <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                        </TableCell>
                      ))}
                      <TableCell><Skeleton variant='text' /></TableCell>
                    </TableRow>))}
                </TableBody>
              </Table>
            </TableContainer>
            : <DataGridPro
              density='compact'
              rows={treeData}
              columns={columns}
              autoHeight
              getRowId={(row) => row?.accountId}
              disableRowSelectionOnClick
              localeText={{ noRowsLabel: 'No Portfolio Found' }}
              hideFooter={treeData?.length === 0}
              pageSizeOptions={[10]}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } }
              }}
              pagination
              sx={(theme) => ({
                [`.${gridClasses.main}`]: {
                  overflow: 'unset'
                },
                [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                  color: '#74788d',
                  fontWeight: 600
                },
                [`.${gridClasses.columnHeaders}`]: {
                  position: 'sticky',
                  top: '-20px',
                  zIndex: 3,
                  backgroundColor: 'white'
                },
                [`.${gridClasses.footerContainer}`]: {
                  position: 'sticky',
                  bottom: '-21px',
                  zIndex: 3,
                  backgroundColor: 'white'
                },
              })}
            />
        }
        <Divider />
        <Box sx={{ mt: '10px' }}>
          <Typography sx={{ fontSize: '16px', fontWeight: 600, color: '#74788D', my: '10px' }}>Original UMA Allocation</Typography>
          {
            loading
              ? <TableContainer mt={10}>
                <Table className='risk-page-table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      {columns.map((item, index) => {
                        return (
                          <TableCell key={index}>{item.headerName}</TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.from({ length: 5 }).map((_, i) => (
                      <TableRow key={i}>
                        {Array.from({ length: columns.length }).map((_, j) => (
                          <TableCell key={j}>
                            <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                          </TableCell>
                        ))}
                        <TableCell><Skeleton variant='text' /></TableCell>
                      </TableRow>))}
                  </TableBody>
                </Table>
              </TableContainer>
              : <DataGridPro
                density='compact'
                rows={scenarioData}
                columns={scenarioCols}
                autoHeight
                getRowId={(row) => row?.accountId}
                disableRowSelectionOnClick
                localeText={{ noRowsLabel: 'No Portfolio Found' }}
                hideFooter={treeData?.length === 0}
                pageSizeOptions={[10]}
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } }
                }}
                pagination
                sx={(theme) => ({
                  [`.${gridClasses.main}`]: {
                    overflow: 'unset'
                  },
                  [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                    color: '#74788d',
                    fontWeight: 600
                  },
                  [`.${gridClasses.columnHeaders}`]: {
                    position: 'sticky',
                    top: '-20px',
                    zIndex: 3,
                    backgroundColor: 'white'
                  },
                  [`.${gridClasses.footerContainer}`]: {
                    position: 'sticky',
                    bottom: '-21px',
                    zIndex: 3,
                    backgroundColor: 'white'
                  },
                })}
              />
          }
        </Box>
      </Box>
    </>
  )
}

export default PortfolioTable