import { Fragment, useEffect, useState } from 'react'
import { InfoOutlined } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  tooltipClasses
} from '@mui/material'
import { styled } from '@mui/material/styles'
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined'
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined'
import { isNumberOnly } from '../../../utils/NumberUtils'
import { InputAdornmentTextField } from '../../../pages/AccountOnboarding/components/inputAdornmentTextfield'
import { useIpsAccess } from '../../../hooks/useIpsAccess'
import { useAuth } from '../../../contexts/AuthContext'

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: '#FFFFFF',
    marginLeft: '120px',
    boxShadow: '0px 1.26482px 12.6482px rgba(0, 0, 0, 0.15)',
    fontSize: '12px',
    color: '#34475A'
  }
}))
export const EditDialog = ({ open, onClose, data, onSave, isUnderApproval }) => {
  const [editData, setEditData] = useState([])
  const { user } = useAuth()
  const policyEditAccess = useIpsAccess('Policy')

  useEffect(() => {
    if (data) {
      setEditData(data)
    }
  }, [data])

  const handleEditInputChange = (event, paramName) => {
    const { value } = event.target
    const updatedEditData = {
      ...editData,
      parameters: editData.parameters.map((item) =>
        item.paramName === paramName ? { ...item, value } : item
      )
    }
    setEditData(updatedEditData)
  }

  const handleSaveClick = () => {
    onSave(editData)
  }

  const handleEditDialogClose = () => {
    onClose()
  }

  return (
    <Dialog
      open={open} onClose={onClose} maxWidth='sm'
    >
      <DialogTitle sx={{ fontSize: '18px', paddingBottom: '10px', fontFamily: 'Open Sans', fontWeight: 600 }}>{editData.policyTitle || []}</DialogTitle>
      {editData.policyText?.includes('policy trading')
        ? <Box sx={{ display: 'flex' }}>
          <Typography ml={3} mb={1}>Policy Trading</Typography>
          <LightTooltip
            title='Policy Trading shows the criteria that will trigger Aris PM to trade in the account to meet the requirement within a specified number of days after the criteria are met.'
            placement='bottom'
          >
            <InfoOutlined fontSize='small' sx={{ marginLeft: '8px', marginTop: '2px' }} />
          </LightTooltip>
          </Box>
        : ''}
      {editData.policyText?.includes('policy review')
        ? <Box sx={{ display: 'flex' }}>
          <Typography ml={3} mb={1}>Policy Review</Typography>
          <LightTooltip
            title='Policy Review shows the criteria that will trigger Aris PM to review trades in the account within a specified number of days after the criteria are met, but it may not necessarily trigger a trade.'
            placement='bottom'
          >
            <InfoOutlined fontSize='small' sx={{ marginLeft: '8px', marginTop: '2px' }} />
          </LightTooltip>
        </Box>
        : ''}
      <DialogContent
        maxWidth='md'
        sx={{
          paddingTop: '10px !important',
          minWidth: '400px'
        }}
      >
        <Box component='form' sx={{ display: 'flex', flexDirection: 'column' }}>
          {editData.parameters?.map((item, index) => {
            const defaultOption = item.options.find((option) => option.isDefault === 1)
            const selectedValue = item.value || (defaultOption ? defaultOption.optionValue : '')

            return (
              <Fragment key={index}>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  {
                    (item.options.length && item.options.every(option => option.optionValue !== null))
                      ? (
                        <>
                          <InputLabel id={`value-select-label-${index}`}>{item.paramName}</InputLabel>
                          <Select
                            labelId={`value-select-label-${index}`}
                            id={`value-simple-select-${index}`}
                            value={selectedValue}
                            label={item.paramName || 'Value'}
                            onChange={(e) => handleEditInputChange(e, item.paramName)}
                            disabled={selectedValue === '' ||
                             ((data.status === 'UNDER_APPROVAL' || data.status === 'APPROVED' || data.status === 'DEACTIVATING') &&
                             (user?.userGroup !== 'pm' && policyEditAccess ? isUnderApproval : true))}
                          >
                            {item.options?.map((option, idx) => {
                              const label = item.uom !== 'NA' ? `${option.optionValue} ${item.uom}` : option.optionValue
                              return (
                                <MenuItem key={idx} value={option.optionValue}>
                                  {label}
                                </MenuItem>
                              )
                            })}
                          </Select>
                        </>)
                      : item.uom === '$'
                        ? (
                          <InputAdornmentTextField
                            value={selectedValue}
                            label={item.paramName || 'Value'}
                            disabled={((data.status === 'UNDER_APPROVAL' || data.status === 'APPROVED' || data.status === 'DEACTIVATING') &&
                             (user?.userGroup !== 'pm' && policyEditAccess ? isUnderApproval : true))}
                            onKeyDown={isNumberOnly}
                            onChange={(e) => handleEditInputChange(e, item.paramName)}
                            adornment={<AttachMoneyOutlinedIcon />}
                          />
                          )
                        : (
                          <TextField
                            labelId={`value-select-label-${index}`}
                            id={`value-simple-select-${index}`}
                            value={selectedValue}
                            label={item.paramName || 'Value'}
                            disabled={((data.status === 'UNDER_APPROVAL' || data.status === 'APPROVED' || data.status === 'DEACTIVATING') &&
                             (user?.userGroup !== 'pm' && policyEditAccess ? isUnderApproval : true))}
                            onKeyDown={isNumberOnly}
                            onChange={(e) => handleEditInputChange(e, item.paramName)}
                            InputProps={{
                              endAdornment: item.uom === '%'
                                ? <InputAdornment
                                    position='end'
                                  >
                                  <PercentOutlinedIcon sx={{
                                    width: 18,
                                    height: 18
                                  }}
                                  />
                                  </InputAdornment>
                                : null
                            }}
                          />)
                      }
                </FormControl>

              </Fragment>
            )
          })}
        </Box>
      </DialogContent>
      <Box
        p={2} display={((data.status === 'UNDER_APPROVAL' || data.status === 'APPROVED' || data.status === 'DEACTIVATING') &&
       (user?.userGroup !== 'pm' && policyEditAccess ? isUnderApproval : true))
          ? 'none'
          : 'flex'} justifyContent='flex-end'
      >
        <Button variant='text' sx={{ marginRight: '1em' }} onClick={handleEditDialogClose}>Cancel</Button>
        <Button variant='contained' onClick={handleSaveClick}>Save</Button>
      </Box>
    </Dialog>
  )
}
