import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { Auth } from 'aws-amplify'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import SaveIcon from '@mui/icons-material/Save'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Button, Grid, Stack, Typography, FormControl, FormHelperText } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import { styled } from '@mui/system'
import { Field, Formik } from 'formik'
import { MuiOtpInput } from 'mui-one-time-password-input'
import { object, ref, string } from 'yup'
import Alert from '../../components/Alert'
import CustomOutlinedInput from '../../components/CustomOutlinedInput'
import { useErrorToast } from '../../hooks/useErrorToast'

const CustomFormLabel = styled(Typography)(({ theme }) => ({
  color: '#002A59',
  fontFamily: 'Open Sans !important',
  fontWeight: '600 !important'
}))

const DEFAULT_TIMER = 60

export const ForgotPasswordChange = (props) => {
  const [passChangeSuccess, setPassChangeSuccess] = useState(false)
  const { showError } = useErrorToast()
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const navigate = useNavigate()
  const [showResend, setShowResend] = useState(false)
  const [counter, setCounter] = useState(DEFAULT_TIMER)

  useEffect(() => {
    setCounter(DEFAULT_TIMER)
  }, [props.email])

  // Start the timer to show resend OTP option once OTP is sent
  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
    return () => clearInterval(timer)
  }, [counter])

  // Enable resend OTP link after 60 seconds
  useEffect(() => {
    if (counter === 0) {
      setShowResend(true)
    }
  }, [counter])

  const handleOptVerification = async (otp, newPassword, resetFormCallback) => {
    // Open the otp verification screen and set the new password
    Auth.forgotPasswordSubmit(props?.email, otp, newPassword)
      .then(data => {
        setPassChangeSuccess(true)
        resetFormCallback()
      })
      .catch(error => {
        showError(error.message)
        Sentry.captureException(error.message)
      })
      .finally(() => setIsSubmitted(false))
  }

  const _handleModalClose = () => {
    setPassChangeSuccess(false)
    navigate('/login')
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show)
  }
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show)
  }

  const renderModal = () => {
    return (
      <Alert
        isOpen={passChangeSuccess}
        handleClose={_handleModalClose}
        handleSubmit={_handleModalClose}
        text='Password changed successfully'
        submitButtonText='Continue'
      />
    )
  }

  const _handleSubmit = async ({ newPass, confirmPass, otp, setSubmitting, resetForm }) => {
    setIsSubmitted(true)
    setSubmitting(false)
    handleOptVerification(otp, newPass, resetForm)
  }

  const handleResendOTP = () => {
    Auth.forgotPassword(props.email)
      .then(() => {
        setCounter(DEFAULT_TIMER)
      })
      .catch(error => {
        showError(error.message)
        Sentry.captureException(error.message)
      })
      .finally(() => setShowResend(false))
  }

  return (
    <Formik
      initialValues={{ newPass: '', confirmPass: '', otp: '' }}
      validateOnBlur={false}
      validate={(values) => {
        const errors = {}
        if (!values.newPass) {
          errors.newPass = 'Required'
        } else if (values.newPass.length < 8 || !(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).*$/).test(values.newPass || '')) {
          errors.newPass = 'Password must have minimum 8 characters and must contain at least one uppercase, lowercase letter, one digit and one special character'
        }
        if (!values.confirmPass) {
          errors.confirmPass = 'Required'
        } else if (values.confirmPass !== values.newPass) {
          errors.confirmPass = 'Passwords do not match'
        }
        if (!values.otp) {
          errors.otp = 'Required'
        } else if (values.otp.length < 6) {
          errors.otp = 'OTP must be a 6-digit code'
        }
        return errors
      }}
      validationSchema={object().shape({
        newPass: string()
          .required('New password is required')
          .min(8, 'Password must have minimum 8 characters and must contain at least one uppercase, lowercase letter, one digit and one special character')
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).*$/,
            'Password must have minimum 8 characters and must contain at least one uppercase, lowercase letter, one digit and one special character'
          ),
        confirmPass: string()
          .oneOf([ref('newPass')], 'Passwords do not match')
          .required('Password is required'),
        otp: string().required('OTP required').length(6, 'OTP must be a 6-digit code')
      })}
      onSubmit={({ newPass, confirmPass, otp }, { setSubmitting, resetForm }) =>
        _handleSubmit({ newPass, confirmPass, otp, setSubmitting, resetForm })}
    >
      {(props) => {
        const { values, touched, errors, setFieldValue, handleChange, handleBlur, handleSubmit, isValid, isSubmitting } = props
        return (
          <Stack sx={{ mt: 4 }}>
            <form className='form' onSubmit={handleSubmit}>
              <FormControl fullWidth margin='dense'>
                <Grid container justifyContent='space-between' alignItems='center'>
                  <Grid item>
                    <CustomFormLabel gutterBottom>
                      OTP
                    </CustomFormLabel>
                  </Grid>
                  <Grid item>
                    <Box>
                      {showResend
                        ? (
                          <span style={{ cursor: 'pointer', fontSize: '14px', textDecoration: 'underline' }} onClick={handleResendOTP}>Resend OTP</span>
                          )
                        : (
                          <Typography fontSize={14} align='center' color='textSecondary'> Resend OTP in <span style={{ fontWeight: '600' }}> 00:{('0' + counter).slice(-2)}</span> </Typography>
                          )}
                    </Box>
                  </Grid>
                </Grid>
                <Field
                  name='otp'
                >
                  {({
                    field
                  }) => (
                    <Box>
                      <MuiOtpInput
                        TextFieldsProps={{ placeholder: '-' }}
                        sx={{
                          gap: {
                            xs: '5px',
                            sm: '12px'
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none'
                          },
                          '& .MuiOutlinedInput-root': {
                            borderRadius: 2,
                            border: '1.5px solid #e3e4e8',
                            height: {
                              xs: '35px',
                              sm: '40px'
                            }
                          },
                          '& .MuiInputBase-input': {
                            padding: '8px'
                          },
                          '& .MuiOtpInput-TextField': {
                            borderRadius: 2
                          }
                        }}
                        autoFocus
                        {...field}
                        length={6}
                        value={field.value}
                        onChange={(value) => setFieldValue(field.name, value)}
                      />
                      <FormHelperText error={Boolean(touched && errors.otp)}>
                        {touched && errors.otp ? errors.otp : ''}
                      </FormHelperText>
                    </Box>
                  )}
                </Field>
              </FormControl>
              <FormControl fullWidth margin='dense' error={Boolean(touched.newPass && errors.newPass)}>
                <CustomFormLabel gutterBottom>
                  New password
                </CustomFormLabel>
                <CustomOutlinedInput
                  placeholder='Enter new password'
                  id='password-new'
                  name='newPass'
                  type={showPassword ? 'text' : 'password'}
                  value={values.newPass}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitted}
                  error={Boolean(touched.newPass && errors.newPass)}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                        sx={{
                          color: '#817D86',
                          fontSize: '22px'
                        }}
                      >
                        {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                      </IconButton>
                    </InputAdornment>
                    }
                  startAdornment={
                    <InputAdornment position='start'>
                      <LockOutlinedIcon
                        aria-label='password'
                        edge='end'
                        fontSize='small'
                        sx={{
                          color: '#3369A6',
                          fontSize: '22px'
                        }}
                      />
                    </InputAdornment>
                    }
                />
                <FormHelperText error={Boolean(touched.newPass && errors.newPass)}>
                  {touched.newPass && errors.newPass ? errors.newPass : ''}
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth margin='dense' error={Boolean(touched.confirmPass && errors.confirmPass)}>
                <CustomFormLabel gutterBottom>
                  Confirm password
                </CustomFormLabel>
                <CustomOutlinedInput
                  placeholder='Enter confirm password'
                  id='password-confirm'
                  name='confirmPass'
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={values.confirmPass}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitted}
                  error={Boolean(touched.confirmPass && errors.confirmPass)}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                        sx={{
                          color: '#817D86',
                          fontSize: '22px'
                        }}
                      >
                        {showConfirmPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                      </IconButton>
                    </InputAdornment>
                    }
                  startAdornment={
                    <InputAdornment position='start'>
                      <LockOutlinedIcon
                        aria-label='password'
                        edge='end'
                        sx={{
                          color: '#3369A6',
                          fontSize: '22px'
                        }}
                      />
                    </InputAdornment>
                    }
                />
                <FormHelperText error={Boolean(touched.confirmPass && errors.confirmPass)}>
                  {touched.confirmPass && errors.confirmPass ? errors.confirmPass : ''}
                </FormHelperText>
              </FormControl>
              <Box sx={{ ml: 'auto', mt: 2 }}>
                {isSubmitted
                  ? (<LoadingButton
                      loading
                      loadingPosition='start'
                      startIcon={<SaveIcon />}
                      variant='outlined'
                      fullWidth
                      sx={{
                        padding: 2
                      }}
                     >
                    Submit
                     </LoadingButton>)
                  : <Button
                      fullWidth
                      type='submit'
                      variant='contained'
                      disabled={Boolean(!isValid || isSubmitting)}
                      size='large'
                      sx={{
                        padding: 2
                      }}
                    >
                    Submit
                  </Button>}
              </Box>
            </form>
            {passChangeSuccess ? renderModal() : ''}
          </Stack>
        )
      }}
    </Formik>
  )
}
