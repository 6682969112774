import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { ErrorBoundary } from 'react-error-boundary'
import { useLocation, useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Card, CardContent, Grid, Skeleton, styled, Tab } from '@mui/material'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { createHierarchy } from '../../../utils/groupData'
import ErrorFallback from '../../ErrorFallback'
import AccountInfo from '../components/AccountInfo'
import AggregateTitle from '../components/AggregateTitle'
import GroupCard from '../components/GroupCard'
import RestrictionGroupTable from '../components/RestrictionGroupTable'
import PortfolioTable2 from '../components/PortfolioTable2'
import '../aggregate.scss'
import TaxlotTab from '../components/UMATabs/TaxlotTab'
import PortfolioAllocationTab from '../components/UMATabs/PortfolioAllocationTab'
import PerformanceTab from '../components/UMATabs/PerformanceTab'
import FactorTab from '../components/UMATabs/FactorTab'
import { assetAllocationDataProcess } from './aggregateDataProcessing'
import { aggregateGroups, allocationData } from './data'
import TradeList from '../components/UMATabs/TradeList'
import SummaryTab from '../components/UMATabs/SummaryTab'

const CustomTab = styled(Tab)(({ theme, selected }) => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  textTransform: 'capitalize',
  fontSize: '16px',
  color: '#34475A',
  fontWeight: 400,
  '&.Mui-selected': {
    fontWeight: 400,
    color: '#34475A'
  }
}))

const IPSAggregate = () => {
  const params = useParams()
  const { user } = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const { showError } = useErrorToast()
  const [isLoading, setIsLoading] = useState(true)
  const [isTitleLoading, setIsTitleLoading] = useState(false)
  const [isGroupCardLoading, setIsGroupCardLoading] = useState(true)
  const [isGroupRestrictionLoading, setIsGroupRestrictionLoading] = useState(true)
  const [aggregateKPIs, setAggregateKPIs] = useState({ 'Total Market Value': '', 'Cash': '', 'Unrealized Gain/Loss': '', 'Realized Gain/Loss': '' })
  const [aggregateAccountHierarchy, setAggregateAccountHierarchy] = useState({})
  const [aggregateGroupData, setAggregateGroupData] = useState([])
  const [selectedGroup, setSelectedGroup] = useState({ aggGroupId: '', groupType: '', aggGroupCd: '', aggGroupName: '' })
  const [selectedGroupRestrictionData, setSelectedGroupRestrictionData] = useState([])
  const [tabList, setTabList] = useState([])
  const [aggGroupDetails, setAggGroupDetails] = useState({})
  const [selectedTab, setSelectedTab] = useState('Asset Allocation')
  const [assetAllocationData, setAssetAllocationData] = useState({})

  const assetLevel = ['assetClassDesc', 'accountName']

  const formatAggregateData = (aggData, isUMA) => {
    const KPIObj = {
      'Total Market Value': { value: 250000 || aggData?.totalMv },
      'Unrealized Gain/Loss': { value: 6500 || aggData?.urgl, percentChange: 2.3 },
      'Realized Gain/Loss': { value: 2300, percentChange: -6.3 }
    }
    if (isUMA) {
      KPIObj['Tax Cost'] = { value: 3300, percentChange: -10 }
    }
    else {
      KPIObj['Cash'] = { value: 3300 || aggData?.cash }
    }
    setAggregateKPIs(KPIObj)
  }

  const fetchPortfolioAccounts = (id) => {
    setIsLoading(true)
    API.get(
      'baseUriAggregateMaster',
      `aggregate-master/v1/${user?.userGroup}/aggregate-portfolio/${id}?viewUntagged=true`
    )
      .then((response) => {
        if (response?.data?.accountData) {
          const accountData = response?.data?.accountData || []
          setAggregateAccountHierarchy(createHierarchy(accountData, 0, assetLevel))
        }
        formatAggregateData(response?.data?.agg, true)
        setIsLoading(false)
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
        setIsLoading(false)
      })
  }

  const fetchGroupRestriction = (id) => {
    setIsGroupRestrictionLoading(true)
    API.get(
      'baseUriAggregateMaster',
      `aggregate-master/v1/${user.userGroup}/aggregate/group-restrictions/${id}`
    )
      .then((response) => {
        if (response?.data) {
          setSelectedGroupRestrictionData(response?.data)
        }
        setIsGroupRestrictionLoading(false)
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
        setIsGroupRestrictionLoading(false)
      })
  }

  const fetchAggregateGrouping = (id, type) => {
    setIsGroupCardLoading(true)
    API.post(
      'baseUriAggregateMaster',
      `aggregate-master/v1/${user.userGroup}/account/aggregate-grouping`,
      { body: { aggGroupId: id, ...(type === 'UMA' ? { type } : null) } }
    )
      .then((response) => {
        if (response?.data) {
          setAggregateGroupData(aggregateGroups.filter(obj => obj?.aggGroupId !== params?.aggGroupId))
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setIsGroupCardLoading(false)
      })
  }

  const fetchAggregateMarketValue = (id) => {
    setIsLoading(true)
    API.get(
      'baseUriAggregateMaster',
      `aggregate-master/v1/${user?.userGroup}/aggregate-group-details/${id}`
    )
      .then((response) => {
        if (response?.data) {
          setAggGroupDetails(response.data)
          formatAggregateData({ totalMv: response?.data?.totalMarketValue, cash: response?.data?.totalCashValue, urgl: response?.data?.totalUnrealizedGainLoss })
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const fetchAggregateFamilyData = () => {
    return new Promise((resolve, reject) => {
      API.get(
        'baseUriAggregateMaster',
        `aggregate-master/v1/${user?.userGroup}/agg-group`,
        {
          queryStringParameters: {
            groupId: params?.aggGroupId
          }
        }
      )
        .then((response) => {
          if (response?.data?.length)
            resolve(response?.data[0])
          else
            resolve(null)
          setIsTitleLoading(false)
        })
        .catch((error) => {
          showError(error.response?.data?.errorInfo?.userMessage || error.message)
          Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
          setIsTitleLoading(false)
          reject(error)
        })
    })
  }

  const handleSelectedGroupIndexChange = (newGroup) => {
    const url = location.pathname.replace(params.aggGroupId, newGroup?.aggGroupId)
    navigate(url, { state: { ...newGroup } })
  }

  const fetchAllocationData = () => {
    setAssetAllocationData(assetAllocationDataProcess(allocationData))
  }

  useEffect(() => {
    if (user) {
      if (location.state) {
        setSelectedGroup(prev => ({ ...prev, aggGroupId: params.aggGroupId, groupType: location.state?.groupType, aggGroupCd: location.state?.aggGroupCd, aggGroupName: location.state?.aggGroupName, groupLabel: location.state?.groupLabel }))
        if (location.state?.groupType === 'UMA') {
          setSelectedTab('Summary')
          setTabList((['Summary', 'Factors', 'Performance', 'Trade List', 'Allocation', 'Taxlot']))
          // fetchPortfolioAccounts(params.aggGroupId)
          fetchAllocationData()
          // remove below two line of code after API integration
          setIsLoading(false)
          formatAggregateData(null,true)
        } else if (location.state?.groupType === 'WASHSALE' || location.state?.groupType === 'SETTLEMENT') {
          setSelectedTab('Asset Allocation')
          setTabList((['Asset Allocation', 'Restrictions']))
          fetchAggregateMarketValue(params.aggGroupId)
          fetchGroupRestriction(params.aggGroupId)
        } else {
          fetchAggregateMarketValue(params.aggGroupId)
        }
        fetchAggregateGrouping(params.aggGroupId, location.state?.groupType)
      } else {
        setIsTitleLoading(true)
        fetchAggregateFamilyData()
          .then((res) => {
            if (!res) {
              setIsLoading(false)
              setIsGroupCardLoading(false)
              setIsGroupRestrictionLoading(false)
              return
            }
            setSelectedGroup({ aggGroupId: res?.aggGroupId, aggGroupCd: res?.aggGroupCd, aggGroupName: res?.aggGroupName, groupType: res?.groupType, groupLabel: res?.groupLabel })
            if (res.groupType === 'UMA') fetchPortfolioAccounts(res?.aggGroupId)
            else if (res?.groupType === 'WASHSALE' || res?.groupType === 'SETTLEMENT') {
              setTabList((['Asset Allocation', 'Restrictions']))
              fetchAggregateMarketValue(res?.aggGroupId)
              fetchGroupRestriction(res?.aggGroupId)
            } else {
              setTabList((['Asset Allocation']))
              fetchAggregateMarketValue(res.aggGroupId)
            }
            fetchAggregateGrouping(res?.aggGroupId, res?.groupType)
          })
      }
    }
  }, [location])

  const renderTabPanel = (tab, index) => {
    switch (tab) {
      case 'Allocation':
        return <PortfolioAllocationTab aggregateAccountHierarchy={aggregateAccountHierarchy} loading={isLoading} />
      case 'Taxlot':
        return <TaxlotTab aggregateAccountHierarchy={aggregateAccountHierarchy} loading={isLoading} />
      case 'Asset Allocation':
        return <PortfolioTable2 data={aggGroupDetails} loading={isLoading} />
      case 'Performance':
        return <PerformanceTab data={aggGroupDetails} loading={isLoading} />
      case 'Trade List':
        return <TradeList />
      case 'Restrictions':
        return <RestrictionGroupTable data={selectedGroupRestrictionData} loading={isGroupRestrictionLoading} />
      case 'Factors':
        return <FactorTab allocationData={assetAllocationData} />
      case 'Summary':
        return <SummaryTab />
      default:
        return <></>
    }
  }

  return (
    <>
      <Box className='aggregate-page'>
        <AggregateTitle title={selectedGroup.groupLabel} name={selectedGroup.aggGroupName} number={selectedGroup.aggGroupCd} accountCodeShow={true} loading={isTitleLoading} slug={params?.aggGroupId} chipText={selectedGroup.groupLabel === 'UMA' ? 'Scenario Transition UMA' : ''} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={9}>
            <Grid container spacing={3}>
              <Grid item sm={12}>
                {aggregateKPIs && !isLoading ? <AccountInfo headers={Object.keys(aggregateKPIs)} values={Object.values(aggregateKPIs)} /> : <AccountInfo headers={Object.keys(aggregateKPIs)} values={[]} />}
              </Grid>
              <Grid item sm={12}>
                <Card>
                  {
                    isTitleLoading ? (
                      <CardContent sx={{ height: 'calc(100vh - 48px - 15px - 20px - 72px - 10px - 92px - 24px)' }}>
                        <Skeleton variant='rectangular' height={'100%'} />
                      </CardContent>
                    ) : selectedGroup.groupType !== 'WASHSALE' && selectedGroup.groupType !== 'SETTLEMENT' && selectedGroup.groupType !== 'UMA' ? (
                      // selectedGroup.groupType === 'UMA' ? (
                      //   <CardContent sx={{ pt: '8px', pb: '16px !important', px: 0 }}>
                      //     <Box sx={{ position: 'relative', px: '20px', py: '20px', height: 'calc(100vh - 48px - 15px - 20px - 72px - 5px - 92px - 24px - 5px - 8px - 16px)', overflowY: 'auto', scrollbarGutter: 'stable', overflowX: 'hidden' }}>
                      //       <AllocationChart groupHierarchy={aggregateAccountHierarchy} loading={isLoading} />
                      //       <PortfolioTable groupHierarchy={aggregateAccountHierarchy} loading={isLoading} />
                      //     </Box>
                      //   </CardContent>
                      // )
                      //     :
                      <CardContent sx={{ pt: '8px', pb: '16px !important' }}>
                        <Box sx={{ position: 'relative', height: 'calc(100vh - 48px - 15px - 20px - 72px - 5px - 92px - 24px - 5px - 8px - 16px)', overflowY: 'auto', scrollbarGutter: 'stable', overflowX: 'hidden' }}>
                          {renderTabPanel('Asset Allocation')}
                        </Box>
                      </CardContent>
                    ) : (
                      <CardContent sx={{ pt: '8px', pb: '16px !important' }}>
                        <TabContext value={selectedTab}>
                          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList
                              onChange={(_, value) => setSelectedTab(value)}
                              TabIndicatorProps={{
                                style: {
                                  backgroundColor: '#34475A'
                                }
                              }}
                              indicatorColor='#34475A'
                            >
                              {tabList.map((tab, index) => (
                                <CustomTab key={index} label={tab} value={tab} />
                              ))}
                            </TabList>
                          </Box>
                          {tabList.map((tab, index) => (
                            <TabPanel key={index} value={tab} sx={{ px: 0, pt: '5px', pb: 0 }}>
                              <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen="not main" />)}>
                                {renderTabPanel(tab, index)}
                              </ErrorBoundary>
                            </TabPanel>
                          ))}
                        </TabContext>
                      </CardContent>
                    )
                  }
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box sx={{ height: 'calc(100vh - 48px - 15px - 56px - 16px - 20px)', overflowY: 'auto', scrollbarGutter: 'stable', scrollbarWidth: 'none', pr: '1px', position: 'sticky', top: '135px' }}>
              {
                isGroupCardLoading
                  ? Array.from({ length: 5 }).map((_, i) => (
                    <Grid item sm={12} key={i} mb={2}>
                      <Skeleton
                        variant='rounded'
                        sx={{ height: '70px' }}
                      />
                    </Grid>
                  ))
                  : aggregateGroupData?.map((data, index) => (
                    <Grid item sm={12} key={index} pt={'1px'}>
                      <GroupCard data={data} setSelectedGroup={handleSelectedGroupIndexChange} borderColor={data?.groupColor || '#6681B7'} />
                    </Grid>
                  ))
              }
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default IPSAggregate