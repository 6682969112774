import React, { useState, useEffect, forwardRef } from 'react'
import { InputAdornment, TextField } from '@mui/material'

export const InputAdornmentTextField = forwardRef((props, ref) => {
  const { label, name, onChange, value, adornment, endAdornment, sx, size, error, helperText, placeholder, onKeyDown, disabled, inputRef } = props
  const inputLabelPadding = size === 'small' ? '20px' : '24px' // Adjust based on size prop
  const [isFocused, setIsFocused] = useState(false)
  const [isShrink, setIsShrink] = useState(isFocused || (value?.toString()?.trim()?.length > 0) || false)

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  useEffect(() => {
    setIsShrink(isFocused || (value?.toString()?.trim()?.length > 0) || false)
  }, [value, isFocused])

  useEffect(() => {
    setIsFocused(value?.toString()?.trim()?.length > 0)
  }, [value])

  return (
    <>
      <TextField
        margin={size === 'small' ? 'none' : 'dense'}
        size={size}
        id={name}
        name={name}
        label={label}
        onFocus={handleFocus}
        onBlur={handleBlur}
        inputRef={inputRef}
        type='text'
        variant='outlined'
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        sx={{
          ...sx,
          ...size === 'small' ? { '.MuiInputBase-root': { paddingLeft: '12px !important' } } : {}

        }}
        error={error}
        helperText={helperText}
        placeholder={placeholder}
        disabled={disabled}
        InputLabelProps={{
          sx: {
            '&.MuiInputLabel-root': {
              paddingLeft: (value?.toString()?.trim()?.length > 0) || isFocused ? '0px' : inputLabelPadding
            }
          },
          shrink: isShrink
        }}
        InputProps={{
          startAdornment: adornment
            ? (
              <InputAdornment
                position='start'
                sx={{
                  svg: {
                    width: 18,
                    height: 18
                  },
                  img: {
                    width: 18,
                    height: 18
                  },
                  '&.MuiInputAdornment-root': {
                    maxWidth: size === 'small' ? 12 : 18
                  }
                }}
              >
                {adornment}
              </InputAdornment>
              )
            : '',
          endAdornment: endAdornment
            ? (
              <InputAdornment
                position='end'
                sx={{
                  svg: {
                    width: 18,
                    height: 18
                  },
                  img: {
                    width: 18,
                    height: 18
                  },
                  '&.MuiInputAdornment-root': {
                    maxWidth: size === 'small' ? 12 : 18
                  }
                }}
              >
                {endAdornment}
              </InputAdornment>
              )
            : ''
        }}
      />
    </>
  )
})
