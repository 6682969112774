import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Box, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import redirect from '../../../assets/images/pop-out-line.svg'
import { formatCurrencyWithSymbol } from '../../../utils/FormateCurrenyInMilion'
import { createHierarchy } from '../../../utils/groupData'
import CustomGridTreeDataGroupingCell from './CustomGridTreeDataGroupingCell'

const columns = [
  {
    field: 'allocationPercentage',
    headerName: 'Account Allocation (%)',
    type: 'number',
    align: 'right',
    flex: 1,
    valueGetter: (params) => params?.value ? (params?.value * 100).toFixed(2) : params?.value,
  },
  {
    field: 'marketValue',
    headerName: 'Market Value',
    type: 'number',
    align: 'right',
    flex: 1,
    renderCell: (params) => params?.row?.marketValue !== null && params?.row?.marketValue !== undefined
      ? formatCurrencyWithSymbol(params?.row?.marketValue, 2, '$')
      : 'N/A'
  },
  {
    field: ' ',
    headerName: '',
    width: 50,
    sortable: false,
    disableColumnMenu: true,
    align: 'right',
    renderCell: (params) => (
      params?.row?.currLevel === 1
        ? <Link to={`/account-review/account-dashboard/${params?.row?.accountId}`} style={params?.row?.name === 'Untagged' || params?.row?.name === 'AGGTAG' || params?.row?.name === 'Uncategorized' ? { opacity: '0.3', pointerEvents: 'none' } : {}}>
          <img src={redirect} alt='' />
        </Link>
        : ''
    )
  }
]

function generateArray(key, hierarchy, finalArray, index, datagridHierarchy, fieldsRequiredForSummation) {
  const arrayFromCurrentLevel = []
  datagridHierarchy.push(key)
  const aggregatedFieldsFromChild = hierarchyToArray(hierarchy[key], arrayFromCurrentLevel, index + 1, datagridHierarchy)
  arrayFromCurrentLevel.unshift({ ...aggregatedFieldsFromChild, id: randomId(), hierarchy: [...datagridHierarchy], name: key !== 'null' ? key : 'Uncategorized', currLevel: index })
  datagridHierarchy.pop()
  arrayFromCurrentLevel.forEach(obj => {
    finalArray.push(obj)
  })
  for (let key in fieldsRequiredForSummation) {
    fieldsRequiredForSummation[key] += aggregatedFieldsFromChild[key]
  }
}

function hierarchyToArray(hierarchy, finalArray, index, datagridHierarchy) {
  const fieldsRequiredForSummation = { allocationPercentage: 0, marketValue: 0 }
  if (Array.isArray(hierarchy)) {
    fieldsRequiredForSummation.accountId = hierarchy.length ? hierarchy[0]?.accountId : null
    hierarchy.forEach((object) => {
      fieldsRequiredForSummation.allocationPercentage += object?.allocationPercentage || 0
      fieldsRequiredForSummation.marketValue += object?.marketValue || 0
    })
    return fieldsRequiredForSummation
  }
  let nullFlag = false
  for (const key in hierarchy) {
    if (key === 'null') {
      nullFlag = true
      continue
    }
    generateArray(key, hierarchy, finalArray, index, datagridHierarchy, fieldsRequiredForSummation)
  }
  if (nullFlag)
    generateArray('null', hierarchy, finalArray, index, datagridHierarchy, fieldsRequiredForSummation)
  return fieldsRequiredForSummation
}

const PortfolioTable2 = ({ data, loading }) => {
  const [treeData, setTreeData] = useState([])
  const groupBy = ['uma', 'account']

  useEffect(() => {
    const finalArray = []
    hierarchyToArray(createHierarchy(data?.data || [], 0, groupBy), finalArray, 0, [])
    setTreeData(finalArray)
  }, [data])

  return (
    <>
      <Box className='aggregate-portfolio-table'>
        {
          loading
            ? <TableContainer mt={5}>
              <Table className='risk-page-table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    {columns.map((item, index) => {
                      return (
                        <TableCell key={index}>{item.headerName}</TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.from({ length: 5 }).map((_, i) => (
                    <TableRow key={i}>
                      {Array.from({ length: columns.length }).map((_, j) => (
                        <TableCell key={j}>
                          <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                        </TableCell>
                      ))}
                      <TableCell><Skeleton variant='text' /></TableCell>
                    </TableRow>))}
                </TableBody>
              </Table>
            </TableContainer>
            : <DataGridPro
              density='compact'
              treeData
              rows={treeData}
              columns={columns}
              autoHeight
              getRowId={(row) => row.id}
              disableRowSelectionOnClick
              defaultGroupingExpansionDepth={1}
              getTreeDataPath={(row) => row.hierarchy}
              groupingColDef={{
                headerName: 'Name',
                width: 250,
                filterable: true,
                sortable: true,
                disableColumnMenu: false,
                valueGetter: (params) => params?.row?.name,
                renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />
              }}
              localeText={{ noRowsLabel: 'No Portfolio Found' }}
              hideFooter={treeData?.length === 0}
              getRowClassName={(params) => !params?.row?.noHighlight && params?.row?.currLevel === 0 ? 'datagrid-main-row' : ''}
              pageSizeOptions={[10]}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } }
              }}
              pagination
              sx={(theme) => ({
                [`.${gridClasses.main}`]: {
                  overflow: 'unset'
                },
                [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                  color: '#74788d',
                  fontWeight: 600
                },
                [`.${gridClasses.columnHeaders}`]: {
                  position: 'sticky',
                  top: 0,
                  zIndex: 3,
                  backgroundColor: 'white'
                },
                [`.${gridClasses.footerContainer}`]: {
                  position: 'sticky',
                  bottom: '-1px',
                  zIndex: 3,
                  backgroundColor: 'white'
                },
              })}
            />
        }
      </Box>
    </>
  )
}

export default PortfolioTable2