import { useEffect, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

const CustomStrategyField = (props) => {
  const { strategies, loading, params, error, handleSearchRequest, currRowsStrategyList, allStrategiesList } = props
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState([])
  const [value, setValue] = useState(strategies?.find(s => s.strategyName === params?.value) || currRowsStrategyList?.find(s => s.strategy === params?.value) || null)

  const handleAccountChange = (event, newValue) => {
    const selectedStrategy = newValue?.strategy || newValue?.strategyName || ''
    params?.api.setEditCellValue({ id: params?.id, field: params?.field, value: selectedStrategy, strategyId: params?.row?.id })
    setValue(newValue)
    setInputValue(selectedStrategy)
  }

  useEffect(() => {
    if (strategies && inputValue?.trim()?.length >= 3) {
      setOptions(strategies)
    } else if (!inputValue?.trim()?.length && allStrategiesList) {
      setOptions(allStrategiesList)
    }
  }, [strategies, allStrategiesList, inputValue])

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue || '')
    if (newInputValue?.trim()?.length >= 3 && handleSearchRequest) {
      handleSearchRequest(newInputValue)
    } else {
      if (!(inputValue && inputValue?.trim()?.length)) {
        setOptions([])
      }
    }
  }

  return (
    <Autocomplete
      value={value || null}
      loading={loading}
      inputValue={inputValue}
      onChange={handleAccountChange}
      onInputChange={handleInputChange}
      loadingText='Loading...'
      size='small'
      noOptionsText='No strategies found'
      options={options}
      getOptionLabel={(option) => (`${option?.strategyName || option?.strategy || ''} ${option?.portSpecCode ? `(${option?.portSpecCode})` : ''}`)}
      isOptionEqualToValue={(option, value) => (option.strategyName === value || option.strategy === value)}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Strategy'
          InputLabelProps={{ shrink: true }}
          error={error}
        />
      )}
      style={params?.style || {}}
      sx={{ width: '100%', mr: 1 }}
    />
  )
}

export default CustomStrategyField
