export const awsconfig = {
  Auth: {

    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,

    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_REGION,

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: process.env.REACT_APP_IDENTITY_POOL_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false

    // // OPTIONAL - Configuration for cookie storage
    // // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    // cookieStorage: {
    //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //   domain: '.yourdomain.com',
    //   // OPTIONAL - Cookie path
    //   path: '/',
    //   // OPTIONAL - Cookie expiration in days
    //   expires: 365,
    //   // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    //   sameSite: "strict" | "lax",
    //   // OPTIONAL - Cookie secure flag
    //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //   secure: true
    // },
  },
  API: {
    endpoints: [
      {
        name: 'baseURL',
        endpoint: process.env.REACT_APP_BASE_URL,
        region: process.env.REACT_APP_BASE_URL_REGION,
        custom_header: async () => {
          return { 'x-api-key': process.env.REACT_APP_API_KEY }
        }
      },
      {
        name: 'baseStrategyURL',
        endpoint: process.env.REACT_APP_BASE_URI_STRATEGY,
        region: process.env.REACT_APP_BASE_URL_REGION,
        custom_header: async () => {
          return { 'x-api-key': process.env.REACT_APP_API_KEY_STRATEGY }
        }
      },
      {
        name: 'baseSponserURL',
        endpoint: process.env.REACT_APP_BASE_URI_SPONSOR_MAINTAIN,
        region: process.env.REACT_APP_BASE_URL_REGION,
        custom_header: async () => {
          return { 'x-api-key': process.env.REACT_APP_API_KEY_SPONSOR_MAINTAIN }
        }
      },
      {
        name: 'baseAccountURL',
        endpoint: process.env.REACT_APP_BASE_URI_ACCOUNT_MAINTAIN,
        region: process.env.REACT_APP_BASE_URL_REGION,
        custom_header: async () => {
          return { 'x-api-key': process.env.REACT_APP_API_KEY_ACCOUNT_MAINTAIN }
        }
      },
      {
        name: 'baseAccountPortSpecURL',
        endpoint: process.env.REACT_APP_ACCOUNT_DATA_MAIN_PORT_SPEC,
        region: process.env.REACT_APP_BASE_URL_REGION,
        custom_header: async () => {
          return { 'x-api-key': process.env.REACT_APP_API_KEY_ACCOUNT_DATA_MAIN_PORT_SPEC }
        }
      },
      {
        name: 'baseStrategyMaintainURL',
        endpoint: process.env.REACT_APP_BASE_URI_STRATEGY_MAINTAIN,
        region: process.env.REACT_APP_BASE_URL_REGION,
        custom_header: async () => {
          return { 'x-api-key': process.env.REACT_APP_API_KEY_STRATEGY_MAINTAIN }
        }
      },
      {
        name: 'baseRequestURL',
        endpoint: process.env.REACT_APP_BASE_URI_REQUEST,
        region: process.env.REACT_APP_BASE_URL_REGION,
        custom_header: async () => {
          return { 'x-api-key': process.env.REACT_APP_API_KEY_REQUEST }
        }
      },
      {
        name: 'baseOptimizationURL',
        endpoint: process.env.REACT_APP_API_BASE_URI_OPTIMIZATION,
        region: process.env.REACT_APP_BASE_URL_REGION,
        custom_header: async () => {
          return { 'x-api-key': process.env.REACT_APP_API_KEY_OPTIMIZATION }
        }
      },
      {
        name: 'baseRequestMaintainUrl',
        endpoint: process.env.REACT_APP_API_BASE_URI_REQUEST_MAINTAIN,
        region: process.env.REACT_APP_BASE_URL_REGION,
        custom_header: async () => {
          return { 'x-api-key': process.env.REACT_APP_API_KEY_REQUEST_MAINTAIN }
        }
      },
      {
        name: 'baseUriTrade',
        endpoint: process.env.REACT_APP_API_BASE_URI_TRADE,
        region: process.env.REACT_APP_BASE_URL_REGION,
        custom_header: async () => {
          return { 'x-api-key': process.env.REACT_APP_API_KEY_TRADE }
        }
      },
      {
        name: 'baseDataValidation',
        endpoint: process.env.REACT_APP_API_BASE_URI_DATA_VALIDATION,
        region: process.env.REACT_APP_BASE_URL_REGION,
        custom_header: async () => {
          return { 'x-api-key': process.env.REACT_APP_API_KEY_DATA_VALIDATION }
        }
      },
      {
        name: 'baseUriReport',
        endpoint: process.env.REACT_APP_API_BASE_URI_REPORTING,
        region: process.env.REACT_APP_BASE_URL_REGION,
        custom_header: async () => {
          return { 'x-api-key': process.env.REACT_APP_API_KEY_REPORTING }
        }
      },
      {
        name: 'baseReportingMaintainURL',
        endpoint: process.env.REACT_APP_API_BASE_URI_REPORTING_MAINTENANCE,
        region: process.env.REACT_APP_BASE_URL_REGION,
        custom_header: async () => {
          return { 'x-api-key': process.env.REACT_APP_API_KEY_REPORTING_MAINTENANCE }
        }
      },
      {
        name: 'baseInstrumentMaintainURL',
        endpoint: process.env.REACT_APP_API_BASE_URI_INSTRUMENT_MAINTENANCE,
        region: process.env.REACT_APP_BASE_URL_REGION,
        custom_header: async () => {
          return { 'x-api-key': process.env.REACT_APP_API_KEY_INSTRUMENT_MAINTENANCE }
        }
      },
      {
        name: 'tradeMaintainURL',
        endpoint: process.env.REACT_APP_API_BASE_URI_TRADE_MAINTENANCE,
        region: process.env.REACT_APP_BASE_URL_REGION,
        custom_header: async () => {
          return { 'x-api-key': process.env.REACT_APP_API_KEY_TRADE_MAINTENANCE }
        }
      },
      {
        name: 'baseUriOptimizationDataMaintenance',
        endpoint: process.env.REACT_APP_API_BASE_URI_OPTIMIZATION_DATA_MAINTENANCE,
        region: process.env.REACT_APP_BASE_URL_REGION,
        custom_header: async () => {
          return { 'x-api-key': process.env.REACT_APP_API_KEY_OPTIMIZATION_DATA_MAINTENANCE }
        }
      },
      {
        name: 'baseUriAccountOptimization',
        endpoint: process.env.REACT_APP_API_BASE_URI_ACCOUNT_CUSTOMIZATION,
        region: process.env.REACT_APP_BASE_URL_REGION,
        custom_header: async () => {
          return { 'x-api-key': process.env.REACT_APP_API_KEY_ACCOUNT_CUSTOMIZATION }
        }
      },
      {
        name: 'baseUriSubAdvisoryPolicy',
        endpoint: process.env.REACT_APP_API_BASE_URI_SUB_ADVISORY_POLICY,
        region: process.env.REACT_APP_BASE_URL_REGION,
        custom_header: async () => {
          return { 'x-api-key': process.env.REACT_APP_API_KEY_SUB_ADVISORY_POLICY }
        }
      },
      {
        name: 'baseUriMonitoring',
        endpoint: process.env.REACT_APP_API_BASE_URI_MONITORING,
        region: process.env.REACT_APP_BASE_URL_REGION,
        custom_header: async () => {
          return { 'x-api-key': process.env.REACT_APP_API_API_KEY_MONITORING }
        }
      },
      {
        name: 'baseUriMasterData',
        endpoint: process.env.REACT_APP_BASE_URI_MASTER_DATA,
        region: process.env.REACT_APP_BASE_URL_REGION,
        custom_header: async () => {
          return { 'x-api-key': process.env.REACT_APP_API_KEY_MASTER_DATA }
        }
      },
      {
        name: 'baseUriTransactionalMaster',
        endpoint: process.env.REACT_APP_API_BASE_URI_TRANSACTIONAL_MASTER,
        region: process.env.REACT_APP_BASE_URL_REGION,
        custom_header: async () => {
          return { 'x-api-key': process.env.REACT_APP_API_KEY_TRANSACTIONAL_MASTER }
        }
      }, {
        name: 'baseUriUser',
        endpoint: process.env.REACT_APP_BASE_URI_USER,
        region: process.env.REACT_APP_BASE_URL_REGION,
        custom_header: async () => {
          return { 'x-api-key': process.env.REACT_APP_API_KEY_USER }
        }
      },
      {
        name: 'baseUriReportBuilder',
        endpoint: process.env.REACT_APP_API_BASE_URL_REPORTBUILDER,
        region: process.env.REACT_APP_BASE_URL_REGION,
        custom_header: async () => {
          return { 'x-api-key': process.env.REACT_APP_API_KEY_REPORTBUILDER }
        }
      },
      {
        name: 'baseUriAggregateMaster',
        endpoint: process.env.REACT_APP_API_BASE_URL_AGGREGATE,
        region: process.env.REACT_APP_BASE_URL_REGION,
        custom_header: async () => {
          return { 'x-api-key': process.env.REACT_APP_API_KEY_AGGREGATE }
        }
      },
      {
        name: 'baseUriPortSpec',
        endpoint: process.env.REACT_APP_API_PORT_SPEC,
        region: process.env.REACT_APP_BASE_URL_REGION,
        custom_header: async () => {
          return { 'x-api-key': process.env.REACT_APP_API_KEY_PORT_SPEC }
        }
      },
      {
        name: 'baseAclURL',
        endpoint: process.env.REACT_APP_BASE_ACL_URL,
        region: process.env.REACT_APP_BASE_URL_REGION,
        custom_header: async () => {
          return { 'x-api-key': process.env.REACT_APP_API_KEY_ACL }
        }
      },
      {
        name: 'baseUriCorporate',
        endpoint: process.env.REACT_APP_API_BASE_URL_CORPORATE,
        region: process.env.REACT_APP_BASE_URL_REGION,
        custom_header: async () => {
          return { 'x-api-key': process.env.REACT_APP_API_KEY_CORPORATE }
        }
      },
      {
        name: 'baseUriReportBuilder2',
        endpoint: process.env.REACT_APP_API_BASE_URL_REPORTBUILDER2,
        region: process.env.REACT_APP_BASE_URL_REGION,
        custom_header: async () => {
          return { 'x-api-key': process.env.REACT_APP_API_KEY_REPORTBUILDER2 }
        }
      }
    ]
  }
}
