import { forwardRef, useEffect, useRef, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import 'rc-slider/assets/index.css'
import { useSelector } from 'react-redux'
import { Auth } from 'aws-amplify'
import { styled } from '@mui/system'
import { useAuth } from '../contexts/AuthContext'
import LeftSidebar from './LeftSidebar'
import Topbar from './Topbar'
import { useTourContext } from '../contexts/TourContext'
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride'
import arisLogo from '../assets/images/arisNewLogoBlue.svg'

const BeaconButton = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  marginBottom: '1.25rem',
  '@media (min-width: 768px)': {
    flexDirection: 'row',
    alignItems: 'center'
  }
})

const BeaconComponent = forwardRef((props, ref) => {
  return <BeaconButton ref={ref} {...props} />
})

const ContentLayout = () => {
  let roles = localStorage.getItem('userGroup')
  const menuNodeRef = useRef(null)
  const topMenuToggleRef = useRef(null)
  const [activeMenu, setActiveMenu] = useState(null)
  const { showLegacyView } = useSelector((state) => state.trade)
  const { user } = useAuth()
  const { startTour, setIsNewUser } = useTourContext()
  const [runTour, setRunTour] = useState(false)
  const location = useLocation()

  const TOUR_STEPS = [
    {
      content: <Box><img src={arisLogo} alt='aris-logo' height={40} width={180} /><Typography sx={{ fontWeight: '500', fontSize: '22px', margin: '15px auto' }}>Welcome {user?.name}</Typography><Typography>Thank you for choosing Aris Investing. Lets begin your journey with quick walk-through of our app.</Typography></Box>,
      placement: 'center',
      disableOverlayClose: true,
      target: 'body',
      locale: { next: 'Quick Tour' }
    },
    {
      target: '#list-of-accounts',
      content: 'Here is the list of client accounts. Click on any account name to view account.',
      disableOverlayClose: true,
      showSkipButton: false,
      placement: 'top',
      locale: { back: 'Prev' }
    },
    {
      target: '#review-sub-menu',
      content: 'Assess account risk, generate reports, and personalize portfolio settings',
      placement: 'right',
      disableOverlayClose: true,
      showSkipButton: false,
      locale: { back: 'Prev' }
    },
    {
      target: '#Request',
      content: 'Raise requests, view their status, and add comments to them',
      placement: 'right',
      disableOverlayClose: true,
      showSkipButton: false,
      locale: { back: 'Prev' }
    },
    {
      target: '#request-create',
      content: 'Post new requests for cash withdrawal, proposal generation, or account closure',
      placement: 'left',
      disableOverlayClose: true,
      showSkipButton: false,
      locale: { back: 'Prev' }
    },
    {
      target: '#Report',
      content: 'Analyze relevant reports to gain insights into the client\'s investments',
      placement: 'right',
      disableOverlayClose: true,
      showSkipButton: false,
      locale: { back: 'Prev' }
    },
    {
      target: '#report-tabs',
      content: 'Access the account report to view Summary, Allocation, Holdings, Performance, Policies, and Methodology',
      placement: 'top',
      disableOverlayClose: true,
      showSkipButton: false,
      locale: { back: 'Prev' }
    },
    {
      target: '#Personalization',
      content: 'Customize account settings using various personalization options',
      placement: 'right',
      disableOverlayClose: true,
      showSkipButton: false,
      locale: { back: 'Prev' }
    },
    {
      target: '#personalization-tabs',
      content: 'Tailor portfolio to align investments with individual preferences and goals',
      placement: 'bottom',
      disableOverlayClose: true,
      showSkipButton: false,
      locale: { back: 'Prev' }
    },
    {
      target: '#ips-sub-menu',
      content: 'View rebalancing policies, monitor and track active policies, and view statement history',
      placement: 'right',
      disableOverlayClose: true,
      showSkipButton: false,
      locale: { back: 'Prev' }
    },
    {
      content: <Box><Typography sx={{ fontWeight: '500', fontSize: '22px', margin: '15px auto' }}>Thank you!</Typography></Box>,
      placement: 'center',
      disableOverlayClose: true,
      showSkipButton: false,
      target: 'body',
      locale: { last: 'Complete', back: 'Prev' }
    }
  ]

  const [stepIndex, setStepIndex] = useState(0)

  const navigate = useNavigate()

  const handleTourCallback = async (data) => {
    const { action, index, status, type } = data
    if (([STATUS.FINISHED, STATUS.SKIPPED]).includes(status) || action === ACTIONS.CLOSE) {
      // Need to set our running state to false, so we can restart if we click start again.
      setRunTour(false)
      setStepIndex(0)
      try {
        const user = await Auth.currentAuthenticatedUser()
        const result = await Auth.updateUserAttributes(user, {
          'custom:newClientUser': 'false'
        })
        if (result) {
          if (result === 'SUCCESS') {
            setIsNewUser(false)
          }
        }
      } catch (err) {
      }
    } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND]).includes(type)) {
      let nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1)
      // Update state to advance the tour
      if (action === 'next') {
        if ([1, 2, 4, 6, 8].includes(index)) {
          setRunTour(false)
          const localStorageData = localStorage.getItem('object')
          const parsedData = JSON.parse(localStorageData)
          const accountId = parsedData && parsedData[0] ? parsedData[0].accountId : null
          if (index === 1) {
            setActiveMenu('Account Dashboard')
            navigate(`/account-review/account-dashboard/${accountId}`)
          } else if (index === 2) {
            setActiveMenu('Request')
            navigate(`/account-review/request/${accountId}`)
          } else if (index === 4) {
            setActiveMenu('Report')
            navigate(`/account-review/reports/${accountId}`)
          } else if (index === 6) {
            setActiveMenu('Personalization')
            navigate(`/account-review/personalization/${accountId}`)
          } else if (index === 8) {
            navigate('/ips/policy')
            setActiveMenu('Policy')
          }
          setTimeout(() => {
            setStepIndex(nextStepIndex)
            setRunTour(true)
          }, 1000)
        } else {
          if (index !== 9) {
            setActiveMenu(null)
          }
          setTimeout(() => {
            setStepIndex(nextStepIndex)
            setRunTour(true)
          }, 400)
        }
      } else if (action === 'prev') {
        if (index === 2) {
          setRunTour(false)
          navigate('book-of-business')
          setActiveMenu(null)
          setTimeout(() => {
            setStepIndex(nextStepIndex)
            setRunTour(true)
          }, 400)
        } else if ([3, 5, 7, 9, 10].includes(index)) {
          setRunTour(false)
          const localStorageData = localStorage.getItem('object')
          const parsedData = JSON.parse(localStorageData)
          const accountId = parsedData && parsedData[0] ? parsedData[0].accountId : null
          if (index === 3) {
            setActiveMenu('Account Dashboard')
            navigate(`/account-review/account-dashboard/${accountId}`)
          } else if (index === 5) {
            nextStepIndex = 3
            setActiveMenu('Request')
            navigate(`/account-review/request/${accountId}`)
          } else if (index === 7) {
            setActiveMenu('Report')
            nextStepIndex = 5
            navigate(`/account-review/reports/${accountId}`)
          } else if (index === 9) {
            setActiveMenu('Personalization')
            nextStepIndex = 7
            navigate(`/account-review/personalization/${accountId}`)
          } else if (index === 10) {
            navigate('/ips/policy')
            setActiveMenu('Policy')
          }
          setTimeout(() => {
            setStepIndex(nextStepIndex)
            setRunTour(true)
          }, index === 3 ? 3000 : 2000)
        } else {
          if (index === 8) {
            setActiveMenu('Personalization')
          }
          if (index === 6) {
            setActiveMenu('Report')
          }
          if (index === 4) {
            setActiveMenu('Request')
          }
          setTimeout(() => {
            setStepIndex(nextStepIndex)
            setRunTour(true)
          }, 2000)
          // setStepIndex(nextStepIndex)
          // setRunTour(true)
        }
      }
    }
  }

  useEffect(() => {
    if (startTour && location.pathname === '/book-of-business') {
      setRunTour(true)
    }
    else {
      setRunTour(false)
    }
  }, [startTour])

  const joyrideStyles = {
    options: {
      primaryColor: '#3A76D4',
      backgroundColor: '#FFFFFF',
      overlayColor: 'rgba(0, 0, 0, 0.5)',
      textColor: '#333333',
      width: 450,
      zIndex: 1401
    },
    button: {
      backgroundColor: '#3A76D4',
      border: 'none',
      borderRadius: '4px',
      color: '#FFFFFF',
      fontSize: '14px',
      padding: '10px 20px',
      margin: '0 5px'
    }
  }

  /**
     * Handle the click anywhere in doc
     */
  const handleOtherClick = (e) => {
    // open sidebar on click of top menu toggle in mobile view
    if (topMenuToggleRef && topMenuToggleRef.current && topMenuToggleRef.current.contains(e.target)) {
      if (document.getElementById('sidebar') && document.getElementById('sidebar').classList.contains('open')) {
        document.getElementById('sidebar').classList.remove('open')
      } else {
        document.getElementById('sidebar').classList.add('open')
      }
    }
  }

  useEffect(() => {
    document.body.addEventListener('mousedown', handleOtherClick, false)

    return () => {
      document.body.removeEventListener('mousedown', handleOtherClick, false)
    }
  }, [])

  useEffect(() => {
    const title = document.getElementById('title')
    const favicon = document.getElementById('favicon')
    if (user?.userGroup) {
      roles = user?.userGroup
    }
    if (!(roles === 'adv-classic')) {
      title.innerText = 'Aris Investing'
      favicon.href = '/ArisFavicon.svg'
    }
  }, [user])

  return (
    <Box sx={{ width: '100%' }}>
      {user?.userGroup === 'adv-classic'
        ? <ReactJoyride
            beaconComponent={BeaconComponent}
            steps={TOUR_STEPS}
            continuous
            stepIndex={stepIndex}
            disableScrollParentFix
            disableScrolling
            showSkipButton
            run={runTour}
            styles={joyrideStyles}
            callback={handleTourCallback}
          />
        : ''}
      <LeftSidebar
        menuNodeRef={menuNodeRef}
        activeMenuFromParent={activeMenu}
      />
      <Topbar topMenuToggleRef={topMenuToggleRef} />
      <Box
        id='main-content'
        sx={{
          paddingLeft: '270px',
          paddingRight: '30px',
          paddingTop: '15px',
          paddingBottom: '20px',
          position: 'relative',
          ...{
            ...showLegacyView
              ? {
                  paddingLeft: '80px',
                  paddingRight: '40px'
                }
              : {}
          },

          '@media (max-width: 600px)': {
            paddingTop: '65px'
          },

          '@media screen and (max-width:600px)': {
            paddingLeft: '20px',
            paddingRight: '20px'
          },

          '@media screen and (max-width:800px)': {
            paddingLeft: '80px',
            paddingRight: '40px'
          },

          '@media screen and (min-width:1024px) and (max-width:1400px)': {
            paddingLeft: '80px',
            paddingRight: '40px'
          },

          '@media screen and (min-width:601px) and (max-width:1024px)': {
            paddingLeft: '80px',
            paddingRight: '40px'
          }
        }}
      >
        <Outlet />
      </Box>
    </Box>
  )
}

export default ContentLayout
