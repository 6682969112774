import { Card, CardContent, Table, TableContainer, Typography ,  Chip , Stack} from '@mui/material'

export const DashboardChip = ({ children, title , label}) => {
  return (
    <>
      <Card>
        <CardContent>
        <Stack direction='row' spacing={1}>
            <Typography component='p' className='text-title header-text'>
             {title}
            </Typography>
            <Chip
              label={label} color='primary' variant='outlined' size='small' sx={{
                '& .MuiChip-label': {
                  fontSize: '12px',
                  padding: '0px 10px'
                }
              }}
            />
          </Stack>
          <TableContainer mt={5}>
            <Table className='risk-page-table'>
              {children}
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  )
}
