import { useState } from 'react'
import { Box, Button, IconButton, Modal, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import CloseIcon from '@mui/icons-material/Close'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  px: 2,
  py: 2,
  width: '100%',
  borderRadius: '4px'
}

const sponsorListCols = [
  {
    field: 'sponsorId',
    headerName: 'Sponsor Id',
    flex: 1
  },
  {
    field: 'sponsorName',
    headerName: 'Sponsor Name',
    flex: 1
  },
  {
    field: 'sponsorCd',
    headerName: 'Sponsor Code',
    flex: 1
  }
]

const renderTableSkeleton = (header, hiddenColumns) => {
  if (hiddenColumns)
    header = header.filter(col => !hiddenColumns.includes(col.field))
  return (
    <TableContainer mt={5}>
      <Table className='risk-page-table'>
        <TableHead>
          <TableRow>
            {header.map((item, index) => {
              return (
                <TableCell key={index}>{item.headerName}</TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: 5 }).map((_, index) => (
            <TableRow key={index}>
              {Array.from({ length: header.length }).map((_, index) => (
                <TableCell key={index}>
                  <Skeleton variant='text' sx={{ fontSize: '1rem', width: 100 }} />
                </TableCell>))}
            </TableRow>))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const SponsorSelectionPopup = ({ open, onClose, onNextClick, selectedSponsorId = [], loading, data = [] }) => {
  const [selectionModel, setSelectionModel] = useState(selectedSponsorId)

  const handleRowClick = (newModel) => {
    setSelectionModel((prevModel) => prevModel.length && newModel.length && prevModel[0] === newModel[0] ? [] : newModel)
  }

  return (
    <Modal
      onClose={onClose}
      open={open}
    >
      <Box sx={{ ...modalStyle, outline: 'none', minWidth: '400px', maxWidth: '600px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '5px', position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 2 }}>
          <Typography variant='h6' sx={{ ml: '5px', fontWeight: 400, color: '#34475A' }}>Select Sponsor to Create Group</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        {
          loading
            ? renderTableSkeleton(sponsorListCols, ['sponsorId'])
            : <DataGridPro
              autoHeight
              density='compact'
              rows={data}
              columns={sponsorListCols}
              getRowId={(row) => row?.sponsorId}
              pagination
              disableMultipleRowSelection
              rowSelectionModel={selectionModel}
              onRowSelectionModelChange={(newModel) => handleRowClick(newModel)}
              pageSizeOptions={[10]}
              initialState={{
                ...data?.initialState,
                pagination: { paginationModel: { pageSize: 10 } },
                columns: {
                  columnVisibilityModel: {
                    sponsorId: false
                  }
                }
              }}
              sx={(theme) => ({
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none'
                },
                '& .MuiDataGrid-row': {
                  cursor: 'pointer'
                },
                [`.${gridClasses.main}`]: {
                  height: 'min(100vh - 16px - 45px - 16px - 53px - 37px, 600px - 16px - 45px - 16px - 40px - 53px - 37px)',
                },
                [`.${gridClasses.columnHeaders}`]: {
                  position: 'sticky',
                  backgroundColor: theme.palette.background.paper,
                  top: 0,
                  zIndex: 1,
                },
                [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                  color: '#74788d',
                  fontWeight: 600
                },
                [`.${gridClasses.virtualScroller}`]: {
                  overflowY: 'auto !important',
                  scrollbarGutter: 'stable',
                  scrollbarWidth: 'none'
                }
              })}
            />
        }
        <Box sx={{ float: 'right' }}>
          <Button
            disabled={selectionModel.length && selectedSponsorId.length ? selectionModel[0] === selectedSponsorId[0] : !selectionModel.length}
            type='submit'
            variant='contained'
            onClick={() => onNextClick(data?.find(sponsor => sponsor?.sponsorId === selectionModel[0]))}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default SponsorSelectionPopup