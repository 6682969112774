const performanceData = [
  {
    "dataDate": "2023-08-30",
    "postTaxDailyReturns": 0,
    "preTaxDailyReturns": 0,
    "cumulatedPreTaxDailyReturns": 0,
    "cumulatedPostTaxDailyReturns": 0,
    "preTaxStrategyReturns": 0,
    "preTaxOriginalUmaReturns": 0.3,
    "postTaxStrategyReturns": 0,
    "postTaxOriginalUmaReturns": 0
  },
  {
    "dataDate": "2023-08-31",
    "postTaxDailyReturns": -0.0022813,
    "preTaxDailyReturns": -0.0022813,
    "cumulatedPreTaxDailyReturns": -0.0022813000000000416,
    "cumulatedPostTaxDailyReturns": -0.0022813000000000416,
    "preTaxStrategyReturns": 0.0001,
    "preTaxOriginalUmaReturns": 0.3001,
    "postTaxStrategyReturns": 0.0001,
    "postTaxOriginalUmaReturns": 0.0001
  },
  {
    "dataDate": "2023-09-01",
    "postTaxDailyReturns": 0.0047936,
    "preTaxDailyReturns": 0.0047936,
    "cumulatedPreTaxDailyReturns": 0.002501364360320002,
    "cumulatedPostTaxDailyReturns": 0.002501364360320002,
    "preTaxStrategyReturns": 0.0002,
    "preTaxOriginalUmaReturns": 0.30019999999999997,
    "postTaxStrategyReturns": 0.0002,
    "postTaxOriginalUmaReturns": 0.0002
  },
  {
    "dataDate": "2023-09-05",
    "postTaxDailyReturns": -0.0010877,
    "preTaxDailyReturns": -0.0010877,
    "cumulatedPreTaxDailyReturns": 0.0014109436263052277,
    "cumulatedPostTaxDailyReturns": 0.0014109436263052277,
    "preTaxStrategyReturns": 0.00030000000000000003,
    "preTaxOriginalUmaReturns": 0.30029999999999996,
    "postTaxStrategyReturns": 0.00030000000000000003,
    "postTaxOriginalUmaReturns": 0.00030000000000000003
  },
  {
    "dataDate": "2023-09-06",
    "postTaxDailyReturns": -0.0056691,
    "preTaxDailyReturns": -0.0056691,
    "cumulatedPreTaxDailyReturns": -0.004266155154206697,
    "cumulatedPostTaxDailyReturns": -0.004266155154206697,
    "preTaxStrategyReturns": 0.0004,
    "preTaxOriginalUmaReturns": 0.30039999999999994,
    "postTaxStrategyReturns": 0.0004,
    "postTaxOriginalUmaReturns": 0.0004
  },
  {
    "dataDate": "2023-09-07",
    "postTaxDailyReturns": -0.0038012,
    "preTaxDailyReturns": -0.0038012,
    "cumulatedPreTaxDailyReturns": -0.008051138645234435,
    "cumulatedPostTaxDailyReturns": -0.008051138645234435,
    "preTaxStrategyReturns": 0.0005,
    "preTaxOriginalUmaReturns": 0.30049999999999993,
    "postTaxStrategyReturns": 0.0005,
    "postTaxOriginalUmaReturns": 0.0005
  },
  {
    "dataDate": "2023-09-08",
    "postTaxDailyReturns": 0.0044993,
    "preTaxDailyReturns": 0.0044993,
    "cumulatedPreTaxDailyReturns": -0.003588063133340924,
    "cumulatedPostTaxDailyReturns": -0.003588063133340924,
    "preTaxStrategyReturns": 0.0006000000000000001,
    "preTaxOriginalUmaReturns": 0.3005999999999999,
    "postTaxStrategyReturns": 0.0006000000000000001,
    "postTaxOriginalUmaReturns": 0.0006000000000000001
  },
  {
    "dataDate": "2023-09-11",
    "postTaxDailyReturns": 0.00435,
    "preTaxDailyReturns": 0.00435,
    "cumulatedPreTaxDailyReturns": 0.0007463287920290718,
    "cumulatedPostTaxDailyReturns": 0.0007463287920290718,
    "preTaxStrategyReturns": 0.0007000000000000001,
    "preTaxOriginalUmaReturns": 0.3006999999999999,
    "postTaxStrategyReturns": 0.0007000000000000001,
    "postTaxOriginalUmaReturns": 0.0007000000000000001
  },
  {
    "dataDate": "2023-09-12",
    "postTaxDailyReturns": -0.0015843,
    "preTaxDailyReturns": -0.0015843,
    "cumulatedPreTaxDailyReturns": -0.0008391536166760805,
    "cumulatedPostTaxDailyReturns": -0.0008391536166760805,
    "preTaxStrategyReturns": 0.0008000000000000001,
    "preTaxOriginalUmaReturns": 0.3007999999999999,
    "postTaxStrategyReturns": 0.0008000000000000001,
    "postTaxOriginalUmaReturns": 0.0008000000000000001
  },
  {
    "dataDate": "2023-09-13",
    "postTaxDailyReturns": 0.0008002,
    "preTaxDailyReturns": 0.0008002,
    "cumulatedPreTaxDailyReturns": -0.00003962510740007641,
    "cumulatedPostTaxDailyReturns": -0.00003962510740007641,
    "preTaxStrategyReturns": 0.0009000000000000002,
    "preTaxOriginalUmaReturns": 0.3008999999999999,
    "postTaxStrategyReturns": 0.0009000000000000002,
    "postTaxOriginalUmaReturns": 0.0009000000000000002
  },
  {
    "dataDate": "2023-09-14",
    "postTaxDailyReturns": 0.007656,
    "preTaxDailyReturns": 0.007656,
    "cumulatedPreTaxDailyReturns": 0.0076160715227777676,
    "cumulatedPostTaxDailyReturns": 0.0076160715227777676,
    "preTaxStrategyReturns": 0.0010000000000000002,
    "preTaxOriginalUmaReturns": 0.3009999999999999,
    "postTaxStrategyReturns": 0.0010000000000000002,
    "postTaxOriginalUmaReturns": 0.0010000000000000002
  },
  {
    "dataDate": "2023-09-15",
    "postTaxDailyReturns": -0.013782,
    "preTaxDailyReturns": -0.013782,
    "cumulatedPreTaxDailyReturns": -0.00627089317494911,
    "cumulatedPostTaxDailyReturns": -0.00627089317494911,
    "preTaxStrategyReturns": 0.0011000000000000003,
    "preTaxOriginalUmaReturns": 0.30109999999999987,
    "postTaxStrategyReturns": 0.0011000000000000003,
    "postTaxOriginalUmaReturns": 0.0011000000000000003
  },
  {
    "dataDate": "2023-09-18",
    "postTaxDailyReturns": 0.0018406,
    "preTaxDailyReturns": 0.0018406,
    "cumulatedPreTaxDailyReturns": -0.004441835380926906,
    "cumulatedPostTaxDailyReturns": -0.004441835380926906,
    "preTaxStrategyReturns": 0.0012000000000000003,
    "preTaxOriginalUmaReturns": 0.30119999999999986,
    "postTaxStrategyReturns": 0.0012000000000000003,
    "postTaxOriginalUmaReturns": 0.0012000000000000003
  },
  {
    "dataDate": "2023-09-19",
    "postTaxDailyReturns": -0.0028012,
    "preTaxDailyReturns": -0.0028012,
    "cumulatedPreTaxDailyReturns": -0.0072305929116578405,
    "cumulatedPostTaxDailyReturns": -0.0072305929116578405,
    "preTaxStrategyReturns": 0.0013000000000000004,
    "preTaxOriginalUmaReturns": 0.30129999999999985,
    "postTaxStrategyReturns": 0.0013000000000000004,
    "postTaxOriginalUmaReturns": 0.0013000000000000004
  },
  {
    "dataDate": "2023-09-20",
    "postTaxDailyReturns": -0.0106822,
    "preTaxDailyReturns": -0.0106822,
    "cumulatedPreTaxDailyReturns": -0.017835554272056853,
    "cumulatedPostTaxDailyReturns": -0.017835554272056853,
    "preTaxStrategyReturns": 0.0014000000000000004,
    "preTaxOriginalUmaReturns": 0.30139999999999983,
    "postTaxStrategyReturns": 0.0014000000000000004,
    "postTaxOriginalUmaReturns": 0.0014000000000000004
  },
  {
    "dataDate": "2023-09-21",
    "postTaxDailyReturns": -0.0160016,
    "preTaxDailyReturns": -0.0160016,
    "cumulatedPreTaxDailyReturns": -0.033551756866817106,
    "cumulatedPostTaxDailyReturns": -0.033551756866817106,
    "preTaxStrategyReturns": 0.0015000000000000005,
    "preTaxOriginalUmaReturns": 0.3014999999999998,
    "postTaxStrategyReturns": 0.0015000000000000005,
    "postTaxOriginalUmaReturns": 0.0015000000000000005
  },
  {
    "dataDate": "2023-09-22",
    "postTaxDailyReturns": -0.0006654,
    "preTaxDailyReturns": -0.0006654,
    "cumulatedPreTaxDailyReturns": -0.03419483152779801,
    "cumulatedPostTaxDailyReturns": -0.03419483152779801,
    "preTaxStrategyReturns": 0.0016000000000000005,
    "preTaxOriginalUmaReturns": 0.3015999999999998,
    "postTaxStrategyReturns": 0.0016000000000000005,
    "postTaxOriginalUmaReturns": 0.0016000000000000005
  },
  {
    "dataDate": "2023-09-25",
    "postTaxDailyReturns": 0.0049111,
    "preTaxDailyReturns": 0.0049111,
    "cumulatedPreTaxDailyReturns": -0.029451665764914203,
    "cumulatedPostTaxDailyReturns": -0.029451665764914203,
    "preTaxStrategyReturns": 0.0017000000000000006,
    "preTaxOriginalUmaReturns": 0.3016999999999998,
    "postTaxStrategyReturns": 0.0017000000000000006,
    "postTaxOriginalUmaReturns": 0.0017000000000000006
  },
  {
    "dataDate": "2023-09-26",
    "postTaxDailyReturns": -0.0139085,
    "preTaxDailyReturns": -0.0139085,
    "cumulatedPreTaxDailyReturns": -0.0429505372716229,
    "cumulatedPostTaxDailyReturns": -0.0429505372716229,
    "preTaxStrategyReturns": 0.0018000000000000006,
    "preTaxOriginalUmaReturns": 0.3017999999999998,
    "postTaxStrategyReturns": 0.0018000000000000006,
    "postTaxOriginalUmaReturns": 0.0018000000000000006
  },
  {
    "dataDate": "2023-09-27",
    "postTaxDailyReturns": 0.0054038,
    "preTaxDailyReturns": 0.0054038,
    "cumulatedPreTaxDailyReturns": -0.037778833384931265,
    "cumulatedPostTaxDailyReturns": -0.037778833384931265,
    "preTaxStrategyReturns": 0.0019000000000000006,
    "preTaxOriginalUmaReturns": 0.3018999999999998,
    "postTaxStrategyReturns": 0.0019000000000000006,
    "postTaxOriginalUmaReturns": 0.0019000000000000006
  },
  {
    "dataDate": "2023-09-28",
    "postTaxDailyReturns": 0.0061212,
    "preTaxDailyReturns": 0.0061212,
    "cumulatedPreTaxDailyReturns": -0.031888885179847115,
    "cumulatedPostTaxDailyReturns": -0.031888885179847115,
    "preTaxStrategyReturns": 0.0020000000000000005,
    "preTaxOriginalUmaReturns": 0.30199999999999977,
    "postTaxStrategyReturns": 0.0020000000000000005,
    "postTaxOriginalUmaReturns": 0.0020000000000000005
  },
  {
    "dataDate": "2023-09-29",
    "postTaxDailyReturns": -0.0043631,
    "preTaxDailyReturns": -0.0043631,
    "cumulatedPreTaxDailyReturns": -0.03611285078491888,
    "cumulatedPostTaxDailyReturns": -0.03611285078491888,
    "preTaxStrategyReturns": 0.0021000000000000003,
    "preTaxOriginalUmaReturns": 0.30209999999999976,
    "postTaxStrategyReturns": 0.0021000000000000003,
    "postTaxOriginalUmaReturns": 0.0021000000000000003
  },
  {
    "dataDate": "2023-10-02",
    "postTaxDailyReturns": -0.0026826,
    "preTaxDailyReturns": -0.0026826,
    "cumulatedPreTaxDailyReturns": -0.038698574451403234,
    "cumulatedPostTaxDailyReturns": -0.038698574451403234,
    "preTaxStrategyReturns": 0.0022,
    "preTaxOriginalUmaReturns": 0.30219999999999975,
    "postTaxStrategyReturns": 0.0022,
    "postTaxOriginalUmaReturns": 0.0022
  },
  {
    "dataDate": "2023-10-03",
    "postTaxDailyReturns": -0.0133523,
    "preTaxDailyReturns": -0.0133523,
    "cumulatedPreTaxDailyReturns": -0.05153415947575579,
    "cumulatedPostTaxDailyReturns": -0.05153415947575579,
    "preTaxStrategyReturns": 0.0023,
    "preTaxOriginalUmaReturns": 0.30229999999999974,
    "postTaxStrategyReturns": 0.0023,
    "postTaxOriginalUmaReturns": 0.0023
  },
  {
    "dataDate": "2023-10-04",
    "postTaxDailyReturns": 0.0039165,
    "preTaxDailyReturns": 0.0039165,
    "cumulatedPreTaxDailyReturns": -0.04781949301134247,
    "cumulatedPostTaxDailyReturns": -0.04781949301134247,
    "preTaxStrategyReturns": 0.0024,
    "preTaxOriginalUmaReturns": 0.3023999999999997,
    "postTaxStrategyReturns": 0.0024,
    "postTaxOriginalUmaReturns": 0.0024
  },
  {
    "dataDate": "2023-10-05",
    "postTaxDailyReturns": -0.0027674,
    "preTaxDailyReturns": -0.0027674,
    "cumulatedPreTaxDailyReturns": -0.05045455734638282,
    "cumulatedPostTaxDailyReturns": -0.05045455734638282,
    "preTaxStrategyReturns": 0.0024999999999999996,
    "preTaxOriginalUmaReturns": 0.3024999999999997,
    "postTaxStrategyReturns": 0.0024999999999999996,
    "postTaxOriginalUmaReturns": 0.0024999999999999996
  },
  {
    "dataDate": "2023-10-06",
    "postTaxDailyReturns": 0.011048,
    "preTaxDailyReturns": 0.011048,
    "cumulatedPreTaxDailyReturns": -0.03996397929594575,
    "cumulatedPostTaxDailyReturns": -0.03996397929594575,
    "preTaxStrategyReturns": 0.0025999999999999994,
    "preTaxOriginalUmaReturns": 0.3025999999999997,
    "postTaxStrategyReturns": 0.0025999999999999994,
    "postTaxOriginalUmaReturns": 0.0025999999999999994
  },
  {
    "dataDate": "2023-10-09",
    "postTaxDailyReturns": 0.0104538,
    "preTaxDailyReturns": 0.0104538,
    "cumulatedPreTaxDailyReturns": -0.029927954742709595,
    "cumulatedPostTaxDailyReturns": -0.029927954742709595,
    "preTaxStrategyReturns": 0.0026999999999999993,
    "preTaxOriginalUmaReturns": 0.3026999999999997,
    "postTaxStrategyReturns": 0.0026999999999999993,
    "postTaxOriginalUmaReturns": 0.0026999999999999993
  },
  {
    "dataDate": "2023-10-10",
    "postTaxDailyReturns": 0.0053174,
    "preTaxDailyReturns": 0.0053174,
    "cumulatedPreTaxDailyReturns": -0.024769693649258406,
    "cumulatedPostTaxDailyReturns": -0.024769693649258406,
    "preTaxStrategyReturns": 0.002799999999999999,
    "preTaxOriginalUmaReturns": 0.3027999999999997,
    "postTaxStrategyReturns": 0.002799999999999999,
    "postTaxOriginalUmaReturns": 0.002799999999999999
  },
  {
    "dataDate": "2023-10-11",
    "postTaxDailyReturns": 0.0014364,
    "preTaxDailyReturns": 0.0014364,
    "cumulatedPreTaxDailyReturns": -0.023368872837216226,
    "cumulatedPostTaxDailyReturns": -0.023368872837216226,
    "preTaxStrategyReturns": 0.002899999999999999,
    "preTaxOriginalUmaReturns": 0.30289999999999967,
    "postTaxStrategyReturns": 0.002899999999999999,
    "postTaxOriginalUmaReturns": 0.002899999999999999
  },
  {
    "dataDate": "2023-10-12",
    "postTaxDailyReturns": -0.0049523,
    "preTaxDailyReturns": -0.0049523,
    "cumulatedPreTaxDailyReturns": -0.028205443168264455,
    "cumulatedPostTaxDailyReturns": -0.028205443168264455,
    "preTaxStrategyReturns": 0.0029999999999999988,
    "preTaxOriginalUmaReturns": 0.30299999999999966,
    "postTaxStrategyReturns": 0.0029999999999999988,
    "postTaxOriginalUmaReturns": 0.0029999999999999988
  },
  {
    "dataDate": "2023-10-13",
    "postTaxDailyReturns": -0.0006149,
    "preTaxDailyReturns": -0.0006149,
    "cumulatedPreTaxDailyReturns": -0.02880299964126032,
    "cumulatedPostTaxDailyReturns": -0.02880299964126032,
    "preTaxStrategyReturns": 0.0030999999999999986,
    "preTaxOriginalUmaReturns": 0.30309999999999965,
    "postTaxStrategyReturns": 0.0030999999999999986,
    "postTaxOriginalUmaReturns": 0.0030999999999999986
  },
  {
    "dataDate": "2023-10-16",
    "postTaxDailyReturns": 0.0101763,
    "preTaxDailyReturns": 0.0101763,
    "cumulatedPreTaxDailyReturns": -0.018919807606509798,
    "cumulatedPostTaxDailyReturns": -0.018919807606509798,
    "preTaxStrategyReturns": 0.0031999999999999984,
    "preTaxOriginalUmaReturns": 0.30319999999999964,
    "postTaxStrategyReturns": 0.0031999999999999984,
    "postTaxOriginalUmaReturns": 0.0031999999999999984
  },
  {
    "dataDate": "2023-10-17",
    "postTaxDailyReturns": 0.0008266,
    "preTaxDailyReturns": 0.0008266,
    "cumulatedPreTaxDailyReturns": -0.018108846719477434,
    "cumulatedPostTaxDailyReturns": -0.018108846719477434,
    "preTaxStrategyReturns": 0.0032999999999999982,
    "preTaxOriginalUmaReturns": 0.3032999999999996,
    "postTaxStrategyReturns": 0.0032999999999999982,
    "postTaxOriginalUmaReturns": 0.0032999999999999982
  },
  {
    "dataDate": "2023-10-18",
    "postTaxDailyReturns": -0.0090393,
    "preTaxDailyReturns": -0.0090393,
    "cumulatedPreTaxDailyReturns": -0.026984455421325992,
    "cumulatedPostTaxDailyReturns": -0.026984455421325992,
    "preTaxStrategyReturns": 0.003399999999999998,
    "preTaxOriginalUmaReturns": 0.3033999999999996,
    "postTaxStrategyReturns": 0.003399999999999998,
    "postTaxOriginalUmaReturns": 0.003399999999999998
  },
  {
    "dataDate": "2023-10-19",
    "postTaxDailyReturns": -0.0058966,
    "preTaxDailyReturns": -0.0058966,
    "cumulatedPreTaxDailyReturns": -0.032721938881488666,
    "cumulatedPostTaxDailyReturns": -0.032721938881488666,
    "preTaxStrategyReturns": 0.003499999999999998,
    "preTaxOriginalUmaReturns": 0.3034999999999996,
    "postTaxStrategyReturns": 0.003499999999999998,
    "postTaxOriginalUmaReturns": 0.003499999999999998
  },
  {
    "dataDate": "2023-10-20",
    "postTaxDailyReturns": -0.0140716,
    "preTaxDailyReturns": -0.0140716,
    "cumulatedPreTaxDailyReturns": -0.04633308884632392,
    "cumulatedPostTaxDailyReturns": -0.04633308884632392,
    "preTaxStrategyReturns": 0.0035999999999999977,
    "preTaxOriginalUmaReturns": 0.3035999999999996,
    "postTaxStrategyReturns": 0.0035999999999999977,
    "postTaxOriginalUmaReturns": 0.0035999999999999977
  },
  {
    "dataDate": "2023-10-23",
    "postTaxDailyReturns": -0.0017954,
    "preTaxDailyReturns": -0.0017954,
    "cumulatedPreTaxDailyReturns": -0.04804530241860927,
    "cumulatedPostTaxDailyReturns": -0.04804530241860927,
    "preTaxStrategyReturns": 0.0036999999999999976,
    "preTaxOriginalUmaReturns": 0.3036999999999996,
    "postTaxStrategyReturns": 0.0036999999999999976,
    "postTaxOriginalUmaReturns": 0.0036999999999999976
  },
  {
    "dataDate": "2023-10-24",
    "postTaxDailyReturns": 0.0049615,
    "preTaxDailyReturns": 0.0049615,
    "cumulatedPreTaxDailyReturns": -0.04332217918655912,
    "cumulatedPostTaxDailyReturns": -0.04332217918655912,
    "preTaxStrategyReturns": 0.0037999999999999974,
    "preTaxOriginalUmaReturns": 0.30379999999999957,
    "postTaxStrategyReturns": 0.0037999999999999974,
    "postTaxOriginalUmaReturns": 0.0037999999999999974
  },
  {
    "dataDate": "2023-10-25",
    "postTaxDailyReturns": -0.0153276,
    "preTaxDailyReturns": -0.0153276,
    "cumulatedPreTaxDailyReturns": -0.057985754152859226,
    "cumulatedPostTaxDailyReturns": -0.057985754152859226,
    "preTaxStrategyReturns": 0.0038999999999999972,
    "preTaxOriginalUmaReturns": 0.30389999999999956,
    "postTaxStrategyReturns": 0.0038999999999999972,
    "postTaxOriginalUmaReturns": 0.0038999999999999972
  },
  {
    "dataDate": "2023-10-26",
    "postTaxDailyReturns": -0.0121045,
    "preTaxDailyReturns": -0.0121045,
    "cumulatedPreTaxDailyReturns": -0.0693883655917159,
    "cumulatedPostTaxDailyReturns": -0.0693883655917159,
    "preTaxStrategyReturns": 0.0039999999999999975,
    "preTaxOriginalUmaReturns": 0.30399999999999955,
    "postTaxStrategyReturns": 0.0039999999999999975,
    "postTaxOriginalUmaReturns": 0.0039999999999999975
  },
  {
    "dataDate": "2023-10-27",
    "postTaxDailyReturns": -0.0040553,
    "preTaxDailyReturns": -0.0040553,
    "cumulatedPreTaxDailyReturns": -0.07316227495273175,
    "cumulatedPostTaxDailyReturns": -0.07316227495273175,
    "preTaxStrategyReturns": 0.004099999999999998,
    "preTaxOriginalUmaReturns": 0.30409999999999954,
    "postTaxStrategyReturns": 0.004099999999999998,
    "postTaxOriginalUmaReturns": 0.004099999999999998
  },
  {
    "dataDate": "2023-10-30",
    "postTaxDailyReturns": 0.0093165,
    "preTaxDailyReturns": 0.0093165,
    "cumulatedPreTaxDailyReturns": -0.06452739128732887,
    "cumulatedPostTaxDailyReturns": -0.06452739128732887,
    "preTaxStrategyReturns": 0.004199999999999998,
    "preTaxOriginalUmaReturns": 0.3041999999999995,
    "postTaxStrategyReturns": 0.004199999999999998,
    "postTaxOriginalUmaReturns": 0.004199999999999998
  },
  {
    "dataDate": "2023-10-31",
    "postTaxDailyReturns": 0.0075102,
    "preTaxDailyReturns": 0.0075102,
    "cumulatedPreTaxDailyReturns": -0.05750180490137491,
    "cumulatedPostTaxDailyReturns": -0.05750180490137491,
    "preTaxStrategyReturns": 0.004299999999999998,
    "preTaxOriginalUmaReturns": 0.3042999999999995,
    "postTaxStrategyReturns": 0.004299999999999998,
    "postTaxOriginalUmaReturns": 0.004299999999999998
  },
  {
    "dataDate": "2023-11-01",
    "postTaxDailyReturns": 0.008649,
    "preTaxDailyReturns": 0.008649,
    "cumulatedPreTaxDailyReturns": -0.04935013801196697,
    "cumulatedPostTaxDailyReturns": -0.04935013801196697,
    "preTaxStrategyReturns": 0.0043999999999999985,
    "preTaxOriginalUmaReturns": 0.3043999999999995,
    "postTaxStrategyReturns": 0.0043999999999999985,
    "postTaxOriginalUmaReturns": 0.0043999999999999985
  },
  {
    "dataDate": "2023-11-02",
    "postTaxDailyReturns": 0.0209536,
    "preTaxDailyReturns": 0.0209536,
    "cumulatedPreTaxDailyReturns": -0.029430601063814565,
    "cumulatedPostTaxDailyReturns": -0.029430601063814565,
    "preTaxStrategyReturns": 0.004499999999999999,
    "preTaxOriginalUmaReturns": 0.3044999999999995,
    "postTaxStrategyReturns": 0.004499999999999999,
    "postTaxOriginalUmaReturns": 0.004499999999999999
  },
  {
    "dataDate": "2023-11-03",
    "postTaxDailyReturns": 0.0049734,
    "preTaxDailyReturns": 0.0049734,
    "cumulatedPreTaxDailyReturns": -0.024603571215145448,
    "cumulatedPostTaxDailyReturns": -0.024603571215145448,
    "preTaxStrategyReturns": 0.004599999999999999,
    "preTaxOriginalUmaReturns": 0.3045999999999995,
    "postTaxStrategyReturns": 0.004599999999999999,
    "postTaxOriginalUmaReturns": 0.004599999999999999
  },
  {
    "dataDate": "2023-11-06",
    "postTaxDailyReturns": -0.0001964,
    "preTaxDailyReturns": -0.0001964,
    "cumulatedPreTaxDailyReturns": -0.024795139073758743,
    "cumulatedPostTaxDailyReturns": -0.024795139073758743,
    "preTaxStrategyReturns": 0.004699999999999999,
    "preTaxOriginalUmaReturns": 0.30469999999999947,
    "postTaxStrategyReturns": 0.004699999999999999,
    "postTaxOriginalUmaReturns": 0.004699999999999999
  },
  {
    "dataDate": "2023-11-07",
    "postTaxDailyReturns": 0.0013749,
    "preTaxDailyReturns": 0.0013749,
    "cumulatedPreTaxDailyReturns": -0.02345432991047114,
    "cumulatedPostTaxDailyReturns": -0.02345432991047114,
    "preTaxStrategyReturns": 0.0048,
    "preTaxOriginalUmaReturns": 0.30479999999999946,
    "postTaxStrategyReturns": 0.0048,
    "postTaxOriginalUmaReturns": 0.0048
  },
  {
    "dataDate": "2023-11-08",
    "postTaxDailyReturns": -0.0006413,
    "preTaxDailyReturns": -0.0006413,
    "cumulatedPreTaxDailyReturns": -0.024080588648699552,
    "cumulatedPostTaxDailyReturns": -0.024080588648699552,
    "preTaxStrategyReturns": 0.0049,
    "preTaxOriginalUmaReturns": 0.30489999999999945,
    "postTaxStrategyReturns": 0.0049,
    "postTaxOriginalUmaReturns": 0.0049
  },
  {
    "dataDate": "2023-11-09",
    "postTaxDailyReturns": -0.0071561,
    "preTaxDailyReturns": -0.0071561,
    "cumulatedPreTaxDailyReturns": -0.031064365548270634,
    "cumulatedPostTaxDailyReturns": -0.031064365548270634,
    "preTaxStrategyReturns": 0.005,
    "preTaxOriginalUmaReturns": 0.30499999999999944,
    "postTaxStrategyReturns": 0.005,
    "postTaxOriginalUmaReturns": 0.005
  },
  {
    "dataDate": "2023-11-10",
    "postTaxDailyReturns": 0.0163939,
    "preTaxDailyReturns": 0.0163939,
    "cumulatedPreTaxDailyReturns": -0.015179731650632466,
    "cumulatedPostTaxDailyReturns": -0.015179731650632466,
    "preTaxStrategyReturns": 0.0051,
    "preTaxOriginalUmaReturns": 0.3050999999999994,
    "postTaxStrategyReturns": 0.0051,
    "postTaxOriginalUmaReturns": 0.0051
  },
  {
    "dataDate": "2023-11-13",
    "postTaxDailyReturns": 0.0011462,
    "preTaxDailyReturns": 0.0011462,
    "cumulatedPreTaxDailyReturns": -0.014050930659050387,
    "cumulatedPostTaxDailyReturns": -0.014050930659050387,
    "preTaxStrategyReturns": 0.005200000000000001,
    "preTaxOriginalUmaReturns": 0.3051999999999994,
    "postTaxStrategyReturns": 0.005200000000000001,
    "postTaxOriginalUmaReturns": 0.005200000000000001
  },
  {
    "dataDate": "2023-11-14",
    "postTaxDailyReturns": 0.0177018,
    "preTaxDailyReturns": 0.0177018,
    "cumulatedPreTaxDailyReturns": 0.0034021425766093127,
    "cumulatedPostTaxDailyReturns": 0.0034021425766093127,
    "preTaxStrategyReturns": 0.005300000000000001,
    "preTaxOriginalUmaReturns": 0.3052999999999994,
    "postTaxStrategyReturns": 0.005300000000000001,
    "postTaxOriginalUmaReturns": 0.005300000000000001
  },
  {
    "dataDate": "2023-11-15",
    "postTaxDailyReturns": -0.0005555,
    "preTaxDailyReturns": -0.0005555,
    "cumulatedPreTaxDailyReturns": 0.0028447526864079364,
    "cumulatedPostTaxDailyReturns": 0.0028447526864079364,
    "preTaxStrategyReturns": 0.005400000000000001,
    "preTaxOriginalUmaReturns": 0.3053999999999994,
    "postTaxStrategyReturns": 0.005400000000000001,
    "postTaxOriginalUmaReturns": 0.005400000000000001
  },
  {
    "dataDate": "2023-11-16",
    "postTaxDailyReturns": -0.000809,
    "preTaxDailyReturns": -0.000809,
    "cumulatedPreTaxDailyReturns": 0.0020334512814845773,
    "cumulatedPostTaxDailyReturns": 0.0020334512814845773,
    "preTaxStrategyReturns": 0.005500000000000001,
    "preTaxOriginalUmaReturns": 0.3054999999999994,
    "postTaxStrategyReturns": 0.005500000000000001,
    "postTaxOriginalUmaReturns": 0.005500000000000001
  },
  {
    "dataDate": "2023-11-17",
    "postTaxDailyReturns": 0.0032293,
    "preTaxDailyReturns": 0.0032293,
    "cumulatedPreTaxDailyReturns": 0.005269317905707949,
    "cumulatedPostTaxDailyReturns": 0.005269317905707949,
    "preTaxStrategyReturns": 0.005600000000000002,
    "preTaxOriginalUmaReturns": 0.30559999999999937,
    "postTaxStrategyReturns": 0.005600000000000002,
    "postTaxOriginalUmaReturns": 0.005600000000000002
  },
  {
    "dataDate": "2023-11-20",
    "postTaxDailyReturns": 0.0062666,
    "preTaxDailyReturns": 0.0062666,
    "cumulatedPreTaxDailyReturns": 0.011568938613295776,
    "cumulatedPostTaxDailyReturns": 0.011568938613295776,
    "preTaxStrategyReturns": 0.005700000000000002,
    "preTaxOriginalUmaReturns": 0.30569999999999936,
    "postTaxStrategyReturns": 0.005700000000000002,
    "postTaxOriginalUmaReturns": 0.005700000000000002
  },
  {
    "dataDate": "2023-11-21",
    "postTaxDailyReturns": -0.003185,
    "preTaxDailyReturns": -0.003185,
    "cumulatedPreTaxDailyReturns": 0.008347091543812413,
    "cumulatedPostTaxDailyReturns": 0.008347091543812413,
    "preTaxStrategyReturns": 0.005800000000000002,
    "preTaxOriginalUmaReturns": 0.30579999999999935,
    "postTaxStrategyReturns": 0.005800000000000002,
    "postTaxOriginalUmaReturns": 0.005800000000000002
  },
  {
    "dataDate": "2023-11-22",
    "postTaxDailyReturns": 0.0034556,
    "preTaxDailyReturns": 0.0034556,
    "cumulatedPreTaxDailyReturns": 0.011831535753351163,
    "cumulatedPostTaxDailyReturns": 0.011831535753351163,
    "preTaxStrategyReturns": 0.0059000000000000025,
    "preTaxOriginalUmaReturns": 0.30589999999999934,
    "postTaxStrategyReturns": 0.0059000000000000025,
    "postTaxOriginalUmaReturns": 0.0059000000000000025
  },
  {
    "dataDate": "2023-11-27",
    "postTaxDailyReturns": -0.0004394,
    "preTaxDailyReturns": -0.0004394,
    "cumulatedPreTaxDailyReturns": 0.01138693697654114,
    "cumulatedPostTaxDailyReturns": 0.01138693697654114,
    "preTaxStrategyReturns": 0.006000000000000003,
    "preTaxOriginalUmaReturns": 0.30599999999999933,
    "postTaxStrategyReturns": 0.006000000000000003,
    "postTaxOriginalUmaReturns": 0.006000000000000003
  },
  {
    "dataDate": "2023-11-28",
    "postTaxDailyReturns": 0.0016565,
    "preTaxDailyReturns": 0.0016565,
    "cumulatedPreTaxDailyReturns": 0.01306229943764281,
    "cumulatedPostTaxDailyReturns": 0.01306229943764281,
    "preTaxStrategyReturns": 0.006100000000000003,
    "preTaxOriginalUmaReturns": 0.3060999999999993,
    "postTaxStrategyReturns": 0.006100000000000003,
    "postTaxOriginalUmaReturns": 0.006100000000000003
  },
  {
    "dataDate": "2023-11-29",
    "postTaxDailyReturns": -0.0003602,
    "preTaxDailyReturns": -0.0003602,
    "cumulatedPreTaxDailyReturns": 0.012697394397385375,
    "cumulatedPostTaxDailyReturns": 0.012697394397385375,
    "preTaxStrategyReturns": 0.006200000000000003,
    "preTaxOriginalUmaReturns": 0.3061999999999993,
    "postTaxStrategyReturns": 0.006200000000000003,
    "postTaxOriginalUmaReturns": 0.006200000000000003
  },
  {
    "dataDate": "2023-11-30",
    "postTaxDailyReturns": 0.0022111,
    "preTaxDailyReturns": 0.0022111,
    "cumulatedPreTaxDailyReturns": 0.014936569606137429,
    "cumulatedPostTaxDailyReturns": 0.014936569606137429,
    "preTaxStrategyReturns": 0.0063000000000000035,
    "preTaxOriginalUmaReturns": 0.3062999999999993,
    "postTaxStrategyReturns": 0.0063000000000000035,
    "postTaxOriginalUmaReturns": 0.0063000000000000035
  },
  {
    "dataDate": "2023-12-01",
    "postTaxDailyReturns": 0.003889,
    "preTaxDailyReturns": 0.003889,
    "cumulatedPreTaxDailyReturns": 0.01888365792533575,
    "cumulatedPostTaxDailyReturns": 0.01888365792533575,
    "preTaxStrategyReturns": 0.006400000000000004,
    "preTaxOriginalUmaReturns": 0.3063999999999993,
    "postTaxStrategyReturns": 0.006400000000000004,
    "postTaxOriginalUmaReturns": 0.006400000000000004
  },
  {
    "dataDate": "2023-12-04",
    "postTaxDailyReturns": -0.0071783,
    "preTaxDailyReturns": -0.0071783,
    "cumulatedPreTaxDailyReturns": 0.011569805363650376,
    "cumulatedPostTaxDailyReturns": 0.011569805363650376,
    "preTaxStrategyReturns": 0.006500000000000004,
    "preTaxOriginalUmaReturns": 0.3064999999999993,
    "postTaxStrategyReturns": 0.006500000000000004,
    "postTaxOriginalUmaReturns": 0.006500000000000004
  },
  {
    "dataDate": "2023-12-05",
    "postTaxDailyReturns": -0.0020118,
    "preTaxDailyReturns": -0.0020118,
    "cumulatedPreTaxDailyReturns": 0.00953472922921983,
    "cumulatedPostTaxDailyReturns": 0.00953472922921983,
    "preTaxStrategyReturns": 0.006600000000000004,
    "preTaxOriginalUmaReturns": 0.30659999999999926,
    "postTaxStrategyReturns": 0.006600000000000004,
    "postTaxOriginalUmaReturns": 0.006600000000000004
  },
  {
    "dataDate": "2023-12-06",
    "postTaxDailyReturns": -0.0064457,
    "preTaxDailyReturns": -0.0065033,
    "cumulatedPreTaxDailyReturns": 0.0029694220246234693,
    "cumulatedPostTaxDailyReturns": 0.003027571225026948,
    "preTaxStrategyReturns": 0.0067000000000000046,
    "preTaxOriginalUmaReturns": 0.30669999999999925,
    "postTaxStrategyReturns": 0.0067000000000000046,
    "postTaxOriginalUmaReturns": 0.0067000000000000046
  },
  {
    "dataDate": "2023-12-07",
    "postTaxDailyReturns": 0.0070107,
    "preTaxDailyReturns": 0.0070107,
    "cumulatedPreTaxDailyReturns": 0.010000939751611426,
    "cumulatedPostTaxDailyReturns": 0.010059496618614272,
    "preTaxStrategyReturns": 0.006800000000000005,
    "preTaxOriginalUmaReturns": 0.30679999999999924,
    "postTaxStrategyReturns": 0.006800000000000005,
    "postTaxOriginalUmaReturns": 0.006800000000000005
  },
  {
    "dataDate": "2023-12-08",
    "postTaxDailyReturns": 0.0051237,
    "preTaxDailyReturns": 0.0051237,
    "cumulatedPreTaxDailyReturns": 0.015175881566616756,
    "cumulatedPostTaxDailyReturns": 0.015234738461439079,
    "preTaxStrategyReturns": 0.006900000000000005,
    "preTaxOriginalUmaReturns": 0.30689999999999923,
    "postTaxStrategyReturns": 0.006900000000000005,
    "postTaxOriginalUmaReturns": 0.006900000000000005
  },
  {
    "dataDate": "2023-12-11",
    "postTaxDailyReturns": 0.0069324,
    "preTaxDailyReturns": 0.0069324,
    "cumulatedPreTaxDailyReturns": 0.022213486847989072,
    "cumulatedPostTaxDailyReturns": 0.022272751762349108,
    "preTaxStrategyReturns": 0.007000000000000005,
    "preTaxOriginalUmaReturns": 0.3069999999999992,
    "postTaxStrategyReturns": 0.007000000000000005,
    "postTaxOriginalUmaReturns": 0.007000000000000005
  },
  {
    "dataDate": "2023-12-12",
    "postTaxDailyReturns": 0.0044097,
    "preTaxDailyReturns": 0.0044097,
    "cumulatedPreTaxDailyReturns": 0.02672114166094275,
    "cumulatedPostTaxDailyReturns": 0.026780667915795586,
    "preTaxStrategyReturns": 0.007100000000000006,
    "preTaxOriginalUmaReturns": 0.3070999999999992,
    "postTaxStrategyReturns": 0.007100000000000006,
    "postTaxOriginalUmaReturns": 0.007100000000000006
  },
  {
    "dataDate": "2023-12-13",
    "postTaxDailyReturns": 0.0136214,
    "preTaxDailyReturns": 0.0136214,
    "cumulatedPreTaxDailyReturns": 0.04070652101996308,
    "cumulatedPostTaxDailyReturns": 0.04076685810574365,
    "preTaxStrategyReturns": 0.007200000000000006,
    "preTaxOriginalUmaReturns": 0.3071999999999992,
    "postTaxStrategyReturns": 0.007200000000000006,
    "postTaxOriginalUmaReturns": 0.007200000000000006
  },
  {
    "dataDate": "2023-12-14",
    "postTaxDailyReturns": 0.0033942,
    "preTaxDailyReturns": 0.0033942,
    "cumulatedPreTaxDailyReturns": 0.044238887093609014,
    "cumulatedPostTaxDailyReturns": 0.04429942897552608,
    "preTaxStrategyReturns": 0.007300000000000006,
    "preTaxOriginalUmaReturns": 0.3072999999999992,
    "postTaxStrategyReturns": 0.007300000000000006,
    "postTaxOriginalUmaReturns": 0.007300000000000006
  },
  {
    "dataDate": "2023-12-15",
    "postTaxDailyReturns": 0.0011994,
    "preTaxDailyReturns": 0.0011994,
    "cumulatedPreTaxDailyReturns": 0.04549134721478909,
    "cumulatedPostTaxDailyReturns": 0.045551961710639244,
    "preTaxStrategyReturns": 0.007400000000000006,
    "preTaxOriginalUmaReturns": 0.3073999999999992,
    "postTaxStrategyReturns": 0.007400000000000006,
    "postTaxOriginalUmaReturns": 0.007400000000000006
  },
  {
    "dataDate": "2023-12-18",
    "postTaxDailyReturns": 0.0050928,
    "preTaxDailyReturns": 0.0050928,
    "cumulatedPreTaxDailyReturns": 0.05081582554788455,
    "cumulatedPostTaxDailyReturns": 0.05087674874123915,
    "preTaxStrategyReturns": 0.007500000000000007,
    "preTaxOriginalUmaReturns": 0.30749999999999916,
    "postTaxStrategyReturns": 0.007500000000000007,
    "postTaxOriginalUmaReturns": 0.007500000000000007
  },
  {
    "dataDate": "2023-12-19",
    "postTaxDailyReturns": 0.0058509,
    "preTaxDailyReturns": 0.0058509,
    "cumulatedPreTaxDailyReturns": 0.0569640438615826,
    "cumulatedPostTaxDailyReturns": 0.0570253235104492,
    "preTaxStrategyReturns": 0.007600000000000007,
    "preTaxOriginalUmaReturns": 0.30759999999999915,
    "postTaxStrategyReturns": 0.007600000000000007,
    "postTaxOriginalUmaReturns": 0.007600000000000007
  },
  {
    "dataDate": "2023-12-20",
    "postTaxDailyReturns": -0.0133851,
    "preTaxDailyReturns": -0.0133851,
    "cumulatedPreTaxDailyReturns": 0.042816474438091,
    "cumulatedPostTaxDailyReturns": 0.042876933852729504,
    "preTaxStrategyReturns": 0.007700000000000007,
    "preTaxOriginalUmaReturns": 0.30769999999999914,
    "postTaxStrategyReturns": 0.007700000000000007,
    "postTaxOriginalUmaReturns": 0.007700000000000007
  },
  {
    "dataDate": "2023-12-21",
    "postTaxDailyReturns": 0.009573,
    "preTaxDailyReturns": 0.009573,
    "cumulatedPreTaxDailyReturns": 0.05279935654788681,
    "cumulatedPostTaxDailyReturns": 0.05286039474050175,
    "preTaxStrategyReturns": 0.0078000000000000074,
    "preTaxOriginalUmaReturns": 0.30779999999999913,
    "postTaxStrategyReturns": 0.0078000000000000074,
    "postTaxOriginalUmaReturns": 0.0078000000000000074
  },
  {
    "dataDate": "2023-12-22",
    "postTaxDailyReturns": -0.0000633,
    "preTaxDailyReturns": -0.0000633,
    "cumulatedPreTaxDailyReturns": 0.05273271434861737,
    "cumulatedPostTaxDailyReturns": 0.05279374867751474,
    "preTaxStrategyReturns": 0.007900000000000008,
    "preTaxOriginalUmaReturns": 0.3078999999999991,
    "postTaxStrategyReturns": 0.007900000000000008,
    "postTaxOriginalUmaReturns": 0.007900000000000008
  },
  {
    "dataDate": "2023-12-26",
    "postTaxDailyReturns": 0.0043706,
    "preTaxDailyReturns": 0.0043706,
    "cumulatedPreTaxDailyReturns": 0.057333787949949366,
    "cumulatedPostTaxDailyReturns": 0.057395089035484625,
    "preTaxStrategyReturns": 0.008000000000000007,
    "preTaxOriginalUmaReturns": 0.3079999999999991,
    "postTaxStrategyReturns": 0.008000000000000007,
    "postTaxOriginalUmaReturns": 0.008000000000000007
  },
  {
    "dataDate": "2023-12-27",
    "postTaxDailyReturns": 0.0000329,
    "preTaxDailyReturns": 0.0000329,
    "cumulatedPreTaxDailyReturns": 0.05736857423157282,
    "cumulatedPostTaxDailyReturns": 0.05742987733391369,
    "preTaxStrategyReturns": 0.008100000000000007,
    "preTaxOriginalUmaReturns": 0.3080999999999991,
    "postTaxStrategyReturns": 0.008100000000000007,
    "postTaxOriginalUmaReturns": 0.008100000000000007
  },
  {
    "dataDate": "2023-12-28",
    "postTaxDailyReturns": -0.001091,
    "preTaxDailyReturns": -0.001091,
    "cumulatedPreTaxDailyReturns": 0.056214985117086336,
    "cumulatedPostTaxDailyReturns": 0.05627622133774235,
    "preTaxStrategyReturns": 0.008200000000000006,
    "preTaxOriginalUmaReturns": 0.3081999999999991,
    "postTaxStrategyReturns": 0.008200000000000006,
    "postTaxOriginalUmaReturns": 0.008200000000000006
  },
  {
    "dataDate": "2023-12-29",
    "postTaxDailyReturns": -0.0030054,
    "preTaxDailyReturns": -0.0030054,
    "cumulatedPreTaxDailyReturns": 0.05304063660081537,
    "cumulatedPostTaxDailyReturns": 0.05310168878213384,
    "preTaxStrategyReturns": 0.008300000000000005,
    "preTaxOriginalUmaReturns": 0.3082999999999991,
    "postTaxStrategyReturns": 0.008300000000000005,
    "postTaxOriginalUmaReturns": 0.008300000000000005
  },
  {
    "dataDate": "2024-01-02",
    "postTaxDailyReturns": -0.0084573,
    "preTaxDailyReturns": -0.0084573,
    "cumulatedPreTaxDailyReturns": 0.04413475602489125,
    "cumulatedPostTaxDailyReturns": 0.0441952918695967,
    "preTaxStrategyReturns": 0.008400000000000005,
    "preTaxOriginalUmaReturns": 0.30839999999999906,
    "postTaxStrategyReturns": 0.008400000000000005,
    "postTaxOriginalUmaReturns": 0.008400000000000005
  },
  {
    "dataDate": "2024-01-03",
    "postTaxDailyReturns": -0.0075341,
    "preTaxDailyReturns": -0.0075341,
    "cumulatedPreTaxDailyReturns": 0.03626814035952419,
    "cumulatedPostTaxDailyReturns": 0.03632822012112191,
    "preTaxStrategyReturns": 0.008500000000000004,
    "preTaxOriginalUmaReturns": 0.30849999999999905,
    "postTaxStrategyReturns": 0.008500000000000004,
    "postTaxOriginalUmaReturns": 0.008500000000000004
  },
  {
    "dataDate": "2024-01-04",
    "postTaxDailyReturns": -0.005544,
    "preTaxDailyReturns": -0.005544,
    "cumulatedPreTaxDailyReturns": 0.030523069789371027,
    "cumulatedPostTaxDailyReturns": 0.03058281646877048,
    "preTaxStrategyReturns": 0.008600000000000003,
    "preTaxOriginalUmaReturns": 0.30859999999999904,
    "postTaxStrategyReturns": 0.008600000000000003,
    "postTaxOriginalUmaReturns": 0.008600000000000003
  },
  {
    "dataDate": "2024-01-05",
    "postTaxDailyReturns": 0.0008717,
    "preTaxDailyReturns": 0.0008717,
    "cumulatedPreTaxDailyReturns": 0.03142137674930656,
    "cumulatedPostTaxDailyReturns": 0.03148117550988627,
    "preTaxStrategyReturns": 0.008700000000000003,
    "preTaxOriginalUmaReturns": 0.30869999999999903,
    "postTaxStrategyReturns": 0.008700000000000003,
    "postTaxOriginalUmaReturns": 0.008700000000000003
  },
  {
    "dataDate": "2024-01-08",
    "postTaxDailyReturns": 0.0109589,
    "preTaxDailyReturns": 0.0109589,
    "cumulatedPreTaxDailyReturns": 0.042724620474964725,
    "cumulatedPostTaxDailyReturns": 0.04278507456418157,
    "preTaxStrategyReturns": 0.008800000000000002,
    "preTaxOriginalUmaReturns": 0.308799999999999,
    "postTaxStrategyReturns": 0.008800000000000002,
    "postTaxOriginalUmaReturns": 0.008800000000000002
  },
  {
    "dataDate": "2024-01-09",
    "postTaxDailyReturns": -0.002083,
    "preTaxDailyReturns": -0.002083,
    "cumulatedPreTaxDailyReturns": 0.04055262509051549,
    "cumulatedPostTaxDailyReturns": 0.04061295325386438,
    "preTaxStrategyReturns": 0.008900000000000002,
    "preTaxOriginalUmaReturns": 0.308899999999999,
    "postTaxStrategyReturns": 0.008900000000000002,
    "postTaxOriginalUmaReturns": 0.008900000000000002
  },
  {
    "dataDate": "2024-01-10",
    "postTaxDailyReturns": 0.0043149,
    "preTaxDailyReturns": 0.0043149,
    "cumulatedPreTaxDailyReturns": 0.045042505612518546,
    "cumulatedPostTaxDailyReturns": 0.04510309408585944,
    "preTaxStrategyReturns": 0.009000000000000001,
    "preTaxOriginalUmaReturns": 0.308999999999999,
    "postTaxStrategyReturns": 0.009000000000000001,
    "postTaxOriginalUmaReturns": 0.009000000000000001
  },
  {
    "dataDate": "2024-01-11",
    "postTaxDailyReturns": 0.000032,
    "preTaxDailyReturns": 0.000032,
    "cumulatedPreTaxDailyReturns": 0.04507594697269823,
    "cumulatedPostTaxDailyReturns": 0.04513653738487022,
    "preTaxStrategyReturns": 0.0091,
    "preTaxOriginalUmaReturns": 0.309099999999999,
    "postTaxStrategyReturns": 0.0091,
    "postTaxOriginalUmaReturns": 0.0091
  },
  {
    "dataDate": "2024-01-12",
    "postTaxDailyReturns": 0.0022808,
    "preTaxDailyReturns": 0.0022808,
    "cumulatedPreTaxDailyReturns": 0.04745955619255371,
    "cumulatedPostTaxDailyReturns": 0.047520284799337675,
    "preTaxStrategyReturns": 0.0092,
    "preTaxOriginalUmaReturns": 0.309199999999999,
    "postTaxStrategyReturns": 0.0092,
    "postTaxOriginalUmaReturns": 0.0092
  },
  {
    "dataDate": "2024-01-16",
    "postTaxDailyReturns": -0.0048446,
    "preTaxDailyReturns": -0.0048446,
    "cumulatedPreTaxDailyReturns": 0.04238503362662338,
    "cumulatedPostTaxDailyReturns": 0.04244546802759874,
    "preTaxStrategyReturns": 0.0093,
    "preTaxOriginalUmaReturns": 0.30929999999999896,
    "postTaxStrategyReturns": 0.0093,
    "postTaxOriginalUmaReturns": 0.0093
  },
  {
    "dataDate": "2024-01-17",
    "postTaxDailyReturns": -0.0060428,
    "preTaxDailyReturns": -0.0060428,
    "cumulatedPreTaxDailyReturns": 0.03608610934542433,
    "cumulatedPostTaxDailyReturns": 0.03614617855340163,
    "preTaxStrategyReturns": 0.009399999999999999,
    "preTaxOriginalUmaReturns": 0.30939999999999895,
    "postTaxStrategyReturns": 0.009399999999999999,
    "postTaxOriginalUmaReturns": 0.009399999999999999
  },
  {
    "dataDate": "2024-01-18",
    "postTaxDailyReturns": 0.0089015,
    "preTaxDailyReturns": 0.0089015,
    "cumulatedPreTaxDailyReturns": 0.04530882984776241,
    "cumulatedPostTaxDailyReturns": 0.0453694337617947,
    "preTaxStrategyReturns": 0.009499999999999998,
    "preTaxOriginalUmaReturns": 0.30949999999999894,
    "postTaxStrategyReturns": 0.009499999999999998,
    "postTaxOriginalUmaReturns": 0.009499999999999998
  },
  {
    "dataDate": "2024-01-19",
    "postTaxDailyReturns": 0.0138894,
    "preTaxDailyReturns": 0.0138894,
    "cumulatedPreTaxDailyReturns": 0.059827542309049964,
    "cumulatedPostTaxDailyReturns": 0.059888987975085906,
    "preTaxStrategyReturns": 0.009599999999999997,
    "preTaxOriginalUmaReturns": 0.30959999999999893,
    "postTaxStrategyReturns": 0.009599999999999997,
    "postTaxOriginalUmaReturns": 0.009599999999999997
  },
  {
    "dataDate": "2024-01-22",
    "postTaxDailyReturns": 0.0029737,
    "preTaxDailyReturns": 0.0029737,
    "cumulatedPreTaxDailyReturns": 0.06297915147161448,
    "cumulatedPostTaxDailyReturns": 0.06304077985862744,
    "preTaxStrategyReturns": 0.009699999999999997,
    "preTaxOriginalUmaReturns": 0.3096999999999989,
    "postTaxStrategyReturns": 0.009699999999999997,
    "postTaxOriginalUmaReturns": 0.009699999999999997
  },
  {
    "dataDate": "2024-01-23",
    "postTaxDailyReturns": 0.0039715,
    "preTaxDailyReturns": 0.0039715,
    "cumulatedPreTaxDailyReturns": 0.06720077317168394,
    "cumulatedPostTaxDailyReturns": 0.0672626463158359,
    "preTaxStrategyReturns": 0.009799999999999996,
    "preTaxOriginalUmaReturns": 0.3097999999999989,
    "postTaxStrategyReturns": 0.009799999999999996,
    "postTaxOriginalUmaReturns": 0.009799999999999996
  },
  {
    "dataDate": "2024-01-24",
    "postTaxDailyReturns": 0.0014994,
    "preTaxDailyReturns": 0.0014994,
    "cumulatedPreTaxDailyReturns": 0.06880093401097742,
    "cumulatedPostTaxDailyReturns": 0.06886289992772188,
    "preTaxStrategyReturns": 0.009899999999999996,
    "preTaxOriginalUmaReturns": 0.3098999999999989,
    "postTaxStrategyReturns": 0.009899999999999996,
    "postTaxOriginalUmaReturns": 0.009899999999999996
  },
  {
    "dataDate": "2024-01-25",
    "postTaxDailyReturns": 0.0057539,
    "preTaxDailyReturns": 0.0057539,
    "cumulatedPreTaxDailyReturns": 0.0749507077051832,
    "cumulatedPostTaxDailyReturns": 0.07501303016761596,
    "preTaxStrategyReturns": 0.009999999999999995,
    "preTaxOriginalUmaReturns": 0.3099999999999989,
    "postTaxStrategyReturns": 0.009999999999999995,
    "postTaxOriginalUmaReturns": 0.009999999999999995
  },
  {
    "dataDate": "2024-01-26",
    "postTaxDailyReturns": -0.0008512,
    "preTaxDailyReturns": -0.0008512,
    "cumulatedPreTaxDailyReturns": 0.07403570966278439,
    "cumulatedPostTaxDailyReturns": 0.0740979790763372,
    "preTaxStrategyReturns": 0.010099999999999994,
    "preTaxOriginalUmaReturns": 0.3100999999999989,
    "postTaxStrategyReturns": 0.010099999999999994,
    "postTaxOriginalUmaReturns": 0.010099999999999994
  },
  {
    "dataDate": "2024-01-29",
    "postTaxDailyReturns": 0.0079679,
    "preTaxDailyReturns": 0.0079679,
    "cumulatedPreTaxDailyReturns": 0.0825935187938065,
    "cumulatedPostTaxDailyReturns": 0.08265628436381944,
    "preTaxStrategyReturns": 0.010199999999999994,
    "preTaxOriginalUmaReturns": 0.31019999999999887,
    "postTaxStrategyReturns": 0.010199999999999994,
    "postTaxOriginalUmaReturns": 0.010199999999999994
  },
  {
    "dataDate": "2024-01-30",
    "postTaxDailyReturns": -0.0001767,
    "preTaxDailyReturns": -0.0001767,
    "cumulatedPreTaxDailyReturns": 0.08240222451903567,
    "cumulatedPostTaxDailyReturns": 0.08246497899837224,
    "preTaxStrategyReturns": 0.010299999999999993,
    "preTaxOriginalUmaReturns": 0.31029999999999885,
    "postTaxStrategyReturns": 0.010299999999999993,
    "postTaxOriginalUmaReturns": 0.010299999999999993
  },
  {
    "dataDate": "2024-01-31",
    "postTaxDailyReturns": -0.016455,
    "preTaxDailyReturns": -0.0164392,
    "cumulatedPreTaxDailyReturns": 0.06460839786972228,
    "cumulatedPostTaxDailyReturns": 0.06465301776895394,
    "preTaxStrategyReturns": 0.010399999999999993,
    "preTaxOriginalUmaReturns": 0.31039999999999884,
    "postTaxStrategyReturns": 0.010399999999999993,
    "postTaxOriginalUmaReturns": 0.010399999999999993
  },
  {
    "dataDate": "2024-02-01",
    "postTaxDailyReturns": 0.0098197,
    "preTaxDailyReturns": 0.0098372,
    "cumulatedPreTaxDailyReturns": 0.07508116360124628,
    "cumulatedPostTaxDailyReturns": 0.07510759100753961,
    "preTaxStrategyReturns": 0.010499999999999992,
    "preTaxOriginalUmaReturns": 0.31049999999999883,
    "postTaxStrategyReturns": 0.010499999999999992,
    "postTaxOriginalUmaReturns": 0.010499999999999992
  },
  {
    "dataDate": "2024-02-02",
    "postTaxDailyReturns": 0.0094087,
    "preTaxDailyReturns": 0.0094087,
    "cumulatedPreTaxDailyReturns": 0.08519627974522148,
    "cumulatedPostTaxDailyReturns": 0.08522295579905226,
    "preTaxStrategyReturns": 0.010599999999999991,
    "preTaxOriginalUmaReturns": 0.3105999999999988,
    "postTaxStrategyReturns": 0.010599999999999991,
    "postTaxOriginalUmaReturns": 0.010599999999999991
  },
  {
    "dataDate": "2024-02-05",
    "postTaxDailyReturns": -0.001394,
    "preTaxDailyReturns": -0.001394,
    "cumulatedPreTaxDailyReturns": 0.08368351613125657,
    "cumulatedPostTaxDailyReturns": 0.0837101549986683,
    "preTaxStrategyReturns": 0.01069999999999999,
    "preTaxOriginalUmaReturns": 0.3106999999999988,
    "postTaxStrategyReturns": 0.01069999999999999,
    "postTaxOriginalUmaReturns": 0.01069999999999999
  },
  {
    "dataDate": "2024-02-08",
    "postTaxDailyReturns": 0,
    "preTaxDailyReturns": 0,
    "cumulatedPreTaxDailyReturns": 0.08368351613125657,
    "cumulatedPostTaxDailyReturns": 0.0837101549986683,
    "preTaxStrategyReturns": 0.01079999999999999,
    "preTaxOriginalUmaReturns": 0.3107999999999988,
    "postTaxStrategyReturns": 0.01079999999999999,
    "postTaxOriginalUmaReturns": 0.01079999999999999
  },
  {
    "dataDate": "2024-02-15",
    "postTaxDailyReturns": 0,
    "preTaxDailyReturns": 0,
    "cumulatedPreTaxDailyReturns": 0.08368351613125657,
    "cumulatedPostTaxDailyReturns": 0.0837101549986683,
    "preTaxStrategyReturns": 0.01089999999999999,
    "preTaxOriginalUmaReturns": 0.3108999999999988,
    "postTaxStrategyReturns": 0.01089999999999999,
    "postTaxOriginalUmaReturns": 0.01089999999999999
  },
  {
    "dataDate": "2024-02-16",
    "postTaxDailyReturns": 0,
    "preTaxDailyReturns": 0,
    "cumulatedPreTaxDailyReturns": 0.08368351613125657,
    "cumulatedPostTaxDailyReturns": 0.0837101549986683,
    "preTaxStrategyReturns": 0.010999999999999989,
    "preTaxOriginalUmaReturns": 0.3109999999999988,
    "postTaxStrategyReturns": 0.010999999999999989,
    "postTaxOriginalUmaReturns": 0.010999999999999989
  },
  {
    "dataDate": "2024-02-20",
    "postTaxDailyReturns": -0.006365,
    "preTaxDailyReturns": -0.006365,
    "cumulatedPreTaxDailyReturns": 0.0767858705510811,
    "cumulatedPostTaxDailyReturns": 0.0768123398621019,
    "preTaxStrategyReturns": 0.011099999999999988,
    "preTaxOriginalUmaReturns": 0.31109999999999877,
    "postTaxStrategyReturns": 0.011099999999999988,
    "postTaxOriginalUmaReturns": 0.011099999999999988
  },
  {
    "dataDate": "2024-02-21",
    "postTaxDailyReturns": 0.0026142,
    "preTaxDailyReturns": 0.0026142,
    "cumulatedPreTaxDailyReturns": 0.07960080417387583,
    "cumulatedPostTaxDailyReturns": 0.07962734268096949,
    "preTaxStrategyReturns": 0.011199999999999988,
    "preTaxOriginalUmaReturns": 0.31119999999999876,
    "postTaxStrategyReturns": 0.011199999999999988,
    "postTaxOriginalUmaReturns": 0.011199999999999988
  },
  {
    "dataDate": "2024-02-22",
    "postTaxDailyReturns": 0.0225483,
    "preTaxDailyReturns": 0.0225483,
    "cumulatedPreTaxDailyReturns": 0.10394396698662955,
    "cumulatedPostTaxDailyReturns": 0.10397110389194286,
    "preTaxStrategyReturns": 0.011299999999999987,
    "preTaxOriginalUmaReturns": 0.31129999999999874,
    "postTaxStrategyReturns": 0.011299999999999987,
    "postTaxOriginalUmaReturns": 0.011299999999999987
  },
  {
    "dataDate": "2024-02-23",
    "postTaxDailyReturns": 0.006488,
    "preTaxDailyReturns": 0.006488,
    "cumulatedPreTaxDailyReturns": 0.11110635544443892,
    "cumulatedPostTaxDailyReturns": 0.11113366841399386,
    "preTaxStrategyReturns": 0.011399999999999987,
    "preTaxOriginalUmaReturns": 0.31139999999999873,
    "postTaxStrategyReturns": 0.011399999999999987,
    "postTaxOriginalUmaReturns": 0.011399999999999987
  },
  {
    "dataDate": "2024-02-26",
    "postTaxDailyReturns": 0,
    "preTaxDailyReturns": 0,
    "cumulatedPreTaxDailyReturns": 0.11110635544443892,
    "cumulatedPostTaxDailyReturns": 0.11113366841399386,
    "preTaxStrategyReturns": 0.011499999999999986,
    "preTaxOriginalUmaReturns": 0.3114999999999987,
    "postTaxStrategyReturns": 0.011499999999999986,
    "postTaxOriginalUmaReturns": 0.011499999999999986
  },
  {
    "dataDate": "2024-02-27",
    "postTaxDailyReturns": -0.0084668,
    "preTaxDailyReturns": -0.0084668,
    "cumulatedPreTaxDailyReturns": 0.10169884015416186,
    "cumulatedPostTaxDailyReturns": 0.10172592187026619,
    "preTaxStrategyReturns": 0.011599999999999985,
    "preTaxOriginalUmaReturns": 0.3115999999999987,
    "postTaxStrategyReturns": 0.011599999999999985,
    "postTaxOriginalUmaReturns": 0.011599999999999985
  },
  {
    "dataDate": "2024-02-28",
    "postTaxDailyReturns": -0.0025677,
    "preTaxDailyReturns": -0.0025677,
    "cumulatedPreTaxDailyReturns": 0.09887000804229817,
    "cumulatedPostTaxDailyReturns": 0.09889702022068003,
    "preTaxStrategyReturns": 0.011699999999999985,
    "preTaxOriginalUmaReturns": 0.3116999999999987,
    "postTaxStrategyReturns": 0.011699999999999985,
    "postTaxOriginalUmaReturns": 0.011699999999999985
  },
  {
    "dataDate": "2024-02-29",
    "postTaxDailyReturns": 0.0054579,
    "preTaxDailyReturns": 0.0054579,
    "cumulatedPreTaxDailyReturns": 0.10486753065919219,
    "cumulatedPostTaxDailyReturns": 0.10489469026734244,
    "preTaxStrategyReturns": 0.011799999999999984,
    "preTaxOriginalUmaReturns": 0.3117999999999987,
    "postTaxStrategyReturns": 0.011799999999999984,
    "postTaxOriginalUmaReturns": 0.011799999999999984
  },
  {
    "dataDate": "2024-03-04",
    "postTaxDailyReturns": -0.0000376,
    "preTaxDailyReturns": 0,
    "cumulatedPreTaxDailyReturns": 0.10486753065919219,
    "cumulatedPostTaxDailyReturns": 0.10485314622698838,
    "preTaxStrategyReturns": 0.011899999999999984,
    "preTaxOriginalUmaReturns": 0.3118999999999987,
    "postTaxStrategyReturns": 0.011899999999999984,
    "postTaxOriginalUmaReturns": 0.011899999999999984
  }
]

const marketValueData = [
  {
    "dataDate": "2023-08-30",
    "metric": {
      "NET_MV_PLUS_CASH": 131003.9355079
    }
  },
  {
    "dataDate": "2023-08-31",
    "metric": {
      "NET_MV_PLUS_CASH": 131530.7341299
    }
  },
  {
    "dataDate": "2023-09-01",
    "metric": {
      "NET_MV_PLUS_CASH": 131230.6735192
    }
  },
  {
    "dataDate": "2023-09-02",
    "metric": {
      "NET_MV_PLUS_CASH": 131859.7451283
    }
  },
  {
    "dataDate": "2023-09-05",
    "metric": {
      "NET_MV_PLUS_CASH": 131859.7451283
    }
  },
  {
    "dataDate": "2023-09-06",
    "metric": {
      "NET_MV_PLUS_CASH": 131716.3149438
    }
  },
  {
    "dataDate": "2023-09-07",
    "metric": {
      "NET_MV_PLUS_CASH": 130969.6019476
    }
  },
  {
    "dataDate": "2023-09-08",
    "metric": {
      "NET_MV_PLUS_CASH": 130471.7588824
    }
  },
  {
    "dataDate": "2023-09-09",
    "metric": {
      "NET_MV_PLUS_CASH": 131057.0939497
    }
  },
  {
    "dataDate": "2023-09-11",
    "metric": {
      "NET_MV_PLUS_CASH": 131058.7939497
    }
  },
  {
    "dataDate": "2023-09-12",
    "metric": {
      "NET_MV_PLUS_CASH": 131628.8966824
    }
  },
  {
    "dataDate": "2023-09-13",
    "metric": {
      "NET_MV_PLUS_CASH": 131420.3573276
    }
  },
  {
    "dataDate": "2023-09-14",
    "metric": {
      "NET_MV_PLUS_CASH": 131525.5210545
    }
  },
  {
    "dataDate": "2023-09-15",
    "metric": {
      "NET_MV_PLUS_CASH": 132532.4833028
    }
  },
  {
    "dataDate": "2023-09-16",
    "metric": {
      "NET_MV_PLUS_CASH": 130705.9221343
    }
  },
  {
    "dataDate": "2023-09-18",
    "metric": {
      "NET_MV_PLUS_CASH": 130705.9221343
    }
  },
  {
    "dataDate": "2023-09-19",
    "metric": {
      "NET_MV_PLUS_CASH": 130946.4960992
    }
  },
  {
    "dataDate": "2023-09-20",
    "metric": {
      "NET_MV_PLUS_CASH": 130579.6914611
    }
  },
  {
    "dataDate": "2023-09-21",
    "metric": {
      "NET_MV_PLUS_CASH": 129184.8079021
    }
  },
  {
    "dataDate": "2023-09-22",
    "metric": {
      "NET_MV_PLUS_CASH": 127117.6401242
    }
  },
  {
    "dataDate": "2023-09-23",
    "metric": {
      "NET_MV_PLUS_CASH": 127033.0550542
    }
  },
  {
    "dataDate": "2023-09-26",
    "metric": {
      "NET_MV_PLUS_CASH": 127656.9210117
    }
  },
  {
    "dataDate": "2023-09-27",
    "metric": {
      "NET_MV_PLUS_CASH": 125881.3990567
    }
  },
  {
    "dataDate": "2023-09-28",
    "metric": {
      "NET_MV_PLUS_CASH": 126561.6369254
    }
  },
  {
    "dataDate": "2023-09-29",
    "metric": {
      "NET_MV_PLUS_CASH": 127336.344967
    }
  },
  {
    "dataDate": "2023-09-30",
    "metric": {
      "NET_MV_PLUS_CASH": 126686.2732224
    }
  },
  {
    "dataDate": "2023-10-01",
    "metric": {
      "NET_MV_PLUS_CASH": 126780.7632224
    }
  },
  {
    "dataDate": "2023-10-03",
    "metric": {
      "NET_MV_PLUS_CASH": 126440.6584832
    }
  },
  {
    "dataDate": "2023-10-04",
    "metric": {
      "NET_MV_PLUS_CASH": 124752.3801246
    }
  },
  {
    "dataDate": "2023-10-05",
    "metric": {
      "NET_MV_PLUS_CASH": 125240.9773641
    }
  },
  {
    "dataDate": "2023-10-06",
    "metric": {
      "NET_MV_PLUS_CASH": 124894.3869766
    }
  },
  {
    "dataDate": "2023-10-07",
    "metric": {
      "NET_MV_PLUS_CASH": 126274.2254321
    }
  },
  {
    "dataDate": "2023-10-10",
    "metric": {
      "NET_MV_PLUS_CASH": 127594.2657058
    }
  },
  {
    "dataDate": "2023-10-11",
    "metric": {
      "NET_MV_PLUS_CASH": 128272.7401215
    }
  },
  {
    "dataDate": "2023-10-12",
    "metric": {
      "NET_MV_PLUS_CASH": 128456.9854168
    }
  },
  {
    "dataDate": "2023-10-13",
    "metric": {
      "NET_MV_PLUS_CASH": 127820.8241038
    }
  },
  {
    "dataDate": "2023-10-14",
    "metric": {
      "NET_MV_PLUS_CASH": 127742.2214409
    }
  },
  {
    "dataDate": "2023-10-15",
    "metric": {
      "NET_MV_PLUS_CASH": 127686.5214388
    }
  },
  {
    "dataDate": "2023-10-16",
    "metric": {
      "NET_MV_PLUS_CASH": 127747.4014409
    }
  },
  {
    "dataDate": "2023-10-17",
    "metric": {
      "NET_MV_PLUS_CASH": 129047.392449
    }
  },
  {
    "dataDate": "2023-10-18",
    "metric": {
      "NET_MV_PLUS_CASH": 129154.0637049
    }
  },
  {
    "dataDate": "2023-10-19",
    "metric": {
      "NET_MV_PLUS_CASH": 127986.602836
    }
  },
  {
    "dataDate": "2023-10-20",
    "metric": {
      "NET_MV_PLUS_CASH": 127231.9195659
    }
  },
  {
    "dataDate": "2023-10-21",
    "metric": {
      "NET_MV_PLUS_CASH": 125441.566431
    }
  },
  {
    "dataDate": "2023-10-22",
    "metric": {
      "NET_MV_PLUS_CASH": 125444.526431
    }
  },
  {
    "dataDate": "2023-10-23",
    "metric": {
      "NET_MV_PLUS_CASH": 125444.526431
    }
  },
  {
    "dataDate": "2023-10-24",
    "metric": {
      "NET_MV_PLUS_CASH": 125219.298453
    }
  },
  {
    "dataDate": "2023-10-25",
    "metric": {
      "NET_MV_PLUS_CASH": 125840.5681849
    }
  },
  {
    "dataDate": "2023-10-26",
    "metric": {
      "NET_MV_PLUS_CASH": 123911.7381949
    }
  },
  {
    "dataDate": "2023-10-27",
    "metric": {
      "NET_MV_PLUS_CASH": 122411.844137
    }
  },
  {
    "dataDate": "2023-10-28",
    "metric": {
      "NET_MV_PLUS_CASH": 121915.4271059
    }
  },
  {
    "dataDate": "2023-10-31",
    "metric": {
      "NET_MV_PLUS_CASH": 123051.2510657
    }
  },
  {
    "dataDate": "2023-11-01",
    "metric": {
      "NET_MV_PLUS_CASH": 123975.3962947
    }
  },
  {
    "dataDate": "2023-11-02",
    "metric": {
      "NET_MV_PLUS_CASH": 125047.6573843
    }
  },
  {
    "dataDate": "2023-11-03",
    "metric": {
      "NET_MV_PLUS_CASH": 127667.8508261
    }
  },
  {
    "dataDate": "2023-11-04",
    "metric": {
      "NET_MV_PLUS_CASH": 128302.7892272
    }
  },
  {
    "dataDate": "2023-11-07",
    "metric": {
      "NET_MV_PLUS_CASH": 128277.5875315
    }
  },
  {
    "dataDate": "2023-11-08",
    "metric": {
      "NET_MV_PLUS_CASH": 128453.9558149
    }
  },
  {
    "dataDate": "2023-11-09",
    "metric": {
      "NET_MV_PLUS_CASH": 128371.5723153
    }
  },
  {
    "dataDate": "2023-11-10",
    "metric": {
      "NET_MV_PLUS_CASH": 127452.9347654
    }
  },
  {
    "dataDate": "2023-11-11",
    "metric": {
      "NET_MV_PLUS_CASH": 129542.3821239
    }
  },
  {
    "dataDate": "2023-11-14",
    "metric": {
      "NET_MV_PLUS_CASH": 129690.8580158
    }
  },
  {
    "dataDate": "2023-11-15",
    "metric": {
      "NET_MV_PLUS_CASH": 131986.614842
    }
  },
  {
    "dataDate": "2023-11-16",
    "metric": {
      "NET_MV_PLUS_CASH": 131913.3016808
    }
  },
  {
    "dataDate": "2023-11-17",
    "metric": {
      "NET_MV_PLUS_CASH": 131806.5846762
    }
  },
  {
    "dataDate": "2023-11-18",
    "metric": {
      "NET_MV_PLUS_CASH": 132232.2228271
    }
  },
  {
    "dataDate": "2023-11-21",
    "metric": {
      "NET_MV_PLUS_CASH": 133060.865407
    }
  },
  {
    "dataDate": "2023-11-22",
    "metric": {
      "NET_MV_PLUS_CASH": 132637.0625525
    }
  },
  {
    "dataDate": "2023-11-23",
    "metric": {
      "NET_MV_PLUS_CASH": 133095.3971873
    }
  },
  {
    "dataDate": "2023-11-28",
    "metric": {
      "NET_MV_PLUS_CASH": 133036.9204572
    }
  },
  {
    "dataDate": "2023-11-29",
    "metric": {
      "NET_MV_PLUS_CASH": 133257.2982973
    }
  },
  {
    "dataDate": "2023-11-30",
    "metric": {
      "NET_MV_PLUS_CASH": 133209.2993491
    }
  },
  {
    "dataDate": "2023-12-01",
    "metric": {
      "NET_MV_PLUS_CASH": 133503.8421484
    }
  },
  {
    "dataDate": "2023-12-02",
    "metric": {
      "NET_MV_PLUS_CASH": 134023.0429238
    }
  },
  {
    "dataDate": "2023-12-05",
    "metric": {
      "NET_MV_PLUS_CASH": 133060.9846629
    }
  },
  {
    "dataDate": "2023-12-06",
    "metric": {
      "NET_MV_PLUS_CASH": 132793.2893579
    }
  },
  {
    "dataDate": "2023-12-07",
    "metric": {
      "NET_MV_PLUS_CASH": 131929.689978
    }
  },
  {
    "dataDate": "2023-12-08",
    "metric": {
      "NET_MV_PLUS_CASH": 132854.6033437
    }
  },
  {
    "dataDate": "2023-12-11",
    "metric": {
      "NET_MV_PLUS_CASH": 133535.3047291
    }
  },
  {
    "dataDate": "2023-12-12",
    "metric": {
      "NET_MV_PLUS_CASH": 134461.0276616
    }
  },
  {
    "dataDate": "2023-12-13",
    "metric": {
      "NET_MV_PLUS_CASH": 135053.961772
    }
  },
  {
    "dataDate": "2023-12-14",
    "metric": {
      "NET_MV_PLUS_CASH": 136893.5920464
    }
  },
  {
    "dataDate": "2023-12-15",
    "metric": {
      "NET_MV_PLUS_CASH": 137358.2385999
    }
  },
  {
    "dataDate": "2023-12-16",
    "metric": {
      "NET_MV_PLUS_CASH": 137522.9875393
    }
  },
  {
    "dataDate": "2023-12-19",
    "metric": {
      "NET_MV_PLUS_CASH": 138223.3709904
    }
  },
  {
    "dataDate": "2023-12-20",
    "metric": {
      "NET_MV_PLUS_CASH": 139032.107133
    }
  },
  {
    "dataDate": "2023-12-21",
    "metric": {
      "NET_MV_PLUS_CASH": 137171.1519897
    }
  },
  {
    "dataDate": "2023-12-22",
    "metric": {
      "NET_MV_PLUS_CASH": 138484.2882119
    }
  },
  {
    "dataDate": "2023-12-23",
    "metric": {
      "NET_MV_PLUS_CASH": 138475.5248408
    }
  },
  {
    "dataDate": "2023-12-26",
    "metric": {
      "NET_MV_PLUS_CASH": 139080.74
    }
  },
  {
    "dataDate": "2023-12-27",
    "metric": {
      "NET_MV_PLUS_CASH": 139080.7410996
    }
  },
  {
    "dataDate": "2023-12-28",
    "metric": {
      "NET_MV_PLUS_CASH": 138933.58
    }
  },
  {
    "dataDate": "2023-12-29",
    "metric": {
      "NET_MV_PLUS_CASH": 138516.03
    }
  },
  {
    "dataDate": "2024-01-02",
    "metric": {
      "NET_MV_PLUS_CASH": 137344.56
    }
  },
  {
    "dataDate": "2024-01-03",
    "metric": {
      "NET_MV_PLUS_CASH": 136309.79
    }
  },
  {
    "dataDate": "2024-01-04",
    "metric": {
      "NET_MV_PLUS_CASH": 135554.09
    }
  },
  {
    "dataDate": "2024-01-05",
    "metric": {
      "NET_MV_PLUS_CASH": 135672.25
    }
  },
  {
    "dataDate": "2024-01-08",
    "metric": {
      "NET_MV_PLUS_CASH": 137159.07
    }
  },
  {
    "dataDate": "2024-01-09",
    "metric": {
      "NET_MV_PLUS_CASH": 136873.37
    }
  },
  {
    "dataDate": "2024-01-10",
    "metric": {
      "NET_MV_PLUS_CASH": 137463.96
    }
  },
  {
    "dataDate": "2024-01-11",
    "metric": {
      "NET_MV_PLUS_CASH": 137468.35
    }
  },
  {
    "dataDate": "2024-01-12",
    "metric": {
      "NET_MV_PLUS_CASH": 137781.89
    }
  },
  {
    "dataDate": "2024-01-16",
    "metric": {
      "NET_MV_PLUS_CASH": 137114.39
    }
  },
  {
    "dataDate": "2024-01-17",
    "metric": {
      "NET_MV_PLUS_CASH": 136285.83
    }
  },
  {
    "dataDate": "2024-01-18",
    "metric": {
      "NET_MV_PLUS_CASH": 137498.99
    }
  },
  {
    "dataDate": "2024-01-19",
    "metric": {
      "NET_MV_PLUS_CASH": 139408.76
    }
  },
  {
    "dataDate": "2024-01-22",
    "metric": {
      "NET_MV_PLUS_CASH": 139823.32
    }
  },
  {
    "dataDate": "2024-01-23",
    "metric": {
      "NET_MV_PLUS_CASH": 140378.63
    }
  },
  {
    "dataDate": "2024-01-24",
    "metric": {
      "NET_MV_PLUS_CASH": 140589.11
    }
  },
  {
    "dataDate": "2024-01-25",
    "metric": {
      "NET_MV_PLUS_CASH": 141398.06
    }
  },
  {
    "dataDate": "2024-01-26",
    "metric": {
      "NET_MV_PLUS_CASH": 141277.69
    }
  },
  {
    "dataDate": "2024-01-29",
    "metric": {
      "NET_MV_PLUS_CASH": 142403.37
    }
  },
  {
    "dataDate": "2024-01-30",
    "metric": {
      "NET_MV_PLUS_CASH": 142378.21
    }
  },
  {
    "dataDate": "2024-01-31",
    "metric": {
      "NET_MV_PLUS_CASH": 140037.62
    }
  },
  {
    "dataDate": "2024-02-01",
    "metric": {
      "NET_MV_PLUS_CASH": 141415.2
    }
  },
  {
    "dataDate": "2024-02-02",
    "metric": {
      "NET_MV_PLUS_CASH": 142745.73
    }
  },
  {
    "dataDate": "2024-02-05",
    "metric": {
      "NET_MV_PLUS_CASH": 142546.74
    }
  },
  {
    "dataDate": "2024-02-08",
    "metric": {
      "NET_MV_PLUS_CASH": 144433.1514374
    }
  },
  {
    "dataDate": "2024-02-15",
    "metric": {
      "NET_MV_PLUS_CASH": 144804.9639922
    }
  },
  {
    "dataDate": "2024-02-16",
    "metric": {
      "NET_MV_PLUS_CASH": 144007.0070638
    }
  },
  {
    "dataDate": "2024-02-20",
    "metric": {
      "NET_MV_PLUS_CASH": 143090.4010951
    }
  },
  {
    "dataDate": "2024-02-21",
    "metric": {
      "NET_MV_PLUS_CASH": 143464.4652042
    }
  },
  {
    "dataDate": "2024-02-22",
    "metric": {
      "NET_MV_PLUS_CASH": 146699.3393987
    }
  },
  {
    "dataDate": "2024-02-23",
    "metric": {
      "NET_MV_PLUS_CASH": 147651.1305945
    }
  },
  {
    "dataDate": "2024-02-26",
    "metric": {
      "NET_MV_PLUS_CASH": 147651.1305945
    }
  },
  {
    "dataDate": "2024-02-27",
    "metric": {
      "NET_MV_PLUS_CASH": 146401.0041997
    }
  },
  {
    "dataDate": "2024-02-28",
    "metric": {
      "NET_MV_PLUS_CASH": 146025.0857645
    }
  },
  {
    "dataDate": "2024-02-29",
    "metric": {
      "NET_MV_PLUS_CASH": 146822.0829834
    }
  },
  {
    "dataDate": "2024-03-04",
    "metric": {
      "NET_MV_PLUS_CASH": 148152.9966351,
      "DIV_AND_INT": [
        {
          "total": 27.82
        },
        {
          "settlementDate": "2024-03-04T00:00:00.000Z",
          "exDividendDate": "2024-01-31T00:00:00.000Z",
          "instrId": "VLO-XNYS",
          "dividendValue": 27.82,
          "localSymbol": "VLO",
          "dividendTypeCode": "QDV",
          "dividendCodeDesc": "QUALIFIED DIV",
          "dataDate": "2024-03-04"
        }
      ]
    }
  }
]

const benchmarkData = [
  {
    "dataDate": "2023-08-30",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0,
    "strPreTaxDailyReturns": 0,
    "strCumulatedPreTaxDailyReturns": 0
  },
  {
    "dataDate": "2023-08-31",
    "accPreTaxDailyReturns": -0.0022813,
    "accCumulatedPreTaxDailyReturns": -0.0022813,
    "strPreTaxDailyReturns": -0.0010995,
    "strCumulatedPreTaxDailyReturns": -0.0010995
  },
  {
    "dataDate": "2023-09-01",
    "accPreTaxDailyReturns": 0.0047936,
    "accCumulatedPreTaxDailyReturns": 0.0025014,
    "strPreTaxDailyReturns": 0.0022143,
    "strCumulatedPreTaxDailyReturns": 0.0011124
  },
  {
    "dataDate": "2023-09-05",
    "accPreTaxDailyReturns": -0.0010877,
    "accCumulatedPreTaxDailyReturns": 0.0014109,
    "strPreTaxDailyReturns": -0.0034752,
    "strCumulatedPreTaxDailyReturns": -0.0023667
  },
  {
    "dataDate": "2023-09-06",
    "accPreTaxDailyReturns": -0.0056691,
    "accCumulatedPreTaxDailyReturns": -0.0042662,
    "strPreTaxDailyReturns": -0.0066951,
    "strCumulatedPreTaxDailyReturns": -0.009046
  },
  {
    "dataDate": "2023-09-07",
    "accPreTaxDailyReturns": -0.0038012,
    "accCumulatedPreTaxDailyReturns": -0.0080511,
    "strPreTaxDailyReturns": -0.0027391,
    "strCumulatedPreTaxDailyReturns": -0.0117603
  },
  {
    "dataDate": "2023-09-08",
    "accPreTaxDailyReturns": 0.0044993,
    "accCumulatedPreTaxDailyReturns": -0.0035881,
    "strPreTaxDailyReturns": 0.0016985,
    "strCumulatedPreTaxDailyReturns": -0.0100818
  },
  {
    "dataDate": "2023-09-11",
    "accPreTaxDailyReturns": 0.00435,
    "accCumulatedPreTaxDailyReturns": 0.0007463,
    "strPreTaxDailyReturns": 0.0073747,
    "strCumulatedPreTaxDailyReturns": -0.0027814
  },
  {
    "dataDate": "2023-09-12",
    "accPreTaxDailyReturns": -0.0015843,
    "accCumulatedPreTaxDailyReturns": -0.0008392,
    "strPreTaxDailyReturns": -0.0054849,
    "strCumulatedPreTaxDailyReturns": -0.008251
  },
  {
    "dataDate": "2023-09-13",
    "accPreTaxDailyReturns": 0.0008002,
    "accCumulatedPreTaxDailyReturns": -0.0000396,
    "strPreTaxDailyReturns": 0.001232,
    "strCumulatedPreTaxDailyReturns": -0.0070292
  },
  {
    "dataDate": "2023-09-14",
    "accPreTaxDailyReturns": 0.007656,
    "accCumulatedPreTaxDailyReturns": 0.0076161,
    "strPreTaxDailyReturns": 0.0081655,
    "strCumulatedPreTaxDailyReturns": 0.0010789
  },
  {
    "dataDate": "2023-09-15",
    "accPreTaxDailyReturns": -0.013782,
    "accCumulatedPreTaxDailyReturns": -0.0062709,
    "strPreTaxDailyReturns": -0.0119319,
    "strCumulatedPreTaxDailyReturns": -0.0108659
  },
  {
    "dataDate": "2023-09-18",
    "accPreTaxDailyReturns": 0.0018406,
    "accCumulatedPreTaxDailyReturns": -0.0044418,
    "strPreTaxDailyReturns": 0.0006474,
    "strCumulatedPreTaxDailyReturns": -0.0102255
  },
  {
    "dataDate": "2023-09-19",
    "accPreTaxDailyReturns": -0.0028012,
    "accCumulatedPreTaxDailyReturns": -0.0072306,
    "strPreTaxDailyReturns": -0.0021261,
    "strCumulatedPreTaxDailyReturns": -0.0123299
  },
  {
    "dataDate": "2023-09-20",
    "accPreTaxDailyReturns": -0.0106822,
    "accCumulatedPreTaxDailyReturns": -0.0178356,
    "strPreTaxDailyReturns": -0.0091633,
    "strCumulatedPreTaxDailyReturns": -0.0213802
  },
  {
    "dataDate": "2023-09-21",
    "accPreTaxDailyReturns": -0.0160016,
    "accCumulatedPreTaxDailyReturns": -0.0335518,
    "strPreTaxDailyReturns": -0.0164821,
    "strCumulatedPreTaxDailyReturns": -0.0375099
  },
  {
    "dataDate": "2023-09-22",
    "accPreTaxDailyReturns": -0.0006654,
    "accCumulatedPreTaxDailyReturns": -0.0341948,
    "strPreTaxDailyReturns": 0,
    "strCumulatedPreTaxDailyReturns": -0.0375099
  },
  {
    "dataDate": "2023-09-25",
    "accPreTaxDailyReturns": 0.0049111,
    "accCumulatedPreTaxDailyReturns": -0.0294517,
    "strPreTaxDailyReturns": 0.0020877,
    "strCumulatedPreTaxDailyReturns": -0.0355005
  },
  {
    "dataDate": "2023-09-26",
    "accPreTaxDailyReturns": -0.0139085,
    "accCumulatedPreTaxDailyReturns": -0.0429505,
    "strPreTaxDailyReturns": -0.0144963,
    "strCumulatedPreTaxDailyReturns": -0.0494822
  },
  {
    "dataDate": "2023-09-27",
    "accPreTaxDailyReturns": 0.0054038,
    "accCumulatedPreTaxDailyReturns": -0.0377788,
    "strPreTaxDailyReturns": 0.000626,
    "strCumulatedPreTaxDailyReturns": -0.0488872
  },
  {
    "dataDate": "2023-09-28",
    "accPreTaxDailyReturns": 0.0061212,
    "accCumulatedPreTaxDailyReturns": -0.0318889,
    "strPreTaxDailyReturns": 0.0060697,
    "strCumulatedPreTaxDailyReturns": -0.0431142
  },
  {
    "dataDate": "2023-09-29",
    "accPreTaxDailyReturns": -0.0043631,
    "accCumulatedPreTaxDailyReturns": -0.0361129,
    "strPreTaxDailyReturns": -0.0023768,
    "strCumulatedPreTaxDailyReturns": -0.0453885
  },
  {
    "dataDate": "2023-10-02",
    "accPreTaxDailyReturns": -0.0026826,
    "accCumulatedPreTaxDailyReturns": -0.0386986,
    "strPreTaxDailyReturns": -0.0001484,
    "strCumulatedPreTaxDailyReturns": -0.0455302
  },
  {
    "dataDate": "2023-10-03",
    "accPreTaxDailyReturns": -0.0133523,
    "accCumulatedPreTaxDailyReturns": -0.0515342,
    "strPreTaxDailyReturns": -0.0139191,
    "strCumulatedPreTaxDailyReturns": -0.0588155
  },
  {
    "dataDate": "2023-10-04",
    "accPreTaxDailyReturns": 0.0039165,
    "accCumulatedPreTaxDailyReturns": -0.0478195,
    "strPreTaxDailyReturns": 0.0084499,
    "strCumulatedPreTaxDailyReturns": -0.0508626
  },
  {
    "dataDate": "2023-10-05",
    "accPreTaxDailyReturns": -0.0027674,
    "accCumulatedPreTaxDailyReturns": -0.0504546,
    "strPreTaxDailyReturns": -0.0010649,
    "strCumulatedPreTaxDailyReturns": -0.0518734
  },
  {
    "dataDate": "2023-10-06",
    "accPreTaxDailyReturns": 0.011048,
    "accCumulatedPreTaxDailyReturns": -0.039964,
    "strPreTaxDailyReturns": 0.0127484,
    "strCumulatedPreTaxDailyReturns": -0.0397863
  },
  {
    "dataDate": "2023-10-09",
    "accPreTaxDailyReturns": 0.0104538,
    "accCumulatedPreTaxDailyReturns": -0.029928,
    "strPreTaxDailyReturns": 0.0065313,
    "strCumulatedPreTaxDailyReturns": -0.0335148
  },
  {
    "dataDate": "2023-10-10",
    "accPreTaxDailyReturns": 0.0053174,
    "accCumulatedPreTaxDailyReturns": -0.0247697,
    "strPreTaxDailyReturns": 0.0054623,
    "strCumulatedPreTaxDailyReturns": -0.0282356
  },
  {
    "dataDate": "2023-10-11",
    "accPreTaxDailyReturns": 0.0014364,
    "accCumulatedPreTaxDailyReturns": -0.0233689,
    "strPreTaxDailyReturns": 0.0045601,
    "strCumulatedPreTaxDailyReturns": -0.0238042
  },
  {
    "dataDate": "2023-10-12",
    "accPreTaxDailyReturns": -0.0049523,
    "accCumulatedPreTaxDailyReturns": -0.0282054,
    "strPreTaxDailyReturns": -0.0060282,
    "strCumulatedPreTaxDailyReturns": -0.029689
  },
  {
    "dataDate": "2023-10-13",
    "accPreTaxDailyReturns": -0.0006149,
    "accCumulatedPreTaxDailyReturns": -0.028803,
    "strPreTaxDailyReturns": -0.005019,
    "strCumulatedPreTaxDailyReturns": -0.0345589
  },
  {
    "dataDate": "2023-10-16",
    "accPreTaxDailyReturns": 0.0101763,
    "accCumulatedPreTaxDailyReturns": -0.0189198,
    "strPreTaxDailyReturns": 0.0108902,
    "strCumulatedPreTaxDailyReturns": -0.0240451
  },
  {
    "dataDate": "2023-10-17",
    "accPreTaxDailyReturns": 0.0008266,
    "accCumulatedPreTaxDailyReturns": -0.0181088,
    "strPreTaxDailyReturns": -0.0000104,
    "strCumulatedPreTaxDailyReturns": -0.0240552
  },
  {
    "dataDate": "2023-10-18",
    "accPreTaxDailyReturns": -0.0090393,
    "accCumulatedPreTaxDailyReturns": -0.0269845,
    "strPreTaxDailyReturns": -0.0132808,
    "strCumulatedPreTaxDailyReturns": -0.0370166
  },
  {
    "dataDate": "2023-10-19",
    "accPreTaxDailyReturns": -0.0058966,
    "accCumulatedPreTaxDailyReturns": -0.0327219,
    "strPreTaxDailyReturns": -0.0080302,
    "strCumulatedPreTaxDailyReturns": -0.0447495
  },
  {
    "dataDate": "2023-10-20",
    "accPreTaxDailyReturns": -0.0140716,
    "accCumulatedPreTaxDailyReturns": -0.0463331,
    "strPreTaxDailyReturns": -0.0126149,
    "strCumulatedPreTaxDailyReturns": -0.0567999
  },
  {
    "dataDate": "2023-10-23",
    "accPreTaxDailyReturns": -0.0017954,
    "accCumulatedPreTaxDailyReturns": -0.0480453,
    "strPreTaxDailyReturns": -0.0016905,
    "strCumulatedPreTaxDailyReturns": -0.0583944
  },
  {
    "dataDate": "2023-10-24",
    "accPreTaxDailyReturns": 0.0049615,
    "accCumulatedPreTaxDailyReturns": -0.0433222,
    "strPreTaxDailyReturns": 0.0076961,
    "strCumulatedPreTaxDailyReturns": -0.0511477
  },
  {
    "dataDate": "2023-10-25",
    "accPreTaxDailyReturns": -0.0153276,
    "accCumulatedPreTaxDailyReturns": -0.0579858,
    "strPreTaxDailyReturns": -0.0140413,
    "strCumulatedPreTaxDailyReturns": -0.0644708
  },
  {
    "dataDate": "2023-10-26",
    "accPreTaxDailyReturns": -0.0121045,
    "accCumulatedPreTaxDailyReturns": -0.0693884,
    "strPreTaxDailyReturns": -0.0116348,
    "strCumulatedPreTaxDailyReturns": -0.0753555
  },
  {
    "dataDate": "2023-10-27",
    "accPreTaxDailyReturns": -0.0040553,
    "accCumulatedPreTaxDailyReturns": -0.0731623,
    "strPreTaxDailyReturns": -0.0041043,
    "strCumulatedPreTaxDailyReturns": -0.0791505
  },
  {
    "dataDate": "2023-10-30",
    "accPreTaxDailyReturns": 0.0093165,
    "accCumulatedPreTaxDailyReturns": -0.0645274,
    "strPreTaxDailyReturns": 0.0124331,
    "strCumulatedPreTaxDailyReturns": -0.0677015
  },
  {
    "dataDate": "2023-10-31",
    "accPreTaxDailyReturns": 0.0075102,
    "accCumulatedPreTaxDailyReturns": -0.0575018,
    "strPreTaxDailyReturns": 0.0068317,
    "strCumulatedPreTaxDailyReturns": -0.0613323
  },
  {
    "dataDate": "2023-11-01",
    "accPreTaxDailyReturns": 0.008649,
    "accCumulatedPreTaxDailyReturns": -0.0493501,
    "strPreTaxDailyReturns": 0.0108652,
    "strCumulatedPreTaxDailyReturns": -0.0511335
  },
  {
    "dataDate": "2023-11-02",
    "accPreTaxDailyReturns": 0.0209536,
    "accCumulatedPreTaxDailyReturns": -0.0294306,
    "strPreTaxDailyReturns": 0.0194877,
    "strCumulatedPreTaxDailyReturns": -0.0326423
  },
  {
    "dataDate": "2023-11-03",
    "accPreTaxDailyReturns": 0.0049734,
    "accCumulatedPreTaxDailyReturns": -0.0246036,
    "strPreTaxDailyReturns": 0.0097271,
    "strCumulatedPreTaxDailyReturns": -0.0232327
  },
  {
    "dataDate": "2023-11-06",
    "accPreTaxDailyReturns": -0.0001964,
    "accCumulatedPreTaxDailyReturns": -0.0247951,
    "strPreTaxDailyReturns": 0.0015517,
    "strCumulatedPreTaxDailyReturns": -0.0217171
  },
  {
    "dataDate": "2023-11-07",
    "accPreTaxDailyReturns": 0.0013749,
    "accCumulatedPreTaxDailyReturns": -0.0234543,
    "strPreTaxDailyReturns": 0.0036628,
    "strCumulatedPreTaxDailyReturns": -0.0181338
  },
  {
    "dataDate": "2023-11-08",
    "accPreTaxDailyReturns": -0.0006413,
    "accCumulatedPreTaxDailyReturns": -0.0240806,
    "strPreTaxDailyReturns": 0.001087,
    "strCumulatedPreTaxDailyReturns": -0.0170665
  },
  {
    "dataDate": "2023-11-09",
    "accPreTaxDailyReturns": -0.0071561,
    "accCumulatedPreTaxDailyReturns": -0.0310644,
    "strPreTaxDailyReturns": -0.0079982,
    "strCumulatedPreTaxDailyReturns": -0.0249282
  },
  {
    "dataDate": "2023-11-10",
    "accPreTaxDailyReturns": 0.0163939,
    "accCumulatedPreTaxDailyReturns": -0.0151797,
    "strPreTaxDailyReturns": 0.0157113,
    "strCumulatedPreTaxDailyReturns": -0.0096086
  },
  {
    "dataDate": "2023-11-13",
    "accPreTaxDailyReturns": 0.0011462,
    "accCumulatedPreTaxDailyReturns": -0.0140509,
    "strPreTaxDailyReturns": -0.0006296,
    "strCumulatedPreTaxDailyReturns": -0.0102321
  },
  {
    "dataDate": "2023-11-14",
    "accPreTaxDailyReturns": 0.0177018,
    "accCumulatedPreTaxDailyReturns": 0.0034021,
    "strPreTaxDailyReturns": 0.0193937,
    "strCumulatedPreTaxDailyReturns": 0.0089631
  },
  {
    "dataDate": "2023-11-15",
    "accPreTaxDailyReturns": -0.0005555,
    "accCumulatedPreTaxDailyReturns": 0.0028448,
    "strPreTaxDailyReturns": 0.0016342,
    "strCumulatedPreTaxDailyReturns": 0.010612
  },
  {
    "dataDate": "2023-11-16",
    "accPreTaxDailyReturns": -0.000809,
    "accCumulatedPreTaxDailyReturns": 0.0020335,
    "strPreTaxDailyReturns": 0.001382,
    "strCumulatedPreTaxDailyReturns": 0.0120086
  },
  {
    "dataDate": "2023-11-17",
    "accPreTaxDailyReturns": 0.0032293,
    "accCumulatedPreTaxDailyReturns": 0.0052693,
    "strPreTaxDailyReturns": 0.0014222,
    "strCumulatedPreTaxDailyReturns": 0.0134479
  },
  {
    "dataDate": "2023-11-20",
    "accPreTaxDailyReturns": 0.0062666,
    "accCumulatedPreTaxDailyReturns": 0.0115689,
    "strPreTaxDailyReturns": 0.007682,
    "strCumulatedPreTaxDailyReturns": 0.0212332
  },
  {
    "dataDate": "2023-11-21",
    "accPreTaxDailyReturns": -0.003185,
    "accCumulatedPreTaxDailyReturns": 0.0083471,
    "strPreTaxDailyReturns": -0.0020667,
    "strCumulatedPreTaxDailyReturns": 0.0191227
  },
  {
    "dataDate": "2023-11-22",
    "accPreTaxDailyReturns": 0.0034556,
    "accCumulatedPreTaxDailyReturns": 0.0118315,
    "strPreTaxDailyReturns": 0.0042389,
    "strCumulatedPreTaxDailyReturns": 0.0234426
  },
  {
    "dataDate": "2023-11-24",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.0118315,
    "strPreTaxDailyReturns": 0.0007566,
    "strCumulatedPreTaxDailyReturns": 0.0242169
  },
  {
    "dataDate": "2023-11-27",
    "accPreTaxDailyReturns": -0.0004394,
    "accCumulatedPreTaxDailyReturns": 0.0113869,
    "strPreTaxDailyReturns": 0,
    "strCumulatedPreTaxDailyReturns": 0.0242169
  },
  {
    "dataDate": "2023-11-28",
    "accPreTaxDailyReturns": 0.0016565,
    "accCumulatedPreTaxDailyReturns": 0.0130623,
    "strPreTaxDailyReturns": 0,
    "strCumulatedPreTaxDailyReturns": 0.0242169
  },
  {
    "dataDate": "2023-11-29",
    "accPreTaxDailyReturns": -0.0003602,
    "accCumulatedPreTaxDailyReturns": 0.0126974,
    "strPreTaxDailyReturns": 0,
    "strCumulatedPreTaxDailyReturns": 0.0242169
  },
  {
    "dataDate": "2023-11-30",
    "accPreTaxDailyReturns": 0.0022111,
    "accCumulatedPreTaxDailyReturns": 0.0149366,
    "strPreTaxDailyReturns": 0.00409,
    "strCumulatedPreTaxDailyReturns": 0.028406
  },
  {
    "dataDate": "2023-12-01",
    "accPreTaxDailyReturns": 0.003889,
    "accCumulatedPreTaxDailyReturns": 0.0188837,
    "strPreTaxDailyReturns": 0.0060031,
    "strCumulatedPreTaxDailyReturns": 0.0345796
  },
  {
    "dataDate": "2023-12-04",
    "accPreTaxDailyReturns": -0.0071783,
    "accCumulatedPreTaxDailyReturns": 0.0115698,
    "strPreTaxDailyReturns": -0.0054564,
    "strCumulatedPreTaxDailyReturns": 0.0289345
  },
  {
    "dataDate": "2023-12-05",
    "accPreTaxDailyReturns": -0.0020118,
    "accCumulatedPreTaxDailyReturns": 0.0095347,
    "strPreTaxDailyReturns": -0.0005214,
    "strCumulatedPreTaxDailyReturns": 0.0283981
  },
  {
    "dataDate": "2023-12-06",
    "accPreTaxDailyReturns": -0.0065033,
    "accCumulatedPreTaxDailyReturns": 0.0029694,
    "strPreTaxDailyReturns": -0.0038868,
    "strCumulatedPreTaxDailyReturns": 0.0244009
  },
  {
    "dataDate": "2023-12-07",
    "accPreTaxDailyReturns": 0.0070107,
    "accCumulatedPreTaxDailyReturns": 0.0100009,
    "strPreTaxDailyReturns": 0.0080908,
    "strCumulatedPreTaxDailyReturns": 0.0326891
  },
  {
    "dataDate": "2023-12-08",
    "accPreTaxDailyReturns": 0.0051237,
    "accCumulatedPreTaxDailyReturns": 0.0151759,
    "strPreTaxDailyReturns": 0.004328,
    "strCumulatedPreTaxDailyReturns": 0.0371586
  },
  {
    "dataDate": "2023-12-11",
    "accPreTaxDailyReturns": 0.0069324,
    "accCumulatedPreTaxDailyReturns": 0.0222135,
    "strPreTaxDailyReturns": 0.0040641,
    "strCumulatedPreTaxDailyReturns": 0.0413737
  },
  {
    "dataDate": "2023-12-12",
    "accPreTaxDailyReturns": 0.0044097,
    "accCumulatedPreTaxDailyReturns": 0.0267211,
    "strPreTaxDailyReturns": 0.0047406,
    "strCumulatedPreTaxDailyReturns": 0.0463104
  },
  {
    "dataDate": "2023-12-13",
    "accPreTaxDailyReturns": 0.0136214,
    "accCumulatedPreTaxDailyReturns": 0.0407065,
    "strPreTaxDailyReturns": 0.0137163,
    "strCumulatedPreTaxDailyReturns": 0.0606619
  },
  {
    "dataDate": "2023-12-14",
    "accPreTaxDailyReturns": 0.0033942,
    "accCumulatedPreTaxDailyReturns": 0.0442389,
    "strPreTaxDailyReturns": 0.0029416,
    "strCumulatedPreTaxDailyReturns": 0.063782
  },
  {
    "dataDate": "2023-12-15",
    "accPreTaxDailyReturns": 0.0011994,
    "accCumulatedPreTaxDailyReturns": 0.0454913,
    "strPreTaxDailyReturns": 0.0000811,
    "strCumulatedPreTaxDailyReturns": 0.0638683
  },
  {
    "dataDate": "2023-12-18",
    "accPreTaxDailyReturns": 0.0050928,
    "accCumulatedPreTaxDailyReturns": 0.0508158,
    "strPreTaxDailyReturns": 0.0046389,
    "strCumulatedPreTaxDailyReturns": 0.0688034
  },
  {
    "dataDate": "2023-12-19",
    "accPreTaxDailyReturns": 0.0058509,
    "accCumulatedPreTaxDailyReturns": 0.056964,
    "strPreTaxDailyReturns": 0.0059791,
    "strCumulatedPreTaxDailyReturns": 0.0751939
  },
  {
    "dataDate": "2023-12-20",
    "accPreTaxDailyReturns": -0.0133851,
    "accCumulatedPreTaxDailyReturns": 0.0428165,
    "strPreTaxDailyReturns": -0.0146492,
    "strCumulatedPreTaxDailyReturns": 0.0594432
  },
  {
    "dataDate": "2023-12-21",
    "accPreTaxDailyReturns": 0.009573,
    "accCumulatedPreTaxDailyReturns": 0.0527994,
    "strPreTaxDailyReturns": 0.0103235,
    "strCumulatedPreTaxDailyReturns": 0.0703803
  },
  {
    "dataDate": "2023-12-22",
    "accPreTaxDailyReturns": -0.0000633,
    "accCumulatedPreTaxDailyReturns": 0.0527327,
    "strPreTaxDailyReturns": 0.0016531,
    "strCumulatedPreTaxDailyReturns": 0.0721498
  },
  {
    "dataDate": "2023-12-26",
    "accPreTaxDailyReturns": 0.0043706,
    "accCumulatedPreTaxDailyReturns": 0.0573338,
    "strPreTaxDailyReturns": 0.0042944,
    "strCumulatedPreTaxDailyReturns": 0.076754
  },
  {
    "dataDate": "2023-12-27",
    "accPreTaxDailyReturns": 0.0000329,
    "accCumulatedPreTaxDailyReturns": 0.0573686,
    "strPreTaxDailyReturns": 0.0016254,
    "strCumulatedPreTaxDailyReturns": 0.0785042
  },
  {
    "dataDate": "2023-12-28",
    "accPreTaxDailyReturns": -0.001091,
    "accCumulatedPreTaxDailyReturns": 0.056215,
    "strPreTaxDailyReturns": 0.000505,
    "strCumulatedPreTaxDailyReturns": 0.0790488
  },
  {
    "dataDate": "2023-12-29",
    "accPreTaxDailyReturns": -0.0030054,
    "accCumulatedPreTaxDailyReturns": 0.0530406,
    "strPreTaxDailyReturns": -0.0029831,
    "strCumulatedPreTaxDailyReturns": 0.0758299
  },
  {
    "dataDate": "2024-01-02",
    "accPreTaxDailyReturns": -0.0084573,
    "accCumulatedPreTaxDailyReturns": 0.0441348,
    "strPreTaxDailyReturns": -0.0063752,
    "strCumulatedPreTaxDailyReturns": 0.0689713
  },
  {
    "dataDate": "2024-01-03",
    "accPreTaxDailyReturns": -0.0075341,
    "accCumulatedPreTaxDailyReturns": 0.0362681,
    "strPreTaxDailyReturns": -0.0080195,
    "strCumulatedPreTaxDailyReturns": 0.0603987
  },
  {
    "dataDate": "2024-01-04",
    "accPreTaxDailyReturns": -0.005544,
    "accCumulatedPreTaxDailyReturns": 0.0305231,
    "strPreTaxDailyReturns": -0.0031157,
    "strCumulatedPreTaxDailyReturns": 0.0570948
  },
  {
    "dataDate": "2024-01-05",
    "accPreTaxDailyReturns": 0.0008717,
    "accCumulatedPreTaxDailyReturns": 0.0314214,
    "strPreTaxDailyReturns": 0.0017787,
    "strCumulatedPreTaxDailyReturns": 0.058975
  },
  {
    "dataDate": "2024-01-08",
    "accPreTaxDailyReturns": 0.0109589,
    "accCumulatedPreTaxDailyReturns": 0.0427246,
    "strPreTaxDailyReturns": 0.014456,
    "strCumulatedPreTaxDailyReturns": 0.0742836
  },
  {
    "dataDate": "2024-01-09",
    "accPreTaxDailyReturns": -0.002083,
    "accCumulatedPreTaxDailyReturns": 0.0405526,
    "strPreTaxDailyReturns": -0.0012444,
    "strCumulatedPreTaxDailyReturns": 0.0729467
  },
  {
    "dataDate": "2024-01-10",
    "accPreTaxDailyReturns": 0.0043149,
    "accCumulatedPreTaxDailyReturns": 0.0450425,
    "strPreTaxDailyReturns": 0.0057584,
    "strCumulatedPreTaxDailyReturns": 0.0791252
  },
  {
    "dataDate": "2024-01-11",
    "accPreTaxDailyReturns": 0.000032,
    "accCumulatedPreTaxDailyReturns": 0.0450759,
    "strPreTaxDailyReturns": -0.0005529,
    "strCumulatedPreTaxDailyReturns": 0.0785286
  },
  {
    "dataDate": "2024-01-12",
    "accPreTaxDailyReturns": 0.0022808,
    "accCumulatedPreTaxDailyReturns": 0.0474596,
    "strPreTaxDailyReturns": 0.0008296,
    "strCumulatedPreTaxDailyReturns": 0.0794233
  },
  {
    "dataDate": "2024-01-16",
    "accPreTaxDailyReturns": -0.0048446,
    "accCumulatedPreTaxDailyReturns": 0.042385,
    "strPreTaxDailyReturns": -0.0038029,
    "strCumulatedPreTaxDailyReturns": 0.0753184
  },
  {
    "dataDate": "2024-01-17",
    "accPreTaxDailyReturns": -0.0060428,
    "accCumulatedPreTaxDailyReturns": 0.0360861,
    "strPreTaxDailyReturns": -0.0055708,
    "strCumulatedPreTaxDailyReturns": 0.069328
  },
  {
    "dataDate": "2024-01-18",
    "accPreTaxDailyReturns": 0.0089015,
    "accCumulatedPreTaxDailyReturns": 0.0453088,
    "strPreTaxDailyReturns": 0.0088167,
    "strCumulatedPreTaxDailyReturns": 0.0787559
  },
  {
    "dataDate": "2024-01-19",
    "accPreTaxDailyReturns": 0.0138894,
    "accCumulatedPreTaxDailyReturns": 0.0598275,
    "strPreTaxDailyReturns": 0.012519,
    "strCumulatedPreTaxDailyReturns": 0.0922609
  },
  {
    "dataDate": "2024-01-22",
    "accPreTaxDailyReturns": 0.0029737,
    "accCumulatedPreTaxDailyReturns": 0.0629792,
    "strPreTaxDailyReturns": 0.0023969,
    "strCumulatedPreTaxDailyReturns": 0.0948789
  },
  {
    "dataDate": "2024-01-23",
    "accPreTaxDailyReturns": 0.0039715,
    "accCumulatedPreTaxDailyReturns": 0.0672008,
    "strPreTaxDailyReturns": 0.0029176,
    "strCumulatedPreTaxDailyReturns": 0.0980733
  },
  {
    "dataDate": "2024-01-24",
    "accPreTaxDailyReturns": 0.0014994,
    "accCumulatedPreTaxDailyReturns": 0.0688009,
    "strPreTaxDailyReturns": 0.0007375,
    "strCumulatedPreTaxDailyReturns": 0.0988832
  },
  {
    "dataDate": "2024-01-25",
    "accPreTaxDailyReturns": 0.0057539,
    "accCumulatedPreTaxDailyReturns": 0.0749507,
    "strPreTaxDailyReturns": 0.0049526,
    "strCumulatedPreTaxDailyReturns": 0.1043255
  },
  {
    "dataDate": "2024-01-26",
    "accPreTaxDailyReturns": -0.0008512,
    "accCumulatedPreTaxDailyReturns": 0.0740357,
    "strPreTaxDailyReturns": -0.0006246,
    "strCumulatedPreTaxDailyReturns": 0.1036357
  },
  {
    "dataDate": "2024-01-29",
    "accPreTaxDailyReturns": 0.0079679,
    "accCumulatedPreTaxDailyReturns": 0.0825935,
    "strPreTaxDailyReturns": 0.0080034,
    "strCumulatedPreTaxDailyReturns": 0.1124686
  },
  {
    "dataDate": "2024-01-30",
    "accPreTaxDailyReturns": -0.0001767,
    "accCumulatedPreTaxDailyReturns": 0.0824022,
    "strPreTaxDailyReturns": -0.0007668,
    "strCumulatedPreTaxDailyReturns": 0.1116155
  },
  {
    "dataDate": "2024-01-31",
    "accPreTaxDailyReturns": -0.0164392,
    "accCumulatedPreTaxDailyReturns": 0.0646084,
    "strPreTaxDailyReturns": -0.0161388,
    "strCumulatedPreTaxDailyReturns": 0.0936754
  },
  {
    "dataDate": "2024-02-01",
    "accPreTaxDailyReturns": 0.0098372,
    "accCumulatedPreTaxDailyReturns": 0.0750812,
    "strPreTaxDailyReturns": 0.0124976,
    "strCumulatedPreTaxDailyReturns": 0.1073437
  },
  {
    "dataDate": "2024-02-02",
    "accPreTaxDailyReturns": 0.0094087,
    "accCumulatedPreTaxDailyReturns": 0.0851963,
    "strPreTaxDailyReturns": 0.010969,
    "strCumulatedPreTaxDailyReturns": 0.1194902
  },
  {
    "dataDate": "2024-02-05",
    "accPreTaxDailyReturns": -0.001394,
    "accCumulatedPreTaxDailyReturns": 0.0836835,
    "strPreTaxDailyReturns": -0.0034985,
    "strCumulatedPreTaxDailyReturns": 0.1155736
  },
  {
    "dataDate": "2024-02-06",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.0836835,
    "strPreTaxDailyReturns": 0.0026809,
    "strCumulatedPreTaxDailyReturns": 0.1185644
  },
  {
    "dataDate": "2024-02-07",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.0836835,
    "strPreTaxDailyReturns": 0.0084656,
    "strCumulatedPreTaxDailyReturns": 0.1280337
  },
  {
    "dataDate": "2024-02-08",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.0836835,
    "strPreTaxDailyReturns": 0.0009203,
    "strCumulatedPreTaxDailyReturns": 0.1290718
  },
  {
    "dataDate": "2024-02-09",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.0836835,
    "strPreTaxDailyReturns": 0.0060386,
    "strCumulatedPreTaxDailyReturns": 0.1358898
  },
  {
    "dataDate": "2024-02-12",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.0836835,
    "strPreTaxDailyReturns": -0.0011933,
    "strCumulatedPreTaxDailyReturns": 0.1345344
  },
  {
    "dataDate": "2024-02-13",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.0836835,
    "strPreTaxDailyReturns": -0.0135641,
    "strCumulatedPreTaxDailyReturns": 0.1191454
  },
  {
    "dataDate": "2024-02-14",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.0836835,
    "strPreTaxDailyReturns": 0.0101741,
    "strCumulatedPreTaxDailyReturns": 0.1305317
  },
  {
    "dataDate": "2024-02-15",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.0836835,
    "strPreTaxDailyReturns": 0.0057756,
    "strCumulatedPreTaxDailyReturns": 0.1370612
  },
  {
    "dataDate": "2024-02-16",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.0836835,
    "strPreTaxDailyReturns": -0.0046697,
    "strCumulatedPreTaxDailyReturns": 0.1317515
  },
  {
    "dataDate": "2024-02-20",
    "accPreTaxDailyReturns": -0.006365,
    "accCumulatedPreTaxDailyReturns": 0.0767859,
    "strPreTaxDailyReturns": -0.0062667,
    "strCumulatedPreTaxDailyReturns": 0.1246591
  },
  {
    "dataDate": "2024-02-21",
    "accPreTaxDailyReturns": 0.0026142,
    "accCumulatedPreTaxDailyReturns": 0.0796008,
    "strPreTaxDailyReturns": 0.0008404,
    "strCumulatedPreTaxDailyReturns": 0.1256043
  },
  {
    "dataDate": "2024-02-22",
    "accPreTaxDailyReturns": 0.0225483,
    "accCumulatedPreTaxDailyReturns": 0.103944,
    "strPreTaxDailyReturns": 0.0212752,
    "strCumulatedPreTaxDailyReturns": 0.1495518
  },
  {
    "dataDate": "2024-02-23",
    "accPreTaxDailyReturns": 0.006488,
    "accCumulatedPreTaxDailyReturns": 0.1111064,
    "strPreTaxDailyReturns": 0.0003998,
    "strCumulatedPreTaxDailyReturns": 0.1500113
  },
  {
    "dataDate": "2024-02-26",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1111064,
    "strPreTaxDailyReturns": 0,
    "strCumulatedPreTaxDailyReturns": 0.1500113
  },
  {
    "dataDate": "2024-02-27",
    "accPreTaxDailyReturns": -0.0084668,
    "accCumulatedPreTaxDailyReturns": 0.1016988,
    "strPreTaxDailyReturns": 0.0017336,
    "strCumulatedPreTaxDailyReturns": 0.152005
  },
  {
    "dataDate": "2024-02-28",
    "accPreTaxDailyReturns": -0.0025677,
    "accCumulatedPreTaxDailyReturns": 0.09887,
    "strPreTaxDailyReturns": -0.0016558,
    "strCumulatedPreTaxDailyReturns": 0.1500975
  },
  {
    "dataDate": "2024-02-29",
    "accPreTaxDailyReturns": 0.0054579,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0052698,
    "strCumulatedPreTaxDailyReturns": 0.1561583
  },
  {
    "dataDate": "2024-03-01",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0080152,
    "strCumulatedPreTaxDailyReturns": 0.1654251
  },
  {
    "dataDate": "2024-03-04",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0013222,
    "strCumulatedPreTaxDailyReturns": 0.1638842
  },
  {
    "dataDate": "2024-03-05",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0107954,
    "strCumulatedPreTaxDailyReturns": 0.1513196
  },
  {
    "dataDate": "2024-03-06",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0054617,
    "strCumulatedPreTaxDailyReturns": 0.1576078
  },
  {
    "dataDate": "2024-04-12",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0145294,
    "strCumulatedPreTaxDailyReturns": 0.1407884
  },
  {
    "dataDate": "2024-04-15",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0124284,
    "strCumulatedPreTaxDailyReturns": 0.1266103
  },
  {
    "dataDate": "2024-04-16",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0019544,
    "strCumulatedPreTaxDailyReturns": 0.1244084
  },
  {
    "dataDate": "2024-04-17",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0058797,
    "strCumulatedPreTaxDailyReturns": 0.1177972
  },
  {
    "dataDate": "2024-04-18",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.002173,
    "strCumulatedPreTaxDailyReturns": 0.1153683
  },
  {
    "dataDate": "2024-04-19",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0090014,
    "strCumulatedPreTaxDailyReturns": 0.1053284
  },
  {
    "dataDate": "2024-04-22",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0088007,
    "strCumulatedPreTaxDailyReturns": 0.115056
  },
  {
    "dataDate": "2024-04-23",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0122871,
    "strCumulatedPreTaxDailyReturns": 0.1287569
  },
  {
    "dataDate": "2024-04-24",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0000555,
    "strCumulatedPreTaxDailyReturns": 0.1288195
  },
  {
    "dataDate": "2024-04-25",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0044649,
    "strCumulatedPreTaxDailyReturns": 0.1237794
  },
  {
    "dataDate": "2024-04-26",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0103366,
    "strCumulatedPreTaxDailyReturns": 0.1353955
  },
  {
    "dataDate": "2024-04-29",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0030245,
    "strCumulatedPreTaxDailyReturns": 0.1388295
  },
  {
    "dataDate": "2024-04-30",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0159159,
    "strCumulatedPreTaxDailyReturns": 0.120704
  },
  {
    "dataDate": "2024-05-01",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0031465,
    "strCumulatedPreTaxDailyReturns": 0.1171777
  },
  {
    "dataDate": "2024-05-02",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0094948,
    "strCumulatedPreTaxDailyReturns": 0.1277851
  },
  {
    "dataDate": "2024-05-03",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0122932,
    "strCumulatedPreTaxDailyReturns": 0.1416492
  },
  {
    "dataDate": "2024-05-06",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0105973,
    "strCumulatedPreTaxDailyReturns": 0.1537476
  },
  {
    "dataDate": "2024-05-07",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0011321,
    "strCumulatedPreTaxDailyReturns": 0.1550537
  },
  {
    "dataDate": "2024-05-08",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0001544,
    "strCumulatedPreTaxDailyReturns": 0.1548754
  },
  {
    "dataDate": "2024-05-09",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0050719,
    "strCumulatedPreTaxDailyReturns": 0.1607328
  },
  {
    "dataDate": "2024-05-10",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0017468,
    "strCumulatedPreTaxDailyReturns": 0.1627604
  },
  {
    "dataDate": "2024-05-13",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0003199,
    "strCumulatedPreTaxDailyReturns": 0.1623884
  },
  {
    "dataDate": "2024-05-14",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0050134,
    "strCumulatedPreTaxDailyReturns": 0.1682159
  },
  {
    "dataDate": "2024-05-15",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0122948,
    "strCumulatedPreTaxDailyReturns": 0.1825789
  },
  {
    "dataDate": "2024-05-16",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0018298,
    "strCumulatedPreTaxDailyReturns": 0.180415
  },
  {
    "dataDate": "2024-05-17",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.001308,
    "strCumulatedPreTaxDailyReturns": 0.181959
  },
  {
    "dataDate": "2024-05-20",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0011493,
    "strCumulatedPreTaxDailyReturns": 0.1833174
  },
  {
    "dataDate": "2024-05-21",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0023956,
    "strCumulatedPreTaxDailyReturns": 0.1861522
  },
  {
    "dataDate": "2024-05-22",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.002672,
    "strCumulatedPreTaxDailyReturns": 0.1829828
  },
  {
    "dataDate": "2024-05-23",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0075284,
    "strCumulatedPreTaxDailyReturns": 0.1740768
  },
  {
    "dataDate": "2024-05-24",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0068694,
    "strCumulatedPreTaxDailyReturns": 0.182142
  },
  {
    "dataDate": "2024-05-28",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0000584,
    "strCumulatedPreTaxDailyReturns": 0.1822111
  },
  {
    "dataDate": "2024-05-29",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0072188,
    "strCumulatedPreTaxDailyReturns": 0.1736769
  },
  {
    "dataDate": "2024-05-30",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0061818,
    "strCumulatedPreTaxDailyReturns": 0.1664215
  },
  {
    "dataDate": "2024-05-31",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0072563,
    "strCumulatedPreTaxDailyReturns": 0.1748854
  },
  {
    "dataDate": "2024-06-03",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0012425,
    "strCumulatedPreTaxDailyReturns": 0.1763452
  },
  {
    "dataDate": "2024-06-04",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0017023,
    "strCumulatedPreTaxDailyReturns": 0.1783477
  },
  {
    "dataDate": "2024-06-05",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0120715,
    "strCumulatedPreTaxDailyReturns": 0.1925721
  },
  {
    "dataDate": "2024-06-06",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0001287,
    "strCumulatedPreTaxDailyReturns": 0.1924186
  },
  {
    "dataDate": "2024-06-07",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0009744,
    "strCumulatedPreTaxDailyReturns": 0.1912567
  },
  {
    "dataDate": "2024-06-10",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0030025,
    "strCumulatedPreTaxDailyReturns": 0.1948335
  },
  {
    "dataDate": "2024-06-12",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0084603,
    "strCumulatedPreTaxDailyReturns": 0.2049421
  },
  {
    "dataDate": "2024-06-13",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0021625,
    "strCumulatedPreTaxDailyReturns": 0.2075478
  },
  {
    "dataDate": "2024-06-14",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0000136,
    "strCumulatedPreTaxDailyReturns": 0.2075642
  },
  {
    "dataDate": "2024-06-17",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0079106,
    "strCumulatedPreTaxDailyReturns": 0.2171168
  },
  {
    "dataDate": "2024-06-18",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.002435,
    "strCumulatedPreTaxDailyReturns": 0.2200805
  },
  {
    "dataDate": "2024-06-20",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0024502,
    "strCumulatedPreTaxDailyReturns": 0.217091
  },
  {
    "dataDate": "2024-06-21",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.001588,
    "strCumulatedPreTaxDailyReturns": 0.2151583
  },
  {
    "dataDate": "2024-06-24",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0029038,
    "strCumulatedPreTaxDailyReturns": 0.2116297
  },
  {
    "dataDate": "2024-06-25",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0041242,
    "strCumulatedPreTaxDailyReturns": 0.2166267
  },
  {
    "dataDate": "2024-06-26",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.001559,
    "strCumulatedPreTaxDailyReturns": 0.2185234
  },
  {
    "dataDate": "2024-06-27",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0012822,
    "strCumulatedPreTaxDailyReturns": 0.2200858
  },
  {
    "dataDate": "2024-06-28",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0040073,
    "strCumulatedPreTaxDailyReturns": 0.2151966
  },
  {
    "dataDate": "2024-07-01",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0030868,
    "strCumulatedPreTaxDailyReturns": 0.2189476
  },
  {
    "dataDate": "2024-07-02",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0061738,
    "strCumulatedPreTaxDailyReturns": 0.2264732
  },
  {
    "dataDate": "2024-07-03",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0050001,
    "strCumulatedPreTaxDailyReturns": 0.2326057
  },
  {
    "dataDate": "2024-07-05",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.005884,
    "strCumulatedPreTaxDailyReturns": 0.2398583
  },
  {
    "dataDate": "2024-07-08",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0008927,
    "strCumulatedPreTaxDailyReturns": 0.2409651
  },
  {
    "dataDate": "2024-07-09",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0005907,
    "strCumulatedPreTaxDailyReturns": 0.2416982
  },
  {
    "dataDate": "2024-07-10",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0100677,
    "strCumulatedPreTaxDailyReturns": 0.2541992
  },
  {
    "dataDate": "2024-07-11",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0087908,
    "strCumulatedPreTaxDailyReturns": 0.2431738
  },
  {
    "dataDate": "2024-07-12",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0054293,
    "strCumulatedPreTaxDailyReturns": 0.2499234
  },
  {
    "dataDate": "2024-07-15",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.003135,
    "strCumulatedPreTaxDailyReturns": 0.2538419
  },
  {
    "dataDate": "2024-07-16",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0062232,
    "strCumulatedPreTaxDailyReturns": 0.2616448
  },
  {
    "dataDate": "2024-07-17",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0143114,
    "strCumulatedPreTaxDailyReturns": 0.2435889
  },
  {
    "dataDate": "2024-07-18",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0080509,
    "strCumulatedPreTaxDailyReturns": 0.2335769
  },
  {
    "dataDate": "2024-07-19",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0066454,
    "strCumulatedPreTaxDailyReturns": 0.2253793
  },
  {
    "dataDate": "2024-07-22",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0107051,
    "strCumulatedPreTaxDailyReturns": 0.2384971
  },
  {
    "dataDate": "2024-07-23",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0013364,
    "strCumulatedPreTaxDailyReturns": 0.2368419
  },
  {
    "dataDate": "2024-07-25",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0052266,
    "strCumulatedPreTaxDailyReturns": 0.2303775
  },
  {
    "dataDate": "2024-07-26",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0109703,
    "strCumulatedPreTaxDailyReturns": 0.2438751
  },
  {
    "dataDate": "2024-07-29",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0007405,
    "strCumulatedPreTaxDailyReturns": 0.2447962
  },
  {
    "dataDate": "2024-07-30",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0050696,
    "strCumulatedPreTaxDailyReturns": 0.2384855
  },
  {
    "dataDate": "2024-07-31",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0157953,
    "strCumulatedPreTaxDailyReturns": 0.2580478
  },
  {
    "dataDate": "2024-08-01",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0137747,
    "strCumulatedPreTaxDailyReturns": 0.2407186
  },
  {
    "dataDate": "2024-08-02",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.018677,
    "strCumulatedPreTaxDailyReturns": 0.2175457
  },
  {
    "dataDate": "2024-08-05",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0299123,
    "strCumulatedPreTaxDailyReturns": 0.1811261
  },
  {
    "dataDate": "2024-08-06",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0103442,
    "strCumulatedPreTaxDailyReturns": 0.1933439
  },
  {
    "dataDate": "2024-08-07",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0075655,
    "strCumulatedPreTaxDailyReturns": 0.1843156
  },
  {
    "dataDate": "2024-08-08",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0233168,
    "strCumulatedPreTaxDailyReturns": 0.2119301
  },
  {
    "dataDate": "2024-08-09",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0050766,
    "strCumulatedPreTaxDailyReturns": 0.2180826
  },
  {
    "dataDate": "2024-08-12",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0000682,
    "strCumulatedPreTaxDailyReturns": 0.2181656
  },
  {
    "dataDate": "2024-08-13",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0169088,
    "strCumulatedPreTaxDailyReturns": 0.2387634
  },
  {
    "dataDate": "2024-08-14",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0040241,
    "strCumulatedPreTaxDailyReturns": 0.2437483
  },
  {
    "dataDate": "2024-08-15",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0164898,
    "strCumulatedPreTaxDailyReturns": 0.2642574
  },
  {
    "dataDate": "2024-08-16",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0021874,
    "strCumulatedPreTaxDailyReturns": 0.2670229
  },
  {
    "dataDate": "2024-08-19",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0097237,
    "strCumulatedPreTaxDailyReturns": 0.279343
  },
  {
    "dataDate": "2024-08-20",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.001909,
    "strCumulatedPreTaxDailyReturns": 0.2769007
  },
  {
    "dataDate": "2024-08-21",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0044279,
    "strCumulatedPreTaxDailyReturns": 0.2825547
  },
  {
    "dataDate": "2024-08-22",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0090756,
    "strCumulatedPreTaxDailyReturns": 0.2709148
  },
  {
    "dataDate": "2024-08-23",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0114666,
    "strCumulatedPreTaxDailyReturns": 0.2854878
  },
  {
    "dataDate": "2024-08-26",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0031673,
    "strCumulatedPreTaxDailyReturns": 0.2814163
  },
  {
    "dataDate": "2024-08-27",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0016407,
    "strCumulatedPreTaxDailyReturns": 0.2835187
  },
  {
    "dataDate": "2024-08-28",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": -0.0061247,
    "strCumulatedPreTaxDailyReturns": 0.2756576
  },
  {
    "dataDate": "2024-08-29",
    "accPreTaxDailyReturns": 0,
    "accCumulatedPreTaxDailyReturns": 0.1048675,
    "strPreTaxDailyReturns": 0.0003143,
    "strCumulatedPreTaxDailyReturns": 0.2760585
  }
]

const allocationData = {
  data: {
    "fieldLabels": {
      "portfolio": "Portfolio",
      "scenario1": "Original UMA",
      "strategy": "Strategy"
    },
    "marketCapAllocationData": [
      {
        "marketCapName": "Micro",
        "portfolio": 0.001,
        "scenario1": 0.0319,
        "strategy": "NA"
      },
      {
        "marketCapName": "Small",
        "portfolio": 0.0347,
        "scenario1": "NA",
        "strategy": "NA"
      },
      {
        "marketCapName": "Mid",
        "portfolio": 0.0199,
        "scenario1": 0.0199,
        "strategy": 0.0499
      },
      {
        "marketCapName": "Large",
        "portfolio": 0.0538,
        "scenario1": 0.6159,
        "strategy": 0.6039
      },
      {
        "marketCapName": "Mega",
        "portfolio": 0.9115,
        "scenario1": 0.9082,
        "strategy": 0.3163
      }
    ],
    "factorAllocationData": [
      {
        "factorName": "Dividend Yield",
        "portfolio": -0.3292,
        "scenario1": -0.4146,
        "strategy": -0.6033
      },
      {
        "factorName": "Momentum",
        "portfolio": -0.2671,
        "scenario1": -0.3735,
        "strategy": -0.1501
      },
      {
        "factorName": "Profitability",
        "portfolio": 0.7509,
        "scenario1": 0.733,
        "strategy": 0.3691
      },
      {
        "factorName": "Size",
        "portfolio": 0.7144,
        "scenario1": 0.7133,
        "strategy": 0.0163
      },
      {
        "factorName": "Value",
        "portfolio": -0.4102,
        "scenario1": -0.3456,
        "strategy": -0.4449
      },
      {
        "factorName": "Growth",
        "portfolio": -0.3359,
        "scenario1": 0.051,
        "strategy": 0.097
      },
      {
        "factorName": "Volatility",
        "portfolio": -0.0013,
        "scenario1": 0.0445,
        "strategy": -0.2215
      }
    ],
    "sectorWiseAllocationData": [
      {
        "assetClass": "INDS",
        "assetClassDesc": "Industrials",
        "portfolio": 0,
        "scenario1": 0.0199,
        "strategy": 0.1563
      },
      {
        "assetClass": "CDIS",
        "assetClassDesc": "Consumer Discretionary",
        "portfolio": 0,
        "scenario1": 0.0592,
        "strategy": 0.0408
      },
      {
        "assetClass": "CSTP",
        "assetClassDesc": "Consumer Staples",
        "portfolio": 0,
        "scenario1": 0,
        "strategy": 0.067
      },
      {
        "assetClass": "HLTH",
        "assetClassDesc": "Health Care",
        "portfolio": 0,
        "scenario1": 0.3234,
        "strategy": 0.3281
      },
      {
        "assetClass": "FINC",
        "assetClassDesc": "Financials",
        "portfolio": 0,
        "scenario1": 0.0739,
        "strategy": 0.0653
      },
      {
        "assetClass": "TECH",
        "assetClassDesc": "Information Technology",
        "portfolio": 0.9152,
        "scenario1": 0.9401,
        "strategy": 0.3125
      },
      {
        "assetClass": "COMM",
        "assetClassDesc": "Communication Services",
        "portfolio": 0.0307,
        "scenario1": 0,
        "strategy": 0
      },
      {
        "assetClass": "UTIL",
        "assetClassDesc": "Utilities",
        "portfolio": 0.0541,
        "scenario1": 0,
        "strategy": 0
      },
      {
        "assetClass": "UNCG",
        "assetClassDesc": "Uncategorized",
        "portfolio": 0,
        "scenario1": 0.0401,
        "strategy": 0.03
      }
    ],
  }
}

const tradeListData = [
  {
    securityName: 'AIA Group Limited - ADR',
    localSymbol: 'AIGY',
    category: 'SELL',
    sourceAccountName: 'Callan GST Irrev Tr',
    destinationAccountName: '',
    tradeQuantity: 10,
    tradeValue: 2771.21
  },
  {
    securityName: 'AIA Group Limited - ADR',
    localSymbol: 'AIGY',
    category: 'BUY',
    sourceAccountName: '',
    destinationAccountName: 'H+M jt Acct',
    tradeQuantity: 5,
    tradeValue: 2771.21
  },
  {
    securityName: 'Apple Inc',
    localSymbol: 'AAPL',
    category: 'TRANSFER',
    sourceAccountName: 'Callan GST Irrev Tr',
    destinationAccountName: 'H+M jt Acct',
    tradeQuantity: 10,
    tradeValue: 2771.21
  },
  {
    securityName: 'Abbvie Inc',
    localSymbol: 'ABV',
    category: 'TRANSFER',
    sourceAccountName: 'Trust FBO',
    destinationAccountName: 'Callan GST Irrev Tr',
    tradeQuantity: 5,
    tradeValue: 2771.21
  },
  {
    securityName: 'Basf SE - ADR',
    localSymbol: 'BADR',
    category: 'SELL',
    sourceAccountName: 'Trust FBO',
    destinationAccountName: '',
    tradeQuantity: 20,
    tradeValue: 2771.21
  }
]

const taxlotData = [
  {
    "instrId": "AAON-XNAS",
    "localSymbol": "AAON",
    "instrumentName": "AAON INC",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 3883.4400939941406,
    "aggTaxlotId": "0e43eb39-7016-11ef-a17f-0260bab84379",
    "shares": 44,
    "weight": 1.2269357372200684,
    "orgPurchasePrice": 71.7719,
    "company": "AAON INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "H+M jt Acct",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 88.26,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 0,
    "rgl": 0
  },
  {
    "instrId": "AAON-XNAS",
    "localSymbol": "AAON",
    "instrumentName": "AAON INC",
    "purchaseDate": "2024-07-17T00:00:00.000Z",
    "marketValue": 88.26000213623047,
    "aggTaxlotId": "0e44698d-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.02788490311863792,
    "orgPurchasePrice": 91.3998,
    "company": "AAON INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "H+M jt Acct",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 88.26,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 1,
    "rgl": 2
  },
  {
    "instrId": "AAPL-XNAS",
    "localSymbol": "AAPL",
    "instrumentName": "APPLE INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 6383.190017700195,
    "aggTaxlotId": "0e449602-7016-11ef-a17f-0260bab84379",
    "shares": 29,
    "weight": 2.0167078056115337,
    "orgPurchasePrice": 184.57,
    "company": "APPLE INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 220.11,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 2,
    "rgl": 4
  },
  {
    "instrId": "AAPL-XNAS",
    "localSymbol": "AAPL",
    "instrumentName": "APPLE INC",
    "purchaseDate": "2024-03-19T00:00:00.000Z",
    "marketValue": 2421.210006713867,
    "aggTaxlotId": "0e449904-7016-11ef-a17f-0260bab84379",
    "shares": 11,
    "weight": 0.7649581331629954,
    "orgPurchasePrice": 175.6193,
    "company": "APPLE INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 220.11,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 3,
    "rgl": 6
  },
  {
    "instrId": "AAPL-XNAS",
    "localSymbol": "AAPL",
    "instrumentName": "APPLE INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 5722.860015869141,
    "aggTaxlotId": "0e449ade-7016-11ef-a17f-0260bab84379",
    "shares": 26,
    "weight": 1.808082860203444,
    "orgPurchasePrice": 195.655,
    "company": "APPLE INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Rev Tr",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 220.11,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 4,
    "rgl": 8
  },
  {
    "instrId": "ABNB-XNAS",
    "localSymbol": "ABNB",
    "instrumentName": "AIRBNB INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 1841.9200439453125,
    "aggTaxlotId": "0e449c7f-7016-11ef-a17f-0260bab84379",
    "shares": 16,
    "weight": 0.5819370126279261,
    "orgPurchasePrice": 138.99,
    "company": "AIRBNB A",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 115.12,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 5,
    "rgl": 10
  },
  {
    "instrId": "ABNB-XNAS",
    "localSymbol": "ABNB",
    "instrumentName": "AIRBNB INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 920.9600219726562,
    "aggTaxlotId": "0e449e52-7016-11ef-a17f-0260bab84379",
    "shares": 8,
    "weight": 0.29096850631396304,
    "orgPurchasePrice": 145.68,
    "company": "AIRBNB A",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Rev Tr",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 115.12,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 6,
    "rgl": 12
  },
  {
    "instrId": "ABNB-XNAS",
    "localSymbol": "ABNB",
    "instrumentName": "AIRBNB INC",
    "purchaseDate": "2024-07-01T00:00:00.000Z",
    "marketValue": 230.2400054931641,
    "aggTaxlotId": "0e449fd6-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.07274212657849076,
    "orgPurchasePrice": 150.345,
    "company": "AIRBNB A",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Rev Tr",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 115.12,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 7,
    "rgl": 14
  },
  {
    "instrId": "ACN-XNYS",
    "localSymbol": "ACN",
    "instrumentName": "ACCENTURE PLC IRELAND",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 3129.300109863281,
    "aggTaxlotId": "0e44a167-7016-11ef-a17f-0260bab84379",
    "shares": 9,
    "weight": 0.98867242556819,
    "orgPurchasePrice": 342.2434,
    "company": "ACCENTURE PLC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 347.7,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 8,
    "rgl": 16
  },
  {
    "instrId": "ACN-XNYS",
    "localSymbol": "ACN",
    "instrumentName": "ACCENTURE PLC IRELAND",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 1390.800048828125,
    "aggTaxlotId": "0e44a32f-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.4394099669191955,
    "orgPurchasePrice": 308.435,
    "company": "ACCENTURE PLC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 347.7,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 9,
    "rgl": 18
  },
  {
    "instrId": "ADBE-XNAS",
    "localSymbol": "ADBE",
    "instrumentName": "ADOBE INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1148.9599609375,
    "aggTaxlotId": "0e44a4c9-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.3630029053079348,
    "orgPurchasePrice": 455.25,
    "company": "ADOBE INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Rev Tr",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 574.48,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 10,
    "rgl": 20
  },
  {
    "instrId": "ADDYY-OTCM",
    "localSymbol": "ADDYY",
    "instrumentName": "ADIDAS AG ADR",
    "purchaseDate": "2024-07-31T00:00:00.000Z",
    "marketValue": 232.2600250244141,
    "aggTaxlotId": "0e44a782-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.07338033241990599,
    "orgPurchasePrice": 125.515,
    "company": "ADIDAS AG",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Rev Tr",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 116.13,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 11,
    "rgl": 22
  },
  {
    "instrId": "ADDYY-OTCM",
    "localSymbol": "ADDYY",
    "instrumentName": "ADIDAS AG ADR",
    "purchaseDate": "2024-08-16T00:00:00.000Z",
    "marketValue": 232.2600250244141,
    "aggTaxlotId": "0e44a945-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.07338033241990599,
    "orgPurchasePrice": 119.565,
    "company": "ADIDAS AG",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Rev Tr",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 116.13,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 12,
    "rgl": 24
  },
  {
    "instrId": "ADDYY-OTCM",
    "localSymbol": "ADDYY",
    "instrumentName": "ADIDAS AG ADR",
    "purchaseDate": "2024-09-09T00:00:00.000Z",
    "marketValue": 232.2600250244141,
    "aggTaxlotId": "0e44aaf1-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.07338033241990599,
    "orgPurchasePrice": 118.535,
    "company": "ADIDAS AG",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Rev Tr",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 116.13,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 13,
    "rgl": 26
  },
  {
    "instrId": "ADI-XNAS",
    "localSymbol": "ADI",
    "instrumentName": "ANALOG DEVICES INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 874.8800048828125,
    "aggTaxlotId": "0e44ac82-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.2764099658522015,
    "orgPurchasePrice": 235.8,
    "company": "ANALOG DEVICES INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Rev Tr",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 218.72,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 14,
    "rgl": 28
  },
  {
    "instrId": "AIN-XNYS",
    "localSymbol": "AIN",
    "instrumentName": "ALBANY INTL CORP",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1196.5800170898438,
    "aggTaxlotId": "0e44ae72-7016-11ef-a17f-0260bab84379",
    "shares": 14,
    "weight": 0.3780480063749233,
    "orgPurchasePrice": 87.785,
    "company": "ALBANY INTERNATIONAL CORP.",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Trst FBO Elizabeth",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 85.47,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 15,
    "rgl": 30
  },
  {
    "instrId": "AMD-XNAS",
    "localSymbol": "AMD",
    "instrumentName": "ADVANCED MICRO DEVICES INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1285.5599670410156,
    "aggTaxlotId": "0e44b059-7016-11ef-a17f-0260bab84379",
    "shares": 9,
    "weight": 0.4061603701165411,
    "orgPurchasePrice": 165.3899,
    "company": "ADVANCED MICRO DEVICES INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 142.84,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 16,
    "rgl": 32
  },
  {
    "instrId": "AMZN-XNAS",
    "localSymbol": "AMZN",
    "instrumentName": "AMAZON COM INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 7541.100128173828,
    "aggTaxlotId": "0e44b266-7016-11ef-a17f-0260bab84379",
    "shares": 42,
    "weight": 2.3825384250218,
    "orgPurchasePrice": 181.3,
    "company": "AMAZON.COM INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 179.55,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 17,
    "rgl": 34
  },
  {
    "instrId": "ANET-XNYS",
    "localSymbol": "ANET",
    "instrumentName": "ARISTA NETWORKS INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 1307,
    "aggTaxlotId": "0e44b448-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.412934143371145,
    "orgPurchasePrice": 246.58,
    "company": "ARISTA NETWORKS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 326.75,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 18,
    "rgl": 36
  },
  {
    "instrId": "APO-XNYS",
    "localSymbol": "APO",
    "instrumentName": "APOLLO GLOBAL MGMT INC",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 640.4399871826172,
    "aggTaxlotId": "0e44b603-7016-11ef-a17f-0260bab84379",
    "shares": 6,
    "weight": 0.20234088560664204,
    "orgPurchasePrice": 118.08,
    "company": "APOLLO GLOBAL MANAGEMENT INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 106.74,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 19,
    "rgl": 38
  },
  {
    "instrId": "APP-XNAS",
    "localSymbol": "APP",
    "instrumentName": "APPLOVIN CORP",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 862.3000335693359,
    "aggTaxlotId": "0e44b7e2-7016-11ef-a17f-0260bab84379",
    "shares": 10,
    "weight": 0.2724354442929329,
    "orgPurchasePrice": 79.8488,
    "company": "APPLOVIN CORP COM CL A",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 86.23,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 20,
    "rgl": 40
  },
  {
    "instrId": "ASML-XNAS",
    "localSymbol": "ASML",
    "instrumentName": "ASML HOLDING N V ADR",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1502.760009765625,
    "aggTaxlotId": "0e44b9b5-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.4747826452371705,
    "orgPurchasePrice": 1037.455,
    "company": "ASML HOLDING NV",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 751.38,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 21,
    "rgl": 42
  },
  {
    "instrId": "ATDRY-OTCM",
    "localSymbol": "ATDRY",
    "instrumentName": "AUTO TRADER GROUP PLC ADR",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 3854.4598960876474,
    "aggTaxlotId": "0e44bb96-7016-11ef-a17f-0260bab84379",
    "shares": 1362,
    "weight": 1.2177797210018249,
    "orgPurchasePrice": 2.625,
    "company": "AUTO TRADER GROUP PLC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "H+M jt Acct",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 2.83,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 22,
    "rgl": 44
  },
  {
    "instrId": "AVGO-XNAS",
    "localSymbol": "AVGO",
    "instrumentName": "BROADCOM LTD",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 4446.300201416016,
    "aggTaxlotId": "0e44bd75-7016-11ef-a17f-0260bab84379",
    "shares": 30,
    "weight": 1.4047660021749595,
    "orgPurchasePrice": 1405.8878,
    "company": "BROADCOM INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 148.21,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 23,
    "rgl": 46
  },
  {
    "instrId": "AWI-XNYS",
    "localSymbol": "AWI",
    "instrumentName": "ARMSTRONG WORLD INDS INC NEW",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 2069.75,
    "aggTaxlotId": "0e44bf54-7016-11ef-a17f-0260bab84379",
    "shares": 17,
    "weight": 0.6539177071479935,
    "orgPurchasePrice": 115.656,
    "company": "ARMSTRONG WORLD INDUSTRIES INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Trst FBO Elizabeth",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 121.75,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 24,
    "rgl": 48
  },
  {
    "instrId": "AZN-XNAS",
    "localSymbol": "AZN",
    "instrumentName": "ASTRAZENECA PLC ADR",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1136.2400512695312,
    "aggTaxlotId": "0e44c10e-7016-11ef-a17f-0260bab84379",
    "shares": 14,
    "weight": 0.35898417156462875,
    "orgPurchasePrice": 80.335,
    "company": "ASTRAZENECA PLC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 81.16,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 25,
    "rgl": 50
  },
  {
    "instrId": "AZPN-XNAS",
    "localSymbol": "AZPN",
    "instrumentName": "ASPEN TECHNOLOGY INC",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 1137.6499938964844,
    "aggTaxlotId": "0e44c396-7016-11ef-a17f-0260bab84379",
    "shares": 5,
    "weight": 0.359429629445932,
    "orgPurchasePrice": 208.43,
    "company": "ASPEN TECHNOLOGY INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "H+M jt Acct",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 227.53,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 26,
    "rgl": 52
  },
  {
    "instrId": "AZPN-XNAS",
    "localSymbol": "AZPN",
    "instrumentName": "ASPEN TECHNOLOGY INC",
    "purchaseDate": "2024-08-08T00:00:00.000Z",
    "marketValue": 227.52999877929688,
    "aggTaxlotId": "0e44c5ea-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.07188592588918641,
    "orgPurchasePrice": 209.7434,
    "company": "ASPEN TECHNOLOGY INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "H+M jt Acct",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 227.53,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 27,
    "rgl": 54
  },
  {
    "instrId": "AZTA-XNAS",
    "localSymbol": "AZTA",
    "instrumentName": "AZENTA INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1421.0400047302246,
    "aggTaxlotId": "0e44c7b4-7016-11ef-a17f-0260bab84379",
    "shares": 31,
    "weight": 0.4489639916215786,
    "orgPurchasePrice": 52.41,
    "company": "AZENTA INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Trst FBO Elizabeth",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 45.84,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 28,
    "rgl": 56
  },
  {
    "instrId": "BA-XNYS",
    "localSymbol": "BA",
    "instrumentName": "BOEING CO",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 640.280029296875,
    "aggTaxlotId": "0e44c995-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.20229034844327215,
    "orgPurchasePrice": 189.955,
    "company": "BOEING CO",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 160.07,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 29,
    "rgl": 58
  },
  {
    "instrId": "BA-XNYS",
    "localSymbol": "BA",
    "instrumentName": "BOEING CO",
    "purchaseDate": "2024-07-01T00:00:00.000Z",
    "marketValue": 480.21002197265625,
    "aggTaxlotId": "0e44cfa9-7016-11ef-a17f-0260bab84379",
    "shares": 3,
    "weight": 0.15171776133245413,
    "orgPurchasePrice": 187.365,
    "company": "BOEING CO",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 160.07,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 30,
    "rgl": 60
  },
  {
    "instrId": "BBY-XNYS",
    "localSymbol": "BBY",
    "instrumentName": "BEST BUY INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 289.1100082397461,
    "aggTaxlotId": "0e44d1a4-7016-11ef-a17f-0260bab84379",
    "shares": 3,
    "weight": 0.09134154062165588,
    "orgPurchasePrice": 75.0298,
    "company": "BEST BUY CO INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 96.37,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 31,
    "rgl": 62
  },
  {
    "instrId": "BK-XNYS",
    "localSymbol": "BK",
    "instrumentName": "BANK NEW YORK MELLON CORP",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 202.5300064086914,
    "aggTaxlotId": "0e44d41e-7016-11ef-a17f-0260bab84379",
    "shares": 3,
    "weight": 0.06398741752358493,
    "orgPurchasePrice": 52.7684,
    "company": "BANK OF NEW YORK MELLON CORP (THE)",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 67.51,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 32,
    "rgl": 64
  },
  {
    "instrId": "BMI-XNYS",
    "localSymbol": "BMI",
    "instrumentName": "BADGER METER INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 780.760009765625,
    "aggTaxlotId": "0e44d707-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.24667365402526029,
    "orgPurchasePrice": 189.34,
    "company": "BADGER METER INC.",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Trst FBO Elizabeth",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 195.19,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 33,
    "rgl": 66
  },
  {
    "instrId": "BOH-XNYS",
    "localSymbol": "BOH",
    "instrumentName": "BANK HAWAII CORP",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1525.1999816894531,
    "aggTaxlotId": "0e44d959-7016-11ef-a17f-0260bab84379",
    "shares": 24,
    "weight": 0.4818723396393423,
    "orgPurchasePrice": 56.5455,
    "company": "BANK OF HAWAII CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Trst FBO Elizabeth",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 63.55,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 34,
    "rgl": 68
  },
  {
    "instrId": "CAH-XNYS",
    "localSymbol": "CAH",
    "instrumentName": "CARDINAL HEALTH INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 1029.3300247192385,
    "aggTaxlotId": "0e44db7c-7016-11ef-a17f-0260bab84379",
    "shares": 9,
    "weight": 0.3252069716936788,
    "orgPurchasePrice": 102.7545,
    "company": "CARDINAL HEALTH INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 114.37,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 35,
    "rgl": 70
  },
  {
    "instrId": "CAH-XNYS",
    "localSymbol": "CAH",
    "instrumentName": "CARDINAL HEALTH INC",
    "purchaseDate": "2024-03-19T00:00:00.000Z",
    "marketValue": 114.37000274658205,
    "aggTaxlotId": "0e44dd8f-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.03613410796596432,
    "orgPurchasePrice": 112.395,
    "company": "CARDINAL HEALTH INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 114.37,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 36,
    "rgl": 72
  },
  {
    "instrId": "CAKE-XNAS",
    "localSymbol": "CAKE",
    "instrumentName": "CHEESECAKE FACTORY INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1585.7600402832031,
    "aggTaxlotId": "0e44df9a-7016-11ef-a17f-0260bab84379",
    "shares": 44,
    "weight": 0.5010057106553458,
    "orgPurchasePrice": 39.63,
    "company": "CHEESECAKE FACTORY INC (THE)",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Trst FBO Elizabeth",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 36.04,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 37,
    "rgl": 74
  },
  {
    "instrId": "CBRE-XNYS",
    "localSymbol": "CBRE",
    "instrumentName": "CBRE GROUP INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 233.6000061035156,
    "aggTaxlotId": "0e44e1ac-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.07380368661962469,
    "orgPurchasePrice": 86.51,
    "company": "CBRE GROUP INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 116.8,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 38,
    "rgl": 76
  },
  {
    "instrId": "CDNS-XNAS",
    "localSymbol": "CDNS",
    "instrumentName": "CADENCE DESIGN SYSTEM INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1023.4000244140624,
    "aggTaxlotId": "0e44e3c7-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.32333344484118576,
    "orgPurchasePrice": 295.78,
    "company": "CADENCE DESIGN SYSTEMS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 255.85,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 39,
    "rgl": 78
  },
  {
    "instrId": "CDNS-XNAS",
    "localSymbol": "CDNS",
    "instrumentName": "CADENCE DESIGN SYSTEM INC",
    "purchaseDate": "2024-07-01T00:00:00.000Z",
    "marketValue": 255.8500061035156,
    "aggTaxlotId": "0e44e5d6-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.08083336121029644,
    "orgPurchasePrice": 310.425,
    "company": "CADENCE DESIGN SYSTEMS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 255.85,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 40,
    "rgl": 80
  },
  {
    "instrId": "CHH-XNYS",
    "localSymbol": "CHH",
    "instrumentName": "CHOICE HOTELS INTL INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1451.7600402832031,
    "aggTaxlotId": "0e44e7af-7016-11ef-a17f-0260bab84379",
    "shares": 12,
    "weight": 0.45866969289579457,
    "orgPurchasePrice": 112.11,
    "company": "CHOICE HOTELS INTERNATIONAL INC.",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Trst FBO Elizabeth",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 120.98,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 41,
    "rgl": 82
  },
  {
    "instrId": "CNC-XNYS",
    "localSymbol": "CNC",
    "instrumentName": "CENTENE CORP DEL",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 1704,
    "aggTaxlotId": "0e44e9b8-7016-11ef-a17f-0260bab84379",
    "shares": 24,
    "weight": 0.5383624944945916,
    "orgPurchasePrice": 77.71,
    "company": "CENTENE CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 71,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 42,
    "rgl": 84
  },
  {
    "instrId": "COIN-XNAS",
    "localSymbol": "COIN",
    "instrumentName": "COINBASE GLOBAL INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 950.7600402832032,
    "aggTaxlotId": "0e44ebd9-7016-11ef-a17f-0260bab84379",
    "shares": 6,
    "weight": 0.30038353694403963,
    "orgPurchasePrice": 153.42,
    "company": "COINBASE GLOBAL A",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 158.46,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 43,
    "rgl": 86
  },
  {
    "instrId": "ABC-XNYS",
    "localSymbol": "COR",
    "instrumentName": "CENCORA INC.",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 1187.6499938964844,
    "aggTaxlotId": "0e44edf0-7016-11ef-a17f-0260bab84379",
    "shares": 5,
    "weight": 0.37522665099800334,
    "orgPurchasePrice": 212.5904,
    "company": "CENCORA INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 237.53,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 44,
    "rgl": 88
  },
  {
    "instrId": "ABC-XNYS",
    "localSymbol": "COR",
    "instrumentName": "CENCORA INC.",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 237.52999877929688,
    "aggTaxlotId": "0e44eff5-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.07504533019960068,
    "orgPurchasePrice": 239.3773,
    "company": "CENCORA INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 237.53,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 45,
    "rgl": 90
  },
  {
    "instrId": "COST-XNAS",
    "localSymbol": "COST",
    "instrumentName": "COSTCO WHSL CORP NEW",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 8942.899780273438,
    "aggTaxlotId": "0e44f1cd-7016-11ef-a17f-0260bab84379",
    "shares": 10,
    "weight": 2.8254236113398723,
    "orgPurchasePrice": 664.95,
    "company": "COSTCO WHOLESALE CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 894.29,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 46,
    "rgl": 92
  },
  {
    "instrId": "COST-XNAS",
    "localSymbol": "COST",
    "instrumentName": "COSTCO WHSL CORP NEW",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 2682.8699340820312,
    "aggTaxlotId": "0e44f426-7016-11ef-a17f-0260bab84379",
    "shares": 3,
    "weight": 0.8476270834019617,
    "orgPurchasePrice": 832.1548,
    "company": "COSTCO WHOLESALE CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 894.29,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 47,
    "rgl": 94
  },
  {
    "instrId": "CRM-XNYS",
    "localSymbol": "CRM",
    "instrumentName": "SALESFORCE INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 3200.080047607422,
    "aggTaxlotId": "0e44f5e2-7016-11ef-a17f-0260bab84379",
    "shares": 13,
    "weight": 1.011034669608159,
    "orgPurchasePrice": 262.4813,
    "company": "SALESFORCE INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 246.16,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 48,
    "rgl": 96
  },
  {
    "instrId": "CRM-XNYS",
    "localSymbol": "CRM",
    "instrumentName": "SALESFORCE INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 738.4800109863281,
    "aggTaxlotId": "0e44f824-7016-11ef-a17f-0260bab84379",
    "shares": 3,
    "weight": 0.2333156929864982,
    "orgPurchasePrice": 236.44,
    "company": "SALESFORCE INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 246.16,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 49,
    "rgl": 98
  },
  {
    "instrId": "CRWD-XNAS",
    "localSymbol": "CRWD",
    "instrumentName": "CROWDSTRIKE HLDGS INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 743.2200164794922,
    "aggTaxlotId": "0e44fa1b-7016-11ef-a17f-0260bab84379",
    "shares": 3,
    "weight": 0.2348132523651472,
    "orgPurchasePrice": 341.79,
    "company": "CROWDSTRIKE HLDGS INC CL A",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 247.74,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 50,
    "rgl": 100
  },
  {
    "instrId": "CRWD-XNAS",
    "localSymbol": "CRWD",
    "instrumentName": "CROWDSTRIKE HLDGS INC",
    "purchaseDate": "2024-08-16T00:00:00.000Z",
    "marketValue": 247.7400054931641,
    "aggTaxlotId": "0e44fc04-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.07827108412171574,
    "orgPurchasePrice": 261.44,
    "company": "CROWDSTRIKE HLDGS INC CL A",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 247.74,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 51,
    "rgl": 102
  },
  {
    "instrId": "CSWI-XNAS",
    "localSymbol": "CSWI",
    "instrumentName": "CSW INDUSTRIALS INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 952.8599853515624,
    "aggTaxlotId": "0e44fdb9-7016-11ef-a17f-0260bab84379",
    "shares": 3,
    "weight": 0.30104699449410044,
    "orgPurchasePrice": 255.448,
    "company": "CSW INDUSTRIALS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Trst FBO Elizabeth",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 317.62,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 52,
    "rgl": 104
  },
  {
    "instrId": "DAVA-XNYS",
    "localSymbol": "DAVA",
    "instrumentName": "ENDAVA PLC ADR",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 1354.3200302124023,
    "aggTaxlotId": "0e44ffc9-7016-11ef-a17f-0260bab84379",
    "shares": 44,
    "weight": 0.4278844541133448,
    "orgPurchasePrice": 27.44,
    "company": "ENDAVA PLC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "H+M jt Acct",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 30.78,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 53,
    "rgl": 106
  },
  {
    "instrId": "DDOG-XNAS",
    "localSymbol": "DDOG",
    "instrumentName": "DATADOG INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 540.6000137329102,
    "aggTaxlotId": "0e4501b5-7016-11ef-a17f-0260bab84379",
    "shares": 5,
    "weight": 0.17079740135977697,
    "orgPurchasePrice": 110.5,
    "company": "DATADOG INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 108.12,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 54,
    "rgl": 108
  },
  {
    "instrId": "DDOG-XNAS",
    "localSymbol": "DDOG",
    "instrumentName": "DATADOG INC",
    "purchaseDate": "2024-07-01T00:00:00.000Z",
    "marketValue": 108.12000274658205,
    "aggTaxlotId": "0e4503a4-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.034159480271955395,
    "orgPurchasePrice": 131.42,
    "company": "DATADOG INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 108.12,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 55,
    "rgl": 110
  },
  {
    "instrId": "DFH-XNYS",
    "localSymbol": "DFH",
    "instrumentName": "DREAM FINDERS HOMES INC",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 1438.420021057129,
    "aggTaxlotId": "0e450698-7016-11ef-a17f-0260bab84379",
    "shares": 46,
    "weight": 0.4544550414714078,
    "orgPurchasePrice": 27.06,
    "company": "DREAM FINDERS HOMES INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "H+M jt Acct",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 31.27,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 56,
    "rgl": 112
  },
  {
    "instrId": "DIS-XNYS",
    "localSymbol": "DIS",
    "instrumentName": "DISNEY WALT CO",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 972.400016784668,
    "aggTaxlotId": "0e45089c-7016-11ef-a17f-0260bab84379",
    "shares": 11,
    "weight": 0.30722048044763883,
    "orgPurchasePrice": 101.475,
    "company": "WALT DISNEY CO (THE)",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 88.4,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 57,
    "rgl": 114
  },
  {
    "instrId": "DIS-XNYS",
    "localSymbol": "DIS",
    "instrumentName": "DISNEY WALT CO",
    "purchaseDate": "2024-07-31T00:00:00.000Z",
    "marketValue": 265.2000045776367,
    "aggTaxlotId": "0e450a91-7016-11ef-a17f-0260bab84379",
    "shares": 3,
    "weight": 0.08378740375844694,
    "orgPurchasePrice": 94.8399,
    "company": "WALT DISNEY CO (THE)",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 88.4,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 58,
    "rgl": 116
  },
  {
    "instrId": "DVN-XNYS",
    "localSymbol": "DVN",
    "instrumentName": "DEVON ENERGY CORP NEW",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 442.5299949645996,
    "aggTaxlotId": "0e450cf2-7016-11ef-a17f-0260bab84379",
    "shares": 11,
    "weight": 0.1398131173578761,
    "orgPurchasePrice": 44.705,
    "company": "DEVON ENERGY CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 40.23,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 59,
    "rgl": 118
  },
  {
    "instrId": "DXCM-XNAS",
    "localSymbol": "DXCM",
    "instrumentName": "DEXCOM INC",
    "purchaseDate": "2024-07-31T00:00:00.000Z",
    "marketValue": 274.20001220703125,
    "aggTaxlotId": "0e450f69-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.086630870048254,
    "orgPurchasePrice": 67.53,
    "company": "DEXCOM INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 68.55,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 60,
    "rgl": 120
  },
  {
    "instrId": "DXCM-XNAS",
    "localSymbol": "DXCM",
    "instrumentName": "DEXCOM INC",
    "purchaseDate": "2024-08-16T00:00:00.000Z",
    "marketValue": 274.20001220703125,
    "aggTaxlotId": "0e451135-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.086630870048254,
    "orgPurchasePrice": 74.545,
    "company": "DEXCOM INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 68.55,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 61,
    "rgl": 122
  },
  {
    "instrId": "EOG-XNYS",
    "localSymbol": "EOG",
    "instrumentName": "EOG RES INC",
    "purchaseDate": "2024-03-19T00:00:00.000Z",
    "marketValue": 475.8800048828125,
    "aggTaxlotId": "0e4512dc-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.15034973386667216,
    "orgPurchasePrice": 124.74,
    "company": "EOG RESOURCES INC.",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 118.97,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 62,
    "rgl": 124
  },
  {
    "instrId": "EOG-XNYS",
    "localSymbol": "EOG",
    "instrumentName": "EOG RES INC",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 237.94000244140625,
    "aggTaxlotId": "0e4514bd-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.07517486693333608,
    "orgPurchasePrice": 124.565,
    "company": "EOG RESOURCES INC.",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 118.97,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 63,
    "rgl": 126
  },
  {
    "instrId": "EPAC-XNYS",
    "localSymbol": "EPAC",
    "instrumentName": "ENERPAC TOOL GROUP CORP",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 2463.299903869629,
    "aggTaxlotId": "0e451661-7016-11ef-a17f-0260bab84379",
    "shares": 63,
    "weight": 0.7782560334128761,
    "orgPurchasePrice": 38.02,
    "company": "ENERPAC TOOL GROUP CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "H+M jt Acct",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 39.1,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 64,
    "rgl": 128
  },
  {
    "instrId": "ETN-XNYS",
    "localSymbol": "ETN",
    "instrumentName": "EATON CORP PLC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 864.8999633789062,
    "aggTaxlotId": "0e451820-7016-11ef-a17f-0260bab84379",
    "shares": 3,
    "weight": 0.27325686723764603,
    "orgPurchasePrice": 326.4819,
    "company": "EATON CORPORATION PLC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 288.3,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 65,
    "rgl": 130
  },
  {
    "instrId": "EVTC-XNYS",
    "localSymbol": "EVTC",
    "instrumentName": "EVERTEC INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1614.0599327087402,
    "aggTaxlotId": "0e451a04-7016-11ef-a17f-0260bab84379",
    "shares": 49,
    "weight": 0.509946790866696,
    "orgPurchasePrice": 33.6661,
    "company": "EVERTEC INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Trst FBO Elizabeth",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 32.94,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 66,
    "rgl": 132
  },
  {
    "instrId": "EXPD-XNAS",
    "localSymbol": "EXPD",
    "instrumentName": "EXPEDITORS INTL WASH INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 487.0400085449219,
    "aggTaxlotId": "0e451bda-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.1538756302341029,
    "orgPurchasePrice": 122.745,
    "company": "EXPEDITORS INTERNATIONAL OF WASHINGTON INC.",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 121.76,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 67,
    "rgl": 134
  },
  {
    "instrId": "EXPD-XNAS",
    "localSymbol": "EXPD",
    "instrumentName": "EXPEDITORS INTL WASH INC",
    "purchaseDate": "2024-03-19T00:00:00.000Z",
    "marketValue": 121.76000213623048,
    "aggTaxlotId": "0e451daa-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.03846890755852572,
    "orgPurchasePrice": 117.31,
    "company": "EXPEDITORS INTERNATIONAL OF WASHINGTON INC.",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 121.76,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 68,
    "rgl": 136
  },
  {
    "instrId": "FAST-XNAS",
    "localSymbol": "FAST",
    "instrumentName": "FASTENAL CO",
    "purchaseDate": "2024-03-19T00:00:00.000Z",
    "marketValue": 1648.0799560546875,
    "aggTaxlotId": "0e451f4c-7016-11ef-a17f-0260bab84379",
    "shares": 24,
    "weight": 0.520695091706654,
    "orgPurchasePrice": 76.0492,
    "company": "FASTENAL CO",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 68.67,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 69,
    "rgl": 138
  },
  {
    "instrId": "FAST-XNAS",
    "localSymbol": "FAST",
    "instrumentName": "FASTENAL CO",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 137.33999633789062,
    "aggTaxlotId": "0e452132-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.04339125764222117,
    "orgPurchasePrice": 64.25,
    "company": "FASTENAL CO",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 68.67,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 70,
    "rgl": 140
  },
  {
    "instrId": "FDS-XNYS",
    "localSymbol": "FDS",
    "instrumentName": "FACTSET RESH SYS INC",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 1322.1900329589844,
    "aggTaxlotId": "0e4522df-7016-11ef-a17f-0260bab84379",
    "shares": 3,
    "weight": 0.41773328893174005,
    "orgPurchasePrice": 407.4594,
    "company": "FACTSET RESEARCH SYSTEMS INC.",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "H+M jt Acct",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 440.73,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 71,
    "rgl": 142
  },
  {
    "instrId": "FFIN-XNAS",
    "localSymbol": "FFIN",
    "instrumentName": "FIRST FINL BANKSHARES INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1296.7200164794922,
    "aggTaxlotId": "0e452acd-7016-11ef-a17f-0260bab84379",
    "shares": 36,
    "weight": 0.409686280946577,
    "orgPurchasePrice": 28.8366,
    "company": "FIRST FINANCIAL BANKSHARES INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Trst FBO Elizabeth",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 36.02,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 72,
    "rgl": 144
  },
  {
    "instrId": "FFIN-XNAS",
    "localSymbol": "FFIN",
    "instrumentName": "FIRST FINL BANKSHARES INC",
    "purchaseDate": "2024-07-17T00:00:00.000Z",
    "marketValue": 432.24000549316406,
    "aggTaxlotId": "0e452ec3-7016-11ef-a17f-0260bab84379",
    "shares": 12,
    "weight": 0.13656209364885902,
    "orgPurchasePrice": 34.79,
    "company": "FIRST FINANCIAL BANKSHARES INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Trst FBO Elizabeth",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 36.02,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 73,
    "rgl": 146
  },
  {
    "instrId": "FIZZ-XNAS",
    "localSymbol": "FIZZ",
    "instrumentName": "NATIONAL BEVERAGE CORP",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1427.8399658203125,
    "aggTaxlotId": "0e45338c-7016-11ef-a17f-0260bab84379",
    "shares": 32,
    "weight": 0.4511123742594459,
    "orgPurchasePrice": 47.0971,
    "company": "NATIONAL BEVERAGE CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Trst FBO Elizabeth",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 44.62,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 74,
    "rgl": 148
  },
  {
    "instrId": "FOXF-XNAS",
    "localSymbol": "FOXF",
    "instrumentName": "FOX FACTORY HLDG CORP",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 1505.699935913086,
    "aggTaxlotId": "0e4537e6-7016-11ef-a17f-0260bab84379",
    "shares": 42,
    "weight": 0.4757114867714293,
    "orgPurchasePrice": 45.6582,
    "company": "FOX FACTORY HOLDING CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "H+M jt Acct",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 35.85,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 75,
    "rgl": 150
  },
  {
    "instrId": "FUL-XNYS",
    "localSymbol": "FUL",
    "instrumentName": "FULLER H B CO",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1896,
    "aggTaxlotId": "0e458608-7016-11ef-a17f-0260bab84379",
    "shares": 24,
    "weight": 0.5990230572545455,
    "orgPurchasePrice": 77.845,
    "company": "H.B. FULLER CO.",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Trst FBO Elizabeth",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 79,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 76,
    "rgl": 152
  },
  {
    "instrId": "GE-XNYS",
    "localSymbol": "GE",
    "instrumentName": "GE AEROSPACE TRADING",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 500.9399871826172,
    "aggTaxlotId": "0e458830-7016-11ef-a17f-0260bab84379",
    "shares": 3,
    "weight": 0.15826719547636295,
    "orgPurchasePrice": 162.8075,
    "company": "GE AEROSPACE",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 166.98,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 77,
    "rgl": 154
  },
  {
    "instrId": "GE-XNYS",
    "localSymbol": "GE",
    "instrumentName": "GE AEROSPACE TRADING",
    "purchaseDate": "2024-07-01T00:00:00.000Z",
    "marketValue": 333.9599914550781,
    "aggTaxlotId": "0e458a29-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.10551146365090866,
    "orgPurchasePrice": 157.575,
    "company": "GE AEROSPACE",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 166.98,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 78,
    "rgl": 156
  },
  {
    "instrId": "GOOG-XNAS",
    "localSymbol": "GOOG",
    "instrumentName": "ALPHABET INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 3150.2098846435547,
    "aggTaxlotId": "0e458bdf-7016-11ef-a17f-0260bab84379",
    "shares": 21,
    "weight": 0.9952786688252486,
    "orgPurchasePrice": 176.795,
    "company": "ALPHABET INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 150.01,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 79,
    "rgl": 158
  },
  {
    "instrId": "GOOG-XNAS",
    "localSymbol": "GOOG",
    "instrumentName": "ALPHABET INC",
    "purchaseDate": "2024-07-01T00:00:00.000Z",
    "marketValue": 150.00999450683594,
    "aggTaxlotId": "0e458db8-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.04739422232501184,
    "orgPurchasePrice": 184.405,
    "company": "ALPHABET INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 150.01,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 80,
    "rgl": 160
  },
  {
    "instrId": "GOOG-XNAS",
    "localSymbol": "GOOG",
    "instrumentName": "ALPHABET INC",
    "purchaseDate": "2024-07-31T00:00:00.000Z",
    "marketValue": 600.0399780273438,
    "aggTaxlotId": "0e458f72-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.18957688930004732,
    "orgPurchasePrice": 173.348,
    "company": "ALPHABET INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 150.01,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 81,
    "rgl": 162
  },
  {
    "instrId": "GS-XNYS",
    "localSymbol": "GS",
    "instrumentName": "GOLDMAN SACHS GROUP INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 934.260009765625,
    "aggTaxlotId": "0e459122-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.29517051019011936,
    "orgPurchasePrice": 461.55,
    "company": "GOLDMAN SACHS GROUP INC (THE)",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 467.13,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 82,
    "rgl": 164
  },
  {
    "instrId": "GSHD-XNAS",
    "localSymbol": "GSHD",
    "instrumentName": "GOOSEHEAD INS INC",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 2207.789909362793,
    "aggTaxlotId": "0e4592f3-7016-11ef-a17f-0260bab84379",
    "shares": 27,
    "weight": 0.6975300956129937,
    "orgPurchasePrice": 62.545,
    "company": "GOOSEHEAD INSURANCE INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "H+M jt Acct",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 81.77,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 83,
    "rgl": 166
  },
  {
    "instrId": "GTY-XNYS",
    "localSymbol": "GTY",
    "instrumentName": "GETTY RLTY CORP NEW",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1333.5,
    "aggTaxlotId": "0e4594b1-7016-11ef-a17f-0260bab84379",
    "shares": 42,
    "weight": 0.4213065647937429,
    "orgPurchasePrice": 27.4,
    "company": "GETTY REALTY CORP.",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Trst FBO Elizabeth",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 31.75,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 84,
    "rgl": 168
  },
  {
    "instrId": "GWW-XNYS",
    "localSymbol": "GWW",
    "instrumentName": "GRAINGER W W INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 977.6799926757812,
    "aggTaxlotId": "0e45966e-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.30888863830656543,
    "orgPurchasePrice": 821.16,
    "company": "GRAINGER (W.W.) INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 977.68,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 85,
    "rgl": 170
  },
  {
    "instrId": "HD-XNYS",
    "localSymbol": "HD",
    "instrumentName": "HOME DEPOT INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 741.739990234375,
    "aggTaxlotId": "0e459835-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.23434565223531223,
    "orgPurchasePrice": 330.3564,
    "company": "HOME DEPOT INC. (THE)",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 370.87,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 86,
    "rgl": 172
  },
  {
    "instrId": "HLI-XNYS",
    "localSymbol": "HLI",
    "instrumentName": "HOULIHAN LOKEY INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 3360.2801208496094,
    "aggTaxlotId": "0e4599ea-7016-11ef-a17f-0260bab84379",
    "shares": 22,
    "weight": 1.0616483498011635,
    "orgPurchasePrice": 133.05,
    "company": "HOULIHAN LOKEY INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Trst FBO Elizabeth",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 152.74,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 87,
    "rgl": 174
  },
  {
    "instrId": "HLLY-XNYS",
    "localSymbol": "HLLY",
    "instrumentName": "HOLLEY INC",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 374.3999862670898,
    "aggTaxlotId": "0e459be8-7016-11ef-a17f-0260bab84379",
    "shares": 120,
    "weight": 0.1182880930431287,
    "orgPurchasePrice": 3.655,
    "company": "EMPOWER LTD",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "H+M jt Acct",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 3.12,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 88,
    "rgl": 176
  },
  {
    "instrId": "HLMN-XNAS",
    "localSymbol": "HLMN",
    "instrumentName": "HILLMAN SOLUTIONS CORP",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1984.3199920654297,
    "aggTaxlotId": "0e459d88-7016-11ef-a17f-0260bab84379",
    "shares": 208,
    "weight": 0.6269269136172728,
    "orgPurchasePrice": 9.04,
    "company": "LANDCADIA HOLDINGS III INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Trst FBO Elizabeth",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 9.54,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 89,
    "rgl": 178
  },
  {
    "instrId": "HLT-XNYS",
    "localSymbol": "HLT",
    "instrumentName": "HILTON WORLDWIDE HLDGS INC",
    "purchaseDate": "2024-07-01T00:00:00.000Z",
    "marketValue": 421.2999877929688,
    "aggTaxlotId": "0e459f77-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.13310569974105846,
    "orgPurchasePrice": 212.82,
    "company": "HILTON WORLDWIDE HOLDINGS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 210.65,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 90,
    "rgl": 180
  },
  {
    "instrId": "HLT-XNYS",
    "localSymbol": "HLT",
    "instrumentName": "HILTON WORLDWIDE HLDGS INC",
    "purchaseDate": "2024-09-09T00:00:00.000Z",
    "marketValue": 210.6499938964844,
    "aggTaxlotId": "0e45a119-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.06655284987052923,
    "orgPurchasePrice": 215.52,
    "company": "HILTON WORLDWIDE HOLDINGS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 210.65,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 91,
    "rgl": 182
  },
  {
    "instrId": "HUM-XNYS",
    "localSymbol": "HUM",
    "instrumentName": "HUMANA INC",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 692.8400268554688,
    "aggTaxlotId": "0e45a2a4-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.2188961767274706,
    "orgPurchasePrice": 358.2,
    "company": "HUMANA INC.",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 346.42,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 92,
    "rgl": 184
  },
  {
    "instrId": "IDXX-XNAS",
    "localSymbol": "IDXX",
    "instrumentName": "IDEXX LABS INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 1983.800048828125,
    "aggTaxlotId": "0e45a47c-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.6267626425267616,
    "orgPurchasePrice": 553.975,
    "company": "IDEXX LABORATORIES INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 495.95,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 93,
    "rgl": 186
  },
  {
    "instrId": "ISRG-XNAS",
    "localSymbol": "ISRG",
    "instrumentName": "INTUITIVE SURGICAL INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1452.719970703125,
    "aggTaxlotId": "0e45a65b-7016-11ef-a17f-0260bab84379",
    "shares": 3,
    "weight": 0.4589729737264345,
    "orgPurchasePrice": 417.52,
    "company": "INTUITIVE SURGICAL INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 484.24,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 94,
    "rgl": 188
  },
  {
    "instrId": "JBT-XNYS",
    "localSymbol": "JBT",
    "instrumentName": "JOHN BEAN TECHNOLOGIES CORP",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1443.8100051879885,
    "aggTaxlotId": "0e45a82f-7016-11ef-a17f-0260bab84379",
    "shares": 17,
    "weight": 0.4561579553810179,
    "orgPurchasePrice": 93.97,
    "company": "JOHN BEAN TECHNOLOGIES CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Trst FBO Elizabeth",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 84.93,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 95,
    "rgl": 190
  },
  {
    "instrId": "JKHY-XNAS",
    "localSymbol": "JKHY",
    "instrumentName": "HENRY JACK & ASSOC INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 875.1999664306641,
    "aggTaxlotId": "0e45a9ff-7016-11ef-a17f-0260bab84379",
    "shares": 5,
    "weight": 0.2765110546415464,
    "orgPurchasePrice": 164.2884,
    "company": "HENRY (JACK) & ASSOCIATES INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Trst FBO Elizabeth",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 175.04,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 96,
    "rgl": 192
  },
  {
    "instrId": "KEYS-XNYS",
    "localSymbol": "KEYS",
    "instrumentName": "KEYSIGHT TECHNOLOGIES INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 589.52001953125,
    "aggTaxlotId": "0e461033-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.1862532090782536,
    "orgPurchasePrice": 136.105,
    "company": "KEYSIGHT TECHNOLOGIES INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 147.38,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 97,
    "rgl": 194
  },
  {
    "instrId": "LCII-XNYS",
    "localSymbol": "LCII",
    "instrumentName": "LCI INDS",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 439.2000122070313,
    "aggTaxlotId": "0e4613cb-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.13876104117008944,
    "orgPurchasePrice": 107.025,
    "company": "LCI INDUSTRIES",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Trst FBO Elizabeth",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 109.8,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 98,
    "rgl": 196
  },
  {
    "instrId": "LESL-XNAS",
    "localSymbol": "LESL",
    "instrumentName": "LESLIES INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 603.5800137519836,
    "aggTaxlotId": "0e46159c-7016-11ef-a17f-0260bab84379",
    "shares": 206,
    "weight": 0.19069532971279213,
    "orgPurchasePrice": 5.525,
    "company": "LESLIES INC COM",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Trst FBO Elizabeth",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 2.93,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 99,
    "rgl": 198
  },
  {
    "instrId": "LKFN-XNAS",
    "localSymbol": "LKFN",
    "instrumentName": "LAKELAND FINL CORP",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 772.9200439453125,
    "aggTaxlotId": "0e461786-7016-11ef-a17f-0260bab84379",
    "shares": 12,
    "weight": 0.24419669184464068,
    "orgPurchasePrice": 60.25,
    "company": "LAKELAND FINANCIAL CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Trst FBO Elizabeth",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 64.41,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 100,
    "rgl": 200
  },
  {
    "instrId": "LLY-XNYS",
    "localSymbol": "LLY",
    "instrumentName": "ELI LILLY & CO",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 4506.25,
    "aggTaxlotId": "0e461959-7016-11ef-a17f-0260bab84379",
    "shares": 5,
    "weight": 1.4237065673804303,
    "orgPurchasePrice": 832.1301,
    "company": "ELI LILLY AND CO",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 901.25,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 101,
    "rgl": 202
  },
  {
    "instrId": "LPLA-XNAS",
    "localSymbol": "LPLA",
    "instrumentName": "LPL FINL HLDGS INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 209.47999572753903,
    "aggTaxlotId": "0e461b27-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.06618320014471497,
    "orgPurchasePrice": 232.3667,
    "company": "LPL FINANCIAL HOLDINGS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 209.48,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 102,
    "rgl": 204
  },
  {
    "instrId": "LRLCY-OTCM",
    "localSymbol": "LRLCY",
    "instrumentName": "L OREAL CO ADR",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 660,
    "aggTaxlotId": "0e461ede-7016-11ef-a17f-0260bab84379",
    "shares": 8,
    "weight": 0.2085206844873418,
    "orgPurchasePrice": 99.39,
    "company": "LOREAL SA",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 82.5,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 103,
    "rgl": 206
  },
  {
    "instrId": "LSTR-XNAS",
    "localSymbol": "LSTR",
    "instrumentName": "LANDSTAR SYS INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1816.000061035156,
    "aggTaxlotId": "0e4620d0-7016-11ef-a17f-0260bab84379",
    "shares": 10,
    "weight": 0.573747842054705,
    "orgPurchasePrice": 180.21,
    "company": "LANDSTAR SYSTEM INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Trst FBO Elizabeth",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 181.6,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 104,
    "rgl": 208
  },
  {
    "instrId": "MA-XNYS",
    "localSymbol": "MA",
    "instrumentName": "MASTERCARD INCORPORATED",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 4880.199890136719,
    "aggTaxlotId": "0e4622a9-7016-11ef-a17f-0260bab84379",
    "shares": 10,
    "weight": 1.5418524568581191,
    "orgPurchasePrice": 422.4875,
    "company": "MASTERCARD INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 488.02,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 105,
    "rgl": 210
  },
  {
    "instrId": "MA-XNYS",
    "localSymbol": "MA",
    "instrumentName": "MASTERCARD INCORPORATED",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 2928.1199340820312,
    "aggTaxlotId": "0e462465-7016-11ef-a17f-0260bab84379",
    "shares": 6,
    "weight": 0.9251114741148716,
    "orgPurchasePrice": 446.12,
    "company": "MASTERCARD INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 488.02,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 106,
    "rgl": 212
  },
  {
    "instrId": "MA-XNYS",
    "localSymbol": "MA",
    "instrumentName": "MASTERCARD INCORPORATED",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 488.0199890136719,
    "aggTaxlotId": "0e4625eb-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.15418524568581196,
    "orgPurchasePrice": 457.62,
    "company": "MASTERCARD INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 488.02,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 107,
    "rgl": 214
  },
  {
    "instrId": "MANH-XNAS",
    "localSymbol": "MANH",
    "instrumentName": "MANHATTAN ASSOCIATES INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 518.97998046875,
    "aggTaxlotId": "0e462771-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.16396675873116823,
    "orgPurchasePrice": 204.195,
    "company": "MANHATTAN ASSOCIATES INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 259.49,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 108,
    "rgl": 216
  },
  {
    "instrId": "MANH-XNAS",
    "localSymbol": "MANH",
    "instrumentName": "MANHATTAN ASSOCIATES INC",
    "purchaseDate": "2024-03-19T00:00:00.000Z",
    "marketValue": 259.489990234375,
    "aggTaxlotId": "0e462936-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.08198337936558411,
    "orgPurchasePrice": 247.8926,
    "company": "MANHATTAN ASSOCIATES INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 259.49,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 109,
    "rgl": 218
  },
  {
    "instrId": "MAR-XNAS",
    "localSymbol": "MAR",
    "instrumentName": "MARRIOTT INTL INC NEW",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 451.3399963378906,
    "aggTaxlotId": "0e462aeb-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.14259655298922924,
    "orgPurchasePrice": 233.01,
    "company": "MARRIOTT INTERNATIONAL INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 225.67,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 110,
    "rgl": 220
  },
  {
    "instrId": "MCK-XNYS",
    "localSymbol": "MCK",
    "instrumentName": "MCKESSON CORP",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 3034.3800659179688,
    "aggTaxlotId": "0e462cc1-7016-11ef-a17f-0260bab84379",
    "shares": 6,
    "weight": 0.9586833459696366,
    "orgPurchasePrice": 481.45,
    "company": "MCKESSON CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 505.73,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 111,
    "rgl": 222
  },
  {
    "instrId": "MCO-XNYS",
    "localSymbol": "MCO",
    "instrumentName": "MOODYS CORP",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 968.280029296875,
    "aggTaxlotId": "0e462e99-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.3059188098248602,
    "orgPurchasePrice": 408.8899,
    "company": "MOODY'S CORP.",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 484.14,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 112,
    "rgl": 224
  },
  {
    "instrId": "MDB-XNAS",
    "localSymbol": "MDB",
    "instrumentName": "MONGODB INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 582.97998046875,
    "aggTaxlotId": "0e463051-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.1841869463178196,
    "orgPurchasePrice": 233.865,
    "company": "MONGODB INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 291.49,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 113,
    "rgl": 226
  },
  {
    "instrId": "META-XNAS",
    "localSymbol": "META",
    "instrumentName": "META PLATFORMS INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 5047.900085449219,
    "aggTaxlotId": "0e46320c-7016-11ef-a17f-0260bab84379",
    "shares": 10,
    "weight": 1.594835728850882,
    "orgPurchasePrice": 495.4696,
    "company": "META PLATFORMS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 504.79,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 114,
    "rgl": 228
  },
  {
    "instrId": "META-XNAS",
    "localSymbol": "META",
    "instrumentName": "META PLATFORMS INC",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 4543.110076904297,
    "aggTaxlotId": "0e4633dd-7016-11ef-a17f-0260bab84379",
    "shares": 9,
    "weight": 1.435352155965794,
    "orgPurchasePrice": 506.99,
    "company": "META PLATFORMS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 504.79,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 115,
    "rgl": 230
  },
  {
    "instrId": "META-XNAS",
    "localSymbol": "META",
    "instrumentName": "META PLATFORMS INC",
    "purchaseDate": "2024-07-01T00:00:00.000Z",
    "marketValue": 504.7900085449219,
    "aggTaxlotId": "0e463589-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.15948357288508822,
    "orgPurchasePrice": 501.426,
    "company": "META PLATFORMS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 504.79,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 116,
    "rgl": 232
  },
  {
    "instrId": "MOH-XNYS",
    "localSymbol": "MOH",
    "instrumentName": "MOLINA HEALTHCARE INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 332.3999938964844,
    "aggTaxlotId": "0e46372e-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.10501859734982295,
    "orgPurchasePrice": 378.745,
    "company": "MOLINA HEALTHCARE INC.",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 332.4,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 117,
    "rgl": 234
  },
  {
    "instrId": "MORN-XNAS",
    "localSymbol": "MORN",
    "instrumentName": "MORNINGSTAR INC",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 4002.570190429688,
    "aggTaxlotId": "0e4638fe-7016-11ef-a17f-0260bab84379",
    "shares": 13,
    "weight": 1.2645737512379218,
    "orgPurchasePrice": 293.505,
    "company": "MORNINGSTAR INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "H+M jt Acct",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 307.89,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 118,
    "rgl": 236
  },
  {
    "instrId": "MSFT-XNAS",
    "localSymbol": "MSFT",
    "instrumentName": "MICROSOFT CORP",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 7455.600219726562,
    "aggTaxlotId": "0e463aa2-7016-11ef-a17f-0260bab84379",
    "shares": 18,
    "weight": 2.355525547092968,
    "orgPurchasePrice": 423.02,
    "company": "MICROSOFT CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 414.2,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 119,
    "rgl": 238
  },
  {
    "instrId": "NCNO-XNAS",
    "localSymbol": "NCNO",
    "instrumentName": "NCINO INC",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 2175,
    "aggTaxlotId": "0e463c5b-7016-11ef-a17f-0260bab84379",
    "shares": 75,
    "weight": 0.6871704375151036,
    "orgPurchasePrice": 29.9433,
    "company": "NCINO INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "H+M jt Acct",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 29,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 120,
    "rgl": 240
  },
  {
    "instrId": "NFLX-XNAS",
    "localSymbol": "NFLX",
    "instrumentName": "NETFLIX INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 3368.099975585937,
    "aggTaxlotId": "0e463e2c-7016-11ef-a17f-0260bab84379",
    "shares": 5,
    "weight": 1.0641189580772406,
    "orgPurchasePrice": 650.1037,
    "company": "NETFLIX INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan FLP",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 673.62,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 121,
    "rgl": 242
  },
  {
    "instrId": "NFLX-XNAS",
    "localSymbol": "NFLX",
    "instrumentName": "NETFLIX INC",
    "purchaseDate": "2024-08-16T00:00:00.000Z",
    "marketValue": 673.6199951171875,
    "aggTaxlotId": "0e464014-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.21282379161544812,
    "orgPurchasePrice": 678.75,
    "company": "NETFLIX INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Rev Tr",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 673.62,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 122,
    "rgl": 244
  },
  {
    "instrId": "NOVT-XNAS",
    "localSymbol": "NOVT",
    "instrumentName": "NOVANTA INC",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 1178.9399871826172,
    "aggTaxlotId": "0e4641b8-7016-11ef-a17f-0260bab84379",
    "shares": 7,
    "weight": 0.3724748077224505,
    "orgPurchasePrice": 162.76,
    "company": "NOVANTA INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 168.42,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 123,
    "rgl": 246
  },
  {
    "instrId": "NOW-XNYS",
    "localSymbol": "NOW",
    "instrumentName": "SERVICENOW INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 7741.350219726562,
    "aggTaxlotId": "0e464382-7016-11ef-a17f-0260bab84379",
    "shares": 9,
    "weight": 2.4458055252630557,
    "orgPurchasePrice": 699.125,
    "company": "SERVICENOW INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 860.15,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 124,
    "rgl": 248
  },
  {
    "instrId": "NOW-XNYS",
    "localSymbol": "NOW",
    "instrumentName": "SERVICENOW INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1720.300048828125,
    "aggTaxlotId": "0e464547-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.5435123389473457,
    "orgPurchasePrice": 703.97,
    "company": "SERVICENOW INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Rev Tr",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 860.15,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 125,
    "rgl": 250
  },
  {
    "instrId": "NRC-XNAS",
    "localSymbol": "NRC",
    "instrumentName": "NATIONAL RESH CORP",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 584.2799835205078,
    "aggTaxlotId": "0e4646d6-7016-11ef-a17f-0260bab84379",
    "shares": 27,
    "weight": 0.18459766984234707,
    "orgPurchasePrice": 27.0972,
    "company": "NATIONAL RESEARCH CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 21.64,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 126,
    "rgl": 252
  },
  {
    "instrId": "NU-XNYS",
    "localSymbol": "NU",
    "instrumentName": "NU HLDGS LTD",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 398.1699867248535,
    "aggTaxlotId": "0e46493e-7016-11ef-a17f-0260bab84379",
    "shares": 29,
    "weight": 0.12579799723360946,
    "orgPurchasePrice": 11.685,
    "company": "NU HOLDINGS A",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Rev Tr",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 13.73,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 127,
    "rgl": 254
  },
  {
    "instrId": "NVDA-XNAS",
    "localSymbol": "NVDA",
    "instrumentName": "NVIDIA CORPORATION",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 1621.4999771118164,
    "aggTaxlotId": "0e464b7d-7016-11ef-a17f-0260bab84379",
    "shares": 15,
    "weight": 0.5122974017023713,
    "orgPurchasePrice": 539.477,
    "company": "NVIDIA CORPORATION",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 108.1,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 128,
    "rgl": 256
  },
  {
    "instrId": "NVDA-XNAS",
    "localSymbol": "NVDA",
    "instrumentName": "NVIDIA CORPORATION",
    "purchaseDate": "2024-03-19T00:00:00.000Z",
    "marketValue": 5404.999923706055,
    "aggTaxlotId": "0e464d38-7016-11ef-a17f-0260bab84379",
    "shares": 50,
    "weight": 1.7076580056745707,
    "orgPurchasePrice": 892.89,
    "company": "NVIDIA CORPORATION",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 108.1,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 129,
    "rgl": 258
  },
  {
    "instrId": "NVDA-XNAS",
    "localSymbol": "NVDA",
    "instrumentName": "NVIDIA CORPORATION",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 7999.399887084961,
    "aggTaxlotId": "0e464ed8-7016-11ef-a17f-0260bab84379",
    "shares": 74,
    "weight": 2.527333848398365,
    "orgPurchasePrice": 1221.03,
    "company": "NVIDIA CORPORATION",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Rev Tr",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 108.1,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 130,
    "rgl": 260
  },
  {
    "instrId": "NVEC-XNAS",
    "localSymbol": "NVEC",
    "instrumentName": "NVE CORP",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 227.51998901367188,
    "aggTaxlotId": "0e465076-7016-11ef-a17f-0260bab84379",
    "shares": 3,
    "weight": 0.07188276339952021,
    "orgPurchasePrice": 76.8088,
    "company": "NVE CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 75.84,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 131,
    "rgl": 262
  },
  {
    "instrId": "NVO-XNYS",
    "localSymbol": "NVO",
    "instrumentName": "NOVO-NORDISK A S ADR",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 2206.259979248047,
    "aggTaxlotId": "0e465239-7016-11ef-a17f-0260bab84379",
    "shares": 17,
    "weight": 0.6970467288330776,
    "orgPurchasePrice": 140.93,
    "company": "NOVO NORDISK A/S",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Rev Tr",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 129.78,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 132,
    "rgl": 264
  },
  {
    "instrId": "ODFL-XNAS",
    "localSymbol": "ODFL",
    "instrumentName": "OLD DOMINION FREIGHT LINE IN",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 1720.4400329589844,
    "aggTaxlotId": "0e4653e7-7016-11ef-a17f-0260bab84379",
    "shares": 9,
    "weight": 0.5435565655939884,
    "orgPurchasePrice": 388.3,
    "company": "OLD DOMINION FREIGHT LINE INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 191.16,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 133,
    "rgl": 266
  },
  {
    "instrId": "OFLX-XNAS",
    "localSymbol": "OFLX",
    "instrumentName": "OMEGA FLEX INC",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 525.3599815368652,
    "aggTaxlotId": "0e4655b7-7016-11ef-a17f-0260bab84379",
    "shares": 11,
    "weight": 0.1659824590186733,
    "orgPurchasePrice": 60.89,
    "company": "OMEGA FLEX INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 47.76,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 134,
    "rgl": 268
  },
  {
    "instrId": "OLLI-XNAS",
    "localSymbol": "OLLI",
    "instrumentName": "OLLIES BARGAIN OUTLET HLDGS",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 1869.4000244140625,
    "aggTaxlotId": "0e46575e-7016-11ef-a17f-0260bab84379",
    "shares": 20,
    "weight": 0.590619049502233,
    "orgPurchasePrice": 85.6,
    "company": "OLLIE'S BARGAIN OUTLET HOLDINGS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 93.47,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 135,
    "rgl": 270
  },
  {
    "instrId": "ORLY-XNAS",
    "localSymbol": "ORLY",
    "instrumentName": "OREILLY AUTOMOTIVE INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 2258.860107421875,
    "aggTaxlotId": "0e46591e-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.7136652360011512,
    "orgPurchasePrice": 973.9395,
    "company": "O'REILLY AUTOMOTIVE INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Rev Tr",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 1129.43,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 136,
    "rgl": 272
  },
  {
    "instrId": "PANW-XNAS",
    "localSymbol": "PANW",
    "instrumentName": "PALO ALTO NETWORKS INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 4866.119812011719,
    "aggTaxlotId": "0e466ff4-7016-11ef-a17f-0260bab84379",
    "shares": 14,
    "weight": 1.5374039909062098,
    "orgPurchasePrice": 299.85,
    "company": "PALO ALTO NETWORKS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 347.58,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 137,
    "rgl": 274
  },
  {
    "instrId": "PANW-XNAS",
    "localSymbol": "PANW",
    "instrumentName": "PALO ALTO NETWORKS INC",
    "purchaseDate": "2024-03-19T00:00:00.000Z",
    "marketValue": 347.5799865722656,
    "aggTaxlotId": "0e467295-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.109814570779015,
    "orgPurchasePrice": 283.79,
    "company": "PALO ALTO NETWORKS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 347.58,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 138,
    "rgl": 276
  },
  {
    "instrId": "PANW-XNAS",
    "localSymbol": "PANW",
    "instrumentName": "PALO ALTO NETWORKS INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 695.1599731445312,
    "aggTaxlotId": "0e46752d-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.21962914155803,
    "orgPurchasePrice": 294.9396,
    "company": "PALO ALTO NETWORKS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Rev Tr",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 347.58,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 139,
    "rgl": 278
  },
  {
    "instrId": "PGR-XNYS",
    "localSymbol": "PGR",
    "instrumentName": "PROGRESSIVE CORP",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 6228.2501220703125,
    "aggTaxlotId": "0e4676db-7016-11ef-a17f-0260bab84379",
    "shares": 25,
    "weight": 1.9677560282007147,
    "orgPurchasePrice": 166.07,
    "company": "PROGRESSIVE CORP (THE)",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 249.13,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 140,
    "rgl": 280
  },
  {
    "instrId": "PGR-XNYS",
    "localSymbol": "PGR",
    "instrumentName": "PROGRESSIVE CORP",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 996.52001953125,
    "aggTaxlotId": "0e467880-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.31484096451211435,
    "orgPurchasePrice": 212.31,
    "company": "PROGRESSIVE CORP (THE)",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Rev Tr",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 249.13,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 141,
    "rgl": 282
  },
  {
    "instrId": "PRI-XNYS",
    "localSymbol": "PRI",
    "instrumentName": "PRIMERICA INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 2276.819961547852,
    "aggTaxlotId": "0e467a96-7016-11ef-a17f-0260bab84379",
    "shares": 9,
    "weight": 0.7193394800551534,
    "orgPurchasePrice": 225.04,
    "company": "PRIMERICA INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Trst FBO Elizabeth",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 252.98,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 142,
    "rgl": 284
  },
  {
    "instrId": "PRU-XNYS",
    "localSymbol": "PRU",
    "instrumentName": "PRUDENTIAL FINL INC",
    "purchaseDate": "2024-03-19T00:00:00.000Z",
    "marketValue": 458.7200012207031,
    "aggTaxlotId": "0e467c77-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.14492819491299286,
    "orgPurchasePrice": 113.789,
    "company": "PRUDENTIAL FINANCIAL INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 114.68,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 143,
    "rgl": 286
  },
  {
    "instrId": "PRU-XNYS",
    "localSymbol": "PRU",
    "instrumentName": "PRUDENTIAL FINL INC",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 229.3600006103516,
    "aggTaxlotId": "0e467e5b-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.07246409745649643,
    "orgPurchasePrice": 118.605,
    "company": "PRUDENTIAL FINANCIAL INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 114.68,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 144,
    "rgl": 288
  },
  {
    "instrId": "PSA-XNYS",
    "localSymbol": "PSA",
    "instrumentName": "PUBLIC STORAGE OPER CO",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 359.1400146484375,
    "aggTaxlotId": "0e468015-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.11346685103225176,
    "orgPurchasePrice": 296.399,
    "company": "PUBLIC STORAGE",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 359.14,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 145,
    "rgl": 290
  },
  {
    "instrId": "PSMT-XNAS",
    "localSymbol": "PSMT",
    "instrumentName": "PRICESMART INC",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 510.8399963378906,
    "aggTaxlotId": "0e4681eb-7016-11ef-a17f-0260bab84379",
    "shares": 6,
    "weight": 0.16139500863619413,
    "orgPurchasePrice": 83.0373,
    "company": "PRICESMART INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 85.14,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 146,
    "rgl": 292
  },
  {
    "instrId": "PSTG-XNYS",
    "localSymbol": "PSTG",
    "instrumentName": "PURE STORAGE INC",
    "purchaseDate": "2024-03-19T00:00:00.000Z",
    "marketValue": 93.41999816894533,
    "aggTaxlotId": "0e4683c8-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.029515154489385895,
    "orgPurchasePrice": 49.765,
    "company": "PURE STORAGE INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 46.71,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 147,
    "rgl": 294
  },
  {
    "instrId": "PSTG-XNYS",
    "localSymbol": "PSTG",
    "instrumentName": "PURE STORAGE INC",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 420.3899917602539,
    "aggTaxlotId": "0e468596-7016-11ef-a17f-0260bab84379",
    "shares": 9,
    "weight": 0.13281819520223656,
    "orgPurchasePrice": 63.2902,
    "company": "PURE STORAGE INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 46.71,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 148,
    "rgl": 296
  },
  {
    "instrId": "RBC-XNYS",
    "localSymbol": "RBC",
    "instrumentName": "RBC BEARINGS INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 2263.840087890625,
    "aggTaxlotId": "0e46874c-7016-11ef-a17f-0260bab84379",
    "shares": 8,
    "weight": 0.715238613177026,
    "orgPurchasePrice": 287.1823,
    "company": "RBC BEARINGS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Trst FBO Elizabeth",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 282.98,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 149,
    "rgl": 298
  },
  {
    "instrId": "REGN-XNAS",
    "localSymbol": "REGN",
    "instrumentName": "REGENERON PHARMACEUTICALS",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 1136.3499755859375,
    "aggTaxlotId": "0e4689b8-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.3590189011005361,
    "orgPurchasePrice": 898.5606,
    "company": "REGENERON PHARMACEUTICALS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 1136.35,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 150,
    "rgl": 300
  },
  {
    "instrId": "RF-XNYS",
    "localSymbol": "RF",
    "instrumentName": "REGIONS FINANCIAL CORP NEW",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 200.879997253418,
    "aggTaxlotId": "0e471e28-7016-11ef-a17f-0260bab84379",
    "shares": 9,
    "weight": 0.06346611291984554,
    "orgPurchasePrice": 19.1756,
    "company": "REGIONS FINANCIAL CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 22.32,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 151,
    "rgl": 302
  },
  {
    "instrId": "RLI-XNYS",
    "localSymbol": "RLI",
    "instrumentName": "RLI CORP",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1676.6199798583984,
    "aggTaxlotId": "0e4720d5-7016-11ef-a17f-0260bab84379",
    "shares": 11,
    "weight": 0.529712039129131,
    "orgPurchasePrice": 142.1852,
    "company": "RLI CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Trst FBO Elizabeth",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 152.42,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 152,
    "rgl": 304
  },
  {
    "instrId": "ROAD-XNAS",
    "localSymbol": "ROAD",
    "instrumentName": "CONSTRUCTION PARTNERS INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 2255.6800231933594,
    "aggTaxlotId": "0e4722b9-7016-11ef-a17f-0260bab84379",
    "shares": 38,
    "weight": 0.712660518819246,
    "orgPurchasePrice": 56.335,
    "company": "CONSTRUCTION PARTNERS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Trst FBO Elizabeth",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 59.36,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 153,
    "rgl": 306
  },
  {
    "instrId": "RTMVY-OTCM",
    "localSymbol": "RTMVY",
    "instrumentName": "RIGHTMOVE PLC ADR",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 2698.0815391540527,
    "aggTaxlotId": "0e4724b7-7016-11ef-a17f-0260bab84379",
    "shares": 154,
    "weight": 0.8524330444652481,
    "orgPurchasePrice": 14.63,
    "company": "RIGHTMOVE PLC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 17.52,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 154,
    "rgl": 308
  },
  {
    "instrId": "RVLV-XNYS",
    "localSymbol": "RVLV",
    "instrumentName": "REVOLVE GROUP INC",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 1355.9400520324707,
    "aggTaxlotId": "0e47265c-7016-11ef-a17f-0260bab84379",
    "shares": 62,
    "weight": 0.4283962845054737,
    "orgPurchasePrice": 18.1282,
    "company": "REVOLVE GROUP LLC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 21.87,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 155,
    "rgl": 310
  },
  {
    "instrId": "RYAN-XNYS",
    "localSymbol": "RYAN",
    "instrumentName": "RYAN SPECIALTY HLDGS INC",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 4984.200119018555,
    "aggTaxlotId": "0e472817-7016-11ef-a17f-0260bab84379",
    "shares": 78,
    "weight": 1.5747103339994537,
    "orgPurchasePrice": 54.573,
    "company": "RYAN SPECIALTY GROUP HOLDINGS A",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 63.9,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 156,
    "rgl": 312
  },
  {
    "instrId": "SDHC-XNYS",
    "localSymbol": "SDHC",
    "instrumentName": "SMITH DOUGLAS HOMES CORP",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 554.0800170898438,
    "aggTaxlotId": "0e4729ca-7016-11ef-a17f-0260bab84379",
    "shares": 16,
    "weight": 0.17505627943080643,
    "orgPurchasePrice": 25.51,
    "company": "SMITH DOUGLAS HOMES CL A ORD",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 34.63,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 157,
    "rgl": 314
  },
  {
    "instrId": "SDHC-XNYS",
    "localSymbol": "SDHC",
    "instrumentName": "SMITH DOUGLAS HOMES CORP",
    "purchaseDate": "2024-08-15T00:00:00.000Z",
    "marketValue": 277.0400085449219,
    "aggTaxlotId": "0e472b9d-7016-11ef-a17f-0260bab84379",
    "shares": 8,
    "weight": 0.08752813971540321,
    "orgPurchasePrice": 34.15,
    "company": "SMITH DOUGLAS HOMES CL A ORD",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 34.63,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 158,
    "rgl": 316
  },
  {
    "instrId": "SFBS-XNYS",
    "localSymbol": "SFBS",
    "instrumentName": "SERVISFIRST BANCSHARES INC",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 2736.7198791503906,
    "aggTaxlotId": "0e472df5-7016-11ef-a17f-0260bab84379",
    "shares": 36,
    "weight": 0.8646404582584163,
    "orgPurchasePrice": 60.7562,
    "company": "SERVISFIRST BANCSHARES INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 76.02,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 159,
    "rgl": 318
  },
  {
    "instrId": "SITE-XNYS",
    "localSymbol": "SITE",
    "instrumentName": "SITEONE LANDSCAPE SUPPLY INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1463.9899597167969,
    "aggTaxlotId": "0e472fb4-7016-11ef-a17f-0260bab84379",
    "shares": 11,
    "weight": 0.4625336189132461,
    "orgPurchasePrice": 136.48,
    "company": "SITEONE LANDSCAPE SUPPLY INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Trst FBO Elizabeth",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 133.09,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 160,
    "rgl": 320
  },
  {
    "instrId": "SMG-XNYS",
    "localSymbol": "SMG",
    "instrumentName": "SCOTTS MIRACLE-GRO CO",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1139,
    "aggTaxlotId": "0e47319b-7016-11ef-a17f-0260bab84379",
    "shares": 17,
    "weight": 0.3598561509561853,
    "orgPurchasePrice": 65.3348,
    "company": "SCOTTS MIRACLE-GRO CO (THE)",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Trst FBO Elizabeth",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 67,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 161,
    "rgl": 322
  },
  {
    "instrId": "SNOW-XNYS",
    "localSymbol": "SNOW",
    "instrumentName": "SNOWFLAKE INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 441.0799865722656,
    "aggTaxlotId": "0e473369-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.13935500108138843,
    "orgPurchasePrice": 134.6999,
    "company": "SNOWFLAKE INC CL A",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Rev Tr",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 110.27,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 162,
    "rgl": 324
  },
  {
    "instrId": "SPSC-XNAS",
    "localSymbol": "SPSC",
    "instrumentName": "SPS COMM INC",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 1858.800048828125,
    "aggTaxlotId": "0e47352b-7016-11ef-a17f-0260bab84379",
    "shares": 10,
    "weight": 0.5872700886465833,
    "orgPurchasePrice": 189.15,
    "company": "SPS COMMERCE INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 185.88,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 163,
    "rgl": 326
  },
  {
    "instrId": "SUI-XNYS",
    "localSymbol": "SUI",
    "instrumentName": "SUN CMNTYS INC",
    "purchaseDate": "2024-03-19T00:00:00.000Z",
    "marketValue": 565.5999755859375,
    "aggTaxlotId": "0e4736dd-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.17869590008364164,
    "orgPurchasePrice": 130.3999,
    "company": "SUN COMMUNITIES INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 141.4,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 164,
    "rgl": 328
  },
  {
    "instrId": "SUI-XNYS",
    "localSymbol": "SUI",
    "instrumentName": "SUN CMNTYS INC",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 282.79998779296875,
    "aggTaxlotId": "0e47395b-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.08934795004182082,
    "orgPurchasePrice": 117.7997,
    "company": "SUN COMMUNITIES INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 141.4,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 165,
    "rgl": 330
  },
  {
    "instrId": "T-XNYS",
    "localSymbol": "T",
    "instrumentName": "AT&T INC",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 846.6899642944336,
    "aggTaxlotId": "0e473b76-7016-11ef-a17f-0260bab84379",
    "shares": 39,
    "weight": 0.26750359227763376,
    "orgPurchasePrice": 18.678,
    "company": "AT&T INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 21.71,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 166,
    "rgl": 332
  },
  {
    "instrId": "TFIN-XNAS",
    "localSymbol": "TFIN",
    "instrumentName": "TRIUMPH FINANCIAL INC",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 2194.919982910156,
    "aggTaxlotId": "0e473d40-7016-11ef-a17f-0260bab84379",
    "shares": 28,
    "weight": 0.6934639655020762,
    "orgPurchasePrice": 75.35,
    "company": "TRIUMPH FINANCIAL INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 78.39,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 167,
    "rgl": 334
  },
  {
    "instrId": "THO-XNYS",
    "localSymbol": "THO",
    "instrumentName": "THOR INDS INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1377.040008544922,
    "aggTaxlotId": "0e473f07-7016-11ef-a17f-0260bab84379",
    "shares": 14,
    "weight": 0.4350626138609728,
    "orgPurchasePrice": 96.68,
    "company": "THOR INDUSTRIES INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Trst FBO Elizabeth",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 98.36,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 168,
    "rgl": 336
  },
  {
    "instrId": "TJX-XNYS",
    "localSymbol": "TJX",
    "instrumentName": "TJX COS INC NEW",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 942.6400146484376,
    "aggTaxlotId": "0e4740b6-7016-11ef-a17f-0260bab84379",
    "shares": 8,
    "weight": 0.2978180925449244,
    "orgPurchasePrice": 107.7559,
    "company": "TJX COMPANIES INC (THE)",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Rev Tr",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 117.83,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 169,
    "rgl": 338
  },
  {
    "instrId": "TPL-XNYS",
    "localSymbol": "TPL",
    "instrumentName": "TEXAS PACIFIC LAND TRUST",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 787.6500244140625,
    "aggTaxlotId": "0e474277-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.24885048822316935,
    "orgPurchasePrice": 742.43,
    "company": "TEXAS PACIFIC LAND CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 787.65,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 170,
    "rgl": 340
  },
  {
    "instrId": "TROW-XNAS",
    "localSymbol": "TROW",
    "instrumentName": "PRICE T ROWE GROUP INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 414.9599914550781,
    "aggTaxlotId": "0e47444a-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.13110263856526422,
    "orgPurchasePrice": 106.265,
    "company": "T. ROWE PRICE GROUP INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 103.74,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 171,
    "rgl": 342
  },
  {
    "instrId": "TRU-XNYS",
    "localSymbol": "TRU",
    "instrumentName": "TRANSUNION",
    "purchaseDate": "2024-03-19T00:00:00.000Z",
    "marketValue": 481.19998931884766,
    "aggTaxlotId": "0e474601-7016-11ef-a17f-0260bab84379",
    "shares": 5,
    "weight": 0.15203053204252678,
    "orgPurchasePrice": 78.3313,
    "company": "TRANSUNION",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 96.24,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 172,
    "rgl": 344
  },
  {
    "instrId": "TRV-XNYS",
    "localSymbol": "TRV",
    "instrumentName": "TRAVELERS COMPANIES INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 716.5500183105469,
    "aggTaxlotId": "0e4747cc-7016-11ef-a17f-0260bab84379",
    "shares": 3,
    "weight": 0.22638712164777652,
    "orgPurchasePrice": 191.6278,
    "company": "TRAVELERS COMPANIES INC (THE)",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 238.85,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 173,
    "rgl": 346
  },
  {
    "instrId": "TRV-XNYS",
    "localSymbol": "TRV",
    "instrumentName": "TRAVELERS COMPANIES INC",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 238.8500061035156,
    "aggTaxlotId": "0e474be9-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.07546237388259218,
    "orgPurchasePrice": 209.9545,
    "company": "TRAVELERS COMPANIES INC (THE)",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 238.85,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 174,
    "rgl": 348
  },
  {
    "instrId": "TSLA-XNAS",
    "localSymbol": "TSLA",
    "instrumentName": "TESLA INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 2261.699981689453,
    "aggTaxlotId": "0e474d9c-7016-11ef-a17f-0260bab84379",
    "shares": 10,
    "weight": 0.7145624671013533,
    "orgPurchasePrice": 175.0799,
    "company": "TESLA INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Rev Tr",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 226.17,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 175,
    "rgl": 350
  },
  {
    "instrId": "TTD-XNAS",
    "localSymbol": "TTD",
    "instrumentName": "THE TRADE DESK INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1296.2299880981443,
    "aggTaxlotId": "0e474f66-7016-11ef-a17f-0260bab84379",
    "shares": 13,
    "weight": 0.4095314611685515,
    "orgPurchasePrice": 97.585,
    "company": "TRADE DESK INC (THE)",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Rev Tr",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 99.71,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 176,
    "rgl": 352
  },
  {
    "instrId": "TXN-XNAS",
    "localSymbol": "TXN",
    "instrumentName": "TEXAS INSTRS INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 1798.2000274658203,
    "aggTaxlotId": "0e47514a-7016-11ef-a17f-0260bab84379",
    "shares": 9,
    "weight": 0.568124091776257,
    "orgPurchasePrice": 168.888,
    "company": "TEXAS INSTRUMENTS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 199.8,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 177,
    "rgl": 354
  },
  {
    "instrId": "UBER-XNYS",
    "localSymbol": "UBER",
    "instrumentName": "UBER TECHNOLOGIES INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1241.8199615478516,
    "aggTaxlotId": "0e47530a-7016-11ef-a17f-0260bab84379",
    "shares": 18,
    "weight": 0.3923411339272765,
    "orgPurchasePrice": 65.7099,
    "company": "UBER TECHNOLOGIES INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Rev Tr",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 68.99,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 178,
    "rgl": 356
  },
  {
    "instrId": "ULTA-XNAS",
    "localSymbol": "ULTA",
    "instrumentName": "ULTA BEAUTY INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 747,
    "aggTaxlotId": "0e4754f8-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.23600750198794596,
    "orgPurchasePrice": 486.8095,
    "company": "ULTA BEAUTY INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 373.5,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 179,
    "rgl": 358
  },
  {
    "instrId": "UNF-XNYS",
    "localSymbol": "UNF",
    "instrumentName": "UNIFIRST CORP MASS",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1645.5599670410156,
    "aggTaxlotId": "0e488dd4-7016-11ef-a17f-0260bab84379",
    "shares": 9,
    "weight": 0.5198989252914548,
    "orgPurchasePrice": 158.115,
    "company": "UNIFIRST CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Trst FBO Elizabeth",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 182.84,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 180,
    "rgl": 360
  },
  {
    "instrId": "USPH-XNYS",
    "localSymbol": "USPH",
    "instrumentName": "U S PHYSICAL THERAPY",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 1170.959991455078,
    "aggTaxlotId": "0e489053-7016-11ef-a17f-0260bab84379",
    "shares": 14,
    "weight": 0.369953604432583,
    "orgPurchasePrice": 98.295,
    "company": "US PHYSICAL THERAPY INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 83.64,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 181,
    "rgl": 362
  },
  {
    "instrId": "V-XNYS",
    "localSymbol": "V",
    "instrumentName": "VISA INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 2568.059967041016,
    "aggTaxlotId": "0e48921c-7016-11ef-a17f-0260bab84379",
    "shares": 9,
    "weight": 0.8113539729271712,
    "orgPurchasePrice": 274.64,
    "company": "VISA INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Rev Tr",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 285.34,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 182,
    "rgl": 364
  },
  {
    "instrId": "VLO-XNYS",
    "localSymbol": "VLO",
    "instrumentName": "VALERO ENERGY CORP",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 2041.3499450683596,
    "aggTaxlotId": "0e4893d0-7016-11ef-a17f-0260bab84379",
    "shares": 15,
    "weight": 0.6449449815512907,
    "orgPurchasePrice": 127.489,
    "company": "VALERO ENERGY CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 136.09,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 183,
    "rgl": 366
  },
  {
    "instrId": "VRTX-XNAS",
    "localSymbol": "VRTX",
    "instrumentName": "VERTEX PHARMACEUTICALS INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 4707.799987792969,
    "aggTaxlotId": "0e489596-7016-11ef-a17f-0260bab84379",
    "shares": 10,
    "weight": 1.487384357400135,
    "orgPurchasePrice": 421.445,
    "company": "VERTEX PHARMACEUTICALS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 470.78,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 184,
    "rgl": 368
  },
  {
    "instrId": "VRTX-XNAS",
    "localSymbol": "VRTX",
    "instrumentName": "VERTEX PHARMACEUTICALS INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1412.3399963378906,
    "aggTaxlotId": "0e489738-7016-11ef-a17f-0260bab84379",
    "shares": 3,
    "weight": 0.44621530722004055,
    "orgPurchasePrice": 483.88,
    "company": "VERTEX PHARMACEUTICALS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Rev Tr",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 470.78,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 185,
    "rgl": 370
  },
  {
    "instrId": "VRTX-XNAS",
    "localSymbol": "VRTX",
    "instrumentName": "VERTEX PHARMACEUTICALS INC",
    "purchaseDate": "2024-07-31T00:00:00.000Z",
    "marketValue": 470.7799987792969,
    "aggTaxlotId": "0e4898d4-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.1487384357400135,
    "orgPurchasePrice": 499.95,
    "company": "VERTEX PHARMACEUTICALS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Rev Tr",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 470.78,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 186,
    "rgl": 372
  },
  {
    "instrId": "VST-XNYS",
    "localSymbol": "VST",
    "instrumentName": "VISTRA CORP",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 1217.280029296875,
    "aggTaxlotId": "0e489a76-7016-11ef-a17f-0260bab84379",
    "shares": 16,
    "weight": 0.38458797715417553,
    "orgPurchasePrice": 89.3651,
    "company": "VISTRA CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 76.08,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 187,
    "rgl": 374
  },
  {
    "instrId": "WDFC-XNAS",
    "localSymbol": "WDFC",
    "instrumentName": "WD 40 CO",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1788.709991455078,
    "aggTaxlotId": "0e489c4f-7016-11ef-a17f-0260bab84379",
    "shares": 7,
    "weight": 0.5651258057084245,
    "orgPurchasePrice": 229.44,
    "company": "WD-40 CO",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Trst FBO Elizabeth",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 255.53,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 188,
    "rgl": 376
  },
  {
    "instrId": "WELL-XNYS",
    "localSymbol": "WELL",
    "instrumentName": "WELLTOWER INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 129.02000427246094,
    "aggTaxlotId": "0e489e0e-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.04076263576280805,
    "orgPurchasePrice": 89.58,
    "company": "WELLTOWER INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 129.02,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 189,
    "rgl": 378
  },
  {
    "instrId": "WELL-XNYS",
    "localSymbol": "WELL",
    "instrumentName": "WELLTOWER INC",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 1161.1800384521484,
    "aggTaxlotId": "0e489fe8-7016-11ef-a17f-0260bab84379",
    "shares": 9,
    "weight": 0.3668637218652725,
    "orgPurchasePrice": 101.9,
    "company": "WELLTOWER INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 129.02,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 190,
    "rgl": 380
  },
  {
    "instrId": "WM-XNYS",
    "localSymbol": "WM",
    "instrumentName": "WASTE MGMT INC DEL",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 624.75,
    "aggTaxlotId": "0e48a197-7016-11ef-a17f-0260bab84379",
    "shares": 3,
    "weight": 0.1973837842931315,
    "orgPurchasePrice": 177.8491,
    "company": "WASTE MANAGEMENT INC.",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 208.25,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 191,
    "rgl": 382
  },
  {
    "instrId": "WM-XNYS",
    "localSymbol": "WM",
    "instrumentName": "WASTE MGMT INC DEL",
    "purchaseDate": "2024-03-19T00:00:00.000Z",
    "marketValue": 208.25,
    "aggTaxlotId": "0e48a550-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.06579459476437717,
    "orgPurchasePrice": 211.615,
    "company": "WASTE MANAGEMENT INC.",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 208.25,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 192,
    "rgl": 384
  },
  {
    "instrId": "WM-XNYS",
    "localSymbol": "WM",
    "instrumentName": "WASTE MGMT INC DEL",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 416.5,
    "aggTaxlotId": "0e48a73b-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.13158918952875434,
    "orgPurchasePrice": 212.95,
    "company": "WASTE MANAGEMENT INC.",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 208.25,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 193,
    "rgl": 386
  },
  {
    "instrId": "WMT-XNYS",
    "localSymbol": "WMT",
    "instrumentName": "WALMART INC",
    "purchaseDate": "2024-03-19T00:00:00.000Z",
    "marketValue": 2679.5399169921875,
    "aggTaxlotId": "0e48a8f6-7016-11ef-a17f-0260bab84379",
    "shares": 34,
    "weight": 0.8465749963672211,
    "orgPurchasePrice": 60.8292,
    "company": "WALMART INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 78.81,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 194,
    "rgl": 388
  },
  {
    "instrId": "WMT-XNYS",
    "localSymbol": "WMT",
    "instrumentName": "WALMART INC",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 315.239990234375,
    "aggTaxlotId": "0e48aac8-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.09959705839614366,
    "orgPurchasePrice": 67.2265,
    "company": "WALMART INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 78.81,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 195,
    "rgl": 390
  },
  {
    "instrId": "WMT-XNYS",
    "localSymbol": "WMT",
    "instrumentName": "WALMART INC",
    "purchaseDate": "2024-08-16T00:00:00.000Z",
    "marketValue": 315.239990234375,
    "aggTaxlotId": "0e48ac5b-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.09959705839614366,
    "orgPurchasePrice": 73.045,
    "company": "WALMART INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Rev Tr",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 78.81,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 196,
    "rgl": 392
  },
  {
    "instrId": "WSO-XNYS",
    "localSymbol": "WSO",
    "instrumentName": "WATSCO INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 2730.8999633789062,
    "aggTaxlotId": "0e48aded-7016-11ef-a17f-0260bab84379",
    "shares": 6,
    "weight": 0.8628017115609488,
    "orgPurchasePrice": 463.8728,
    "company": "WATSCO INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Trst FBO Elizabeth",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 455.15,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 197,
    "rgl": 394
  },
  {
    "instrId": "__CASH",
    "localSymbol": "__USD",
    "instrumentName": "CASH PGM FOR USD",
    "purchaseDate": "2024-09-11T00:00:00.000Z",
    "marketValue": 2029.1472577,
    "aggTaxlotId": "0e48afbc-7016-11ef-a17f-0260bab84379",
    "shares": 2029.1472577,
    "weight": 0.6410896592442675,
    "orgPurchasePrice": 1,
    "company": "CASH PGM FOR USD",
    "assetClass": "CA",
    "assetClassDesc": "Cash",
    "accountName": "Harry Rev Tr",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 1,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 198,
    "rgl": 396
  },
  {
    "instrId": "__CASH",
    "localSymbol": "__USD",
    "instrumentName": "CASH PGM FOR USD",
    "purchaseDate": "2024-09-11T00:00:00.000Z",
    "marketValue": 3211.4474147,
    "aggTaxlotId": "0e48afbc-7016-11ef-a17f-0260bab84379",
    "shares": 3211.4474147,
    "weight": 1.0146260804671945,
    "orgPurchasePrice": 1,
    "company": "CASH PGM FOR USD",
    "assetClass": "CA",
    "assetClassDesc": "Cash",
    "accountName": "Trst FBO Elizabeth",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 1,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 199,
    "rgl": 398
  },
  {
    "instrId": "__CASH",
    "localSymbol": "__USD",
    "instrumentName": "CASH PGM FOR USD",
    "purchaseDate": "2024-09-11T00:00:00.000Z",
    "marketValue": 4674.3208486,
    "aggTaxlotId": "0e48afbc-7016-11ef-a17f-0260bab84379",
    "shares": 4674.3208486,
    "weight": 1.4768069437326126,
    "orgPurchasePrice": 1,
    "company": "CASH PGM FOR USD",
    "assetClass": "CA",
    "assetClassDesc": "Cash",
    "accountName": "H+M jt Acct",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 1,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 200,
    "rgl": 400
  },
  {
    "instrId": "__CASH",
    "localSymbol": "__USD",
    "instrumentName": "CASH PGM FOR USD",
    "purchaseDate": "2024-09-11T00:00:00.000Z",
    "marketValue": 1113.302728,
    "aggTaxlotId": "0e48afbc-7016-11ef-a17f-0260bab84379",
    "shares": 1113.302728,
    "weight": 0.35173734376391647,
    "orgPurchasePrice": 1,
    "company": "CASH PGM FOR USD",
    "assetClass": "CA",
    "assetClassDesc": "Cash",
    "accountName": "Callan GST Irrev Tr",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 1,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 201,
    "rgl": 402
  }
]

const scenarioData = [
  {
    "instrId": "AAON-XNAS",
    "localSymbol": "AAON",
    "instrumentName": "AAON INC",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 3883.4400939941406,
    "aggTaxlotId": "0e43eb39-7016-11ef-a17f-0260bab84379",
    "shares": 44,
    "weight": 1.2269357372200684,
    "orgPurchasePrice": 71.7719,
    "company": "AAON INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Marry Callan",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 88.26,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 0,
    "rgl": 0
  },
  {
    "instrId": "AAON-XNAS",
    "localSymbol": "AAON",
    "instrumentName": "AAON INC",
    "purchaseDate": "2024-07-17T00:00:00.000Z",
    "marketValue": 88.26000213623047,
    "aggTaxlotId": "0e44698d-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.02788490311863792,
    "orgPurchasePrice": 91.3998,
    "company": "AAON INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Marry Callan",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 88.26,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 1,
    "rgl": 2
  },
  {
    "instrId": "AAPL-XNAS",
    "localSymbol": "AAPL",
    "instrumentName": "APPLE INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 6383.190017700195,
    "aggTaxlotId": "0e449602-7016-11ef-a17f-0260bab84379",
    "shares": 29,
    "weight": 2.0167078056115337,
    "orgPurchasePrice": 184.57,
    "company": "APPLE INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 220.11,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 2,
    "rgl": 4
  },
  {
    "instrId": "AAPL-XNAS",
    "localSymbol": "AAPL",
    "instrumentName": "APPLE INC",
    "purchaseDate": "2024-03-19T00:00:00.000Z",
    "marketValue": 2421.210006713867,
    "aggTaxlotId": "0e449904-7016-11ef-a17f-0260bab84379",
    "shares": 11,
    "weight": 0.7649581331629954,
    "orgPurchasePrice": 175.6193,
    "company": "APPLE INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 220.11,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 3,
    "rgl": 6
  },
  {
    "instrId": "AAPL-XNAS",
    "localSymbol": "AAPL",
    "instrumentName": "APPLE INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 5722.860015869141,
    "aggTaxlotId": "0e449ade-7016-11ef-a17f-0260bab84379",
    "shares": 26,
    "weight": 1.808082860203444,
    "orgPurchasePrice": 195.655,
    "company": "APPLE INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 220.11,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 4,
    "rgl": 8
  },
  {
    "instrId": "ABNB-XNAS",
    "localSymbol": "ABNB",
    "instrumentName": "AIRBNB INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 1841.9200439453125,
    "aggTaxlotId": "0e449c7f-7016-11ef-a17f-0260bab84379",
    "shares": 16,
    "weight": 0.5819370126279261,
    "orgPurchasePrice": 138.99,
    "company": "AIRBNB A",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 115.12,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 5,
    "rgl": 10
  },
  {
    "instrId": "ABNB-XNAS",
    "localSymbol": "ABNB",
    "instrumentName": "AIRBNB INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 920.9600219726562,
    "aggTaxlotId": "0e449e52-7016-11ef-a17f-0260bab84379",
    "shares": 8,
    "weight": 0.29096850631396304,
    "orgPurchasePrice": 145.68,
    "company": "AIRBNB A",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 115.12,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 6,
    "rgl": 12
  },
  {
    "instrId": "ABNB-XNAS",
    "localSymbol": "ABNB",
    "instrumentName": "AIRBNB INC",
    "purchaseDate": "2024-07-01T00:00:00.000Z",
    "marketValue": 230.2400054931641,
    "aggTaxlotId": "0e449fd6-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.07274212657849076,
    "orgPurchasePrice": 150.345,
    "company": "AIRBNB A",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 115.12,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 7,
    "rgl": 14
  },
  {
    "instrId": "ACN-XNYS",
    "localSymbol": "ACN",
    "instrumentName": "ACCENTURE PLC IRELAND",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 3129.300109863281,
    "aggTaxlotId": "0e44a167-7016-11ef-a17f-0260bab84379",
    "shares": 9,
    "weight": 0.98867242556819,
    "orgPurchasePrice": 342.2434,
    "company": "ACCENTURE PLC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 347.7,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 8,
    "rgl": 16
  },
  {
    "instrId": "ACN-XNYS",
    "localSymbol": "ACN",
    "instrumentName": "ACCENTURE PLC IRELAND",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 1390.800048828125,
    "aggTaxlotId": "0e44a32f-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.4394099669191955,
    "orgPurchasePrice": 308.435,
    "company": "ACCENTURE PLC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 347.7,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 9,
    "rgl": 18
  },
  {
    "instrId": "ADBE-XNAS",
    "localSymbol": "ADBE",
    "instrumentName": "ADOBE INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1148.9599609375,
    "aggTaxlotId": "0e44a4c9-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.3630029053079348,
    "orgPurchasePrice": 455.25,
    "company": "ADOBE INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 574.48,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 10,
    "rgl": 20
  },
  {
    "instrId": "ADDYY-OTCM",
    "localSymbol": "ADDYY",
    "instrumentName": "ADIDAS AG ADR",
    "purchaseDate": "2024-07-31T00:00:00.000Z",
    "marketValue": 232.2600250244141,
    "aggTaxlotId": "0e44a782-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.07338033241990599,
    "orgPurchasePrice": 125.515,
    "company": "ADIDAS AG",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 116.13,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 11,
    "rgl": 22
  },
  {
    "instrId": "ADDYY-OTCM",
    "localSymbol": "ADDYY",
    "instrumentName": "ADIDAS AG ADR",
    "purchaseDate": "2024-08-16T00:00:00.000Z",
    "marketValue": 232.2600250244141,
    "aggTaxlotId": "0e44a945-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.07338033241990599,
    "orgPurchasePrice": 119.565,
    "company": "ADIDAS AG",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 116.13,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 12,
    "rgl": 24
  },
  {
    "instrId": "ADDYY-OTCM",
    "localSymbol": "ADDYY",
    "instrumentName": "ADIDAS AG ADR",
    "purchaseDate": "2024-09-09T00:00:00.000Z",
    "marketValue": 232.2600250244141,
    "aggTaxlotId": "0e44aaf1-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.07338033241990599,
    "orgPurchasePrice": 118.535,
    "company": "ADIDAS AG",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 116.13,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 13,
    "rgl": 26
  },
  {
    "instrId": "ADI-XNAS",
    "localSymbol": "ADI",
    "instrumentName": "ANALOG DEVICES INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 874.8800048828125,
    "aggTaxlotId": "0e44ac82-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.2764099658522015,
    "orgPurchasePrice": 235.8,
    "company": "ANALOG DEVICES INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 218.72,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 14,
    "rgl": 28
  },
  {
    "instrId": "AIN-XNYS",
    "localSymbol": "AIN",
    "instrumentName": "ALBANY INTL CORP",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1196.5800170898438,
    "aggTaxlotId": "0e44ae72-7016-11ef-a17f-0260bab84379",
    "shares": 14,
    "weight": 0.3780480063749233,
    "orgPurchasePrice": 87.785,
    "company": "ALBANY INTERNATIONAL CORP.",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Clara Callan",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 85.47,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 15,
    "rgl": 30
  },
  {
    "instrId": "AMD-XNAS",
    "localSymbol": "AMD",
    "instrumentName": "ADVANCED MICRO DEVICES INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1285.5599670410156,
    "aggTaxlotId": "0e44b059-7016-11ef-a17f-0260bab84379",
    "shares": 9,
    "weight": 0.4061603701165411,
    "orgPurchasePrice": 165.3899,
    "company": "ADVANCED MICRO DEVICES INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 142.84,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 16,
    "rgl": 32
  },
  {
    "instrId": "AMZN-XNAS",
    "localSymbol": "AMZN",
    "instrumentName": "AMAZON COM INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 7541.100128173828,
    "aggTaxlotId": "0e44b266-7016-11ef-a17f-0260bab84379",
    "shares": 42,
    "weight": 2.3825384250218,
    "orgPurchasePrice": 181.3,
    "company": "AMAZON.COM INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 179.55,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 17,
    "rgl": 34
  },
  {
    "instrId": "ANET-XNYS",
    "localSymbol": "ANET",
    "instrumentName": "ARISTA NETWORKS INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 1307,
    "aggTaxlotId": "0e44b448-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.412934143371145,
    "orgPurchasePrice": 246.58,
    "company": "ARISTA NETWORKS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 326.75,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 18,
    "rgl": 36
  },
  {
    "instrId": "APO-XNYS",
    "localSymbol": "APO",
    "instrumentName": "APOLLO GLOBAL MGMT INC",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 640.4399871826172,
    "aggTaxlotId": "0e44b603-7016-11ef-a17f-0260bab84379",
    "shares": 6,
    "weight": 0.20234088560664204,
    "orgPurchasePrice": 118.08,
    "company": "APOLLO GLOBAL MANAGEMENT INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 106.74,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 19,
    "rgl": 38
  },
  {
    "instrId": "APP-XNAS",
    "localSymbol": "APP",
    "instrumentName": "APPLOVIN CORP",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 862.3000335693359,
    "aggTaxlotId": "0e44b7e2-7016-11ef-a17f-0260bab84379",
    "shares": 10,
    "weight": 0.2724354442929329,
    "orgPurchasePrice": 79.8488,
    "company": "APPLOVIN CORP COM CL A",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 86.23,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 20,
    "rgl": 40
  },
  {
    "instrId": "ASML-XNAS",
    "localSymbol": "ASML",
    "instrumentName": "ASML HOLDING N V ADR",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1502.760009765625,
    "aggTaxlotId": "0e44b9b5-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.4747826452371705,
    "orgPurchasePrice": 1037.455,
    "company": "ASML HOLDING NV",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 751.38,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 21,
    "rgl": 42
  },
  {
    "instrId": "ATDRY-OTCM",
    "localSymbol": "ATDRY",
    "instrumentName": "AUTO TRADER GROUP PLC ADR",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 3854.4598960876474,
    "aggTaxlotId": "0e44bb96-7016-11ef-a17f-0260bab84379",
    "shares": 1362,
    "weight": 1.2177797210018249,
    "orgPurchasePrice": 2.625,
    "company": "AUTO TRADER GROUP PLC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Marry Callan",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 2.83,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 22,
    "rgl": 44
  },
  {
    "instrId": "AVGO-XNAS",
    "localSymbol": "AVGO",
    "instrumentName": "BROADCOM LTD",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 4446.300201416016,
    "aggTaxlotId": "0e44bd75-7016-11ef-a17f-0260bab84379",
    "shares": 30,
    "weight": 1.4047660021749595,
    "orgPurchasePrice": 1405.8878,
    "company": "BROADCOM INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 148.21,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 23,
    "rgl": 46
  },
  {
    "instrId": "AWI-XNYS",
    "localSymbol": "AWI",
    "instrumentName": "ARMSTRONG WORLD INDS INC NEW",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 2069.75,
    "aggTaxlotId": "0e44bf54-7016-11ef-a17f-0260bab84379",
    "shares": 17,
    "weight": 0.6539177071479935,
    "orgPurchasePrice": 115.656,
    "company": "ARMSTRONG WORLD INDUSTRIES INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Clara Callan",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 121.75,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 24,
    "rgl": 48
  },
  {
    "instrId": "AZN-XNAS",
    "localSymbol": "AZN",
    "instrumentName": "ASTRAZENECA PLC ADR",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1136.2400512695312,
    "aggTaxlotId": "0e44c10e-7016-11ef-a17f-0260bab84379",
    "shares": 14,
    "weight": 0.35898417156462875,
    "orgPurchasePrice": 80.335,
    "company": "ASTRAZENECA PLC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 81.16,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 25,
    "rgl": 50
  },
  {
    "instrId": "AZPN-XNAS",
    "localSymbol": "AZPN",
    "instrumentName": "ASPEN TECHNOLOGY INC",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 1137.6499938964844,
    "aggTaxlotId": "0e44c396-7016-11ef-a17f-0260bab84379",
    "shares": 5,
    "weight": 0.359429629445932,
    "orgPurchasePrice": 208.43,
    "company": "ASPEN TECHNOLOGY INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Marry Callan",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 227.53,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 26,
    "rgl": 52
  },
  {
    "instrId": "AZPN-XNAS",
    "localSymbol": "AZPN",
    "instrumentName": "ASPEN TECHNOLOGY INC",
    "purchaseDate": "2024-08-08T00:00:00.000Z",
    "marketValue": 227.52999877929688,
    "aggTaxlotId": "0e44c5ea-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.07188592588918641,
    "orgPurchasePrice": 209.7434,
    "company": "ASPEN TECHNOLOGY INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Marry Callan",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 227.53,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 27,
    "rgl": 54
  },
  {
    "instrId": "AZTA-XNAS",
    "localSymbol": "AZTA",
    "instrumentName": "AZENTA INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1421.0400047302246,
    "aggTaxlotId": "0e44c7b4-7016-11ef-a17f-0260bab84379",
    "shares": 31,
    "weight": 0.4489639916215786,
    "orgPurchasePrice": 52.41,
    "company": "AZENTA INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Clara Callan",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 45.84,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 28,
    "rgl": 56
  },
  {
    "instrId": "BA-XNYS",
    "localSymbol": "BA",
    "instrumentName": "BOEING CO",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 640.280029296875,
    "aggTaxlotId": "0e44c995-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.20229034844327215,
    "orgPurchasePrice": 189.955,
    "company": "BOEING CO",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 160.07,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 29,
    "rgl": 58
  },
  {
    "instrId": "BA-XNYS",
    "localSymbol": "BA",
    "instrumentName": "BOEING CO",
    "purchaseDate": "2024-07-01T00:00:00.000Z",
    "marketValue": 480.21002197265625,
    "aggTaxlotId": "0e44cfa9-7016-11ef-a17f-0260bab84379",
    "shares": 3,
    "weight": 0.15171776133245413,
    "orgPurchasePrice": 187.365,
    "company": "BOEING CO",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 160.07,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 30,
    "rgl": 60
  },
  {
    "instrId": "BBY-XNYS",
    "localSymbol": "BBY",
    "instrumentName": "BEST BUY INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 289.1100082397461,
    "aggTaxlotId": "0e44d1a4-7016-11ef-a17f-0260bab84379",
    "shares": 3,
    "weight": 0.09134154062165588,
    "orgPurchasePrice": 75.0298,
    "company": "BEST BUY CO INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 96.37,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 31,
    "rgl": 62
  },
  {
    "instrId": "BK-XNYS",
    "localSymbol": "BK",
    "instrumentName": "BANK NEW YORK MELLON CORP",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 202.5300064086914,
    "aggTaxlotId": "0e44d41e-7016-11ef-a17f-0260bab84379",
    "shares": 3,
    "weight": 0.06398741752358493,
    "orgPurchasePrice": 52.7684,
    "company": "BANK OF NEW YORK MELLON CORP (THE)",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 67.51,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 32,
    "rgl": 64
  },
  {
    "instrId": "BMI-XNYS",
    "localSymbol": "BMI",
    "instrumentName": "BADGER METER INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 780.760009765625,
    "aggTaxlotId": "0e44d707-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.24667365402526029,
    "orgPurchasePrice": 189.34,
    "company": "BADGER METER INC.",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Clara Callan",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 195.19,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 33,
    "rgl": 66
  },
  {
    "instrId": "BOH-XNYS",
    "localSymbol": "BOH",
    "instrumentName": "BANK HAWAII CORP",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1525.1999816894531,
    "aggTaxlotId": "0e44d959-7016-11ef-a17f-0260bab84379",
    "shares": 24,
    "weight": 0.4818723396393423,
    "orgPurchasePrice": 56.5455,
    "company": "BANK OF HAWAII CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Clara Callan",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 63.55,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 34,
    "rgl": 68
  },
  {
    "instrId": "CAH-XNYS",
    "localSymbol": "CAH",
    "instrumentName": "CARDINAL HEALTH INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 1029.3300247192385,
    "aggTaxlotId": "0e44db7c-7016-11ef-a17f-0260bab84379",
    "shares": 9,
    "weight": 0.3252069716936788,
    "orgPurchasePrice": 102.7545,
    "company": "CARDINAL HEALTH INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 114.37,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 35,
    "rgl": 70
  },
  {
    "instrId": "CAH-XNYS",
    "localSymbol": "CAH",
    "instrumentName": "CARDINAL HEALTH INC",
    "purchaseDate": "2024-03-19T00:00:00.000Z",
    "marketValue": 114.37000274658205,
    "aggTaxlotId": "0e44dd8f-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.03613410796596432,
    "orgPurchasePrice": 112.395,
    "company": "CARDINAL HEALTH INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 114.37,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 36,
    "rgl": 72
  },
  {
    "instrId": "CAKE-XNAS",
    "localSymbol": "CAKE",
    "instrumentName": "CHEESECAKE FACTORY INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1585.7600402832031,
    "aggTaxlotId": "0e44df9a-7016-11ef-a17f-0260bab84379",
    "shares": 44,
    "weight": 0.5010057106553458,
    "orgPurchasePrice": 39.63,
    "company": "CHEESECAKE FACTORY INC (THE)",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Clara Callan",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 36.04,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 37,
    "rgl": 74
  },
  {
    "instrId": "CBRE-XNYS",
    "localSymbol": "CBRE",
    "instrumentName": "CBRE GROUP INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 233.6000061035156,
    "aggTaxlotId": "0e44e1ac-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.07380368661962469,
    "orgPurchasePrice": 86.51,
    "company": "CBRE GROUP INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 116.8,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 38,
    "rgl": 76
  },
  {
    "instrId": "CDNS-XNAS",
    "localSymbol": "CDNS",
    "instrumentName": "CADENCE DESIGN SYSTEM INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1023.4000244140624,
    "aggTaxlotId": "0e44e3c7-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.32333344484118576,
    "orgPurchasePrice": 295.78,
    "company": "CADENCE DESIGN SYSTEMS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 255.85,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 39,
    "rgl": 78
  },
  {
    "instrId": "CDNS-XNAS",
    "localSymbol": "CDNS",
    "instrumentName": "CADENCE DESIGN SYSTEM INC",
    "purchaseDate": "2024-07-01T00:00:00.000Z",
    "marketValue": 255.8500061035156,
    "aggTaxlotId": "0e44e5d6-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.08083336121029644,
    "orgPurchasePrice": 310.425,
    "company": "CADENCE DESIGN SYSTEMS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 255.85,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 40,
    "rgl": 80
  },
  {
    "instrId": "CHH-XNYS",
    "localSymbol": "CHH",
    "instrumentName": "CHOICE HOTELS INTL INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1451.7600402832031,
    "aggTaxlotId": "0e44e7af-7016-11ef-a17f-0260bab84379",
    "shares": 12,
    "weight": 0.45866969289579457,
    "orgPurchasePrice": 112.11,
    "company": "CHOICE HOTELS INTERNATIONAL INC.",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Clara Callan",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 120.98,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 41,
    "rgl": 82
  },
  {
    "instrId": "CNC-XNYS",
    "localSymbol": "CNC",
    "instrumentName": "CENTENE CORP DEL",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 1704,
    "aggTaxlotId": "0e44e9b8-7016-11ef-a17f-0260bab84379",
    "shares": 24,
    "weight": 0.5383624944945916,
    "orgPurchasePrice": 77.71,
    "company": "CENTENE CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 71,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 42,
    "rgl": 84
  },
  {
    "instrId": "COIN-XNAS",
    "localSymbol": "COIN",
    "instrumentName": "COINBASE GLOBAL INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 950.7600402832032,
    "aggTaxlotId": "0e44ebd9-7016-11ef-a17f-0260bab84379",
    "shares": 6,
    "weight": 0.30038353694403963,
    "orgPurchasePrice": 153.42,
    "company": "COINBASE GLOBAL A",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 158.46,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 43,
    "rgl": 86
  },
  {
    "instrId": "ABC-XNYS",
    "localSymbol": "COR",
    "instrumentName": "CENCORA INC.",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 1187.6499938964844,
    "aggTaxlotId": "0e44edf0-7016-11ef-a17f-0260bab84379",
    "shares": 5,
    "weight": 0.37522665099800334,
    "orgPurchasePrice": 212.5904,
    "company": "CENCORA INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 237.53,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 44,
    "rgl": 88
  },
  {
    "instrId": "ABC-XNYS",
    "localSymbol": "COR",
    "instrumentName": "CENCORA INC.",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 237.52999877929688,
    "aggTaxlotId": "0e44eff5-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.07504533019960068,
    "orgPurchasePrice": 239.3773,
    "company": "CENCORA INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 237.53,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 45,
    "rgl": 90
  },
  {
    "instrId": "COST-XNAS",
    "localSymbol": "COST",
    "instrumentName": "COSTCO WHSL CORP NEW",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 8942.899780273438,
    "aggTaxlotId": "0e44f1cd-7016-11ef-a17f-0260bab84379",
    "shares": 10,
    "weight": 2.8254236113398723,
    "orgPurchasePrice": 664.95,
    "company": "COSTCO WHOLESALE CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 894.29,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 46,
    "rgl": 92
  },
  {
    "instrId": "COST-XNAS",
    "localSymbol": "COST",
    "instrumentName": "COSTCO WHSL CORP NEW",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 2682.8699340820312,
    "aggTaxlotId": "0e44f426-7016-11ef-a17f-0260bab84379",
    "shares": 3,
    "weight": 0.8476270834019617,
    "orgPurchasePrice": 832.1548,
    "company": "COSTCO WHOLESALE CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 894.29,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 47,
    "rgl": 94
  },
  {
    "instrId": "CRM-XNYS",
    "localSymbol": "CRM",
    "instrumentName": "SALESFORCE INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 3200.080047607422,
    "aggTaxlotId": "0e44f5e2-7016-11ef-a17f-0260bab84379",
    "shares": 13,
    "weight": 1.011034669608159,
    "orgPurchasePrice": 262.4813,
    "company": "SALESFORCE INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 246.16,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 48,
    "rgl": 96
  },
  {
    "instrId": "CRM-XNYS",
    "localSymbol": "CRM",
    "instrumentName": "SALESFORCE INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 738.4800109863281,
    "aggTaxlotId": "0e44f824-7016-11ef-a17f-0260bab84379",
    "shares": 3,
    "weight": 0.2333156929864982,
    "orgPurchasePrice": 236.44,
    "company": "SALESFORCE INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 246.16,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 49,
    "rgl": 98
  },
  {
    "instrId": "CRWD-XNAS",
    "localSymbol": "CRWD",
    "instrumentName": "CROWDSTRIKE HLDGS INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 743.2200164794922,
    "aggTaxlotId": "0e44fa1b-7016-11ef-a17f-0260bab84379",
    "shares": 3,
    "weight": 0.2348132523651472,
    "orgPurchasePrice": 341.79,
    "company": "CROWDSTRIKE HLDGS INC CL A",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 247.74,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 50,
    "rgl": 100
  },
  {
    "instrId": "CRWD-XNAS",
    "localSymbol": "CRWD",
    "instrumentName": "CROWDSTRIKE HLDGS INC",
    "purchaseDate": "2024-08-16T00:00:00.000Z",
    "marketValue": 247.7400054931641,
    "aggTaxlotId": "0e44fc04-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.07827108412171574,
    "orgPurchasePrice": 261.44,
    "company": "CROWDSTRIKE HLDGS INC CL A",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 247.74,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 51,
    "rgl": 102
  },
  {
    "instrId": "CSWI-XNAS",
    "localSymbol": "CSWI",
    "instrumentName": "CSW INDUSTRIALS INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 952.8599853515624,
    "aggTaxlotId": "0e44fdb9-7016-11ef-a17f-0260bab84379",
    "shares": 3,
    "weight": 0.30104699449410044,
    "orgPurchasePrice": 255.448,
    "company": "CSW INDUSTRIALS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Clara Callan",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 317.62,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 52,
    "rgl": 104
  },
  {
    "instrId": "DAVA-XNYS",
    "localSymbol": "DAVA",
    "instrumentName": "ENDAVA PLC ADR",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 1354.3200302124023,
    "aggTaxlotId": "0e44ffc9-7016-11ef-a17f-0260bab84379",
    "shares": 44,
    "weight": 0.4278844541133448,
    "orgPurchasePrice": 27.44,
    "company": "ENDAVA PLC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Marry Callan",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 30.78,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 53,
    "rgl": 106
  },
  {
    "instrId": "DDOG-XNAS",
    "localSymbol": "DDOG",
    "instrumentName": "DATADOG INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 540.6000137329102,
    "aggTaxlotId": "0e4501b5-7016-11ef-a17f-0260bab84379",
    "shares": 5,
    "weight": 0.17079740135977697,
    "orgPurchasePrice": 110.5,
    "company": "DATADOG INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 108.12,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 54,
    "rgl": 108
  },
  {
    "instrId": "DDOG-XNAS",
    "localSymbol": "DDOG",
    "instrumentName": "DATADOG INC",
    "purchaseDate": "2024-07-01T00:00:00.000Z",
    "marketValue": 108.12000274658205,
    "aggTaxlotId": "0e4503a4-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.034159480271955395,
    "orgPurchasePrice": 131.42,
    "company": "DATADOG INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 108.12,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 55,
    "rgl": 110
  },
  {
    "instrId": "DFH-XNYS",
    "localSymbol": "DFH",
    "instrumentName": "DREAM FINDERS HOMES INC",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 1438.420021057129,
    "aggTaxlotId": "0e450698-7016-11ef-a17f-0260bab84379",
    "shares": 46,
    "weight": 0.4544550414714078,
    "orgPurchasePrice": 27.06,
    "company": "DREAM FINDERS HOMES INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Marry Callan",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 31.27,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 56,
    "rgl": 112
  },
  {
    "instrId": "DIS-XNYS",
    "localSymbol": "DIS",
    "instrumentName": "DISNEY WALT CO",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 972.400016784668,
    "aggTaxlotId": "0e45089c-7016-11ef-a17f-0260bab84379",
    "shares": 11,
    "weight": 0.30722048044763883,
    "orgPurchasePrice": 101.475,
    "company": "WALT DISNEY CO (THE)",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 88.4,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 57,
    "rgl": 114
  },
  {
    "instrId": "DIS-XNYS",
    "localSymbol": "DIS",
    "instrumentName": "DISNEY WALT CO",
    "purchaseDate": "2024-07-31T00:00:00.000Z",
    "marketValue": 265.2000045776367,
    "aggTaxlotId": "0e450a91-7016-11ef-a17f-0260bab84379",
    "shares": 3,
    "weight": 0.08378740375844694,
    "orgPurchasePrice": 94.8399,
    "company": "WALT DISNEY CO (THE)",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 88.4,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 58,
    "rgl": 116
  },
  {
    "instrId": "DVN-XNYS",
    "localSymbol": "DVN",
    "instrumentName": "DEVON ENERGY CORP NEW",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 442.5299949645996,
    "aggTaxlotId": "0e450cf2-7016-11ef-a17f-0260bab84379",
    "shares": 11,
    "weight": 0.1398131173578761,
    "orgPurchasePrice": 44.705,
    "company": "DEVON ENERGY CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 40.23,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 59,
    "rgl": 118
  },
  {
    "instrId": "DXCM-XNAS",
    "localSymbol": "DXCM",
    "instrumentName": "DEXCOM INC",
    "purchaseDate": "2024-07-31T00:00:00.000Z",
    "marketValue": 274.20001220703125,
    "aggTaxlotId": "0e450f69-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.086630870048254,
    "orgPurchasePrice": 67.53,
    "company": "DEXCOM INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 68.55,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 60,
    "rgl": 120
  },
  {
    "instrId": "DXCM-XNAS",
    "localSymbol": "DXCM",
    "instrumentName": "DEXCOM INC",
    "purchaseDate": "2024-08-16T00:00:00.000Z",
    "marketValue": 274.20001220703125,
    "aggTaxlotId": "0e451135-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.086630870048254,
    "orgPurchasePrice": 74.545,
    "company": "DEXCOM INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 68.55,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 61,
    "rgl": 122
  },
  {
    "instrId": "EOG-XNYS",
    "localSymbol": "EOG",
    "instrumentName": "EOG RES INC",
    "purchaseDate": "2024-03-19T00:00:00.000Z",
    "marketValue": 475.8800048828125,
    "aggTaxlotId": "0e4512dc-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.15034973386667216,
    "orgPurchasePrice": 124.74,
    "company": "EOG RESOURCES INC.",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 118.97,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 62,
    "rgl": 124
  },
  {
    "instrId": "EOG-XNYS",
    "localSymbol": "EOG",
    "instrumentName": "EOG RES INC",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 237.94000244140625,
    "aggTaxlotId": "0e4514bd-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.07517486693333608,
    "orgPurchasePrice": 124.565,
    "company": "EOG RESOURCES INC.",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 118.97,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 63,
    "rgl": 126
  },
  {
    "instrId": "EPAC-XNYS",
    "localSymbol": "EPAC",
    "instrumentName": "ENERPAC TOOL GROUP CORP",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 2463.299903869629,
    "aggTaxlotId": "0e451661-7016-11ef-a17f-0260bab84379",
    "shares": 63,
    "weight": 0.7782560334128761,
    "orgPurchasePrice": 38.02,
    "company": "ENERPAC TOOL GROUP CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Marry Callan",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 39.1,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 64,
    "rgl": 128
  },
  {
    "instrId": "ETN-XNYS",
    "localSymbol": "ETN",
    "instrumentName": "EATON CORP PLC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 864.8999633789062,
    "aggTaxlotId": "0e451820-7016-11ef-a17f-0260bab84379",
    "shares": 3,
    "weight": 0.27325686723764603,
    "orgPurchasePrice": 326.4819,
    "company": "EATON CORPORATION PLC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 288.3,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 65,
    "rgl": 130
  },
  {
    "instrId": "EVTC-XNYS",
    "localSymbol": "EVTC",
    "instrumentName": "EVERTEC INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1614.0599327087402,
    "aggTaxlotId": "0e451a04-7016-11ef-a17f-0260bab84379",
    "shares": 49,
    "weight": 0.509946790866696,
    "orgPurchasePrice": 33.6661,
    "company": "EVERTEC INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Clara Callan",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 32.94,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 66,
    "rgl": 132
  },
  {
    "instrId": "EXPD-XNAS",
    "localSymbol": "EXPD",
    "instrumentName": "EXPEDITORS INTL WASH INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 487.0400085449219,
    "aggTaxlotId": "0e451bda-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.1538756302341029,
    "orgPurchasePrice": 122.745,
    "company": "EXPEDITORS INTERNATIONAL OF WASHINGTON INC.",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 121.76,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 67,
    "rgl": 134
  },
  {
    "instrId": "EXPD-XNAS",
    "localSymbol": "EXPD",
    "instrumentName": "EXPEDITORS INTL WASH INC",
    "purchaseDate": "2024-03-19T00:00:00.000Z",
    "marketValue": 121.76000213623048,
    "aggTaxlotId": "0e451daa-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.03846890755852572,
    "orgPurchasePrice": 117.31,
    "company": "EXPEDITORS INTERNATIONAL OF WASHINGTON INC.",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 121.76,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 68,
    "rgl": 136
  },
  {
    "instrId": "FAST-XNAS",
    "localSymbol": "FAST",
    "instrumentName": "FASTENAL CO",
    "purchaseDate": "2024-03-19T00:00:00.000Z",
    "marketValue": 1648.0799560546875,
    "aggTaxlotId": "0e451f4c-7016-11ef-a17f-0260bab84379",
    "shares": 24,
    "weight": 0.520695091706654,
    "orgPurchasePrice": 76.0492,
    "company": "FASTENAL CO",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 68.67,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 69,
    "rgl": 138
  },
  {
    "instrId": "FAST-XNAS",
    "localSymbol": "FAST",
    "instrumentName": "FASTENAL CO",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 137.33999633789062,
    "aggTaxlotId": "0e452132-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.04339125764222117,
    "orgPurchasePrice": 64.25,
    "company": "FASTENAL CO",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 68.67,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 70,
    "rgl": 140
  },
  {
    "instrId": "FDS-XNYS",
    "localSymbol": "FDS",
    "instrumentName": "FACTSET RESH SYS INC",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 1322.1900329589844,
    "aggTaxlotId": "0e4522df-7016-11ef-a17f-0260bab84379",
    "shares": 3,
    "weight": 0.41773328893174005,
    "orgPurchasePrice": 407.4594,
    "company": "FACTSET RESEARCH SYSTEMS INC.",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Marry Callan",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 440.73,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 71,
    "rgl": 142
  },
  {
    "instrId": "FFIN-XNAS",
    "localSymbol": "FFIN",
    "instrumentName": "FIRST FINL BANKSHARES INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1296.7200164794922,
    "aggTaxlotId": "0e452acd-7016-11ef-a17f-0260bab84379",
    "shares": 36,
    "weight": 0.409686280946577,
    "orgPurchasePrice": 28.8366,
    "company": "FIRST FINANCIAL BANKSHARES INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Clara Callan",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 36.02,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 72,
    "rgl": 144
  },
  {
    "instrId": "FFIN-XNAS",
    "localSymbol": "FFIN",
    "instrumentName": "FIRST FINL BANKSHARES INC",
    "purchaseDate": "2024-07-17T00:00:00.000Z",
    "marketValue": 432.24000549316406,
    "aggTaxlotId": "0e452ec3-7016-11ef-a17f-0260bab84379",
    "shares": 12,
    "weight": 0.13656209364885902,
    "orgPurchasePrice": 34.79,
    "company": "FIRST FINANCIAL BANKSHARES INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Clara Callan",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 36.02,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 73,
    "rgl": 146
  },
  {
    "instrId": "FIZZ-XNAS",
    "localSymbol": "FIZZ",
    "instrumentName": "NATIONAL BEVERAGE CORP",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1427.8399658203125,
    "aggTaxlotId": "0e45338c-7016-11ef-a17f-0260bab84379",
    "shares": 32,
    "weight": 0.4511123742594459,
    "orgPurchasePrice": 47.0971,
    "company": "NATIONAL BEVERAGE CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Clara Callan",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 44.62,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 74,
    "rgl": 148
  },
  {
    "instrId": "FOXF-XNAS",
    "localSymbol": "FOXF",
    "instrumentName": "FOX FACTORY HLDG CORP",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 1505.699935913086,
    "aggTaxlotId": "0e4537e6-7016-11ef-a17f-0260bab84379",
    "shares": 42,
    "weight": 0.4757114867714293,
    "orgPurchasePrice": 45.6582,
    "company": "FOX FACTORY HOLDING CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Marry Callan",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 35.85,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 75,
    "rgl": 150
  },
  {
    "instrId": "FUL-XNYS",
    "localSymbol": "FUL",
    "instrumentName": "FULLER H B CO",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1896,
    "aggTaxlotId": "0e458608-7016-11ef-a17f-0260bab84379",
    "shares": 24,
    "weight": 0.5990230572545455,
    "orgPurchasePrice": 77.845,
    "company": "H.B. FULLER CO.",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Clara Callan",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 79,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 76,
    "rgl": 152
  },
  {
    "instrId": "GE-XNYS",
    "localSymbol": "GE",
    "instrumentName": "GE AEROSPACE TRADING",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 500.9399871826172,
    "aggTaxlotId": "0e458830-7016-11ef-a17f-0260bab84379",
    "shares": 3,
    "weight": 0.15826719547636295,
    "orgPurchasePrice": 162.8075,
    "company": "GE AEROSPACE",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 166.98,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 77,
    "rgl": 154
  },
  {
    "instrId": "GE-XNYS",
    "localSymbol": "GE",
    "instrumentName": "GE AEROSPACE TRADING",
    "purchaseDate": "2024-07-01T00:00:00.000Z",
    "marketValue": 333.9599914550781,
    "aggTaxlotId": "0e458a29-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.10551146365090866,
    "orgPurchasePrice": 157.575,
    "company": "GE AEROSPACE",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 166.98,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 78,
    "rgl": 156
  },
  {
    "instrId": "GOOG-XNAS",
    "localSymbol": "GOOG",
    "instrumentName": "ALPHABET INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 3150.2098846435547,
    "aggTaxlotId": "0e458bdf-7016-11ef-a17f-0260bab84379",
    "shares": 21,
    "weight": 0.9952786688252486,
    "orgPurchasePrice": 176.795,
    "company": "ALPHABET INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 150.01,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 79,
    "rgl": 158
  },
  {
    "instrId": "GOOG-XNAS",
    "localSymbol": "GOOG",
    "instrumentName": "ALPHABET INC",
    "purchaseDate": "2024-07-01T00:00:00.000Z",
    "marketValue": 150.00999450683594,
    "aggTaxlotId": "0e458db8-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.04739422232501184,
    "orgPurchasePrice": 184.405,
    "company": "ALPHABET INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 150.01,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 80,
    "rgl": 160
  },
  {
    "instrId": "GOOG-XNAS",
    "localSymbol": "GOOG",
    "instrumentName": "ALPHABET INC",
    "purchaseDate": "2024-07-31T00:00:00.000Z",
    "marketValue": 600.0399780273438,
    "aggTaxlotId": "0e458f72-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.18957688930004732,
    "orgPurchasePrice": 173.348,
    "company": "ALPHABET INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 150.01,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 81,
    "rgl": 162
  },
  {
    "instrId": "GS-XNYS",
    "localSymbol": "GS",
    "instrumentName": "GOLDMAN SACHS GROUP INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 934.260009765625,
    "aggTaxlotId": "0e459122-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.29517051019011936,
    "orgPurchasePrice": 461.55,
    "company": "GOLDMAN SACHS GROUP INC (THE)",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 467.13,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 82,
    "rgl": 164
  },
  {
    "instrId": "GSHD-XNAS",
    "localSymbol": "GSHD",
    "instrumentName": "GOOSEHEAD INS INC",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 2207.789909362793,
    "aggTaxlotId": "0e4592f3-7016-11ef-a17f-0260bab84379",
    "shares": 27,
    "weight": 0.6975300956129937,
    "orgPurchasePrice": 62.545,
    "company": "GOOSEHEAD INSURANCE INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Marry Callan",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 81.77,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 83,
    "rgl": 166
  },
  {
    "instrId": "GTY-XNYS",
    "localSymbol": "GTY",
    "instrumentName": "GETTY RLTY CORP NEW",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1333.5,
    "aggTaxlotId": "0e4594b1-7016-11ef-a17f-0260bab84379",
    "shares": 42,
    "weight": 0.4213065647937429,
    "orgPurchasePrice": 27.4,
    "company": "GETTY REALTY CORP.",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Clara Callan",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 31.75,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 84,
    "rgl": 168
  },
  {
    "instrId": "GWW-XNYS",
    "localSymbol": "GWW",
    "instrumentName": "GRAINGER W W INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 977.6799926757812,
    "aggTaxlotId": "0e45966e-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.30888863830656543,
    "orgPurchasePrice": 821.16,
    "company": "GRAINGER (W.W.) INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 977.68,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 85,
    "rgl": 170
  },
  {
    "instrId": "HD-XNYS",
    "localSymbol": "HD",
    "instrumentName": "HOME DEPOT INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 741.739990234375,
    "aggTaxlotId": "0e459835-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.23434565223531223,
    "orgPurchasePrice": 330.3564,
    "company": "HOME DEPOT INC. (THE)",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 370.87,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 86,
    "rgl": 172
  },
  {
    "instrId": "HLI-XNYS",
    "localSymbol": "HLI",
    "instrumentName": "HOULIHAN LOKEY INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 3360.2801208496094,
    "aggTaxlotId": "0e4599ea-7016-11ef-a17f-0260bab84379",
    "shares": 22,
    "weight": 1.0616483498011635,
    "orgPurchasePrice": 133.05,
    "company": "HOULIHAN LOKEY INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Clara Callan",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 152.74,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 87,
    "rgl": 174
  },
  {
    "instrId": "HLLY-XNYS",
    "localSymbol": "HLLY",
    "instrumentName": "HOLLEY INC",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 374.3999862670898,
    "aggTaxlotId": "0e459be8-7016-11ef-a17f-0260bab84379",
    "shares": 120,
    "weight": 0.1182880930431287,
    "orgPurchasePrice": 3.655,
    "company": "EMPOWER LTD",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Marry Callan",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 3.12,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 88,
    "rgl": 176
  },
  {
    "instrId": "HLMN-XNAS",
    "localSymbol": "HLMN",
    "instrumentName": "HILLMAN SOLUTIONS CORP",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1984.3199920654297,
    "aggTaxlotId": "0e459d88-7016-11ef-a17f-0260bab84379",
    "shares": 208,
    "weight": 0.6269269136172728,
    "orgPurchasePrice": 9.04,
    "company": "LANDCADIA HOLDINGS III INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Clara Callan",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 9.54,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 89,
    "rgl": 178
  },
  {
    "instrId": "HLT-XNYS",
    "localSymbol": "HLT",
    "instrumentName": "HILTON WORLDWIDE HLDGS INC",
    "purchaseDate": "2024-07-01T00:00:00.000Z",
    "marketValue": 421.2999877929688,
    "aggTaxlotId": "0e459f77-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.13310569974105846,
    "orgPurchasePrice": 212.82,
    "company": "HILTON WORLDWIDE HOLDINGS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 210.65,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 90,
    "rgl": 180
  },
  {
    "instrId": "HLT-XNYS",
    "localSymbol": "HLT",
    "instrumentName": "HILTON WORLDWIDE HLDGS INC",
    "purchaseDate": "2024-09-09T00:00:00.000Z",
    "marketValue": 210.6499938964844,
    "aggTaxlotId": "0e45a119-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.06655284987052923,
    "orgPurchasePrice": 215.52,
    "company": "HILTON WORLDWIDE HOLDINGS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 210.65,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 91,
    "rgl": 182
  },
  {
    "instrId": "HUM-XNYS",
    "localSymbol": "HUM",
    "instrumentName": "HUMANA INC",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 692.8400268554688,
    "aggTaxlotId": "0e45a2a4-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.2188961767274706,
    "orgPurchasePrice": 358.2,
    "company": "HUMANA INC.",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 346.42,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 92,
    "rgl": 184
  },
  {
    "instrId": "IDXX-XNAS",
    "localSymbol": "IDXX",
    "instrumentName": "IDEXX LABS INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 1983.800048828125,
    "aggTaxlotId": "0e45a47c-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.6267626425267616,
    "orgPurchasePrice": 553.975,
    "company": "IDEXX LABORATORIES INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 495.95,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 93,
    "rgl": 186
  },
  {
    "instrId": "ISRG-XNAS",
    "localSymbol": "ISRG",
    "instrumentName": "INTUITIVE SURGICAL INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1452.719970703125,
    "aggTaxlotId": "0e45a65b-7016-11ef-a17f-0260bab84379",
    "shares": 3,
    "weight": 0.4589729737264345,
    "orgPurchasePrice": 417.52,
    "company": "INTUITIVE SURGICAL INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 484.24,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 94,
    "rgl": 188
  },
  {
    "instrId": "JBT-XNYS",
    "localSymbol": "JBT",
    "instrumentName": "JOHN BEAN TECHNOLOGIES CORP",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1443.8100051879885,
    "aggTaxlotId": "0e45a82f-7016-11ef-a17f-0260bab84379",
    "shares": 17,
    "weight": 0.4561579553810179,
    "orgPurchasePrice": 93.97,
    "company": "JOHN BEAN TECHNOLOGIES CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Clara Callan",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 84.93,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 95,
    "rgl": 190
  },
  {
    "instrId": "JKHY-XNAS",
    "localSymbol": "JKHY",
    "instrumentName": "HENRY JACK & ASSOC INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 875.1999664306641,
    "aggTaxlotId": "0e45a9ff-7016-11ef-a17f-0260bab84379",
    "shares": 5,
    "weight": 0.2765110546415464,
    "orgPurchasePrice": 164.2884,
    "company": "HENRY (JACK) & ASSOCIATES INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Clara Callan",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 175.04,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 96,
    "rgl": 192
  },
  {
    "instrId": "KEYS-XNYS",
    "localSymbol": "KEYS",
    "instrumentName": "KEYSIGHT TECHNOLOGIES INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 589.52001953125,
    "aggTaxlotId": "0e461033-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.1862532090782536,
    "orgPurchasePrice": 136.105,
    "company": "KEYSIGHT TECHNOLOGIES INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 147.38,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 97,
    "rgl": 194
  },
  {
    "instrId": "LCII-XNYS",
    "localSymbol": "LCII",
    "instrumentName": "LCI INDS",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 439.2000122070313,
    "aggTaxlotId": "0e4613cb-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.13876104117008944,
    "orgPurchasePrice": 107.025,
    "company": "LCI INDUSTRIES",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Clara Callan",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 109.8,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 98,
    "rgl": 196
  },
  {
    "instrId": "LESL-XNAS",
    "localSymbol": "LESL",
    "instrumentName": "LESLIES INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 603.5800137519836,
    "aggTaxlotId": "0e46159c-7016-11ef-a17f-0260bab84379",
    "shares": 206,
    "weight": 0.19069532971279213,
    "orgPurchasePrice": 5.525,
    "company": "LESLIES INC COM",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Clara Callan",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 2.93,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 99,
    "rgl": 198
  },
  {
    "instrId": "LKFN-XNAS",
    "localSymbol": "LKFN",
    "instrumentName": "LAKELAND FINL CORP",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 772.9200439453125,
    "aggTaxlotId": "0e461786-7016-11ef-a17f-0260bab84379",
    "shares": 12,
    "weight": 0.24419669184464068,
    "orgPurchasePrice": 60.25,
    "company": "LAKELAND FINANCIAL CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Clara Callan",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 64.41,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 100,
    "rgl": 200
  },
  {
    "instrId": "LLY-XNYS",
    "localSymbol": "LLY",
    "instrumentName": "ELI LILLY & CO",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 4506.25,
    "aggTaxlotId": "0e461959-7016-11ef-a17f-0260bab84379",
    "shares": 5,
    "weight": 1.4237065673804303,
    "orgPurchasePrice": 832.1301,
    "company": "ELI LILLY AND CO",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 901.25,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 101,
    "rgl": 202
  },
  {
    "instrId": "LPLA-XNAS",
    "localSymbol": "LPLA",
    "instrumentName": "LPL FINL HLDGS INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 209.47999572753903,
    "aggTaxlotId": "0e461b27-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.06618320014471497,
    "orgPurchasePrice": 232.3667,
    "company": "LPL FINANCIAL HOLDINGS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 209.48,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 102,
    "rgl": 204
  },
  {
    "instrId": "LRLCY-OTCM",
    "localSymbol": "LRLCY",
    "instrumentName": "L OREAL CO ADR",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 660,
    "aggTaxlotId": "0e461ede-7016-11ef-a17f-0260bab84379",
    "shares": 8,
    "weight": 0.2085206844873418,
    "orgPurchasePrice": 99.39,
    "company": "LOREAL SA",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 82.5,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 103,
    "rgl": 206
  },
  {
    "instrId": "LSTR-XNAS",
    "localSymbol": "LSTR",
    "instrumentName": "LANDSTAR SYS INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1816.000061035156,
    "aggTaxlotId": "0e4620d0-7016-11ef-a17f-0260bab84379",
    "shares": 10,
    "weight": 0.573747842054705,
    "orgPurchasePrice": 180.21,
    "company": "LANDSTAR SYSTEM INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Clara Callan",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 181.6,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 104,
    "rgl": 208
  },
  {
    "instrId": "MA-XNYS",
    "localSymbol": "MA",
    "instrumentName": "MASTERCARD INCORPORATED",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 4880.199890136719,
    "aggTaxlotId": "0e4622a9-7016-11ef-a17f-0260bab84379",
    "shares": 10,
    "weight": 1.5418524568581191,
    "orgPurchasePrice": 422.4875,
    "company": "MASTERCARD INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 488.02,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 105,
    "rgl": 210
  },
  {
    "instrId": "MA-XNYS",
    "localSymbol": "MA",
    "instrumentName": "MASTERCARD INCORPORATED",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 2928.1199340820312,
    "aggTaxlotId": "0e462465-7016-11ef-a17f-0260bab84379",
    "shares": 6,
    "weight": 0.9251114741148716,
    "orgPurchasePrice": 446.12,
    "company": "MASTERCARD INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 488.02,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 106,
    "rgl": 212
  },
  {
    "instrId": "MA-XNYS",
    "localSymbol": "MA",
    "instrumentName": "MASTERCARD INCORPORATED",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 488.0199890136719,
    "aggTaxlotId": "0e4625eb-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.15418524568581196,
    "orgPurchasePrice": 457.62,
    "company": "MASTERCARD INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 488.02,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 107,
    "rgl": 214
  },
  {
    "instrId": "MANH-XNAS",
    "localSymbol": "MANH",
    "instrumentName": "MANHATTAN ASSOCIATES INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 518.97998046875,
    "aggTaxlotId": "0e462771-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.16396675873116823,
    "orgPurchasePrice": 204.195,
    "company": "MANHATTAN ASSOCIATES INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 259.49,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 108,
    "rgl": 216
  },
  {
    "instrId": "MANH-XNAS",
    "localSymbol": "MANH",
    "instrumentName": "MANHATTAN ASSOCIATES INC",
    "purchaseDate": "2024-03-19T00:00:00.000Z",
    "marketValue": 259.489990234375,
    "aggTaxlotId": "0e462936-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.08198337936558411,
    "orgPurchasePrice": 247.8926,
    "company": "MANHATTAN ASSOCIATES INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 259.49,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 109,
    "rgl": 218
  },
  {
    "instrId": "MAR-XNAS",
    "localSymbol": "MAR",
    "instrumentName": "MARRIOTT INTL INC NEW",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 451.3399963378906,
    "aggTaxlotId": "0e462aeb-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.14259655298922924,
    "orgPurchasePrice": 233.01,
    "company": "MARRIOTT INTERNATIONAL INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 225.67,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 110,
    "rgl": 220
  },
  {
    "instrId": "MCK-XNYS",
    "localSymbol": "MCK",
    "instrumentName": "MCKESSON CORP",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 3034.3800659179688,
    "aggTaxlotId": "0e462cc1-7016-11ef-a17f-0260bab84379",
    "shares": 6,
    "weight": 0.9586833459696366,
    "orgPurchasePrice": 481.45,
    "company": "MCKESSON CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 505.73,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 111,
    "rgl": 222
  },
  {
    "instrId": "MCO-XNYS",
    "localSymbol": "MCO",
    "instrumentName": "MOODYS CORP",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 968.280029296875,
    "aggTaxlotId": "0e462e99-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.3059188098248602,
    "orgPurchasePrice": 408.8899,
    "company": "MOODY'S CORP.",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 484.14,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 112,
    "rgl": 224
  },
  {
    "instrId": "MDB-XNAS",
    "localSymbol": "MDB",
    "instrumentName": "MONGODB INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 582.97998046875,
    "aggTaxlotId": "0e463051-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.1841869463178196,
    "orgPurchasePrice": 233.865,
    "company": "MONGODB INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 291.49,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 113,
    "rgl": 226
  },
  {
    "instrId": "META-XNAS",
    "localSymbol": "META",
    "instrumentName": "META PLATFORMS INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 5047.900085449219,
    "aggTaxlotId": "0e46320c-7016-11ef-a17f-0260bab84379",
    "shares": 10,
    "weight": 1.594835728850882,
    "orgPurchasePrice": 495.4696,
    "company": "META PLATFORMS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 504.79,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 114,
    "rgl": 228
  },
  {
    "instrId": "META-XNAS",
    "localSymbol": "META",
    "instrumentName": "META PLATFORMS INC",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 4543.110076904297,
    "aggTaxlotId": "0e4633dd-7016-11ef-a17f-0260bab84379",
    "shares": 9,
    "weight": 1.435352155965794,
    "orgPurchasePrice": 506.99,
    "company": "META PLATFORMS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 504.79,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 115,
    "rgl": 230
  },
  {
    "instrId": "META-XNAS",
    "localSymbol": "META",
    "instrumentName": "META PLATFORMS INC",
    "purchaseDate": "2024-07-01T00:00:00.000Z",
    "marketValue": 504.7900085449219,
    "aggTaxlotId": "0e463589-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.15948357288508822,
    "orgPurchasePrice": 501.426,
    "company": "META PLATFORMS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 504.79,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 116,
    "rgl": 232
  },
  {
    "instrId": "MOH-XNYS",
    "localSymbol": "MOH",
    "instrumentName": "MOLINA HEALTHCARE INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 332.3999938964844,
    "aggTaxlotId": "0e46372e-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.10501859734982295,
    "orgPurchasePrice": 378.745,
    "company": "MOLINA HEALTHCARE INC.",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 332.4,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 117,
    "rgl": 234
  },
  {
    "instrId": "MORN-XNAS",
    "localSymbol": "MORN",
    "instrumentName": "MORNINGSTAR INC",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 4002.570190429688,
    "aggTaxlotId": "0e4638fe-7016-11ef-a17f-0260bab84379",
    "shares": 13,
    "weight": 1.2645737512379218,
    "orgPurchasePrice": 293.505,
    "company": "MORNINGSTAR INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Marry Callan",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 307.89,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 118,
    "rgl": 236
  },
  {
    "instrId": "MSFT-XNAS",
    "localSymbol": "MSFT",
    "instrumentName": "MICROSOFT CORP",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 7455.600219726562,
    "aggTaxlotId": "0e463aa2-7016-11ef-a17f-0260bab84379",
    "shares": 18,
    "weight": 2.355525547092968,
    "orgPurchasePrice": 423.02,
    "company": "MICROSOFT CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 414.2,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 119,
    "rgl": 238
  },
  {
    "instrId": "NCNO-XNAS",
    "localSymbol": "NCNO",
    "instrumentName": "NCINO INC",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 2175,
    "aggTaxlotId": "0e463c5b-7016-11ef-a17f-0260bab84379",
    "shares": 75,
    "weight": 0.6871704375151036,
    "orgPurchasePrice": 29.9433,
    "company": "NCINO INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Marry Callan",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 29,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 120,
    "rgl": 240
  },
  {
    "instrId": "NFLX-XNAS",
    "localSymbol": "NFLX",
    "instrumentName": "NETFLIX INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 3368.099975585937,
    "aggTaxlotId": "0e463e2c-7016-11ef-a17f-0260bab84379",
    "shares": 5,
    "weight": 1.0641189580772406,
    "orgPurchasePrice": 650.1037,
    "company": "NETFLIX INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 673.62,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 121,
    "rgl": 242
  },
  {
    "instrId": "NFLX-XNAS",
    "localSymbol": "NFLX",
    "instrumentName": "NETFLIX INC",
    "purchaseDate": "2024-08-16T00:00:00.000Z",
    "marketValue": 673.6199951171875,
    "aggTaxlotId": "0e464014-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.21282379161544812,
    "orgPurchasePrice": 678.75,
    "company": "NETFLIX INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 673.62,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 122,
    "rgl": 244
  },
  {
    "instrId": "NOVT-XNAS",
    "localSymbol": "NOVT",
    "instrumentName": "NOVANTA INC",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 1178.9399871826172,
    "aggTaxlotId": "0e4641b8-7016-11ef-a17f-0260bab84379",
    "shares": 7,
    "weight": 0.3724748077224505,
    "orgPurchasePrice": 162.76,
    "company": "NOVANTA INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Marry Callan",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 168.42,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 123,
    "rgl": 246
  },
  {
    "instrId": "NOW-XNYS",
    "localSymbol": "NOW",
    "instrumentName": "SERVICENOW INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 7741.350219726562,
    "aggTaxlotId": "0e464382-7016-11ef-a17f-0260bab84379",
    "shares": 9,
    "weight": 2.4458055252630557,
    "orgPurchasePrice": 699.125,
    "company": "SERVICENOW INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 860.15,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 124,
    "rgl": 248
  },
  {
    "instrId": "NOW-XNYS",
    "localSymbol": "NOW",
    "instrumentName": "SERVICENOW INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1720.300048828125,
    "aggTaxlotId": "0e464547-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.5435123389473457,
    "orgPurchasePrice": 703.97,
    "company": "SERVICENOW INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 860.15,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 125,
    "rgl": 250
  },
  {
    "instrId": "NRC-XNAS",
    "localSymbol": "NRC",
    "instrumentName": "NATIONAL RESH CORP",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 584.2799835205078,
    "aggTaxlotId": "0e4646d6-7016-11ef-a17f-0260bab84379",
    "shares": 27,
    "weight": 0.18459766984234707,
    "orgPurchasePrice": 27.0972,
    "company": "NATIONAL RESEARCH CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Marry Callan",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 21.64,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 126,
    "rgl": 252
  },
  {
    "instrId": "NU-XNYS",
    "localSymbol": "NU",
    "instrumentName": "NU HLDGS LTD",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 398.1699867248535,
    "aggTaxlotId": "0e46493e-7016-11ef-a17f-0260bab84379",
    "shares": 29,
    "weight": 0.12579799723360946,
    "orgPurchasePrice": 11.685,
    "company": "NU HOLDINGS A",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 13.73,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 127,
    "rgl": 254
  },
  {
    "instrId": "NVDA-XNAS",
    "localSymbol": "NVDA",
    "instrumentName": "NVIDIA CORPORATION",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 1621.4999771118164,
    "aggTaxlotId": "0e464b7d-7016-11ef-a17f-0260bab84379",
    "shares": 15,
    "weight": 0.5122974017023713,
    "orgPurchasePrice": 539.477,
    "company": "NVIDIA CORPORATION",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 108.1,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 128,
    "rgl": 256
  },
  {
    "instrId": "NVDA-XNAS",
    "localSymbol": "NVDA",
    "instrumentName": "NVIDIA CORPORATION",
    "purchaseDate": "2024-03-19T00:00:00.000Z",
    "marketValue": 5404.999923706055,
    "aggTaxlotId": "0e464d38-7016-11ef-a17f-0260bab84379",
    "shares": 50,
    "weight": 1.7076580056745707,
    "orgPurchasePrice": 892.89,
    "company": "NVIDIA CORPORATION",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 108.1,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 129,
    "rgl": 258
  },
  {
    "instrId": "NVDA-XNAS",
    "localSymbol": "NVDA",
    "instrumentName": "NVIDIA CORPORATION",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 7999.399887084961,
    "aggTaxlotId": "0e464ed8-7016-11ef-a17f-0260bab84379",
    "shares": 74,
    "weight": 2.527333848398365,
    "orgPurchasePrice": 1221.03,
    "company": "NVIDIA CORPORATION",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 108.1,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 130,
    "rgl": 260
  },
  {
    "instrId": "NVEC-XNAS",
    "localSymbol": "NVEC",
    "instrumentName": "NVE CORP",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 227.51998901367188,
    "aggTaxlotId": "0e465076-7016-11ef-a17f-0260bab84379",
    "shares": 3,
    "weight": 0.07188276339952021,
    "orgPurchasePrice": 76.8088,
    "company": "NVE CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Marry Callan",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 75.84,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 131,
    "rgl": 262
  },
  {
    "instrId": "NVO-XNYS",
    "localSymbol": "NVO",
    "instrumentName": "NOVO-NORDISK A S ADR",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 2206.259979248047,
    "aggTaxlotId": "0e465239-7016-11ef-a17f-0260bab84379",
    "shares": 17,
    "weight": 0.6970467288330776,
    "orgPurchasePrice": 140.93,
    "company": "NOVO NORDISK A/S",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 129.78,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 132,
    "rgl": 264
  },
  {
    "instrId": "ODFL-XNAS",
    "localSymbol": "ODFL",
    "instrumentName": "OLD DOMINION FREIGHT LINE IN",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 1720.4400329589844,
    "aggTaxlotId": "0e4653e7-7016-11ef-a17f-0260bab84379",
    "shares": 9,
    "weight": 0.5435565655939884,
    "orgPurchasePrice": 388.3,
    "company": "OLD DOMINION FREIGHT LINE INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 191.16,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 133,
    "rgl": 266
  },
  {
    "instrId": "OFLX-XNAS",
    "localSymbol": "OFLX",
    "instrumentName": "OMEGA FLEX INC",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 525.3599815368652,
    "aggTaxlotId": "0e4655b7-7016-11ef-a17f-0260bab84379",
    "shares": 11,
    "weight": 0.1659824590186733,
    "orgPurchasePrice": 60.89,
    "company": "OMEGA FLEX INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Marry Callan",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 47.76,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 134,
    "rgl": 268
  },
  {
    "instrId": "OLLI-XNAS",
    "localSymbol": "OLLI",
    "instrumentName": "OLLIES BARGAIN OUTLET HLDGS",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 1869.4000244140625,
    "aggTaxlotId": "0e46575e-7016-11ef-a17f-0260bab84379",
    "shares": 20,
    "weight": 0.590619049502233,
    "orgPurchasePrice": 85.6,
    "company": "OLLIE'S BARGAIN OUTLET HOLDINGS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Marry Callan",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 93.47,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 135,
    "rgl": 270
  },
  {
    "instrId": "ORLY-XNAS",
    "localSymbol": "ORLY",
    "instrumentName": "OREILLY AUTOMOTIVE INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 2258.860107421875,
    "aggTaxlotId": "0e46591e-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.7136652360011512,
    "orgPurchasePrice": 973.9395,
    "company": "O'REILLY AUTOMOTIVE INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 1129.43,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 136,
    "rgl": 272
  },
  {
    "instrId": "PANW-XNAS",
    "localSymbol": "PANW",
    "instrumentName": "PALO ALTO NETWORKS INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 4866.119812011719,
    "aggTaxlotId": "0e466ff4-7016-11ef-a17f-0260bab84379",
    "shares": 14,
    "weight": 1.5374039909062098,
    "orgPurchasePrice": 299.85,
    "company": "PALO ALTO NETWORKS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 347.58,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 137,
    "rgl": 274
  },
  {
    "instrId": "PANW-XNAS",
    "localSymbol": "PANW",
    "instrumentName": "PALO ALTO NETWORKS INC",
    "purchaseDate": "2024-03-19T00:00:00.000Z",
    "marketValue": 347.5799865722656,
    "aggTaxlotId": "0e467295-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.109814570779015,
    "orgPurchasePrice": 283.79,
    "company": "PALO ALTO NETWORKS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 347.58,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 138,
    "rgl": 276
  },
  {
    "instrId": "PANW-XNAS",
    "localSymbol": "PANW",
    "instrumentName": "PALO ALTO NETWORKS INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 695.1599731445312,
    "aggTaxlotId": "0e46752d-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.21962914155803,
    "orgPurchasePrice": 294.9396,
    "company": "PALO ALTO NETWORKS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 347.58,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 139,
    "rgl": 278
  },
  {
    "instrId": "PGR-XNYS",
    "localSymbol": "PGR",
    "instrumentName": "PROGRESSIVE CORP",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 6228.2501220703125,
    "aggTaxlotId": "0e4676db-7016-11ef-a17f-0260bab84379",
    "shares": 25,
    "weight": 1.9677560282007147,
    "orgPurchasePrice": 166.07,
    "company": "PROGRESSIVE CORP (THE)",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 249.13,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 140,
    "rgl": 280
  },
  {
    "instrId": "PGR-XNYS",
    "localSymbol": "PGR",
    "instrumentName": "PROGRESSIVE CORP",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 996.52001953125,
    "aggTaxlotId": "0e467880-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.31484096451211435,
    "orgPurchasePrice": 212.31,
    "company": "PROGRESSIVE CORP (THE)",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 249.13,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 141,
    "rgl": 282
  },
  {
    "instrId": "PRI-XNYS",
    "localSymbol": "PRI",
    "instrumentName": "PRIMERICA INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 2276.819961547852,
    "aggTaxlotId": "0e467a96-7016-11ef-a17f-0260bab84379",
    "shares": 9,
    "weight": 0.7193394800551534,
    "orgPurchasePrice": 225.04,
    "company": "PRIMERICA INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Clara Callan",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 252.98,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 142,
    "rgl": 284
  },
  {
    "instrId": "PRU-XNYS",
    "localSymbol": "PRU",
    "instrumentName": "PRUDENTIAL FINL INC",
    "purchaseDate": "2024-03-19T00:00:00.000Z",
    "marketValue": 458.7200012207031,
    "aggTaxlotId": "0e467c77-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.14492819491299286,
    "orgPurchasePrice": 113.789,
    "company": "PRUDENTIAL FINANCIAL INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 114.68,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 143,
    "rgl": 286
  },
  {
    "instrId": "PRU-XNYS",
    "localSymbol": "PRU",
    "instrumentName": "PRUDENTIAL FINL INC",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 229.3600006103516,
    "aggTaxlotId": "0e467e5b-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.07246409745649643,
    "orgPurchasePrice": 118.605,
    "company": "PRUDENTIAL FINANCIAL INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 114.68,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 144,
    "rgl": 288
  },
  {
    "instrId": "PSA-XNYS",
    "localSymbol": "PSA",
    "instrumentName": "PUBLIC STORAGE OPER CO",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 359.1400146484375,
    "aggTaxlotId": "0e468015-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.11346685103225176,
    "orgPurchasePrice": 296.399,
    "company": "PUBLIC STORAGE",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 359.14,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 145,
    "rgl": 290
  },
  {
    "instrId": "PSMT-XNAS",
    "localSymbol": "PSMT",
    "instrumentName": "PRICESMART INC",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 510.8399963378906,
    "aggTaxlotId": "0e4681eb-7016-11ef-a17f-0260bab84379",
    "shares": 6,
    "weight": 0.16139500863619413,
    "orgPurchasePrice": 83.0373,
    "company": "PRICESMART INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Marry Callan",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 85.14,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 146,
    "rgl": 292
  },
  {
    "instrId": "PSTG-XNYS",
    "localSymbol": "PSTG",
    "instrumentName": "PURE STORAGE INC",
    "purchaseDate": "2024-03-19T00:00:00.000Z",
    "marketValue": 93.41999816894533,
    "aggTaxlotId": "0e4683c8-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.029515154489385895,
    "orgPurchasePrice": 49.765,
    "company": "PURE STORAGE INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 46.71,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 147,
    "rgl": 294
  },
  {
    "instrId": "PSTG-XNYS",
    "localSymbol": "PSTG",
    "instrumentName": "PURE STORAGE INC",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 420.3899917602539,
    "aggTaxlotId": "0e468596-7016-11ef-a17f-0260bab84379",
    "shares": 9,
    "weight": 0.13281819520223656,
    "orgPurchasePrice": 63.2902,
    "company": "PURE STORAGE INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 46.71,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 148,
    "rgl": 296
  },
  {
    "instrId": "RBC-XNYS",
    "localSymbol": "RBC",
    "instrumentName": "RBC BEARINGS INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 2263.840087890625,
    "aggTaxlotId": "0e46874c-7016-11ef-a17f-0260bab84379",
    "shares": 8,
    "weight": 0.715238613177026,
    "orgPurchasePrice": 287.1823,
    "company": "RBC BEARINGS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Elizabeth Callan",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 282.98,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 149,
    "rgl": 298
  },
  {
    "instrId": "REGN-XNAS",
    "localSymbol": "REGN",
    "instrumentName": "REGENERON PHARMACEUTICALS",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 1136.3499755859375,
    "aggTaxlotId": "0e4689b8-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.3590189011005361,
    "orgPurchasePrice": 898.5606,
    "company": "REGENERON PHARMACEUTICALS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 1136.35,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 150,
    "rgl": 300
  },
  {
    "instrId": "RF-XNYS",
    "localSymbol": "RF",
    "instrumentName": "REGIONS FINANCIAL CORP NEW",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 200.879997253418,
    "aggTaxlotId": "0e471e28-7016-11ef-a17f-0260bab84379",
    "shares": 9,
    "weight": 0.06346611291984554,
    "orgPurchasePrice": 19.1756,
    "company": "REGIONS FINANCIAL CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 22.32,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 151,
    "rgl": 302
  },
  {
    "instrId": "RLI-XNYS",
    "localSymbol": "RLI",
    "instrumentName": "RLI CORP",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1676.6199798583984,
    "aggTaxlotId": "0e4720d5-7016-11ef-a17f-0260bab84379",
    "shares": 11,
    "weight": 0.529712039129131,
    "orgPurchasePrice": 142.1852,
    "company": "RLI CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Elizabeth Callan",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 152.42,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 152,
    "rgl": 304
  },
  {
    "instrId": "ROAD-XNAS",
    "localSymbol": "ROAD",
    "instrumentName": "CONSTRUCTION PARTNERS INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 2255.6800231933594,
    "aggTaxlotId": "0e4722b9-7016-11ef-a17f-0260bab84379",
    "shares": 38,
    "weight": 0.712660518819246,
    "orgPurchasePrice": 56.335,
    "company": "CONSTRUCTION PARTNERS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Elizabeth Callan",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 59.36,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 153,
    "rgl": 306
  },
  {
    "instrId": "RTMVY-OTCM",
    "localSymbol": "RTMVY",
    "instrumentName": "RIGHTMOVE PLC ADR",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 2698.0815391540527,
    "aggTaxlotId": "0e4724b7-7016-11ef-a17f-0260bab84379",
    "shares": 154,
    "weight": 0.8524330444652481,
    "orgPurchasePrice": 14.63,
    "company": "RIGHTMOVE PLC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Marry Callan",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 17.52,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 154,
    "rgl": 308
  },
  {
    "instrId": "RVLV-XNYS",
    "localSymbol": "RVLV",
    "instrumentName": "REVOLVE GROUP INC",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 1355.9400520324707,
    "aggTaxlotId": "0e47265c-7016-11ef-a17f-0260bab84379",
    "shares": 62,
    "weight": 0.4283962845054737,
    "orgPurchasePrice": 18.1282,
    "company": "REVOLVE GROUP LLC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Marry Callan",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 21.87,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 155,
    "rgl": 310
  },
  {
    "instrId": "RYAN-XNYS",
    "localSymbol": "RYAN",
    "instrumentName": "RYAN SPECIALTY HLDGS INC",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 4984.200119018555,
    "aggTaxlotId": "0e472817-7016-11ef-a17f-0260bab84379",
    "shares": 78,
    "weight": 1.5747103339994537,
    "orgPurchasePrice": 54.573,
    "company": "RYAN SPECIALTY GROUP HOLDINGS A",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Marry Callan",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 63.9,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 156,
    "rgl": 312
  },
  {
    "instrId": "SDHC-XNYS",
    "localSymbol": "SDHC",
    "instrumentName": "SMITH DOUGLAS HOMES CORP",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 554.0800170898438,
    "aggTaxlotId": "0e4729ca-7016-11ef-a17f-0260bab84379",
    "shares": 16,
    "weight": 0.17505627943080643,
    "orgPurchasePrice": 25.51,
    "company": "SMITH DOUGLAS HOMES CL A ORD",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Marry Callan",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 34.63,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 157,
    "rgl": 314
  },
  {
    "instrId": "SDHC-XNYS",
    "localSymbol": "SDHC",
    "instrumentName": "SMITH DOUGLAS HOMES CORP",
    "purchaseDate": "2024-08-15T00:00:00.000Z",
    "marketValue": 277.0400085449219,
    "aggTaxlotId": "0e472b9d-7016-11ef-a17f-0260bab84379",
    "shares": 8,
    "weight": 0.08752813971540321,
    "orgPurchasePrice": 34.15,
    "company": "SMITH DOUGLAS HOMES CL A ORD",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Marry Callan",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 34.63,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 158,
    "rgl": 316
  },
  {
    "instrId": "SFBS-XNYS",
    "localSymbol": "SFBS",
    "instrumentName": "SERVISFIRST BANCSHARES INC",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 2736.7198791503906,
    "aggTaxlotId": "0e472df5-7016-11ef-a17f-0260bab84379",
    "shares": 36,
    "weight": 0.8646404582584163,
    "orgPurchasePrice": 60.7562,
    "company": "SERVISFIRST BANCSHARES INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Marry Callan",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 76.02,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 159,
    "rgl": 318
  },
  {
    "instrId": "SITE-XNYS",
    "localSymbol": "SITE",
    "instrumentName": "SITEONE LANDSCAPE SUPPLY INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1463.9899597167969,
    "aggTaxlotId": "0e472fb4-7016-11ef-a17f-0260bab84379",
    "shares": 11,
    "weight": 0.4625336189132461,
    "orgPurchasePrice": 136.48,
    "company": "SITEONE LANDSCAPE SUPPLY INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Elizabeth Callan",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 133.09,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 160,
    "rgl": 320
  },
  {
    "instrId": "SMG-XNYS",
    "localSymbol": "SMG",
    "instrumentName": "SCOTTS MIRACLE-GRO CO",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1139,
    "aggTaxlotId": "0e47319b-7016-11ef-a17f-0260bab84379",
    "shares": 17,
    "weight": 0.3598561509561853,
    "orgPurchasePrice": 65.3348,
    "company": "SCOTTS MIRACLE-GRO CO (THE)",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Elizabeth Callan",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 67,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 161,
    "rgl": 322
  },
  {
    "instrId": "SNOW-XNYS",
    "localSymbol": "SNOW",
    "instrumentName": "SNOWFLAKE INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 441.0799865722656,
    "aggTaxlotId": "0e473369-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.13935500108138843,
    "orgPurchasePrice": 134.6999,
    "company": "SNOWFLAKE INC CL A",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 110.27,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 162,
    "rgl": 324
  },
  {
    "instrId": "SPSC-XNAS",
    "localSymbol": "SPSC",
    "instrumentName": "SPS COMM INC",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 1858.800048828125,
    "aggTaxlotId": "0e47352b-7016-11ef-a17f-0260bab84379",
    "shares": 10,
    "weight": 0.5872700886465833,
    "orgPurchasePrice": 189.15,
    "company": "SPS COMMERCE INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Marry Callan",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 185.88,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 163,
    "rgl": 326
  },
  {
    "instrId": "SUI-XNYS",
    "localSymbol": "SUI",
    "instrumentName": "SUN CMNTYS INC",
    "purchaseDate": "2024-03-19T00:00:00.000Z",
    "marketValue": 565.5999755859375,
    "aggTaxlotId": "0e4736dd-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.17869590008364164,
    "orgPurchasePrice": 130.3999,
    "company": "SUN COMMUNITIES INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 141.4,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 164,
    "rgl": 328
  },
  {
    "instrId": "SUI-XNYS",
    "localSymbol": "SUI",
    "instrumentName": "SUN CMNTYS INC",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 282.79998779296875,
    "aggTaxlotId": "0e47395b-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.08934795004182082,
    "orgPurchasePrice": 117.7997,
    "company": "SUN COMMUNITIES INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 141.4,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 165,
    "rgl": 330
  },
  {
    "instrId": "T-XNYS",
    "localSymbol": "T",
    "instrumentName": "AT&T INC",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 846.6899642944336,
    "aggTaxlotId": "0e473b76-7016-11ef-a17f-0260bab84379",
    "shares": 39,
    "weight": 0.26750359227763376,
    "orgPurchasePrice": 18.678,
    "company": "AT&T INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 21.71,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 166,
    "rgl": 332
  },
  {
    "instrId": "TFIN-XNAS",
    "localSymbol": "TFIN",
    "instrumentName": "TRIUMPH FINANCIAL INC",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 2194.919982910156,
    "aggTaxlotId": "0e473d40-7016-11ef-a17f-0260bab84379",
    "shares": 28,
    "weight": 0.6934639655020762,
    "orgPurchasePrice": 75.35,
    "company": "TRIUMPH FINANCIAL INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Marry Callan",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 78.39,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 167,
    "rgl": 334
  },
  {
    "instrId": "THO-XNYS",
    "localSymbol": "THO",
    "instrumentName": "THOR INDS INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1377.040008544922,
    "aggTaxlotId": "0e473f07-7016-11ef-a17f-0260bab84379",
    "shares": 14,
    "weight": 0.4350626138609728,
    "orgPurchasePrice": 96.68,
    "company": "THOR INDUSTRIES INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Elizabeth Callan",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 98.36,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 168,
    "rgl": 336
  },
  {
    "instrId": "TJX-XNYS",
    "localSymbol": "TJX",
    "instrumentName": "TJX COS INC NEW",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 942.6400146484376,
    "aggTaxlotId": "0e4740b6-7016-11ef-a17f-0260bab84379",
    "shares": 8,
    "weight": 0.2978180925449244,
    "orgPurchasePrice": 107.7559,
    "company": "TJX COMPANIES INC (THE)",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 117.83,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 169,
    "rgl": 338
  },
  {
    "instrId": "TPL-XNYS",
    "localSymbol": "TPL",
    "instrumentName": "TEXAS PACIFIC LAND TRUST",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 787.6500244140625,
    "aggTaxlotId": "0e474277-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.24885048822316935,
    "orgPurchasePrice": 742.43,
    "company": "TEXAS PACIFIC LAND CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 787.65,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 170,
    "rgl": 340
  },
  {
    "instrId": "TROW-XNAS",
    "localSymbol": "TROW",
    "instrumentName": "PRICE T ROWE GROUP INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 414.9599914550781,
    "aggTaxlotId": "0e47444a-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.13110263856526422,
    "orgPurchasePrice": 106.265,
    "company": "T. ROWE PRICE GROUP INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 103.74,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 171,
    "rgl": 342
  },
  {
    "instrId": "TRU-XNYS",
    "localSymbol": "TRU",
    "instrumentName": "TRANSUNION",
    "purchaseDate": "2024-03-19T00:00:00.000Z",
    "marketValue": 481.19998931884766,
    "aggTaxlotId": "0e474601-7016-11ef-a17f-0260bab84379",
    "shares": 5,
    "weight": 0.15203053204252678,
    "orgPurchasePrice": 78.3313,
    "company": "TRANSUNION",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 96.24,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 172,
    "rgl": 344
  },
  {
    "instrId": "TRV-XNYS",
    "localSymbol": "TRV",
    "instrumentName": "TRAVELERS COMPANIES INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 716.5500183105469,
    "aggTaxlotId": "0e4747cc-7016-11ef-a17f-0260bab84379",
    "shares": 3,
    "weight": 0.22638712164777652,
    "orgPurchasePrice": 191.6278,
    "company": "TRAVELERS COMPANIES INC (THE)",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 238.85,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 173,
    "rgl": 346
  },
  {
    "instrId": "TRV-XNYS",
    "localSymbol": "TRV",
    "instrumentName": "TRAVELERS COMPANIES INC",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 238.8500061035156,
    "aggTaxlotId": "0e474be9-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.07546237388259218,
    "orgPurchasePrice": 209.9545,
    "company": "TRAVELERS COMPANIES INC (THE)",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 238.85,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 174,
    "rgl": 348
  },
  {
    "instrId": "TSLA-XNAS",
    "localSymbol": "TSLA",
    "instrumentName": "TESLA INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 2261.699981689453,
    "aggTaxlotId": "0e474d9c-7016-11ef-a17f-0260bab84379",
    "shares": 10,
    "weight": 0.7145624671013533,
    "orgPurchasePrice": 175.0799,
    "company": "TESLA INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 226.17,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 175,
    "rgl": 350
  },
  {
    "instrId": "TTD-XNAS",
    "localSymbol": "TTD",
    "instrumentName": "THE TRADE DESK INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1296.2299880981443,
    "aggTaxlotId": "0e474f66-7016-11ef-a17f-0260bab84379",
    "shares": 13,
    "weight": 0.4095314611685515,
    "orgPurchasePrice": 97.585,
    "company": "TRADE DESK INC (THE)",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 99.71,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 176,
    "rgl": 352
  },
  {
    "instrId": "TXN-XNAS",
    "localSymbol": "TXN",
    "instrumentName": "TEXAS INSTRS INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 1798.2000274658203,
    "aggTaxlotId": "0e47514a-7016-11ef-a17f-0260bab84379",
    "shares": 9,
    "weight": 0.568124091776257,
    "orgPurchasePrice": 168.888,
    "company": "TEXAS INSTRUMENTS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 199.8,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 177,
    "rgl": 354
  },
  {
    "instrId": "UBER-XNYS",
    "localSymbol": "UBER",
    "instrumentName": "UBER TECHNOLOGIES INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1241.8199615478516,
    "aggTaxlotId": "0e47530a-7016-11ef-a17f-0260bab84379",
    "shares": 18,
    "weight": 0.3923411339272765,
    "orgPurchasePrice": 65.7099,
    "company": "UBER TECHNOLOGIES INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 68.99,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 178,
    "rgl": 356
  },
  {
    "instrId": "ULTA-XNAS",
    "localSymbol": "ULTA",
    "instrumentName": "ULTA BEAUTY INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 747,
    "aggTaxlotId": "0e4754f8-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.23600750198794596,
    "orgPurchasePrice": 486.8095,
    "company": "ULTA BEAUTY INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 373.5,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 179,
    "rgl": 358
  },
  {
    "instrId": "UNF-XNYS",
    "localSymbol": "UNF",
    "instrumentName": "UNIFIRST CORP MASS",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1645.5599670410156,
    "aggTaxlotId": "0e488dd4-7016-11ef-a17f-0260bab84379",
    "shares": 9,
    "weight": 0.5198989252914548,
    "orgPurchasePrice": 158.115,
    "company": "UNIFIRST CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Elizabeth Callan",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 182.84,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 180,
    "rgl": 360
  },
  {
    "instrId": "USPH-XNYS",
    "localSymbol": "USPH",
    "instrumentName": "U S PHYSICAL THERAPY",
    "purchaseDate": "2024-06-06T00:00:00.000Z",
    "marketValue": 1170.959991455078,
    "aggTaxlotId": "0e489053-7016-11ef-a17f-0260bab84379",
    "shares": 14,
    "weight": 0.369953604432583,
    "orgPurchasePrice": 98.295,
    "company": "US PHYSICAL THERAPY INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Marry Callan",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 83.64,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 181,
    "rgl": 362
  },
  {
    "instrId": "V-XNYS",
    "localSymbol": "V",
    "instrumentName": "VISA INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 2568.059967041016,
    "aggTaxlotId": "0e48921c-7016-11ef-a17f-0260bab84379",
    "shares": 9,
    "weight": 0.8113539729271712,
    "orgPurchasePrice": 274.64,
    "company": "VISA INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 285.34,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 182,
    "rgl": 364
  },
  {
    "instrId": "VLO-XNYS",
    "localSymbol": "VLO",
    "instrumentName": "VALERO ENERGY CORP",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 2041.3499450683596,
    "aggTaxlotId": "0e4893d0-7016-11ef-a17f-0260bab84379",
    "shares": 15,
    "weight": 0.6449449815512907,
    "orgPurchasePrice": 127.489,
    "company": "VALERO ENERGY CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 136.09,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 183,
    "rgl": 366
  },
  {
    "instrId": "VRTX-XNAS",
    "localSymbol": "VRTX",
    "instrumentName": "VERTEX PHARMACEUTICALS INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 4707.799987792969,
    "aggTaxlotId": "0e489596-7016-11ef-a17f-0260bab84379",
    "shares": 10,
    "weight": 1.487384357400135,
    "orgPurchasePrice": 421.445,
    "company": "VERTEX PHARMACEUTICALS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 470.78,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 184,
    "rgl": 368
  },
  {
    "instrId": "VRTX-XNAS",
    "localSymbol": "VRTX",
    "instrumentName": "VERTEX PHARMACEUTICALS INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1412.3399963378906,
    "aggTaxlotId": "0e489738-7016-11ef-a17f-0260bab84379",
    "shares": 3,
    "weight": 0.44621530722004055,
    "orgPurchasePrice": 483.88,
    "company": "VERTEX PHARMACEUTICALS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 470.78,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 185,
    "rgl": 370
  },
  {
    "instrId": "VRTX-XNAS",
    "localSymbol": "VRTX",
    "instrumentName": "VERTEX PHARMACEUTICALS INC",
    "purchaseDate": "2024-07-31T00:00:00.000Z",
    "marketValue": 470.7799987792969,
    "aggTaxlotId": "0e4898d4-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.1487384357400135,
    "orgPurchasePrice": 499.95,
    "company": "VERTEX PHARMACEUTICALS INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 470.78,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 186,
    "rgl": 372
  },
  {
    "instrId": "VST-XNYS",
    "localSymbol": "VST",
    "instrumentName": "VISTRA CORP",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 1217.280029296875,
    "aggTaxlotId": "0e489a76-7016-11ef-a17f-0260bab84379",
    "shares": 16,
    "weight": 0.38458797715417553,
    "orgPurchasePrice": 89.3651,
    "company": "VISTRA CORP",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 76.08,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 187,
    "rgl": 374
  },
  {
    "instrId": "WDFC-XNAS",
    "localSymbol": "WDFC",
    "instrumentName": "WD 40 CO",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 1788.709991455078,
    "aggTaxlotId": "0e489c4f-7016-11ef-a17f-0260bab84379",
    "shares": 7,
    "weight": 0.5651258057084245,
    "orgPurchasePrice": 229.44,
    "company": "WD-40 CO",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Elizabeth Callan",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 255.53,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 188,
    "rgl": 376
  },
  {
    "instrId": "WELL-XNYS",
    "localSymbol": "WELL",
    "instrumentName": "WELLTOWER INC",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 129.02000427246094,
    "aggTaxlotId": "0e489e0e-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.04076263576280805,
    "orgPurchasePrice": 89.58,
    "company": "WELLTOWER INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 129.02,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 189,
    "rgl": 378
  },
  {
    "instrId": "WELL-XNYS",
    "localSymbol": "WELL",
    "instrumentName": "WELLTOWER INC",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 1161.1800384521484,
    "aggTaxlotId": "0e489fe8-7016-11ef-a17f-0260bab84379",
    "shares": 9,
    "weight": 0.3668637218652725,
    "orgPurchasePrice": 101.9,
    "company": "WELLTOWER INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 129.02,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 190,
    "rgl": 380
  },
  {
    "instrId": "WM-XNYS",
    "localSymbol": "WM",
    "instrumentName": "WASTE MGMT INC DEL",
    "purchaseDate": "2024-01-09T00:00:00.000Z",
    "marketValue": 624.75,
    "aggTaxlotId": "0e48a197-7016-11ef-a17f-0260bab84379",
    "shares": 3,
    "weight": 0.1973837842931315,
    "orgPurchasePrice": 177.8491,
    "company": "WASTE MANAGEMENT INC.",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 208.25,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 191,
    "rgl": 382
  },
  {
    "instrId": "WM-XNYS",
    "localSymbol": "WM",
    "instrumentName": "WASTE MGMT INC DEL",
    "purchaseDate": "2024-03-19T00:00:00.000Z",
    "marketValue": 208.25,
    "aggTaxlotId": "0e48a550-7016-11ef-a17f-0260bab84379",
    "shares": 1,
    "weight": 0.06579459476437717,
    "orgPurchasePrice": 211.615,
    "company": "WASTE MANAGEMENT INC.",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 208.25,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 192,
    "rgl": 384
  },
  {
    "instrId": "WM-XNYS",
    "localSymbol": "WM",
    "instrumentName": "WASTE MGMT INC DEL",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 416.5,
    "aggTaxlotId": "0e48a73b-7016-11ef-a17f-0260bab84379",
    "shares": 2,
    "weight": 0.13158918952875434,
    "orgPurchasePrice": 212.95,
    "company": "WASTE MANAGEMENT INC.",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 208.25,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 193,
    "rgl": 386
  },
  {
    "instrId": "WMT-XNYS",
    "localSymbol": "WMT",
    "instrumentName": "WALMART INC",
    "purchaseDate": "2024-03-19T00:00:00.000Z",
    "marketValue": 2679.5399169921875,
    "aggTaxlotId": "0e48a8f6-7016-11ef-a17f-0260bab84379",
    "shares": 34,
    "weight": 0.8465749963672211,
    "orgPurchasePrice": 60.8292,
    "company": "WALMART INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 78.81,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 194,
    "rgl": 388
  },
  {
    "instrId": "WMT-XNYS",
    "localSymbol": "WMT",
    "instrumentName": "WALMART INC",
    "purchaseDate": "2024-06-25T00:00:00.000Z",
    "marketValue": 315.239990234375,
    "aggTaxlotId": "0e48aac8-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.09959705839614366,
    "orgPurchasePrice": 67.2265,
    "company": "WALMART INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 78.81,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 195,
    "rgl": 390
  },
  {
    "instrId": "WMT-XNYS",
    "localSymbol": "WMT",
    "instrumentName": "WALMART INC",
    "purchaseDate": "2024-08-16T00:00:00.000Z",
    "marketValue": 315.239990234375,
    "aggTaxlotId": "0e48ac5b-7016-11ef-a17f-0260bab84379",
    "shares": 4,
    "weight": 0.09959705839614366,
    "orgPurchasePrice": 73.045,
    "company": "WALMART INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 78.81,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 196,
    "rgl": 392
  },
  {
    "instrId": "WSO-XNYS",
    "localSymbol": "WSO",
    "instrumentName": "WATSCO INC",
    "purchaseDate": "2024-06-05T00:00:00.000Z",
    "marketValue": 2730.8999633789062,
    "aggTaxlotId": "0e48aded-7016-11ef-a17f-0260bab84379",
    "shares": 6,
    "weight": 0.8628017115609488,
    "orgPurchasePrice": 463.8728,
    "company": "WATSCO INC",
    "assetClass": "EQ",
    "assetClassDesc": "Equity",
    "accountName": "Elizabeth Callan",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 455.15,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 197,
    "rgl": 394
  },
  {
    "instrId": "__CASH",
    "localSymbol": "__USD",
    "instrumentName": "CASH PGM FOR USD",
    "purchaseDate": "2024-09-11T00:00:00.000Z",
    "marketValue": 2029.1472577,
    "aggTaxlotId": "0e48afbc-7016-11ef-a17f-0260bab84379",
    "shares": 2029.1472577,
    "weight": 0.6410896592442675,
    "orgPurchasePrice": 1,
    "company": "CASH PGM FOR USD",
    "assetClass": "CA",
    "assetClassDesc": "Cash",
    "accountName": "Austin Callan",
    "accountId": "12753078-8337-49d5-8dfa-e012699a17a4",
    "accountCd": "CALJE0401",
    "lastClosePrice": 1,
    "strategyName": "Jennison Large Cap Growth Drifted",
    "urgl": 198,
    "rgl": 396
  },
  {
    "instrId": "__CASH",
    "localSymbol": "__USD",
    "instrumentName": "CASH PGM FOR USD",
    "purchaseDate": "2024-09-11T00:00:00.000Z",
    "marketValue": 3211.4474147,
    "aggTaxlotId": "0e48afbc-7016-11ef-a17f-0260bab84379",
    "shares": 3211.4474147,
    "weight": 1.0146260804671945,
    "orgPurchasePrice": 1,
    "company": "CASH PGM FOR USD",
    "assetClass": "CA",
    "assetClassDesc": "Cash",
    "accountName": "Clara Callan",
    "accountId": "62f80c70-1c07-4a52-b3dc-c0b3a6e379c2",
    "accountCd": "CALJE0321",
    "lastClosePrice": 1,
    "strategyName": "Kayne Anderson Rudnick SCV Drifted",
    "urgl": 199,
    "rgl": 398
  },
  {
    "instrId": "__CASH",
    "localSymbol": "__USD",
    "instrumentName": "CASH PGM FOR USD",
    "purchaseDate": "2024-09-11T00:00:00.000Z",
    "marketValue": 4674.3208486,
    "aggTaxlotId": "0e48afbc-7016-11ef-a17f-0260bab84379",
    "shares": 4674.3208486,
    "weight": 1.4768069437326126,
    "orgPurchasePrice": 1,
    "company": "CASH PGM FOR USD",
    "assetClass": "CA",
    "assetClassDesc": "Cash",
    "accountName": "Marry Callan",
    "accountId": "759c0d24-d831-4564-bbe7-1a0bf61a7eff",
    "accountCd": "CALJE1660",
    "lastClosePrice": 1,
    "strategyName": "Kayne Anderson Rudnick Sustainable Growth Drifted",
    "urgl": 200,
    "rgl": 400
  },
  {
    "instrId": "__CASH",
    "localSymbol": "__USD",
    "instrumentName": "CASH PGM FOR USD",
    "purchaseDate": "2024-09-11T00:00:00.000Z",
    "marketValue": 1113.302728,
    "aggTaxlotId": "0e48afbc-7016-11ef-a17f-0260bab84379",
    "shares": 1113.302728,
    "weight": 0.35173734376391647,
    "orgPurchasePrice": 1,
    "company": "CASH PGM FOR USD",
    "assetClass": "CA",
    "assetClassDesc": "Cash",
    "accountName": "Harry Callan",
    "accountId": "c9c008b7-609c-4c9a-93b5-ed37c2952443",
    "accountCd": "CALJE5365",
    "lastClosePrice": 1,
    "strategyName": "STX US5 IN QUAL USD NR",
    "urgl": 201,
    "rgl": 402
  }
]
const summaryData = [
  {
    id: 1,
    name: 'Harry Rev Tr',
    trackingError: 11.99,
    hold: 491,
    buy: 117,
    sell: 109
  },
  {
    id: 2,
    name: 'H+M jt Acct',
    trackingError: 11.99,
    hold: 491,
    buy: 117,
    sell: 109
  },
  {
    id: 3,
    name: 'Callan FLP',
    trackingError: 11.99,
    hold: 491,
    buy: 117,
    sell: 109
  },
  {
    id: 4,
    name: 'Trust FBO',
    trackingError: 11.99,
    hold: 491,
    buy: 117,
    sell: 109
  },
  {
    id: 5,
    name: 'Callan GST Irrev Tr',
    trackingError: 11.99,
    hold: 491,
    buy: 117,
    sell: 109
  },
  {
    id: 6,
    name: 'Trst FBO Elizabeth',
    trackingError: 11.99,
    hold: 491,
    buy: 117,
    sell: 109
  },
]
const aggregateGroups = [
  {
    "aggGroupId": "6805a13d-0d20-11ef-b940-0ae47778074f",
    "aggGroupName": "Callan UMA",
    "aggGroupCd": "CALLAN",
    "marketValue": 343875.87,
    "groupType": "UMA",
    "groupLabel": "UMA",
    "groupColor": "#6DA9ED",
    "members": [
      {
        "marketValue": 122084.9158793,
        "accountId": "c3ea14c1-54ce-4a22-aa5d-8d75073e7fe9",
        "accountName": "Harry Callan",
        "accountCd": "ARIMI8436",
        "memberType": "ACCOUNT"
      },
      {
        "marketValue": 1522680.9192156,
        "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
        "accountName": "Marry Callan",
        "accountCd": "ARIMI8170",
        "memberType": "ACCOUNT"
      }
    ]
  },
  {
    "aggGroupId": "0908faf0-0b3e-4ff4-a916-fee127068ae7",
    "aggGroupCd": "ARIMICXYX",
    "aggGroupName": "Scenario 1 Callan UMA",
    "marketValue": 361852.5402601,
    "groupType": "UMA",
    "groupLabel": "UMA",
    "groupColor": "#A7ABC1",
    "members": [
      {
        "marketValue": 62.94,
        "accountId": "aeaa50e7-e2a7-4495-a56e-3621c360876e",
        "accountName": "Harry Rev Tr",
        "accountCd": "ARINA6872",
        "memberType": "ACCOUNT"
      },
      {
        "marketValue": 17913.7302601,
        "accountId": "140ad39f-8e09-4715-bcbb-3fb1521ad31f",
        "accountName": "Callan FLP",
        "accountCd": "CFODR9195",
        "memberType": "ACCOUNT"
      },
      {
        "marketValue": 343875.87,
        "accountId": "6805a13d-0d20-11ef-b940-0ae47778074f",
        "accountName": "Callan GST Irrev Tr",
        "accountCd": "MICHAEL",
        "memberType": "UMA"
      },
      {
        "marketValue": 12000,
        "accountId": "ffb1881a-8e6a-442a-8c76-c2b50f6c1158",
        "accountName": "H+M jt Acct",
        "accountCd": "ARIAC6798",
        "memberType": "ACCOUNT"
      }
    ]
  },
  {
    "aggGroupId": "2882b0a3-6040-4477-a21c-cc6668a223b8",
    "aggGroupCd": "ARIGRUZYV",
    "aggGroupName": "Scenario 2 Callan UMA",
    "marketValue": 355875.87,
    "groupType": "UMA",
    "groupLabel": "UMA",
    "groupColor": "#B3C9E3",
    "members": [
      {
        "marketValue": 343875.87,
        "accountId": "6805a13d-0d20-11ef-b940-0ae47778074f",
        "accountName": "Michael Smolarski",
        "accountCd": "MICHAEL",
        "memberType": "ACCOUNT"
      },
      {
        "marketValue": 12000,
        "accountId": "ffb1881a-8e6a-442a-8c76-c2b50f6c1158",
        "accountName": "H+M jt Acct",
        "accountCd": "ARIAC6798",
        "memberType": "ACCOUNT"
      }
    ]
  },
  {
    "aggGroupId": "33833ead-25c3-4d97-9eb9-ae5dfaec1d49",
    "aggGroupCd": "ARIGRJGBC",
    "aggGroupName": "Grp X",
    "marketValue": 355875.87,
    "groupType": "TRUST",
    "groupLabel": "Trust",
    "groupColor": "#bf2ce8",
    "members": [
      {
        "marketValue": 12000,
        "accountId": "ffb1881a-8e6a-442a-8c76-c2b50f6c1158",
        "accountName": "ACL Test User",
        "accountCd": "ARIAC6798",
        "memberType": "ACCOUNT"
      },
      {
        "marketValue": 343875.87,
        "accountId": "6805a13d-0d20-11ef-b940-0ae47778074f",
        "accountName": "Michael Smolarski",
        "accountCd": "MICHAEL",
        "memberType": "UMA"
      }
    ]
  },
  {
    "aggGroupId": "514461ad-618b-4051-b524-9efcf60ba822",
    "aggGroupCd": "CALABPAMJ",
    "aggGroupName": "abc",
    "marketValue": 566411.9406420001,
    "groupType": "TRUST",
    "groupLabel": "Trust",
    "groupColor": "#bf2ce8",
    "members": [
      {
        "marketValue": 115700.6602286,
        "accountId": "008b901e-99b5-4084-8faf-4bd2ac080b3f",
        "accountName": "Preethi test",
        "accountCd": "ARIPR0195",
        "memberType": "ACCOUNT"
      },
      {
        "marketValue": 343875.87,
        "accountId": "6805a13d-0d20-11ef-b940-0ae47778074f",
        "accountName": "Michael Smolarski",
        "accountCd": "MICHAEL",
        "memberType": "UMA"
      },
      {
        "marketValue": 106835.4104134,
        "accountId": "0d90a0d9-477d-4eaa-b6c8-2bf9d7401ef6",
        "accountName": "Adi 10",
        "accountCd": "ARIAD8796",
        "memberType": "ACCOUNT"
      }
    ]
  },
  {
    "aggGroupId": "949f4905-49a7-11ef-b940-0ae47778074f",
    "aggGroupCd": "CAL",
    "aggGroupName": "CallansHouse",
    "marketValue": 3394008.4669955,
    "groupType": "FAMILY",
    "groupLabel": "Family",
    "groupColor": "#B3C9E3",
    "members": [
      {
        "marketValue": 310518.6355506,
        "accountId": "b1144187-fce9-46d7-b436-a47257d89e67",
        "accountName": "Asmita AGG Acc 1",
        "accountCd": "ARIAS5047",
        "memberType": "ACCOUNT"
      },
      {
        "marketValue": 740882.6679149,
        "accountId": "ed175ace-1e2e-4202-868e-b4dfbd402a5d",
        "accountName": "ADI AGG Test",
        "accountCd": "ADIAGG",
        "memberType": "UMA"
      },
      {
        "marketValue": 1522680.9192156,
        "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
        "accountName": "Michael Smolarski R3",
        "accountCd": "ARIMI8170",
        "memberType": "ACCOUNT"
      },
      {
        "marketValue": 476050.3743144,
        "accountId": "789fbb50-78e9-4dfe-a9ad-23f11271880c",
        "accountName": "ASMITA KANANI-HISTORY_SCRT_TEST",
        "accountCd": "ARIAS2061",
        "memberType": "ACCOUNT"
      },
      {
        "marketValue": 343875.87,
        "accountId": "6805a13d-0d20-11ef-b940-0ae47778074f",
        "accountName": "Michael Smolarski",
        "accountCd": "MICHAEL",
        "memberType": "UMA"
      }
    ]
  },
  {
    "aggGroupId": "b126d2a3-49a8-11ef-b940-0ae47778074f",
    "aggGroupCd": "CAL1",
    "aggGroupName": "CallansHouse1",
    "marketValue": 3050132.5969955,
    "groupType": "TRUST",
    "groupLabel": "Trust",
    "groupColor": "#bf2ce8",
    "members": [
      {
        "marketValue": 740882.6679149,
        "accountId": "ed175ace-1e2e-4202-868e-b4dfbd402a5d",
        "accountName": "ADI AGG Test",
        "accountCd": "ADIAGG",
        "memberType": "UMA"
      },
      {
        "marketValue": 310518.6355506,
        "accountId": "b1144187-fce9-46d7-b436-a47257d89e67",
        "accountName": "Asmita AGG Acc 1",
        "accountCd": "ARIAS5047",
        "memberType": "ACCOUNT"
      },
      {
        "marketValue": 1522680.9192156,
        "accountId": "5cd7f2d5-eca8-458c-b144-858dcecfebd8",
        "accountName": "Michael Smolarski R3",
        "accountCd": "ARIMI8170",
        "memberType": "ACCOUNT"
      },
      {
        "marketValue": 476050.3743144,
        "accountId": "789fbb50-78e9-4dfe-a9ad-23f11271880c",
        "accountName": "ASMITA KANANI-HISTORY_SCRT_TEST",
        "accountCd": "ARIAS2061",
        "memberType": "ACCOUNT"
      }
    ]
  },
  {
    "aggGroupId": "c5e885fc-c2a7-4043-9f70-8adc00937a73",
    "aggGroupCd": "ARIGRSKTI",
    "aggGroupName": "Grp Y",
    "marketValue": 355875.87,
    "groupType": "HOUSEHOLD",
    "groupLabel": "Household",
    "groupColor": "#1025c4",
    "members": [
      {
        "marketValue": 12000,
        "accountId": "ffb1881a-8e6a-442a-8c76-c2b50f6c1158",
        "accountName": "ACL Test User",
        "accountCd": "ARIAC6798",
        "memberType": "ACCOUNT"
      },
      {
        "marketValue": 343875.87,
        "accountId": "6805a13d-0d20-11ef-b940-0ae47778074f",
        "accountName": "Michael Smolarski",
        "accountCd": "MICHAEL",
        "memberType": "UMA"
      }
    ]
  }
]

export { performanceData, marketValueData, benchmarkData, allocationData, tradeListData, taxlotData, scenarioData, aggregateGroups,summaryData }
