import { useMemo } from 'react'

export function getSuccessors (edges, nodeId, nodes) {
  return edges?.filter(edge => edge.source === nodeId)?.map(edge => edge.target).concat(nodes?.filter(node => node.parentId === nodeId).map(node => node.id))
}

export function filterCollapsedChildren (nodes, edges, node) {
  const children = getSuccessors(edges, node.id, nodes)
  node.data.expandable = !!children.length

  if (node.data.expanded === false) {
    const childArray = [...children]
    while (childArray.length) {
      const childId = childArray.pop()
      const childIndex = nodes.findIndex(n => n.id === childId)

      if (childIndex !== -1) {
        nodes[childIndex].hidden = true
        childArray.push(...getSuccessors(edges, childId, nodes))
      }

      const edgeIndex = edges.findIndex(edge => edge.source === node.id && edge.target === childId)
      if (edgeIndex !== -1) {
        edges[edgeIndex].hidden = true
      }
    }
  }
}

function useExpandCollapse (nodes, edges, { layoutNodes = true } = {}) {
  return useMemo(() => {
    if (!layoutNodes) return { nodes, edges }

    const filteredNodes = nodes?.map(node => ({ ...node, hidden: false }))
    const filteredEdges = edges?.map(edge => ({ ...edge, hidden: false }))

    for (let i = 0; i < filteredNodes?.length; i++) {
      filterCollapsedChildren(filteredNodes, filteredEdges, filteredNodes[i])
    }

    const layoutedNodes = filteredNodes?.map(node => {
      const data = { ...node?.data }
      return { ...node, data }
    })

    return {
      nodes: layoutedNodes,
      edges: filteredEdges
    }
  }, [nodes, edges, layoutNodes])
}

export default useExpandCollapse
