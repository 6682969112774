import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as Sentry from '@sentry/react'
import { API } from 'aws-amplify'
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  debounce,
  Divider,
  TextField,
  Typography
} from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, GridRowEditStopReasons, GridRowModes, useGridApiRef, GridToolbarContainer } from '@mui/x-data-grid-pro'
import { Add } from '@mui/icons-material'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import CustomTooltipIcon from '../../Personalization/components/CustomTooltipIcon'
import AllocationActionCell from './AllocationActionCell'
import CustomStrategyField from './CustomStrategyField'
import UploadPortfolioPopup from './UploadPortfolioPopup'
import ViewPortfolioPopup from './ViewPortfolioPopup'

const UmaTransitionPortfolioTab = (props) => {
  const {
    setData, transitionPortfolioAllocationData, setTransitionPortfolioAllocationData, setTransitionUploadPortfolioData, transitionUploadPortfolioData, setAllStepsVisited, setTaxlotInformation, umaDetails
  } = props
  const apiRef = useGridApiRef()
  const [loading, setLoading] = useState(false)
  const [strategies, setStrategies] = useState([])
  const [allStrategiesList, setAllStrategiesList] = useState([])
  const { showError } = useErrorToast()
  const { user } = useAuth()
  const [rowModesModel, setRowModesModel] = useState({})
  const [viewPortfolioPopup, setViewPortfolioPopup] = useState(false)
  const [uploadPortfolioPopup, setUploadPortfolioPopup] = useState(false)
  const [currentPortfolioId, setCurrentPortfolioId] = useState(null)
  const [rowErrors, setRowErrors] = useState({})
  const [currentAllocationGridState, setCurrentAllocationGridState] = useState(null)

  // Function to save the current state of the DataGridPro
  const handleCurrentAllocationTableStateChange = () => {
    if (apiRef?.current?.exportState) {
      const state = apiRef?.current?.exportState()
      setCurrentAllocationGridState(state)
    }
  }

  useEffect(() => {
    if (user) {
      getStrategyList()
    }
  }, [user])

  // Effect to restore the grid state whenever currentAllocationGridState changes
  useEffect(() => {
    if (apiRef?.current?.restoreState && currentAllocationGridState) {
      const resetGridStateObj = {
        columns: currentAllocationGridState?.columns || {},
        pinnedColumns: currentAllocationGridState?.pinnedColumns || {},
        sorting: currentAllocationGridState?.sorting || []
      }
      apiRef?.current?.restoreState(resetGridStateObj)
    }
  }, [currentAllocationGridState, viewPortfolioPopup, rowModesModel, uploadPortfolioPopup, currentPortfolioId, rowErrors, strategies, transitionPortfolioAllocationData, transitionUploadPortfolioData, umaDetails, setTaxlotInformation])

  const missingPortfolioError = (transitionPortfolioAllocationData, transitionUploadPortfolioData) => {
    if (transitionPortfolioAllocationData && transitionPortfolioAllocationData.length) {
      if (!Object.keys(transitionUploadPortfolioData).length) return true
      else if (Object.keys(transitionUploadPortfolioData).length !== transitionPortfolioAllocationData.length) {
        return true
      } else {
        return false
      }
    }
    return false
  }

  const totalAllocation = useMemo(
    () => transitionPortfolioAllocationData?.reduce(
      (sum, row) => sum + Number(row?.allocation || 0), 0
    ),
    [transitionPortfolioAllocationData]
  )

  // set error if allocation weight is less or grater then 100
  const hasAllocationError = useMemo(
    () => totalAllocation !== 100,
    [totalAllocation]
  )

  const hasMissingPortfolioError = useCallback(
    () => missingPortfolioError(transitionPortfolioAllocationData, transitionUploadPortfolioData),
    [transitionPortfolioAllocationData, transitionUploadPortfolioData]
  )

  useEffect(() => {
    // On screen load, filter out incomplete rows
    setTransitionPortfolioAllocationData((prevRows) =>
      prevRows.filter((row) => row.strategy && row.allocation)
    )
  }, [])

  // add error key in allocations if below conditions matches
  useEffect(() => {
    if (!transitionPortfolioAllocationData) return

    const updatedOnboardingAllocation = transitionPortfolioAllocationData?.map((allocation) => {
      const rowError = rowErrors[allocation.id] || {}
      return {
        ...allocation,
        error:
          hasAllocationError ||
          rowError.allocationError ||
          rowError.strategyError ||
          hasMissingPortfolioError()
      }
    })

    if (updatedOnboardingAllocation?.some((data) => data?.error) || !updatedOnboardingAllocation?.length) {
      setAllStepsVisited(false)
    }

    setData(updatedOnboardingAllocation)
  }, [rowErrors, transitionPortfolioAllocationData, hasAllocationError, hasMissingPortfolioError])

  const handleSearchRequest = useMemo(
    () => debounce((req) => {
      getStrategyList(req)
    }, 200), [])

  // store portfolio data for allocation
  const updatePortfolioDataForRow = (rowId, data, fundingInformation) => {
    setTransitionUploadPortfolioData((prev) => {
      const existingData = Array.isArray(prev[rowId]?.data) ? prev[rowId].data : []
      const updatedData = Array.isArray(data) ? [...existingData, ...data] : [...existingData, data]
      return {
        ...prev,
        [rowId]: {
          ...prev[rowId],
          data: updatedData,
          fundingInformation: {
            ...(prev[rowId]?.fundingInformation || {}),
            ...fundingInformation
          }
        }
      }
    })
  }

  const transitionPortfolioAllocationColumns = [
    {
      field: 'strategy',
      headerName: 'Strategy',
      editable: true,
      flex: 2,
      preProcessEditCellProps: (params) => {
        const hasError = !params?.props?.value
        setRowErrors((prevErrors) => ({
          ...prevErrors,
          [params.id]: {
            ...prevErrors[params.id],
            strategyError: hasError
          }
        }))
        return { ...params.props, error: hasError }
      },
      renderEditCell: (params) => (
        <CustomStrategyField
          params={params}
          loading={loading}
          strategies={strategies}
          handleSearchRequest={handleSearchRequest}
          currRowsStrategyList={transitionPortfolioAllocationData}
          allStrategiesList={allStrategiesList}
          setLoading={setLoading}
          error={rowErrors[params.id]?.strategyError}
        />
      )
    },
    {
      field: 'allocation',
      headerName: 'Allocation',
      editable: true,
      flex: 1,
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      preProcessEditCellProps: (params) => {
        const hasError = !params?.props?.value
        setRowErrors((prevErrors) => ({
          ...prevErrors,
          [params.id]: {
            ...prevErrors[params.id],
            allocationError: hasError
          }
        }))

        return { ...params.props, error: hasError }
      },
      renderEditCell: (params) => (
        <TextField
          type='number'
          variant='outlined'
          value={params.value || ''}
          sx={{
            ml: 2,
            width: '100%',
            mr: 2,
            height: '40px'
          }}
          error={rowErrors[params.id]?.allocationError}
          InputProps={{
            inputProps: {
              type: 'number',
              step: '1',
              min: 1,
              max: 100
            },
            sx: {
              height: '100%'
            }
          }}
          onChange={(event) => {
            const newValue = event.target.value
            if (newValue === '' || (newValue > 0 && newValue <= 100)) {
              params.api.setEditCellValue({ id: params.id, field: 'allocation', value: Number(newValue) })
            }
          }}
          // prevent the user from typing the '-' (negative sign)
          onKeyDown={(e) => {
            if (e.key === '-' || e.key === 'e') {
              e.preventDefault()
            }
          }}
        />
      ),
      renderHeader: (params) => {
        return (
          <>
            Allocation
            {(totalAllocation > 100 ||
              totalAllocation < 100) &&
              transitionPortfolioAllocationData &&
              transitionPortfolioAllocationData?.length
              ? (
                <CustomTooltipIcon action='error' text='Total Allocation Weight Should be 100' />
                )
              : ''}
          </>
        )
      }
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 200,
      cellClassName: 'actions',
      renderCell: (params) => {
        const rowError = rowErrors[params.id] || {}
        return (
          <AllocationActionCell
            params={params}
            rowModesModel={rowModesModel}
            apiRef={apiRef}
            handleSaveClick={handleSaveClick}
            handleCancelClick={handleCancelClick}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
            hasStrategyError={rowError.strategyError}
            hasAllocationError={rowError.allocationError}
            hasPortfolioData={transitionUploadPortfolioData}
            setTransitionUploadPortfolioData={setTransitionUploadPortfolioData}
            setCurrentPortfolioId={setCurrentPortfolioId}
            setViewPortfolioPopup={setViewPortfolioPopup}
          />
        )
      }
    },
    {
      field: 'upload',
      type: 'actions',
      width: 180,
      sortable: false,
      align: 'center',
      cellClassName: 'actions',
      renderCell: (params) => {
        return (
          <Button
            variant='contained'
            disabled={(Object.keys(transitionUploadPortfolioData)?.length && (transitionUploadPortfolioData[params.row.id]?.fundingInformation?.fundingSource === 'CASH' ||
             transitionUploadPortfolioData[params.row.id]?.data?.length)) || (!params?.row?.strategy || !params?.row?.allocation)}
            onClick={(e) => {
              e.preventDefault()
              setUploadPortfolioPopup(true)
              setCurrentPortfolioId(params.row.id)
            }}
          >
            Upload
          </Button>
        )
      }
    }
  ]

  const getStrategyList = (strategyName) => {
    setLoading(true)
    API.get('baseStrategyURL', `strategy/v1/${user?.userGroup}/strategy-master`, {
      queryStringParameters: {
        ...{
          ...strategyName
            ? {
                strategyName
              }
            : {}
        },
        resources: encodeURIComponent(JSON.stringify({
          serviceId: 'aggregate',
          resourceId: 'uma-onboarding'
        }))
      }
    })
      .then((response) => {
        if (response?.data) {
          const strategyListData = response?.data?.map((item) => ({
            id: randomId(),
            ...item
          }))
          if (!strategyName) {
            setAllStrategiesList(strategyListData)
          }
          setStrategies(strategyListData)
          setLoading(false)
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        setLoading(false)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  // const addAllocation = () => {
  //   if (selectedStrategy && weight) {
  //     // Add new strategy and allocation row
  //     setTransitionPortfolioAllocationData((prevAllocations) => [
  //       ...prevAllocations,
  //       {
  //         id: randomId(),
  //         strategyId: selectedStrategy?.strategyId,
  //         strategy: selectedStrategy?.strategyName,
  //         allocation: weight
  //       }
  //     ])
  //     setAllocationDataSubmitted(false)
  //     setFormSubmitted(false)
  //     setWeight('')
  //   } else {
  //     setAllocationDataSubmitted(true)
  //   }
  // }

  const handleRowEditStop = (params, event) => {
    if (
      params.reason === GridRowEditStopReasons.rowFocusOut ||
      params.reason === GridRowEditStopReasons.escapeKeyDown ||
      params.reason === GridRowEditStopReasons.enterKeyDown ||
      params.reason === GridRowEditStopReasons.tabKeyDown
    ) {
      event.defaultMuiPrevented = true
    }

    event.defaultMuiPrevented = true
  }

  const handleCellEditStart = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleCellEditStop = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleEditClick = (id) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = (id) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleDeleteClick = (id) => {
    setTransitionPortfolioAllocationData((prevRows) =>
      prevRows.filter((row) => row.id !== id)
    )

    setTransitionUploadPortfolioData((prevData) => {
      const updatedData = { ...prevData }
      delete updatedData[id]
      return updatedData
    })
  }

  const handleCancelClick = (id) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })

    const editedRow = transitionPortfolioAllocationData?.find((row) => row.id === id)

    if (editedRow?.isNew) {
      setTransitionPortfolioAllocationData((prevRows) =>
        prevRows.filter((row) => row.id !== id)
      )
    }

    setRowErrors((prevErrors) => ({
      ...prevErrors,
      [id]: {
        strategyError: false,
        allocationError: false
      }
    }))
  }

  const processRowUpdate = (newRow, oldRow) => {
    const updatedRow = { ...newRow, isNew: false }
    setTransitionPortfolioAllocationData((prevRows) =>
      prevRows.map((row) =>
        row.id === newRow.id ? updatedRow : row
      )
    )
    return updatedRow
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  const closeModal = () => {
    setUploadPortfolioPopup(false)
  }

  const closeShowPortfolioPopup = () => {
    setViewPortfolioPopup(false)
  }

  const EditToolbar = (props) => {
    const { setTransitionPortfolioAllocationData, setRowModesModel } = props

    const handleClick = () => {
      const id = randomId()
      setTransitionPortfolioAllocationData((oldRows) => [...oldRows, { id, strategy: '', strategyId: '', allocation: '', isNew: true }])
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'strategy' }
      }))
    }

    return (
      <GridToolbarContainer>
        <Button
          color='primary' startIcon={<Add />} onClick={handleClick} disabled={totalAllocation >= 100}
        >
          Add Strategy
        </Button>
      </GridToolbarContainer>
    )
  }

  return (
    <>
      {viewPortfolioPopup && (
        <ViewPortfolioPopup
          transitionUploadPortfolioData={(transitionUploadPortfolioData[currentPortfolioId] || {})}
          open={viewPortfolioPopup}
          closeModal={closeShowPortfolioPopup}
        />
      )}
      {uploadPortfolioPopup && (
        <UploadPortfolioPopup
          open={uploadPortfolioPopup}
          umaDetails={umaDetails}
          closeModal={closeModal}
          setTaxlotInformation={setTaxlotInformation}
          setTransitionUploadPortfolioData={(data, fundingInformation) => updatePortfolioDataForRow(currentPortfolioId, data, fundingInformation)}
        />
      )}
      <Box sx={{ position: 'relative' }}>
        <Typography sx={{ marginLeft: '5px', marginBottom: '10px', fontWeight: 600, color: '#74788D', fontSize: '17px', fontFamily: 'Open Sans' }}>Create Current Allocation Model</Typography>
        {
          missingPortfolioError(transitionPortfolioAllocationData, transitionUploadPortfolioData) && (
            <Alert
              severity='warning'
              sx={{
                position: 'absolute',
                marginBottom: '10px',
                marginTop: '8px',
                top: '-12px',
                padding: '1px 8px',
                right: '0px',
                zIndex: 98,
                '& .MuiAlert-icon': {
                  padding: '3px 0'
                },
                '& .MuiAlert-message': {
                  padding: '3px 0'
                }
              }}
            >Please ensure all strategy have an associated portfolio uploaded.
            </Alert>
          )
        }
        <Divider sx={{ marginBottom: '20px' }} />
        <Card variant='outlined' sx={{ marginBottom: 2 }}>
          <CardContent>
            <DataGridPro
              autoHeight
              rows={transitionPortfolioAllocationData}
              columns={transitionPortfolioAllocationColumns}
              disableRowSelectionOnClick
              pageSize={5}
              pagination
              pageSizeOptions={[5]}
              getRowId={(row) => row?.id}
              rowModesModel={rowModesModel}
              sx={{
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none !important'
                },
                '& .MuiDataGrid-cell--editing': {
                  outline: 'none !important'
                }
              }}
              apiRef={apiRef}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              editMode='row'
              processRowUpdate={processRowUpdate}
              onColumnWidthChange={() => handleCurrentAllocationTableStateChange()}
              onColumnOrderChange={() => handleCurrentAllocationTableStateChange()}
              onSortModelChange={() => handleCurrentAllocationTableStateChange()}
              onCellEditStart={handleCellEditStart}
              onCellEditStop={handleCellEditStop}
              slots={{
                toolbar: EditToolbar
              }}
              slotProps={{
                toolbar: { setTransitionPortfolioAllocationData, setRowModesModel }
              }}
              initialState={{
                ...currentAllocationGridState?.initialState,
                pagination: { paginationModel: { pageSize: 5 } }
              }}
            />
          </CardContent>
        </Card>
      </Box>
    </>
  )
}

export default UmaTransitionPortfolioTab
