import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import CancelIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import { Box, Button, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, FormControl, InputLabel, OutlinedInput, MenuItem, Select, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, GridActionsCellItem, GridRowModes } from '@mui/x-data-grid-pro'
import { LoadingButton } from '@mui/lab'
import * as Sentry from '@sentry/react'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { useSuccessToast } from '../../../hooks/useSuccessToast'
import StyledTradePopupBox from '../components/StyledTradePopupBox'

const UpdatePropertiesModal = ({ selectedItem, index, allScenarioList }) => {
  const { scenarioId, propertyCode, propertyValue, accountId } = selectedItem
  const [data] = useState({
    scenarioId,
    propertyCode,
    propertyValue,
    accountId
  })
  const [approvalProperties, setApprovalProperties] = useState([])
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const { showSuccess } = useSuccessToast()
  const [isLoading, setIsLoading] = useState(true)

  const [rowModesModel, setRowModesModel] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [scenarioGroupId, setScenarioGroupId] = useState((index && index[1] && index[1]?.optDetails?.map(obj => obj.scenarioId)?.sort((a, b) => a - b)?.join(',')) || '1,2,3')

  const handleChange = (event) => {
    const {
      target: { value }
    } = event
    setScenarioGroupId(value)
    setIsLoading(true)
    setRowModesModel({})
  }

  const getAdhocOptm = (formatteddata) => {
    const accId = index[0]
    setIsSubmitting(true)
    API.patch(
      'baseOptimizationURL',
      `optimization/v1/${user?.userGroup}/accounts/${accId}`,
      { body: formatteddata }
    )
      .then((response) => {
        showSuccess(response.message)
      })
      .catch((error) => {
        if (Array.isArray(error.response?.data?.errorInfo?.userMessage)) {
          error.response?.data?.errorInfo?.userMessage?.forEach((element) => {
            showError(element.message)
          })
        } else {
          showError(error.response?.data?.errorInfo?.userMessage || error.message)
        }
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => setIsSubmitting(false))
  }

  const getApprovalProperties = async () => {
    const accountId = index[0]
    API.get(
      'baseOptimizationURL',
      `optimization/v1/${user?.userGroup}/opt-properties/accounts/${accountId}/scenarios/${scenarioGroupId}`
    )
      .then((response) => {
        if (response.data.length > 0) {
          const proData = []
          response.data.forEach((item) => {
            item.data.forEach((property) => {
              if (property.propertyCode !== 'SCENARIO_GROUP_ID') {
                proData.push({
                  scenarioId: item.scenarioId,
                  scenarioDesc: item.scenarioDesc,
                  propertyCode: property.propertyCode,
                  propertyValue: property.propertyValue,
                  propertySource: property?.propertySource,
                  propertyValueCopy: property?.propertyValue,
                  propertySourceCopy: property?.propertySource,
                  id: randomId()
                })
              }
            })
          })
          setApprovalProperties(proData)
        }
      })
      .catch((error) => {
        if (Array.isArray(error.response?.data?.errorInfo?.userMessage)) {
          error.response?.data?.errorInfo?.userMessage?.forEach((element) => {
            showError(element.message)
          })
        } else {
          showError(error.response?.data?.errorInfo?.userMessage || error.message)
        }
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (user) {
      getApprovalProperties(data)
    }
  }, [user, scenarioGroupId])

  const handleSubmitHandler = () => {
    const formatteddata = approvalProperties.map((item) => {
      return {
        scenarioId: item.scenarioId,
        propertyCode: item.propertyCode,
        propertyValue: item.propertyValue,
        propertySource: item?.propertySource
      }
    })
    getAdhocOptm(formatteddata)
  }

  if (approvalProperties === null) {
    return null
  }

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleCellEditStart = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleCellEditStop = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = (id, row) => () => {
    if (!row.error) {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
    }
  }

  const handleCancelClick = (id, row) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })
    const editedRow = approvalProperties.find((row) => row.id === id)
    if (editedRow.isNew) {
      setApprovalProperties(approvalProperties.filter((row) => row.id !== id))
    }
  }

  const processRowUpdate = (newRow) => {
    let updatedRow = { ...newRow }
    if (newRow?.propertyValue !== newRow?.propertyValueCopy) {
      updatedRow = { ...newRow, propertySource: 'ADHOC' }
    } else {
      updatedRow = { ...newRow, propertySource: newRow?.propertySourceCopy }
    }
    setApprovalProperties(approvalProperties.map((row) => (row.id === newRow.id ? updatedRow : row)))
    return updatedRow
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  const columns = [
    { field: 'scenarioDesc', headerName: 'Scenario Description', flex: 1 },
    { field: 'propertyCode', headerName: 'Property Code', flex: 1 },
    { field: 'propertyValue', headerName: 'Property Value', editable: true, flex: 1, align: 'right', headerAlign: 'right' },
    {
      field: 'propertySource',
      headerName: 'Property Source',
      flex: 1,
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key='save'
              icon={<SaveIcon />}
              label='Save'
              onClick={handleSaveClick(id, row)}
            />,
            <GridActionsCellItem
              key='cancel'
              icon={<CancelIcon />}
              label='Cancel'
              className='textPrimary'
              onClick={handleCancelClick(id, row)}
              color='inherit'
            />
          ]
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            key='edit'
            label='Edit'
            className='textPrimary'
            onClick={handleEditClick(id)}
            color='inherit'
          />
        ]
      }
    }
  ]
  const getRowId = (row) => row.id
  const tableHeaderHTML = columns.map(({ headerName }, index) => <TableCell key={index}>{headerName}</TableCell>)
  const rowSkeleton = columns.map(({ headerName }, index) => <TableCell key={index}><Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} /></TableCell>)

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography style={{ textAlign: 'left', fontSize: '22px', fontWeight: 400 }}>Update Properties</Typography>
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id='demo-multiple-name-label'>Scenario Group Id</InputLabel>
          <Select
            labelId='demo-multiple-name-label'
            id='demo-multiple-name'
            value={scenarioGroupId}
            onChange={handleChange}
            size='small'
            input={<OutlinedInput label='Scenario Group Id' />}
            label='Scenario Group Id'
          >
            {
              Object.keys(allScenarioList).map(scenario => (
                <MenuItem
                  key={scenario}
                  value={allScenarioList[scenario].map(obj => obj.scenarioId).join(',')}
                >
                  {scenario + ' (' + allScenarioList[scenario].map(obj => (obj.scenarioGroupId)) + ')'}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Box>
      <StyledTradePopupBox className='table-responsive'>
        {isLoading
          ? (
            <Table className='security-holdings-table'>
              <TableHead>
                <TableRow style={{ position: 'sticky', top: '0', background: 'white' }}>
                  {tableHeaderHTML}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {rowSkeleton}
                </TableRow>
                <TableRow>
                  {rowSkeleton}
                </TableRow>
                <TableRow>
                  {rowSkeleton}
                </TableRow>
              </TableBody>
            </Table>
            )
          : (
            <>
              {!approvalProperties
                ? (
                  <>
                    <h6 style={{ color: 'black', height: '10px', textAlign: 'center' }}>No Data found</h6>
                  </>
                  )
                : (
                  <>
                    <DataGridPro
                      autoHeight
                      rows={approvalProperties}
                      columns={columns}
                      loading={isLoading}
                      getRowId={getRowId}
                      pagination
                      pageSizeOptions={[10]}
                      editMode='row'
                      rowModesModel={rowModesModel}
                      onRowModesModelChange={handleRowModesModelChange}
                      onRowEditStart={handleRowEditStart}
                      onRowEditStop={handleRowEditStop}
                      onCellEditStart={handleCellEditStart}
                      onCellEditStop={handleCellEditStop}
                      processRowUpdate={processRowUpdate}
                      initialState={{
                        ...approvalProperties?.initialState,
                        pagination: { paginationModel: { pageSize: 10 } }
                      }}
                    />

                  </>
                  )}
            </>
            )}
      </StyledTradePopupBox>
      {!isLoading
        ? (
          <Box sx={{ textAlign: 'right', mt: 2 }}>
            {!approvalProperties
              ? ''
              : isSubmitting
                ? (
                  <LoadingButton
                    loading
                    loadingPosition='start'
                    startIcon={<SaveIcon />}
                    variant='outlined'
                  >
                    Run Optimization
                  </LoadingButton>
                  )
                : <Button
                    variant='contained'
                    endIcon={<Tooltip title='Refresh the screen after 10 minutes to see latest opt-run data' placement='top-end'><InfoOutlined /></Tooltip>}
                    onClick={() => handleSubmitHandler()}
                  >Run Optimization
                  </Button>}
          </Box>
          )
        : ''}
    </>
  )
}
export default UpdatePropertiesModal
