import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { API } from 'aws-amplify'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import Loader from '../Loader'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CircleIcon from '@mui/icons-material/Circle'
import { Box, Button, Grid, IconButton, Modal, Step, StepIcon, StepLabel, Stepper, Typography } from '@mui/material'
import * as Sentry from '@sentry/react'
import dayjs from 'dayjs'
import DoneIcon from '../../assets/images/Check.svg'
import Success from '../../assets/images/success.svg'
import { useAuth } from '../../contexts/AuthContext'
import { useErrorToast } from '../../hooks/useErrorToast'
import AccountDetailsTab from './AccountDetailsTab'
import { accountOnboardingData } from './DataFormat'
import ModelSelectionTab from './ModelSelectionTab'
import NewConfirmationTab from './NewConfirmationTab'
import PersonalizationTab from './Personalization'
import PortfolioTab from './PortfolioTab'

const AccountOnboarding = () => {
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const [isLoading, setIsLoading] = useState(false)
  const [active, setActive] = useState('account-details')
  const [activeIndex, setActiveIndex] = useState(0)
  const [completed, setCompleted] = useState(false)
  const [accountDetails, setAccountDetails] = useState({})
  const [taxlotData, setTaxlotData] = useState({})
  const [personalization, setPersonalizationData] = useState({})
  const [fileIdExists, setFileIdExists] = useState(0)
  const [selectedStrategy, setSelectedStrategy] = useState({})
  const [loading, setLoading] = useState(true)
  const [strategies, setStrategies] = useState([])
  const accountDetailsFormRef = useRef()
  const taxlotDetailsFormRef = useRef()
  const [modalShow, setModalShow] = useState(false)
  const [successMessage, setSuccessMessage] = useState('on Boarding...')
  const [showSecondMsg, setShowSecondMsg] = useState(false)
  const [showThirdMsg, setShowThirdMsg] = useState(false)
  const [accountTypeMsg, setAccountTypMsg] = useState()
  const [isTaxlotDetailsFormRef, setIsTaxlotDetailsFormRef] = useState(false)
  const [isAccountDetailsFormRef, setIsAccountDetailsFormRef] = useState(false)
  const [taxStates, setTaxStates] = useState([])
  const [sellLogicCodes, setSellLogicCodes] = useState([])
  const [custodianList, setCustodianList] = useState([])

  // GET API for custodian dropdown data
  const getCustodianType = () => {
    API.get('baseUriTransactionalMaster', `transactional-master/v1/${user.userGroup}/custodians`)
      .then(response => {
        if (response?.data) {
          setCustodianList(response.data)
        }
      }).catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const getTaxStates = async () => {
    API.get('baseUriTransactionalMaster', `transactional-master/v1/${user.userGroup}/tax-states`, {
      queryStringParameters: {}
    })
      .then((response) => {
        if (response.data) {
          setTaxStates([...response.data])
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const getSellLogicCodes = async () => {
    API.get('baseUriTransactionalMaster', `transactional-master/v1/${user.userGroup}/sell-logics`, {
      queryStringParameters: {}
    })
      .then((response) => {
        if (response.data) {
          setSellLogicCodes([...response.data])
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }
  useEffect(() => {
    if (user) {
      getTaxStates()
      getSellLogicCodes()
      getCustodianType()
    }
  }, [user])
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    borderRadius: '4px'
  }

  const [selectedCustodian, setselectedCustodian] = useState({ custodianId: '', custodianName: '' })
  const steps = [
    {
      id: 1,
      name: 'account-details'
    },
    {
      id: 2,
      name: 'upload-portfolio'
    },
    {
      id: 3,
      name: 'select-model'
    },
    {
      id: 4,
      name: 'personalization'
    },
    {
      id: 5,
      name: 'confirmation'
    }
  ]
  const isCharlesSchwabAndUrlUpload = selectedCustodian?.custodianName === 'Charles Schwab' && taxlotData?.uploadType === 'urlupload'

  const gotoNext = () => {
    if (activeIndex === 0 && !isAccountDetailsFormRef) {
      accountDetailsFormRef.current.click()
    } else if (activeIndex === 1 && !isTaxlotDetailsFormRef) {
      taxlotDetailsFormRef.current.click()
    } else if (activeIndex === 2 && Object.keys(selectedStrategy).length === 0) {
      showError('Please select strategy')
    } else if (activeIndex === 3 && personalization?.error) {
      const hasError = Object.entries(personalization?.error).some(([name, error]) => error)
      if (hasError) {
        showError('Please enter valid values')
      } else {
        if (activeIndex + 1 < steps?.length) {
          setActiveIndex(activeIndex + 1)
          setActive(steps[activeIndex + 1].name)
        }
      }
    } else {
      if (activeIndex === 0) {
        setIsAccountDetailsFormRef(false)
      } else if (activeIndex === 1) {
        setIsTaxlotDetailsFormRef(false)
      }
      if (activeIndex + 1 < steps?.length) {
        setActiveIndex(activeIndex + 1)
        setActive(steps[activeIndex + 1].name)
      }
    }
  }

  const setAccountInformation = (data) => {
    if (data?.masterAccountId !== accountDetails?.masterAccountId || data?.custodianNumber !== accountDetails?.custodianNumber || data?.taxManaged !== accountDetails?.taxManaged) {
      setTaxlotData({})
    }
    setIsAccountDetailsFormRef(true)
    setAccountDetails(data)
    gotoNext()
  }

  const setTaxlotInformation = (data) => {
    setIsTaxlotDetailsFormRef(true)
    setTaxlotData(data)
    gotoNext()
  }
  const gotoPrev = () => {
    if (activeIndex - 1 > -1) {
      setActiveIndex(activeIndex - 1)
      setActive(steps[activeIndex - 1].name)
    }
    setFileIdExists(taxlotData.fileId)
  }
  const closeModal = () => {
    setModalShow(false)
  }
  const checkAccountType = () => {
    if (accountDetails.accountType === 'transition') {
      setAccountTypMsg('Account will be live soon in 15 mins')
    } else {
      setAccountTypMsg('Account will be live soon in 24 Hours')
      setShowThirdMsg(true)
    }
  }

  const submitForm = () => {
    setIsLoading(true)
    let data = {
      accountType: accountDetails.accountType?.toUpperCase(),
      accountName: accountDetails.name,
      clientEmail: accountDetails.email,
      fundingSource: taxlotData.fundingSource,
      sellLogicId: JSON.parse(accountDetails.selllogicCode).sellLogicId,
      isTaxManaged: accountDetails.taxManaged,
      minCashLevel: accountDetails.minCashLevel,
      maxShortTermGain: accountDetails.shortTermTaxGain,
      maxLongTermGain: accountDetails.longTermTaxGain,
      portSpecId: selectedStrategy.portSpecId
    }
    data.restrictedSecurities = accountDetails.securities?.map((item) => {
      return {
        restrictionId: item.restrictionCode.restrictionId,
        instrumentId: item.securityName[0].instrId
      }
    })
    if (accountDetails.substitutes?.length > 0) {
      data.substitution = accountDetails.substitutes?.map((item) => {
        return {
          sourceInstrumentId: item.sourceName[0].instrId,
          targetInstrumentId: item.targetName[0].instrId
        }
      })
    }
    if (accountDetails.accountType === 'trading') {
      data.custodianId = selectedCustodian?.custodianId
      data.custodianAccountNumber = accountDetails.custodianNumber
      if (selectedCustodian?.custodianName === 'Charles Schwab') {
        data.masterAccountID = accountDetails.masterAccountId
      }
    }
    if (accountDetails.taxManaged) {
      data = {
        ...data,
        taxSensitivity: accountDetails.taxSensitivity,
        taxStateId: JSON.parse(accountDetails.taxState).stateId,
        shortTermTaxRate: accountDetails.shortTermTaxRate,
        longTermTaxRate: accountDetails.longTermTaxRate,
        advancedTaxes: accountDetails.showAdvancedTax
      }
      if (accountDetails.showAdvancedTax) {
        data = {
          ...data,
          extRealizedLongGL: accountDetails.externalLongGainLoss,
          extRealizedShortGL: accountDetails.externalShortGainLoss,
          advShortTermTaxRate: accountDetails.advancedShortTermTaxRate,
          advLongTermTaxRate: accountDetails.advancedLongTermTaxRate
        }
      }
    }

    // Format the data
    data = accountOnboardingData(data, personalization, selectedStrategy, accountDetails, taxlotData)
    if (accountDetails.accountType === 'transition') {
      setAccountTypMsg('Account will be live soon in 15 mins')
    } else {
      setAccountTypMsg('Account will be live soon in 24 Hours')
    }
    let accountId = ''
    API.post(
      'baseURL',
      `account-master/v1/${user.userGroup}/accounts/onboard`,
      { body: { ...data } }
    )
      .then((response) => {
        setModalShow(true)
        setSuccessMessage(response?.message)

        if (taxlotData.fundingSource === 'CASH') {
          const cashdata = {
            accountId: response?.data?.accountId,
            cash: Number(taxlotData?.totalCashValue)
          }
          API.post(
            'baseURL',
            `account-master/v1/${user.userGroup}/account-imports/submit`,
            { body: { ...cashdata } }
          )
            .then((response) => {
              if (response) {
                setShowSecondMsg(true)
              }
            })
            .catch((error) => {
              showError(error.response?.data?.errorInfo?.userMessage || error.message)
              Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
            })
        } else if (taxlotData.fundingSource === 'SECURITY') {
          accountId = response?.data?.accountId
          const formattedData = taxlotData?.stagedData?.map((e) => {
            return {
              symbol: e.symbol,
              purchase_date: e.purchaseDate ? typeof e.purchaseDate !== 'string' ? dayjs(e.purchaseDate).format('YYYY-MM-DD') : e.purchaseDate.split('T')[0] : '',
              purchase_cost: (e.symbol === '__CASH' || e.symbol === '__USD') ? 1 : e.purchaseCost,
              shares: e.shares,
              instrId: e?.instrId,
              ...{ ...isCharlesSchwabAndUrlUpload ? { org_purchase_price: e.orgPurchasePrice, schwabLotMv: e?.schwabLotMv, schwabLastClosePrice: e?.schwabLastClosePrice } : {} }
            }
          })
          const requestBody = {
            securities: formattedData,
            ...(isCharlesSchwabAndUrlUpload
              ? {
                  schwabPortfolioMarketValue: taxlotData?.validatedPortfolioData?.schwabPortfolioMarketValue,
                  schwabTotalLots: taxlotData?.validatedPortfolioData?.schwabTotalLots
                }
              : {})
          }

          API.post(
            'baseURL',
            `account-master/v1/${user.userGroup}/account-imports/submit/${taxlotData.fileId}?account-id=${response?.data?.accountId}`,
            { body: requestBody }
          )
            .then((response) => {
              if (response) {
                setShowSecondMsg(true)
              }
            })
            .catch((error) => {
              showError(error.response?.data?.errorInfo?.userMessage)
              Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
            })
        } else if (taxlotData.fundingSource === 'CASH_SECURITY') {
          accountId = response?.data?.accountId
          const formattedData = taxlotData.stagedData?.map((e) => {
            return {
              symbol: e.symbol,
              purchase_date: e.purchaseDate ? typeof e.purchaseDate !== 'string' ? dayjs(e.purchaseDate).format('YYYY-MM-DD') : e.purchaseDate.split('T')[0] : '',
              purchase_cost: (e.symbol === '__CASH' || e.symbol === '__USD') ? 1 : e.purchaseCost,
              shares: e.shares,
              instrId: e?.instrId,
              ...{ ...isCharlesSchwabAndUrlUpload ? { org_purchase_price: e.orgPurchasePrice, schwabLotMv: e?.schwabLotMv, schwabLastClosePrice: e?.schwabLastClosePrice } : {} }
            }
          })
          const requestBody = {
            securities: formattedData,
            ...(isCharlesSchwabAndUrlUpload
              ? {
                  schwabPortfolioMarketValue: taxlotData?.validatedPortfolioData?.schwabPortfolioMarketValue,
                  schwabTotalLots: taxlotData?.validatedPortfolioData?.schwabTotalLots
                }
              : {})
          }

          if (Object.keys(taxlotData.stagedData)?.length === 0) {
            const cashdata = {
              accountId: response?.data?.accountId,
              cash: taxlotData.totalCashValue
            }
            API.post(
              'baseURL',
              `account-master/v1/${user.userGroup}/account-imports/submit`,
              { body: { ...cashdata } }
            )
              .then((response) => {
                if (response) {
                  setShowSecondMsg(true)
                }
              })
              .catch((error) => {
                showError(error.response?.data?.errorInfo?.userMessage || error.message)
                Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
              })
          } else {
            API.post(
              'baseURL',
              `account-master/v1/${user?.userGroup}/account-imports/submit/${taxlotData?.fileId}?account-id=${response?.data?.accountId}`,
              { body: requestBody }
            )
              .then((response) => {
                if (response) {
                  setShowSecondMsg(true)
                }
              })
              .catch((error) => {
                showError(error.response?.data?.errorInfo?.userMessage || error.message)
                Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
              })
          }
        }
        if (accountDetails.accountType === 'trading') {
          const withdrawal = personalization?.cashConstraintData?.find(item => item.propertyDesc === 'Withdrawal' && item.value !== '')
          if (withdrawal && withdrawal?.value) {
            const withdrawalValue = withdrawal?.value
            accountId = response.data.accountId
            const raiseCashReqData = {
              requestType: 'CASH_RAISE',
              title: 'Onboarding withdrawal',
              amount: withdrawalValue,
              comment: 'Account onboarding cash withdrawal'
            }
            API.post(
              'baseRequestURL',
              `request/v1/${user.userGroup}/requests/${accountId}`,
              { body: { ...raiseCashReqData } }
            )
              .then((response) => {
                if (response) {
                  // Do something.
                }
              })
              .catch((error) => {
                showError(error.message)
                Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
              })
            // use withdrawalValue as needed
          }
        }
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        showError(error?.response?.data?.errorInfo?.userMessage || error?.message)
        Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
      })
  }

  // handle selected custodian data received from AccountDetailsTab
  const handleSelectedCustodian = (data) => {
    setselectedCustodian(data)
  }

  useEffect(() => {
    const items = { ...personalization }
    if (items.sectorData) {
      delete items.sectorData
    }
    if (items.industryData) {
      delete items.industryData
    }
    if (items.factorUtilData) {
      delete items.factorUtilData
    }

    setPersonalizationData(items)
  }, [selectedStrategy])

  // prefetch strategies list to prevent strategies api fetch every time on select model tab
  const getStrategyList = async () => {
    API
      .get('baseStrategyURL', `strategy/v1/${user.userGroup}/sponsors/strategies`)
      .then(response => {
        if (response.data) {
          const strategyListData = response.data.map((item, index) => {
            const result = {
              id: index + 1, ...item
            }
            return result
          })
          setStrategies(strategyListData)
          if (selectedStrategy && selectedStrategy.id) {
            const selectedItem = strategyListData.filter((item) => item.id === selectedStrategy.id)
            setSelectedStrategy(selectedItem[0])
          }
          setLoading(false)
        }
      })
      .catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        setLoading(false)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  useEffect(() => {
    if (user) {
      getStrategyList()
    }
  }, [user])

  const stepLabels = [
    '1. Account Details',
    '2. Upload Portfolio',
    '3. Select Model',
    '4. Personalization',
    '5. Confirmation'
  ]

  return (
    <Box className='onboarding-page'>
      {isLoading ? <Loader /> : ''}
      <Box sx={{ display: 'flex' }} mb={4} pt={3}>
        <Grid container alignItems='flex-end'>
          <Grid item sm={1}>
            <Typography className='page-title'>Onboarding</Typography>
          </Grid>
          <Grid item sm={10} mt={3} px={5}>
            <Stepper activeStep={activeIndex} alternativeLabel sx={{ marginLeft: '15px' }}>
              {stepLabels?.map((label, index) => (
                <Step
                  key={label}
                  completed={index < activeIndex}
                  className={index === activeIndex ? 'current' : ''}
                >
                  <StepLabel
                    sx={{
                      '.MuiStepLabel-label': {
                        marginTop: '-50px !important'
                      }
                    }}
                    StepIconComponent={(props) =>
                      index === activeIndex
                        ? <StepIcon {...props} icon={<CircleIcon style={{ color: '#175cc2' }} />} />
                        : index < activeIndex
                          ? <StepIcon {...props} icon={<CheckCircleIcon color='success' />} />
                          : <StepIcon {...props} icon={<CircleIcon style={{ color: '#eceff1' }} />} />}
                    iconcontainerstyle={{ flexDirection: 'column', alignItems: 'center' }}
                  >
                    <Typography
                      variant='subtitle1'
                      sx={{ fontWeight: 700, fontSize: '14px', whiteSpace: 'nowrap' }}
                    >
                      {label}
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item sm={1}>
            <Box sx={{ display: 'flex', width: '34px', height: '34px' }}>
              {active === 'account-details'
                ? <IconButton className='btn-grey rounded-circle' onClick={gotoNext} sx={{ width: '34px', height: '34px' }}>
                  <KeyboardArrowRight />
                  </IconButton>
                : null}
              {active === 'upload-portfolio'
                ? <>
                  <IconButton className='btn-grey rounded-circle' onClick={gotoPrev} sx={{ width: '34px', height: '34px', marginRight: '10px' }}>
                    <KeyboardArrowLeft />
                  </IconButton>
                  <IconButton className='btn-grey rounded-circle' onClick={gotoNext} sx={{ width: '34px', height: '34px' }}>
                    <KeyboardArrowRight />
                  </IconButton>
                </>
                : null}
              {active === 'select-model'
                ? <>
                  <IconButton className='btn-grey rounded-circle' onClick={gotoPrev} sx={{ width: '34px', height: '34px', marginRight: '10px' }}>
                    <KeyboardArrowLeft />
                  </IconButton>
                  <IconButton className='btn-grey rounded-circle' onClick={gotoNext} sx={{ width: '34px', height: '34px' }}>
                    <KeyboardArrowRight />
                  </IconButton>
                </>
                : null}
              {active === 'personalization'
                ? <>
                  <IconButton className='btn-grey rounded-circle' onClick={gotoPrev} sx={{ width: '34px', height: '34px', marginRight: '10px' }}>
                    <KeyboardArrowLeft />
                  </IconButton>
                  <IconButton className='btn-grey rounded-circle' onClick={gotoNext} sx={{ width: '34px', height: '34px' }}>
                    <KeyboardArrowRight />
                  </IconButton>
                  </>
                : null}
              {active === 'confirmation'
                ? <IconButton className='btn-grey rounded-circle' onClick={gotoPrev} sx={{ width: '34px', height: '34px', marginRight: '10px' }}>
                  <KeyboardArrowLeft />
                </IconButton>
                : null}
              {activeIndex === 4
                ? <Button
                    sx={{
                      padding: '10px 45px'
                    }}
                    variant='contained'
                    onClick={() =>
                      activeIndex === 4
                        ? submitForm() || checkAccountType()
                        : setCompleted(false)}
                  >
                  Submit
                </Button>
                : null}
            </Box>
          </Grid>
        </Grid>
      </Box>
      {active === 'account-details'
        ? <AccountDetailsTab
            custodianList={custodianList}
            taxStates={taxStates}
            sellLogicCodes={sellLogicCodes}
            submitRef={accountDetailsFormRef}
            setData={setAccountInformation}
            data={accountDetails}
            custodianOptions={selectedCustodian}
            setselectedCustodian={handleSelectedCustodian}
          />
        : ''}
      {active === 'upload-portfolio'
        ? <PortfolioTab
            submitRef={taxlotDetailsFormRef}
            setData={setTaxlotInformation}
            data={taxlotData}
            custodianData={{ selectedCustodian, custodianNumber: accountDetails?.custodianNumber, masterAccountId: accountDetails?.masterAccountId, taxManaged: accountDetails?.taxManaged }}
            fileIdExists={fileIdExists}
            accountDetails={accountDetails}
          />
        : ''}
      {active === 'select-model'
        ? <ModelSelectionTab
            setData={setSelectedStrategy}
            data={selectedStrategy}
            strategies={strategies}
            getStrategyList={getStrategyList}
            loading={loading}
          />
        : ''}
      {active === 'personalization'
        ? <PersonalizationTab
            accountDetails={accountDetails}
            taxlotData={taxlotData}
            strategy={selectedStrategy}
            personalization={personalization}
            setPersonalizationData={setPersonalizationData}
          />
        : ''}
      {active === 'confirmation'
        ? <NewConfirmationTab
            accountDetails={accountDetails}
            taxlotData={taxlotData}
            strategy={selectedStrategy}
            personlization={personalization}
            selectedCustodian={selectedCustodian}
          />
        : ''}
      <Modal
        open={modalShow}
        onClose={closeModal}
        disableEscapeKeyDown
        aria-labelledby='contained-modal-title-vcenter'
        centered
        hideBackdrop
      >
        <Box className='blur-background-parent'>
          <Box sx={style}>
            <Box className='oboardSuccess'>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
                <img src={Success} alt='' height={100} width={100} />
              </Box>
              <Typography variant='h4' align='center' mb={2} sx={{ fontFamily: 'Open Sans', fontSize: '24px', fontWeight: '600' }}>
                {successMessage}
              </Typography>
              {showSecondMsg && (
                <Box className='apimsgbox'>
                  <ul className='apimsg'>
                    <li className='msglist'>
                      <Box sx={{ width: '10%' }} mb={1}>
                        <img
                          src={DoneIcon}
                          alt=''
                          height={25}
                          width={25}
                        />
                      </Box>{' '}
                      <Typography>Update portfolio details</Typography>
                    </li>
                    <li className='msglist'>
                      <Box sx={{ width: '10%' }} mb={1}>
                        <img
                          src={DoneIcon}
                          alt=''
                          height={25}
                          width={25}
                        />
                      </Box>{' '}
                      <Typography>Generate account summary</Typography>
                    </li>
                    <li className='msglist'>
                      <Box sx={{ width: '10%' }} mb={1}>
                        <img
                          src={DoneIcon}
                          alt=''
                          height={25}
                          width={25}
                        />
                      </Box>{' '}
                      <Typography>Calculate Risk Data</Typography>
                    </li>
                    {showThirdMsg && (
                      <li className='msglist'>
                        <Box sx={{ width: '10%' }} mb={1}>
                          <img
                            src={DoneIcon}
                            alt=''
                            height={25}
                            width={25}
                          />
                        </Box>{' '}
                        <Typography>Enable account for optimization</Typography>
                      </li>
                    )}
                    {!showThirdMsg && (
                      <li className='msglist'>
                        <Box sx={{ width: '10%' }} mb={1}>
                          <img
                            src={DoneIcon}
                            alt=''
                            height={25}
                            width={25}
                          />
                        </Box>{' '}
                        <Typography>Initiate transition proposal</Typography>
                      </li>
                    )}
                    <li className='msglist'>
                      <Box sx={{ width: '10%' }} mb={3}>
                        <img
                          src={DoneIcon}
                          alt=''
                          height={25}
                          width={25}
                        />
                      </Box>{' '}
                      <Typography>Check policy breaks on portfolio</Typography>
                    </li>
                  </ul>
                </Box>
              )}
              <Link to='/book-of-business' style={{ display: 'flex', justifyContent: 'center', textDecoration: 'none' }}>
                <Button
                  variant='contained'
                  sx={{
                    padding: '6px 35px',
                    mt: 2
                  }}
                >
                  Go to dashboard
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default AccountOnboarding
