import { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import * as Sentry from '@sentry/react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { styled } from '@mui/system'
import { Box, IconButton, Skeleton, Tooltip, Typography } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import { useAuth } from '../contexts/AuthContext'
import { moduleConfig } from '../contexts/data'
import { ACCESS_LEVEL } from '../contstants/constants'
import { useErrorToast } from '../hooks/useErrorToast'
import PolicyStatusPopup from './PolicyStatusPopup'

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  marginBottom: '1.25rem',
  '@media (min-width: 768px)': {
    flexDirection: 'row',
    alignItems: 'center'
  }
})

const StrategyTitle = ({ title, strategyName, strategyCode, infoLoading, children }) => {
  const { user, checkAccess } = useAuth()
  const { showError } = useErrorToast()
  const params = useParams()
  const location = useLocation()
  const [showPolicyPopup, setShowPolicyPopup] = useState(false)
  const [policyData, setPolicyData] = useState({})

  const fetchStrategyPolicy = (strategyId) => {
    API.post('baseDataValidation', `data-validation/v1/${user.userGroup}/policies/strategy`, {
      body: {
        strategyIds: [strategyId]
      }
    })
      .then(res => {
        if (res?.data?.length) {
          setPolicyData(res?.data[0])
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const getStatusColor = (status) => {
    if (status === 'green')
      return '#3BBFA3'
    else if (status === 'red')
      return '#FF6161'
    else if (status === 'yellow')
      return '#D29922'
    else if (status === 'gray')
      return '#0000004d'
    return '#c9c9c9'
  }

  useEffect(() => {
    if (title === 'Strategy' && checkAccess(moduleConfig.MODEL, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.STRATEGY_PORTFOLIO, component_name: moduleConfig.VIEW_MODEL_POLICY_CHECK })) {
      fetchStrategyPolicy(params?.strategyId)
    }
  }, [user])

  return (
    <>
      {children}
      <Container>
        <Box className='page-title' sx={{ paddingTop: '0 !important' }}>{title}</Box>
        <Box className='client' sx={{ marginTop: '10px' }}>
          <Box className='divider bg-yellow' mr={2} />
          <Box>
            <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
              {infoLoading
                ? <>
                  <Skeleton variant='text' sx={{ width: '150px', fontSize: '20px' }} />
                </>
                : <Typography variant='h6' sx={{ display: 'flex', alignItems: 'center' }}>{strategyName || ''}</Typography>}
              {
                title === 'Strategy' && checkAccess(moduleConfig.MODEL, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.STRATEGY_PORTFOLIO, component_name: moduleConfig.VIEW_MODEL_POLICY_CHECK }) && policyData?.strategyPolicyColorStatus ? (
                  <Tooltip title={'Strategy Policies'}>
                    <IconButton onClick={() => setShowPolicyPopup(true)} sx={{ p: '6px' }}>
                      <CircleIcon sx={{ color: getStatusColor(policyData?.strategyPolicyColorStatus), height: '12px', width: '12px' }} />
                    </IconButton>
                  </Tooltip>
                ) : ''
              }
            </Box>
            {/* <Box
                component='span'
                sx={{
                  backgroundColor: '#50CAF1',
                  color: 'common.white',
                  borderRadius: '16px',
                  marginLeft: '0.75rem',
                  paddingLeft: '0.5rem',
                  paddingRight: '0.5rem',
                  fontSize: '0.625rem'
                }}
              >
                {filterSecuredata[0]?.strTypeCode ? filterSecuredata[0]?.strTypeCode?.charAt(0)?.toUpperCase() + filterSecuredata[0]?.strTypeCode?.slice(1)?.toLowerCase() : ''}
              </Box> */}
            {infoLoading
              ? <Skeleton variant='text' sx={{ width: '50px', fontSize: '14px', mr: 1, display: 'inline-block' }} />
              : (
                <Typography variant='small' sx={{ fontSize: '0.875rem', mr: 1 }}>
                  {strategyCode || ''}
                </Typography>
              )}
            <Link to={location.pathname.replace(params.strategyId, '')} style={{ color: '#3A76D4' }}>
              Switch Strategy
            </Link>
          </Box>
        </Box>
      </Container>
      {title === 'Strategy' && <PolicyStatusPopup showPolicyPopup={showPolicyPopup} onClose={() => setShowPolicyPopup(false)} data={policyData} canDownloadJson={checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.ACCOUNT_DASHBOARD, component_name: moduleConfig.DOWNLOAD_ACCOUNT_POLICY_CHECK })} title='strategy' />}
    </>
  )
}

export default StrategyTitle
