import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { API } from 'aws-amplify'
import * as Yup from 'yup'
import { ErrorMessage, Field, Formik } from 'formik'
import * as Sentry from '@sentry/react'
import { Autocomplete, Box, Button, Card, CardContent, Divider, Grid, IconButton, Skeleton, TextField, Typography } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import Edit from '@mui/icons-material/Edit'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { useSuccessToast } from '../../../hooks/useSuccessToast'
import Loader from '../../Loader'
import MemberTable from './MemberTable'
import MemberSelectionPopup from './MemberSelectionPopup'
import SponsorSelectionPopup from './SponsorSelectionPopup'
import './aggregateOnboarding.scss'

const AggregateOnboarding = () => {
  const location = useLocation()
  const formikRef = useRef(null)
  const navigate = useNavigate()
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const { showSuccess } = useSuccessToast()
  const userData = useSelector(state => state.user)

  const [isAccountLoading, setIsAccountLoading] = useState(false)
  const [accountData, setAccountData] = useState([])
  const [copyAccountData, setCopyAccountData] = useState([])
  const [accountRowSelectionModel, setAccountRowSelectionModel] = useState([])

  const [isGroupLoading, setIsGroupLoading] = useState(false)
  const [groupData, setGroupData] = useState([])
  const [copyGroupData, setCopyGroupData] = useState([])
  const [groupRowSelectionModel, setGroupRowSelectionModel] = useState([])

  const [isUmaLoading, setIsUmaLoading] = useState(false)
  const [umaData, setUmaGroupData] = useState([])
  const [copyUmaGroupData, setCopyUmaGroupData] = useState([])
  const [umaRowSelectionModel, setUmaRowSelectionModel] = useState([])

  const [showMemberPopup, setShowMemberPopup] = useState(false)
  const [selectedMembers, setSelectedMembers] = useState([])

  const [isGroupTypeFetching, setIsGroupTypeFetching] = useState(false)
  const [groupTypes, setGroupTypes] = useState([])

  const [isNameEditMode, setIsNameEditMode] = useState(false)
  const [groupName, setGroupName] = useState('')
  const [aggGroupData, setAggGroupData] = useState({ aggGroupName: '', groupType: '', aggGroupCd: '' })
  const [isGroupInfoLoading, setIsGroupInfoLoading] = useState(false)
  const [loadingCounter, setLoadingCounter] = useState(0)
  const [selectedSponsor, setSelectedSponsor] = useState(location?.state?.sponsorData || null)
  const [showSponsorSelection, setShowSponsorSelection] = useState(false)
  const [isSponsorsLoading, setIsSponsorsLoading] = useState(!userData?.allowedSponsorList?.length)
  const [sponsorList, setSponsorsList] = useState(userData?.allowedSponsorList || [])

  const fetchSponsorsList = () => {
    setIsSponsorsLoading(true)
    API.get('baseSponserURL', `data-maintenance/v1/${user?.userGroup}/sponsorIds`)
      .then(response => {
        if (response?.data?.data?.length) {
          setSponsorsList(response.data.data)
          if (user?.userGroup === 'adv-classic')
            setSelectedSponsor(response.data.data[0])
        }
      })
      .catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => setIsSponsorsLoading(false))
  }

  const fetchAggregateGroupData = () => {
    setIsGroupInfoLoading(true)
    API.get('baseUriAggregateMaster', `aggregate-master/v1/${user?.userGroup}/aggregate-grouping/${location.state?.aggGroupId}`)
      .then(response => {
        if (response?.data.length) {
          setAggGroupData({ aggGroupName: response.data[0]?.aggGroupName, groupType: response?.data[0]?.groupLabel, aggGroupCd: response.data[0]?.aggGroupCd })
          setSelectedMembers(response.data[0]?.members || [])
          setGroupName(response.data[0]?.aggGroupName)
        }
      })
      .catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setIsGroupInfoLoading(false)
      })
  }

  const fetchAccountsData = () => {
    setIsAccountLoading(true)
    API.get('baseURL', `account-master/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/summary/details`, {
      queryStringParameters: {
        page: 1,
        perpage: 200,
        ...user?.userGroup === 'adv-classic'
          ? {
            resources: encodeURIComponent(JSON.stringify({
              serviceId: 'aggregate',
              resourceId: 'group-onboarding'
            }))
          }
          : {}
      }
    })
      .then(response => {
        if (response?.data?.accountsSummaryDetails) {
          const tradingAccs = response?.data?.accountsSummaryDetails.filter(obj => obj?.accountType !== 'TRANSITION')
          setAccountData(tradingAccs)
          setCopyAccountData(tradingAccs)
        }
      })
      .catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setIsAccountLoading(false)
      })
  }

  const fetchGroupData = () => {
    setIsGroupLoading(true)
    API.get(
      'baseUriAggregateMaster',
      `aggregate-master/v1/${user?.userGroup}/aggregate-grouping`
    )
      .then((response) => {
        if (response?.data?.length > 0) {
          const tempGroupData = response.data?.filter(obj => obj?.groupType !== 'UMA' && obj?.aggGroupId !== location?.state?.aggGroupId)
          setCopyGroupData(tempGroupData)
          setGroupData(tempGroupData)
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setIsGroupLoading(false)
      })
  }

  const fetchUmaData = () => {
    setIsUmaLoading(true)
    API.get(
      'baseUriAggregateMaster',
      `aggregate-master/v1/${user?.userGroup}/agg-group`,
      { queryStringParameters: { groupType: 'UMA' } }
    )
      .then((response) => {
        if (response?.data?.length > 0) {
          setUmaGroupData(response?.data)
          setCopyUmaGroupData(response?.data)
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setIsUmaLoading(false)
      })
  }

  const fetchGroupTypes = () => {
    setIsGroupTypeFetching(true)
    API.get(
      'baseUriTransactionalMaster',
      `transactional-master/v1/${user?.userGroup}/aggregate-group-type`
    )
      .then((response) => {
        if (response?.data?.length > 0) {
          setGroupTypes(response.data.filter(grp => grp?.groupType !== 'UMA'))
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setIsGroupTypeFetching(false)
      })
  }

  const createAggregateMembers = (members) => {
    setLoadingCounter(prev => prev + 1)
    API.post('baseUriAggregateMaster', `aggregate-master/v1/${user?.userGroup}/aggregate-member/${location.state?.aggGroupId}`, {
      body: members
    })
      .then((res) => {
        if (res?.success) {
          showSuccess(res?.message)
          setSelectedMembers(res?.data)
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setLoadingCounter(prev => prev - 1)
      })
  }

  const deleteAggregateMembers = (id) => {
    setLoadingCounter(prev => prev + 1)
    API.del('baseUriAggregateMaster', `aggregate-master/v1/${user?.userGroup}/aggregate-member`, {
      body: {
        aggregateMemberId: [id]
      }
    })
      .then((res) => {
        if (res?.success) {
          showSuccess(res?.message)
          setSelectedMembers(selectedMembers.filter(member => member.aggGrpMemberId !== id))
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setLoadingCounter(prev => prev - 1)
      })
  }

  const updateAggregateData = (aggGroupName) => {
    setLoadingCounter(prev => prev + 1)
    API.patch('baseUriAggregateMaster', `aggregate-master/v1/${user?.userGroup}/aggregate-grouping/${location?.state?.aggGroupId}`, {
      body: {
        aggGroupName
      }
    })
      .then((res) => {
        if (res?.success) {
          showSuccess(res?.message)
          setAggGroupData(prev => ({ ...prev, aggGroupName }))
          setIsNameEditMode(false)
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setLoadingCounter(prev => prev - 1)
      })
  }

  const createGroup = (values) => {
    setLoadingCounter(prev => prev + 1)
    API.post('baseUriAggregateMaster', `aggregate-master/v1/${user?.userGroup}/aggregate-grouping`, {
      body: {
        sponsorId: selectedSponsor?.sponsorId,
        aggGroupName: values?.aggGroupName,
        groupType: values?.aggGroupType?.groupType,
        members: selectedMembers?.map(members => ({ memberType: members?.memberType, memberRefId: members?.memberRefId }))
      }
    })
      .then((res) => {
        if (res.success) {
          showSuccess(res?.message)
          setTimeout(() => {
            navigate('/aggregate/aggregate-dashboard')
          }, [1000])
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setLoadingCounter(prev => prev - 1)
      })
  }

  useEffect(() => {
    if (!user) return
    // fetch sponsor list in case of no data in redux and select first sponsor if role is adv-classic
    if (!sponsorList.length && !location?.state?.edit)
      fetchSponsorsList()
    else if (!location?.state?.edit && user?.userGroup === 'adv-classic') {
      if (!isSponsorsLoading && !sponsorList.length) {
        showError('No sponsor available for creating group.')
      } else
        setSelectedSponsor(sponsorList[0])
    }
    if (user?.userGroup !== 'adv-classic' && !selectedSponsor && !location?.state?.edit) {
      setShowSponsorSelection(true)
    }
    fetchAccountsData()
    fetchGroupData()
    fetchUmaData()
    if (location?.state?.edit)
      fetchAggregateGroupData()
    else
      fetchGroupTypes()
  }, [user])

  useEffect(() => {
    let newData = []
    if (!isAccountLoading && copyAccountData.length) {
      newData = copyAccountData.slice()
      selectedMembers.forEach(member => {
        if (member.memberType === 'ACCOUNT') {
          newData = newData.filter(acc => acc.accountId !== member.memberRefId)
        }
      })
      setAccountData(newData)
    }
    if (!isUmaLoading && copyUmaGroupData.length) {
      newData = copyUmaGroupData.slice()
      selectedMembers.forEach(member => {
        if (member.memberType === 'UMA') {
          newData = newData.filter(grp => grp.aggGroupId !== member.memberRefId)
        }
      })
      setUmaGroupData(newData)
    }
    if (!isGroupLoading && copyGroupData.length) {
      newData = copyGroupData.slice()
      selectedMembers.forEach(member => {
        if (member.memberType === 'GROUP') {
          newData = newData.filter(grp => grp.aggGroupId !== member.memberRefId)
        }
      })
      setGroupData(newData)
    }
  }, [selectedMembers, isAccountLoading, isUmaLoading, isGroupLoading])

  const deleteMember = (params) => {
    if (location?.state?.edit)
      deleteAggregateMembers(params?.row?.aggGrpMemberId)
    else {
      setSelectedMembers(selectedMembers?.filter(member => member?.memberRefId !== params?.row?.memberRefId))
      if (params?.row?.memberType === 'ACCOUNT')
        setAccountData(copyAccountData.filter(acc => acc.accountId !== params?.row?.memberRefId))
      if (params?.row?.memberType === 'UMA')
        setUmaGroupData(copyUmaGroupData.filter(grp => grp.aggGroupId !== params?.row?.memberRefId))
      if (params?.row?.memberType === 'GROUP')
        setGroupData(copyGroupData.filter(grp => grp.aggGroupId !== params?.row?.memberRefId))
    }
  }

  const addMembers = () => {
    if (location?.state?.edit) {
      const newMembers = []
      accountRowSelectionModel.forEach(selectedId => {
        const selectedGroup = copyAccountData.find(acc => acc.accountId === selectedId)
        newMembers.push({ memberRefId: selectedGroup.accountId, memberType: 'ACCOUNT' })
      })
      umaRowSelectionModel.forEach(selectedId => {
        const selectedGroup = copyUmaGroupData.find(acc => acc.aggGroupId === selectedId)
        newMembers.push({ memberRefId: selectedGroup.aggGroupId, memberType: 'UMA' })
      })
      groupRowSelectionModel.forEach(selectedId => {
        const selectedGroup = copyGroupData.find(acc => acc.aggGroupId === selectedId)
        newMembers.push({ memberRefId: selectedGroup.aggGroupId, memberType: 'GROUP' })
      })
      createAggregateMembers(newMembers)
    } else {
      const tempSelectedMembers = selectedMembers.slice()
      accountRowSelectionModel.forEach(selectedId => {
        const selectedGroup = copyAccountData.find(acc => acc.accountId === selectedId)
        tempSelectedMembers.push({ memberRefId: selectedGroup.accountId, memberRefName: selectedGroup.accountName, memberType: 'ACCOUNT' })
      })

      umaRowSelectionModel.forEach(selectedId => {
        const selectedGroup = copyUmaGroupData.find(grp => grp.aggGroupId === selectedId)
        tempSelectedMembers.push({ memberRefId: selectedGroup.aggGroupId, memberRefName: selectedGroup.aggGroupName, memberType: 'UMA' })
      })

      groupRowSelectionModel.forEach(selectedId => {
        const selectedGroup = copyGroupData.find(grp => grp.aggGroupId === selectedId)
        tempSelectedMembers.push({ memberRefId: selectedGroup.aggGroupId, memberRefName: selectedGroup.aggGroupName, memberType: 'GROUP' })
      })
      setSelectedMembers(tempSelectedMembers)
    }
    setShowMemberPopup(false)
    setAccountRowSelectionModel([])
    setUmaRowSelectionModel([])
    setGroupRowSelectionModel([])
  }

  const handlePopupClose = () => {
    setShowMemberPopup(false)
    setAccountRowSelectionModel([])
    setUmaRowSelectionModel([])
    setGroupRowSelectionModel([])
  }

  const handlePopupOpen = (value) => {
    setShowMemberPopup(value)
  }

  const handleSearchChange = (e) => {
    // filter data which includes name and does not include already selected members
    setUmaGroupData(!e.target.value
      ? copyUmaGroupData.filter(obj => selectedMembers.findIndex(member => member?.memberRefId === obj?.aggGroupId) === -1)
      : copyUmaGroupData.filter(obj => obj?.['aggGroupName']?.toLowerCase().includes(e.target.value?.toLowerCase()) && selectedMembers.findIndex(member => member?.memberRefId === obj?.aggGroupId) === -1))
    setAccountData(!e.target.value
      ? copyAccountData.filter(obj => selectedMembers.findIndex(member => member?.memberRefId === obj?.accountId) === -1)
      : copyAccountData.filter(obj => obj?.['accountName']?.toLowerCase().includes(e.target.value?.toLowerCase()) && selectedMembers.findIndex(member => member?.memberRefId === obj?.accountId) === -1))
    setGroupData(!e.target.value
      ? copyGroupData.filter(obj => selectedMembers.findIndex(member => member?.memberRefId === obj?.aggGroupId) === -1)
      : copyGroupData.filter(obj => obj?.['aggGroupName']?.toLowerCase().includes(e.target.value?.toLowerCase()) && selectedMembers.findIndex(member => member?.memberRefId === obj?.aggGroupId) === -1))
  }

  const submitForm = () => {
    if (formikRef.current) {
      formikRef.current.submitForm()
    }
  }

  const handleFormSubmit = (values) => {
    if (!selectedMembers.length) {
      showError('Minimum one member is required to create group.')
      return
    }
    createGroup(values)
  }

  const saveGroupName = () => {
    if (groupName.trim() !== aggGroupData.aggGroupName)
      updateAggregateData(groupName.trim())
    else
      setIsNameEditMode(false)
  }

  const closeNameEdit = () => {
    setIsNameEditMode(false)
    setGroupName(aggGroupData.aggGroupName)
  }

  const onNextClick = (newSponsor) => {
    setSelectedSponsor(newSponsor)
    setShowSponsorSelection(false)
  }

  const onSponsorSelectionClose = () => {
    if (!selectedSponsor && !location?.state?.edit) {
      navigate('/aggregate/aggregate-dashboard')
    }
    setShowSponsorSelection(false)
  }

  const handleNameChange = (e) => {
    const inputEle = e.target
    inputEle.setCustomValidity('')
    if (!inputEle.checkValidity()) {
      if (inputEle.validity.patternMismatch)
        inputEle.setCustomValidity('Group name must contain only alphanumeric characters and spaces.')
    }
    if (e.target.value.trim().length <= 200)
      setGroupName(e.target.value)
    inputEle.reportValidity()
  }

  const validationSchema = Yup.object({
    aggGroupName: Yup.string().trim().required('Aggregate Group Name is required')
      .test('alphanumeric', 'Aggregate Group Name can not contain special characters', value => {
        const regex = /^[0-9a-zA-z ]*$/
        return regex.test(value?.trim())
      }),
    aggGroupType: Yup.object().required('Group Type is required')
  })

  return <>
    {loadingCounter > 0 || isGroupInfoLoading ? <Loader /> : ''}
    <Box className='aggregate-onboarding'>
      <Grid container>
        <Grid container mb={2}>
          <Grid item xs={10} display='flex' alignItems='center' overflow='hidden'>
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {
                  isGroupInfoLoading
                    ? <Skeleton variant='text' sx={{ width: 120, fontSize: '26px' }} />
                    : <Typography className='page-title' sx={{ whiteSpace: 'nowrap' }}>{aggGroupData.groupType || 'Aggregate Group'}</Typography>
                }
                <Box sx={{
                  borderRadius: '4px',
                  width: '3.05px',
                  height: '53.8px',
                  mx: 2,
                  background: '#EFAE3A'
                }}
                />
              </Box>
              <Box sx={{ overflow: 'hidden' }}>
                {
                  !location?.state?.edit ? (
                    <>
                      <Typography id='background-change-hover' onClick={() => setIsNameEditMode(true)}>{selectedSponsor?.sponsorName || ''}</Typography>
                      <Box sx={{ display: 'flex' }}>
                        <Typography lineHeight='1.25rem' fontSize='0.875rem' mr={1}>{selectedSponsor?.sponsorCd || ''}</Typography>
                        {!location?.state?.edit && user?.userGroup !== 'adv-classic' && <Typography sx={{ fontSize: '14px', color: '#3476D4', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setShowSponsorSelection(true)}>Switch Sponsor</Typography>}
                      </Box>
                    </>
                  ) : isGroupInfoLoading
                    ? <Skeleton variant='text' sx={{ width: '150px', fontSize: '20px' }} />
                    : isNameEditMode ? (
                      <Box sx={{ height: '32px', display: 'flex', position: 'relative' }}>
                        <Box sx={{
                          position: 'relative', '&::after': {
                            backgroundColor: groupName.trim() === '' || !(/^[0-9a-zA-z ]*$/.test(groupName)) ? '#e74c3c' : '#1976d2'
                          }
                        }} className='border-animation'>
                          <form>
                            <input onChange={(e) => handleNameChange(e)} value={groupName} autoFocus autoComplete='off' id='aggregate-name-inp' required pattern='^[0-9a-zA-Z ]*$'></input>
                          </form>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                          <IconButton onClick={saveGroupName} sx={{ p: '4px' }} disabled={groupName.trim() === '' || !(/^[0-9a-zA-Z ]*$/.test(groupName))}>
                            <CheckIcon fontSize='small' />
                          </IconButton>
                          <IconButton onClick={closeNameEdit} sx={{ p: '4px' }}>
                            <ClearIcon fontSize='small' />
                          </IconButton>
                        </Box>
                      </Box>
                    ) : <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }} id='groupName'>
                      <Typography id='background-change-hover' onClick={() => setIsNameEditMode(true)}>{groupName}</Typography>
                      <IconButton id='edit-icon' onClick={() => setIsNameEditMode(true)}>
                        <Edit fontSize='small' />
                      </IconButton>
                    </Box>
                }
                {
                  location?.state?.edit && isGroupInfoLoading
                    ? <Skeleton variant='text' sx={{ width: '50px', fontSize: '14px' }} />
                    : <Typography lineHeight='1.25rem' fontSize='0.875rem' mr={1}>{aggGroupData.aggGroupCd}</Typography>
                }
              </Box>
            </>
          </Grid>
          {
            !location.state?.edit ? (
              <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                <Button variant='contained' onClick={submitForm}>Submit</Button>
              </Grid>
            ) : ''
          }
        </Grid>
        <Grid item xs={12}>
          {
            !location?.state?.edit ? (
              <Card className='card-layout'>
                <CardContent>
                  <Formik
                    innerRef={formikRef}
                    initialValues={{ aggGroupName: '', aggGroupType: null }}
                    validateOnChange
                    validationSchema={validationSchema}
                    onSubmit={({ aggGroupName, aggGroupType }, { setSubmitting, resetForm }) => {
                      handleFormSubmit({ aggGroupName, aggGroupType, setSubmitting, resetForm })
                    }}
                  >
                    {(props) => (
                      <form>
                        <Grid container spacing={2}>
                          <Grid item xs={8} sm={3.5}>
                            <TextField
                              id='aggGroupName'
                              name='aggGroupName'
                              variant='outlined'
                              label='Aggregate Group Name'
                              fullWidth
                              autoComplete='off'
                              sx={{
                                '.MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
                                  lineHeight: '1.25rem',
                                  top: '-5px'
                                },
                                '.MuiOutlinedInput-input': {
                                  padding: '10px'
                                }
                              }}
                              error={props.touched?.aggGroupName && Boolean(props.errors.aggGroupName)}
                              value={props.values.aggGroupName}
                              onChange={props.handleChange}
                            />
                            <ErrorMessage name='aggGroupName' component={Typography} sx={{ color: '#f05f5f', fontSize: '12px' }} />
                          </Grid>
                          <Grid item xs={8} sm={3.5}>
                            <Field name='aggGroupType'>
                              {({ field }) => (
                                <Autocomplete
                                  id='aggGroupType'
                                  value={field.value}
                                  loading={isGroupTypeFetching}
                                  loadingText={'Loading...'}
                                  disabled={location?.state?.edit}
                                  isOptionEqualToValue={(option, value) => option.groupTypeId === value.groupTypeId}
                                  options={groupTypes}
                                  getOptionLabel={(option) => option ? option?.groupLabel : ''}
                                  sx={{
                                    '.MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
                                      lineHeight: '1.25rem',
                                      top: '-5px'
                                    },
                                    '.MuiOutlinedInput-root': {
                                      paddingBlock: '5px'
                                    },
                                    '.MuiOutlinedInput-root .MuiAutocomplete-input': {
                                      padding: '5px 4px 5px 5px'
                                    }
                                  }}
                                  renderInput={(params) => <TextField {...params} label='Group Type' variant='outlined' error={props.touched?.aggGroupType && Boolean(props.errors.aggGroupType)} />}
                                  onChange={((e, newValue) => props.setFieldValue('aggGroupType', newValue))} />
                              )}
                            </Field>
                            <ErrorMessage name='aggGroupType' component={Typography} sx={{ color: '#f05f5f', fontSize: '12px' }} />
                          </Grid>
                        </Grid>
                      </form>
                    )}
                  </Formik>
                  <Divider sx={{ my: '20px' }} />
                  <MemberTable
                    selectedMembers={selectedMembers}
                    setShowMemberPopup={handlePopupOpen}
                    deleteMember={deleteMember}
                  />
                </CardContent>
              </Card>
            ) :
              <Card>
                <CardContent>
                  <MemberTable
                    selectedMembers={selectedMembers}
                    setShowMemberPopup={handlePopupOpen}
                    deleteMember={deleteMember}
                  />
                </CardContent>
              </Card>
          }
        </Grid>
      </Grid>
    </Box>
    {
      showMemberPopup ? (
        <MemberSelectionPopup
          addMembers={addMembers}
          handlePopupClose={handlePopupClose}
          showMemberPopup={showMemberPopup}
          handleSearchChange={handleSearchChange}
          isAccountLoading={isAccountLoading}
          isUmaLoading={isUmaLoading}
          isGroupLoading={isGroupLoading}
          accountData={accountData}
          umaData={umaData}
          groupData={groupData}
          accountRowSelectionModel={accountRowSelectionModel}
          umaRowSelectionModel={umaRowSelectionModel}
          groupRowSelectionModel={groupRowSelectionModel}
          setAccountRowSelectionModel={(newRowSelectionModel) => setAccountRowSelectionModel(newRowSelectionModel)}
          setUmaRowSelectionModel={(newRowSelectionModel) => setUmaRowSelectionModel(newRowSelectionModel)}
          setGroupRowSelectionModel={(newRowSelectionModel) => setGroupRowSelectionModel(newRowSelectionModel)}
        />
      ) : ''
    }
    {showSponsorSelection ? <SponsorSelectionPopup open={showSponsorSelection} onClose={onSponsorSelectionClose} onNextClick={onNextClick} selectedSponsorId={selectedSponsor ? [selectedSponsor?.sponsorId] : []} loading={isSponsorsLoading} data={sponsorList} /> : ''}
  </>
}

export default AggregateOnboarding