import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { API } from 'aws-amplify'
import * as Sentry from '@sentry/react'
import dayjs from 'dayjs'
import { VscPreview } from 'react-icons/vsc'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as DeleteSvg } from '../../../assets/images/pdf-customizer/svg/delete.svg'
import { ReactComponent as EditSvg } from '../../../assets/images/pdf-customizer/svg/Edit.svg'
import { ReactComponent as ShareSvg } from '../../../assets/images/pdf-customizer/svg/share.svg'
import {
  changeEditorName,
  changePreviousEditorName,
  reportDataSetPage,
  selectedTemplates
} from '../../../store/pdf-customizer-reducer/Action/chartDropAction.js'
import { chartDataState } from '../../../store/pdf-customizer-reducer/Action/chartStateAction.js'
import {
  deleteTemplates,
  fetchAllTemplates,
  getElements
} from '../../../utils/pdf-customizer/_data.js'
import './report-template-list.css'
import { useAuth } from '../../../contexts/AuthContext.js'
import { useErrorToast } from '../../../hooks/useErrorToast.js'
import {
  isLoadingSet,
  pageChange,
  userClickMode
} from '../../../store/pdf-customizer-reducer/Action/allPageAction'
import { storeTemplateData } from '../../../store/pdf-customizer-reducer/Reducer/template-reducer.js'
import AccountAggregateSelectionPopup from './AccountAggregateSelectionPopup.js'


const ReportTemplateRow = ({
  template,
  onClickPreview,
  onClickActions,
  onClickDelete
}) => (
  <tr className='border-bottom'>
    <td className='name_width padding-left-4 ps-sm-1 ps-md-3 ps-lg-4'>
      <div>
        <div className='template-title'>{template.report_name}</div>
        <p className='template-text padding-top-1'>{template.name}</p>
      </div>
    </td>
    <td className='Modified_width'>
      {dayjs(template.update_time).format('YYYY-MM-DD hh:mm A')}
    </td>
    <td className='Report_width text-align-center'>{template.report_type}</td>
    <td className='text-align-center'>
      <button title='Preview' onClick={onClickPreview} className='cursor-pointer'>
        <VscPreview color='#727474' size={16} />
      </button>
      <button onClick={onClickActions} className='cursor-pointer'>
        <EditSvg />
      </button>
      {/* <button onClick={() => marketCapAllocationApi()}>
        <ShareSvg />
      </button> */}
      <button onClick={onClickDelete} className='cursor-pointer'>
        <DeleteSvg />
      </button>
    </td>
  </tr>
)

const AllReportTemplates = () => {
  const [responseTemplates, setResponseTemplates] = useState([])
  const { user } = useAuth()
  const [aggregateGroups, setAggregateGroups] = useState([])
  const [accounts, setAccounts] = useState([])
  const [accountsLoading, setAccountsLoading] = useState(false)
  const [aggregatesLoading, setAggregatesLoading] = useState(false)
  const { showError } = useErrorToast()
  const [selectedTemplateForPreview, setSelectedTemplateForPreview] = useState(null)
  const [showSelectionPopup, setShowSelectionPopup] = useState(false)

  const fetchData = async () => {
    try {
      const response = await fetchAllTemplates(user?.userGroup)
      if (response?.success) {
        setResponseTemplates(response?.data || [])
      }
    } catch (error) {
      console.error('Error fetching data:', error)
      throw error
    }
  }

  const fetchAggregateGroups = () => {
    setAggregatesLoading(true)
    API.get('baseUriReportBuilder2', `report-builder/v1/${user?.userGroup}/group-details/list`)
      .then(response => {
        if (response?.data?.length > 0) {
          setAggregateGroups(response?.data)
        }
      })
      .catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setAggregatesLoading(false)
      })
  }

  const fetchAccountsData = () => {
    setAccountsLoading(true)
    API
      .get('baseURL', `account-master/v1/${user?.userGroup}/accounts/summary/details`, {
        queryStringParameters: {
          page: 1,
          perpage: 200,
          ...{
            ...user?.userGroup === 'adv-classic'
              ? {
                resources: encodeURIComponent(JSON.stringify({
                  serviceId: 'pdf-customizer',
                  resourceId: 'pdf-customizer'
                }))
              }
              : {}
          }
        }
      })
      .then(response => {
        if (response?.data?.accountsSummaryDetails) {
          setAccounts(response?.data?.accountsSummaryDetails)
        }
      })
      .catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setAccountsLoading(false)
      })
  }

  useEffect(() => {
    if (user) {
      fetchData()
      fetchAggregateGroups()
      fetchAccountsData()
    }
  }, [user])

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const columns = [
    {
      name: 'Name',
      className: 'name_width width-30 padding-left-4 ps-sm-1 ps-md-3 ps-lg-4',
      field: 'key'
    },
    {
      name: 'Date Modified',
      className: 'name_width width-20',
      field: 'updated_time'
    },
    {
      name: 'Report Type',
      className: 'name_width width-25 text-align-center',
      field: 'report_type'
    },
    {
      name: 'Actions',
      className: 'name_width width-25 text-align-center',
      field: 'actions'
    }
  ]

  const openAPIEntitySelection = (selectedTemplate) => {
    setSelectedTemplateForPreview(selectedTemplate)
    setShowSelectionPopup(true)
  }

  const closeAPIEntitySelectionPopup = () => {
    setSelectedTemplateForPreview(null)
    setShowSelectionPopup(false)
  }

  const renderTableHeader = () => (
    <tr className='border-bottom'>
      {columns.map((header, index) => (
        <th key={index} scope='col' className={header.className}>
          {header.name}
        </th>
      ))}
    </tr>
  )

  const renderTableBody = () =>
    data &&
    data.length > 0 &&
    data.map((template, index) => (
      <ReportTemplateRow
        key={template.id}
        template={template}
        onClickPreview={() => openAPIEntitySelection(template)}
        onClickActions={() =>
          handleTemplateClick(false, template.identifier, template.report_name, template)}
        onClickDelete={() =>
          handleTemplateDelete(template.report_name, template.identifier, index)}
      />
    ))
  const data =
    responseTemplates &&
    responseTemplates.length > 0 &&
    responseTemplates.map((item, index) => ({
      ...item,
      id: index + 1,
      key: item.Key.split('.json')[0] || '',
      name: 'Overview of a model portfolio or security makeup and performance in 2 pages.'
    }))


  const handleTemplatePreview = (selectedEntity) => {
    if (selectedEntity && selectedEntity?.length && selectedTemplateForPreview) {
      if (selectedTemplateForPreview?.report_type !== 'Aggregate Report') {
        dispatch(isLoadingSet(true))
        // fetch account report API for optId
        let optId = ''
        API.get('baseUriReport', `reporting/v1/${user?.userGroup}/account-report/${selectedEntity[0]}`)
          .then((response) => {
            if (response?.data?.accReportData?.length) {
              optId = response?.data?.accReportData[0]?.optId
            }
          }).catch(error => {
            showError(error.response?.data?.errorInfo?.userMessage || error.message)
            Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
            dispatch(isLoadingSet(false))
          })
          .finally(() => {
            dispatch(storeTemplateData({
              accountId: selectedEntity[0],
              identifier: selectedTemplateForPreview?.identifier,
              reportType: selectedTemplateForPreview?.report_type,
              optId: optId,
              apiData: {
                accountId: selectedEntity[0],
                optId: optId
              }
            }))
            handleTemplateClick(true, selectedTemplateForPreview.identifier, selectedTemplateForPreview.report_name, selectedTemplateForPreview)
          })
      }
      else {
        if (selectedTemplateForPreview?.report_type === 'Aggregate Report') {
          dispatch(storeTemplateData({
            identifier: selectedTemplateForPreview?.identifier,
            reportType: selectedTemplateForPreview?.report_type,
            apiData: {
              aggregateGroupId: selectedEntity[0]
            }
          }))
          handleTemplateClick(true, selectedTemplateForPreview.identifier, selectedTemplateForPreview.report_name, selectedTemplateForPreview)
        }
      }

    }
  }

  const handleTemplateClick = async (apiCall, identifier, name, data) => {
    try {
      dispatch(isLoadingSet(true))
      dispatch(userClickMode(apiCall ? 'preview' : 'edit'))
      const response = await getElements(name, identifier, user?.userGroup)

      if (response.success) {
        dispatch(
          selectedTemplates({
            name: data?.report_type
          })
        )
        dispatch(
          pageChange(
            data?.orientation === 'landscape'
              ? {
                width: 647 - 45 + 20,
                height: 457 + 8 + 15,
                orientation: 'l'
              }
              : {
                width: 457 + 8 + 20,
                height: 647 - 45 + 20,
                orientation: 'p'
              }
          )
        )
        dispatch(changeEditorName(name))
        dispatch(changePreviousEditorName({ editorName: name, identifier }))
        dispatch(reportDataSetPage(response?.data))

        navigate('/pdf-customizer/report')
        dispatch(isLoadingSet(false))
        if (apiCall) {
          dispatch(chartDataState(true, 'IS_API_CALL'))
          closeAPIEntitySelectionPopup()
        } else {
          dispatch(chartDataState(false, 'IS_API_CALL'))
        }
      } else {
        console.error('Error:', response.msg)
      }
    } catch (error) {
      console.error('Unexpected error:', error)
    } finally {
      dispatch(isLoadingSet(false))
      // if (apiCall) {
      //   dispatch(chartDataState(true, 'IS_API_CALL'))
      // }
      // else {
      //   dispatch(chartDataState(false, 'IS_API_CALL'))
      // }
    }
  }

  const handleTemplateDelete = async (name, identifier, index) => {
    try {
      dispatch(isLoadingSet(true))
      const response = await deleteTemplates(name, identifier, user?.userGroup)
      if (response?.success) {
        setResponseTemplates((prevTemplates) =>
          prevTemplates.filter((template, i) => i !== index)
        )
      }
    } catch (error) {
      console.error('Unexpected error:', error)
    }
    finally {
      dispatch(isLoadingSet(false))
    }
  }

  return (
    <>
      <div className='width-100 table_main_div'>
        {/* <p className='margin-0 table_header_left '>
          Showing 1-10 of {data ? data.length : 0} pdf templates
        </p> */}
        <table
          className='table mainPage width-100'
          style={{ borderCollapse: 'collapse' }}
        >
          <thead className='static-heder border-bottom'>
            {renderTableHeader()}
          </thead>
          <tbody>{renderTableBody()}</tbody>
        </table>
        {
          showSelectionPopup
            ? <AccountAggregateSelectionPopup
              openSelectionPopup={showSelectionPopup}
              closeSelectionPopup={closeAPIEntitySelectionPopup}
              accountsLoading={accountsLoading}
              accounts={accounts}
              aggregatesLoading={aggregatesLoading}
              aggregateGroups={aggregateGroups}
              reportType={selectedTemplateForPreview?.report_type}
              openPreview={handleTemplatePreview}
            />
            : ''
        }
      </div>
    </>
  )
}

export default AllReportTemplates
