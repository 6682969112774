import { useState } from 'react'
import { useReactFlow, useStore } from '@xyflow/react'
import { Box, Button, Drawer, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import useDetachNodes from './helper/useDetachNodes'
import deleteIcon from '../../../../assets/images/icons/delete-node.svg'
import attachIcon from '../../../../assets/images/icons/attechment-icon.svg'

const UpdateNodeAndGrpDrawer = (props) => {
  const { setUpdateDrawerOpen, updateDrawerOpen, handleNodeChanges, currentNode } = props

  const [selectedLeftDataValue, setSelectedLeftDataValue] = useState(currentNode?.nodeInfo?.leftDataPoint || 'Market Value')
  const [selectedRightDataValue, setSelectedRightDataValue] = useState(currentNode?.nodeInfo?.rightDataPoint || 'Total Risk')
  const [defaultNodeType, setDefaultNodeType] = useState(currentNode?.nodeInfo?.category || 'family')
  const [nodeData, setNodeData] = useState(currentNode)
  const { deleteElements } = useReactFlow()
  const detachNodes = useDetachNodes()

  const nodeId = currentNode?.id

  const hasParent = useStore((store) => !!store.nodeLookup.get(nodeId)?.parentId)

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setNodeData((prevData) => ({
      ...prevData,
      [name]: value
    }))
  }

  const handleDrawerClose = (nodeData) => {
    setUpdateDrawerOpen(false)
    handleNodeChanges(nodeData)
  }

  const handleFormSubmit = () => {
    const { name, type } = nodeData

    if (name && defaultNodeType && selectedLeftDataValue && selectedRightDataValue && type) {
      let updatedNode = {
        ...nodeData,
        name
      }
      if (type === 'group') {
        updatedNode = {
          ...nodeData,
          name,
          data: { nodeData: { groupName: name } }
        }
      } else {
        updatedNode = {
          ...nodeData,
          data: { nodeData: { ...nodeData, name, leftDataPoint: selectedLeftDataValue, rightDataPoint: selectedRightDataValue, totalRisk: 12, marketValue: 23, expanded: true, category: defaultNodeType }, expanded: true, isNode: true },
          nodeInfo: { ...nodeData, name, leftDataPoint: selectedLeftDataValue, rightDataPoint: selectedRightDataValue, totalRisk: 12, marketValue: 23, expanded: true, category: defaultNodeType, isNode: true },
          name
        }
      }
      handleDrawerClose(updatedNode)
    }
  }

  const onDelete = () => {
      deleteElements({ nodes: [{ id: nodeId }] })
      setUpdateDrawerOpen(false)
  }

  const onDetach = () => {
    if (hasParent) {
      detachNodes([nodeId])
      setUpdateDrawerOpen(false)
    }
  }

  return (
    <Drawer anchor='right' open={updateDrawerOpen} onClose={() => handleDrawerClose()}>
      <Box sx={{ width: 300, maxWidth: 300, padding: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant='h6' sx={{ fontSize: '16px' }}>
            Update {nodeData?.type === 'group' ? 'Group' : 'Node'}
          </Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            {hasParent && <img src={attachIcon} alt='' onClick={onDetach} style={{ cursor: 'pointer' }} title='detach'/>}
            <img src={deleteIcon} alt='' onClick={onDelete} style={{ cursor: 'pointer' }} title='delete'/>
          </Box>
        </Box>
        <TextField
          label='Name'
          name='name'
          size='small'
          value={nodeData?.name || ''}
          onChange={handleInputChange}
          fullWidth
          margin='normal'
        />
        <>
          {
            nodeData?.type === 'node' || nodeData?.type === 'default'
              ? <>
                <InputLabel id='label-id' sx={{ mt: 2 }}>Node Type</InputLabel>
                <Select
                  margin='dense'
                  size='small'
                  name='nodeType'
                  value={defaultNodeType}
                  fullWidth
                  onChange={(event, newValue) => {
                    setDefaultNodeType(newValue?.props?.value)
                  }}
                >
                  {
                    ['family', 'entity', 'ips', 'accounts','washsales','settlement','household'].map((option, index) => (
                      <MenuItem value={option} key={index}>
                        {option}
                      </MenuItem>
                    ))
                  }
                </Select>
                <InputLabel id='label-id' sx={{ mt: 2 }}>Left Data Point</InputLabel>
                <Select
                  margin='dense'
                  size='small'
                  name='leftDataPoint'
                  value={selectedLeftDataValue}
                  fullWidth
                  onChange={(event, newValue) => {
                    setSelectedLeftDataValue(newValue?.props?.value)
                  }}
                >
                  {
                    ['Market Value', 'Total Risk', 'Tracking Error'].map((option, index) => (
                      <MenuItem value={option} key={index}>
                        {option}
                      </MenuItem>
                    ))
                  }
                </Select>
                <InputLabel id='label-id' sx={{ mt: 2 }}>Right Data Point</InputLabel>
                <Select
                  margin='dense'
                  size='small'
                  name='rightDataPoint'
                  value={selectedRightDataValue}
                  fullWidth
                  onChange={(event, newValue) => {
                    setSelectedRightDataValue(newValue?.props?.value)
                  }}
                >
                  {
                    ['Market Value', 'Total Risk', 'Tracking Error'].map((option, index) => (
                      <MenuItem value={option} key={index}>
                        {option}
                      </MenuItem>
                    ))
                  }
                </Select>
                </>
              : ''
          }
        </>
        <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button sx={{ width: '30%' }} variant='contained' color='primary' onClick={handleFormSubmit}>
            Update
          </Button>
        </Box>
      </Box>
    </Drawer>
  )
}

export default UpdateNodeAndGrpDrawer
