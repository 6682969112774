import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { Link } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { Box, Card, CardContent, Skeleton, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro } from '@mui/x-data-grid-pro'
import CustomKeyFieldSearch from '../../../components/CustomKeyFieldSearch'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import '../aggregate.scss'

const SelectFamilyAccount = ({ title }) => {
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const [isLoading, setIsLoading] = useState(false)
  const [aggregateFamilyData, setAggregateFamilyData] = useState([])
  const [copyAggregateFamilyData, setCopyAggregateFamilyData] = useState([])

  const fetchAggregateFamilyData = async () => {
    setIsLoading(true)
    API.get(
      'baseUriAggregateMaster',
      `aggregate-master/v1/${user.userGroup === 'spn-pm' ? 'adv-classic' : user.userGroup}/aggregates`, { queryStringParameters: { aggTypeId: '' } }
    )
      .then((response) => {
        if (response?.data?.length > 0) {
          setAggregateFamilyData(response.data.map((family) => ({ ...family, id: randomId() })))
          setCopyAggregateFamilyData(response.data.map((family) => ({ ...family, id: randomId() })))
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (user) {
      fetchAggregateFamilyData()
    }
  }, [user])

  const columns = [
    {
      field: 'aggName',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => (
        <Link
          className='linkId'
          to={`/aggregate/${title}/${params?.row?.aggId}`}
        >
          {params?.value || 'N/A'}
        </Link>)
    },
    {
      field: 'aggCd',
      headerName: 'Account Code',
      flex: 1,
    },
    {
      field: 'isTaxable',
      headerName: 'Is Taxable',
      flex: 1,
      renderCell: (params) => params?.row?.isTaxable ? 'Yes' : 'No'
    }
  ]

  return (
    <Box className='selectListofAccount'>
      <Typography className='page-title' mb={2}>Select Family to Access Aggregate</Typography>
      <Card className='card-layout'>
        <CardContent>
          {isLoading
            ? <Box className='table-responsive'>
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Account Code</th>
                    <th>Is Taxable</th>
                  </tr>
                </thead>
                {Array.from(Array(5)).map((_, index) => (
                  <tr key={index}>
                    {Array.from(Array(3)).map((x, i) => (
                      <td key={i}>
                        <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                      </td>
                    ))}
                  </tr>
                ))}
              </table>
            </Box>
            : <DataGridPro
              rows={aggregateFamilyData}
              columns={columns}
              getRowId={(row) => row.id}
              autoHeight
              pageSizeOptions={[10]}
              initialState={{
                ...aggregateFamilyData.initialState,
                pagination: { paginationModel: { pageSize: 10 } }
              }}
              pagination
              slots={{
                toolbar: CustomKeyFieldSearch
              }}
              slotProps={{ toolbar: { filterData: (data) => { setAggregateFamilyData(data); }, fieldName: 'aggName', tableData: copyAggregateFamilyData, fieldLabel: 'Name' } }}
              disableRowSelectionOnClick
              sx={{
                border: 'none',
                fontFamily: 'Open Sans'
              }}
            />}
        </CardContent>
      </Card>
    </Box>
  )
}

export default SelectFamilyAccount