import { useState } from 'react'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Button, IconButton, Modal, Skeleton, styled, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import CloseIcon from '@mui/icons-material/Close'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  px: 2,
  py: 2,
  width: '100%',
  borderRadius: '4px'
}

const CustomTab = styled(Tab)(({ theme }) => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  textTransform: 'capitalize',
  fontSize: '16px',
  color: '#34475A',
  fontWeight: 400,
  '&.Mui-selected': {
    fontWeight: 400,
    color: '#34475A'
  }
}))

const renderTableSkeleton = (header, hiddenColumns) => {
  if (hiddenColumns)
    header = header.filter(col => !hiddenColumns.includes(col.field))
  return (
    <TableContainer mt={5}>
      <Table className='risk-page-table'>
        <TableHead>
          <TableRow>
            {header.map((item, index) => {
              return (
                <TableCell key={index}>{item.headerName}</TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: 5 }).map((_, index) => (
            <TableRow key={index}>
              {Array.from({ length: header.length }).map((_, index) => (
                <TableCell key={index}>
                  <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                </TableCell>))}
            </TableRow>))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const renderDataGridPro = (rowData, cols, getRowId, columnVisibility, rowSelectionModel, onRowSelectionModelChange) => {
  return (
    <DataGridPro
      density='compact'
      autoHeight
      rows={rowData || []}
      columns={cols}
      checkboxSelection
      rowSelectionModel={rowSelectionModel}
      onRowSelectionModelChange={onRowSelectionModelChange}
      keepNonExistentRowsSelected
      getRowId={getRowId}
      pagination
      pageSizeOptions={[10, 25, 50, 100]}
      initialState={{
        ...rowData?.initialState,
        pagination: { paginationModel: { pageSize: 25 } },
        columns: {
          columnVisibilityModel:
            columnVisibility
              ? columnVisibility
              : {}
        }
      }}
      sx={(theme) => ({
        '& .MuiDataGrid-cell': {
          cursor: 'default'
        },
        '& .MuiDataGrid-cell:focus': {
          outline: 'none'
        },
        [`.${gridClasses.main}`]: {
          height: 'min(100vh - 16px - 45px - 16px - 53px, 600px - 16px - 45px - 16px - 40px - 53px)',
        },
        [`.${gridClasses.columnHeaders}`]: {
          position: 'sticky',
          backgroundColor: theme.palette.background.paper,
          top: 0,
          zIndex: 1,
        },
        [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
          color: '#74788d',
          fontWeight: 600
        },
        [`.${gridClasses.virtualScroller}`]: {
          overflowY: 'auto !important',
          scrollbarWidth: 'none'
        }
      })}
    />
  )
}

const accountCols = [
  {
    field: 'accountId',
    headerName: 'Account Id',
    flex: 1
  },
  {
    field: 'accountName',
    headerName: 'Account Name',
    flex: 1
  },
  {
    field: 'accountCode',
    headerName: 'Account Code',
    flex: 1
  },
  {
    field: 'accountType',
    headerName: 'Account Type',
    flex: 1
  },
  {
    field: 'isTaxable',
    headerName: 'Taxable',
    valueGetter: (params) => (params.value === 0 ? 'No' : 'Yes')
  }
]

const groupCols = [
  {
    field: 'aggGroupId',
    headerName: 'Group Id',
    flex: 1
  },
  {
    field: 'aggGroupName',
    headerName: 'Name',
    flex: 1,
  },
  {
    field: 'groupType',
    headerName: 'Group Type',
    flex: 1
  },
  {
    field: 'aggGroupCd',
    headerName: 'Group Code',
    flex: 1
  }
]

const MemberSelectionPopup = (props) => {
  const {
    addMembers,
    handlePopupClose,
    showMemberPopup,
    handleSearchChange,
    isAccountLoading,
    isUmaLoading,
    isGroupLoading,
    accountData,
    umaData,
    groupData,
    accountRowSelectionModel,
    umaRowSelectionModel,
    groupRowSelectionModel,
    setAccountRowSelectionModel,
    setUmaRowSelectionModel,
    setGroupRowSelectionModel
  } = props
  const [searchText, setSearchText] = useState('')
  const [selectedTab, setSelectedTab] = useState('Account')
  const tabList = ['Account', 'UMA', 'Other Groups']

  const searchInputChange = (e) => {
    setSearchText(e.target.value)
    handleSearchChange(e)
  }

  const renderTabPanel = (tab) => {
    switch (tab) {
      case 'UMA':
        return (
          isUmaLoading
            ? renderTableSkeleton(groupCols, ['aggGroupId'])
            : renderDataGridPro(
              umaData,
              groupCols,
              (row) => row?.aggGroupId,
              { aggGroupId: false },
              umaRowSelectionModel,
              setUmaRowSelectionModel
            )
        )
      case 'Account':
        return (
          isAccountLoading
            ? renderTableSkeleton(accountCols, ['accountId'])
            : renderDataGridPro(
              accountData,
              accountCols,
              (row) => row?.accountId,
              { accountId: false },
              accountRowSelectionModel,
              setAccountRowSelectionModel
            )
        )
      case 'Other Groups':
        return (
          isGroupLoading
            ? renderTableSkeleton(groupCols, ['aggGroupId'])
            : renderDataGridPro(
              groupData,
              groupCols,
              (row) => row?.aggGroupId,
              { aggGroupId: false },
              groupRowSelectionModel,
              setGroupRowSelectionModel
            )
        )
      default:
        return <></>
    }
  }

  const handleClosePopup = () => {
    handlePopupClose()
    setSearchText('')
  }

  return (
    <Modal
      onClose={handleClosePopup}
      open={showMemberPopup}
    >
      <Box sx={{ ...modalStyle, outline: 'none', minWidth: '600px', maxWidth: '1000px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '5px', position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 2 }}>
          <Typography variant='h6' sx={{ ml: '5px', fontWeight: 400, color: '#34475A' }}>Member Creation</Typography>
          <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <TextField
              size='small'
              label={`Search by Name`}
              variant='outlined'
              type='text'
              value={searchText || ''}
              autoComplete='off'
              sx={{ width: '150px' }}
              onChange={searchInputChange}
            />
            <Button
              variant='contained'
              disabled={!accountRowSelectionModel.length && !umaRowSelectionModel.length && !groupRowSelectionModel.length}
              onClick={addMembers}
            >
              Apply
            </Button>
            <IconButton onClick={handleClosePopup}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box>
          <TabContext value={selectedTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={(_, value) => setSelectedTab(value)}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: '#34475A'
                  }
                }}
                indicatorColor='#34475A'
              >
                {tabList.map((tab, index) => (
                  <CustomTab key={index} label={tab} value={tab} />
                ))}
              </TabList>
            </Box>
            {tabList.map((tab, index) => (
              <TabPanel key={index} value={tab} sx={{ px: 0, pt: '5px', pb: 0 }}>
                {renderTabPanel(tab)}
              </TabPanel>
            ))}
          </TabContext>
        </Box>
      </Box>
    </Modal>
  )
}

export default MemberSelectionPopup