import { randomId } from "@mui/x-data-grid-generator"

export const assetAllocationDataProcess = (response) => {
  const marketCapColor = ['#4B8CCA', '#4B8CCACC', '#34C38F', '#4B8CCA80', '#4B8CCA4D']// ['#4B8CCA', '#4B8CCA80', '#34C38F', '#4B8CCA4D', '#4B8CCA1A']

  // Market Cap
  let marketCapLabels = []
  let marketCapData = []
  if (Array.isArray(response?.data?.marketCapAllocationData)) {
    marketCapLabels = response?.data?.marketCapAllocationData?.map(item => item.marketCapName) || []
    marketCapData = Object.keys(response?.data?.fieldLabels || {}).map((key, index) => {
      const label = response.data.fieldLabels[key]
      const backgroundColor = marketCapColor[index] || `#${Math.floor(Math.random() * 16777215).toString(16)}`
      const values = (response?.data?.marketCapAllocationData || []).map(item => parseFloat(item[key]) * 100 || 0)

      return {
        label,
        data: values,
        backgroundColor,
        stack: `Stack ${index}`,
        grid: false
      }
    })
  } else {
    marketCapData = []
  }

  // Factor
  const factorColor = ['#4B8CCA', '#4B8CCACC', '#34C38F', '#4B8CCA80', '#4B8CCA4D']// ['#4B8CCA', '#4B8CCA80', '#34C38F', '#4B8CCA4D', '#4B8CCA1A']
  let factorLabels = []
  let factorData = []
  if (Array.isArray(response?.data?.factorAllocationData)) {
    factorLabels = response?.data?.factorAllocationData?.map(item => item.factorName) || []
    factorData = Object.keys(response?.data?.fieldLabels || {}).map((key, index) => {
      const label = response?.data?.fieldLabels[key]
      const backgroundColor = factorColor[index] || `#${Math.floor(Math.random() * 16777215).toString(16)}`
      const values = (response?.data?.factorAllocationData || []).map(item => parseFloat(item[key]) || 0)

      return {
        label,
        data: values,
        backgroundColor,
        stack: `Stack ${index}`,
        grid: false
      }
    })
  } else {
    factorData = []
  }
  // Sector
  let sectorColumns = []
  let sectorData = []
  let sectorDataTableColumnMapping = {}
  if (Array.isArray(response?.data?.sectorWiseAllocationData)) {
    sectorColumns = [
      {
        headerName: 'Sector',
        field: 'assetClassDesc',
        flex: 1
      },
      ...Object.entries(response?.data?.fieldLabels || []).map(([key, value]) => ({
      headerName: key === 'portfolio'
        ? 'Portfolio (%)'
        : key === 'strategy'
          ? 'Strategy (%)'
          : value + ' (%)',
        field: key,
        flex: 1,
        type: 'number',
        headerAlign: 'right',
        align: 'right',
        valueGetter: (params) => parseFloat(params?.value ? params?.value?.toFixed(2) : params.value),
        valueFormatter: (params) => params?.value !== null && params?.value !== undefined ? params?.value?.toFixed(2) : 'N/A'
    }))]
    sectorData = (response?.data?.sectorWiseAllocationData || []).map((sectorObj) => ({ ...sectorObj, id: randomId() }))
    // sectorDataTableColumnMapping = {
    //   Sector: 'assetClassDesc'
    // }
    // Object.entries(response?.data?.fieldLabels || []).forEach(([key, value]) => {
    //   if (key === 'portfolio') {
    //     sectorDataTableColumnMapping['Portfolio (%)'] = 'Portfolio'
    //   } else if (key === 'strategy') {
    //     sectorDataTableColumnMapping['Strategy (%)'] = response?.data?.fieldLabels[key]
    //   } else {
    //     sectorDataTableColumnMapping[`${String(value).toLowerCase().split(' ').map(function (s) {
    //       return s.charAt(0).toUpperCase() + s.substring(1)
    //     }).join(' ')} (%)`] = response?.data?.fieldLabels[key]
    //   }
    // })
  } else {
    sectorData = []
  }

  return { marketCapData, sectorData, factorData, marketCapLabels, sectorColumns, factorLabels, sectorDataTableColumnMapping }
}