import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Autocomplete, Box, Button, Card, CardContent, Checkbox, Collapse, FormControlLabel, FormHelperText, Grid, InputAdornment, MenuItem, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined'
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined'
import MailOutlinedIcon from '@mui/icons-material/MailOutlined'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { isNumberOnly, isNumberWithSign, isWholeNumber } from '../../utils/NumberUtils'
import { InputAdornmentTextField } from './components/inputAdornmentTextfield'

const AccountDetailsTab = ({ submitRef, setData, data, custodianOptions, setselectedCustodian, custodianList, taxStates, sellLogicCodes }) => {
  const [accountType, setAccountType] = useState('trading')
  const [taxManaged, setTaxManaged] = useState(Object.keys(data).length === 0 ? true : data.taxManaged)
  const [showAdvancedTax, setShowAdvancedTax] = useState(false)
  const [selectedCustodian, setSelectedCustodian] = useState(custodianOptions)

  const validationSchema = Yup.object({
    accountType: Yup.string().oneOf(['transition', 'trading']),
    name: Yup.string()
      .trim()
      .required('Name required')
      .max(100, 'Name can not be more than 100 characters')
      .min(3, 'Name can not be less than 3 characters'),
    email: Yup.string()
      .trim()
      .email('Invalid email')
      .required('Email required')
      .max(255, 'Email can not be more than 255 characters')
      .min(1, 'Email can not be less than 1 character'),
    selllogicCode: Yup.string().required('Tax Account Method required'),
    custodianId: Yup.string()
      .when('accountType', (accountType, schema) => {
        if (accountType.length && accountType[0] === 'trading') { return schema.required('Custodian required') }
        return schema
      }),
    custodianNumber: Yup.string()
      .trim()
      .max(45, 'Custodian number can not be more than 45 characters')
      .when('accountType', (accountType, schema) => {
        if (accountType.length && accountType[0] === 'trading') { return schema.required('Custodian Number required').min(1, 'Custodian Number can not be less than 1 character') }
        return schema
      }),
    masterAccountId: Yup.string()
      .max(45, 'Master Account ID can not be more than 45 characters')
      .when('accountType', (accountType, schema) => {
        if (accountType.length && accountType[0] === 'trading' && selectedCustodian?.custodianName === 'Charles Schwab') { return schema.required('Master Account ID required').min(1, 'Master Account ID can not be less than 1 character') }
        return schema
      }),
    taxManaged: Yup.boolean(),
    taxState: Yup.string()
      .when('taxManaged', (taxManaged, schema) => {
        if (taxManaged.length && taxManaged[0]) { return schema.required('Tax state required') }
        return schema
      }),
    shortTermTaxRate: Yup.string()
      .when('taxManaged', (taxManaged, schema) => {
        if (taxManaged.length && taxManaged[0]) {
          return schema.required('Short term tax rate required')
            .test('valid-range', 'Short term tax rate must be between 0 and 100', value => {
              const numericValue = parseFloat(value)
              return numericValue >= 0 && numericValue <= 100
            })
        }
        return schema
      }),
    longTermTaxRate: Yup.string()
      .when('taxManaged', (taxManaged, schema) => {
        if (taxManaged.length && taxManaged[0]) {
          return schema.required('Long term tax rate required')
            .test('valid-range', 'Long term tax rate must be between 0 and 100', value => {
              const numericValue = parseFloat(value)
              return numericValue >= 0 && numericValue <= 100
            })
        }
        return schema
      }),
    showAdvancedTax: Yup.boolean(),
    externalLongGainLoss: Yup.string()
      .when(['showAdvancedTax', 'taxManaged'], ([showAdvancedTax, taxManaged], schema) => {
        if (showAdvancedTax && taxManaged) { return schema.required('External realized long gain or losses required') }
        return schema
      }),
    externalShortGainLoss: Yup.string()
      .when(['showAdvancedTax', 'taxManaged'], ([showAdvancedTax, taxManaged], schema) => {
        if (showAdvancedTax && taxManaged) { return schema.required('External realized short gain or losses required') }
        return schema
      })
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    control
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues:
      Object.keys(data).length === 0
        ? {
            accountType: 'trading',
            taxManaged: true,
            showAdvancedTax: false,
            name: '',
            email: '',
            selllogicCode: '',
            custodianId: '',
            custodianNumber: '',
            masterAccountId: '',
            taxSensitivity: '',
            shortTermTaxRate: '',
            longTermTaxRate: '',
            externalLongGainLoss: '',
            externalShortGainLoss: '',
            taxState: ''
          }
        : {
            ...data
          }
  })

  useEffect(() => {
    if (Object.keys(data).length !== 0) {
      setTaxManaged(data.taxManaged)
      setShowAdvancedTax(data.showAdvancedTax)
      setAccountType(data.accountType)
    }
  }, [])

  // // GET API for custodian dropdown data
  // const getCustodianType = () => {
  //   API.get('baseUriTransactionalMaster', `transactional-master/v1/${user.userGroup}/custodians`)
  //     .then(response => {
  //       if (response?.data) {
  //         setCustodianType(response.data)
  //       }
  //     }).catch(error => {
  //       showError(error.response?.data?.errorInfo?.userMessage || error.message)
  //       Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
  //     })
  // }

  // const getTaxStates = async () => {
  //   API.get('baseUriTransactionalMaster', `transactional-master/v1/${user.userGroup}/tax-states`, {
  //     queryStringParameters: {}
  //   })
  //     .then((response) => {
  //       if (response.data) {
  //         setTaxStates([...response.data])
  //       }
  //     })
  //     .catch((error) => {
  //       showError(error.response?.data?.errorInfo?.userMessage || error.message)
  //       Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
  //     })
  // }

  // const getSellLogicCodes = async () => {
  //   API.get('baseUriTransactionalMaster', `transactional-master/v1/${user.userGroup}/sell-logics`, {
  //     queryStringParameters: {}
  //   })
  //     .then((response) => {
  //       if (response.data) {
  //         setSellLogicCodes([...response.data])
  //       }
  //     })
  //     .catch((error) => {
  //       showError(error.response?.data?.errorInfo?.userMessage || error.message)
  //       Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
  //     })
  // }
  const onSubmitHandler = (data) => {
    setData({ ...data })
  }

  // handle onChange custodian dropdown
  const handleCustodianTypeChange = (value) => {
    setSelectedCustodian(value)
    setValue('custodianId', value?.custodianId)
    if (value?.custodianName !== 'Charles Schwab') {
      setValue('masterAccountId', '')
    }
    // trigger validation for custodian ID field
    trigger('custodianId')
    setselectedCustodian(value)
  }

  return (
    <Box className='account-details'>
      <form noValidate onSubmit={handleSubmit(onSubmitHandler)} autoComplete='off'>
        <Button
          ref={submitRef}
          type='submit'
          style={{ display: 'none' }}
        />
        <Card className='card-layout'>
          <CardContent>
            <Typography className='account-label-header' mb={2}>
              Basic Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} display='flex' alignItems='center' mb={2}>
                <Typography variant='body1' component='label' htmlFor='accountType'>
                  Account Type *
                </Typography>
                <Box mx={2}>
                  <Controller
                    name='accountType'
                    control={control}
                    render={({ field }) => (
                      <RadioGroup
                        row
                        aria-labelledby='demo-row-radio-buttons-group-label'
                        {...field}
                      >
                        <FormControlLabel
                          value='transition'
                          control={<Radio size='small' />}
                          label='Transition'
                          onClick={() => {
                            setAccountType('transition')
                            setValue('masterAccountId', '')
                          }}
                        />
                        <FormControlLabel
                          value='trading'
                          control={<Radio size='small' />}
                          label='Trading'
                          onClick={() => setAccountType('trading')}
                        />
                      </RadioGroup>
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} mb={2}>
                <Controller
                  name='name'
                  control={control}
                  render={({ field }) => (
                    <InputAdornmentTextField
                      id='name'
                      name='name'
                      type='text'
                      label='Name *'
                      {...register('name')}
                      value={field.value}
                      onChange={field.onChange}
                      placeholder='Enter Name'
                      sx={{
                        width: '100%',
                        marginTop: '0px'
                      }}
                      adornment={<PersonOutlineOutlinedIcon sx={{
                        width: '20px !important',
                        height: '20px !important'
                      }}
                                 />}
                      error={Boolean(errors.name)}
                      helperText={errors.name && errors.name.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} mb={2}>
                <Controller
                  name='email'
                  control={control}
                  render={({ field }) => (
                    <InputAdornmentTextField
                      id='email'
                      name='email'
                      type='text'
                      label='Client Email *'
                      {...register('email')}
                      value={field.value}
                      onChange={field.onChange}
                      placeholder='Enter email'
                      sx={{
                        width: '100%',
                        marginTop: '0px'
                      }}
                      adornment={<MailOutlinedIcon sx={{
                        height: '20px !important'
                      }}
                                 />}
                      error={Boolean(errors.email)}
                      helperText={errors.email && errors.email.message}
                    />
                  )}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6} md={4} mb={2}>
                <Controller
                  name='fundingSource'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id='funding-source'
                      name='fundingSource'
                      select
                      label='Funding Source *'
                      value={field.value || ''}
                      sx={{ width: '100%' }}
                      error={Boolean(errors.fundingSource)}
                      helperText={errors.fundingSource && errors.fundingSource.message}
                      {...field}
                    >
                      <MenuItem value=''>Select</MenuItem>
                      {Object.keys(FundingSource).map((option, index) => (
                        <MenuItem
                          key={index}
                          value={option}
                          selected={field.value === option}
                        >
                          {FundingSource[option]}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid> */}
              <Grid item xs={12} sm={6} md={4} mb={2}>
                <Controller
                  name='selllogicCode'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id='selllogic-code'
                      name='selllogicCode'
                      select
                      value={field.value || ''}
                      label='Tax Accounting Method for Account *'
                      sx={{
                        width: '100%'
                      }}
                      error={Boolean(errors.selllogicCode)}
                      helperText={errors.selllogicCode && errors.selllogicCode.message}
                      {...field}
                    >
                      <MenuItem value=''>Select</MenuItem>
                      {sellLogicCodes?.map((option, index) => (
                        <MenuItem
                          key={index}
                          value={JSON.stringify(option)}
                        >
                          {option.sellLogicCode}-{option.sellLogicDesc}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              {accountType === 'trading'
                ? <>
                  <Grid item xs={12} sm={6} md={4} mb={2}>
                    <Box>
                      <Autocomplete
                        id='custodianId'
                        options={custodianList}
                        value={selectedCustodian}
                        getOptionLabel={(option) => option?.custodianName}
                        onChange={(event, value) => {
                          handleCustodianTypeChange(value)
                        }}
                        onInputChange={(event, newInputValue) => {
                          if (!custodianList.some(option => option.custodianName === newInputValue)) {
                            // Clear the input if the typed value is not in the options
                            setValue('custodianId', '')
                          }
                        }}
                        isOptionEqualToValue={(option, value) => option?.custodianId === value?.custodianId}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              error={Boolean(errors.custodianId)}
                              label='Custodian *'
                              {...register('custodianId')}
                              className={
                                errors.custodianId && errors.custodianId.message
                                  ? 'invalid'
                                  : ''
                              }
                            />
                          )
                        }}
                        required
                        className={
                          errors.custodianId && errors.custodianId.message
                            ? 'invalid'
                            : ''
                        }
                      />
                    </Box>
                    {errors.custodianId
                      ? <FormHelperText type='invalid' error>
                        {errors.custodianId.message}
                      </FormHelperText>
                      : <></>}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} mb={2}>
                    <TextField
                      id='custodian-number'
                      name='custodianNumber'
                      type='text'
                      label='Custodian Account Number *'
                      placeholder='Enter number here'
                      onKeyDown={(e) =>
                        selectedCustodian?.custodianName === 'Charles Schwab' ? isWholeNumber(e) : true}
                      onPaste={(event) => {
                        if (selectedCustodian?.custodianName === 'Charles Schwab') {
                          const inputValue = event.clipboardData.getData('text')
                          const regex = /^\d+$/
                          if (!regex.test(inputValue)) {
                            event.target.value = ''
                            event.preventDefault()
                            return false
                          }
                          return true
                        }
                      }}
                      {...register('custodianNumber')}
                      error={Boolean(errors.custodianNumber)}
                      helperText={errors.custodianNumber && errors.custodianNumber.message}
                      sx={{
                        width: '100%'
                      }}
                    />
                  </Grid>
                  {selectedCustodian?.custodianName === 'Charles Schwab'
                    ? <Grid item xs={12} sm={6} md={4} mb={2}>
                      <TextField
                        id='master-account-id'
                        name='masterAccountId'
                        type='text'
                        label='Master Account ID *'
                        placeholder='Enter account ID here'
                        {...register('masterAccountId')}
                        onKeyDown={(e) => isWholeNumber(e)}
                        onPaste={(event) => {
                          const inputValue = event.clipboardData.getData('text')
                          const regex = /^\d+$/
                          if (!regex.test(inputValue)) {
                            event.target.value = ''
                            event.preventDefault()
                            return false
                          }
                          return true
                        }}
                        error={Boolean(errors.masterAccountId)}
                        helperText={errors.masterAccountId && errors.masterAccountId.message}
                        sx={{
                          width: '100%'
                        }}
                      />
                      </Grid>
                    : ''}
                  </>
                : <></>}
            </Grid>
          </CardContent>
        </Card>
        <Card className='card-layout' sx={{ marginTop: '22px' }}>
          <CardContent>
            <Typography className='account-label-header' mb={2}>
              Tax Information ({new Date().getFullYear()})
            </Typography>
            <Box>
              <Box display='flex' alignItems='center' mb={2}>
                <Typography variant='body1' component='label' style={{ marginRight: '16px' }}>
                  Is tax managed?
                </Typography>
                <Radio
                  size='small'
                  id='tax-yes'
                  name='tax-managed'
                  value
                  checked={taxManaged}
                  onClick={() => setTaxManaged(true)}
                  {...register('taxManaged')}
                />
                <Typography htmlFor='tax-yes' style={{ marginRight: '16px' }}>Yes</Typography>
                <Radio
                  size='small'
                  id='tax-no'
                  name='tax-managed'
                  value={false}
                  checked={!taxManaged}
                  onClick={() => setTaxManaged(false)}
                  {...register('taxManaged')}
                />
                <Typography htmlFor='tax-no'>No</Typography>
              </Box>
              <Collapse in={taxManaged}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4} mb={2}>
                    <Controller
                      name='taxState'
                      control={control}
                      render={({ field }) => (
                        <TextField
                          id='tax-state'
                          name='taxState'
                          label='Tax State *'
                          value={field.value || ''}
                          select
                          sx={{ width: '100%' }}
                          error={Boolean(errors.taxState)}
                          helperText={errors.taxState && errors.taxState.message}
                          {...field}
                        >
                          <MenuItem value=''>Select</MenuItem>
                          {taxStates.map((option, index) => (
                            <MenuItem
                              key={index}
                              value={JSON.stringify(option)}
                            >
                              {option.stateName}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} mb={2}>
                    <TextField
                      id='short-term-label'
                      name='shortTermTaxRate'
                      type='text'
                      error={Boolean(errors.shortTermTaxRate)}
                      helperText={errors.shortTermTaxRate && errors.shortTermTaxRate.message}
                      sx={{ width: '100%' }}
                      label='Short term tax rate *'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position='end'
                          ><PercentOutlinedIcon sx={{
                            width: 18,
                            height: 18
                          }}
                          />
                          </InputAdornment>
                        )
                      }}
                      onKeyDown={(event) => isNumberOnly(event)}
                      {...register('shortTermTaxRate')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} mb={2}>
                    <TextField
                      id='long-term-label'
                      name='longTermTaxRate'
                      type='text'
                      error={Boolean(errors.longTermTaxRate)}
                      helperText={errors.longTermTaxRate && errors.longTermTaxRate.message}
                      sx={{ width: '100%' }}
                      label='Long term tax rate *'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position='end'
                          >
                            <PercentOutlinedIcon sx={{
                              width: 18,
                              height: 18
                            }}
                            />
                          </InputAdornment>
                        )
                      }}
                      onKeyDown={(event) => isNumberOnly(event)}
                      {...register('longTermTaxRate')}
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </Box>
            {taxManaged && (
              <Box>
                <Box mb={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showAdvancedTax}
                        onClick={(event) => setShowAdvancedTax(event.target.checked)}
                        id='advance-tax'
                        name='showAdvancedTax'
                        {...register('showAdvancedTax')}
                        size='small'
                      />
                    }
                    label='Advanced Taxes (Optional)'
                  />
                </Box>
                <Collapse in={showAdvancedTax}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} mb={2}>
                      <Controller
                        name='externalLongGainLoss'
                        control={control}
                        render={({ field }) => (
                          <InputAdornmentTextField
                            id='external-long-gainloss-label'
                            name='externalLongGainLoss'
                            type='text'
                            label='External realized long gain or losses *'
                            {...register('externalLongGainLoss')}
                            value={field.value}
                            onChange={field.onChange}
                            onKeyDown={(event) => isNumberWithSign(event)}
                            sx={{
                              width: '100%'
                            }}
                            adornment={<AttachMoneyOutlinedIcon />}
                            error={Boolean(errors.externalLongGainLoss)}
                            helperText={errors.externalLongGainLoss && errors.externalLongGainLoss.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} mb={2}>
                      <Controller
                        name='externalShortGainLoss'
                        control={control}
                        render={({ field }) => (
                          <InputAdornmentTextField
                            id='external-short-gainloss-label'
                            name='externalShortGainLoss'
                            type='text'
                            label='External realized short gain or losses *'
                            {...register('externalShortGainLoss')}
                            value={field.value}
                            onChange={field.onChange}
                            onKeyDown={(event) => isNumberWithSign(event)}
                            sx={{ width: '100%' }}
                            adornment={<AttachMoneyOutlinedIcon />}
                            error={Boolean(errors.externalShortGainLoss)}
                            helperText={errors.externalShortGainLoss && errors.externalShortGainLoss.message}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Collapse>
              </Box>
            )}
          </CardContent>
        </Card>
      </form>
    </Box>
  )
}

export default AccountDetailsTab
