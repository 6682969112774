import React, { useRef, useEffect } from 'react'
import Chart from 'react-apexcharts'

const ApexAreaChart = ({ data, resetKey, onZoomChange }) => {
  const chartRef = useRef(null)

  useEffect(() => {
    resetZoom()
  }, [resetKey, data])

  const resetZoom = () => {
    if (chartRef.current && data && data.length > 0) {
      // Get the initial x-axis range based on the first and last dates in the data array
      const minX = new Date(data[0]?.x).getTime()
      const maxX = new Date(data[data.length - 1]?.x).getTime()

      // Reset the zoom by updating the x-axis range
      chartRef.current.chart.updateOptions({
        xaxis: {
          min: minX,
          max: maxX
        }
      })
      onZoomChange(false)
    }
  }

  const options = {
    xaxis: {
      type: 'datetime',
      tooltip: {
        enabled: true
      }
    },
    yaxis: [
      {
        title: {
          text: data?.find(point => point?.postTaxReturns !== null)
            ? 'Returns (%)'
            : 'Pre Tax Returns (%)',
          offsetX: 0,
          style: {
            fontSize: '12px',
            color: '#74788D',
            fontWeight: 500
          }
        },
        labels: {
          formatter: function (value) {
            return value?.toFixed(2)
          }
        },
        tickAmount: 7
      }
    ],
    markers: {
      size: 0
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        // Get the series values for the data point index
        const preTaxValue = (series[0] && series[0][dataPointIndex] !== undefined && series[0][dataPointIndex] !== null)
          ? series[0][dataPointIndex]?.toFixed(2)
          : undefined
        const accPreTaxValue = (series[1] && series[1][dataPointIndex] !== undefined && series[1][dataPointIndex] !== null)
          ? series[1][dataPointIndex]?.toFixed(2)
          : undefined
        let tooltipContent = '<div style="padding: 8px; border-radius: 4px; color: #000; background-color: #fff;">'
        if (preTaxValue !== undefined) {
          tooltipContent += `
            <div>
              <span style="display: inline-block; width: 10px; height: 10px; background-color: ${w.config.colors[0]}; border-radius: 50%; margin-right: 5px;"></span>
              <span>${preTaxValue}%</span>
            </div>`
        }
        if (accPreTaxValue !== undefined) {
          tooltipContent += `
            <div>
              <span style="display: inline-block; width: 10px; height: 10px; background-color: ${w.config.colors[1]}; border-radius: 50%; margin-right: 5px;"></span>
              <span>${accPreTaxValue}%</span>
            </div>`
        }
        tooltipContent += '</div>'
        return tooltipContent
      }
    },

    chart: {
      events: {
        zoomed: function (chartContext, { xaxis, yaxis }) {
          onZoomChange(true)
        }
      },
      toolbar: {
        show: false
      }
    },
    legend: {
      show: data?.find(point => point?.postTaxReturns !== null), // Show legend only if postTax data is present
      position: 'top'
    },
    stroke: {
      curve: 'straight',
      width: 1.8
    },
    fill: {
      type: 'gradient',
      gradient: {
        enabled: true,
        opacityFrom: 0.3,
        opacityTo: 0
      }
    },
    colors: ['#002A59', '#33A68D'],
    noData: {
      text: 'No performance data available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '18px',
        fontFamily: undefined
      }
    }
  }

  const preTaxSeries = {
    name: 'Pre Tax Returns',
    data: data?.map((point) => ({ x: point?.x, y: point?.y !== null ? (point?.y || 0) * 100 : point?.y }))
  }

  const postTaxSeries = {
    name: 'Post Tax Returns',
    data: data?.find((point) => point?.postTaxReturns !== null)
      ? data?.map((point) => ({
        x: point?.x,
        y: point?.postTaxReturns !== null ? (point?.postTaxReturns || 0) * 100 : point?.postTaxReturns
      }))
      : []
  }

  const series = data?.find((point) => point?.postTaxReturns !== null) ? [preTaxSeries, postTaxSeries] : [preTaxSeries]

  return <Chart ref={chartRef} options={options} series={series} type='area' height={500} width='100%' />
}

export default ApexAreaChart
