import { getBezierPath, getStraightPath, getSmoothStepPath, getSimpleBezierPath } from '@xyflow/react'
import '../../../aggregate.scss'

const edgeTypes = {
  default: getBezierPath,
  workflow: getBezierPath,
  Straight: getStraightPath,
  SmoothStep: getSmoothStepPath,
  Bezier: getBezierPath,
  SimpleBezier: getSimpleBezierPath
}

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
  markerEnd,
  type
}) {
  const getPath = edgeTypes[type]
  const [edgePath, edgeCenterX, edgeCenterY] = getPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  })

  const handleEdgeClick = (e) => {
    e.preventDefault()
  }

  return (
    <>
      <path
        id={id}
        style={style}
        className='edgePath'
        d={edgePath}
        markerEnd={markerEnd}
      />
      <g transform={`translate(${edgeCenterX}, ${edgeCenterY})`}>
        <rect
          onClick={handleEdgeClick}
          id='edge-button'
          x={-10}
          y={-10}
          width={20}
          ry={15}
          rx={20}
          stroke={style?.stroke || 'skyblue'}
          stroke-linecap='round'
          strokeWidth={2}
          height={20}
          className='edgeButton'
        />
        <text style={{ cursor: 'pointer', fill: style?.stroke || 'skyblue' }} y={6} x={-4}>
          +
        </text>
      </g>
    </>
  )
}
