import React, { useRef } from 'react'
import Chart from 'react-apexcharts'
import { formatCurrencyWithSymbol, formatCurrencyWithoutSign } from '../../utils/FormateCurrenyInMilion'
import { formatNumbersWithSymbol } from '../Report/components/dataProcess/DataProcess'
import moveSVGIcon from '../../assets/images/move.svg'
import homeSVGIcon from '../../assets/images/home.svg'
import zoomSVGIcon from '../../assets/images/zoom-in.svg'
import exportMenuSVGIcon from '../../assets/images/export-menu.svg'

const MarketValueAnnotationChart = ({ data, selectedAnnotations, onTradeDetailsUpdate }) => {
  const chartRef = useRef(null)
  let openTooltipId = null // id of the currently open tooltip

  const minYValue = Math.min(...data?.map(point => point.y))
  const annotationsArray = []
  data?.forEach((point) => {
    if (point.annotation && Object.keys(point.annotation).filter(key => key !== 'NET_MV_PLUS_CASH')?.length > 0) {
      annotationsArray.push({ annotations: point.annotation, date: point.x })
    }
  })

  const dividendTooltip = (annotationObj) => {
    if (annotationObj?.value?.length) {
      let tooltipHTML = `
            <div class='green-border'>
                <p id='heading'>Dividend</p>
                <div id='date-row'>
                    <p>Date</p>
                    <b>${new Date(annotationObj.date).toISOString().slice(0, 10)}</b>
                </div>`
      tooltipHTML += `
                <div id='data-row'>
                    <p>Total</p>
                    <b>${formatCurrencyWithSymbol(annotationObj?.value[0]?.total, 2, '$')}</b>
                </div>`
      tooltipHTML += `
                <div style='display: flex; gap:42px; margin-bottom: -10px'>
                    <p>Security</p>
                    <p>Amount</p>
                </div>`
      for (let i = 1; i < Math.min(annotationObj?.value?.length, 6); i++) {
        const dividend = annotationObj?.value[i]
        tooltipHTML += `
                <div id='data-row'>
                    <p>${dividend?.localSymbol}</p>
                    <b>${formatCurrencyWithSymbol(dividend?.dividendValue, 2, '$')}</b>
                </div>`
      }

      tooltipHTML += '</div>'
      return tooltipHTML
    } else {
      return ''
    }
  }

  const depositWithdrawalTooltip = (annotationObj) => {
    return "<div class='red-border'><p id='heading'>Deposit & Withdrawal</p><div id='date-row'><p>Date</p><b>" + new Date(annotationObj?.date).toISOString().slice(0, 10) + "</b></div><div id='data-row'><p>Amount</p><b>" + formatCurrencyWithSymbol(annotationObj?.value, 2, '$') + '</b></div></div>'
  }

  const tradeDetailsTooltip = (annotationObj) => {
    const allBuyTrades = []
    const allSellTrades = []
    const tradeDate = annotationObj.date

    window.handleViewMoreClick = function () {
      onTradeDetailsUpdate({ allBuyTrades, allSellTrades, date: tradeDate })
    }

    annotationObj?.value?.forEach((val) => {
      allBuyTrades.push(...(val?.buyTrades || []))
      allSellTrades.push(...(val?.sellTrades || []))
    })

    const buyTrades = allBuyTrades.slice(0, 5)
    const sellTrades = allSellTrades.slice(0, 5)

    let tooltipHTML = ''
    if (annotationObj?.value?.length) {
      tooltipHTML += "<div class='blue-border'>"
      tooltipHTML += "<p id='heading' style='padding-top: 5px'>Trade Details</p>"
      tooltipHTML += "<div id='date-row' style='display: flex; justify-content: space-between; align-items: center; margin-top: -20px'><p>Date</p><b>" + new Date(annotationObj.date).toISOString().slice(0, 10) + '</b></div>'
      tooltipHTML += "<div class='trade-columns'>"

      // Buy Section
      if (buyTrades?.length > 0) {
        tooltipHTML += `<div class='trade-section' style="border-right: ${sellTrades?.length > 0 && buyTrades?.length > 0 ? '1px dashed #ccc' : 'none'};"><p>Buy Trades</p>`

        for (let i = 0; i < buyTrades?.length; i++) {
          const buyTrade = buyTrades[i]

          tooltipHTML += "<div class='trade-row'>"
          tooltipHTML += `<p id='data-row' style="text-align: left;"><p class="symbol">${buyTrade?.localSymbol}</p><p style="text-align: right;" class='buy-trade'>${buyTrade?.tradedShares?.toFixed(2) || '-'}</p></p>`
          tooltipHTML += '</div>'
        }

        tooltipHTML += '</div>'
      }

      // Sell Section
      if (sellTrades?.length > 0) {
        tooltipHTML += "<div class='trade-section'><p>Sell Trades</p>"

        for (let i = 0; i < sellTrades?.length; i++) {
          const sellTrade = sellTrades[i]
          tooltipHTML += "<div class='trade-row'>"
          tooltipHTML += `<p class='data-row' style="text-align: left;"><p class="symbol">${sellTrade?.localSymbol}</p><p style="text-align: right;" class='sell-trade'>${sellTrade?.tradedShares?.toFixed(2) || '-'}</p></p>`
          tooltipHTML += '</div>'
        }
        tooltipHTML += '</div>'
      }
      tooltipHTML += '</div>'
      if (allBuyTrades?.length > 5 || allSellTrades?.length > 5) {
        tooltipHTML += "<button class='view-more-btn' onclick='handleViewMoreClick()'>View More</button>"
      }
      tooltipHTML += '</div>'
    }
    return tooltipHTML
  }

  // Create an array to store separate annotation entries
  const separateAnnotations = []
  // Iterate through each data entry
  annotationsArray?.forEach(entry => {
    // Extract annotations and date from each entry
    const annotations = entry.annotations
    const date = entry.date
    // check for selectedAnnotation list to show hide annotation
    const annotationsKey = Object.keys(annotations).filter(key => key !== 'NET_MV_PLUS_CASH').filter(key => selectedAnnotations && selectedAnnotations?.length ? selectedAnnotations?.includes(key) : true)
    let count = 0
    // Create separate entries for each annotation type
    annotationsKey?.forEach((annotationType, idx) => {
      let separateEntry = {}
      // create individual annotations for each optMapId for TRADE_DETAILS
      if (annotationType === 'TRADE_DETAILS' && annotations[annotationType]?.length > 1) {
        annotations[annotationType].map((item, index) => {
          separateEntry = {
            date,
            annotationType,
            value: [item]
          }
          if (annotations[annotationType]?.length > 1) {
            separateEntry.isSameDate = true
            separateEntry.offsetY = 0 - (25 * count)
            count++
          }
          // Add the separate entry to the array
          separateAnnotations.push(separateEntry)
        })
      } else {
        separateEntry = {
          date,
          annotationType,
          value: annotations[annotationType]
        }
        if (annotationsKey?.length > 1) {
          separateEntry.isSameDate = true
          separateEntry.offsetY = 0 - (25 * count)
          count++
        }
        // Add the separate entry to the array
        separateAnnotations.push(separateEntry)
      }
    })
  })

  function isClickedOnTooltipOrChildren (clickedElement, tooltipId) {
    const tooltipElement = document.getElementById(tooltipId)
    return tooltipElement && (clickedElement === tooltipElement || tooltipElement.contains(clickedElement))
  }

  const annotationsObj = {
    points: separateAnnotations?.map((annotObj, index) => ({
      id: 'tooltip-' + index,
      x: new Date(annotObj?.date).getTime(),
      y: minYValue,
      label: {
        click: (point, event) => {
          const tooltipId = `tooltip-${index}`
          // Calculate left and top positions of the ApexCharts container
          const tooltipDiv = document.getElementById(tooltipId)
          if (openTooltipId === tooltipId) {
            // If clicking on the same point, close the tooltip
            tooltipDiv?.parentNode?.removeChild(tooltipDiv)
            openTooltipId = null
          } else {
            // Calculate left and top positions of the ApexCharts container
            const chartContainer = document.getElementById('market-chart-expanded')
            // Close any existing tooltip
            if (openTooltipId) {
              const currentTooltip = document.getElementById(openTooltipId)
              currentTooltip?.parentNode?.removeChild(currentTooltip)
            }
            // Create and append the new tooltip
            const chartRect = chartContainer?.getBoundingClientRect()
            const chartLeft = chartRect?.left
            const chartTop = chartRect?.top

            // Create a tooltip div
            const newTooltipDiv = document.createElement('div')
            newTooltipDiv.innerHTML = annotObj?.annotationType === 'DIV_AND_INT' ? dividendTooltip(annotObj) : annotObj?.annotationType === 'DEP_AND_WIT' ? depositWithdrawalTooltip(annotObj) : annotObj?.annotationType === 'TRADE_DETAILS' ? tradeDetailsTooltip(annotObj) : ''
            newTooltipDiv.id = tooltipId

            // Set the position of the tooltip
            newTooltipDiv.style.zIndex = '15'
            newTooltipDiv.style.position = 'absolute'
            newTooltipDiv.style.left = event?.clientX - chartLeft - 100 + 'px'
            newTooltipDiv.style.top = annotObj?.annotationType === 'TRADE_DETAILS' ? event?.clientY - chartTop - 250 + 'px' : (annotObj?.annotationType === 'DIV_AND_INT' ? (annotObj?.value?.length > 3) ? event?.clientY - chartTop - 250 + 'px' : event?.clientY - chartTop - 220 + 'px' : event?.clientY - chartTop - 180 + 'px')
            // Check if the tooltip is going beyond the right edge of the chart container
            const tooltipWidth = 250
            newTooltipDiv.style.boxShadow = 'rgba(116, 120, 141, 0.5) 0px 0px 4px'
            const chartWidth = chartRect.width
            if (event?.clientX - chartLeft - 100 + tooltipWidth > chartWidth) {
              newTooltipDiv.style.left = chartWidth - tooltipWidth + 'px'
            }
            // Check if the tooltip exceeds the left edge of the chart container
            if (event?.clientX - chartLeft - 100 < 0) {
              newTooltipDiv.style.left = '0'
            }

            // Append the tooltip to the body
            chartContainer?.appendChild(newTooltipDiv)
            openTooltipId = tooltipId
            // event listener to the document to close the tooltip when clicked outside
            const handleGlobalClick = (globalEvent) => {
              const clickedOnAnnotation = globalEvent.target.classList.contains(`tooltip-${index}`)
              const clickedOnTooltipOrChildren = isClickedOnTooltipOrChildren(globalEvent.target, tooltipId)

              if (!clickedOnAnnotation && !clickedOnTooltipOrChildren) {
                // Close the tooltip when clicked outside and not on the annotation points or the tooltip
                const clickedTooltip = document.getElementById(tooltipId)

                if (clickedTooltip) {
                  clickedTooltip.parentNode?.removeChild(clickedTooltip)
                  openTooltipId = null
                }
                document.removeEventListener('click', handleGlobalClick)
              }
            }
            // event listener to the document to close the tooltip when clicked outside
            document.addEventListener('click', handleGlobalClick)
          }
        },
        // mouseLeave: (point, event) => {
        //   if (document.getElementById('tooltip-annotation-' + point.id)) {
        //     document.getElementById('market-chart-expanded')?.removeChild(document.getElementById('tooltip-annotation-' + point.id))
        //   }
        // },
        text: annotObj?.annotationType === 'DIV_AND_INT' ? 'D' : (annotObj?.annotationType === 'TRADE_DETAILS' ? 'T' : 'W'),
        borderColor: annotObj?.annotationType === 'DIV_AND_INT' ? '#33A68D' : (annotObj?.annotationType === 'TRADE_DETAILS' ? '#002A59' : '#d32f2f'),
        borderRadius: annotObj?.annotationType === 'TRADE_DETAILS' ? '4px' : '50%',
        offsetY: annotObj?.isSameDate ? annotObj?.offsetY : 0, // Adjust the vertical offset of the label
        style: {
          color: annotObj?.annotationType === 'DIV_AND_INT' ? '#33A68D' : (annotObj?.annotationType === 'TRADE_DETAILS' ? '#002A59' : '#d32f2f'),
          fontSize: '9.5px',
          fontWeight: 400,
          padding: {
            left: annotObj?.annotationType === 'DIV_AND_INT' ? 5.5 : 4,
            right: annotObj?.annotationType === 'DIV_AND_INT' ? 5.5 : 4,
            top: 4,
            bottom: 4
          }// Add padding to the label
        }
      }
    }))
  }

  const options = {
    chart: {
      offsetX: -15,
      animations: {
        enabled: false
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: -35,
        tools: {
          download: `<img src="${exportMenuSVGIcon}" />`,
          selection: true,
          zoom: `<img src="${zoomSVGIcon}" />`,
          zoomin: false,
          zoomout: false,
          pan: `<img src="${moveSVGIcon}" />`,
          reset: `<img src="${homeSVGIcon}" />`
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: ',',
            headerCategory: 'Date',
            headerValue: 'value',
            dateFormatter (timestamp) {
              const date = new Date(timestamp)
              const offset = date.getTimezoneOffset()
              const adjustedDate = new Date(date.getTime() + offset * 60000)
              const formattedDate = `${adjustedDate.getUTCFullYear()}-${(adjustedDate.getUTCMonth() + 1).toString().padStart(2, '0')}-${date.getUTCDate().toString().padStart(2, '0')}`
              return formattedDate
            }
          }
        }
      }
    },
    xaxis: {
      type: 'datetime',
      tooltip: {
        enabled: true
      }
    },
    yaxis: [
      {
        min: Math.min(...data?.map(point => point.y)),
        labels: {
          formatter: function (value) {
            return formatCurrencyWithoutSign(value, 1)
          }
        },
        tickAmount: 10
      }
    ],
    markers: {
      size: 0
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      custom: function ({ dataPointIndex, w }) {
        // const preTaxValue = w.globals.series && w.globals.series[0] && w.globals.series[0][dataPointIndex]?.toFixed(2)
        // return `<div style="padding: 8px; border-radius: 4px; color: #000; background-color: #fff;">
        //               <div>
        //                 <span style="display: inline-block; width: 10px; height: 10px; background-color: ${w.config.colors && w.config.colors[0]}; border-radius: 50%; margin-right: 5px;"></span>
        //                 <span>$${formatCurrencyWithoutSign(preTaxValue)}</span>
        //               </div>
        //           </div>`
        return ''
      }
    },
    stroke: {
      curve: 'straight',
      width: 1.8
    },
    colors: ['#002A59'],
    noData: {
      text: 'No market value data available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '18px',
        fontFamily: undefined
      }
    },
    legend: {
      show: true, // Show legend only if postTax data is present
      showForSingleSeries: true,
      position: 'left',
      horizontalAlign: 'left',
      floating: true,
      fontSize: '12px',
      offsetX: 40,
      offsetY: -10,
      markers: {
        width: 15,
        height: 2,
        radius: 0,
        offsetY: -4
      },
      tooltipHoverFormatter: function (seriesName, opts) {
        if (opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] >= 0) {
          return seriesName + ' - <span style="color: green;font-weight:600;">' + formatNumbersWithSymbol(opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex], 2, '$') + '</span>'
        }
        return seriesName + ' - <span style="color: red;font-weight:600;">' + formatNumbersWithSymbol(opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex], 2, '$') + '</span>'
      },
      onItemClick: {
        toggleDataSeries: false
      }
    },
    annotations: annotationsObj,
    grid: {
      borderColor: '#74788d33',
      position: 'back'
    }
  }

  const series = [
    {
      name: 'Total Market Value',
      data: data?.map((point) => ({ x: point?.x, y: point?.y }))
    }
  ]

  return (
    <>
      <Chart ref={chartRef} options={options} series={series} type='line' height='100%' id='market-chart-expanded' />
    </>
  )
}
export default MarketValueAnnotationChart
