import React, { memo } from 'react'
import { Handle, Position } from '@xyflow/react'
import { Box, Typography } from '@mui/material'
import { formatCurrencyWithSymbol } from '../../../../../utils/FormateCurrenyInMilion'

export default memo(({ data, isConnectable }) => {
  return (
    <>
      <Handle
        type='target'
        position={Position.Top}
        style={{ top: '-4px', background: '#555' }}
        isConnectable={isConnectable}
      />
      <Box className='react-flow__node-default inner-box' style={{ backgroundColor: 'transparent', color: data?.nodeData?.color, borderColor: data?.nodeData?.color }}>
        <Typography fontSize='14px'>{data?.nodeData?.name}</Typography>
        <Box display='flex' justifyContent='space-between'>
          <Typography fontSize='14px' className='row-div'>Market Value <span>{data?.nodeData?.marketValue !== undefined && data?.nodeData?.marketValue !== null ? formatCurrencyWithSymbol(data?.nodeData?.marketValue, 2, '$') : 'NA'}</span></Typography>
          <Typography fontSize='14px' className='row-div'>Tracking Error <span>{data?.nodeData?.te === null || data?.nodeData?.te === undefined ? 'NA' : `${data?.nodeData?.te}%`}</span></Typography>
        </Box>
      </Box>
      <Handle
        type='source'
        position={Position.Bottom}
        style={{ bottom: '-4px', top: 'auto', background: '#555' }}
        isConnectable={isConnectable}
      />
    </>
  )
})
