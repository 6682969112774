import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { API } from 'aws-amplify'
import { Link, useNavigate } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import * as Sentry from '@sentry/react'
import { Box, Button, Card, CardContent, Skeleton, styled, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { DataGridPro, GridActionsCellItem, gridClasses } from '@mui/x-data-grid-pro'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { useSuccessToast } from '../../../hooks/useSuccessToast'
import { ACCESS_LEVEL } from '../../../contstants/constants'
import { moduleConfig } from '../../../contexts/data'
import Loader from '../../Loader'
import ErrorFallback from '../../ErrorFallback'
import SponsorSelectionPopup from '../AggregateOnboarding/SponsorSelectionPopup'
import '../aggregate.scss'

const renderTableSkeleton = (header, hiddenColumns) => {
  if (hiddenColumns)
    header = header.filter(col => !hiddenColumns.includes(col.field))
  return (
    <TableContainer mt={5}>
      <Table className='risk-page-table'>
        <TableHead>
          <TableRow>
            {header.map((item, index) => {
              return (
                <TableCell key={index}>{item.headerName}</TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: 5 }).map((_, index) => (
            <TableRow key={index}>
              {Array.from({ length: header.length }).map((_, index) => (
                <TableCell key={index}>
                  <Skeleton variant='text' sx={{ fontSize: '1rem', width: 100 }} />
                </TableCell>))}
            </TableRow>))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const CustomTab = styled(Tab)(({ theme, selected }) => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  textTransform: 'capitalize',
  fontSize: '16px',
  color: '#34475A',
  fontWeight: 400,
  '&.Mui-selected': {
    fontWeight: 400,
    color: '#34475A'
  }
}))

const renderDataGridPro = (rows, columns) => {
  return (
    <DataGridPro
      rows={rows}
      columns={columns}
      getRowId={(row) => row?.aggGroupId}
      autoHeight
      pagination
      pageSizeOptions={[10, 50, 100]}
      initialState={{
        ...rows.initialState,
        pagination: { paginationModel: { pageSize: 10 } },
        columns: {
          columnVisibilityModel: {
            aggGroupId: false
          }
        }
      }}
      disableRowSelectionOnClick
      sx={(theme) => ({
        border: 'none',
        fontFamily: 'Open Sans',
        [`.${gridClasses.main}`]: {
          height: 'calc(100vh - 48px - 15px - 20px - 55px - 16px - 24px - 48px - 5px - 5px - 53px)'
        },
        [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
          color: '#74788d',
          fontWeight: 600
        },
        [`.${gridClasses.columnHeaders}`]: {
          position: 'sticky',
          top: 0,
          zIndex: 3,
          backgroundColor: 'white'
        },
        [`.${gridClasses.footerContainer}`]: {
          position: 'sticky',
          bottom: 0,
          zIndex: 3,
          backgroundColor: 'white'
        },
        [`.${gridClasses.virtualScroller}`]: {
          overflowY: 'auto !important',
          scrollbarWidth: 'none'
        }
      })}
    />
  )
}

const SelectFamilyAccount = ({ title }) => {
  const { user, checkAccess } = useAuth()
  const userData = useSelector(state => state.user)
  const navigate = useNavigate()
  const { showError } = useErrorToast()
  const { showSuccess } = useSuccessToast()
  const [isGroupLoading, setIsGroupLoading] = useState(false)
  const [groupData, setGroupData] = useState([])
  const [umaData, setUmaData] = useState([])
  const [copyGroupData, setCopyGroupData] = useState([])
  const [copyUmaData, setCopyUmaData] = useState([])
  const [selectedTab, setSelectedTab] = useState('UMA')
  const [searchText, setSearchText] = useState('')
  const [isDeleteGroupLoading, setIsDeleteGroupLoading] = useState(false)
  const [showSponsorSelection, setShowSponsorSelection] = useState(false)
  const [isSponsorsLoading, setIsSponsorsLoading] = useState(!userData?.allowedSponsorList?.length)
  const [sponsorList, setSponsorsList] = useState(userData?.allowedSponsorList || [])
  const tabList = ['UMA', 'Groups']

  const fetchGroupData = () => {
    setIsGroupLoading(true)
    API.get(
      'baseUriAggregateMaster',
      `aggregate-master/v1/${user?.userGroup}/agg-group`
    )
      .then((response) => {
        if (response?.data?.length > 0) {
          const groupData = response.data?.filter(obj => obj?.groupType !== 'UMA')
          setGroupData(groupData)
          setCopyGroupData(groupData)
          const umaData = response.data?.filter(obj => obj?.groupType === 'UMA')
          setUmaData(umaData)
          setCopyUmaData(umaData)
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setIsGroupLoading(false)
      })
  }

  const deleteGroup = (id) => {
    setIsDeleteGroupLoading(true)
    API.del('baseUriAggregateMaster', `aggregate-master/v1/${user?.userGroup}/aggregate-grouping/${id}`)
      .then((res) => {
        if (res?.success) {
          showSuccess(res?.message)
          setCopyGroupData(copyGroupData.slice().filter(grp => grp.aggGroupId !== id))
          setGroupData(copyGroupData.slice().filter(grp => grp.aggGroupId !== id))
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setIsDeleteGroupLoading(false)
      })
  }

  const fetchSponsorsList = () => {
    setIsSponsorsLoading(true)
    API.get('baseSponserURL', `data-maintenance/v1/${user?.userGroup}/sponsorIds`)
      .then(response => {
        if (response?.data?.data?.length) {
          setSponsorsList(response.data.data)
        }
      })
      .catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => setIsSponsorsLoading(false))
  }

  const editGroup = ({ sponsorId, aggGroupId }) => {
    navigate('/aggregate/group-onboarding', { state: { sponsorId, edit: true, aggGroupId } })
  }

  useEffect(() => {
    if (user) {
      fetchGroupData()
      if (!sponsorList.length)
        fetchSponsorsList()
    }
  }, [user])

  const groupColumns = [
    {
      field: 'aggGroupId',
      headerName: 'Group Id',
      flex: 1
    },
    {
      field: 'aggGroupName',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => (
        params?.row?.groupType !== 'ENTITYMAP'
          ? <Link
            className='linkId'
            to={`/aggregate/${title}/${params?.row?.aggGroupId}`}
            state={{
              aggGroupName: params?.row?.aggGroupName,
              aggGroupCd: params?.row?.aggGroupCd,
              groupType: params?.row?.groupType,
              groupLabel: params?.row?.groupLabel
            }}
          >
            {params?.value || 'N/A'}
          </Link>
          : <Link
            className='linkId'
            to={params?.row?.aggGroupId === '55aba481-4927-4505-a562-479c9c2e8c73' ? `/aggregate/aggregate-view-group/${params?.row?.aggGroupId}` : `/aggregate/entity-mapping/${params?.row?.aggGroupId}`}>
            {params?.value}
          </Link>
      )
    },
    {
      field: 'groupLabel',
      headerName: 'Group Type',
      flex: 1
    },
    {
      field: 'aggGroupCd',
      headerName: 'Group Code',
      flex: 1
    },
    {
      field: 'actions',
      headerName: '',
      type: 'actions',
      width: 100,
      getActions: (params) =>
        [
          ...(checkAccess(moduleConfig.AGGREGATE, ACCESS_LEVEL.COMPONENT_ACCESS, {
            subModuleName: moduleConfig.GROUP_ONBOARDING, component_name: moduleConfig.EDIT_GROUP
          }) ? [
            <GridActionsCellItem
              icon={<EditIcon />}
              label='Edit'
              onClick={() => editGroup({ sponsorId: params?.row?.sponsorId, aggGroupId: params?.id })}
            />
          ] : []),
          ...(checkAccess(moduleConfig.AGGREGATE, ACCESS_LEVEL.COMPONENT_ACCESS, {
            subModuleName: moduleConfig.GROUP_ONBOARDING, component_name: moduleConfig.DELETE_GROUP
          }) ? [
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label='Delete'
              onClick={() => deleteGroup(params?.id)}
            />
          ] : [])
        ]
    }
  ]

  const umaColumns = [
    {
      field: 'aggGroupId',
      headerName: 'Group Id',
      flex: 1
    },
    {
      field: 'aggGroupName',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => (
        params?.row?.groupType !== 'ENTITYMAP'
          ? <Link
            className='linkId'
            to={`/aggregate/${title}/${params?.row?.aggGroupId}`}
            state={{
              aggGroupName: params?.row?.aggGroupName,
              aggGroupCd: params?.row?.aggGroupCd,
              groupType: params?.row?.groupType,
              groupLabel: params?.row?.groupLabel
            }}
          >
            {params?.value || 'N/A'}
          </Link>
          : <Link
            className='linkId'
            to={`/aggregate/entity-mapping/${params?.row?.aggGroupId}`}>
            {params?.value}
          </Link>
      )
    },
    {
      field: 'groupLabel',
      headerName: 'Group Type',
      flex: 1
    },
    {
      field: 'aggGroupCd',
      headerName: 'Group Code',
      flex: 1
    }
  ]

  const redirectToGroupOnboarding = () => {
    if (user?.userGroup === 'adv-classic') {
      if (!sponsorList?.length) {
        showError('No sponsor available for creating group.')
      } else
        navigate('/aggregate/group-onboarding', { state: { sponsorData: sponsorList[0] } })
    }
    else
      setShowSponsorSelection(true)
  }

  const renderTabPanel = (tab) => {
    switch (tab) {
      case 'UMA':
        return (
          <>
            {
              isGroupLoading
                ? renderTableSkeleton(umaColumns, ['aggGroupId'])
                : renderDataGridPro(umaData, umaColumns)
            }
          </>
        )
      case 'Groups':
        return (
          <>
            {
              isGroupLoading
                ? renderTableSkeleton(groupColumns, ['aggGroupId'])
                : renderDataGridPro(groupData, groupColumns)
            }
          </>
        )
      default:
        return <></>
    }
  }

  const onNextClick = (newSponsor) => {
    setShowSponsorSelection(false)
    navigate('/aggregate/group-onboarding', { state: { sponsorData: newSponsor, edit: false } })
  }

  return (
    <>
      {isDeleteGroupLoading ? <Loader /> : ''}
      <Box className='selectListofAccount'>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography className='page-title' mb={2}>Select Aggregate to Access Dashboard</Typography>
          <TextField
            size='small'
            label={`Search by Group Name`}
            variant='outlined'
            type='text'
            value={searchText || ''}
            autoComplete='off'
            onChange={(event) => {
              setSearchText(event.target.value)
              setGroupData(copyGroupData.filter(obj => obj?.aggGroupName?.toLowerCase().includes(event.target.value?.toLowerCase())))
              setUmaData(copyUmaData.filter(obj => obj?.aggGroupName?.toLowerCase().includes(event.target.value?.toLowerCase())))
            }}
          />
        </Box>
        <Card className='card-layout'>
          <CardContent>
            {
              <TabContext value={selectedTab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                  <TabList
                    onChange={(_, value) => setSelectedTab(value)}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: '#34475A'
                      }
                    }}
                    indicatorColor='#34475A'
                  >
                    {tabList.map((tab, index) => (
                      <CustomTab key={index} label={tab} value={tab} />
                    ))}
                  </TabList>
                  {
                    !isSponsorsLoading && selectedTab !== 'UMA' && checkAccess(moduleConfig.AGGREGATE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.GROUP_ONBOARDING, component_name: moduleConfig.CREATE_GROUP }) ? (
                      <Button
                        variant='text'
                        onClick={redirectToGroupOnboarding}
                      >
                        + Add Group
                      </Button>
                    ) : <Box></Box>
                  }
                </Box>
                {tabList.map((tab, index) => (
                  <TabPanel key={index} value={tab} sx={{ p: 0 }}>
                    <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen="not main" />)}>
                      {renderTabPanel(tab)}
                    </ErrorBoundary>
                  </TabPanel>
                ))}
              </TabContext>
            }
          </CardContent>
        </Card>
      </Box>
      {showSponsorSelection ? <SponsorSelectionPopup open={showSponsorSelection} onClose={() => setShowSponsorSelection(false)} onNextClick={onNextClick} data={sponsorList} /> : ''}
    </>
  )
}

export default SelectFamilyAccount