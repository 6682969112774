import { useEffect, useRef, useState } from 'react'
import Chart from 'react-apexcharts'
import { formatCurrencyWithSymbol } from '../../../../utils/FormateCurrenyInMilion'

const MarketValueChart = ({ data, setIsChartZoomed, seed }) => {
  const chartRef = useRef(null)

  const resetZoom = () => {
    if (chartRef.current && data && data.length > 0) {
      const minX = new Date(data[0]?.data[0]?.x).getTime()
      const maxX = new Date(data[0]?.data[data[0]?.data.length - 1]?.x).getTime()
      // Reset the zoom by updating the x-axis range
      chartRef.current.chart.updateOptions({
        xaxis: {
          min: minX,
          max: maxX
        }
      })
      setIsChartZoomed(false)
    }
  }

  const options = {
    xaxis: {
      type: 'datetime',
      tooltip: {
        enabled: true
      }
    },
    yaxis: [
      {
        title: {
          text: 'Total Portfolio Value ($)',
          offsetX: 0,
          style: {
            fontSize: '12px',
            color: '#74788D',
            fontWeight: 500
          }
        },
        // min: Math.min(...data?.map(obj => obj.metric.NET_MV_PLUS_CASH)),
        labels: {
          formatter: function (value) {
            return formatCurrencyWithSymbol(value, 1, '')
          }
        },
        tickAmount: 7
      }
    ],
    markers: {
      size: 0
    },
    dataLabels: {
      enabled: false
    },
    chart: {
      events: {
        zoomed: function (chartContext, { xaxis, yaxis }) {
          setIsChartZoomed(true)
        }
      },
      toolbar: {
        show: false
      }
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const preTaxValue = series[0]?.[dataPointIndex]?.toFixed(2)
        return `<div style="padding: 8px; border-radius: 4px; color: #000; background-color: #fff;">
                      <div>
                        <span style="display: inline-block; width: 10px; height: 10px; background-color: ${w.config.colors[0]}; border-radius: 50%; margin-right: 5px;"></span>
                        <span>${formatCurrencyWithSymbol(preTaxValue, 1, '$')}</span>
                      </div>
                  </div>`
      }
    },
    legend: {
      show: true,
      position: 'top'
    },
    stroke: {
      curve: 'straight',
      width: 1.8
    },
    fill: {
      type: 'gradient',
      gradient: {
        enabled: true,
        opacityFrom: 0.5,
        opacityTo: 0
      }
    },
    colors: ['#002A59', '#33A68D', '#74788D'],
    noData: {
      text: 'No performance data available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '18px',
        fontFamily: undefined
      }
    }
  }

  useEffect(() => {
    resetZoom()
  }, [data, seed])

  return (
    <Chart ref={chartRef} options={options} series={data} type='area' height={500} width='100%' />
  )
}

export default MarketValueChart