import React, { useEffect, useState } from 'react'
import { Divider, Stack, Typography } from '@mui/material'
import { AllocationVerticalBarChart } from './AllocationVerticalBarChart'
import { DataGridPro } from '@mui/x-data-grid-pro'

const FactorTab = ({ allocationData }) => {
  const [marketCapData, setMarketCapData] = useState([])
  const [factorData, setFactorData] = useState([])
  const [labelBackgroundColor, setLabelBackgroundColor] = useState([])
  const [sectorTableColumns, setSectorTableColumns] = useState([])
  const [sectorData, setSectorData] = useState([])

  useEffect(() => {
    const backgroundColor = ['#4B8CCA', '#4B8CCACC', '#4B8CCA80', '#4B8CCA4D']
    const marketData = allocationData?.marketCapData?.map((data,index) => {
      return data?.label?.toLowerCase() === 'portfolio' ? { ...data, backgroundColor: '#34C38F' } : { ...data, backgroundColor: backgroundColor[index] }
    })

    const factorDataSet = allocationData?.factorData?.map((data, index) => {
      return data?.label?.toLowerCase() === 'portfolio' ? { ...data, backgroundColor: '#34C38F' } : { ...data, backgroundColor: backgroundColor[index] }
    })
    const labelsBackgroundColor = marketData?.map((data) => { return data.backgroundColor })
    setMarketCapData(marketData)
    setFactorData(factorDataSet)
    setLabelBackgroundColor(labelsBackgroundColor)
    setSectorData(allocationData?.sectorData)
    setSectorTableColumns(allocationData?.sectorColumns)
  }, [allocationData])

  return (
    <>
      {marketCapData?.length
        ? <AllocationVerticalBarChart
            labels={marketCapData?.map(dataObj => dataObj?.label)}
            backgroundColor={labelBackgroundColor}
            chartHeader='By Marketcap'
            options={{
              plugins: {
                tooltip: {
                  enabled: true,
                  callbacks: {
                    label: (context) => {
                      const label = context.dataset.label || ''
                      const value = context.dataset.data[context.dataIndex]
                      return [`${label} :  ${value.toFixed(1)}%`]
                    }
                  }
                },
                legend: {
                  display: false
                },
                datalabels: {
                  display: false
                },
                title: {
                  display: true
                }
              },
              responsive: true,
              interaction: {
                mode: 'index',
                intersect: false
              },
              scales: {
                x: {
                  grid: {
                    display: false
                  }
                },
                y: {
                  display: true,
                  grid: {
                    display: false
                  }
                }
              }
            }}
            data={{
              labels: allocationData?.marketCapLabels || [],
              datasets: marketCapData
            }}
          />
        : <Stack>
          <Typography sx={{ pt: 2.5, fontWeight: 600, color: '#74788D' }}>
            By Marketcap
          </Typography>
          <Typography className='no-data-text' my={2} textAlign={'left'}>
            No marketcap data available.
          </Typography>
        </Stack>}

      <Divider sx={{ bgcolor: 'rgba(116, 120, 141, 0.15)', marginTop: '22px', mx: -2 }} />
      <Stack>
        <Typography sx={{ pt: 2.5, fontWeight: 600, color: '#74788D' }}>
          By Sector
        </Typography>
        {
          sectorData.length
        ? <DataGridPro
            rows={sectorData}
            columns={sectorTableColumns}
            autoHeight
            hideFooter
          />
        : <Typography className='no-data-text' my={2} textAlign={'left'}>
            No sector data available.
          </Typography>
        }
      </Stack>

      <Divider sx={{ bgcolor: 'rgba(116, 120, 141, 0.3)', marginTop: '22px', mx: -2 }} />
      {factorData?.length
        ? <AllocationVerticalBarChart
            chartHeader='By Factors'
            labels={factorData?.map(dataObj => dataObj?.label)}
            backgroundColor={labelBackgroundColor}
            options={{
              plugins: {
                tooltip: {
                  enabled: true,
                  callbacks: {
                    label: (context) => {
                      const label = context.dataset.label || ''
                      const value = context.dataset.data[context.dataIndex]
                      return [`${label} :  ${value.toFixed(1)}`]
                    }
                  }
                },
                legend: {
                  display: false
                },
                datalabels: {
                  display: false
                },
                title: {
                  display: true
                }
              },
              categoryPercentage: 0.6,
              responsive: true,
              interaction: {
                mode: 'index',
                intersect: false
              },
              scales: {
                x: {
                  display: true,
                  grid: {
                    display: false,
                    borderColor: 'white'
                  },
                  gridLines: {
                      zeroLineColor: 'rgba(0, 0, 0, 1)',
                      drawOnChartArea: false,
                      drawTicks: false
                    }
                },
                y: {
                  ticks: {
                    beginAtZero: true
                  },
                    display: true,
                    grid: {
                      beginAtZero: true,
                      display: true,
                      color: (context) => {
                        const ZeroLine = context.tick.value
                        const gridColor = ZeroLine === 0 ? '#74788D4D' : 'transparent'
                        return gridColor
                      }
                    }
                }
              }
            }}
            data={{
              labels: allocationData?.factorLabels || [],
              datasets: factorData
            }}
          />
        : <Stack>
          <Typography sx={{ pt: 2.5, fontWeight: 600, color: '#74788D' }}>
            By Factors
          </Typography>
          <Typography className='no-data-text' my={2} textAlign={'left'}>
            No factor allocation available.
          </Typography>
        </Stack>}
    </>
  )
}

export default FactorTab
