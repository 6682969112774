import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Card, CardContent, Modal, Grid, MenuItem, TextField, Typography } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { API } from 'aws-amplify'
import * as Sentry from '@sentry/react'
import { useAuth } from '../contexts/AuthContext'
import { useErrorToast } from '../hooks/useErrorToast'
import { isNumberOnly } from '../utils/NumberUtils'
import SecurityRestictions from '../pages/AccountOnboarding/SecurityRestictions'
import Success from '../assets/images/success.svg'
import Loader from '../pages/Loader'
import CloseIcon from '@mui/icons-material/Close'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  borderRadius: '4px'
}
const StrategyOnboarding = () => {
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const [loading, setLoading] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [resetSecurityRestrictions, setResetSecurityRestrictions] = useState(false)
  const [securityError, setSecurityError] = useState({})
  const [riskModelVerId, setRiskModelVerId] = useState([])
  const [strategyType, setStrategyType] = useState([])
  const strategySource = [{
    label: 'Internal', value: 'INT'
  },
  {
    label: 'External', value: 'EXT'
  }
  ]

  const aggregationLevel = [{
    label: '1', value: '1'
  },
  {
    label: '2', value: '2'
  },
  {
    label: '3', value: '3'
  },
  {
    label: '4', value: '4'
  }
  ]

  const getRiskModelVersionId = async () => {
    API.get('baseUriTransactionalMaster', `transactional-master/v1/${user.userGroup}/risk-model/version`)
      .then((response) => {
        if (response.data) {
          setRiskModelVerId([...response.data])
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const getStrategyType = async () => {
    API.get('baseUriTransactionalMaster', `transactional-master/v1/${user.userGroup}/strategy-type`)
      .then((response) => {
        if (response.data) {
          setStrategyType([...response.data])
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const validationSchema = Yup.object({
    strategyName: Yup.string()
      .trim()
      .required('Strategy Name required')
      .max(100, 'Strategy Name can not be more than 100 characters')
      .min(3, 'Strategy Name can not be less than 3 characters'),
    strategyCode: Yup.string()
      .trim()
      .required('Strategy Code required')
      .max(100, 'Strategy Code can not be more than 100 characters')
      .min(3, 'Strategy Code can not be less than 3 characters'),
    vendorName: Yup.string()
      .trim()
      .required('Vendor Name required')
      .max(100, 'Vendor Name can not be more than 100 characters')
      .min(3, 'Vendor Name can not be less than 3 characters'),
    minCashLevel: Yup.number()
      .typeError('Min Cash Level must be a number')
      .required('Min Cash Level required')
      .min(0, 'Min Cash Level must be at least 0')
      .max(1, 'Min Cash Level must be between 0 and 1'),
    aggregationLevel: Yup.string().required('Aggregation Level required'),
    strategySource: Yup.string().required('Strategy Source required'),
    staticWeights: Yup.string().required('Static Weight required'),
    riskModelVerId: Yup.string().required('Risk Model Version Id required'),
    strategyType: Yup.string().required('Strategy Type required')
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    control,
    reset
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      strategyName: '',
      strategyCode: '',
      strategyType: '',
      vendorName: '',
      strategySource: '',
      minCashLevel: null,
      aggregationLevel: '',
      staticWeights: '',
      riskModelVerId: '',
      restrictedSecurities: []
    }
  })

  useEffect(() => {
    if (user) {
      getRiskModelVersionId()
      getStrategyType()
    }
  }, [user])

  const handleSecurityData = (data) => {
    const formattedSecurities = data?.map((selectedSecurity) => ({
      instrumentId: selectedSecurity.actionKey,
      restrictionId: selectedSecurity.secKey
    }))
    setValue('restrictedSecurities', formattedSecurities)
  }

  const onSubmitHandler = (data) => {
    setLoading(true)
    let payload = {}
    if (data?.restrictedSecurities && data?.restrictedSecurities?.length > 0) {
      payload = data
    } else {
      const { restrictedSecurities, ...payloadWithoutRestrictedSecurities } = data
      payload = payloadWithoutRestrictedSecurities
    }
    API.post('baseStrategyURL', `strategy/v1/${user?.userGroup}/strategies/onboarding`, { body: { ...payload } })
      .then(response => {
        if (response) {
          setModalShow(true)
          reset({})
          setResetSecurityRestrictions(true)
        }
      })
      .catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const closeModal = () => {
    setModalShow(false)
    setResetSecurityRestrictions(false)
  }

  return (
    <Box className='account-details'>
      {loading ? <Loader /> : ''}
      <Grid container mb={2}>
        <Grid item sm={12} display='flex' justifyContent='space-between'>
          <Typography className='page-title'>Strategy Onboarding</Typography>
          <Button
            variant='contained' sx={{
              padding: '10px 20px',
              height: '34px'
            }}
            onClick={handleSubmit(onSubmitHandler)}
          >Submit
          </Button>
        </Grid>
      </Grid>
      <Card className='card-layout'>
        <CardContent>
          <Typography className='account-label-header' mb={2}>
            Strategy Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} mb={2}>
              <Controller
                name='strategyName'
                control={control}
                render={({ field }) => (
                  <TextField
                    id='strategyName'
                    name='strategyName'
                    type='text'
                    label='Strategy Name *'
                    {...register('strategyName')}
                    value={field.value}
                    onChange={field.onChange}
                    placeholder='Enter Strategy Name'
                    sx={{
                      width: '100%',
                      marginTop: '0px'
                    }}
                    error={Boolean(errors.strategyName)}
                    helperText={errors.strategyName && errors.strategyName.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} mb={2}>
              <Controller
                name='strategyCode'
                control={control}
                render={({ field }) => (
                  <TextField
                    id='strategyCode'
                    name='strategyCode'
                    type='text'
                    label='Strategy Code *'
                    {...register('strategyCode')}
                    value={field.value}
                    onChange={field.onChange}
                    placeholder='Enter Strategy Code'
                    sx={{
                      width: '100%',
                      marginTop: '0px'
                    }}
                    error={Boolean(errors.strategyCode)}
                    helperText={errors.strategyCode && errors.strategyCode.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} mb={2}>
              <Controller
                name='vendorName'
                control={control}
                render={({ field }) => (
                  <TextField
                    id='vendorName'
                    name='vendorName'
                    type='text'
                    label='Vendor Name *'
                    {...register('vendorName')}
                    value={field.value}
                    onChange={field.onChange}
                    placeholder='Enter Vendor Name'
                    sx={{
                      width: '100%',
                      marginTop: '0px'
                    }}
                    error={Boolean(errors.vendorName)}
                    helperText={errors.vendorName && errors.vendorName.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} mb={2}>
              <Controller
                name='strategySource'
                control={control}
                render={({ field }) => (
                  <TextField
                    id='strategySource'
                    name='strategySource'
                    select
                    value={field.value || ''}
                    label='Strategy Source *'
                    sx={{
                      width: '100%'
                    }}
                    error={Boolean(errors.strategySource)}
                    helperText={errors.strategySource && errors.strategySource.message}
                    {...field}
                  >
                    <MenuItem value=''>Select</MenuItem>
                    {strategySource?.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} mb={2}>
              <Controller
                name='strategyType'
                control={control}
                render={({ field }) => (
                  <TextField
                    id='strategyType'
                    name='strategyType'
                    select
                    value={field.value || ''}
                    label='Strategy Type *'
                    sx={{
                      width: '100%'
                    }}
                    error={Boolean(errors.strategyType)}
                    helperText={errors.strategyType && errors.strategyType.message}
                    {...field}
                  >
                    <MenuItem value=''>Select</MenuItem>
                    {strategyType?.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.strTypeId}
                      >
                        {option.strTypeCode}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} mb={2}>
              <Box>
                <Controller
                  name='aggregationLevel'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id='aggregationLevel'
                      name='aggregationLevel'
                      select
                      value={field.value || ''}
                      label='Aggregation Level *'
                      sx={{
                        width: '100%'
                      }}
                      error={Boolean(errors.aggregationLevel)}
                      helperText={errors.aggregationLevel && errors.aggregationLevel.message}
                      {...field}
                    >
                      <MenuItem value=''>Select</MenuItem>
                      {aggregationLevel?.map((option, index) => (
                        <MenuItem
                          key={index}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} mb={2}>
              <TextField
                id='minCashLevel'
                name='minCashLevel'
                type='text'
                error={Boolean(errors.minCashLevel)}
                helperText={errors.minCashLevel && errors.minCashLevel.message}
                sx={{ width: '100%' }}
                label='Minimum Cash Level *'
                onKeyDown={(event) => isNumberOnly(event)}
                {...register('minCashLevel')}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} mb={2}>
              <Box>
                <Controller
                  name='staticWeights'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id='staticWeights'
                      name='staticWeights'
                      select
                      value={field.value || ''}
                      label='Static Weights *'
                      sx={{
                        width: '100%'
                      }}
                      error={Boolean(errors.staticWeights)}
                      helperText={errors.staticWeights && errors.staticWeights.message}
                      {...field}
                    >
                      <MenuItem value=''>Select</MenuItem>
                      <MenuItem value='true'>True</MenuItem>
                      <MenuItem value='false'>False</MenuItem>

                    </TextField>
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} mb={2}>
              <Box>
                <Controller
                  name='riskModelVerId'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id='riskModelVerId'
                      name='riskModelVerId'
                      select
                      value={field.value || ''}
                      label='Risk Model Version ID *'
                      sx={{
                        width: '100%'
                      }}
                      error={Boolean(errors.riskModelVerId)}
                      helperText={errors.riskModelVerId && errors.riskModelVerId.message}
                      {...field}
                    >
                      <MenuItem value=''>Select</MenuItem>
                      {riskModelVerId?.map((option, index) => (
                        <MenuItem
                          key={index}
                          value={option.riskModelVerId}
                        >
                          {option.riskModelVerId}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card className='card-layout' sx={{ marginTop: '22px' }}>
        <CardContent>
          <Typography className='account-label-header' sx={{ marginBottom: '30px !important' }}>
            Security Restrictions
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SecurityRestictions onData={handleSecurityData} setError={setSecurityError} size resetSecurityRestrictions={resetSecurityRestrictions} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Modal
        open={modalShow}
        onClose={closeModal}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Box className='blur-background-parent'>
          <Box sx={style}>
            <Button
              onClick={closeModal}
              sx={{
                position: 'absolute',
                right: 5,
                top: 5
              }}
              variant='secondary'
            >
              <CloseIcon />
            </Button>
            <Box className='oboardSuccess'>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
                <img src={Success} alt='' height={100} width={100} />
              </Box>
              <Typography variant='h4' align='center' mb={2} sx={{ fontFamily: 'Open Sans', fontSize: '24px', fontWeight: '600' }}>
                Successfully Onboarded the Strategy
              </Typography>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default StrategyOnboarding
