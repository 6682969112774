import React, { useEffect, useState } from 'react'
import { MarkerType } from '@xyflow/react'
import LineWeightIcon from '@mui/icons-material/LineWeight'
import { Drawer, Grid, Box, Select, MenuItem, InputLabel, FormControl, Typography, TextField, FormControlLabel, Checkbox, InputAdornment, Button } from '@mui/material'
import Straight from '../../../../assets/images/icons/straight.svg'
import Step from '../../../../assets/images/icons/step.svg'
import SmoothStep from '../../../../assets/images/icons/smooth-step.svg'
import Bezier from '../../../../assets/images/icons/bezier.svg'
import ArrowNone from '../../../../assets/images/icons/arrow-none.svg'
import LeftLinedArrow from '../../../../assets/images/icons/leftlinearrow.svg'
import LeftTriangleArrow from '../../../../assets/images/icons/lefttrianglearrow.svg'
import RightLinedArrow from '../../../../assets/images/icons/rightlinearrow.svg'
import RightTriangleArrow from '../../../../assets/images/icons/rightgtrianglearrow.svg'
import deleteIcon from '../../../../assets/images/icons/delete-node.svg'

const configJSON = {
  edgeTypes: [
    {
      image: Straight,
      optionName: 'Straight',
      optionValue: 'Straight'
    },
    {
      image: Step,
      optionName: 'Step',
      optionValue: 'Step'
    },
    {
      image: SmoothStep,
      optionName: 'SmoothStep',
      optionValue: 'SmoothStep'
    },
    {
      image: Bezier,
      optionName: 'Bezier',
      optionValue: 'Bezier'
    },
    {
      image: Bezier,
      optionName: 'SimpleBezier',
      optionValue: 'SimpleBezier'
    }
  ],
  leftArrowTypes: [
    {
      image: LeftTriangleArrow,
      optionName: 'Closed',
      optionValue: MarkerType.ArrowClosed
    },
    {
      image: LeftLinedArrow,
      optionName: 'Lined',
      optionValue: MarkerType.Arrow
    },
    {
      image: ArrowNone,
      optionName: 'None',
      optionValue: ''
    }
  ],
  rightArrowTypes: [
    {

      image: RightTriangleArrow,
      optionName: 'Closed',
      optionValue: MarkerType.ArrowClosed
    },
    {
      image: RightLinedArrow,
      optionName: 'Lined',
      optionValue: MarkerType.Arrow
    },
    {
      image: ArrowNone,
      optionName: 'None',
      optionValue: ''
    }
  ]
}

const EdgeCustomizationDrawer = ({ open, currentCustomizationEdge, setEdgeCustomizationDrawer, setEdges }) => {
  const [currentSelectedColor, setCurrentSelectedColor] = useState(currentCustomizationEdge?.style?.stroke || '')
  const [currentEdgeType, setCurrentEdgeType] = useState(configJSON.edgeTypes.find((edge) => edge.optionValue === currentCustomizationEdge?.type) || {})
  const [currentStrokeOpacity, setCurrentStrokeOpacity] = useState(currentCustomizationEdge?.style?.strokeOpacity || '')
  const [currStrokeWidth, setCurrentStokeWidth] = useState(currentCustomizationEdge?.style?.strokeWidth || '')
  const [currentArrowColor, setCurrentArrowColor] = useState(currentCustomizationEdge?.markerStart?.color || '')
  const [leftArrowType, setLeftArrowType] = useState(configJSON.leftArrowTypes.find((arrow) => arrow.optionValue === currentCustomizationEdge?.markerStart?.type) || {})
  const [rightArrowType, setRightArrowType] = useState(configJSON.rightArrowTypes.find((arrow) => arrow.optionValue === currentCustomizationEdge?.markerEnd?.type) || {})
  const [checked, setChecked] = useState(currentCustomizationEdge?.style?.strokeDasharray > 0)
  const [animationCheck, setAnimationCheck] = useState(currentCustomizationEdge?.animated || false)

  useEffect(() => {
    if (currentCustomizationEdge) {
      setCurrentSelectedColor(currentCustomizationEdge?.style?.stroke === 'skyblue' ? '#87CEEB' : currentCustomizationEdge?.style?.stroke)
      setCurrentEdgeType(configJSON.edgeTypes.find((edge) => edge.optionValue === currentCustomizationEdge?.type) || {})
      setCurrentStrokeOpacity(currentCustomizationEdge?.style?.strokeOpacity || '')
      setCurrentStokeWidth(currentCustomizationEdge?.style?.strokeWidth || '')
      setChecked(currentCustomizationEdge?.style?.strokeDasharray > 0)
      setRightArrowType(configJSON.rightArrowTypes.find((arrow) => arrow.optionValue === currentCustomizationEdge?.markerEnd?.type) || {})
      setLeftArrowType(configJSON.leftArrowTypes.find((arrow) => arrow.optionValue === currentCustomizationEdge?.markerStart?.type) || {})
      setAnimationCheck(currentCustomizationEdge?.animated || false)
      setCurrentArrowColor(currentCustomizationEdge?.markerStart?.color === 'skyblue' || currentCustomizationEdge?.markerEnd?.color === 'skyblue' ? '#87CEEB' : currentCustomizationEdge?.markerStart?.color)
    }
  }, [currentCustomizationEdge])

  const updateEdges = (id, updateFn) => {
    setEdges((edg) => {
      return edg.map((n) => {
        if (n.id === id) {
          return updateFn(n)
        }
        return n
      })
    })
  }

  const handleColorChange = (event) => {
    setCurrentSelectedColor(event.target.value)
    updateEdges(currentCustomizationEdge?.id, (n) => ({
      ...n,
      style: { ...n.style, stroke: event.target.value }
    }))
  }

  const handleLeftArrowColorChange = (event) => {
    setCurrentArrowColor(event.target.value)
    updateEdges(currentCustomizationEdge?.id, (n) => ({
      ...n,
      markerStart: { ...n.markerStart, color: event.target.value },
      markerEnd: { ...n.markerEnd, color: event.target.value }
    }))
  }

  const handleDashEdgeCheckClick = (event) => {
    setChecked(event.target.checked)
    const strokeDasharray = (event.target.checked || animationCheck) ? '5' : '0'
    updateEdges(currentCustomizationEdge?.id, (n) => ({
      ...n,
      style: { ...n.style, strokeDasharray }
    }))
  }

  const handleEdgeAnimationClick = (event) => {
    setAnimationCheck(event.target.checked)
    const isAnimated = !!event.target.checked
    updateEdges(currentCustomizationEdge?.id, (n) => ({
      ...n,
      animated: isAnimated,
      style: { ...n.style, strokeDasharray: (isAnimated || checked) ? '5' : '0' }
    }))
  }

  const handleRightArrowTypeChange = (e) => {
    const value = e.target.value
    const selectedRightArrow = configJSON.rightArrowTypes.find((arrow) => arrow.optionValue === value)
    setRightArrowType(selectedRightArrow)
    updateEdges(currentCustomizationEdge?.id, (n) => ({
      ...n,
      markerEnd: {
        ...n.markerEnd,
        type: value === 'arrowclosed'
          ? MarkerType.ArrowClosed
          : value === 'arrow'
            ? MarkerType.Arrow
            : value === 'None' ? '' : n.markerEnd?.type
      }
    }))
  }

  const handleEdgeTypeChange = (event) => {
    const value = event.target.value
    const edgeType = configJSON?.edgeTypes?.find((edge) => edge.optionValue === value)
    setCurrentEdgeType(edgeType)
    updateEdges(currentCustomizationEdge?.id, (n) => ({
      ...n,
      type: ['Straight', 'SmoothStep', 'Bezier', 'SimpleBezier'].includes(value)
        ? value
        : n.type
    }))
  }

  const handleStrokeOpacityChange = (event) => {
    const value = event.target.value
    if (value === '' || (Number(value) <= 100 && Number(value) >= 0)) {
      setCurrentStrokeOpacity(value)
      updateEdges(currentCustomizationEdge?.id, (n) => ({
        ...n,
        style: { ...n.style, strokeOpacity: value }
      }))
    }
  }

  const handleStrokeWidthChange = (event) => {
    const value = event.target.value
    if (value === '' || (Number(value) <= 5 && Number(value) >= 0)) {
      setCurrentStokeWidth(value)
      updateEdges(currentCustomizationEdge?.id, (n) => ({
        ...n,
        style: { ...n.style, strokeWidth: value }
      }))
    }
  }

  const handleLeftArrowTypeChange = (e) => {
    const value = e.target.value
    const selectedLeftArrow = configJSON.leftArrowTypes.find((arrow) => arrow.optionValue === value)
    setLeftArrowType(selectedLeftArrow)
    updateEdges(currentCustomizationEdge?.id, (n) => ({
      ...n,
      markerStart: {
        ...n.markerStart,
        type: value === 'arrowclosed'
          ? MarkerType.ArrowClosed
          : value === 'arrow'
            ? MarkerType.Arrow
            : value === 'None' ? '' : n.markerStart?.type
      }
    }))
  }

  const handleEdgeDelete = (e) => {
    setEdges((edg) => {
      return edg.filter((n) => n.id !== currentCustomizationEdge?.id)
    })
    setEdgeCustomizationDrawer(false)
  }

  const handleDrawerClose = () => {
    setEdgeCustomizationDrawer(false)
  }

  return (
    <Drawer anchor='right' open={open} onClose={handleDrawerClose}>
      <Box sx={{ width: 370, maxWidth: 370, padding: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant='h6' sx={{ fontSize: '16px' }}>
            Edge Customization
          </Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <img src={deleteIcon} alt='' onClick={handleEdgeDelete} style={{ cursor: 'pointer' }} title='delete edge' />
          </Box>
        </Box>
        <Box sx={{ mt: '1rem' }}>
          <Typography sx={{ fontWeight: 600, color: '#717982', fontSize: '16px' }}>Edge</Typography>
          <Grid container rowSpacing={2} columnSpacing={2}>

            {/* Color Picker */}
            <Grid item xs={12} sm={6}>
              <Box className='aggregate' sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                <input
                  type='color'
                  style={{ width: '26px', border: 'none', outline: 'none', borderRight: 'none' }}
                  value={currentSelectedColor}
                  onChange={handleColorChange}
                />
                <input
                  type='text'
                  onChange={handleColorChange}
                  style={{ marginLeft: '5px', width: '80px', border: 'none', outline: 'none', borderRight: 'none' }}
                  value={currentSelectedColor}
                />
              </Box>
            </Grid>

            {/* Edge Type */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant='standard' size='small'>
                <InputLabel>Type</InputLabel>
                <Select
                  value={currentEdgeType?.optionValue || 'Bezier'}
                  onChange={handleEdgeTypeChange}
                  label='Edge Type'
                  renderValue={(value) => (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <img src={currentEdgeType?.image || Bezier} alt='' style={{ marginRight: 8, height: 12, width: 12 }} />
                      {currentEdgeType?.optionName || 'Bezier'}
                    </Box>
                  )}
                >
                  <MenuItem value='Straight'>
                    <img src={Straight} alt='' style={{ marginRight: 8, height: 15, width: 15 }} />
                    Straight
                  </MenuItem>
                  <MenuItem value='SmoothStep'>
                    <img src={SmoothStep} alt='' style={{ marginRight: 8, height: 15, width: 15 }} />
                    Smooth Step
                  </MenuItem>
                  <MenuItem value='Bezier'>
                    <img src={Bezier} alt='' style={{ marginRight: 8, height: 15, width: 15 }} />
                    Bezier
                  </MenuItem>
                  <MenuItem value='SimpleBezier'>
                    <img src={Bezier} alt='' style={{ marginRight: 8, height: 15, width: 15 }} />
                    Simple Bezier
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Stroke Width */}
            <Grid item xs={12} sm={6}>
              <FormControl variant='standard' size='small'>
                <InputLabel>Width</InputLabel>
                <Select
                  value={currStrokeWidth || currentCustomizationEdge?.style?.strokeWidth}
                  onChange={handleStrokeWidthChange}
                  startAdornment={(
                    <InputAdornment position='start'>
                      <LineWeightIcon />
                    </InputAdornment>
              )}
                  label='Stroke Width'
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Opacity */}
            <Grid item xs={12} sm={6}>
              <TextField
                size='small'
                label='Opacity'
                variant='standard'
                value={currentStrokeOpacity || currentCustomizationEdge?.style?.strokeOpacity || 1}
                onChange={handleStrokeOpacityChange}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  type: 'number',
                  step: '0.1',
                  min: 0,
                  max: 1
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                  control={<Checkbox onChange={handleDashEdgeCheckClick} checked={checked} />}
                  label='Dashed edge'
                  sx={{ mr: 4 }}
                />
                <FormControlLabel
                  control={<Checkbox onChange={handleEdgeAnimationClick} checked={animationCheck} />}
                  label='Edge Animation'
                />
              </Box>
            </Grid>

          </Grid>
        </Box>
        <Box sx={{ mt: '1rem' }}>
          <Typography sx={{ fontWeight: 600, color: '#717982', fontSize: '16px' }}>Arrow</Typography>
          <Grid container rowSpacing={2} columnSpacing={2}>

            {/* Left Arrow Color */}
            <Grid item xs={12} sm={12}>
              <Box className='aggregate' sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                <input
                  type='color'
                  style={{ width: '26px', border: 'none', outline: 'none', borderRight: 'none' }}
                  value={currentArrowColor}
                  onChange={handleLeftArrowColorChange}
                />
                <input
                  type='text'
                  onChange={handleColorChange}
                  style={{ marginLeft: '5px', width: '80px', border: 'none', outline: 'none', borderRight: 'none' }}
                  value={currentSelectedColor}
                />
              </Box>
            </Grid>
            {/* Source Arrow */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant='standard' size='small'>
                <InputLabel>Source Arrow</InputLabel>
                <Select
                  value={leftArrowType?.optionValue || 'None'}
                  onChange={handleLeftArrowTypeChange}
                  label='Source Arrow'
                  renderValue={(value) => (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <img src={leftArrowType?.image || ArrowNone} alt='' style={{ marginRight: 8, height: 15, width: 15 }} />
                      {leftArrowType?.optionName || 'None'}
                    </Box>
                  )}
                >
                  <MenuItem value='None'>
                    <img src={ArrowNone} alt='' style={{ marginRight: 8, height: 15, width: 15 }} />
                    None
                  </MenuItem>
                  <MenuItem value={MarkerType.Arrow}>
                    <img src={LeftLinedArrow} alt='' style={{ marginRight: 8, height: 15, width: 15 }} />
                    Lined
                  </MenuItem>
                  <MenuItem value={MarkerType.ArrowClosed}>
                    <img src={LeftTriangleArrow} alt='' style={{ marginRight: 8, height: 15, width: 15 }} />
                    Closed
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Target Arrow */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant='standard' size='small'>
                <InputLabel>Target Arrow</InputLabel>
                <Select
                  value={rightArrowType?.optionValue || 'None'}
                  onChange={handleRightArrowTypeChange}
                  label='Target Arrow'
                  renderValue={(value) => (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <img src={rightArrowType?.image || ArrowNone} alt='' style={{ marginRight: 8, height: 15, width: 15 }} />
                      {rightArrowType?.optionName || 'None'}
                    </Box>
                  )}
                >
                  <MenuItem value='None'>
                    <img src={ArrowNone} alt='' style={{ marginRight: 8, height: 15, width: 15 }} />
                    None
                  </MenuItem>
                  <MenuItem value={MarkerType.Arrow}>
                    <img src={RightLinedArrow} alt='' style={{ marginRight: 8, height: 15, width: 15 }} />
                    Lined
                  </MenuItem>
                  <MenuItem value={MarkerType.ArrowClosed}>
                    <img src={RightTriangleArrow} alt='' style={{ marginRight: 8, height: 15, width: 15 }} />
                    Closed
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Drawer>
  )
}

export default EdgeCustomizationDrawer
