import { randomId } from '@mui/x-data-grid-generator'
import * as ActionTypes from '../Action/chartDropAction.types'
import { MAX_GRID_COUNT } from '../../../pages/PDFCustomizer/PDFLayout'

const initialState = {
  // isLoading: false,
  clickEvent: 1,
  zoomLevel: 100,
  previousEditorName: null,
  previousReportIdentifier: null,
  editorName: 'Report 1',
  templateName: '',
  customTableOverflowElementsQueue: [],
  pageHeadingFooter: {
    heading: false,
    footer: false,
    headingElement: null,
    footerElement: null,
    value: {
      templateType: 'Transition Report',
      accountName: 'SARAH DAVIS TM',
      companyName: 'ARIS INVESTING',
      companyLogoUrl: '',
      textFooter:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    }
  },
  pages: [{ id: randomId(), elements: [], pageActive: false }], // initialize pages of templates
  activeElement: null,
  customTableRows: {},
  customTableRowHeight: 13,
  overlappingElementsWithTable: []
}

const chartDropReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.COVER_PAGE_DRAGGING:
      return handleCoverPageDragging(state, action)
    case ActionTypes.ITEM_DRAGGING:
      return handleItemDragging(state, action)
    case ActionTypes.CONTENT_UPDATE:
      return handleContentUpdate(state, action)
    case ActionTypes.NEW_PAGE:
      return handleNewPage(state, action)
    case ActionTypes.IS_ACTIVE:
      return handleToggleActive(state, action)
    case ActionTypes.BACKGROUND_CHANGE:
      return handleBackgroundChange(state, action)
    case ActionTypes.BACKGROUND_RESET:
      return handleBackgroundReset(state, action)
    case ActionTypes.DRAG_CHANGE:
      return handleDragChange(state, action)
    case ActionTypes.EDIT_CHARTS:
      return handleEditCharts(state, action)
    case ActionTypes.DELETE_CHART:
      return handleDeleteChart(state, action)
    case ActionTypes.EDIT_LABEL_CHANGE:
      return handleEditLabelChange(state, action)
    case ActionTypes.SET_REPORT:
      return handleSetReport(state, action)
    // case ActionTypes.IS_LOADING:
    //   return handleLoading(state, action)
    case ActionTypes.IS_ACTIVE_REMOVE:
      return handleIsActive(state, action)
    case ActionTypes.FONT_SIZE_CHANGE:
      return handleFontSizeChange(state, action)
    case ActionTypes.CHANGE_TEXT_UPDATE:
      return handleChangeUpdates(state, action)
    case ActionTypes.IS_ACTIVE_PAGE:
      return handlePageActive(state, action)
    case ActionTypes.DELETE_PAGE:
      return handleDeletePage(state, action)
    case ActionTypes.MIDDLE_PAGE:
      return handleAddMidPage(state, action)
    case ActionTypes.ZOOM_PAGE:
      return handleZoomPage(state, action)
    case ActionTypes.EDITOR_NAME_CHANGE:
      return handleEditorNameChange(state, action)
    case ActionTypes.PREVIOUS_EDITOR_NAME_CHANGE:
      return changePreviousEditorName(state, action)
    case ActionTypes.CHART_NAME_CHANGE:
      return handleChartNameChange(state, action)
    case ActionTypes.CHART_VALUES_ADD:
      return handleChartDataAdd(state, action)
    case ActionTypes.REORDER_LABELS:
      return handleReorderLabels(state, action)
    case ActionTypes.COVER_NAME_CHANGE:
      return handleCoverLabels(state, action)
    case ActionTypes.UPDATE_HEADER_OR_FOOTER_CHANGE:
      return handlePageHeadingFooter(state, action)
    case ActionTypes.RE_SET_DATA:
      return reSetData()
    case ActionTypes.SELECTED_TEMPLATES:
      return selectTemplatesItem(state, action)
    case ActionTypes.UPDATE_ELEMENT_OBJECT:
      return handleUpdateByKey(state, action)
    case ActionTypes.PDF_TO_IMAGES:
      return pdfToPage(state, action)
    case ActionTypes.ADD_COVER_PAGE_DATA:
      return addCoverResponse(state, action)
    case ActionTypes.CLOSE_PDF_MODAL:
      return closePdfModel(state)
    case ActionTypes.STORE_SVG_CODE:
      return handleSvgImageData(state, action)
    case ActionTypes.CHANGE_PAGE_LAYOUT:
      return handleLayoutChange(state, action)
    case ActionTypes.STORE_CUSTOM_TABLE_ROWS:
      return handleUpdateCustomTableRows(state, action)
    case ActionTypes.CHANGE_CUSTOM_TABLE_OVERFLOW:
      return changeCustomTableOverflowId(state, action)
    case ActionTypes.HANDLE_TABLE_OVERFLOW:
      return handleTableOverflow(state, action)
    // case ActionTypes.CREATE_PAGE_FOR_TABLE_OVERFLOW:
    //   return handlePageOverflowForTable(state, action)
    case ActionTypes.CREATE_PAGE_FOR_OVERFLOWING_ELEMENTS:
      return handlePageOverflowForElements(state, action)
    case ActionTypes.QUEUE_CHANGE:
      return handleQueueActionChange(state, action)
    default:
      return state
  }
}

const handleUpdateCustomTableRows = (state, action) => {
  return { ...state, customTableRows: { ...state.customTableRows, ...(action?.payload || {}) } }
}

const closePdfModel = (state) => {
  const updatedPagesDrag = [...state.pages]

  // Use forEach to modify elements in place
  updatedPagesDrag.forEach(
    (items) =>
    (items.elements = items.elements.filter(
      (element) => element.name !== 'PDF Holder'
    ))
  )
  return {
    ...state,
    pages: updatedPagesDrag
  }
}
const pdfToPage = (state, action) => {
  const { payload } = action
  let updatedPagesDrag = [...state.pages]

  // Use forEach to modify elements in place
  let pdfHolderPageIndex = 0
  updatedPagesDrag.forEach(
    (items, pageNumber) =>
    (items.elements = items.elements.filter(
      (element) => {
        if (element.name !== 'PDF Holder') { return element } else {
          pdfHolderPageIndex = pageNumber
        }
      }
    ))
  )

  const createChart = (overrides = {}) => {
    const newRandomId = randomId()
    return {
      name: 'PDF Uploads',
      pageByWidth: 1,
      isActive: false,
      size: payload.size,
      width: MAX_GRID_COUNT,
      height: MAX_GRID_COUNT,
      id: newRandomId,
      position: {
        w: MAX_GRID_COUNT, h: MAX_GRID_COUNT, x: 0, y: 0, i: newRandomId, static: true, isResizable: false, isDraggable: false
      },
      ...overrides
    }
  }

  if (payload?.processing) {
    if (payload?.processing === 'error') {
      const processingPageIndex = updatedPagesDrag.findIndex((items) =>
        items.elements.filter(
          (element) => element.pdfKey === payload.key && element.value === 'processing'
        ).length)
      if (processingPageIndex > -1) {
        updatedPagesDrag.splice(processingPageIndex, 1)
        updatedPagesDrag.forEach((items, pageIndex) =>
        (items.elements = items.elements.map(
          (element) => {
            element.pageIndex = pageIndex
            return element
          }
        )))
      }
    } else {
      // check at pdfHolder page if there are other elements except pdfHolder
      const isAnyElementExceptPDFHolder = updatedPagesDrag[pdfHolderPageIndex].elements.some((element) => element.name !== 'PDF Holder')
      if (isAnyElementExceptPDFHolder) {
        // if there are other elements on pdf holder page
        // create new page on next index
        const newElement = {
          elements: [
            createChart({
              headingText: payload.name,
              textShow: payload.name,
              pdfPageIndex: -1,
              pdfKey: payload.key,
              pageIndex: updatedPagesDrag.length,
              isActive: false,
              value: 'processing',
              size: '1'
            })
          ],
          pageActive: false,
          id: randomId()
        }
        updatedPagesDrag.splice(pdfHolderPageIndex + 1, 0, newElement)
      } else {
        // if there is no element on that pdfHolderPageIndex then add pdf on that index
        updatedPagesDrag[pdfHolderPageIndex].elements.push(
          createChart({
            headingText: payload.name,
            textShow: payload.name,
            pdfPageIndex: -1,
            pdfKey: payload.key,
            pageIndex: pdfHolderPageIndex,
            isActive: false,
            value: 'processing',
            size: '1'
          })
        )
      }
    }
  }
  // Ensure payload is an array
  if (Array.isArray(payload?.imagesList)) {
    const pdfPages = []
    payload?.imagesList?.forEach((items, index) =>
      pdfPages.push({
        elements: [
          createChart({
            headingText: payload.name,
            textShow: payload.name,
            pdfPageIndex: index,
            pdfKey: payload.key,
            pageIndex: updatedPagesDrag.length,
            isActive: false,
            value: items,
            size: '1'
          })
        ],
        pageActive: false,
        id: randomId()
      })
    )
    const processingPageIndex = updatedPagesDrag.findIndex((items) =>
      items.elements.filter(
        (element) => element.pdfKey === payload.key && element.value === 'processing'
      ).length)
    if (processingPageIndex > -1) {
      updatedPagesDrag.splice(processingPageIndex, 1, ...pdfPages)
    } else {
      updatedPagesDrag = [...updatedPagesDrag, ...pdfPages]
    }
    updatedPagesDrag.forEach((items, pageIndex) =>
    (items.elements = items.elements.map(
      (element) => {
        element.pageIndex = pageIndex
        return element
      }
    )))
  }

  return {
    ...state,
    pages: updatedPagesDrag
  }
}

const handleCoverPageDragging = (state, action) => {
  const { payload } = action
  const updatedPagesDrag = [...state.pages]
  const coverPageList = [
    'Cover page 1',
    'Cover page 2',
    'Cover page 3',
    'PDF Uploads'
  ]
  const getValueCoverPage = (name) => {
    const values = { payload: state.pageHeadingFooter.value }
    switch (name) {
      case 'Cover page 1':
        return {
          templateType: state?.templateName
            ? (state?.templateName?.name || state?.templateName).toUpperCase()
            : 'TRANSITION REPORT',
          companyLogoUrl: getValue(
            values,
            'companyLogoUrl',
            '',
            values
          ),
          accountCd: getValue(values, 'accountCd', 'ARISA000'),
          accountName: getValue(
            values,
            'accountName',
            'Sarah Davis TM'
          ),
          companyName: getValue(values, 'sponsorName', state?.pageHeadingFooter?.value?.companyName || 'ARIS Investing LLC')
        }
      case 'Cover page 2':
        return {
          templateType: state?.templateName
            ? (state?.templateName?.name || state?.templateName).toUpperCase()
            : 'TRANSITION REPORT',
          companyLogoUrl: getValue(
            values,
            'companyLogoUrl',
            '',
            values
          ),
          accountCd: getValue(values, 'accountCd', 'ARISA000'),
          accountName: getValue(
            values,
            'accountName',
            'Sarah Davis TM'
          ),
          companyName: getValue(values, 'sponsorName', state?.pageHeadingFooter?.value?.companyName || 'ARIS Investing LLC')
        }
      case 'Cover page 3':
        return {
          templateType: state?.templateName
            ? (state?.templateName?.name || state?.templateName).toUpperCase()
            : 'TRANSITION REPORT',
          companyLogoUrl: getValue(
            values,
            'companyLogoUrl',
            '',
            values
          ),
          accountCd: getValue(values, 'accountCd', 'ARISA000'),
          accountName: getValue(
            values,
            'accountName',
            'Sarah Davis TM'
          ),
          companyName: getValue(values, 'sponsorName', state?.pageHeadingFooter?.value?.companyName || 'ARIS Investing LLC')
        }
      default:
        return ''
    }
  }
  const newChart = {
    elements: [
      {
        headingText: payload.name,
        name: payload.name,
        templateType: state?.templateName
          ? (state?.templateName?.name || state?.templateName).toUpperCase()
          : 'TRANSITION REPORT',
        value: getValueCoverPage(payload.name),
        pageIndex: payload.dropIndex,
        isActive: false,
        size: payload.size
      }
    ],
    pageActive: false
  }

  if (updatedPagesDrag.length > 0) {
    if (coverPageList.includes(updatedPagesDrag[0].elements[0]?.name)) {
      updatedPagesDrag[0].elements[0].name = payload.name
      updatedPagesDrag[0].elements[0].headingText = payload.name
    } else {
      updatedPagesDrag.unshift(newChart)
    }
  } else {
    updatedPagesDrag.push(newChart)
  }

  return {
    ...state,
    pages: updatedPagesDrag
  }
}
const selectTemplatesItem = (state, action) => ({
  ...state,
  templateName: action.payload
})
// const dopingSecondChart = (state, action) => {
//   const { payload } = action
//   const updatedPagesDrag = [...state.pages]

//   updatedPagesDrag[payload.pageIndex].elements[payload.dropIndex].pageByWidth[
//     payload.index
//   ] = {
//     name: payload.name,
//     pageIndex: payload.dropIndex
//   }

//   return {
//     ...state,
//     pages: updatedPagesDrag
//   }
// }

const handleItemDragging = (state, action) => {
  const { payload } = action
  let updatedPages = [...state.pages]
  const newElementId = randomId()
  // if dropped element is header or footer, then don't store it in pages array, update global page header footer config
  if (
    payload?.element?.name?.startsWith('Page Heading') ||
    payload?.element?.name?.startsWith('Page Footer')
  ) {
    const isHeading = payload?.element?.name?.startsWith('Page Heading')
    if (isHeading) {
      state.pageHeadingFooter.heading = payload?.element?.name
      state.pageHeadingFooter.headingElement = {
        id: randomId(),
        ...payload?.element
      }
    } else {
      state.pageHeadingFooter.footer = payload?.element?.name
      state.pageHeadingFooter.footerElement = {
        id: randomId(),
        ...payload?.element
      }
    }
    return {
      ...state,
      pages: updatedPages,
      pageHeadingFooter: { ...state.pageHeadingFooter }
    }
  }
  // if dropped element is cover page, either replace existing cover page with new cover page or add cover page at 1st page in pages
  if (payload?.element?.name?.startsWith('Cover page')) {
    const getValueCoverPage = (name) => {
      const values = { payload: state.pageHeadingFooter.value }
      switch (name) {
        case 'Cover page 1':
          return {
            templateType: state?.templateName
              ? (state?.templateName?.name || state?.templateName).toUpperCase()
              : 'TRANSITION REPORT',
            companyLogoUrl: getValue(
              values,
              'companyLogoUrl',
              '',
              values
            ),
            accountCd: getValue(values, 'accountCd', 'ARISA000'),
            accountName: getValue(
              values,
              'accountName',
              'Sarah Davis TM'
            ),
            companyName: getValue(values, 'sponsorName', state?.pageHeadingFooter?.value?.companyName || 'ARIS Investing LLC')
          }
        case 'Cover page 2':
          return {
            templateType: state?.templateName
              ? (state?.templateName?.name || state?.templateName).toUpperCase()
              : 'TRANSITION REPORT',
            companyLogoUrl: getValue(
              values,
              'companyLogoUrl',
              '',
              values
            ),
            accountCd: getValue(values, 'accountCd', 'ARISA000'),
            accountName: getValue(
              values,
              'accountName',
              'Sarah Davis TM'
            ),
            companyName: getValue(values, 'sponsorName', state?.pageHeadingFooter?.value?.companyName || 'ARIS Investing LLC')
          }
        case 'Cover page 3':
          return {
            templateType: state?.templateName
              ? (state?.templateName?.name || state?.templateName).toUpperCase()
              : 'TRANSITION REPORT',
            companyLogoUrl: getValue(
              values,
              'companyLogoUrl',
              '',
              values
            ),
            accountCd: getValue(values, 'accountCd', 'ARISA000'),
            accountName: getValue(
              values,
              'accountName',
              'Sarah Davis TM'
            ),
            companyName: getValue(values, 'sponsorName', state?.pageHeadingFooter?.value?.companyName || 'ARIS Investing LLC')
          }
        default:
          return ''
      }
    }
    const coverPage = {
      id: randomId(),
      elements: [{
        id: newElementId,
        ...payload?.element,
        templateType: state?.templateName
          ? (state?.templateName?.name || state?.templateName).toUpperCase()
          : 'TRANSITION REPORT',
        value: getValueCoverPage(payload?.element?.name),
        position: { ...payload.position, i: newElementId, w: MAX_GRID_COUNT, h: MAX_GRID_COUNT, y: 0, x: 0, static: true, isResizable: false, isDraggable: false },
        pageIndex: 0,
        chartIndex: 0
      }]
    }

    if (!updatedPages?.length) {
      updatedPages.push(coverPage)
    }
    else if (updatedPages[0]?.elements?.length === 0 || updatedPages[0]?.elements[0]?.coverPage) {
      updatedPages[0] = coverPage
    } else {
      updatedPages.unshift(coverPage)
    }
    return {
      ...state,
      pages: updatedPages,
      pageHeadingFooter: { ...state.pageHeadingFooter }
    }
  }
  let elementDefaultSettings = {}
  if (payload?.element?.name === 'Title Text' || payload?.element?.name === 'Paragraph Text') {
    elementDefaultSettings = {
      textShow: payload?.element?.name,
      value: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'start',
        fontSize: payload?.element?.name === 'Paragraph Text' ? '6px' : '10px',
        padding: 0,
        margin: 0,
        textAlign: 'left',
        // whiteSpace: 'pre-wrap',
        wordBreak: 'break-all',
        letterSpacing: '0em',
        fontWeight: payload?.element?.name === 'Paragraph Text' ? '500' : '700'
      }
    }
  } else if (payload?.element?.name === 'Definitions') {
    elementDefaultSettings = {
      textShow: payload?.element?.name,
      heading1: 'Disclaimers',
      heading2: 'Dislosure',
      definition1:
        'ARIS Investing LLC is a registered investment adviser. Information presented is for educational purposes only and does not intend to make an offer or solicitation for the sale or purchase of any specific securities, investments, or investment strategies. Investments involve risk and, unless otherwise stated, are not guaranteed. Be sure to first consult with a qualified financial adviser and/or tax professional before implementing any strategy discussed herein. Past performance is not indicative of future performance.',
      definition2:
        'ARIS Investing LLC is a registered investment adviser. Information presented is for educational purposes only and does not intend to make an offer or solicitation for the sale or purchase of any specific securities, investments, or investment strategies. Investments involve risk and, unless otherwise stated, are not guaranteed. Be sure to first consult with a qualified financial adviser and/or tax professional before implementing any strategy discussed herein. Past performance is not indicative of future performance.',
      value: {
        isEdit: true
      }
    }
  } else if (payload?.element?.name === 'Section Page') {
    elementDefaultSettings = {
      value: {
        sectionTitle: 'Section title',
        sectionSubTitle: 'Section sub title',
        sectionUserName: 'Sarah Davis TM'
      }
    }
  }
  else if (payload?.element?.canOverflow) {
    elementDefaultSettings = {
      canOverflow: true,
      overflowElementId: newElementId,
      value: payload?.element?.name === 'Custom Table' ? null : { dataSource: payload?.element?.name },
      startIndex: 0,
      lastIndex: null
    }
  }

  // if dragged element is causing overflow, then create new container next to current page and add element in it else append element in current page
  if (payload.isOverFlowing) {
    updatedPages.splice(payload?.pageIndex + 1, 0, {
      id: randomId(),
      elements: [{
        id: newElementId,
        ...payload?.element,
        ...elementDefaultSettings,
        position: { ...payload.position, i: newElementId, w: payload.element.width, h: payload.element.height, y: 0 },
        pageIndex: payload?.pageIndex + 1,
        chartIndex: 0
      }]
    })
    updatedPages = updatedPages?.map((page, pageIndex) => ({
      ...page,
      elements: page?.elements?.map((object, elementIndex) => ({
        ...object,
        chartIndex: elementIndex,
        pageIndex
      }))
    }))
  } else {
    updatedPages[payload?.pageIndex] = {
      ...updatedPages[payload?.pageIndex],
      elements: [...updatedPages[payload?.pageIndex]?.elements, {
        id: newElementId,
        ...payload?.element,
        ...elementDefaultSettings,
        position: { ...payload.position, i: newElementId, w: payload.element.width, h: payload.element.height },
        pageIndex: payload?.pageIndex,
        chartIndex: updatedPages[payload?.pageIndex]?.elements?.length
      }]
    }
  }
  return {
    ...state,
    pages: updatedPages,
    pageHeadingFooter: { ...state.pageHeadingFooter }
  }
}

// const handleItemDragging2 = (state, action) => {
//   const { payload } = action
//   const updatedPagesDrag = [...state.pages]
//   const createChart = (overrides = {}) => ({
//     headingText: payload.headingText,
//     isEditTitle: payload.isEdit,
//     name: payload.name,
//     pageByWidth: payload.width,
//     showInRow: payload.width === '1' ? false : 'left',
//     pageIndex: payload.dropIndex,
//     chartIndex: updatedPagesDrag[payload.dropIndex].elements.length,
//     isActive: false,
//     size: payload.size,
//     ...overrides
//   })

//   const coverPageList = [
//     'Cover page 1',
//     'Cover page 2',
//     'Cover page 3',
//     'PDF Uploads'
//   ]

//   const updateHeaderFooter = (type) => {
//     const typeName = type === 'heading' ? 'Page Heading' : 'Page Footer'
//     if (state.pageHeadingFooter[type]) {
//       updatedPagesDrag.forEach((page) => {
//         const chartToUpdate = page.elements.find((object) =>
//           object.name.startsWith(typeName)
//         )
//         if (chartToUpdate === undefined) {
//           const newChart = createChart({ name: payload?.element?.name, value: state.pageHeadingFooter.value })
//           if (!coverPageList.includes(page.elements[0]?.name)) {
//             page.elements[type === 'heading' ? 'unshift' : 'push'](newChart)
//           }
//         }
//         if (chartToUpdate) {
//           chartToUpdate.name = payload.name
//           chartToUpdate.headingText = payload.name
//           state.pageHeadingFooter[type] = payload.name
//         }
//       })
//     } else {
//       state.pageHeadingFooter[type] = payload.name

//       updatedPagesDrag.forEach((page, i) => {
//         const newChart = createChart({ name: payload.name, value: state.pageHeadingFooter.value })
//         if (!coverPageList.includes(page.elements[0]?.name)) {
//           page.elements[type.includes('heading') ? 'unshift' : 'push'](newChart)
//         }
//       })
//     }
//   }
//   if (payload.dropIndex >= 0) {
//     if (payload.name === 'Title Text' || payload.name === 'Paragraph Text') {
//       updatedPagesDrag[payload.dropIndex].elements.push(
//         createChart({
//           headingText: payload.headingText,
//           textShow: payload.name,
//           value: {
//             display: 'flex',
//             justifyContent: 'start',
//             alignItems: 'center',
//             fontSize: payload.name === 'Paragraph Text' ? '6px' : '10px',
//             padding: 0,
//             margin: 0,
//             textAlign: 'left',
//             // whiteSpace: 'pre-wrap',
//             wordBreak: 'break-all',
//             letterSpacing: '0em',
//             fontWeight: payload.name === 'Paragraph Text' ? '500' : '700'
//           }
//         })
//       )
//     } else if (payload.name === 'Definitions') {
//       updatedPagesDrag[payload.dropIndex].elements.push(
//         createChart({
//           headingText: payload.headingText,
//           textShow: payload.name,
//           hedging1: 'Disclaimers',
//           hedging2: 'Dislosure',
//           definitions1:
//             'ARIS Investing LLC is a registered investment adviser. Information presented is for educational purposes only and does not intend to make an offer or solicitation for the sale or purchase of any specific securities, investments, or investment strategies. Investments involve risk and, unless otherwise stated, are not guaranteed. Be sure to first consult with a qualified financial adviser and/or tax professional before implementing any strategy discussed herein. Past performance is not indicative of future performance.',
//           definitions2:
//             'ARIS Investing LLC is a registered investment adviser. Information presented is for educational purposes only and does not intend to make an offer or solicitation for the sale or purchase of any specific securities, investments, or investment strategies. Investments involve risk and, unless otherwise stated, are not guaranteed. Be sure to first consult with a qualified financial adviser and/or tax professional before implementing any strategy discussed herein. Past performance is not indicative of future performance.',
//           value: {
//             isEdit: true
//           }
//         })
//       )
//     } else if (payload.name === 'Section Page') {
//       updatedPagesDrag[payload.dropIndex].elements.push(
//         createChart({
//           value: {
//             sectionTitle: 'Section title',
//             sectionSubTitle: 'Section sub title',
//             sectionUserName: 'Section User Name'
//           }
//         })
//       )
//     } else if (
//       payload.name.startsWith('Page Heading') ||
//       payload.name.startsWith('Page Footer')
//     ) {
//       const isHeading = payload.name.startsWith('Page Heading')
//       const type = isHeading ? 'heading' : 'footer'
//       updateHeaderFooter(type)
//     } else {
//       const chartOverrides = {
//         value: null
//         // chartLabels: []
//       }
//       const coverPageList = [
//         'Cover page 1',
//         'Cover page 2',
//         'Cover page 3',
//         'PDF Uploads'
//       ]
//       const checksCoverDrops = updatedPagesDrag[
//         payload.dropIndex
//       ].elements.find((items) => coverPageList.includes(items.name))
//       if (!checksCoverDrops) {
//         updatedPagesDrag[payload.dropIndex].elements.push(
//           createChart(chartOverrides)
//         )
//       }
//     }
//   }
//   return {
//     ...state,
//     pages: updatedPagesDrag,
//     pageHeadingFooter: { ...state.pageHeadingFooter }
//   }
// }

const handleLayoutChange = (state, action) => {
  const { payload } = action
  let updatedPages = [...state.pages]
  // checks if after changing this layout, any element has height more than available space
  if (payload?.elements?.filter(item => item?.position?.y + item?.position?.h > MAX_GRID_COUNT)?.length > 0) {
    // store current page's elements with new x,y,w,h
    updatedPages[payload?.pageIndex].elements = payload?.elements?.filter(item => item?.position?.y + item?.position?.h < MAX_GRID_COUNT)
    // move all the elements to next page which causes overflow with new x,y,w,h of the elements
    updatedPages.splice(payload?.pageIndex + 1, 0, {
      id: randomId(),
      elements: payload?.elements?.filter(item => item?.position?.y + item?.position?.h > MAX_GRID_COUNT).map(item => ({ ...item, position: { ...item.position, y: 0 } }))
    })
    updatedPages = updatedPages?.map((page, pageIndex) => ({
      ...page,
      elements: page?.elements?.map((object, elementIndex) => ({
        ...object,
        height: object?.position?.h,
        width: object?.position?.w,
        chartIndex: elementIndex,
        pageIndex
      }))
    }))
  } else {
    // store current page's elements with new x,y,w,h
    updatedPages = updatedPages.map((page, pageIdx) =>
      pageIdx === payload.pageIndex
        ? {
          ...page, elements: [...payload?.elements?.map((object) => ({
            ...object,
            height: object?.position?.h,
            width: object?.position?.w,
          }))]
        }
        : page)
  }

  return {
    ...state,
    pages: updatedPages
  }
}

const handleTableOverflow = (state, action) => {
  const { payload } = action
  const { cellCount, elementIndex, pageIndex, hasRemainingData, lastIndex } = payload
  let updatedPages = [...state.pages].map((page, index) => {
    // find current container ID
    if (index === pageIndex) {
      return {
        ...page,
        elements: page.elements.map((element, eleIndex) => {
          // find element and add extra cell count into its height
          if (eleIndex === elementIndex) {
            if (cellCount) {
              return { ...element, height: element?.position?.h + cellCount, position: { ...element.position, h: element?.position?.h + cellCount }, lastIndex }
            }
            // store last index if no extra cell needs to be added
            return { ...element, lastIndex }
          }
          return element
        })
      }
    }
    return page
  })
  let pageElements = updatedPages[pageIndex]?.elements
  const currentElement = pageElements[elementIndex]
  let updatedOverlappingElementsList = state.overlappingElementsWithTable ? [...state.overlappingElementsWithTable] : []
  if (cellCount > 0) {
    // find if there is any element that is overlapping with current element
    // elementY>= currY && elementY<=currY+currH && elementX<=currX+currW
    if (pageElements.find(elementObj => elementObj?.position?.i !== currentElement?.position?.i && elementObj?.position?.y >= currentElement?.position?.y && elementObj?.position?.y < currentElement?.position?.y + currentElement?.position?.h && elementObj?.position?.x <= currentElement?.position?.x + currentElement?.position?.w)) {
      pageElements = pageElements?.map((elementObj) => {
        // ignore elements which is not overlapping with current element
        // (elementY + elementH <= currY) || (elementX + elementW <= currX) || (elementX >= currX + currW)
        if (elementObj?.position?.i !== currentElement?.position?.i && !(
          elementObj?.position?.y + elementObj?.position?.h <= currentElement?.position?.y ||
          elementObj?.position?.x >= currentElement?.position?.x + currentElement?.position?.w ||
          elementObj?.position?.x + elementObj?.position?.w <= currentElement?.position?.x)
        ) {
          // by increasing cell count if it causes overflow, add in overflow list
          if (elementObj?.position?.y + cellCount + elementObj?.position?.h > MAX_GRID_COUNT) {
            updatedOverlappingElementsList = [...updatedOverlappingElementsList, elementObj]
          }
          // find elements which are below currentIndexElement
          else if (pageElements.find(eleObj =>
            eleObj?.position?.y >= elementObj?.position?.y + elementObj?.position?.h && // check element is below currentIndexElement
            (eleObj?.position?.y - (elementObj?.position?.y + elementObj?.position?.h + cellCount) < 0) && // check if element is overlapping if we move currentIndexElement below
            eleObj?.position?.i !== elementObj?.position?.i && // check element id is not same as currentIndexElement
            (eleObj?.position?.y + eleObj?.position?.h <= currentElement?.position?.y || // check if element is not falling under overflowing element
              eleObj?.position?.x >= currentElement?.position?.x + currentElement?.position?.w ||
              eleObj?.position?.x + eleObj?.position?.w <= currentElement?.position?.x) &&
            ((eleObj?.position?.x <= elementObj?.position?.x && eleObj?.position?.x + eleObj?.position?.w > elementObj?.position?.x) || // check horizontal position of element if it is under horizontal area covered by currentIndexElement
              (eleObj?.position?.x >= elementObj?.position?.x && eleObj?.position?.x < elementObj?.position?.x + elementObj?.position?.w)))
          ) {
            updatedOverlappingElementsList = [...updatedOverlappingElementsList, elementObj]
            return { ...elementObj, position: { ...elementObj.position, y: MAX_GRID_COUNT } }
          }
          return { ...elementObj, position: { ...elementObj.position, y: elementObj?.position?.y + cellCount } }
        }
        return elementObj
      })
      updatedPages[pageIndex].elements = pageElements.filter(elementObj => elementObj?.position?.y + elementObj?.position?.h <= MAX_GRID_COUNT)
    }
  }
  if (updatedOverlappingElementsList?.length && !hasRemainingData) {
    updatedPages.splice(pageIndex + 1, 0, { id: randomId(), elements: [...updatedOverlappingElementsList.map(element => ({ ...element, pageIndex: pageIndex + 1 }))] })
    updatedOverlappingElementsList = []
  }
  // handle new page creation if data is remaining to display
  else if (hasRemainingData) {
    let newElementId = randomId()
    // add required no of pages to show remaining rows if current element is having full page height
    if (state.customTableRows[`${currentElement?.value?.dataSource}-${currentElement?.overflowElementId}`]?.length && currentElement?.position?.y === 0 && currentElement?.position?.h === MAX_GRID_COUNT) {
      const rowsPerPage = currentElement?.lastIndex - currentElement?.startIndex + 1
      const totalRowsToDisplayLength = state?.customTableRows[`${currentElement?.value?.dataSource}-${currentElement?.overflowElementId}`]?.length
      let newPageStartIndex = currentElement?.lastIndex + 1
      Array.from({ length: Math.ceil((totalRowsToDisplayLength - newPageStartIndex) / rowsPerPage) }).forEach((_, pageIncrementIndex) => {
        let newPageLastIndex = newPageStartIndex + rowsPerPage - 1
        // if last index increases from total rows length, store length of rows as last index for last page
        if (newPageLastIndex >= totalRowsToDisplayLength) {
          newPageLastIndex = totalRowsToDisplayLength - 1
        }
        if (newPageStartIndex < newPageLastIndex) {
          updatedPages.splice(pageIndex + pageIncrementIndex + 1, 0, {
            id: randomId(),
            overflowId: currentElement?.overflowElementId,
            elements: [{
              ...currentElement,
              pageIndex: pageIndex + pageIncrementIndex + 1,
              chartIndex: 0,
              startIndex: newPageStartIndex,
              lastIndex: newPageLastIndex,
              overflowElementId: currentElement?.overflowElementId,
              id: newElementId,
              height: MAX_GRID_COUNT,
              position: {
                ...currentElement.position,
                i: newElementId,
                h: MAX_GRID_COUNT,
                y: 0
              }
            }]
          })
          newElementId = randomId()
          newPageStartIndex = newPageLastIndex + 1
        }
      })
    } else {
      updatedPages.splice(pageIndex + 1, 0, {
        id: randomId(),
        overflowId: currentElement?.overflowElementId,
        elements: [{
          ...currentElement,
          pageIndex: pageIndex + 1,
          chartIndex: 0,
          startIndex: lastIndex + 1,
          lastIndex: null,
          overflowElementId: currentElement?.overflowElementId,
          id: newElementId,
          height: MAX_GRID_COUNT,
          position: {
            ...currentElement.position,
            i: newElementId,
            h: MAX_GRID_COUNT,
            y: 0
          }
        }]
      })
    }
  }

  let newOverflowElementsId = []
  if (!hasRemainingData) {
    newOverflowElementsId = state.customTableOverflowElementsQueue?.length > 0 ? state.customTableOverflowElementsQueue?.slice(1) : []
  }

  updatedPages = updatedPages?.map((page, pageIndex) => ({
    ...page,
    elements: page?.elements?.map((element, chartIndex) => ({
      ...element,
      chartIndex,
      pageIndex
    }))
  }))

  return {
    ...state,
    overlappingElementsWithTable: updatedOverlappingElementsList,
    pages: updatedPages,
    ...(newOverflowElementsId?.length > 0 ? { customTableOverflowElementsQueue: newOverflowElementsId } : {})
  }
}

// const handlePageOverflowForTable = (state, action) => {
//   const { payload } = action
//   const { elementObj, elementIndex, lastIndex, pageIndex } = payload
//   let updatedPages = [...state.pages].map((page, index) => {
//     // find current container
//     if (index === pageIndex) {
//       return {
//         ...page,
//         elements: page?.elements?.map(element => {
//           // find element which is having overflow and store current last index for that element
//           if (elementObj.id === element.id) {
//             return { ...elementObj, lastIndex: lastIndex }
//           }
//           return elementObj
//         })
//       }
//     }
//     return page
//   })
//   const newElementId=randomId()
//   updatedPages.splice(pageIndex + 1, 0, {
//     id: randomId(),
//     elements: [{
//       ...elementObj,
//       pageIndex: pageIndex + 1,
//       startIndex: lastIndex + 1,
//       lastIndex: null,
//       overflowElementId: elementObj?.id,
//       id: newElementId,
//       height: MAX_GRID_COUNT,
//       position: {
//         ...elementObj.position,
//         i: newElementId,
//         h: MAX_GRID_COUNT,
//         y: 0
//       }
//     }]
//   })
//   return {
//     ...state,
//     pages: updatedPages
//   }
// }

const handlePageOverflowForElements = (state, action) => {
  const { payload } = action
  const { pageIndex } = payload

  let updatedPages = [...state.pages]
  let updatedOverlappingElementsList = state?.overlappingElementsWithTable?.map(element => ({ ...element, pageIndex: pageIndex + 1 }))
  updatedPages.splice(pageIndex + 1, 0, { id: randomId(), elements: [...updatedOverlappingElementsList] })
  updatedPages = updatedPages?.map((page, pageIndex) => ({
    ...page,
    elements: page?.elements?.map((element, chartIndex) => ({
      ...element,
      chartIndex,
      pageIndex
    }))
  }))

  return {
    ...state,
    overlappingElementsWithTable: [],
    pages: updatedPages
  }
}


const handleContentUpdate = (state, action) => {
  const { value } = action.payload

  const updatedPages =
    Array.isArray(state.pages) &&
    state.pages.map((page) => ({
      ...page,
      elements:
        Array.isArray(page.elements) &&
        page.elements.map((object) => ({
          ...object,
          value: object.isActive ? value : object.value
        }))
    }))

  return { ...state, pages: updatedPages }
}
const handleNewPage = (state, action) => {
  // const { pages, pageHeadingFooter } = state
  // const headingList = ['Page Heading 1', 'Page Heading 2']
  // const footerList = ['Page Footer 1', 'Page Footer 2', 'Page Footer 3']
  // const modifiedPages = [...pages]
  // const chartArray = modifiedPages.reduce((acc, page) => {
  //   const filteredElements = page.elements.find(
  //     (obj) =>
  //       (pageHeadingFooter.heading && headingList.includes(obj.name)) ||
  //       (pageHeadingFooter.footer && footerList.includes(obj.name))
  //   )
  //   const elements = page.elements.find(
  //     (obj) => pageHeadingFooter.footer && footerList.includes(obj.name)
  //   )

  //   if (pageHeadingFooter.heading && pageHeadingFooter.footer) {
  //     if (filteredElements && (acc.length === 0 || acc.length === 1)) {
  //       acc.push(filteredElements, elements)
  //     }
  //   } else {
  //     if (filteredElements && acc.length === 0) {
  //       acc.push(filteredElements)
  //     }
  //   }

  //   return acc
  // }, [])

  const newPage = { id: randomId(), elements: [] }
  const newPageArray = action.payload === 'top'
    ? [newPage, ...state.pages]
    : [...state.pages, newPage]
  const updatedPages = newPageArray.map((page, pageIndex) => ({
    ...page,
    elements: page.elements.map((object) => ({
      ...object,
      pageIndex
    }))
  }))

  return {
    ...state,
    pages:
      [...updatedPages],
  }
}
const handleToggleActive = (state, action) => {
  const { pageIndex, elementId, isHeader, isFooter, clearActiveElement } = action.payload

  if (clearActiveElement) {
    return {
      ...state,
      activeElement: null
    }
  }

  if (isHeader || isFooter) {
    return {
      ...state,
      activeElement: isHeader ? 'headingElement' : isFooter ? 'footerElement' : null
    }
  }
  let updatedActiveElement = state.activeElement
  state?.pages?.forEach((page, pageIdx) => {
    page.elements.forEach((object) => {
      if (pageIndex === pageIdx && elementId === object.id) {
        updatedActiveElement = object.id
      }
    })
  })

  return {
    ...state,
    activeElement: updatedActiveElement
  }
}
const hexToRgba = (hex) => {
  // Remove '#' if it's included in the hex code
  const cleanHex = hex.replace('#', '')

  // Parse the hexadecimal values for red, green, blue, and alpha (opacity)
  const r = parseInt(cleanHex.substring(0, 2), 16)
  const g = parseInt(cleanHex.substring(2, 4), 16)
  const b = parseInt(cleanHex.substring(4, 6), 16)

  // Set default alpha value to 1 (fully opaque) if not provided
  const a =
    cleanHex.length > 6 ? parseInt(cleanHex.substring(6, 8), 16) / 255 : 0.7

  // Return the RGBA color in the 'rgba(r, g, b, a)' format
  return `rgba(${r}, ${g}, ${b}, ${a})`
}
const handleBackgroundChange = (state, action) => {
  const { elementId, value, index: columnIndexToChange } = action.payload
  const colors = [
    'rgba(75, 140, 202, 1)',
    'rgba(164, 197, 227, 1)',
    'rgba(52, 195, 143, 1)',
    'rgba(200, 219, 238, 1)',
    'rgba(235, 242, 248, 1)'
  ]

  // const updatedPages = state.pages.map((page) => {
  //   const activeChart = page.elements.find((object) => object.id === elementId)

  //   if (activeChart) {
  //     if (action.payload === 'reset-color') {
  //       activeChart.value.datasets = activeChart.value.datasets.map(
  //         (setColor, i) => ({
  //           ...setColor,
  //           backgroundColor: colors[i],
  //           borderColor: colors[i]
  //         })
  //       )
  //     } else {
  //       activeChart.value.datasets[index].backgroundColor = hexToRgba(value)
  //       activeChart.value.datasets[index].borderColor = hexToRgba(value)
  //     }
  //   }

  //   return page
  // })
  const updatedPages = state?.pages?.map(page => ({
    ...page,
    elements: page?.elements?.map(object => ({
      ...object,
      value: object?.id === state?.activeElement ? {
        ...object?.value,
        datasets: object?.value?.datasets?.map((columnObj, index) => ({
          ...columnObj,
          backgroundColor: index === columnIndexToChange ? hexToRgba(value) : columnObj?.backgroundColor,
          borderColor: index === columnIndexToChange ? hexToRgba(value) : columnObj?.backgroundColor
        }))
      } : object?.value
    }))
  }))

  return { ...state, pages: updatedPages, clickEvent: state.clickEvent + 1 }
}

const handleBackgroundReset = (state, action) => {
  // add colorArray in payload to override default colors
  const colorArray = action.payload?.colorArray

  const colors = [
    'rgba(75, 140, 202, 1)',
    'rgba(164, 197, 227, 1)',
    'rgba(52, 195, 143, 1)',
    'rgba(200, 219, 238, 1)',
    'rgba(235, 242, 248, 1)'
  ]

  const colorArrayLength = colorArray ? colorArray?.length : 0, colorsLength = colors.length

  const updatedPages = state?.pages?.map(page => ({
    ...page,
    elements: page?.elements?.map(object => ({
      ...object,
      value: object?.id === state?.activeElement ? {
        ...object?.value,
        datasets: object?.value?.datasets?.map((columnObj, index) => ({
          ...columnObj,
          backgroundColor: colorArray ? colorArray[index % (colorArrayLength - 1)] : colors[index % (colorsLength - 1)],
          borderColor: colorArray ? colorArray[index % (colorArrayLength - 1)] : colors[index % (colorsLength - 1)]
        }))
      } : object?.value
    }))
  }))

  return { ...state, pages: updatedPages }
}

const isPageHeading = (element) => element?.name.startsWith('Page Heading')
const isPageFooter = (element) => element?.name.startsWith('Page Footer')

const swapElements = (arr, index1, index2) => {
  return ([arr[index1], arr[index2]] = [arr[index2], arr[index1]])
}

const byPageSwapElements = (
  arr,
  key,
  childIndex,
  dropIndex,
  dragIndex,
  dropPageIndex,
  dropElementsIndex
) => {
  if (!key || typeof key !== 'string') {
    console.error('Invalid key')
    return arr
  }

  const newArr = [...arr]

  // Check if all the indices are valid
  const isValidIndex = (index, array) => index >= 0 && index < array.length

  if (
    !isValidIndex(childIndex, newArr) ||
    !isValidIndex(dropIndex, newArr) ||
    !isValidIndex(dragIndex, newArr) ||
    !isValidIndex(dropPageIndex, newArr) ||
    !isValidIndex(dropElementsIndex, newArr[dropPageIndex]?.elements || [])
  ) {
    console.error('Invalid indices')
    return newArr
  }

  const dragPageElements = newArr[dragIndex]?.elements[childIndex]
  const dropPageElements = newArr[dropPageIndex]?.elements[dropElementsIndex]

  const arrayFix = newArr.map((page, index) => {
    if (dragIndex === index || dropPageIndex === index) {
      // If the current page is either the dragged or dropped page, update its elements
      return {
        ...page,
        elements: (page.elements || []).map((element, elementIndex) => {
          // Perform your modifications on the elements
          if (elementIndex === childIndex && dragIndex === index) {
            // This is the dragged element, modify it as needed
            return dropPageElements
              ? { ...element, ...dropPageElements }
              : element
          } else if (
            elementIndex === dropElementsIndex &&
            dropPageIndex === index
          ) {
            // This is the dropped element, modify it as needed
            return dragPageElements
              ? { ...element, ...dragPageElements }
              : element
          }
          return element
        })
      }
    }
    // If it's not the dragged or dropped page, return it unchanged
    return page
  })

  return arrayFix
}

const toggleShowInRow = (elements, index, draggedChart, currentIndex) => {
  if (
    elements[index]?.showInRow !== undefined &&
    elements[index]?.pageByWidth !== '1' &&
    draggedChart.pageByWidth === elements[index]?.pageByWidth
  ) {
    const updatedElement = {
      ...elements[index],
      showInRow: elements[currentIndex].showInRow
    }

    return updatedElement
  }

  // If the condition is not met, return the original element
  return elements[index]
}

const toggleByLoop = (elements, index, draggedChart) => {
  const updatedElements = [...elements]

  for (let i = 0; i < updatedElements.length; i++) {
    if (i === index) {
      if (updatedElements[i + 1]?.showInRow !== undefined) {
        updatedElements[i + 1] = toggleShowInRow(
          updatedElements,
          index + 1,
          draggedChart,
          index
        )
      } else if (updatedElements[i - 1]?.showInRow !== undefined) {
        updatedElements[i - 1] = toggleShowInRow(
          updatedElements,
          index - 1,
          draggedChart,
          index
        )
      }
    }
  }

  return updatedElements
}

const handleDragChange = (state, action) => {
  const {
    chartIndex,
    dragIndex,
    dropIndex,
    onPage,
    pageIndex,
    dropPageIndex,
    dropElementsIndex
  } = action.payload
  // Ensure dropIndex and dropPageElements are defined
  if (dropIndex === undefined || !state.pages[dropIndex]?.elements) {
    const isHeading =
      isPageHeading(state.pages[dragIndex]?.elements[chartIndex]) ||
      isPageHeading(state.pages[pageIndex]?.elements[dropIndex])
    const isFooter =
      isPageFooter(state.pages[dropIndex]?.elements[chartIndex]) ||
      isPageFooter(state.pages[pageIndex]?.elements[dropIndex])
    const isHeaderFooterDrag = isHeading || isFooter
    if (isHeaderFooterDrag) {
      return state
    }
  }

  let updatedPages = [...state.pages]
  const dragPageElements = updatedPages[dragIndex]?.elements || null
  const dropPageElements = updatedPages[dropIndex]?.elements || null

  if (!onPage) {
    // Swap elements between dragIndex and dropIndex within the same page
    if (dragIndex === dropPageIndex) {
      swapElements(dragPageElements, chartIndex, dropIndex)
    } else {
      updatedPages = byPageSwapElements(
        updatedPages,
        'elements',
        chartIndex,
        dropIndex,
        dragIndex,
        dropPageIndex,
        dropElementsIndex
      )
    }
  } else {
    // Move charts from dragIndex page to dropIndex page
    const [draggedChart] = dragPageElements.splice(chartIndex, 1)
    if (draggedChart.pageByWidth !== '1') {
      draggedChart.showInRow = draggedChart.showInRow ? false : 'left'
    }
    dropPageElements.push(draggedChart)
    // dropPageElements = dropPageElements.map((element, index) => {
    //   if (element.pageByWidth !== '1') {
    //     return {
    //       ...element,
    //       showInRow: calculateShowIndex(dropPageElements, index, element)
    //     }
    //   } else {
    //     return element
    //   }
    // })

    if (draggedChart.pageByWidth !== '1') {
      updatedPages[dropIndex].elements = toggleByLoop(
        dropPageElements,
        chartIndex,
        draggedChart
      )
    }
  }

  return { ...state, pages: updatedPages }
}

const handleEditCharts = (state, action) => {
  const { pageIndex, updatedChart } = action.payload

  const newPages = state.pages.map((page, i) => ({
    ...page,
    elements:
      i === pageIndex
        ? page.elements.map((object) =>
          object === action.payload
            ? { ...object, isActive: updatedChart.isActive }
            : object
        )
        : page.elements
  }))

  return { ...state, pages: newPages, clickEvent: state.clickEvent + 1 }
}
const handleDeleteChart = (state, action) => {
  const { activeChart } = action?.payload
  // check if active element is header or footer
  const isActiveChartHeaderOrFooter = state.activeElement === 'headingElement' || state.activeElement === 'footerElement'
  if (isActiveChartHeaderOrFooter) {
    return {
      ...state,
      pageHeadingFooter: {
        ...state.pageHeadingFooter,
        headingElement: state.activeElement === 'headingElement' ? null : state.pageHeadingFooter.headingElement,
        footerElement: state.activeElement === 'footerElement' ? null : state.pageHeadingFooter.footerElement
      }
    }
  }

  let newPages = state?.pages?.map((page, pageIndex) => {
    // find page which has active element and filter it.
    if (pageIndex === activeChart?.pageIndex) {
      return {
        ...page,
        ...(activeChart?.canOverflow) ? { overflowId: null } : {},
        elements: page?.elements.filter((object) => object.id !== activeChart?.id)
      }
    }
    return page
  })
  if (activeChart?.canOverflow) {
    newPages.forEach(page => {
      // remove elements from all page which has same overflowID as current active element 
      if (page?.elements?.some(element => element?.overflowElementId === activeChart?.overflowElementId)) {
        page.overflowId = null
        page.elements = page?.elements?.filter(element => element?.overflowElementId !== activeChart?.overflowElementId)
        // if no element is left in page after removing overflow element, add remove flag to delete the page
        if (!page?.elements?.length) {
          page.remove = true
        }
      }
    })
    // filter pages which does not have any elements
    newPages = newPages.filter(page => !page?.remove)
  }

  newPages.forEach((page, pageIndex) => {
    page.elements = page?.elements?.map((element, chartIndex) => ({
      ...element,
      pageIndex,
      chartIndex
    }))
  })

  return {
    ...state,
    pages: newPages,
    pageHeadingFooter: { ...state.pageHeadingFooter },
    clickEvent: state.clickEvent + 1
  }
}
const handleEditLabelChange = (state, action) => ({
  ...state,
  pages: state.pages.map((page) => ({
    ...page,
    elements: page.elements.map((object) =>
      object.id === state.activeElement ? action.payload : object
    )
  })),
  clickEvent: state.clickEvent + 1
})

const handleSetReport = (state, { payload: { pages, pageHeadingFooter } }) => {
  const newCustomTableOverflowIds = []
  pages.forEach(page => {
    page.elements.forEach(element => {
      if (element?.canOverflow && element?.value?.showOverflow) {
        newCustomTableOverflowIds.push(element?.id)
      }
    })
  })

  // if template is saved with only header and footer element, create empty page 
  if (!pages?.length && (pageHeadingFooter?.headingElement || pageHeadingFooter?.footerElement)) {
    return {
      ...state,
      pages: [{ id: randomId(), elements: [] }],
      pageHeadingFooter,
      customTableOverflowElementsQueue: newCustomTableOverflowIds
    }
  }

  return {
    ...state,
    pages,
    pageHeadingFooter,
    customTableOverflowElementsQueue: newCustomTableOverflowIds
  }
}

const handleIsActive = (state, action) => {
  return { ...state, activeElement: null }
}

const handleFontSizeChange = (state, action) => ({
  ...state,
  pages: state.pages.map((page) => ({
    ...page,
    elements: page.elements.map((object) =>
      object.id === state.activeElement ? action.payload : object
    )
  }))
})

const handleChangeUpdates = (state, action) => ({
  ...state,
  pages: state.pages.map((page) => ({
    ...page,
    elements: page.elements.map((object) =>
      object.id === state.activeElement ? action.payload : object
    )
  }))
})

const handlePageActive = (state, action) => {
  const { index } = action.payload
  if (index !== 'unActive') {
    // const updatedPages = state.pages.map((page, pageIndex) => ({
    //   ...page,
    //   pageActive: index === action.payload,
    //   elements: page.elements.map((object, chartIndex) => ({
    //     ...object,
    //     isActive: false
    //   }))
    // }))
    const updatedPages = state.pages
    updatedPages.forEach((page, pageIndex) => {
      if (index === action.payload) {
        page.pageActive = true
      }
      page.elements.forEach((object, chartIndex) => {
        if (object.isActive) {
          object.isActive = false
        }
      })
    })
    return {
      ...state,
      pages: updatedPages,
      zoomLevel: 100,
      clickEvent: state.clickEvent + 1
    }
  } else {
    const updatedPages = state.pages.map((page, pageIndex) => ({
      ...page,
      pageActive: false,
      elements: page.elements.map((object, chartIndex) => ({
        ...object,
        isActive: false
      }))
    }))
    return {
      ...state,
      pages: updatedPages,
      zoomLevel: 100,
      clickEvent: state.clickEvent + 1
    }
  }
}
const handleDeletePage = (state, action) => ({
  ...state,
  pages: state?.pages?.filter((_, index) => index !== action?.payload)?.map((page, pageIndex) => ({
    ...page,
    elements: page?.elements?.map((object) => ({
      ...object,
      pageIndex
    }))
  })),
})

const handleAddMidPage = (state, action) => {
  const { pages } = state
  const { payload } = action
  const modifiedPages = [...pages]

  // const headingList = ['Page Heading 1', 'Page Heading 2']
  // const footerList = ['Page Footer 1', 'Page Footer 2', 'Page Footer 3']

  // const getFilteredCharts = (pageIndex, index) => {
  //   const currentPage = modifiedPages[pageIndex]
  //   const filteredCharts = currentPage.elements.slice(index)
  //   modifiedPages[pageIndex].elements.splice(index, 1)
  //   return filteredCharts
  // }

  // const getChartArray = () => {
  //   const lastPage = modifiedPages[modifiedPages.length - 1]
  //   return lastPage
  //     ? lastPage.elements.filter(
  //       (obj) =>
  //         (pageHeadingFooter.heading && headingList.includes(obj.name)) ||
  //         (pageHeadingFooter.footer && footerList.includes(obj.name))
  //     )
  //     : []
  // }

  // const insertNewPage = (pageIndex, elements) => {
  //   modifiedPages.splice(pageIndex + 1, 0, {
  //     id: randomId(),
  //     elements,
  //     pageActive: false
  //   })
  // }

  // if (payload.isOverflow) {
  //   const { pageIndex, index } = payload
  //   const filteredCharts = getFilteredCharts(pageIndex, index)
  //   const chartArray = getChartArray()
  //   insertNewPage(pageIndex, [...chartArray, ...filteredCharts])
  // } else {
  //   const chartArray = modifiedPages.reduce((acc, page) => {
  //     const filteredElements = page.elements.find(
  //       (obj) =>
  //         (pageHeadingFooter.heading && headingList.includes(obj.name)) ||
  //         (pageHeadingFooter.footer && footerList.includes(obj.name))
  //     )
  //     const elements = page.elements.find(
  //       (obj) => pageHeadingFooter.footer && footerList.includes(obj.name)
  //     )

  //     if (pageHeadingFooter.heading && pageHeadingFooter.footer) {
  //       if (filteredElements && (acc.length === 0 || acc.length === 1)) {
  //         acc.push(filteredElements, elements)
  //       }
  //     } else {
  //       if (filteredElements && acc.length === 0) {
  //         acc.push(filteredElements)
  //       }
  //     }

  //     return acc
  //   }, [])

  modifiedPages.splice(payload, 0, {
    id: randomId(),
    elements: [],
    pageActive: false
  })
  // }

  return {
    ...state,
    pages: modifiedPages?.map((page, pageIndex) => ({
      ...page,
      elements: page?.elements?.map((object) => ({
        ...object,
        pageIndex
      }))
    })),
    zoomLevel: 100,
    clickEvent: state.clickEvent + 1
  }
}

const handleZoomPage = (state, action) => {
  const { zoomType } = action.payload
  const currentZoomLevel = state.zoomLevel

  if (!state.pages.some((page) => page.pageActive)) {
    // No active page, return the current state
    return state
  }

  let newZoomLevel

  switch (zoomType) {
    case 'in':
      newZoomLevel = currentZoomLevel + 10
      break
    case 'out':
      newZoomLevel =
        currentZoomLevel > 10 ? currentZoomLevel - 10 : currentZoomLevel
      break
    case 'reset':
      newZoomLevel = 100
      break
    default:
      newZoomLevel = currentZoomLevel
  }

  return { ...state, zoomLevel: newZoomLevel }
}
const addCoverResponse = (state, action) => {
  const { chartIndex, value, pageIndex } = action.payload

  const updatedPages = state.pages.map((page, i) => ({
    ...page,
    elements:
      i === pageIndex
        ? page.elements.map((object, index) => ({
          ...object,
          value: index === chartIndex ? { value } : object.value
        }))
        : page.elements
  }))

  return {
    ...state,
    pages: updatedPages
  }
}
const handleEditorNameChange = (state, action) => ({
  ...state,
  editorName: action.payload
})

const changePreviousEditorName = (state, action) => ({
  ...state,
  previousEditorName: action.payload.editorName,
  previousReportIdentifier: action.payload.identifier
})

const handleChartNameChange = (state, action) => {
  const { label, activeChart } = action.payload
  let updatedPages = state.pages;
  if (activeChart?.canOverflow && activeChart?.value?.showOverflow) {
    const originalTableId = activeChart?.overflowElementId
    updatedPages = state.pages.map((page) => ({
      ...page,
      elements: page?.elements?.map((object) => object?.overflowElementId === originalTableId ? { ...object, headingText: label } : object)
    }))
  } else {
    updatedPages = state.pages.map((page) => ({
      ...page,
      elements: page?.elements?.map((object) => object?.id === state.activeElement ? { ...object, headingText: label } : object)
    }))
  }

  return { ...state, pages: updatedPages, clickEvent: state.clickEvent + 1 }
}

const handleChartDataAdd = (state, action) => {
  const { chartIndex, pageIndex, clearTableRows, ...rest } = action.payload
  let updatedPages = state.pages.map((page, i) => ({
    ...page,
    elements:
      i === pageIndex
        ? page.elements.map((object, index) => ({
          ...object,
          value: index === chartIndex ? { ...object.value, ...rest } : object.value
        }))
        : page.elements
  }))
  if (rest?.showOverflow === false) {
    const newPages = []
    updatedPages.forEach((page, i) => {
      const hasAnyElementWithOverflowId = page.elements.some(element => element?.overflowElementId === state.activeElement && element?.id !== state.activeElement)
      if (hasAnyElementWithOverflowId) {
        const newElements = page.elements.filter(element => element?.overflowElementId !== state.activeElement)
        if (newElements.length) {
          newPages.push({
            ...page,
            overflowId: undefined,
            elements: newElements
          })
          return
        } else {
          return
        }
      }
      newPages.push(page)
    })
    updatedPages = [...newPages]
    updatedPages = updatedPages.map((page, pageIndex) => ({
      ...page,
      elements: page.elements.map((element, chartIndex) => ({ ...element, pageIndex, chartIndex }))
    }
    ))
  }
  else if (rest?.showOverflow === true) {
    updatedPages[pageIndex].elements[chartIndex].lastIndex = null
  }

  const modifiedElementObject = updatedPages[pageIndex] && updatedPages[pageIndex].elements && updatedPages[pageIndex]?.elements[chartIndex]

  if (clearTableRows && rest?.dataSource) {
    if (updatedPages[pageIndex] && updatedPages[pageIndex].elements && updatedPages[pageIndex]?.elements[chartIndex] && updatedPages[pageIndex]?.elements[chartIndex]?.canOverflow) {
      updatedPages[pageIndex].elements[chartIndex].lastIndex = null
      // check if there is any element which has same overflow id as current modified element.
      if (modifiedElementObject?.canOverflow
        && updatedPages?.filter(page => page?.elements?.some(element => element?.overflowElementId === modifiedElementObject?.overflowElementId && element?.id !== modifiedElementObject?.overflowElementId))?.length) {
        updatedPages.forEach(page => {
          // keep first element which has caused overflow and store all dataset changes in first element
          if (page?.elements?.find(element => element?.id === modifiedElementObject?.overflowElementId)) {
            page.elements = page?.elements?.map(element => {
              if (element?.id === modifiedElementObject?.overflowElementId) {
                return {
                  ...element,
                  lastIndex: null,
                  value: { ...element.value, ...rest }
                }
              }
              return element
            })
          }
          // remove elements from all page which has same overflowID as current active element
          else if (page?.elements?.some(element => element?.overflowElementId === modifiedElementObject?.overflowElementId)) {
            page.overflowId = null
            page.elements = page?.elements?.filter(element => element?.overflowElementId !== modifiedElementObject?.overflowElementId)
            // if no element is left in page after removing overflow element, add remove flag to delete the page
            if (!page?.elements?.length) {
              page.remove = true
            }
          }
        })
        // filter pages which does not have any elements
        updatedPages = updatedPages.filter(page => !page?.remove)
      }
    }

    state.customTableRows = {
      ...state.customTableRows,
      [`${rest.dataSource}-${modifiedElementObject?.overflowElementId}`]: []
    }
  }
  else if (rest?.removeContent && rest?.dataSource) {
    // remove all rows from the table rows
    state.customTableRows = {
      ...state.customTableRows,
      [`${rest?.dataSource}-${modifiedElementObject?.overflowElementId}`]: []
    }
    // add payload in all of the current component's pages
    if (modifiedElementObject?.canOverflow
      && updatedPages?.filter(page => page?.elements?.some(element => element?.overflowElementId === modifiedElementObject?.overflowElementId))?.length) {
      updatedPages.forEach(page => {
        // find elements from all page which has same overflowID as current active element 
        if (page?.elements?.some(element => element?.overflowElementId === modifiedElementObject?.overflowElementId)) {
          page.elements = page?.elements?.map(element => {
            // add dataset changes in all table which has same overflow id as current element overflow id
            if (element?.overflowElementId === modifiedElementObject?.overflowElementId) {
              return {
                ...element,
                value: { ...element.value, ...rest }
              }
            }
            return element
          })
        }
      })
    }
  }
  else {
    // check if there is any element which has same overflow id as current modified element.
    if (modifiedElementObject?.canOverflow
      && updatedPages?.filter(page => page?.elements?.some(element => element?.overflowElementId === modifiedElementObject?.overflowElementId))?.length) {
      updatedPages.forEach(page => {
        // find elements from all page which has same overflowID as current active element 
        if (page?.elements?.some(element => element?.overflowElementId === modifiedElementObject?.overflowElementId)) {
          page.elements = page?.elements?.map(element => {
            // add dataset changes in all custom table which has same overflow id as current element overflow id
            if (element?.overflowElementId === modifiedElementObject?.overflowElementId) {
              return {
                ...element,
                value: { ...element.value, ...rest }
              }
            }
            return element
          })
        }
      })
    }
  }

  return {
    ...state,
    pages: updatedPages
  }
}

const handleReorderLabels = (state, action) => {
  const updatedPages = state.pages.map((page) => ({
    ...page,
    elements: page.elements.map((object) =>
      object.id === state.activeElement
        ? { ...object, value: { ...object.value, datasets: action.payload } }
        : object
    )
  }))

  return { ...state, pages: updatedPages }
}
const handleCoverLabels = (state, action) => {
  const updatedPages = state.pages.map((page) => ({
    ...page,
    elements: page.elements.map((object) =>
      object.id === state.activeElement
        ? {
          ...object,
          value: {
            ...object.value,
            [action.payload.label]: action.payload.value
          }
        }
        : object
    )
  }))
  return { ...state, pages: updatedPages }
}
const getValue = (action, label, defaultValue, values) => {
  if (action.payload.logo === 'no change' && label === 'companyLogoUrl') {
    return (
      values[label] ||
      (action.payload.label === label ? action.payload.value : defaultValue)
    )
  } else {
    return (
      action.payload[label] ||
      (action.payload.label === label ? action.payload.value : defaultValue)
    )
  }
}
const handlePageHeadingFooter = (state, action) => {
  const coverPageList = [
    'Cover page 1',
    'Cover page 2',
    'Cover page 3'
    // 'PDF Uploads'
  ]
  const values = state.pageHeadingFooter.value
  const updatedPages = state.pages.map((page, pageIndex) => ({
    ...(pageIndex === 0
      ? {
        ...page,
        elements: page.elements.map((object, chartIndex) =>
          !coverPageList.includes(object.name)
            ? object
            : {
              ...object,
              templateType: state?.templateName
                ? (state?.templateName?.name || state?.templateName).toUpperCase()
                : 'REPORT NAME',
              value: {
                ...values,
                templateType: state?.templateName
                  ? (state?.templateName?.name || state?.templateName).toUpperCase()
                  : 'REPORT NAME',
                companyLogoUrl: getValue(
                  action,
                  'companyLogoUrl',
                  '',
                  values
                ),
                accountCd: getValue(action, 'accountCd', 'ARISA000'),
                accountName: getValue(
                  action,
                  'accountName',
                  'Sarah Davis TM'
                ),
                companyName: getValue(action, 'sponsorName', 'ARIS Investing LLC'),
                textFooter:
                  'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
              }
            }
        )
      }
      : page)
  }))

  const updatedPageHeadingFooterValue = {
    templateType: state.templateName?.name || 'Report Name',
    accountName: getValue(action, 'accountName', 'Sarah Davis TM'),
    accountCd: getValue(action, 'accountCd', 'ARISA000'),
    companyName: getValue(action, 'sponsorName', 'Report Name'),
    textFooter:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
  }

  return action?.payload?.allUpdate
    ? {
      ...state,
      pages: updatedPages,
      pageHeadingFooter: {
        ...state.pageHeadingFooter,

        value: {
          ...state.pageHeadingFooter.value,
          ...updatedPageHeadingFooterValue
        }
      }
    }
    : {
      ...state,
      pages: updatedPages,
      pageHeadingFooter: {
        ...state.pageHeadingFooter,
        value: {
          ...state.pageHeadingFooter.value,
          [action.payload.label]: action.payload.value
        }
      }
    }
}

const handleUpdateByKey = (state, action) => {
  const { key, value } = action.payload

  const updatedPages = state.pages.map((page, pageIndex) => ({
    ...page,
    elements: page.elements.map((object, chartIndex) =>
      object.id === state.activeElement ? { ...object, [key]: value } : { ...object }
    )
  }))

  return {
    ...state,
    pages: updatedPages,
    zoomLevel: 100,
    clickEvent: state.clickEvent + 1
  }
}

const handleSvgImageData = (state, action) => {
  const { pageIdx, chartIdx, imageUrl, value, name } = action.payload
  const updatedHeaderFooter = (name?.includes('Cover page') || name?.includes('Page Heading') || name?.includes('Page Footer'))
    ? { ...state?.pageHeadingFooter, value: { ...state?.pageHeadingFooter?.value, svgCode: value } }
    : {
      ...state?.pageHeadingFooter
    }

  const updatedPages = state.pages.map((page, pageIndex) => {
    if (pageIndex === pageIdx || ((name?.includes('Cover page') || name?.includes('Page Heading') || name?.includes('Page Footer')))) {
      return {
        ...page,
        elements: page.elements.map((object, chartIndex) =>
          ((object.chartIndex === chartIdx && object.name === name) || ((name?.includes('Cover page') || object?.name?.includes('Page Heading') || object?.name?.includes('Page Footer')) && ((object?.name?.includes('Cover page') || name?.includes('Page Heading') || name?.includes('Page Footer'))))) ? { ...object, value: { ...object.value, svgCode: value } } : { ...object }
        )
      }
    } else return { ...page }
  })

  return {
    ...state,
    pageHeadingFooter: updatedHeaderFooter,
    pages: updatedPages
  }
}

const reSetData = () => {
  return {
    // isLoading: false,
    clickEvent: 1,
    zoomLevel: 100,
    editorName: 'Report 1',
    templateName: '',
    pageHeadingFooter: { heading: false, footer: false, value: '', headingElement: null, footerElement: null },
    pages: [{ id: randomId(), elements: [], pageActive: false }],
    customTableRows: {},
    customTableRowHeight: 13,
    activeElement: null,
    overlappingElementsWithTable: []
  }
}

export const changeCustomTableOverflowId = (state, action) => {
  const { pageIndex, overflowId } = action.payload
  const updatedPages = state?.pages?.map((page, index) => {
    if (index === pageIndex) {
      return { ...page, overflowId }
    }
    else {
      return page
    }
  })
  return { ...state, pages: updatedPages }
}

const handleQueueActionChange = (state, action) => {
  const { queueAction, item } = action.payload
  switch (queueAction) {
    case 'push':
      return {
        ...state, customTableOverflowElementsQueue: [...state.customTableOverflowElementsQueue, item]
      }
    case 'pop':
      return { ...state, customTableOverflowElementsQueue: state.customTableOverflowElementsQueue?.slice(1) }
    case 'clear':
      return { ...state, customTableOverflowElementsQueue: [] }
    default:
      return state
  }
}

export default chartDropReducer
