import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import * as Sentry from '@sentry/react'
import { useNavigate } from 'react-router-dom'
import { Close, Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Box, Button, Grid, IconButton, Modal, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ErrorMessage, Field, Formik } from 'formik'
import propTypes from 'prop-types'
import * as Yup from 'yup'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { useSuccessToast } from '../../../hooks/useSuccessToast'
import StyledTradePopupBox from '../components/StyledTradePopupBox'
import Loader from '../../Loader'
import CustomActionButton from './CustomActionButton'
import './mandatory-trades.css'
import { ACCESS_LEVEL } from '../../../contstants/constants'
import { checkInstrumentSearchQuery } from '../../../utils/searchQueryUtils'

dayjs.extend(utc)

const corporateModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  px: 2,
  py: 2,
  width: '100%',
  minWidth: '600px',
  maxWidth: '900px',
  borderRadius: '4px',
  '.MuiDataGrid-root': {
    border: 'none'
  },
  '.MuiDataGrid-main': {
    // remove overflow hidden overwise sticky does not work
    overflow: 'unset'
  },
  '.MuiDataGrid-columnHeaders': {
    position: 'sticky',
    top: '-4px',
    zIndex: 99,
    background: 'white'
  },
  '.MuiDataGrid-virtualScroller': {
    // remove the space left for the header
    marginTop: '-4px!important'
  }
}

const renderTableSkeleton = (header, hiddenColumns) => {
  if (hiddenColumns) { header = header.filter(col => !hiddenColumns.includes(col.field)) }
  return (
    <TableContainer mt={5}>
      <Table className='risk-page-table'>
        <TableHead>
          <TableRow>
            {header.map((item, index) => {
              return (
                <TableCell key={index}>{item.headerName}</TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: 5 }).map((_, index) => (
            <TableRow key={index}>
              {Array.from({ length: header.length }).map((_, index) => (
                <TableCell key={index}>
                  <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                </TableCell>))}
            </TableRow>))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const renderDataGridPro = (rowData, cols, getRowId, columnVisibility, sortModel, extraProps, styleProps) => {
  return (
    <DataGridPro
      density='compact'
      autoHeight
      rows={rowData || []}
      columns={cols}
      disableRowSelectionOnClick
      getRowId={getRowId}
      pagination
      pageSizeOptions={[20]}
      initialState={{
        ...rowData?.initialState,
        pagination: { paginationModel: { pageSize: 20 } },
        sorting: {
          sortModel: sortModel || []
        },
        columns: {
          columnVisibilityModel:
            columnVisibility || {}
        }
      }}
      {...extraProps}
      sx={(theme) => ({
        ...styleProps,
        '& .MuiDataGrid-cell:focus': {
          outline: 'none'
        },
        [`.${gridClasses.main}`]: {
          overflow: 'unset'
        },
        [`.${gridClasses.columnHeaders}`]: {
          position: 'sticky',
          backgroundColor: theme.palette.background.paper,
          top: 0,
          zIndex: 1
        },
        [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
          color: '#74788d',
          fontWeight: 600
        },
        [`.${gridClasses.footerContainer}`]: {
          position: 'sticky',
          bottom: '-1px',
          backgroundColor: theme.palette.background.paper,
          zIndex: 1
        }
      })}
    />
  )
}

const DelistingTable = ({ rows, getUpdatedRows, fetchDelistActions }) => {
  const { user, checkAccess } = useAuth()
  const { showError } = useErrorToast()
  const { showSuccess } = useSuccessToast()
  const [isModalDataLoading, setIsModalDataLoading] = useState(false)
  const [whichModal, setWhichModal] = useState({ name: '', open: false })
  const [modalData, setModalData] = useState([])
  const [selectedRow, setSelectedRow] = useState(null)
  // maintaining one state for loading and data for refetching api
  const [delistData, setDelistData] = useState({ loading: false, data: [] })
  const [selectedAccountPopup, setSelectedAccountPopup] = useState('')
  const [showLoader, setShowLoader] = useState(false)
  const [rowSelectionModel, setRowSelectionModel] = useState([])
  const navigate = useNavigate()

  const delistTableColumns = [
    { field: 'instrId', headerName: 'Instrument Id', flex: 1 },
    { field: 'localSymbol', headerName: 'Local Symbol', flex: 1 },
    { field: 'instrName', headerName: 'Instrument Name', flex: 2 },
    {
      field: 'status',
      headerName: 'DNH Applied',
      flex: 1,
      renderCell: (params) => {
        const data = params?.row?.status
        return <><Box sx={{ borderRadius: '20px', fontSize: '10px', px: '10px', py: '2px', ...getColorProperties(data), fontWeight: 600 }}>{data}</Box></>
      }
    },
    {
      field: 'pendingAccounts',
      headerName: 'Pre Trade Accounts',
      width: 200,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params) => {
        const data = params?.row?.pendingAccounts?.length
        return (
          <Box onClick={(e) => data > 0 ? handleDelistCountClick(e, params?.row, 'Pre Trade Accounts') : ''}>
            <Typography sx={{
              color: '#0066C7',
              fontWeight: 600,
              fontFamily: 'Open Sans',
              cursor: data > 0 ? 'pointer' : 'not-allowed'
            }}
            >
              {data}
            </Typography>
          </Box>
        )
      }
    },
    {
      field: 'proposedAccounts',
      headerName: 'Proposed Accounts',
      flex: 1,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params) => {
        const data = params?.row?.proposedAccounts?.length
        return (
          <Box onClick={(e) => data > 0 ? handleDelistCountClick(e, params?.row, 'Proposed Accounts') : ''}>
            <Typography sx={{
              color: '#0066C7',
              fontWeight: 600,
              fontFamily: 'Open Sans',
              cursor: data > 0 ? 'pointer' : 'not-allowed'
            }}
            >
              {data}
            </Typography>
          </Box>
        )
      }
    },
    {
      field: 'models',
      headerName: 'Models',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      renderCell: (params) => {
        const data = params?.row?.models?.length
          ? params?.row?.models?.reduce((acc, data) => {
            if (data?.actionCompleted) {
              acc.applied += 1
            }
            acc.total += 1
            return acc
          }, { applied: 0, total: 0 })
          : null

        return (
          <Box onClick={(e) => params?.row?.models?.length ? handleDelistCountClick(e, params?.row, 'Models') : ''}>
            <Typography sx={{
              color: '#0066C7',
              fontWeight: 600,
              fontFamily: 'Open Sans',
              cursor: params?.row?.models?.length > 0 ? 'pointer' : 'not-allowed'
            }}
            >
              {data ? `${data.applied} / ${data.total}` : 0}
            </Typography>
          </Box>
        )
      }
    },
    { field: 'effectiveDate', headerName: 'Effective Date', headerAlign: 'right', align: 'right', flex: 1, valueFormatter: (params) => params?.value ? params.value.split('T')[0] : '' },
    {
      field: 'action',
      headerName: 'Action',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        params.row ? <CustomActionButton params={params} handleShowModal={handleShowModal} name='delist' /> : ''
      )
    }
  ]

  const [accountListModalColumns] = useState([
    { field: 'accountId', headerName: 'Account Id', flex: 1 },
    { field: 'accountName', headerName: 'Account Name', flex: 1 },
    { field: 'accountCd', headerName: 'Account Code', flex: 1 }
  ])

  const [strategyListModalColumns] = useState([
    { field: 'strategyId', headerName: 'Strategy Id', flex: 1 },
    { field: 'strategyName', headerName: 'Strategy Name', flex: 1 },
    { field: 'strategyCd', headerName: 'Strategy Code', flex: 1 },
    {
      field: 'actionCompleted',
      headerName: 'Applied',
      flex: 1,
      renderCell: (params) => params?.row?.actionCompleted === false ? 'Pending' : 'Completed'
    }
  ])

  useEffect(() => {
    setDelistData({
      loading: rows?.loading,
      data: !rows?.loading && rows?.data ? rows?.data?.delist?.map(obj => ({ ...obj, id: randomId() })) : []
    })
  }, [rows])

  const handleDelistCountClick = (e, row, column) => {
    // set row to get instrument id
    setSelectedRow(row)
    setWhichModal({ name: 'Accounts', open: true })
    if (column === 'Pre Trade Accounts') {
      setSelectedAccountPopup('Pre Trade Accounts')
      setModalData(row?.pendingAccounts?.map(obj => ({ ...obj, id: randomId() })))
    } else if (column === 'Proposed Accounts') {
      setSelectedAccountPopup('Proposed Accounts')
      setModalData(row?.proposedAccounts?.map(obj => ({ ...obj, id: randomId() })))
    } else if (column === 'Models') {
      setWhichModal({ name: 'Models', open: true })
      setModalData(row?.models?.map(obj => ({ ...obj, id: randomId() })))
    }
  }

  const getColorProperties = (data) => {
    if (data === 'APPLIED') {
      return {
        color: '#446b60',
        backgroundColor: '#3BBFA399',
        border: '1px solid #446b6022'
      }
    } else if (data === 'PENDING') {
      return {
        color: '#aaab29',
        backgroundColor: '#fbfa6c99',
        border: '1px solid #aaab2922'
      }
    } else if (data === 'PARTIALLY COMPLETED') {
      return {
        color: '#816f36',
        backgroundColor: '#d2992299',
        border: '1px solid #816f3622'
      }
    } else if (data === 'NOT APPLICABLE') {
      return {
        color: '#969696',
        backgroundColor: '#C0C0C099',
        border: '1px solid #96969622'
      }
    } else {
      return {
        color: '#969696',
        backgroundColor: '#C0C0C099',
        border: '1px solid #96969622'
      }
    }
  }

  const fetchAccountsAndStrategiesList = async (modal, row) => {
    setIsModalDataLoading(true)
    API.post(
      'baseUriCorporate',
      `corporate-action/v1/${user?.userGroup}/accounts/strategies/list`,
      {
        ...(user?.userGroup === 'adv-classic'
          ? {
              queryStringParameters: {
                resources: encodeURIComponent(JSON.stringify({ serviceId: 'trade', resourceId: 'mandatory-trades' }))
              }
            }
          : {}),
        body: {
          instrId: [row.instrId],
          actionType: 'DELIST'
        }
      }
    )
      .then((response) => {
        if (response.success) {
          if (modal === 'Accounts') {
            setModalData(response?.data?.map(obj => obj.accounts).flat())
          }
        }
      })
      .catch(error => {
        showError(error?.response?.data?.errorInfo?.userMessage || error?.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setIsModalDataLoading(false)
      })
  }

  // removeCurrentInstrument: boolean var (true for remove DNH, false for delete delist)
  const updateDelist = async (row, removeCurrentInstrument = false) => {
    setShowLoader(true)
    API.patch(
      'baseUriCorporate',
      `corporate-action/v1/${user.userGroup}/instruments/delist-cal/${row.instrId}/${row.startDate.split('T')[0]}`,
      {
        queryStringParameters: {
          deleteRestriction: removeCurrentInstrument,
          dnhStatus: row?.status
        },
        ...(removeCurrentInstrument && {
          body: {
            accountIds: row?.currentAccounts
          }
        })
      }
    )
      .then(response => {
        if (response.success) {
          showSuccess(response.message)
          // remove the row from table only when remove delist is clicked
          if (!removeCurrentInstrument) {
            // there can be multiple instrument with same name, so use randomId to filter the row
            getUpdatedRows({ loading: false, data: { delist: delistData?.data?.filter(obj => row?.id !== obj?.id) } })
          } else { fetchDelistActions() }
          setShowLoader(false)
        }
      })
      .catch(error => {
        setShowLoader(false)
        showError(error?.response?.data?.errorInfo?.userMessage || error?.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const applyDNHRestrictions = async (row) => {
    API.post(
      'baseUriCorporate',
      `corporate-action/v1/${user?.userGroup}/model-portfolios/delist-restrictions`,
      {
        body: [{
          instrId: row?.instrId,
          strategyIds: Array.isArray(row?.models) ? row?.models?.filter(model => !model.actionCompleted).map(model => model?.strategyId) : []
        }]
      }
    )
      .then((response) => {
        if (response.success) {
          // restriction applied
          showSuccess(response.message)
          // fetch delist again so user doesn't have to press refresh icon
          fetchDelistActions()
        }
      })
      .catch(error => {
        showError(error?.response?.data?.errorInfo?.userMessage || error?.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setShowLoader(false)
      })
  }

  const runOptimizationForAccounts = async () => {
    setShowLoader(true)
    // get all accountIds for each id in the row selection model
    const accountIds = new Set()
    for (let i = 0; i < delistData.data.length; i++) {
      if (rowSelectionModel.includes(delistData.data[i].id)) {
        if (Array.isArray(delistData.data[i].proposedAccounts) && delistData.data[i].proposedAccounts.length > 0) {
          delistData.data[i].proposedAccounts?.map(accounts => accounts.accountId).forEach(id => {
            accountIds.add(id)
          })
        }
        if (Array.isArray(delistData.data[i].currentAccounts) && delistData.data[i].currentAccounts.length > 0) {
          delistData.data[i].currentAccounts?.map(accounts => accounts.accountId).forEach(id => {
            accountIds.add(id)
          })
        }
      }
    }

    API.post(
      'baseOptimizationURL',
      `optimization/v1/${user?.userGroup}/run-optimization`,
      {
        body: {
          accountIds: Array.from(accountIds)
        }
      }
    )
      .then((response) => {
        if (response?.success) {
          showSuccess(response?.message)
        }
      })
      .catch(error => {
        showError(error?.response?.data?.errorInfo?.userMessage || error?.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setRowSelectionModel([])
        setShowLoader(false)
      })
  }

  const handleViewTradeClick = () => {
    navigate('/trade/trade-approvals', { state: { tradeData: modalData.map(obj => obj.accountCd), filterName: `Delist: ${selectedRow.instrId}` } })
  }

  const handleShowModal = (modal, row) => {
    switch (modal) {
      case 'Accounts':
        if (user) {
          setSelectedRow(row)
          fetchAccountsAndStrategiesList(modal, row)
          setSelectedAccountPopup('Accounts')
          setWhichModal({ name: 'Accounts', open: true })
        }
        break
      case 'Apply DNH':
        if (user) {
          setShowLoader(true)
          setSelectedRow(row)
          applyDNHRestrictions(row)
        }
        break
      case 'Delete Delist':
        if (user) {
          setSelectedRow(row)
          updateDelist(row)
        }
        break
      case 'Remove DNH':
        if (user) {
          setSelectedRow(row)
          updateDelist(row, true)
        }
        break
      case 'Add Record':
        setWhichModal({ name: 'Add Record', open: true })
        break
      default:
        setWhichModal({ name: '', open: false })
    }
  }

  const renderModal = () => {
    // render which modal to show
    switch (whichModal.name) {
      case 'Accounts':
        return (
          <Modal
            onClose={() => setWhichModal({ name: '', open: false })}
            open={whichModal.open}
          >
            <Box sx={{ ...corporateModalStyle }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h6' sx={{ ml: '5px', fontWeight: 400, color: '#34475A' }}>{selectedAccountPopup}</Typography>
                <IconButton aria-label='close' edge='end' onClick={() => setWhichModal({ name: '', open: false })}>
                  <Close />
                </IconButton>
              </Box>
              <StyledTradePopupBox
                className='table-responsive' sx={{
                  height: '600px',
                  scrollbarGutter: 'stable'
                }}
              >
                {
                  isModalDataLoading
                    ? renderTableSkeleton(accountListModalColumns, ['accountId'])
                    : selectedAccountPopup === 'Proposed Accounts'
                      ? renderDataGridPro(modalData, accountListModalColumns, (row) => row.id, { accountId: false })
                      : selectedAccountPopup === 'Accounts'
                        ? renderDataGridPro(modalData, accountListModalColumns, (row) => row.accountId, { accountId: false })
                        : selectedAccountPopup === 'Pre Trade Accounts'
                          ? renderDataGridPro(modalData, accountListModalColumns, (row) => row.id, { accountId: false })
                          : ''
                }
              </StyledTradePopupBox>
              {
                // View Trades button will only be visible if checkAccess is true
                checkAccess('trade-approvals', ACCESS_LEVEL.MODULE_ACCESS)
                  ? (
                      selectedAccountPopup === 'Proposed Accounts' || selectedAccountPopup === 'Pre Trade Accounts'
                        ? <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', mt: '5px' }}>
                          <Button onClick={handleViewTradeClick} variant='contained' disabled={modalData.length === 0}>
                            View Trades
                          </Button>
                          </Box>
                        : ''
                    )
                  : null
              }
            </Box>
          </Modal>
        )
      case 'Models':
        return (
          <Modal
            onClose={() => setWhichModal({ name: '', open: false })}
            open={whichModal.open}
          >
            <Box sx={{ ...corporateModalStyle }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h6' sx={{ ml: '5px', fontWeight: 400, color: '#34475A' }}>Models</Typography>
                <IconButton aria-label='close' edge='end' onClick={() => setWhichModal({ name: '', open: false })}>
                  <Close />
                </IconButton>
              </Box>
              <StyledTradePopupBox
                className='table-responsive' sx={{
                  height: '600px',
                  scrollbarGutter: 'stable'
                }}
              >
                {
                  isModalDataLoading
                    ? renderTableSkeleton(strategyListModalColumns, ['strategyId'])
                    : renderDataGridPro(modalData, strategyListModalColumns, (row) => row?.strategyId, { strategyId: false })
                }
              </StyledTradePopupBox>
              {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '20px', mt: '5px' }}>
              <Button variant='text' onClick={() => setWhichModal({ name: '', open: false })} disabled={isRestrictionApplying || isModalDataLoading}>
                Cancel
              </Button>
              {isRestrictionApplying
                ? (
                  <LoadingButton
                    loading
                    loadingPosition='start'
                    startIcon={<Save />}
                    variant='outlined'
                  >
                    Apply Do not Hold
                  </LoadingButton>
                )
                : (
                  <Button
                    disabled={isModalDataLoading || selectedRow.status === 'APPLIED' || modalData.length === 0}
                    type='submit'
                    variant='contained'
                    onClick={applyDNHRestrictions}
                  >
                    Apply Do not Hold
                  </Button>
                )
              }
            </Box> */}
            </Box>
          </Modal>
        )
      case 'Add Record':
        return (
          <Modal
            onClose={() => setWhichModal({ name: '', open: false })}
            open={whichModal.open}
          >
            <Box sx={{ ...corporateModalStyle, outline: 'none', maxWidth: '600px', minWidth: '400px', height: 'auto', maxHeight: '90%', overflow: 'auto' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '5px', position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 2 }}>
                <Typography variant='h6' sx={{ ml: '5px', fontWeight: 400, color: '#34475A' }}>Add Delist</Typography>
                <IconButton aria-label='close' edge='end' onClick={() => setWhichModal({ name: '', open: false })}>
                  <Close />
                </IconButton>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <AddDelistRecord closePopup={closeAddRecordPopup} fetchDelist={fetchDelistActions} />
              </Box>
            </Box>
          </Modal>
        )
      default:
        return <></>
    }
  }

  const openAddRecordPopup = () => {
    setWhichModal({ name: 'Add Record', open: true })
  }

  const closeAddRecordPopup = () => {
    setWhichModal({ name: '', open: false })
  }

  return (
    <>
      {showLoader ? <Loader /> : ''}
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button variant='text' onClick={openAddRecordPopup}>
          + Add Record
        </Button>
        <Button
          variant='contained'
          onClick={() => runOptimizationForAccounts()}
          size='small'
          disabled={delistData.loading || (rowSelectionModel.length === 0)}
        >
          Run Optimization
        </Button>
      </Box>
      <Box sx={{
        position: 'relative',
        overflowY: 'auto',
        scrollbarWidth: 'none',
        height: 'calc(100vh - 48px - 15px - 39px - 16px - 16px - 48px - 36.5px - 24px - 20px - 10px)'
      }}
      >
        {delistData.loading
          ? renderTableSkeleton(delistTableColumns, ['localSymbol'])
          : renderDataGridPro(
            delistData.data,
            delistTableColumns,
            (row) => row?.id,
            { localSymbol: false },
            [{ field: 'effectiveDate', sort: 'desc' }],
            {
              checkboxSelection: true,
              isRowSelectable: (params) => params?.row?.isActive && params?.row?.status !== 'NOT APPLICABLE' && (params?.row?.proposedAccounts?.length > 0 || params?.row?.currentAccounts?.length > 0),
              rowSelectionModel,
              onRowSelectionModelChange: (newRowSelectionModel) => setRowSelectionModel(newRowSelectionModel)
            }
          )}
      </Box>
      {
        whichModal.open
          ? renderModal()
          : <></>
      }
    </>
  )
}

const AddDelistRecord = ({ closePopup, fetchDelist }) => {
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const { showSuccess } = useSuccessToast()
  const [instrumentIdOptions, setInstrumentIdOptions] = useState([])
  const [instrIdInput, setInstrIdInput] = useState('')
  const [isLoadingSource, setIsLoadingSource] = useState(false)

  const fetchInstrumentName = (values) => {
    if (checkInstrumentSearchQuery(values)) {
      setIsLoadingSource(true)
      API.get('baseUriTransactionalMaster', `transactional-master/v1/${user?.userGroup}/instruments`, {
        queryStringParameters: { search: values }
      })
        .then((response) => {
          if (response?.data) {
            setInstrumentIdOptions(response.data)
          }
        })
        .catch((error) => {
          showError(error?.response?.data?.errorInfo?.userMessage || error.message)
          Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
        })
        .finally(() => {
          setIsLoadingSource(false)
        })
    } else {
      setIsLoadingSource(false)
    }
  }

  useEffect(() => {
    const id = setTimeout(() => {
      fetchInstrumentName(instrIdInput.trim())
    }, [300])
    return () => clearTimeout(id)
  }, [instrIdInput])

  const handleInstrumentIdChange = (e, newValue) => {
    setInstrIdInput(newValue)
  }

  const handleFormSubmit = (values) => {
    values.setSubmitting(true)
    API.post(
      'baseInstrumentMaintainURL',
      `data-maintenance/v1/${user?.userGroup}/instruments/delist-cal`,
      {
        body: {
          instrId: values.instrId.instrId,
          localSymbol: values.instrId.localSymbol,
          startDate: values.startDate.format('YYYY-MM-DD'),
          endDate: values.endDate.format('YYYY-MM-DD'),
          effectiveDate: values.effectiveDate.format('YYYY-MM-DD')
        }
      }
    )
      .then((response) => {
        if (response.success && response?.data) {
          showSuccess(response?.message)
          closePopup()
          fetchDelist()
          values.resetForm()
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        values.setSubmitting(false)
      })
  }

  const validationSchema = Yup.object({
    instrId: Yup.object().required('Select an instrument id'),
    localSymbol: Yup.string().required(''),
    // all the dates are in dayjs format
    startDate: Yup.mixed().required('Start date is required')
      .test('valid', 'Start date must be same or later than today', value => {
        return value.isSame(dayjs().subtract(1, 'day'), 'day') || value.isAfter(dayjs().subtract(1, 'day'), 'day')
      }),
    endDate: Yup.mixed().required('End date is required')
      .when(['startDate'], ([startDate], schema) => {
        return schema.test('valid', 'End date must be later than start date', value => {
          return value.isAfter(startDate, 'day')
        })
      }),
    effectiveDate: Yup.mixed().required('Effective date is required')
      .when(['startDate', 'endDate'], ([startDate, endDate], schema) => {
        return schema.test('not weekend', 'Effective must not be weekend', value => {
          return value.day() !== 0 && value.day() !== 6
        })
          .test('valid', 'Effective must be later than start date and earlier than end date', value => {
            return value.isAfter(startDate, 'day') && value.isBefore(endDate, 'day')
          })
      })
  })

  const handleAutocompleteChange = (e, newValue, props) => {
    if (newValue) {
      props.setFieldValue('localSymbol', newValue.localSymbol)
      props.setFieldValue('instrId', newValue)
    } else {
      props.setFieldValue('localSymbol', '')
      props.setFieldValue('instrId', '')
    }
  }

  const disableWeekendsAndHolidays = (date) => {
    return date.day() === 0 || date.day() === 6
  }

  return (
    <Formik
      initialValues={{ instrId: '', localSymbol: '', startDate: dayjs(), endDate: dayjs().add(2, 'day'), effectiveDate: dayjs().add(1, 'day') }}
      validateOnChange
      validationSchema={validationSchema}
      onSubmit={({ instrId, localSymbol, startDate, endDate, effectiveDate }, { setSubmitting, resetForm }) => {
        handleFormSubmit({ instrId, localSymbol, startDate, endDate, effectiveDate, setSubmitting, resetForm })
      }}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <Grid container spacing={2} direction='column'>
            <Grid item>
              <Field name='instrId'>
                {({ field }) => (
                  <Autocomplete
                    id='instrId'
                    inputValue={instrIdInput}
                    value={field.value}
                    loading={isLoadingSource}
                    loadingText='Loading'
                    isOptionEqualToValue={(option, value) => option.instrId === value.instrId}
                    options={instrumentIdOptions}
                    getOptionLabel={(option) => option ? `${option?.instrId} (${option?.name})` : ''}
                    size='small'
                    renderInput={(params) => <TextField {...params} label='Instrument Id' variant='outlined' />}
                    onChange={(e, newValue) => handleAutocompleteChange(e, newValue, props)}
                    onBlur={() => setInstrumentIdOptions([])}
                    onInputChange={handleInstrumentIdChange}
                  />
                )}
              </Field>
              <ErrorMessage name='instrId' component={Typography} sx={{ color: '#f05f5f', fontSize: '12px' }} />
            </Grid>
            <Grid item>
              <TextField
                id='localSymbol'
                name='localSymbol'
                size='small'
                fullWidth
                disabled
                value={props.values.localSymbol}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                label='Local Symbol'
                variant='outlined'
              />
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item>
                <Field name='startDate'>
                  {({ field }) => (
                    <DatePicker
                      label='Start Date'
                      id='startDate'
                      name='startDate'
                      minDate={dayjs().subtract(1, 'day')}
                      fullWidth
                      value={field.value}
                      slotProps={{ textField: { variant: 'outlined', size: 'small', fullWidth: true } }}
                      onChange={(newValue) => props.setFieldValue('startDate', newValue)}
                    />
                  )}
                </Field>
                <ErrorMessage name='startDate' component={Typography} sx={{ color: '#f05f5f', fontSize: '12px' }} />
              </Grid>
              <Grid item>
                <Field name='endDate'>
                  {({ field }) => (
                    <DatePicker
                      label='End Date'
                      fullWidth
                      disablePast
                      minDate={props.values.startDate}
                      value={field.value}
                      slotProps={{ textField: { variant: 'outlined', size: 'small', fullWidth: true } }}
                      onChange={(newValue) => props.setFieldValue('endDate', newValue)}
                    />
                  )}
                </Field>
                <ErrorMessage name='endDate' component={Typography} sx={{ color: '#f05f5f', fontSize: '12px' }} />
              </Grid>
              <Grid item>
                <Field name='effectiveDate'>
                  {({ field }) => (
                    <DatePicker
                      label='Effective Date'
                      fullWidth
                      disablePast
                      shouldDisableDate={disableWeekendsAndHolidays}
                      maxDate={props.values.endDate}
                      minDate={props.values.startDate}
                      value={field.value}
                      slotProps={{ textField: { variant: 'outlined', size: 'small', fullWidth: true } }}
                      onChange={(newValue) => props.setFieldValue('effectiveDate', newValue)}
                    />
                  )}
                </Field>
                <ErrorMessage name='effectiveDate' component={Typography} sx={{ color: '#f05f5f', fontSize: '12px' }} />
              </Grid>
            </LocalizationProvider>
            <Grid item>
              {props.isSubmitting
                ? (<LoadingButton
                    loading
                    loadingPosition='start'
                    startIcon={<Save />}
                    variant='outlined'
                    fullWidth
                    size='small'
                    sx={{
                      padding: 1
                    }}
                   >
                  Submit
                </LoadingButton>)
                : <Button
                    type='submit'
                    size='small'
                    fullWidth
                    variant='contained'
                  // disabled={Boolean(!props.isValid || props.isSubmitting)}
                    sx={{
                      padding: 1
                    }}
                  >
                  Submit
                </Button>}
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  )
}

DelistingTable.propTypes = {
  rows: propTypes.object.isRequired,
  getUpdatedRows: propTypes.func.isRequired,
  fetchDelistActions: propTypes.func.isRequired
}

export default DelistingTable
