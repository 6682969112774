import React from 'react'
import { Box, Button, Card, CardContent, Dialog, DialogContent, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import CloseIcon from '@mui/icons-material/Close'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { formatNumbersWithSymbol } from '../pages/Report/components/dataProcess/DataProcess'


const SchwabDataPopup = ({ isSchwabDataModelOpen, setIsSchwabDataModelOpen, validatedPortfolioData, submitPortfolio, showSubmitButton=false }) => {
  const getTextColor = (status) => {
    if (status === 'red') return '#F46A6A'
    if (status === 'yellow') return '#F1B44C'
    if (status === 'green') return '#34C38F'
    return 'default'
  }

  const schwabUploadDataColumns = [
    {
      field: 'symbol',
      headerName: 'Symbol',
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>
            {params?.value || 'N/A'}
          </Typography>
        )
      }
    },
    { field: 'instrId', headerName: 'Instrument Id', flex: 1 },
    {
      field: 'shares',
      headerName: 'Shares',
      flex: 1,
      type: 'number',
      valueGetter: (params) => {
        return params?.row?.shares ?? ''
      },
      renderCell: (params) => {
        return params?.value != null ? params?.value?.toString() : ''
      }
    },
    {
      field: 'dbLastClosePrice',
      headerName: 'Price',
      flex: 1,
      type: 'number',
      valueGetter: (params) => {
        return params?.value !== null && params?.value !== undefined ? parseFloat(params?.value?.toFixed(2)) : null
      },
      renderCell: (params) => {
        const value = params?.row?.dbLastClosePrice
        return (
          <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>
            {value !== null && value !== undefined ? `${formatNumbersWithSymbol(value, 2, '$')}` : 'N/A'}
          </Typography>
        )
      }
    },
    {
      field: 'schwabLastClosePrice',
      headerName: 'Custodian Price',
      flex: 1,
      type: 'number',
      valueGetter: (params) => {
        return params?.value !== null && params?.value !== undefined ? parseFloat(params?.value?.toFixed(2)) : null
      },
      renderCell: (params) => {
        const value = params?.row?.schwabLastClosePrice
        return (
          <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>
            {value !== null && value !== undefined ? `${formatNumbersWithSymbol(value, 2, '$')}` : 'N/A'}
          </Typography>
        )
      }
    },
    {
      field: 'dbLotMv',
      headerName: 'Market Value',
      flex: 1,
      type: 'number',
      valueGetter: (params) => {
        return params?.value !== null && params?.value !== undefined ? parseFloat(params?.value?.toFixed(2)) : null
      },
      renderCell: (params) => {
        const value = params?.row?.dbLotMv
        return (
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '14px'
            }}
          >
            {value !== null && value !== undefined ? `${formatNumbersWithSymbol(value, 2, '$')}` : 'N/A'}
          </Typography>
        )
      }
    },
    {
      field: 'schwabLotMv',
      headerName: 'Custodian Market Value',
      flex: 1,
      type: 'number',
      valueGetter: (params) => {
        return params?.value !== null && params?.value !== undefined ? parseFloat(params?.value?.toFixed(2)) : null
      },
      renderCell: (params) => {
        const value = params?.row?.schwabLotMv
        return (
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '14px'
            }}
          >
            {value !== null && value !== undefined ? `${formatNumbersWithSymbol(value, 2, '$')}` : 'N/A'}
          </Typography>
        )
      }
    }
  ]

  const manualUploadDataColumns = [
    {
      field: 'symbol',
      headerName: 'Symbol',
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>
            {params?.value || 'N/A'}
          </Typography>
        )
      }
    },
    {
      field: 'instrId',
      headerName: 'Instrument Id',
      flex: 1
    },
    {
      field: 'shares',
      headerName: 'Shares',
      flex: 1,
      type: 'number'
    },
    {
      field: 'dbLastClosePrice',
      headerName: 'Price',
      flex: 1,
      type: 'number',
      valueGetter: (params) => {
        return params?.value !== null && params?.value !== undefined ? parseFloat(params?.value?.toFixed(2)) : null
      },
      renderCell: (params) => {
        const value = params?.row?.dbLastClosePrice
        return (
          <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>
            {value !== null && value !== undefined ? `${formatNumbersWithSymbol(value, 2, '$')}` : 'N/A'}
          </Typography>
        )
      }
    },
    {
      field: 'dbLotMv',
      headerName: 'Market Value',
      flex: 1,
      type: 'number',
      valueGetter: (params) => {
        return params?.value !== null && params?.value !== undefined ? parseFloat(params?.value?.toFixed(2)) : null
      },
      renderCell: (params) => {
        const value = params?.row?.dbLotMv
        return (
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '14px'
            }}
          >
            {value !== null && value !== undefined ? `${formatNumbersWithSymbol(value, 2, '$')}` : 'N/A'}
          </Typography>
        )
      }
    }
  ]

  const getRowClassName = (params) => {
    const { lotMvStatus } = params?.row
    if (lotMvStatus === 'red') return 'row-red'
    return ''
  }

  return (
    <>
      <Dialog
        open={isSchwabDataModelOpen}
        onClose={() => setIsSchwabDataModelOpen(false)}
        fullScreen
      >
        <DialogContent>
          <Box overflow='auto' width='100%'>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography id='modal-title' variant='h6' component='h2' sx={{ display: 'inline-flex', alignItems: 'center', gap: '5px' }}>
                Uploaded Portfolio {validatedPortfolioData?.portfolio?.length > 0 && showSubmitButton
                  ? <Tooltip title={validatedPortfolioData?.reason || 'View uploaded portfolio'}>
                    <Box
                      sx={{
                        cursor: 'pointer',
                        marginTop: '6px'
                      }}
                    >
                      <InfoOutlinedIcon
                        sx={{
                          color: '#74788d',
                          fontSize: '18px'
                        }}
                      />
                    </Box>
                  </Tooltip>
                  : ''}
              </Typography>
              <IconButton onClick={() => setIsSchwabDataModelOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box mt={2}>
              <Card sx={{ boxShadow: 'none !important', mb: 2 }}>
                <CardContent style={{ paddingBottom: '16px' }}>
                  <Grid flexWrap='nowrap' container>
                    <Grid sm={3}>
                      <Stack px={3}>
                        <Typography className='sub-labels' sx={{ fontWeight: 600, fontSize: '16px', color: '#74788D', }}>Market Value Calculated</Typography>
                        <Typography className='label-value' sx={{ color: getTextColor(validatedPortfolioData?.mvStatus), fontWeight: 600, fontSize: '16px' }}>
                          {formatNumbersWithSymbol(validatedPortfolioData?.dbPortfolioMarketValue, 2, '$') || 0}
                        </Typography>
                      </Stack>
                    </Grid>
                    {validatedPortfolioData?.schwabPortfolioMarketValue
                      ? <Grid sm={3} borderLeft='1px solid rgba(116, 120, 141, 0.3)'>
                        <Stack pl={5} pr={3}>
                          <Typography className='sub-labels' sx={{ fontWeight: 600, fontSize: '16px', color: '#74788D' }}>Market Value Reported By Custodian</Typography>
                          <Typography className='label-value' sx={{ color: getTextColor(validatedPortfolioData?.mvStatus), fontWeight: 600, fontSize: '16px' }}>
                            {formatNumbersWithSymbol(validatedPortfolioData?.schwabPortfolioMarketValue, 2, '$') || 0}
                          </Typography>
                        </Stack>
                      </Grid>
                      : ''}
                    <Grid sm={3} borderLeft='1px solid rgba(116, 120, 141, 0.3)'>
                      <Stack pl={5} pr={3}>
                        <Typography className='sub-labels' sx={{ fontWeight: 600, fontSize: '16px', color: '#74788D' }}>Total Lots Imported</Typography>
                        <Typography className='label-value' sx={{ color: getTextColor(validatedPortfolioData?.mvStatus), fontWeight: 600, fontSize: '16px' }}>
                          {validatedPortfolioData?.dbTotalLots || 0}
                        </Typography>
                      </Stack>
                    </Grid>
                    {validatedPortfolioData?.schwabPortfolioMarketValue
                      ? <Grid sm={3} borderLeft='1px solid rgba(116, 120, 141, 0.3)'>
                        <Stack pl={5} pr={3}>
                          <Typography className='sub-labels' sx={{ fontWeight: 600, fontSize: '16px', color: '#74788D' }}>Total Lots Reported By Custodian</Typography>
                          <Typography className='label-value' sx={{ color: getTextColor(validatedPortfolioData?.mvStatus), fontWeight: 600, fontSize: '16px' }}>
                            {validatedPortfolioData?.schwabTotalLots || 0}
                          </Typography>
                        </Stack>
                      </Grid>
                      : ''}
                  </Grid>
                </CardContent>
              </Card>
              <DataGridPro
                autoHeight
                density='compact'
                columns={validatedPortfolioData?.schwabPortfolioMarketValue === null ? manualUploadDataColumns : schwabUploadDataColumns}
                rows={validatedPortfolioData?.portfolio || []}
                disableRowSelectionOnClick
                getRowClassName={getRowClassName}
                pagination
                pageSizeOptions={[15, 25, 50, 100]}
                initialState={{
                  ...validatedPortfolioData?.portfolio?.initialState,
                  pagination: { paginationModel: { pageSize: 15 } }
                }}
                sx={(theme) => ({
                  '& .row-red': {
                    backgroundColor: '#F46A6A26',
                    '&:hover': { backgroundColor: '#F46A6A26' }
                  },
                  border: 'none',
                  fontFamily: 'Open Sans',
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none'
                  },
                  [`.${gridClasses.main}`]: {
                    overflow: 'unset'
                  },
                  [`.${gridClasses.columnHeaders}`]: {
                    position: 'sticky',
                    backgroundColor: theme.palette.background.paper,
                    top: 0,
                    zIndex: 1
                  },
                  [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                    color: '#74788d',
                    fontWeight: 600
                  },
                  [`.${gridClasses.footerContainer}`]: {
                    position: 'sticky',
                    bottom: '-1px',
                    backgroundColor: theme.palette.background.paper,
                    zIndex: 1
                  },
                  [`.${gridClasses.virtualScroller}`]: {
                    '::-webkit-scrollbar': {
                      width: '0px !important'
                    },
                    overflowY: 'auto !important',
                    height: `calc(100vh - 40px - 40px - 16px - 80px - 16px - 45px - 52px - ${showSubmitButton ? '37px' : '0px'})`
                  }
                })}
              />
            </Box>
            {
              showSubmitButton
                ? <Box
                    sx={{ textAlign: 'right' }}
                  >
                  <Button
                    variant='contained'
                    onClick={() => submitPortfolio()}
                    className='PortfolioEndBtn'
                  >
                    Submit
                  </Button>
                  </Box>
                : ''
            }
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default SchwabDataPopup
