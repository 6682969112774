import React, { useCallback, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { DataGridPro, GridToolbarContainer, gridClasses } from '@mui/x-data-grid-pro'
import redirect from '../../../assets/images/pop-out-line.svg'
import { ExpandAssetAllocation } from './ExpandAssetAllocation'

function CustomToolbar ({ onExportClick }) {
  return (
    <GridToolbarContainer>
      <Button
        variant='text'
        color='primary'
        onClick={onExportClick}
        startIcon={<FileDownloadOutlinedIcon />}
      >
        Export as CSV
      </Button>
    </GridToolbarContainer>
  )
}

const AssetAllocation = ({ data }) => {
  const [showModal, setShowModal] = useState(false)
  const openModal = () => {
    document.body.classList.add('modal-open', 'pe-0')
    setShowModal(true)
  }

  const handleExportClick = () => {
    if (data.length > 0) {
      // Create a CSV export link
      const csvContent = ['Asset Category,Security,Local Symbol, Account Weight (%),Model Weight (%),Model (+/-) %']

      // Iterate through the data and add rows for each item
      data?.forEach((item) => {
        const mainDataRow = [
          `${item.name || ''}`, // Asset Category column
          `${item.name || ''}`, // Security column
          `${item.securityLevelAllocation?.localSymbol || ''}`, // Local Symbol column

          item.currentPortfolioWeight
            ? (Number(item.currentPortfolioWeight) * 100).toFixed(2).toString()
            : '0',
          item.targetPortfolioWeight
            ? (Number(item.targetPortfolioWeight) * 100).toFixed(2).toString()
            : '0',
          item.difference
            ? (Number(item.difference) * 100).toFixed(2).toString()
            : ''
        ]

        // Add the main data row to the CSV content
        csvContent.push(mainDataRow.join(','))

        if (item.securityLevelAllocation && item.securityLevelAllocation.length > 0) {
          // Iterate through the securityLevelAllocation data and add rows for each security level
          item.securityLevelAllocation.forEach((security) => {
            const securityDataRow = [
              `${item.name.replaceAll(',', '') || ''}`, // Asset Category column
              `${security.name.replaceAll(',', '') || ''}`, // Security column
              `${security.localSymbol || ''}`, // Local Symbol column
              security.currentPortfolioWeight
                ? (Number(security.currentPortfolioWeight) * 100).toFixed(2).toString()
                : '0',
              security.targetPortfolioWeight
                ? (Number(security.targetPortfolioWeight) * 100).toFixed(2).toString()
                : '0',
              security.difference
                ? (Number(security.difference) * 100).toFixed(2).toString()
                : ''
            ]

            // Add the security level allocation row to the CSV content
            csvContent.push(securityDataRow.join(','))
          })
        }
      })

      const csvBlob = new Blob([csvContent.join('\n')], { type: 'text/csv' })
      const csvUrl = URL.createObjectURL(csvBlob)

      // Create a temporary link and trigger the download
      const tempLink = document.createElement('a')
      tempLink.href = csvUrl
      tempLink.setAttribute('download', 'Major-Asset-Class-Allocation.csv')
      tempLink.click()

      // Clean up the URL object
      URL.revokeObjectURL(csvUrl)
    }
  }

  const closeModal = () => {
    setShowModal(false)
    setTimeout(() => {
      document.body.classList.remove('modal-open', 'pe-0')
    }, 200)
  }

  const columns = [
    { field: 'name', headerName: 'Asset Category', flex: 1 },
    {
      field: 'currentPortfolioWeight',
      headerName: 'Account Weight (%)',
      type: 'number',
      flex: 1,
      valueGetter: (params) =>
        params.value !== undefined
          ? `${(params.value * 100).toFixed(2)}`
          : '0'
    },
    {
      field: 'targetPortfolioWeight',
      headerName: 'Model Weight (%)',
      type: 'number',
      flex: 1,
      valueGetter: (params) =>
        params.value !== undefined
          ? `${(params.value * 100).toFixed(2)}`
          : '0'
    },
    {
      field: 'difference',
      headerName: 'Model (+/-) %',
      type: 'number',
      flex: 1,
      valueFormatter: (params) =>
        params.value !== undefined
          ? `${(params.value * 100).toFixed(2)}`
          : '0'
    }
  ]

  const getDetailPanelContent = useCallback(
    ({ row }) => <ExpandAssetAllocation row={row} />,
    []
  )
  function getRowId (row) {
    return row.id
  }

  const getDetailPanelHeight = React.useCallback(() => 'auto', [])

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Grid container justifyContent='space-between'>
          <Grid item>
            <Typography component='p' className='text-title header-text'>Major Asset Class Allocation</Typography>
          </Grid>
          {data && data?.length > 5
            ? (
              <Grid item>
                <Box className='tooltip-trade' onClick={openModal}>
                  <img src={redirect} alt='' />
                  <span
                    className='tooltiptext'
                    style={{ marginLeft: '-50px' }}
                  >
                    All Major Asset Class Allocation
                  </span>
                </Box>
              </Grid>
              )
            : (
                ''
              )}
        </Grid>
        <TableContainer mt={5}>
          <Table className='risk-page-table'>
            <TableHead>
              <TableRow>
                <TableCell>Asset Category</TableCell>
                <TableCell align='right'>Account Weight (%)</TableCell>
                <TableCell align='right'>Model Weight (%)</TableCell>
                <TableCell align='right'>Model (+/-) %</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.length > 0
                ? <>
                  {(data?.slice(0, 6) || []).map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{item.name || ''}</TableCell>
                        <TableCell align='right'>
                          {item.currentPortfolioWeight
                            ? (Number(item.currentPortfolioWeight) * 100)
                                .toFixed(2)
                                .toString()
                            : '0'}
                        </TableCell>
                        <TableCell align='right'>
                          {item.targetPortfolioWeight
                            ? (Number(item.targetPortfolioWeight) * 100)
                                .toFixed(2)
                                .toString()
                            : '0'}
                        </TableCell>
                        <TableCell align='right'>
                          {item.difference
                            ? (Number(item.difference) * 100).toFixed(2)
                            : ''}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                  </>
                : <TableRow>
                  <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                    No Rows
                  </TableCell>
                  </TableRow>}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog fullScreen open={showModal} onClose={closeModal}>
          <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', paddingBottom: 0 }}>
            <Typography
              variant='h6'
              textAlign='center'
              color='#34475A'
              fontWeight={400}
            >
              Major Asset Class Allocation
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
              <Button
                variant='text'
                color='primary'
                onClick={handleExportClick}
                startIcon={<FileDownloadOutlinedIcon />}
              >
                Export as CSV
              </Button>
              <IconButton
                aria-label='close'
                onClick={closeModal}
                sx={{
                  color: (theme) => theme.palette.grey[500]
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent sx={{ overflow: 'scroll' }}>
            <Box style={{ width: '100%' }}>
              <DataGridPro
                rows={data || []}
                columns={columns}
                hideFooter
                disableRowSelectionOnClick
                disableSelectionOnClick
                disableRowSelectionOnClick
                isRowSelectable={() => false}
                getRowId={getRowId}
                getDetailPanelHeight={getDetailPanelHeight} // Height based on the content.
                getDetailPanelContent={getDetailPanelContent}
                sx={(theme) => ({
                  [`.${gridClasses.columnHeaders}`]: {
                    position: 'sticky',
                    top: 0,
                    backgroundColor: theme.palette.background.paper,
                    zIndex: 1
                  },
                  [`.${gridClasses.footerContainer}`]: {
                    position: 'sticky',
                    bottom: -20,
                    backgroundColor: theme.palette.background.paper,
                    zIndex: 1
                  },
                  [`.${gridClasses.toolbarContainer}`]: {
                    position: 'relative',
                    top: 0,
                    backgroundColor: theme.palette.background.paper,
                    zIndex: 1
                  }
                })}
              />
            </Box>
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  )
}

export default AssetAllocation
