import { useEffect, useState } from 'react'
import { Box, IconButton } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, gridFilteredDescendantCountLookupSelector, gridClasses, useGridApiContext, useGridSelector, useGridApiRef } from '@mui/x-data-grid-pro'
import { ExpandMore } from '@mui/icons-material'
import { formatCurrencyWithSymbol } from '../../../../utils/FormateCurrenyInMilion'
import { createHierarchy } from '../../../../utils/groupData'
import { taxlotData as taxlot } from '../../IPSAggregate/data'

const columns = [
  {
    field: 'weight',
    headerName: 'Weight',
    type: 'number',
    align: 'right',
    flex: 1,
    valueGetter: (params) => params?.row?.weight !== undefined && params?.row?.weight !== null ? params?.row?.weight?.toFixed(2) : 'NA'
  },
  {
    field: 'marketValue',
    headerName: 'Market Value',
    type: 'number',
    align: 'right',
    flex: 1,
    valueGetter: (params) => params?.value ? params?.value?.toFixed(0) : params?.value,
    valueFormatter: (params) => typeof params?.value !== 'undefined' ? params?.value !== null ? formatCurrencyWithSymbol(params?.value, 0, '$') : 'NA' : '',
  },
  {
    field: 'purchaseDate',
    headerName: 'Purchase Date',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    valueGetter: (params) => typeof params?.row?.purchaseDate !== 'undefined' ? params?.row?.shares !== null ? params?.row?.purchaseDate?.split('T')[0] : 'NA' : ''
  }
]

function generateArray(key, hierarchy, finalArray, index, datagridHierarchy, fieldsRequiredForSummation) {
  const arrayFromCurrentLevel = []
  datagridHierarchy.push(key)
  const aggregatedFieldsFromChild = hierarchyToArray(hierarchy[key], arrayFromCurrentLevel, index + 1, datagridHierarchy)
  // if (key !== 'Untagged' && key !== 'null')
  arrayFromCurrentLevel.unshift({ ...aggregatedFieldsFromChild, hierarchy: [...datagridHierarchy], id: randomId(), name: key, currLevel: index })
  datagridHierarchy.pop()
  // add the generated child hierarchy array into final array
  arrayFromCurrentLevel.forEach(obj => {
    finalArray.push(obj)
  })
  // add up the required fields properties from sibling to show in parent
  for (let key in fieldsRequiredForSummation) {
    fieldsRequiredForSummation[key] += aggregatedFieldsFromChild[key]
  }
}

function hierarchyToArray(hierarchy, finalArray, index, datagridHierarchy) {
  const fieldsRequiredForSummation = { weight: 0, marketValue: 0 }
  if (Array.isArray(hierarchy)) {
    fieldsRequiredForSummation.accountId = hierarchy.length ? hierarchy[0]?.accountId : null
    hierarchy.forEach((object, index) => {
      finalArray.push({ ...object, id: randomId(), name: object?.instrumentName, currLevel: index, hierarchy: [...datagridHierarchy, object?.instrumentName + index] })
      fieldsRequiredForSummation.weight += object?.weight || 0
      fieldsRequiredForSummation.marketValue += object?.marketValue || 0
    })
    return fieldsRequiredForSummation
  }
  let untaggedFlag = false
  for (const key in hierarchy) {
    if (key === 'Untagged' || key === 'null') {
      untaggedFlag = true
      continue
    }
    generateArray(key, hierarchy, finalArray, index, datagridHierarchy, fieldsRequiredForSummation)
  }
  if (untaggedFlag)
    generateArray('Untagged', hierarchy, finalArray, index, datagridHierarchy, fieldsRequiredForSummation)
  return fieldsRequiredForSummation
}

const TaxlotTab = ({ aggregateAccountHierarchy, loading }) => {
  const [treeData, setTreeData] = useState([])
  const apiRef = useGridApiRef()
  const groupBy = ['accountName', 'instrumentName']

  useEffect(() => {
    const finalArray = []
    hierarchyToArray(createHierarchy(taxlot || [], 0, groupBy), finalArray, 0, [])
    setTreeData(finalArray)
  }, [taxlot])

  return (
    <DataGridPro
      apiRef={apiRef}
      density='compact'
      rows={treeData}
      columns={columns}
      treeData
      getTreeDataPath={(row) => row?.hierarchy}
      autoHeight
      getRowId={(row) => row?.id}
      disableRowSelectionOnClick
      groupingColDef={{
        headerName: 'Name',
        field: 'name',
        flex: 1,
        filterable: true,
        sortable: true,
        disableColumnMenu: false,
        valueGetter: (params) => params?.row?.name,
        renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />
      }}
      localeText={{ noRowsLabel: 'No Portfolio Found' }}
      pageSizeOptions={[10, 25, 50, 100]}
      initialState={{
        pagination: { paginationModel: { pageSize: 10 } }
      }}
      pagination
      sx={{
        [`.${gridClasses.detailPanel}`]: {
          maxHeight: '500px',
          height: 'auto !important'
        }
      }}
    />
  )
}

const CustomGridTreeDataGroupingCell = (props) => {
  const { id, field, rowNode } = props
  const apiRef = useGridApiContext()
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector,
  )
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0

  const handleClick = (event) => {
    if (rowNode.type !== 'group') {
      return
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
    apiRef.current.setCellFocus(id, field)
    event.stopPropagation()
  }

  return (
    <Box sx={{ ml: rowNode.depth * 2 }}>
      <div>
        {filteredDescendantCount > 0 && props?.row?.hierarchy?.length >= 1 ? (
          <>
            <IconButton
              onClick={handleClick}
              size="small"
              tabIndex={-1}
              aria-label={rowNode.childrenExpanded ? 'Close' : 'Open'}
            >
              <ExpandMore
                sx={{
                  transform: `rotateZ(${rowNode.childrenExpanded ? 360 : 270}deg)`,
                  transition: (theme) =>
                    theme.transitions.create('transform', {
                      duration: theme.transitions.duration.shortest,
                    }),
                }}
                fontSize='inherit'
              />
            </IconButton>
            <span style={{ marginLeft: rowNode.depth * 2 }}>
              {props?.row?.name}
            </span>
          </>
        ) : (
          <span style={{ marginLeft: rowNode.depth * 8 }}>{props?.row?.name}</span>
        )}
      </div>
    </Box>
  )
}

export default TaxlotTab