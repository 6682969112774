import React, { useEffect, useState } from 'react'
import { Box, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import { randomId } from '@mui/x-data-grid-generator'
import { formatNumbersWithSymbol } from '../../Report/components/dataProcess/DataProcess'

const renderTableSkeleton = (header, hiddenColumns) => {
  if (hiddenColumns) { header = header.filter(col => !hiddenColumns.includes(col.field)) }
  return (
    <TableContainer mt={5}>
      <Table className='risk-page-table'>
        <TableHead>
          <TableRow>
            {header.map((item, index) => {
              return (
                <TableCell key={index}>{item.headerName}</TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: 5 }).map((_, index) => (
            <TableRow key={index}>
              {Array.from({ length: header.length }).map((_, index) => (
                <TableCell key={index}>
                  <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                </TableCell>))}
            </TableRow>))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const MiscTab = ({ data }) => {
  const [rowData, setRowData] = useState([])

  useEffect(() => {
    if (!data.loading && Array.isArray(data?.data)) { setRowData(data?.data?.map(obj => ({ ...obj, id: randomId() }))) }
  }, [data])

  const [miscellaneousColumns] = useState([
    { field: 'source', headerName: 'Source', flex: 1 },
    { field: 'instrId', headerName: 'Instrument Id', flex: 1 },
    { field: 'instrName', headerName: 'Instrument Name', flex: 1 },
    {
      field: 'price',
      headerName: 'Price',
      type: 'number',
      flex: 1,
      valueGetter: (params) => params?.value ? params?.value?.toFixed(2) : params?.value,
      renderCell: (params) => typeof params?.value !== 'undefined' ? params?.value !== null ? formatNumbersWithSymbol(params?.value, 2, '$') : 'NA' : ''
    },
    { field: 'effectiveDate', headerName: 'Effective Date', flex: 1, headerAlign: 'right', align: 'right', valueFormatter: (params) => params.value ? params.value.split('T')[0] : '' },
    {
      field: 'adjustmentFactor',
      headerName: 'Adjustment Factor',
      flex: 1,
      type: 'number',
      renderCell: (params) => typeof params?.value !== 'undefined' ? params.value !== null ? params?.value : 'NA' : ''
    },
    { field: 'corporateActionTypeCode', headerName: 'CA Type Code', flex: 1 },
    { field: 'description', headerName: 'Description', flex: 2 }
  ])

  return (
    <Box sx={{
      position: 'relative',
      overflowY: 'auto',
      scrollbarWidth: 'none',
      height: 'calc(100vh - 48px - 15px - 39px - 16px - 16px - 48px - 24px - 20px - 10px)'
    }}
    >
      {data.loading
        ? renderTableSkeleton(miscellaneousColumns, ['instrName'])
        : <DataGridPro
            density='compact'
            autoHeight
            rows={rowData || []}
            columns={miscellaneousColumns}
            disableRowSelectionOnClick
            getRowHeight={() => 'auto'}
            getRowId={(row) => row?.id}
            pagination
            pageSizeOptions={[20]}
            initialState={{
              ...rowData?.initialState,
              pagination: { paginationModel: { pageSize: 20 } },
              sorting: {
                sortModel: [{ field: 'effectiveDate', sort: 'desc' }]
              },
              columns: {
                columnVisibilityModel: { instrName: false }
              }
            }}
            sx={(theme) => ({
              '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                py: '8px'
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none'
              },
              [`.${gridClasses.main}`]: {
                overflow: 'unset'
              },
              [`.${gridClasses.columnHeaders}`]: {
                position: 'sticky',
                backgroundColor: theme.palette.background.paper,
                top: 0,
                zIndex: 1
              },
              [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                color: '#74788d',
                fontWeight: 600
              },
              [`.${gridClasses.footerContainer}`]: {
                position: 'sticky',
                bottom: '-1px',
                backgroundColor: theme.palette.background.paper,
                zIndex: 1
              }
            })}
          />}
    </Box>
  )
}

export default MiscTab
