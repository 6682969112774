import { Skeleton, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { CardBodyDashboard } from './DashboardCardbody'
import { DashboardChip } from './CardBodyWithChip'

export const DashboardTableLoaders = () => {
  const SecurityWiseHeaders = [
    { name: 'Security', id: 1 },
    { name: 'Drift', id: 2 },
    { name: 'Weight', id: 5 }
  ]
  const MajorassetAlocationHeaders = [
    { name: 'Asset Category', id: 1 },
    { name: 'Account Weight', id: 2 },
    { name: 'Model Weight', id: 3 },
    { name: 'Model(+/-)', id: 4 }
  ]

  const TopSecurityHeader = [
    { name: 'Security', id: 1 },
    { name: 'Weight', id: 2 }
  ]

  const ActivitySummaryHeader = [
    { name: '', id: 1 },
    { name: 'Total', id: 2 }
  ]
  const SectorAndSecurityLoader = ({ title }) => {
    return (
      <>
        <CardBodyDashboard title={title}>
          <TableHead>
            <TableRow>
              {SecurityWiseHeaders.map((item, index) => {
                return (
                  <TableCell key={index}>{item.name}</TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
            </TableRow>
          </TableBody>
        </CardBodyDashboard>
      </>
    )
  }

  const MajorAssetAllocationLoader = ({ title }) => {
    return (
      <>
        <CardBodyDashboard title={title}>
          <TableHead>
            <TableRow>
              {MajorassetAlocationHeaders.map((item, index) => {
                return (
                  <TableCell key={index}>{item.name}</TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
            </TableRow>
          </TableBody>
        </CardBodyDashboard>
      </>
    )
  }

  const TopSecurityLoader = ({ title }) => {
    return (
      <>
        <CardBodyDashboard title={title}>
          <TableHead>
            <TableRow>
              {TopSecurityHeader.map((item, index) => {
                return (
                  <TableCell key={index}>{item.name}</TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={75} />
              </TableCell>
            </TableRow>
          </TableBody>
        </CardBodyDashboard>
      </>
    )
  }

  const DashboardRequestLoader = ({ title }) => {
    return (
      <CardBodyDashboard title={title}>
        <Skeleton height={60} animation='wave' className='py-4' />
        <Skeleton height={60} animation='wave' className='py-4' />
        <Skeleton height={60} animation='wave' className='py-4' />
      </CardBodyDashboard>
    )
  }

  const ActivitySummaryLoader = ({ title , label}) => {
    return (
      <>
        <DashboardChip title={title} label={label}>
          <TableHead>
            <TableRow>
              {ActivitySummaryHeader?.map((item, index) => (
                <TableCell key={index} align={item.id === 2 ? 'right' : 'left'}>
                  {item.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {[...Array(6)].map((_, index) => (
              <TableRow key={index}>
                <TableCell width='75%'>
                  <Skeleton variant='text' sx={{ fontSize: '11.8px' }} width={75} />
                </TableCell>
                <TableCell align='right' width='5%'>
                  <Skeleton variant='text' sx={{ fontSize: '11.8px' }} width={75} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </DashboardChip>
      </>
    )
  }
  return {
    SectorAndSecurityLoader,
    MajorAssetAllocationLoader,
    TopSecurityLoader,
    DashboardRequestLoader,
    ActivitySummaryLoader
  }
}
