import { useState } from 'react'
import { Box, Button, Drawer, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'

const CreateNewNodeDrawer = (props) => {
  const { newNodeData, setDrawerOpen, drawerOpen, handleNodeChanges } = props

  const [selectedLeftDataValue, setSelectedLeftDataValue] = useState('Market Value')
  const [selectedRightDataValue, setSelectedRightDataValue] = useState('Total Risk')
  const [defaultNodeType, setDefaultNodeType] = useState('family')
  const [nodeData, setNodeData] = useState(newNodeData)

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setNodeData((prevData) => ({
      ...prevData,
      [name]: value
    }))
  }

  const handleDrawerClose = (nodeData) => {
    setDrawerOpen(false)
    handleNodeChanges(nodeData)
  }

  const handleFormSubmit = () => {
    const { name, type } = nodeData

    if (name && defaultNodeType && selectedLeftDataValue && selectedRightDataValue && type) {
      let newNode = {
        ...nodeData,
        name
      }
      if (type === 'group') {
        newNode = {
          ...nodeData,
          name,
          data: { nodeData: { groupName: name } }
        }
      } else {
        newNode = {
          ...nodeData,
          data: { nodeData: { ...nodeData, name, leftDataPoint: selectedLeftDataValue, rightDataPoint: selectedRightDataValue, totalRisk: 12, marketValue: 23, expanded: true, category: defaultNodeType }, expanded: true, isNode: true },
          nodeInfo: { ...nodeData, name, leftDataPoint: selectedLeftDataValue, rightDataPoint: selectedRightDataValue, totalRisk: 12, marketValue: 23, expanded: true, category: defaultNodeType, isNode: true },
          name
        }
      }
      handleDrawerClose(newNode)
    }
  }

  return (
    <Drawer anchor='right' open={drawerOpen} onClose={() => handleDrawerClose()}>
      <Box sx={{ width: 300, maxWidth: 300, padding: 2 }}>
        <Typography variant='h6' sx={{ fontSize: '16px' }}>Create New {nodeData?.type === 'group' ? 'Group' : 'Node'}</Typography>
        <TextField
          label='Name'
          name='name'
          size='small'
          value={nodeData?.name || ''}
          onChange={handleInputChange}
          fullWidth
          margin='normal'
        />
        <>
          {
            nodeData?.type === 'node'
              ? <>
                <InputLabel id='label-id' sx={{ mt: 2 }}>Node Type</InputLabel>
                <Select
                  margin='dense'
                  size='small'
                  name='nodeType'
                  value={defaultNodeType}
                  fullWidth
                  onChange={(event, newValue) => {
                    setDefaultNodeType(newValue?.props?.value)
                  }}
                >
                  {
                    ['family', 'entity', 'ips', 'accounts','washsales','settlement','household'].map((option, index) => (
                      <MenuItem value={option} key={index}>
                        {option}
                      </MenuItem>
                    ))
                  }
                </Select>
                <InputLabel id='label-id' sx={{ mt: 2 }}>Left Data Point</InputLabel>
                <Select
                  margin='dense'
                  size='small'
                  name='leftDataPoint'
                  value={selectedLeftDataValue}
                  fullWidth
                  onChange={(event, newValue) => {
                    setSelectedLeftDataValue(newValue?.props?.value)
                  }}
                >
                  {
                    ['Market Value', 'Total Risk', 'Tracking Error'].map((option, index) => (
                      <MenuItem value={option} key={index}>
                        {option}
                      </MenuItem>
                    ))
                  }
                </Select>
                <InputLabel id='label-id' sx={{ mt: 2 }}>Right Data Point</InputLabel>
                <Select
                  margin='dense'
                  size='small'
                  name='rightDataPoint'
                  value={selectedRightDataValue}
                  fullWidth
                  onChange={(event, newValue) => {
                    setSelectedRightDataValue(newValue?.props?.value)
                  }}
                >
                  {
                    ['Market Value', 'Total Risk', 'Tracking Error'].map((option, index) => (
                      <MenuItem value={option} key={index}>
                        {option}
                      </MenuItem>
                    ))
                  }
                </Select>
                </>
              : ''
          }
        </>
        <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button sx={{ width: '30%' }} variant='contained' color='primary' onClick={handleFormSubmit}>
            Create
          </Button>
        </Box>
      </Box>
    </Drawer>
  )
}

export default CreateNewNodeDrawer
