import React from 'react'
import { Card, CardContent, Grid, Typography, Stack, Tooltip, Box } from '@mui/material'
import { formatCurrency } from '../../../utils/FormateCurrenyInMilion'

const AccountInfo = ({ headers, values }) => {

  return (
    <Card className='report-card'>
      <CardContent sx={{ px: '20px', py: '15px', height: '90px', display: 'flex' }}>
        <Grid className='report-card-border' container alignItems='center'>
        {
          headers.map((field, index) => (
            <Grid key={index} xs={2} sm={3} item>
              <Stack>
                <Box mb={1}>
                <Tooltip title={typeof values[index] === 'number' ? '$' + values[index].toFixed(2) : ''}>
                  <Typography className='card-heading' sx={{ color: '#4B8CCA', display: 'inline-flex', alignItems: 'center'}}>
                    {
                        values[index] && values[index].value !== undefined ? values[index].value >= 0 ? '$' + formatCurrency(values[index].value) : '-$' + formatCurrency(values[index].value) : '-'
                    }
                  </Typography>
                  </Tooltip>
                  {
                    values[index] && values[index].percentChange
                      ? <Box sx={{ color: values[index].percentChange > 0 ? '#33A68D' : '#FF6A6A', fontSize: '14px', margin: '2px 0 0 5px', alignItems: 'center', display: 'inline-flex' }}>
                        ({values[index].percentChange > 0 ? '+' : ''}{values[index].percentChange}%)
                      </Box>
                      : ''
                  }
                  </Box>
                <Typography variant='subtitle1' className='card-subheading'>
                  {field}
                </Typography>
              </Stack>
            </Grid>
          ))
        }
        </Grid>
      </CardContent>
    </Card>
  )
  
}

export default AccountInfo