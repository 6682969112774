import { memo, useState } from 'react'
import {
  useStore,
  NodeResizer,
  Position,
  Handle,
  useHandleConnections
} from '@xyflow/react'
import { Box } from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { getRelativeNodesBounds } from '../helper/reactFlowChartUtils'

const lineStyle = { borderColor: 'white' }

function GroupNode({ data, id }) {
  const [isCollapsed, setIsCollapsed] = useState(data?.expanded || false)
  const connections = useHandleConnections({ type: 'source', nodeId: id })
  const { minWidth, minHeight, nodesConnectable } = useStore((store) => {
    const childNodes = Array.from(store.nodeLookup.values()).filter(
      (n) => n.parentId === id
    )
    const rect = getRelativeNodesBounds(childNodes)
    return {
      minWidth: rect.x + rect.width,
      minHeight: rect.y + rect.height,
      hasChildNodes: childNodes.length > 0,
      nodesConnectable: store.nodesConnectable
    }
  }, isEqual)

  const operation = (e) => {
    e.preventDefault()
    setIsCollapsed((prev) => !prev)
  }

  return (
    <div>
      <Handle
        type='target'
        position={Position.Top}
        style={{ top: '-4px', background: '#555' }}
      />
      {
        nodesConnectable
          ? <NodeResizer
              lineStyle={lineStyle}
              minHeight={minHeight}
              minWidth={minWidth}
            />
          : ''
      }
      {connections?.length > 0 && (
        <Box sx={{
          position: 'absolute',
          bottom: 0,
          left: '50%',
          transform: 'translate(-50%, 28px)'
        }}>
          <button
            style={{
              cursor: 'pointer',
              border: '1px solid gray',
              padding: '2px 8px',
              borderRadius: '5px',
              display: 'flex',
              justifyContent: 'center'
            }}
            onClick={operation}
          >
            {connections.length} {isCollapsed ? <KeyboardArrowUpIcon fontSize='small' /> : <KeyboardArrowDownIcon fontSize='small' />}
          </button>
        </Box>
      )}
      <Handle
        type='source'
        position={Position.Bottom}
        style={{ bottom: '-4px', background: '#555' }}
      />
    </div>
  )
}

function isEqual (prev, next) {
  return (
    prev.minWidth === next.minWidth &&
    prev.minHeight === next.minHeight &&
    prev.hasChildNodes === next.hasChildNodes && 
    prev.nodesConnectable === next.nodesConnectable
  )
}

export default memo(GroupNode)
