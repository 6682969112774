import React from 'react'
import { BsTrash } from 'react-icons/bs'
import { Box, Button, Typography } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'

const MemberTable = (props) => {
  const {
    selectedMembers,
    setShowMemberPopup,
    deleteMember
  } = props

  const memberCols = [
    {
      field: 'memberRefId',
      headerName: 'Id',
      flex: 1
    },
    {
      field: 'memberRefName',
      headerName: 'Name',
      flex: 1
    },
    {
      field: 'memberType',
      headerName: 'Type',
      flex: 1
    },
    {
      field: ' ',
      headerName: ' ',
      sortable: false,
      align: 'center',
      disableColumnMenu: true,
      width: 50,
      renderCell: (params) => (
        <Box onClick={() => deleteMember(params)}>
          <BsTrash style={{ fontSize: '14px', color: '#74788D', cursor: 'pointer' }} />
        </Box>
      )
    }
  ]

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '8px' }}>
        <Typography sx={{ display: 'flex', flexDirection: 'column', color: '#74788D', fontSize: '16px', fontWeight: 600, justifyContent: 'center' }}>Members</Typography>
        <Button
          size='small'
          variant='text'
          onClick={() => setShowMemberPopup(true)}
          sx={{ fontSize: '14px' }}
        >
          + Add Member
        </Button>
      </Box>
      <DataGridPro
        density='compact'
        rows={selectedMembers}
        columns={memberCols}
        getRowId={(row) => row?.memberRefId}
        autoHeight
        pagination
        pageSizeOptions={[10]}
        disableRowSelectionOnClick
        initialState={{
          ...selectedMembers?.initialState,
          pagination: { paginationModel: { pageSize: 10 } },
          columns: {
            columnVisibilityModel: {
              memberRefId: false
            }
          }
        }}
        sx={{
          border: 'none',
          fontFamily: 'Open Sans',
          '& .MuiDataGrid-cell': {
            cursor: 'default'
          },
        }}
      />
    </>
  )
}

export default MemberTable