import AddIcon from '@mui/icons-material/Add'
import CancelIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import { Box, Button, Dialog, Stack } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, GridActionsCellItem, GridRowModes, GridToolbarContainer } from '@mui/x-data-grid-pro'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { CreateAccountData } from '../Account/CreateAccountData'
import { UpdateAccountData } from '../Account/UpdateAccountData'
import { requestDataContext } from '../DataMaintenance'
import { UpdateInstrumentData } from '../Instrument/UpdateInstrumentData'
import { CreateRequestData } from '../Request/CreateRequestData'
import { DeleteRequestData } from '../Request/DeleteRequestData'
import { UpdateRequestData } from '../Request/UpdateRequestData'
import { CreateStrategyData } from '../Strategy/CreateStrategyData'
import { DeleteStrategyData } from '../Strategy/DeleteStrategyData'
import { UpdateStrategyData } from '../Strategy/UpdateStrategyData'
import { CreateReportData } from '../Report/CreateReportData'
import { DeleteReportData } from '../Report/DeleteReportData'
import { UpdateReportData } from '../Report/UpdateReportData'
import CustomEditDateComponent from './CustomEditDateComponent'
import { InputForm } from './InputForm'
import { CreateDirectIndexingData } from '../Strategy/DI/CreateDirectIndexingData'
import { DeleteDirectIndexingData } from '../Strategy/DI/DeleteDirectIndexingData'
import { UpdateDirectIndexingData } from '../Strategy/DI/UpdateDirectIndexingData'
import { UpdateDynamicStrategy } from '../Strategy/DI/UpdateDynamicStrategy'
import { CreateSponsorData } from '../Sponsor/CreateSponsorData'
import { DeleteSponsorData } from '../Sponsor/DeleteSponsorData'
import { UpdateSponsorData } from '../Sponsor/UpdateSponsorData'
import { DeleteAccountData } from '../Account/DeleteAccountData'
import { CreateTradeData } from '../Trade/CreateTradeData'
import { UpdateTradeData } from '../Trade/UpdateTradeData'
import { DeleteTradeData } from '../Trade/DeleteTradeData'
import { CreateOptimizationData } from '../Optimization/CreateOptimizationData'
import { DeleteOptimizationData } from '../Optimization/DeleteOptimizationData'
import { UpdateOptimizationData } from '../Optimization/UpdateOptimizationData'
import { CreateMasterData } from '../Master/CreateMasterData'
import { UpdateMasterData } from '../Master/UpdateMasterData'
import { DeleteMasterData } from '../Master/DeleteMasterData'
import CustomTooltipIcon from '../../Personalization/components/CustomTooltipIcon'
import { FormatDateAPI } from './FormatAPIRequest'
import { CreateInstrumentData } from '../Instrument/CreateInstrumentData'
import { DeleteInstrumentData } from '../Instrument/DeleteInstrumentData'

// Extend dayjs with the utc plugin
dayjs.extend(utc)

let responseData = {}

const EditToolbar = (props) => {
  const { setRows, setRowModesModel, setResponseData, tabIndex, dataKey, activeId, openIndexingModal, handleDerivedStrategyInsert, setOpenIndexingModal, rows, name, mode, setMode } = props
  const [open, setOpen] = React.useState(false)
  const { showError } = useErrorToast()
  const [defaultFieldValue, setFieldDefaultValue] = useState({})
  const [loading, setLoading] = useState(false)
  const [excludeFields, setExcludeFields] = useState([])

  // set default value for input form based on module
  useEffect(() => {
    switch (dataKey) {
      case 'account':
        switch (tabIndex) {
          case 'external-gl':
            setFieldDefaultValue({ accountId: activeId })
            setExcludeFields(['accExtGl'])
            break
          case 'internal-gl':
            setFieldDefaultValue({ accountId: activeId })
            setExcludeFields(['accIntGl'])
            break
          case 'portfolio-specification':
            setExcludeFields(['accountId', 'portSpecId'])
            break
          case 'portfolio-specification-properties':
            setExcludeFields(['accountId', 'portSpecPropertiesId'])
            break
          case 'properties':
            setFieldDefaultValue({ accountId: activeId })
            setExcludeFields(['accountPropertiesId'])
            break
          case 'restriction':
            setFieldDefaultValue({ accountId: activeId })
            setExcludeFields(['accountInstrRestrId'])
            break
          default:
            setFieldDefaultValue({ accountId: activeId })
            setExcludeFields([])
            break
        }
        break
      case 'strategy':
        switch (tabIndex) {
          case 'strategy-properties-master':
            setFieldDefaultValue({ strategyId: activeId })
            setExcludeFields(['strategyPropertiesId'])
            break
          case 'strategy-benchmark-master':
            setFieldDefaultValue({ benchmarkId: activeId })
            setExcludeFields(['benchmarkId'])
            break
          case 'strategy-instrument-restriction':
            setFieldDefaultValue({ strategyId: activeId })
            setExcludeFields(['strategyInstrRestrId'])
            break
          default:
            setFieldDefaultValue({ strategyId: activeId })
            setExcludeFields([])
            break
        }
        break
      case 'strategyAllocation':
        setFieldDefaultValue({})
        setExcludeFields(['strTypeId'])
        break
      case 'sponsor':
        switch (tabIndex) {
          case 'sponsor':
            setExcludeFields(['sponsorId'])
            break
          case 'strategy-owner':
            setExcludeFields(['strategyOwnerId'])
            break
          case 'sponsor-financial-advisors':
            setExcludeFields(['faId'])
            break
          default:
            setFieldDefaultValue({})
            setExcludeFields([])
            break
        }
        break
      case 'trade': {
        switch (tabIndex) {
          case 'trade-status-master':
            setFieldDefaultValue({})
            setExcludeFields(['tradeStatusId'])
            break
          default:
            setFieldDefaultValue({})
            setExcludeFields([])
            break
        }
        break
      }
      case 'optimization': {
        switch (tabIndex) {
          case 'opt-status-master':
            setFieldDefaultValue({})
            setExcludeFields(['optStatusId'])
            break
          case 'opt-scenario-list':
            setFieldDefaultValue({})
            setExcludeFields(['scenarioId'])
            break
          case 'opt-scenario-properties':
            setFieldDefaultValue({})
            setExcludeFields(['optScenarioPropertiesId'])
            break
          default:
            break
        }
        break
      }
      case 'master': {
        switch (tabIndex) {
          case 'account-status-codes':
            setFieldDefaultValue({})
            setExcludeFields(['accountStatusId'])
            break
          case 'restriction-codes':
            setFieldDefaultValue({})
            setExcludeFields(['restrictionId'])
            break
          case 'sell-logic-codes':
            setFieldDefaultValue({})
            setExcludeFields(['sellLogicId'])
            break
          case 'tax-states':
            setFieldDefaultValue({})
            setExcludeFields(['stateId'])
            break
          default:
            setFieldDefaultValue({})
            setExcludeFields([])
            break
        }
        break
      }
      default:
        setFieldDefaultValue({})
        setExcludeFields([])
        break
    }
  }, [dataKey])

  const handleClick = (tabIndex) => {
    if (tabIndex === 'dynamic-strategy') {
      setOpenIndexingModal(true)
      setMode('add')
    } else {
      setOpen(true)
    }
  }

  const handleSaveClose = async (values) => {
    let CreateRequestResponse = {}
    switch (dataKey) {
      case 'strategy':
        CreateRequestResponse = await CreateStrategyData(values, tabIndex)
        break
      case 'request':
        CreateRequestResponse = await CreateRequestData(values, tabIndex)
        break
      case 'account':
        CreateRequestResponse = await CreateAccountData(values, tabIndex)
        break
      case 'strategyAllocation':
        CreateRequestResponse = await CreateDirectIndexingData(values, tabIndex)
        break
      case 'report':
        CreateRequestResponse = await CreateReportData(values, tabIndex)
        break
      case 'sponsor':
        CreateRequestResponse = await CreateSponsorData(values, tabIndex)
        break
      case 'trade':
        CreateRequestResponse = await CreateTradeData(values, tabIndex)
        break
      case 'optimization':
        CreateRequestResponse = await CreateOptimizationData(values, tabIndex)
        break
      case 'master':
        CreateRequestResponse = await CreateMasterData(values, tabIndex)
        break
      case 'instrument':
        CreateRequestResponse = await CreateInstrumentData(values, tabIndex)
        break
      default:
        break
    }
    if (Object.keys(CreateRequestResponse).length === 0) {
      return CreateRequestResponse
    }
    if (Object.keys(CreateRequestResponse).includes('error')) {
      setLoading(false)
      showError(CreateRequestResponse.error || 'Invalid input data')
      return {}
    }
    setOpen(false)
    const id = randomId()
    const newRow = {
      id,
      ...{
        ...(CreateRequestResponse.data?.length && CreateRequestResponse.data[0]) ? CreateRequestResponse.data[0] : values
      },
      insId: (CreateRequestResponse.data?.length && CreateRequestResponse.data[0] && CreateRequestResponse.data[0].insId) || '',
      insTs: new Date((CreateRequestResponse.data?.length && CreateRequestResponse.data[0] && CreateRequestResponse.data[0].insTs) || null),
      updId: (CreateRequestResponse.data?.length && CreateRequestResponse.data[0] && CreateRequestResponse.data[0].updId) || '',
      updTs: new Date((CreateRequestResponse.data?.length && CreateRequestResponse.data[0] && CreateRequestResponse.data[0].updTs) || null),
      isNew: true
    }
    setRows((oldRows) => [...oldRows, newRow])
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.View, fieldToFocus: 'name' }
    }))
    // Update existing responseData with the new row
    const updatedResponseData = structuredClone(props.responseData)
    updatedResponseData[tabIndex].data.data.push(newRow)
    setResponseData(updatedResponseData)
    setLoading(false)
  }

  const handleCancelClose = () => {
    setOpen(false)
  }

  return (
    <>
      <GridToolbarContainer>
        {responseData?.operationAllowed?.insert &&
          <Button color='primary' startIcon={<AddIcon />} onClick={() => handleClick(tabIndex)}>
            Add record
          </Button>}
      </GridToolbarContainer>
      {
          (name === 'strategyAllocation' && tabIndex === 'dynamic-strategy' && mode === 'add')
            ? <UpdateDynamicStrategy
                open={openIndexingModal}
                handleDerivedStrategyInsert={handleDerivedStrategyInsert}
                handleCancelClose={() => setOpenIndexingModal(false)}
                strategyRows={rows}
                currentStrategyId={activeId}
              />
            : <>
              <Dialog open={open} onClose={handleCancelClose} fullWidth maxWidth='sm'>
                <InputForm savedData={handleSaveClose} setLoading={setLoading} loading={loading} data={responseData} tabName={tabIndex} moduleName={dataKey} defaultFieldValue={defaultFieldValue} excludeFields={excludeFields} />
              </Dialog>
            </>
      }
    </>
  )
}

EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,
  setRows: PropTypes.func.isRequired,
  setResponseData: PropTypes.func.isRequired,
  responseData: PropTypes.object,
  tabIndex: PropTypes.string.isRequired,
  dataKey: PropTypes.string.isRequired
}

const setData = (value) => {
  responseData = value
}

export const TabPanelData = (props) => {
  const { tabIndex, name } = props
  const { responseData, setResponseData, dataKey, activeId } = useContext(requestDataContext)
  const [rows, setRows] = useState([])
  const [rowModesModel, setRowModesModel] = React.useState({})
  const { showError } = useErrorToast()
  const [openIndexingModal, setOpenIndexingModal] = useState(false)
  const requestDataIndex = responseData[tabIndex] || {}
  setData(requestDataIndex?.data)
  const [mode, setMode] = useState('add')

  const getRowId = (row) => row.id

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleEditClick = (id) => () => {
    if (name === 'strategyAllocation' && tabIndex === 'dynamic-strategy') {
      setOpenIndexingModal(true)
      setMode('edit')
    } else {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
    }
  }

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleDeleteClick = (id) => async () => {
    // Call API
    const deleteRow = rows.find((row) => row.id === id)
    let DeleteRequestResponse = {}
    switch (dataKey) {
      case 'strategy':
        DeleteRequestResponse = await DeleteStrategyData(deleteRow, tabIndex)
        break
      case 'account':
        DeleteRequestResponse = await DeleteAccountData(deleteRow, tabIndex)
        break
      case 'request':
        DeleteRequestResponse = await DeleteRequestData(deleteRow, tabIndex)
        break
      case 'strategyAllocation':
        DeleteRequestResponse = await DeleteDirectIndexingData(deleteRow, tabIndex)
        break
      case 'report':
        DeleteRequestResponse = await DeleteReportData(deleteRow, tabIndex)
        break
      case 'sponsor':
        DeleteRequestResponse = await DeleteSponsorData(deleteRow, tabIndex)
        break
      case 'trade':
        DeleteRequestResponse = await DeleteTradeData(deleteRow, tabIndex)
        break
      case 'optimization':
        DeleteRequestResponse = await DeleteOptimizationData(deleteRow, tabIndex)
        break
      case 'master':
        DeleteRequestResponse = await DeleteMasterData(deleteRow, tabIndex)
        break
      case 'instrument':
        DeleteRequestResponse = await DeleteInstrumentData(deleteRow, tabIndex)
        break
      default:
        break
    }
    if (Object.keys(DeleteRequestResponse).length === 0) {
      return DeleteRequestResponse
    }
    if (DeleteRequestResponse?.error) {
      showError(DeleteRequestResponse.error)
      return {}
    }
    setRows(rows.filter((row) => row.id !== id))
    // Delete row from all tab response
    if (responseData[tabIndex] && responseData[tabIndex].data && responseData[tabIndex].data.data) {
      const updatedResponseData = {
        ...responseData,
        [tabIndex]: {
          ...responseData[tabIndex],
          data: {
            ...responseData[tabIndex].data,
            data: responseData[tabIndex].data.data.filter((dataItem) => dataItem.id !== id)
          }
        }
      }
      setResponseData(updatedResponseData)
    }
  }

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })

    const editedRow = rows.find((row) => row.id === id)
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id))
    }
  }

  const processRowUpdate = async (newRow) => {
    let oldRow
    let UpdateRequestResponse = {}
    if (responseData[tabIndex] && responseData[tabIndex].data && responseData[tabIndex].data.data) {
      const updatedResponseData = {
        ...responseData,
        [tabIndex]: {
          ...responseData[tabIndex],
          data: {
            ...responseData[tabIndex].data,
            data: responseData[tabIndex].data.data.map((dataItem) => {
              if (dataItem.id === newRow.id) {
                for (const key in dataItem) {
                  const columnType = responseData[tabIndex].data?.tableStructure?.find(column => column.columnName === key)?.dataType
                  if (columnType === 'select') {
                    dataItem[key] = dataItem[key] === 'Yes' ? 1 : 0
                    newRow[key] = newRow[key] === 'Yes' ? 1 : 0
                  }
                }
                if (tabIndex === 'account-master' && newRow?.accountStatusId === 0) {
                  dataItem.endDate = new Date().toUTCString()
                  newRow.endDate = new Date().toUTCString()
                }
                oldRow = dataItem
                return Object.assign({}, dataItem, newRow)
              }
              return dataItem
            })
          }
        }
      }
      // Call API
      switch (dataKey) {
        case 'strategy':
          UpdateRequestResponse = await UpdateStrategyData(newRow, oldRow, tabIndex)
          break
        case 'request':
          UpdateRequestResponse = await UpdateRequestData(newRow, tabIndex)
          break
        case 'account':
          UpdateRequestResponse = await UpdateAccountData(newRow, oldRow, tabIndex)
          break
        case 'instrument':
          UpdateRequestResponse = await UpdateInstrumentData(newRow, oldRow, tabIndex)
          break
        case 'strategyAllocation':
          UpdateRequestResponse = await UpdateDirectIndexingData(newRow, tabIndex)
          break
        case 'report':
          UpdateRequestResponse = await UpdateReportData(newRow, tabIndex, oldRow)
          break
        case 'sponsor':
          UpdateRequestResponse = await UpdateSponsorData(newRow, tabIndex)
          break
        case 'trade':
          UpdateRequestResponse = await UpdateTradeData(newRow, tabIndex)
          break
        case 'optimization':
          UpdateRequestResponse = await UpdateOptimizationData(newRow, tabIndex)
          break
        case 'master':
          UpdateRequestResponse = await UpdateMasterData(newRow, oldRow, tabIndex)
          break
        default:
          break
      }
      if (Object.keys(UpdateRequestResponse).length === 0) {
        return UpdateRequestResponse
      }
      if (UpdateRequestResponse?.error) {
        showError(UpdateRequestResponse.error)
        return {}
      }
      setResponseData(updatedResponseData)
    }
    const updatedRow = { ...newRow, isNew: false }
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)))
    return updatedRow
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  const handleDerivedStrategyInsert = (newRows) => {
    // Update existing responseData with the new row
    const updatedResponseData = structuredClone(responseData)
    updatedResponseData[tabIndex].data.data = [...newRows]
    setResponseData(updatedResponseData)
    setRows(updatedResponseData[tabIndex]?.data?.data)
    setOpenIndexingModal(false)
  }

  useEffect(() => {
    if (requestDataIndex) {
      setRows(requestDataIndex?.data?.data || [])
    }
  }, [dataKey, name, requestDataIndex])

  let column = []
  if (requestDataIndex?.data?.fieldLabels) {
    column = Object.entries(requestDataIndex?.data?.fieldLabels).map(([key, value]) => {
      const data = requestDataIndex?.data
      let columnType = data?.tableStructure?.find(column => column.columnName === key)?.dataType
      columnType = columnType === 'select' ? 'singleSelect' : columnType
      if (columnType !== 'singleSelect') {
        return {
          field: key,
          headerName: value,
          type: columnType,
          flex: 1,
          editable: data?.fieldEditAllowed?.[key],
          align: columnType === 'number' ? 'right' : 'left',
          ...{
            ...columnType === 'date'
              ? {
                  valueFormatter: (param) => param.value ? new Date(param.value).toUTCString() : '',
                  ...{
                    ...data?.fieldEditAllowed?.[key]
                      ? { renderEditCell: (params) => (<CustomEditDateComponent {...params} />) }
                      : {}
                  }
                }
              : {}
          }
        }
      } else {
        return {
          field: key,
          headerName: value,
          type: columnType,
          flex: 1,
          editable: data?.fieldEditAllowed?.[key],
          valueGetter: (param) => (param.value === 1 ? 'Yes' : 'No'),
          valueOptions: ['Yes', 'No']
        }
      }
    })
  }

  if (tabIndex === 'account-master' && column.length && rows[0]?.accountStatusId === 1 &&
    (FormatDateAPI(rows[0]?.endDate) < FormatDateAPI(new Date()))) {
    if (rows[0]?.endDate !== null && rows[0]?.endDate !== '') {
      column.unshift({
        field: 'info',
        headerName: '',
        type: 'string',
        width: 30,
        align: 'left',
        renderCell: () => {
          return (
            <Box sx={{ display: 'flex', marginLeft: '1px' }}>
              <CustomTooltipIcon text='Account status is active but end date is in past' action='error' />
            </Box>
          )
        }
      })
    }
  }

  if (column.length) {
    column.push({
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 1,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label='Save'
              key='Save'
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label='Cancel'
              key='Cancel'
              className='textPrimary'
              onClick={handleCancelClick(id)}
              color='inherit'
            />
          ]
        }
        const actions = [
          <GridActionsCellItem
            icon={<EditIcon />}
            label='Edit'
            key='Edit'
            className='textPrimary'
            onClick={handleEditClick(id)}
            disabled={!requestDataIndex?.data?.operationAllowed?.update}
            color='inherit'
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label='Delete'
            key='Delete'
            onClick={handleDeleteClick(id)}
            disabled={!requestDataIndex?.data?.operationAllowed?.delete}
            color='inherit'
          />
        ]
        return actions
      }
    }
    )
  }

  if (!column?.length) {
    return (
      <Stack>
        <div>No Data</div>
      </Stack>
    )
  }

  return (
    <>
      <DataGridPro
        autoHeight
        rows={rows}
        columns={column}
        pageSizeOptions={[10, 25, 50]}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } }
        }}
        pagination
        getRowId={getRowId}
        sx={{
          // '.MuiDataGrid-cell--editable': {
          //   background: 'lightgray'
          // },
          '.MuiDataGrid-editInputCell': {
            background: 'rgba(128,128,128,0.2)',
            height: '55px'
          }
        }}
        editMode='row'
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, responseData, setResponseData, tabIndex, dataKey, activeId, openIndexingModal, handleDerivedStrategyInsert, setOpenIndexingModal, rows, name, mode, setMode }
        }}
      />
      {name === 'strategyAllocation' && tabIndex === 'dynamic-strategy' && mode === 'edit'
        ? <UpdateDynamicStrategy
            open={openIndexingModal}
            handleDerivedStrategyInsert={handleDerivedStrategyInsert}
            handleCancelClose={() => setOpenIndexingModal(false)}
            strategyRows={rows}
          />
        : ''}
    </>
  )
}
