export const formatCurrencyWithSymbol = (amount, decimal, symbol) => {
  const absAmount = Math.abs(Number(amount))
  const sign = Math.sign(amount) === -1 ? '-' : ''
  if (absAmount >= 1.0e9) {
    return sign + symbol + (absAmount / 1.0e9).toFixed(decimal) + 'B'
  } else if (absAmount >= 1.0e6) {
    return sign + symbol + (absAmount / 1.0e6).toFixed(decimal) + 'M'
  } else if (absAmount >= 1.0e3) {
    return sign + symbol + (absAmount / 1.0e3).toFixed(decimal) + 'K'
  } else if (absAmount === 0) {
    return symbol + (absAmount).toFixed(decimal)
  } else {
    return sign + symbol + absAmount.toFixed(decimal)
  }
}

export const formatCurrency = (amount, decimal) => {
  const absAmount = Math.abs(Number(amount))
  const sign = Math.sign(amount) === -1 ? '-' : ''
  if (absAmount >= 1.0e9) {
    return sign + (absAmount / 1.0e9).toFixed(decimal) + 'B'
  } else if (absAmount >= 1.0e6) {
    return sign + (absAmount / 1.0e6).toFixed(decimal) + 'M'
  } else if (absAmount >= 1.0e3) {
    return sign + (absAmount / 1.0e3).toFixed(decimal) + 'K'
  } else if (absAmount === 0) {
    return (absAmount).toFixed(decimal)
  } else {
    return sign + absAmount.toFixed(decimal)
  }
}

// export const formatCurrency1 = (amount, decimal) => {
//   const absAmount = Math.abs(Number(amount))
//   const sign = Math.sign(amount) === -1 ? '-' : ''
//   if (absAmount >= 1.0e9) {
//     return formatValue(absAmount, 1.0e9, decimal, 'B', sign)
//   } else if (absAmount >= 1.0e6) {
//     return formatValue(absAmount, 1.0e6, decimal, 'M', sign)
//   } else if (absAmount >= 1.0e3) {
//     return formatValue(absAmount, 1.0e3, decimal, 'K', sign)
//   } else if (absAmount === 0) {
//     return (absAmount.toFixed(decimal) + '0')
//   } else {
//     return sign + absAmount.toFixed(decimal)
//   }
// }

// function formatValue (absAmount, divisor, decimal, suffix, sign) {
//   const formattedValue = (absAmount / divisor).toFixed(decimal)
//   const [integerPart, decimalPart = ''] = formattedValue.split('.')
//   const totalLength = integerPart.length + decimalPart.length
//   // Check if the total length is less than the maximum digits
//   if (totalLength < 4) {
//     // Calculate the number of zeros to add to the decimal part
//     const zerosToAdd = 4 - totalLength
//     // Pad the decimal part with zeros
//     const paddedDecimalPart = decimalPart.padEnd(decimal + zerosToAdd, '0')
//     // Return the formatted value with the sign, integer part, padded decimal part, and suffix
//     return `${sign}${integerPart}.${paddedDecimalPart}${suffix}`
//   } else {
//     // Return the formatted value with the sign, integer part, decimal part, and suffix
//     return `${sign}${integerPart}.${decimalPart}${suffix}`
//   }
// }

export const decTwoPercentage = (string, decimal) => {
  return (Number(string) * 100).toFixed(decimal).toString()
}
export const decTwoPercentageBeta = (string, decimal) => {
  return (Number(string)).toFixed(decimal).toString()
}

export const decTwoPlaces = (string, decimal) => {
  return (Number(string)).toFixed(decimal).toString()
}

export const capitalized = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export const formatNumbersWithSymbol = (amount, decimal, symbol) => {
  const sign = Math.sign(amount) === -1 ? '-' : ''
  const absAmount = Math.abs(Number(amount))

  return sign + symbol + absAmount.toLocaleString('en-Us', { maximumFractionDigits: decimal })
}

export const formatNumbers = (amount, decimal, symbol) => {
  const absAmount = Math.abs(Number(amount))

  return symbol + absAmount.toLocaleString('en-Us', { maximumFractionDigits: decimal })
}
