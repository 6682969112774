import React, { useEffect, useState } from 'react'
import { Box, IconButton, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, gridFilteredDescendantCountLookupSelector, gridClasses, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro'
import { ExpandMore } from '@mui/icons-material'

const RestrictionGroupTable = ({ data, loading }) => {
  const [treeData, setTreeData] = useState([])

  useEffect(() => {
    const groupByAccountId = data.reduce((acc, obj) => {
      const key = obj?.accountId
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(obj)
      return acc
    }, {})

    const rows = []
    for (let accountId in groupByAccountId) {
      rows.push({
        id: randomId(),
        hierarchy: [accountId],
        accountName: groupByAccountId[accountId].length > 0 ? groupByAccountId[accountId][0]?.accountName : ''
      })
      groupByAccountId[accountId].map(data => {
        const tempRandomId = randomId()
        rows.push({
          id: tempRandomId,
          ...data,
          hierarchy: [accountId, data.instrName + tempRandomId]
        })
      })
    }
    setTreeData(rows)
  }, [data])

  const columns = [
    {
      field: 'restrictionDesc',
      headerName: 'Restriction',
      flex: 1,
    },
    {
      field: 'sourceAccountName',
      headerName: 'Source Account Name',
      flex: 1,
    }
  ]

  return (
    <>
      <Box sx={{ position: 'relative' }} className='aggregate-portfolio-table'>
        {
          loading
            ? <TableContainer mt={5}>
              <Table className='risk-page-table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    {columns.map(item => {
                      return (
                        <TableCell>{item.headerName}</TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.from({ length: 5 }).map(_ => (
                    <TableRow>
                      {Array.from({ length: columns.length + 1 }).map(_ => (
                        <TableCell>
                          <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                        </TableCell>))}
                    </TableRow>))}
                </TableBody>
              </Table>
            </TableContainer>
            : <DataGridPro
              density='compact'
              treeData
              rows={treeData}
              columns={columns}
              autoHeight
              getRowId={(row) => row.id}
              disableRowSelectionOnClick
              defaultGroupingExpansionDepth={0}
              getTreeDataPath={(row) => row.hierarchy}
              groupingColDef={{
                headerName: 'Name',
                width: 250,
                filterable: true,
                sortable: true,
                disableColumnMenu: false,
                valueGetter: (params) => {
                  return params?.row?.hierarchy?.length === 2 ? params?.row?.instrName : params?.row?.accountName
                },
                renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />
              }}
              localeText={{ noRowsLabel: 'No Restrction Found' }}
              hideFooter={data?.length === 0}
              getRowClassName={(params) => params?.row?.hierarchy?.length === 1 ? 'datagrid-main-row' : ''}
              pageSizeOptions={[10]}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } }
              }}
              pagination
              sx={(theme) => ({
                [`.${gridClasses.main}`]: {
                  overflow: 'unset'
                },
                [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                  color: '#74788d',
                  fontWeight: 600
                },
                [`.${gridClasses.columnHeaders}`]: {
                  position: 'sticky',
                  top: 0,
                  zIndex: 3,
                  backgroundColor: 'white'
                },
                [`.${gridClasses.footerContainer}`]: {
                  position: 'sticky',
                  bottom: 0,
                  zIndex: 3,
                  backgroundColor: 'white'
                },
              })}
            />
        }
      </Box>
    </>
  )
}

const CustomGridTreeDataGroupingCell = (props) => {
  const { id, field, rowNode } = props
  const apiRef = useGridApiContext()
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector,
  )

  const handleClick = (event) => {
    if (rowNode.type !== 'group') {
      return
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
    apiRef.current.setCellFocus(id, field)
    event.stopPropagation()
  }

  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0

  return (
    <Box sx={{ ml: rowNode.depth * 2 }}>
      <div>
        {filteredDescendantCount > 0 && props?.row?.hierarchy?.length === 1 ? (
          <>
            <IconButton
              onClick={handleClick}
              size="small"
              tabIndex={-1}
              aria-label={rowNode.childrenExpanded ? 'Close' : 'Open'}
            >
              <ExpandMore
                sx={{
                  transform: `rotateZ(${rowNode.childrenExpanded ? 360 : 270}deg)`,
                  transition: (theme) =>
                    theme.transitions.create('transform', {
                      duration: theme.transitions.duration.shortest,
                    }),
                }}
                fontSize="inherit"
              />
            </IconButton>
            <span style={{ marginLeft: rowNode.depth * 2 }}>
              {props?.row?.accountName}
            </span>
          </>
        ) : (
          <span style={{ marginLeft: rowNode.depth * 12 }}>{props?.row?.instrName}</span>
        )}
      </div>
    </Box>
  );
}

export default RestrictionGroupTable