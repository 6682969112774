import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { addEdge, Background, BackgroundVariant, ControlButton, Controls, MarkerType, ReactFlow, ReactFlowProvider, useEdgesState, useNodesState, useReactFlow } from '@xyflow/react'
import { Download, Edit, Refresh, Save, WorkspacesOutlined } from '@mui/icons-material'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { downloadImageFromHtml } from '../../../../utils/HTMLToImageUtil'
import clickIcon from './../../../../assets/images/icon-click.svg'
import CreateNewNodeDrawer from './CreateNewNodeDrawer'
import Sidebar from './DragAndDropSidebar'
import EdgeCustomizationDrawer from './EdgeCustomizationDrawer'
import CustomEdge from './edges/WorkflowEdge'
import { getId, getNodePositionInsideParent, sortNodes } from './helper/reactFlowChartUtils'
import useExpandCollapse from './helper/useExpandCollapse'
import BorderedNode from './nodes/BorderedNode'
import DefaultNode from './nodes/DefaultNode'
import IPSNode from './nodes/IPSNode'
import SelectedNodesToolbar from './SelectedNodesToolbar'
import UpdateNodeAndGrpDrawer from './UpdateNodeAndGrpDrawer'
import GroupNode from './nodes/GroupNode'

const nodeTypes = {
  default: DefaultNode,
  account: BorderedNode,
  IPS: IPSNode,
  node: DefaultNode,
  group: GroupNode
}

const edgeTypes = {
  default: (props) => <CustomEdge {...props} type='default' />,
  workflow: (props) => <CustomEdge {...props} type='workflow' />,
  Bezier: (props) => <CustomEdge {...props} type='Bezier' />,
  SimpleBezier: (props) => <CustomEdge {...props} type='SimpleBezier' />,
  Straight: (props) => <CustomEdge {...props} type='Straight' />,
  SmoothStep: (props) => <CustomEdge {...props} type='SmoothStep' />
}

const groupOptions = [
  {
    id: 1, group: 'washsales', name: 'Wash Sales', value: 'washsales'
  },
  {
    id: 2, group: 'settlement', name: 'Settlement', value: 'settlement'
  },
  {
    id: 3, group: 'asset-allocation', name: 'Asset Allocation', value: 'asset-allocation'
  },
  {
    id: 4, group: 'cash-program', name: 'Cash Program', value: 'cash-program'
  }
]

const requestOptions = [
  {
    id: 1, name: 'Cash Raise', value: 'cash-raise'
  },
  {
    id: 2, name: 'Taxlot Swap', value: 'taxlot-swap'
  },
  {
    id: 3, name: 'Transition Analysis', value: 'transition-analysis'
  },
  {
    id: 4, name: 'Allocation Change', value: 'allocation-change'
  },
  {
    id: 5, name: 'Gain Analysis', value: 'gain-analysis'
  }
]

const onDragOver = (event) => {
  event.preventDefault()
  event.dataTransfer.dropEffect = 'move'
}

const proOptions = { account: 'paid-pro', hideAttribution: true }


function TreeView ({ aggregateData, isInitialChart, updateChart, refreshChart }) {
  // you can access the internal state here
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const [edgeCustomizationDrawer, setEdgeCustomizationDrawer] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [selectedGroups, setSelectedGroups] = useState([])
  const [groupAnchorEl, setGroupAnchorEl] = useState(null)
  const [requestAnchorEl, setRequestAnchorEl] = useState(null)
  const openGroupDropDown = Boolean(groupAnchorEl)
  const openRequestDropDown = Boolean(requestAnchorEl)
  const [newNodeData, setNewNodeData] = useState({})
  const [createNodeType, setCreateNodeType] = useState(null)
  const [updateDrawerOpen, setUpdateDrawerOpen] = useState(false)
  const [rfInstance, setRfInstance] = useState(null)
  const [currentNode, setCurrentNode] = useState({})
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [currentClickedEdge, setCurrentClickedEdge] = useState(null)
  const params = useParams()
  const navigate = useNavigate()
  const { screenToFlowPosition, getIntersectingNodes, getNodes, getNode } =
    useReactFlow()
  const { nodes: visibleNodes, edges: visibleEdges } = useExpandCollapse(
    nodes,
    edges
  )

  // new edge connection
  const onConnect = useCallback(
    (edge) => setEdges((eds) => addEdge(edge, eds)),
    [setEdges]
  )
 
  // handle external node or group drop
  const onDrop = (event) => {
    event.preventDefault()

    const type = event.dataTransfer.getData('application/reactflow')
    const position = screenToFlowPosition({
      x: event.clientX - 20,
      y: event.clientY - 20
    })
    const nodeDimensions = type === 'group'
      ? { width: 475, height: 200 }
      : {
          width: 325,
          height: 120
        }
    const intersections = getIntersectingNodes({
      x: position.x,
      y: position.y,
      width: 325,
      height: 120
    }).filter((n) => n.type === 'group')
    const groupNode = intersections[0]
    const newNode = {
      id: getId(),
      type,
      position,
      ...nodeDimensions
    }

    if (groupNode) {
      // if we drop a node on a group node, we want to position the node inside the group
      newNode.position = getNodePositionInsideParent(
        {
          position,
          width: 325,
          height: 120
        },
        groupNode
      ) ?? { x: 0, y: 0 }
      newNode.extent = 'parent'
      newNode.parentId = groupNode?.id
      newNode.expandParent = false
    }

    // we need to make sure that the parents are sorted before the children
    // to make sure that the children are rendered on top of the parents
    setNewNodeData(newNode)
    setCreateNodeType('drop-node')
    setDrawerOpen(true)
    // setLayoutOptions({})
  }

  // handle internal node drag event
  const onNodeDragStop = useCallback(
    (_, node) => {
      if (node.type !== 'node' && node.type !== 'default' && node.type !== 'IPS' && node.type !== 'account' && !node.parentId) {
        return
      }

      const intersections = getIntersectingNodes(node).filter(
        (n) => n.type === 'group'
      )
      const groupNode = intersections[0]

      // when there is an intersection on drag stop, we want to attach the node to its new parent
      if (intersections.length && node.parentId !== groupNode?.id) {
        const nextNodes = getNodes()
          .map((n) => {
            if (n.id === groupNode.id) {
              return {
                ...n,
                className: ''
              }
            } else if (n.id === node.id) {
              const position = getNodePositionInsideParent(n, groupNode) ?? {
                x: 0,
                y: 0
              }

              return {
                ...n,
                position,
                parentId: groupNode.id,
                extent: 'parent'
              }
            }

            return n
          })
          .sort(sortNodes)

        setNodes(nextNodes)
      }
      // setLayoutOptions({})
    },
    [getIntersectingNodes, getNodes, setNodes]
  )

  const onNodeDrag = useCallback(
    (_, node) => {
      if (node.type !== 'node' && node.type !== 'default' && node.type !== 'IPS' && node.type !== 'account' && !node.parentId) {
        return
      }

      const intersections = getIntersectingNodes(node).filter(
        (n) => n.type === 'group'
      )
      const groupClassName =
        intersections.length && node.parentId !== intersections[0]?.id
          ? 'active'
          : ''

      setNodes((nds) => {
        return nds.map((n) => {
          if (n.type === 'group') {
            return {
              ...n,
              className: groupClassName
            }
          } else if (n.id === node.id) {
            return {
              ...n,
              position: node.position
            }
          }

          return { ...n }
        })
      })
      // setLayoutOptions({})
    },
    [getIntersectingNodes, setNodes]
  )

  // generate required node format for data rendering
  const createNodeHTML = (nodeData) => {
    const node = {
      data: {
        nodeData: nodeData?.nodeInfo,
        expanded: true,
        isNode: nodeData?.type!=='group'
      },
      x: undefined, 
      y: undefined
    }
    if (nodeData?.parentNode) {
      node.parentId = nodeData?.parentNode
    }
    if (nodeData?.nodeInfo?.category === 'account') {
      node.type = 'account'
    }
    return node
  }

  // calculate the initial layout on mount.
  useEffect(() => {
    setNodes(aggregateData?.nodes?.map(node => ({
      ...node,
      ...createNodeHTML(node)
    })))
    setEdges(aggregateData?.edges?.map(edge => ({ ...edge, type: 'workflow' })))
  }, [aggregateData, setEdges, setNodes])


  const handleGroupFilterClick = (event) => {
    document.body.classList.add('model-open')
    setGroupAnchorEl(event.currentTarget)
  }

  const handleGroupFilterClose = () => {
    document.body.classList.remove('model-open')
    setGroupAnchorEl(null)
  }

  const handleRequestFilterClick = (event) => {
    document.body.classList.add('model-open')
    setRequestAnchorEl(event.currentTarget)
  }
  const handleRequestFilterClose = () => {
    document.body.classList.remove('model-open')
    setRequestAnchorEl(null)
  }

  const handleGroupFilterChange = (value) => {
    if (['washsales', 'settlement'].includes(value)) {
      const nextList = selectedGroups.filter(group => group === value)?.length ? selectedGroups.filter(group => group !== value) : [value]
      setSelectedGroups(nextList)
      setNodes((nds) =>
        nds.map((node) => {
          if (nextList.length) {
            if (nextList?.some(selectedGroup => node?.nodeInfo?.group?.includes(selectedGroup))) {
              // it's important that you create a new object here
              // in order to notify react flow about the change
              node.style = { ...node.style, opacity: 1 }
            } else {
              node.style = { ...node.style, opacity: 0.2 }
            }
          } else {
            node.style = { ...node.style, opacity: 1 }
          }

          return node
        })
      )
    }
  }

  // store latest updates of flowchart
  const onSave = useCallback(() => {
    if (rfInstance) {
      const flow = rfInstance.toObject()
      flow.nodes.forEach(node => {
        node.data = null
      })
      try {
        // updateChart(flow, true)
      } catch (err) {
        console.error(
          'Unable to copy to clipboard.',
          err
        )
      } finally {
        setIsEditMode(false)
      }
    }
  }, [rfInstance])

  // by clicking on refresh icon will get latest flow chart data
  const onRefresh = useCallback(() => {
    refreshChart()
  }, [])

  const onNodeDoubleClick = (e, node) => {
    e.preventDefault()
    if (e.detail === 2 && !isEditMode) {
      if (node?.nodeInfo?.category?.toUpperCase() === 'IPS') {
        navigate(`/aggregate/aggregate-dashboard/${node?.nodeInfo?.id}`)
      } else if (node?.nodeInfo?.category?.toUpperCase() === 'ACCOUNTS' && node?.nodeInfo?.tagged) {
        navigate(`/account-review/account-dashboard/${node?.nodeInfo?.id}`)
      }
    }
  }

  const onNodeClick = (e, node) => {
    e.preventDefault()
    if (e?.target?.tagName !== 'svg' && e?.target?.tagName !== 'path' && e?.target?.tagName !== 'BUTTON' && isEditMode) {
      setNodes((nds) =>
        nds.map((n) => {
          if (n.id === node.id) {
            return {
              ...n,
              data: { ...n.data }
            }
          }
          return n
        })
      )
      setCreateNodeType('edit-node')
      setCurrentNode(node)
      setUpdateDrawerOpen(true)
    } else if (e?.target?.tagName === 'BUTTON' || e?.target?.tagName === 'svg' || e?.target?.tagName === 'path'){
      setNodes((nds) =>
        nds.map((n) => {
          if (n.id === node.id) {
            return {
              ...n,
              data: { ...n.data, expanded: !n.data.expanded }
            }
          }
          return n
        })
      )
    }
  }

  const onEdgeClick = (event, edge) => {
    event.preventDefault()
    if (isEditMode) {
      if (event.target.innerHTML === '+') {
        // we retrieve the target node to get its position
        const targetNode = getNode(edge.target)

        if (!targetNode) {
          return
        }

        // create a unique id for newly added elements
        const insertNodeId = randomId()

        // this is the node object that will be added in between source and target node
        const newNode = {
          id: insertNodeId,
          // we place the node at the current position of the target (prevents jumping)
          position: { x: targetNode.position.x, y: targetNode.position.y },
          type: 'node',
          width: 325,
          height: 120
        }
        setCurrentClickedEdge(edge)
        setCreateNodeType('edge-click-node')
        setNewNodeData(newNode)
        setDrawerOpen(true)
      } else {
        setEdgeCustomizationDrawer(true)
        setCurrentClickedEdge(edge)
      }
    }
  }

  const findDescendants = (nodeId, edges) => {
    const descendants = []
    const stack = [nodeId]

    while (stack.length > 0) {
      const currentId = stack.pop()
      const childEdges = edges.filter(edge => edge.source === currentId)

      childEdges.forEach(edge => {
        descendants.push(edge.target)
        stack.push(edge.target)
      })
    }

    return descendants
  }

  const handleNodeChanges = (nodeData) => {
    if (nodeData) {
      if (createNodeType === 'drop-node') {
        const sortedNodes = getNodes().concat(nodeData).sort(sortNodes)
        setNodes(sortedNodes)
      } else if (createNodeType === 'edge-click-node') {
        // new connection from source to new node
        const sourceEdge = {
          id: `${currentClickedEdge?.source}->${nodeData?.id}`,
          source: currentClickedEdge?.source,
          target: nodeData?.id,
          type: 'workflow',
          style: {
            stroke: 'skyblue',
            strokeWidth: 3
          },
          markerEnd:
            { type: MarkerType.Arrow, color: 'skyblue' }
        }

        // new connection from new node to target
        const targetEdge = {
          id: `${nodeData?.id}->${currentClickedEdge?.target}`,
          source: nodeData?.id,
          target: currentClickedEdge?.target,
          type: 'workflow',
          style: {
            stroke: 'skyblue',
            strokeWidth: 3
          },
          markerEnd:
            { type: MarkerType.Arrow, color: 'skyblue' }
        }
        const targetNodeIndex = nodes.findIndex(
          (node) => node.id === currentClickedEdge?.target
        )
        // remove the edge that was clicked as we have a new connection with a node inbetween
        const updatedEdges = edges.filter((e) => e.id !== currentClickedEdge?.id).concat([sourceEdge, targetEdge])
        // insert the node between the source and target node in the react flow state
        const updatedNodes = [
          ...nodes.slice(0, targetNodeIndex),
          nodeData,
          ...nodes.slice(targetNodeIndex, nodes.length)
        ]
        const childNodes = findDescendants(nodeData?.id, updatedEdges)
        setNodes(updatedNodes.map(node => {
          if (childNodes.includes(node.id)) {
            return {
              ...node,
              y: node.y + 120 + 150,
              position: {
                ...node?.position,
                y: node?.position?.y + 120 + 150
              }
            }
          }
          return node
        }))
        setEdges(updatedEdges)
      } else if (createNodeType === 'edit-node') {
        setNodes((nds) =>
          nds.map((n) => {
            if (n?.id === currentNode?.id) {
              return nodeData
            }
            return n
          })
        )
      }
    } else {
      setNewNodeData(null)
      setCreateNodeType(null)
      setCurrentClickedEdge(null)
    }
  }

  // const handleNodesReset = async () => {
  //   const graph = {
  //     id: 'elk-root',
  //     layoutOptions: {
  //       'elk.algorithm': 'mrtree',
  //       'elk.direction': 'DOWN',
  //       'elk.spacing.nodeNode': 160
  //     },
  //     children: visibleNodes?.map((node) => ({
  //       id: node.id,
  //       width: node.measured?.width ?? 0,
  //       height: node.measured?.height ?? 0
  //     })),
  //     edges: visibleEdges?.map((edge) => ({
  //       id: edge.id,
  //       sources: [edge.source],
  //       targets: [edge.target]
  //     }))
  //   }

  //   const root = await elk.layout(graph)

  //   const layoutNodes = new Map()
  //   for (const node of root?.children ?? []) {
  //     layoutNodes.set(node.id, node)
  //   }

  //   const nextNodes = nodes?.map((node) => {
  //     const elkNode = layoutNodes?.get(node.id)
  //     const position = { x: elkNode?.x, y: elkNode?.y }

  //     return {
  //       ...node,
  //       position
  //     }
  //   })

  //   for (const node of nextNodes) {
  //     node.style = { ...node.style, opacity: 1 }
  //     node.sourcePosition = Position.Bottom
  //     node.targetPosition = Position.Top
  //   }

  //   for (const edge of edges) {
  //     edge.style = { ...edge.style, opacity: 1 }
  //   }

  //   setNodes(nextNodes)
  //   setEdges(edges)
  // }

  return (
    <>

      {isEditMode ? <Sidebar /> : ''}
      {drawerOpen && <CreateNewNodeDrawer newNodeData={newNodeData} setNodes={setNodes} setDrawerOpen={setDrawerOpen} drawerOpen={drawerOpen} handleNodeChanges={handleNodeChanges} />}
      {updateDrawerOpen && <UpdateNodeAndGrpDrawer setUpdateDrawerOpen={setUpdateDrawerOpen} updateDrawerOpen={updateDrawerOpen} handleNodeChanges={handleNodeChanges} currentNode={currentNode} />}
      {edgeCustomizationDrawer && <EdgeCustomizationDrawer
        open={edgeCustomizationDrawer}
        currentCustomizationEdge={currentClickedEdge}
        setEdges={setEdges}
        setEdgeCustomizationDrawer={setEdgeCustomizationDrawer}
                                  />}

      <ReactFlow
        className='dynamic-tree'
        nodes={visibleNodes}
        edges={visibleEdges}
        proOptions={proOptions}
        onEdgesChange={onEdgesChange}
        onNodesChange={onNodesChange}
        onNodeClick={(event, node) => onNodeClick(event, node)}
        onEdgeClick={(event, edge) => onEdgeClick(event, edge)}
        onNodeDoubleClick={(event, edge) => onNodeDoubleClick(event, edge)}
        nodesDraggable={isEditMode}
        nodesConnectable={isEditMode}
        onConnect={onConnect}
        onNodeDrag={onNodeDrag}
        onNodeDragStop={onNodeDragStop}
        onDrop={onDrop}
        onInit={setRfInstance}
        onDragOver={onDragOver}
        fitView
        selectNodesOnDrag
        zoomOnDoubleClick={false}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        defaultEdgeOptions={{
          type: 'workflow',
          style: {
            stroke: 'skyblue',
            strokeWidth: 3
          },
          markerEnd:
          { type: MarkerType.Arrow, color: 'skyblue' }
        }}
      >
        <SelectedNodesToolbar />
        <Controls showInteractive={false} position='top-right'>
          {!isEditMode ? <>
            <ControlButton>
              <IconButton
                aria-label='more'
                id='long-button'
                aria-controls={openGroupDropDown ? 'long-menu' : undefined}
                aria-expanded={openGroupDropDown ? 'true' : undefined}
                aria-haspopup='true'
                onClick={handleGroupFilterClick}
              >
                <WorkspacesOutlined
                  aria-label='more'
                  id='long-button'
                  aria-controls={openGroupDropDown ? 'long-menu' : undefined}
                  aria-expanded={openGroupDropDown ? 'true' : undefined}
                  aria-haspopup='true'
                  onClick={handleGroupFilterClick} />
              </IconButton>
              <Menu
                id='long-menu'
                MenuListProps={{
                  'aria-labelledby': 'long-button'
                }}
                anchorEl={groupAnchorEl}
                open={openGroupDropDown}
                onClose={handleGroupFilterClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              >
                {groupOptions.map((option) => (
                  <MenuItem key={option.id} selected={selectedGroups.includes(option.value)} onClick={() => handleGroupFilterChange(option.value)}>
                    {option.name}
                  </MenuItem>
                ))}
              </Menu>
            </ControlButton>
            <ControlButton title='view request'>
              <IconButton
                aria-label='more'
                id='long-button'
                aria-controls={openRequestDropDown ? 'long-menu' : undefined}
                aria-expanded={openRequestDropDown ? 'true' : undefined}
                aria-haspopup='true'
                onClick={handleRequestFilterClick}
              >
                <img src={clickIcon} alt='' height={14} width={14} />
              </IconButton>
              <Menu
                id='long-menu'
                MenuListProps={{
                  'aria-labelledby': 'long-button'
                }}
                anchorEl={requestAnchorEl}
                open={openRequestDropDown}
                onClose={handleRequestFilterClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              >
                {requestOptions.map((option) => (
                  <MenuItem key={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Menu>
            </ControlButton>
            <ControlButton onClick={() => onRefresh()} title='refresh chart'>
              <Refresh fontSize='14px' />
            </ControlButton>
            <ControlButton onClick={() => downloadImageFromHtml('.react-flow__viewport', (nodes?.length ? nodes[0]?.name : params?.familyId), 'png')} title='download image'>
              <Download fontSize='14px' />
            </ControlButton>
          </>
            : ''
          }
          <ControlButton onClick={() => isEditMode ? onSave() : setIsEditMode(true)}>
            {isEditMode ? <Save fontSize='14px' title='save changes' /> : <Edit fontSize='14px' title='edit chart' />}
          </ControlButton>
        </Controls>
        <Background variant={BackgroundVariant.Dots} />
      </ReactFlow>

    </>
  )
}

const TreeComponent = ({ aggregateData, isInitialChart, updateChart, refreshChart }) => {
  return (
    <ReactFlowProvider>
      <TreeView aggregateData={aggregateData} isInitialChart={isInitialChart} updateChart={updateChart} refreshChart={refreshChart} />
    </ReactFlowProvider>
  )
}

export default TreeComponent
