export const harryAndMarryStaticData = {
  "nodes": [
    {
      "id": "c6d35000-05b7-5449-a105-34bdc31d4d4e",
      "data": null,
      "name": "Callan's Family",
      "width": 300,
      "height": 100,
      "dragging": false,
      "nodeInfo": {
        "id": "c6d35000-05b7-5449-a105-34bdc31d4d4e",
        "name": "Callan's Family",
        "totalRisk": 12,
        "marketValue": 28900000
      },
      "position": {
        "x": 972.6567034823264,
        "y": -105.20345221232422
      },
      "selected": false,
      "positionAbsolute": {
        "x": 972.6567034823264,
        "y": -105.20345221232422
      },
      "measured": {
        "width": 300,
        "height": 120
      },
      "hidden": false
    },
    {
      "id": "d2527c72-8c60-597b-895f-8677cc0e9811",
      "data": null,
      "name": "Harry and marry callan",
      "width": 300,
      "height": 100,
      "dragging": false,
      "nodeInfo": {
        "id": "d2527c72-8c60-597b-895f-8677cc0e9811",
        "name": "Harry and marry callan",
        "totalRisk": 12,
        "marketValue": 28900000
      },
      "position": {
        "x": 257.7887435220231,
        "y": 127.92770989902158
      },
      "selected": false,
      "positionAbsolute": {
        "x": 257.7887435220231,
        "y": 127.92770989902158
      },
      "measured": {
        "width": 300,
        "height": 120
      },
      "hidden": false
    },
    {
      "id": "4c39f7cd-4404-5bc4-84fa-01e079d91e61",
      "data": null,
      "name": "Harry Callan",
      "style": {
        "color": "blue",
        "borderColor": "blue",
        "backgroundColor": "transparent"
      },
      "width": 300,
      "height": 100,
      "dragging": false,
      "nodeInfo": {
        "id": "4c39f7cd-4404-5bc4-84fa-01e079d91e61",
        "name": "Harry Callan",
        "color": "blue",
        "totalRisk": 12,
        "marketValue": 28900000
      },
      "position": {
        "x": 64.05180775191548,
        "y": 356.955779428533
      },
      "selected": false,
      "positionAbsolute": {
        "x": 66.05180775191548,
        "y": 324.955779428533
      },
      "measured": {
        "width": 300,
        "height": 120
      },
      "hidden": false
    },
    {
      "id": "0d544534-8e72-579f-b46a-0102e4628965",
      "data": null,
      "name": "Marry Callan",
      "style": {
        "color": "green",
        "borderColor": "green",
        "backgroundColor": "transparent"
      },
      "width": 300,
      "height": 100,
      "dragging": false,
      "nodeInfo": {
        "id": "0d544534-8e72-579f-b46a-0102e4628965",
        "name": "Marry Callan",
        "color": "green",
        "totalRisk": 12,
        "marketValue": 28900000
      },
      "position": {
        "x": 459.00479006229625,
        "y": 353.5380961169024
      },
      "selected": false,
      "positionAbsolute": {
        "x": 463.00479006229625,
        "y": 321.5380961169024
      },
      "measured": {
        "width": 300,
        "height": 130
      },
      "hidden": false
    },
    {
      "id": "daffcbbf-02be-5ff3-a395-d22f43afd6db",
      "data": null,
      "name": "Clara Callan+Spouse and minor child(Austin)",
      "width": 300,
      "height": 122,
      "dragging": false,
      "nodeInfo": {
        "id": "daffcbbf-02be-5ff3-a395-d22f43afd6db",
        "name": "Clara Callan+Spouse and minor child(Austin)",
        "totalRisk": 12,
        "marketValue": 28900000
      },
      "position": {
        "x": 977.2527195133188,
        "y": 124.47317611116848
      },
      "selected": false,
      "positionAbsolute": {
        "x": 977.2527195133188,
        "y": 124.47317611116848
      },
      "measured": {
        "width": 300,
        "height": 130
      },
      "hidden": false
    },
    {
      "id": "b7e6cd87-163f-5796-8138-91eb63ca90e0",
      "data": null,
      "name": "Clara Callan",
      "style": {
        "color": "purple",
        "borderColor": "purple",
        "backgroundColor": "transparent"
      },
      "width": 300,
      "height": 100,
      "dragging": false,
      "nodeInfo": {
        "id": "b7e6cd87-163f-5796-8138-91eb63ca90e0",
        "name": "Clara Callan",
        "color": "purple",
        "totalRisk": 12,
        "marketValue": 28900000
      },
      "position": {
        "x": 810.0807652108967,
        "y": 353.5380961169024
      },
      "selected": false,
      "positionAbsolute": {
        "x": 810.0807652108967,
        "y": 321.5380961169024
      },
      "measured": {
        "width": 300,
        "height": 120
      },
      "hidden": false
    },
    {
      "id": "49972796-42a3-50a7-82d7-90192dbd3daf",
      "data": null,
      "name": "Austin Callan",
      "style": {
        "color": "red",
        "borderColor": "red",
        "backgroundColor": "transparent"
      },
      "width": 300,
      "height": 100,
      "dragging": false,
      "nodeInfo": {
        "id": "49972796-42a3-50a7-82d7-90192dbd3daf",
        "name": "Austin Callan",
        "color": "red",
        "totalRisk": 12,
        "marketValue": 28900000
      },
      "position": {
        "x": 1251.5520717154095,
        "y": 351.25799291558434
      },
      "selected": false,
      "positionAbsolute": {
        "x": 1251.5520717154095,
        "y": 323.25799291558445
      },
      "measured": {
        "width": 300,
        "height": 120
      },
      "hidden": false
    },
    {
      "id": "b44936f3-707a-52dc-838d-e9dad1dbd202",
      "data": null,
      "name": "Elizabeth Callan",
      "width": 300,
      "height": 100,
      "dragging": false,
      "nodeInfo": {
        "id": "b44936f3-707a-52dc-838d-e9dad1dbd202",
        "name": "Elizabeth Callan",
        "totalRisk": 12,
        "marketValue": 28900000
      },
      "position": {
        "x": 1649.5874375858236,
        "y": 136.50876865432076
      },
      "selected": false,
      "positionAbsolute": {
        "x": 1649.5874375858236,
        "y": 136.50876865432076
      },
      "measured": {
        "width": 300,
        "height": 120
      },
      "hidden": false
    },
    {
      "id": "e727a62a-5cfd-55ba-9e8e-a0dcf81631ab",
      "data": null,
      "name": "Elizabeth Callan",
      "style": {
        "color": "orange",
        "borderColor": "orange",
        "backgroundColor": "transparent"
      },
      "width": 300,
      "height": 100,
      "dragging": false,
      "nodeInfo": {
        "id": "e727a62a-5cfd-55ba-9e8e-a0dcf81631ab",
        "name": "Elizabeth Callan",
        "color": "orange",
        "totalRisk": 12,
        "marketValue": 28900000
      },
      "position": {
        "x": 1653.1647231310988,
        "y": 353.8476796991983
      },
      "selected": false,
      "positionAbsolute": {
        "x": 1653.1647231310988,
        "y": 319.8476796991983
      },
      "measured": {
        "width": 300,
        "height": 120
      },
      "hidden": false
    },
    {
      "id": "D",
      "data": null,
      "style": {
        "width": 2200,
        "border": "4px dashed orange",
        "height": "300px",
        "backgroundColor": "transparent"
      },
      "type": "group",
      "width": 2196,
      "height": 382,
      "dragging": false,
      "nodeInfo": {
        "id": "D",
        "isTaxable": true,
        "totalRisk": 12
      },
      "position": {
        "x": -22.797177043165902,
        "y": 579.0537479578896
      },
      "selected": false,
      "positionAbsolute": {
        "x": -20.797177043165902,
        "y": 555.0537479578896
      },
      "measured": {
        "width": 2196,
        "height": 382
      },
      "className": "",
      "hidden": false,
      "resizing": false
    },
    {
      "id": "E",
      "data": null,
      "name": "Harry Rev Tr",
      "width": 300,
      "extent": "parent",
      "height": 160,
      "dragging": false,
      "nodeInfo": {
        "id": "E",
        "name": "Harry Rev Tr",
        "group": [
          "washsales",
          "settlement"
        ],
        "isTaxable": true,
        "totalRisk": 12,
        "parentData": [
          {
            "id": "4c39f7cd-4404-5bc4-84fa-01e079d91e61",
            "name": "Harry Callan",
            "color": "blue",
            "allocation": "100%"
          }
        ],
        "marketValue": 28900000
      },
      "position": {
        "x": 38.44555294624843,
        "y": 62.08772234277785
      },
      "selected": false,
      "parentId": "D",
      "positionAbsolute": {
        "x": 21.648375903082524,
        "y": 607.1414703006675
      },
      "measured": {
        "width": 300,
        "height": 160
      },
      "hidden": false
    },
    {
      "id": "F",
      "data": null,
      "name": "H+M Jt.Acct",
      "width": 300,
      "extent": "parent",
      "height": 160,
      "dragging": false,
      "nodeInfo": {
        "id": "F",
        "name": "H+M Jt.Acct",
        "group": [
          "washsales"
        ],
        "isTaxable": true,
        "totalRisk": 12,
        "parentData": [
          {
            "id": "0d544534-8e72-579f-b46a-0102e4628965",
            "name": "Marry Callan",
            "color": "green",
            "allocation": "100%"
          }
        ],
        "marketValue": 28900000
      },
      "position": {
        "x": 409.1865719589322,
        "y": 58.19268733278511
      },
      "selected": true,
      "parentId": "D",
      "positionAbsolute": {
        "x": 390.3893949157663,
        "y": 611.2464352906748
      },
      "measured": {
        "width": 300,
        "height": 160
      },
      "hidden": false
    },
    {
      "id": "G",
      "data": null,
      "name": "Callan FLP",
      "width": 300,
      "extent": "parent",
      "height": 260,
      "dragging": false,
      "nodeInfo": {
        "id": "G",
        "name": "Callan FLP",
        "group": [
          "settlement"
        ],
        "isTaxable": false,
        "totalRisk": 12,
        "parentData": [
          {
            "id": "4c39f7cd-4404-5bc4-84fa-01e079d91e61",
            "name": "Harry Callan",
            "color": "blue",
            "allocation": "100%"
          },
          {
            "id": "0d544534-8e72-579f-b46a-0102e4628965",
            "name": "Marry Callan",
            "color": "green",
            "allocation": "100%"
          },
          {
            "id": "e727a62a-5cfd-55ba-9e8e-a0dcf81631ab",
            "name": "Elizabeth Callan",
            "color": "orange",
            "allocation": "100%"
          },
          {
            "id": "49972796-42a3-50a7-82d7-90192dbd3daf",
            "name": "Austin Callan",
            "color": "red",
            "allocation": "100%"
          },
          {
            "id": "b7e6cd87-163f-5796-8138-91eb63ca90e0",
            "name": "Clara Callan",
            "color": "purple",
            "allocation": "100%"
          }
        ],
        "marketValue": 28900000
      },
      "position": {
        "x": 780.1529667373902,
        "y": 46
      },
      "selected": false,
      "parentId": "D",
      "positionAbsolute": {
        "x": 765.3557896942243,
        "y": 597.481056307122
      },
      "measured": {
        "width": 300,
        "height": 300
      },
      "hidden": false
    },
    {
      "id": "H",
      "data": null,
      "name": "Trust FBO Clara",
      "width": 300,
      "extent": "parent",
      "height": 180,
      "dragging": false,
      "nodeInfo": {
        "id": "H",
        "name": "Trust FBO Clara",
        "group": [
          "washsales",
          "settlement"
        ],
        "isTaxable": true,
        "totalRisk": 12,
        "parentData": [
          {
            "id": "0d544534-8e72-579f-b46a-0102e4628965",
            "name": "Marry Callan",
            "color": "green",
            "allocation": "75%"
          },
          {
            "id": "b7e6cd87-163f-5796-8138-91eb63ca90e0",
            "name": "Clara Callan",
            "color": "purple",
            "allocation": "100%"
          }
        ],
        "marketValue": 28900000
      },
      "position": {
        "x": 1149.1921560767544,
        "y": 53.63006515076745
      },
      "selected": false,
      "parentId": "D",
      "positionAbsolute": {
        "x": 1126.3949790335885,
        "y": 606.6838131086571
      },
      "measured": {
        "width": 300,
        "height": 180
      },
      "hidden": false
    },
    {
      "id": "I",
      "data": null,
      "name": "Callan GST Irrev Tr",
      "width": 300,
      "extent": "parent",
      "height": 180,
      "dragging": false,
      "nodeInfo": {
        "id": "I",
        "name": "Callan GST Irrev Tr",
        "group": [
          "settlement"
        ],
        "isTaxable": true,
        "totalRisk": 12,
        "parentData": [
          {
            "id": "4c39f7cd-4404-5bc4-84fa-01e079d91e61",
            "name": "Harry Callan",
            "color": "blue",
            "allocation": "25%"
          },
          {
            "id": "49972796-42a3-50a7-82d7-90192dbd3daf",
            "name": "Austin Callan",
            "color": "red",
            "allocation": "100%"
          }
        ],
        "marketValue": 28900000
      },
      "position": {
        "x": 1509.212939318972,
        "y": 52.40926705243123
      },
      "selected": false,
      "parentId": "D",
      "positionAbsolute": {
        "x": 1498.4157622758062,
        "y": 609.4630150103209
      },
      "measured": {
        "width": 300,
        "height": 180
      },
      "hidden": false
    },
    {
      "id": "J",
      "data": null,
      "name": "Trust FBO Elizabeth",
      "width": 300,
      "extent": "parent",
      "height": 180,
      "dragging": false,
      "nodeInfo": {
        "id": "J",
        "name": "Trust FBO Elizabeth",
        "group": [
          "washsales"
        ],
        "isTaxable": true,
        "totalRisk": 12,
        "parentData": [
          {
            "id": "e727a62a-5cfd-55ba-9e8e-a0dcf81631ab",
            "name": "Elizabeth Callan",
            "color": "orange",
            "allocation": "25%"
          },
          {
            "id": "0d544534-8e72-579f-b46a-0102e4628965",
            "name": "Marry Callan",
            "color": "green",
            "allocation": "75%"
          }
        ],
        "marketValue": 28900000
      },
      "position": {
        "x": 1864,
        "y": 51.92792759661552
      },
      "selected": false,
      "parentId": "D",
      "positionAbsolute": {
        "x": 1853.202822956834,
        "y": 608.9816755545052
      },
      "measured": {
        "width": 300,
        "height": 180
      },
      "hidden": false
    },
    {
      "id": "K",
      "data": null,
      "style": {
        "width": "350px",
        "border": "1px solid rgba(116, 120, 141, 0.3)",
        "height": 360,
        "boxShadow": "0px 7px 29px 0px rgba(100, 100, 111, 0.2)",
        "backgroundColor": "transparent"
      },
      "type": "group",
      "width": 346,
      "height": 454,
      "dragging": false,
      "nodeInfo": {
        "id": "K",
        "groupType": "strategy",
        "isTaxable": true,
        "totalRisk": 12,
        "parentAccountData": [
          {
            "id": "4c39f7cd-4404-5bc4-84fa-01e079d91e61",
            "name": "Harry Callan",
            "color": "blue",
            "allocation": "25%"
          }
        ]
      },
      "position": {
        "x": -20.5675506304824,
        "y": 1071.7032785635965
      },
      "selected": false,
      "positionAbsolute": {
        "x": -6.567550630482401,
        "y": 981.7032785635964
      },
      "measured": {
        "width": 346,
        "height": 454
      },
      "resizing": false,
      "className": "",
      "hidden": false
    },
    {
      "id": "L",
      "data": null,
      "name": "R1J 40,Coho 20, stoxx intl 20",
      "width": 300,
      "height": 100,
      "dragging": false,
      "nodeInfo": {
        "id": "L",
        "name": "R1J 40,Coho 20, stoxx intl 20",
        "group": [
          "washsales",
          "settlement"
        ],
        "nodeType": "strategy",
        "isTaxable": true,
        "allocation": "25%",
        "marketValue": 2500000,
        "trackingError": 12,
        "parentAccountData": [
          {
            "id": "4c39f7cd-4404-5bc4-84fa-01e079d91e61",
            "name": "Harry Callan",
            "color": "blue",
            "allocation": "25%"
          }
        ]
      },
      "position": {
        "x": 24,
        "y": 21
      },
      "selected": false,
      "parentId": "K",
      "positionAbsolute": {
        "x": 17.4324493695176,
        "y": 1002.7032785635964
      },
      "measured": {
        "width": 300,
        "height": 120
      },
      "hidden": false
    },
    {
      "id": "M",
      "data": null,
      "name": "R1J 40,Coho 20, stoxx intl 20",
      "width": 300,
      "height": 100,
      "dragging": false,
      "nodeInfo": {
        "id": "M",
        "name": "R1J 40,Coho 20, stoxx intl 20",
        "group": [
          "settlement"
        ],
        "nodeType": "strategy",
        "isTaxable": true,
        "allocation": "25%",
        "marketValue": 2500000,
        "trackingError": 12
      },
      "position": {
        "x": 25.630749999999978,
        "y": 165.61499999999967
      },
      "selected": false,
      "parentId": "K",
      "positionAbsolute": {
        "x": 19.063199369517577,
        "y": 1111.3182785635963
      },
      "measured": {
        "width": 300,
        "height": 120
      },
      "hidden": false
    },
    {
      "id": "O",
      "data": null,
      "name": "R1J 40,Coho 20, stoxx intl 20",
      "width": 300,
      "height": 100,
      "dragging": false,
      "nodeInfo": {
        "id": "O",
        "name": "R1J 40,Coho 20, stoxx intl 20",
        "group": [
          "settlement"
        ],
        "nodeType": "strategy",
        "isTaxable": true,
        "allocation": "25%",
        "marketValue": 2500000,
        "trackingError": 12
      },
      "position": {
        "x": 22.00000000000017,
        "y": 308.2614999999997
      },
      "selected": false,
      "parentId": "K",
      "positionAbsolute": {
        "x": 15.43244936951777,
        "y": 1217.9647785635964
      },
      "measured": {
        "width": 300,
        "height": 120
      },
      "hidden": false
    },
    {
      "id": "P",
      "data": null,
      "style": {
        "width": "350px",
        "border": "1px solid rgba(116, 120, 141, 0.3)",
        "height": 130,
        "boxShadow": "0px 7px 29px 0px rgba(100, 100, 111, 0.2)",
        "backgroundColor": "transparent"
      },
      "type": "group",
      "width": 350,
      "height": 148,
      "dragging": false,
      "nodeInfo": {
        "id": "P",
        "groupType": "strategy",
        "isTaxable": true,
        "totalRisk": 12,
        "parentAccountData": [
          {
            "id": "4c39f7cd-4404-5bc4-84fa-01e079d91e61",
            "name": "Harry Callan",
            "color": "green",
            "allocation": "25%"
          },
          {
            "id": "4c39f7cd-4404-5bc4-84fa-01e079d91e61",
            "name": "Harry Callan",
            "color": "purple",
            "allocation": "25%"
          }
        ]
      },
      "position": {
        "x": 359.5681773026315,
        "y": 1075.1357279331141
      },
      "selected": false,
      "positionAbsolute": {
        "x": 367.5681773026315,
        "y": 985.135727933114
      },
      "measured": {
        "width": 350,
        "height": 148
      },
      "className": "",
      "resizing": false,
      "hidden": false
    },
    {
      "id": "Q",
      "data": null,
      "name": "R1J 40,Coho 20, stoxx intl 20",
      "width": 300,
      "height": 100,
      "dragging": false,
      "nodeInfo": {
        "id": "Q",
        "name": "R1J 40,Coho 20, stoxx intl 20",
        "group": [
          "washsales",
          "settlement"
        ],
        "nodeType": "strategy",
        "isTaxable": true,
        "allocation": "25%",
        "marketValue": 2500000,
        "trackingError": 12,
        "parentAccountData": [
          {
            "id": "4c39f7cd-4404-5bc4-84fa-01e079d91e61",
            "name": "Harry Callan",
            "color": "green",
            "allocation": "25%"
          },
          {
            "id": "4c39f7cd-4404-5bc4-84fa-01e079d91e61",
            "name": "Harry Callan",
            "color": "purple",
            "allocation": "25%"
          }
        ]
      },
      "position": {
        "x": 24,
        "y": 13
      },
      "selected": false,
      "parentId": "P",
      "positionAbsolute": {
        "x": 391.5681773026315,
        "y": 998.135727933114
      },
      "measured": {
        "width": 300,
        "height": 120
      },
      "hidden": false
    },
    {
      "id": "T",
      "data": null,
      "style": {
        "width": "350px",
        "border": "1px solid rgba(116, 120, 141, 0.3)",
        "height": 480,
        "boxShadow": "0px 7px 29px 0px rgba(100, 100, 111, 0.2)",
        "backgroundColor": "transparent"
      },
      "type": "group",
      "width": 343,
      "height": 550,
      "dragging": false,
      "nodeInfo": {
        "id": "T",
        "groupType": "strategy",
        "isTaxable": false,
        "totalRisk": 12,
        "parentAccountData": [
          {
            "id": "4c39f7cd-4404-5bc4-84fa-01e079d91e61",
            "name": "Harry Callan",
            "color": "green",
            "allocation": "25%"
          },
          {
            "id": "4c39f7cd-4404-5bc4-84fa-01e079d91e61",
            "name": "Harry Callan",
            "color": "purple",
            "allocation": "25%"
          }
        ]
      },
      "position": {
        "x": 735.0228036215044,
        "y": 1056.4969009813703
      },
      "selected": false,
      "positionAbsolute": {
        "x": 737.0228036215044,
        "y": 982.4969009813703
      },
      "measured": {
        "width": 343,
        "height": 550
      },
      "resizing": false,
      "className": "",
      "hidden": false
    },
    {
      "id": "U",
      "data": null,
      "name": "R1J 40,Coho 20, stoxx intl 20",
      "width": 300,
      "height": 100,
      "dragging": false,
      "nodeInfo": {
        "id": "U",
        "name": "R1J 40,Coho 20, stoxx intl 20",
        "group": [
          "washsales"
        ],
        "nodeType": "strategy",
        "isTaxable": false,
        "allocation": "25%",
        "marketValue": 2500000,
        "trackingError": 12,
        "parentAccountData": [
          {
            "id": "4c39f7cd-4404-5bc4-84fa-01e079d91e61",
            "name": "Harry Callan",
            "color": "green",
            "allocation": "25%"
          },
          {
            "id": "4c39f7cd-4404-5bc4-84fa-01e079d91e61",
            "name": "Harry Callan",
            "color": "purple",
            "allocation": "25%"
          }
        ]
      },
      "position": {
        "x": 28,
        "y": 15
      },
      "selected": false,
      "parentId": "T",
      "positionAbsolute": {
        "x": 763.0228036215044,
        "y": 1007.4969009813703
      },
      "measured": {
        "width": 300,
        "height": 120
      },
      "hidden": false
    },
    {
      "id": "N",
      "data": null,
      "name": "R1J 40,Coho 20, stoxx intl 20",
      "width": 300,
      "height": 100,
      "dragging": false,
      "nodeInfo": {
        "id": "N",
        "name": "R1J 40,Coho 20, stoxx intl 20",
        "group": [
          "washsales",
          "settlement"
        ],
        "nodeType": "strategy",
        "isTaxable": false,
        "allocation": "25%",
        "marketValue": 2500000,
        "trackingError": 12
      },
      "position": {
        "x": 23.261499999999955,
        "y": 279.01449999999977
      },
      "selected": false,
      "parentId": "T",
      "positionAbsolute": {
        "x": 760.2843036215044,
        "y": 1226.51140098137
      },
      "measured": {
        "width": 300,
        "height": 120
      },
      "hidden": false
    },
    {
      "id": "V",
      "data": null,
      "name": "R1J 40,Coho 20, stoxx intl 20",
      "width": 300,
      "height": 100,
      "dragging": false,
      "nodeInfo": {
        "id": "V",
        "name": "R1J 40,Coho 20, stoxx intl 20",
        "group": [
          "washsales",
          "settlement"
        ],
        "nodeType": "strategy",
        "isTaxable": false,
        "allocation": "25%",
        "marketValue": 2500000,
        "trackingError": 12
      },
      "position": {
        "x": 24.26150000000007,
        "y": 147.15249999999992
      },
      "selected": false,
      "parentId": "T",
      "positionAbsolute": {
        "x": 760.2843036215045,
        "y": 1123.6494009813705
      },
      "measured": {
        "width": 300,
        "height": 120
      },
      "hidden": false
    },
    {
      "id": "A",
      "data": null,
      "name": "R1J 40,Coho 20, stoxx intl 20",
      "width": 300,
      "height": 100,
      "nodeInfo": {
        "id": "A",
        "name": "R1J 40,Coho 20, stoxx intl 20",
        "group": [
          "washsales",
          "settlement"
        ],
        "nodeType": "strategy",
        "isTaxable": false,
        "allocation": "25%",
        "marketValue": 2500000,
        "trackingError": 12
      },
      "position": {
        "x": 19.62850771875003,
        "y": 413.3775583125
      },
      "parentId": "T",
      "positionAbsolute": {
        "x": 758.6513113402544,
        "y": 1336.8744592938704
      },
      "measured": {
        "width": 300,
        "height": 120
      },
      "selected": false,
      "dragging": false,
      "hidden": false
    },
    {
      "id": "W",
      "data": null,
      "style": {
        "width": "350px",
        "border": "1px solid rgba(116, 120, 141, 0.3)",
        "height": 130,
        "boxShadow": "0px 7px 29px 0px rgba(100, 100, 111, 0.2)",
        "backgroundColor": "transparent"
      },
      "type": "group",
      "width": 349,
      "height": 155,
      "dragging": false,
      "nodeInfo": {
        "id": "W",
        "groupType": "strategy",
        "isTaxable": true,
        "totalRisk": 12,
        "parentAccountData": [
          {
            "id": "4c39f7cd-4404-5bc4-84fa-01e079d91e61",
            "name": "Harry Callan",
            "color": "green",
            "allocation": "25%"
          },
          {
            "id": "4c39f7cd-4404-5bc4-84fa-01e079d91e61",
            "name": "Harry Callan",
            "color": "purple",
            "allocation": "25%"
          }
        ]
      },
      "position": {
        "x": 1098.7088880857768,
        "y": 1061.0653549892668
      },
      "selected": false,
      "positionAbsolute": {
        "x": 1108.7088880857768,
        "y": 981.0653549892668
      },
      "measured": {
        "width": 349,
        "height": 155
      },
      "resizing": false,
      "className": "",
      "hidden": false
    },
    {
      "id": "X",
      "data": null,
      "name": "R1J 40,Coho 20, stoxx intl 20",
      "width": 300,
      "height": 100,
      "dragging": false,
      "nodeInfo": {
        "id": "X",
        "name": "R1J 40,Coho 20, stoxx intl 20",
        "group": [
          "settlement"
        ],
        "nodeType": "strategy",
        "isTaxable": true,
        "allocation": "25%",
        "marketValue": 2500000,
        "trackingError": 12,
        "parentAccountData": [
          {
            "id": "4c39f7cd-4404-5bc4-84fa-01e079d91e61",
            "name": "Harry Callan",
            "color": "green",
            "allocation": "25%"
          },
          {
            "id": "4c39f7cd-4404-5bc4-84fa-01e079d91e61",
            "name": "Harry Callan",
            "color": "purple",
            "allocation": "25%"
          }
        ]
      },
      "position": {
        "x": 26.45034043388091,
        "y": 13.593300810961296
      },
      "selected": false,
      "parentId": "W",
      "positionAbsolute": {
        "x": 1135.1592285196575,
        "y": 994.658655800228
      },
      "measured": {
        "width": 300,
        "height": 120
      },
      "hidden": false
    },
    {
      "id": "Y",
      "data": null,
      "style": {
        "width": "350px",
        "border": "1px solid rgba(116, 120, 141, 0.3)",
        "height": 130,
        "boxShadow": "0px 7px 29px 0px rgba(100, 100, 111, 0.2)",
        "backgroundColor": "transparent"
      },
      "type": "group",
      "width": 350,
      "height": 160,
      "dragging": false,
      "nodeInfo": {
        "id": "Y",
        "groupType": "strategy",
        "isTaxable": true,
        "totalRisk": 12,
        "parentAccountData": [
          {
            "id": "4c39f7cd-4404-5bc4-84fa-01e079d91e61",
            "name": "Harry Callan",
            "color": "green",
            "allocation": "25%"
          },
          {
            "id": "4c39f7cd-4404-5bc4-84fa-01e079d91e61",
            "name": "Harry Callan",
            "color": "purple",
            "allocation": "25%"
          }
        ]
      },
      "position": {
        "x": 1467.072512617167,
        "y": 1057.3220850295074
      },
      "selected": false,
      "positionAbsolute": {
        "x": 1471.072512617167,
        "y": 977.3220850295075
      },
      "measured": {
        "width": 350,
        "height": 160
      },
      "resizing": false,
      "className": "",
      "hidden": false
    },
    {
      "id": "Z",
      "data": null,
      "name": "R1J 40,Coho 20, stoxx intl 20",
      "width": 300,
      "height": 100,
      "dragging": false,
      "nodeInfo": {
        "id": "Z",
        "name": "R1J 40,Coho 20, stoxx intl 20",
        "group": [
          "washsales"
        ],
        "nodeType": "strategy",
        "isTaxable": true,
        "allocation": "25%",
        "marketValue": 2500000,
        "trackingError": 12,
        "parentAccountData": [
          {
            "id": "4c39f7cd-4404-5bc4-84fa-01e079d91e61",
            "name": "Harry Callan",
            "color": "green",
            "allocation": "25%"
          },
          {
            "id": "4c39f7cd-4404-5bc4-84fa-01e079d91e61",
            "name": "Harry Callan",
            "color": "purple",
            "allocation": "25%"
          }
        ]
      },
      "position": {
        "x": 22.892249999999876,
        "y": 14.476999999999975
      },
      "selected": false,
      "parentId": "Y",
      "positionAbsolute": {
        "x": 1493.9647626171668,
        "y": 991.7990850295076
      },
      "measured": {
        "width": 300,
        "height": 120
      },
      "hidden": false
    },
    {
      "id": "B",
      "data": null,
      "style": {
        "width": "350px",
        "border": "1px solid rgba(116, 120, 141, 0.3)",
        "height": 240,
        "boxShadow": "0px 7px 29px 0px rgba(100, 100, 111, 0.2)",
        "backgroundColor": "transparent"
      },
      "type": "group",
      "width": 325,
      "height": 279,
      "dragging": false,
      "nodeInfo": {
        "id": "B",
        "groupType": "strategy",
        "isTaxable": true,
        "totalRisk": 12,
        "parentAccountData": [
          {
            "id": "4c39f7cd-4404-5bc4-84fa-01e079d91e61",
            "name": "Harry Callan",
            "color": "green",
            "allocation": "25%"
          },
          {
            "id": "4c39f7cd-4404-5bc4-84fa-01e079d91e61",
            "name": "Harry Callan",
            "color": "purple",
            "allocation": "25%"
          }
        ]
      },
      "position": {
        "x": 1839.0400335543031,
        "y": 1055.9293527467107
      },
      "selected": false,
      "positionAbsolute": {
        "x": 1845.797845063307,
        "y": 978.7702491619642
      },
      "measured": {
        "width": 325,
        "height": 279
      },
      "resizing": false,
      "className": "",
      "hidden": false
    },
    {
      "id": "C",
      "data": null,
      "name": "R1J 40,Coho 20, stoxx intl 20",
      "width": 300,
      "height": 100,
      "dragging": false,
      "nodeInfo": {
        "id": "C",
        "name": "R1J 40,Coho 20, stoxx intl 20",
        "group": [
          "washsales",
          "settlement"
        ],
        "nodeType": "strategy",
        "isTaxable": true,
        "allocation": "25%",
        "marketValue": 2500000,
        "trackingError": 12,
        "parentAccountData": [
          {
            "id": "4c39f7cd-4404-5bc4-84fa-01e079d91e61",
            "name": "Harry Callan",
            "color": "green",
            "allocation": "25%"
          },
          {
            "id": "4c39f7cd-4404-5bc4-84fa-01e079d91e61",
            "name": "Harry Callan",
            "color": "purple",
            "allocation": "25%"
          }
        ]
      },
      "position": {
        "x": 15.583969619709023,
        "y": 10.468791771193992
      },
      "selected": false,
      "parentId": "B",
      "positionAbsolute": {
        "x": 1870.714760157057,
        "y": 1001.852487161964
      },
      "measured": {
        "width": 300,
        "height": 120
      },
      "hidden": false
    },
    {
      "id": "nodeD",
      "data": null,
      "name": "R1J 40,Coho 20, stoxx intl 20",
      "width": 300,
      "height": 100,
      "dragging": false,
      "nodeInfo": {
        "id": "nodeD",
        "name": "R1J 40,Coho 20, stoxx intl 20",
        "group": [
          "washsales",
          "settlement"
        ],
        "nodeType": "strategy",
        "isTaxable": true,
        "allocation": "25%",
        "marketValue": 2500000,
        "trackingError": 12
      },
      "position": {
        "x": 14.791873753713162,
        "y": 142.99342827329804
      },
      "selected": false,
      "parentId": "B",
      "positionAbsolute": {
        "x": 1869.0817678758071,
        "y": 1107.832024036964
      },
      "measured": {
        "width": 300,
        "height": 120
      },
      "hidden": false
    }
  ],
  "edges": [
    {
      "id": "e-c6d35000-05b7-5449-a105-34bdc31d4d4e-d2527c72-8c60-597b-895f-8677cc0e9811",
      "style": {
        "stroke": "skyblue"
      },
      "source": "c6d35000-05b7-5449-a105-34bdc31d4d4e",
      "target": "d2527c72-8c60-597b-895f-8677cc0e9811",
      "type": "workflow",
      "hidden": false
    },
    {
      "id": "e-d2527c72-8c60-597b-895f-8677cc0e9811-4c39f7cd-4404-5bc4-84fa-01e079d91e61",
      "style": {
        "stroke": "skyblue"
      },
      "source": "d2527c72-8c60-597b-895f-8677cc0e9811",
      "target": "4c39f7cd-4404-5bc4-84fa-01e079d91e61",
      "type": "workflow",
      "hidden": false
    },
    {
      "id": "e-d2527c72-8c60-597b-895f-8677cc0e9811-0d544534-8e72-579f-b46a-0102e4628965",
      "style": {
        "stroke": "skyblue"
      },
      "source": "d2527c72-8c60-597b-895f-8677cc0e9811",
      "target": "0d544534-8e72-579f-b46a-0102e4628965",
      "type": "workflow",
      "hidden": false
    },
    {
      "id": "e-c6d35000-05b7-5449-a105-34bdc31d4d4e-daffcbbf-02be-5ff3-a395-d22f43afd6db",
      "style": {
        "stroke": "skyblue"
      },
      "source": "c6d35000-05b7-5449-a105-34bdc31d4d4e",
      "target": "daffcbbf-02be-5ff3-a395-d22f43afd6db",
      "type": "workflow",
      "hidden": false
    },
    {
      "id": "e-daffcbbf-02be-5ff3-a395-d22f43afd6db-b7e6cd87-163f-5796-8138-91eb63ca90e0",
      "style": {
        "stroke": "skyblue"
      },
      "source": "daffcbbf-02be-5ff3-a395-d22f43afd6db",
      "target": "b7e6cd87-163f-5796-8138-91eb63ca90e0",
      "type": "workflow",
      "hidden": false
    },
    {
      "id": "e-daffcbbf-02be-5ff3-a395-d22f43afd6db-49972796-42a3-50a7-82d7-90192dbd3daf",
      "style": {
        "stroke": "skyblue"
      },
      "source": "daffcbbf-02be-5ff3-a395-d22f43afd6db",
      "target": "49972796-42a3-50a7-82d7-90192dbd3daf",
      "type": "workflow",
      "hidden": false
    },
    {
      "id": "e-c6d35000-05b7-5449-a105-34bdc31d4d4e-b44936f3-707a-52dc-838d-e9dad1dbd202",
      "style": {
        "stroke": "skyblue"
      },
      "source": "c6d35000-05b7-5449-a105-34bdc31d4d4e",
      "target": "b44936f3-707a-52dc-838d-e9dad1dbd202",
      "type": "workflow",
      "hidden": false
    },
    {
      "id": "e-b44936f3-707a-52dc-838d-e9dad1dbd202-e727a62a-5cfd-55ba-9e8e-a0dcf81631ab",
      "style": {
        "stroke": "skyblue"
      },
      "source": "b44936f3-707a-52dc-838d-e9dad1dbd202",
      "target": "e727a62a-5cfd-55ba-9e8e-a0dcf81631ab",
      "type": "workflow",
      "hidden": false
    },
    {
      "id": "e56",
      "style": {
        "stroke": "skyblue"
      },
      "source": "4c39f7cd-4404-5bc4-84fa-01e079d91e61",
      "target": "D",
      "type": "workflow",
      "hidden": false
    },
    {
      "id": "e57",
      "style": {
        "stroke": "skyblue"
      },
      "source": "0d544534-8e72-579f-b46a-0102e4628965",
      "target": "D",
      "type": "workflow",
      "hidden": false
    },
    {
      "id": "e58",
      "style": {
        "stroke": "skyblue"
      },
      "source": "b7e6cd87-163f-5796-8138-91eb63ca90e0",
      "target": "D",
      "type": "workflow",
      "hidden": false
    },
    {
      "id": "e59",
      "style": {
        "stroke": "skyblue"
      },
      "source": "49972796-42a3-50a7-82d7-90192dbd3daf",
      "target": "D",
      "type": "workflow",
      "hidden": false
    },
    {
      "id": "e60",
      "style": {
        "stroke": "skyblue"
      },
      "source": "e727a62a-5cfd-55ba-9e8e-a0dcf81631ab",
      "target": "D",
      "type": "workflow",
      "hidden": false
    },
    {
      "id": "e66",
      "style": {
        "stroke": "skyblue",
        "stokeWidth": 2,
        "strokeDasharray": 6
      },
      "source": "E",
      "target": "K",
      "type": "workflow",
      "hidden": false
    },
    {
      "id": "e67",
      "style": {
        "stroke": "skyblue",
        "stokeWidth": 2,
        "strokeDasharray": 6
      },
      "source": "F",
      "target": "P",
      "type": "workflow",
      "hidden": false
    },
    {
      "id": "e68",
      "style": {
        "stroke": "skyblue",
        "stokeWidth": 2,
        "strokeDasharray": 6
      },
      "source": "G",
      "target": "T",
      "type": "workflow",
      "hidden": false
    },
    {
      "id": "e69",
      "style": {
        "stroke": "skyblue",
        "stokeWidth": 2,
        "strokeDasharray": 6
      },
      "source": "H",
      "target": "W",
      "type": "workflow",
      "hidden": false
    },
    {
      "id": "e70",
      "style": {
        "stroke": "skyblue",
        "stokeWidth": 2,
        "strokeDasharray": 6
      },
      "source": "I",
      "target": "Y",
      "type": "workflow",
      "hidden": false
    },
    {
      "id": "e71",
      "style": {
        "stroke": "skyblue",
        "stokeWidth": 2,
        "strokeDasharray": 6
      },
      "source": "J",
      "target": "B",
      "type": "workflow",
      "hidden": false
    }
  ],
  "viewport": {
    "x": 68.17769210715664,
    "y": -54.49202691721871,
    "zoom": 0.5
  }
}