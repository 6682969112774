import { Box, Button } from '@mui/material'

const onDragStart = (event, nodeType) => {
  event.dataTransfer.setData('application/reactflow', nodeType)
  event.dataTransfer.effectAllowed = 'move'
}

const DragAndDropSidebar = () => {
  return (
    <aside style={{ position: 'absolute', top: 0, left: 0, zIndex: 99 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <Button
          variant='outlined'
          color='success'
          size='small'
          onDragStart={(event) => onDragStart(event, 'node')}
          draggable
          style={{ marginBottom: '10px' }}
        >
          Node
        </Button>
        <Button
          variant='outlined'
          color='success'
          size='small'
          onDragStart={(event) => onDragStart(event, 'group')}
          draggable
          style={{ marginBottom: '10px' }}
        >
          Group
        </Button>
      </Box>
    </aside>
  )
}

export default DragAndDropSidebar
