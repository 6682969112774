import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro } from '@mui/x-data-grid-pro'
import * as Sentry from '@sentry/react'
import '../../../assets/styles/AccountDetailspage.scss'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import StyledTradePopupBox from '../components/StyledTradePopupBox'

const ViewPolicyBreakCheckModal = (selectedItem, index) => {
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const [isloading, setIsLoading] = useState(true)
  const [policyBreak, setPolicyBreak] = useState()

  useEffect(() => {
    if (user) {
      getPolicyBreackCheck(selectedItem.index)
    }
  }, [user])

  const getPolicyBreackCheck = async (optMapId) => {
    const accMapId = optMapId[0]
    const scenarioDesc = optMapId[1]
    API.get(
      'baseDataValidation',
      `data-validation/v1/${user?.userGroup
      }/policies/checks/${accMapId}/scenario/${scenarioDesc
        .split(' ')
        .join('')}`
    )
      .then((response) => {
        setIsLoading(false)
        if (response.data) {
          setPolicyBreak(response.data?.map((row) => ({ ...row, id: randomId() })))
        }
      })
      .catch((error) => {
        setIsLoading(false)
        showError(error.response?.data?.errorInfo?.userMessage)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const tableHeaders = [
    { field: 'scenarioDesc', headerName: 'Scenario', flex: 1 },
    { field: 'policyCode', headerName: 'Policy Code', flex: 1 },
    { field: 'lastClosePrice', headerName: 'As per Last Close Price', flex: 1 },
    { field: 'livePrice', headerName: 'As per Live Price', flex: 1 }
  ]
  const getRowId = (row) => row.id

  const tableHeaderHTML = tableHeaders.map(({ headerName }, index) => <TableCell key={index}>{headerName}</TableCell>)
  const rowSkeleton = tableHeaders.map(({ headerName }, index) => <TableCell key={index}><Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} /></TableCell>)

  return (
    <>
      <StyledTradePopupBox className='table-responsive'>
        <>
          {isloading
            ? (
              <>
                <Table>
                  <TableHead>
                    <TableRow style={{ position: 'sticky', top: '0', background: 'white' }}>
                      {tableHeaderHTML}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {rowSkeleton}
                    </TableRow>
                    <TableRow>
                      {rowSkeleton}
                    </TableRow>
                    <TableRow>
                      {rowSkeleton}
                    </TableRow>
                  </TableBody>
                </Table>
              </>
              )
            : (
              <>
                {!policyBreak
                  ? (
                    <>
                      <Typography
                        component='h6'
                        style={{
                          color: 'black',
                          marginTop: '10px',
                          textAlign: 'center'
                        }}
                      >
                        No optimization properties data found
                      </Typography>
                    </>
                    )
                  : (
                    <>
                      <DataGridPro
                        autoHeight
                        rows={policyBreak}
                        columns={tableHeaders}
                        loading={isloading}
                        getRowId={getRowId}
                        pagination
                        pageSizeOptions={[10]}
                        initialState={{
                          ...policyBreak?.initialState,
                          pagination: { paginationModel: { pageSize: 10 } }
                        }}
                      />
                    </>
                    )}
              </>
              )}
        </>
      </StyledTradePopupBox>
    </>
  )
}
export default ViewPolicyBreakCheckModal
