import { ErrorBoundary } from 'react-error-boundary'
import { Box, Button, Divider, Grid, Typography } from '@mui/material'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import ErrorFallback from '../ErrorFallback'

const renderDataGridPro = (rows, columns, sortModel) => {
  return (
    <DataGridPro
      density='compact'
      autoHeight
      rows={rows}
      columns={columns}
      disableRowSelectionOnClick
      slots={{
        noRowsOverlay: CustomNoRowsOverlay
      }}
      slotProps={{
        noRowsOverlay: { message: 'No Securities Available.' }
      }}
      pagination
      pageSizeOptions={[5, 10, 15, 25, 50, 100]}
      hideFooter={rows?.length <= 5}
      initialState={{
        pagination: { paginationModel: { pageSize: 5 } },
        sorting: {
          sortModel: sortModel || []
        }
      }}
      sx={(theme) => ({
        '& .MuiDataGrid-cell': {
          cursor: 'pointer'
        },
        '& .MuiDataGrid-cell:focus': {
          outline: 'none'
        },
        [`.${gridClasses.main}`]: {
          height: 'min(100vh - 16px - 16px - 40px - 24px - 15px - 24px - 36.5px - 39px, 450px - 16px - 16px - 40px - 24px - 15px - 24px - 36.5px - 39px)'
        },
        [`.${gridClasses.columnHeaders}`]: {
          position: 'sticky',
          backgroundColor: theme.palette.background.paper,
          top: 0,
          zIndex: 1
        },
        [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
          color: '#74788d',
          fontWeight: 600
        },
        [`.${gridClasses.virtualScroller}`]: {
          overflowY: 'auto !important',
          scrollbarGutter: 'stable',
          scrollbarWidth: 'none'
        }
      })}
    />
  )
}

const ViewConfirmModelPopup = ({ data, submitStrategyPortfolio }) => {
  const modelColumns = (item) => {
    return [
      {
        field: 'name',
        headerName: 'Instrument Name',
        flex: 1
      },
      {
        field: 'symbol',
        headerName: 'Symbol',
        flex: 1
      },
      ...(item === 'Security Removed'
        ? [{
            field: 'weightValue',
            headerName: 'Weight(%)',
            align: 'right',
            headerAlign: 'right',
            type: 'number',
            flex: 1,
            renderCell: (props) => (
              <Box sx={{ color: '#FF6161' }}>
                {props?.row?.currentWeight !== undefined
                ? props?.row?.currentWeight?.toFixed(4)
                : '0.0000'}
              </Box>
            ),
            valueGetter: (props) =>
              props?.row?.currentWeight !== undefined
                ? Number((props?.row?.currentWeight).toFixed(4))
                : 0.0000
          }]
        : [{
            field: 'adjustedWeight',
            headerName: 'Weight(%)',
            align: 'right',
            headerAlign: 'right',
            type: 'number',
            flex: 1,
            renderCell: (props) => (
              <Box sx={{ color: '#3BBFA3' }}>
                {props?.row?.adjustedWeight !== undefined
                ? props?.row?.adjustedWeight?.toFixed(4)
                : '0.0000'}
              </Box>
            ),
            valueGetter: (props) =>
              props?.row?.adjustedWeight !== undefined
                ? Number((props?.row?.adjustedWeight).toFixed(4))
                : 0.0000
          }]
      )
    ]
  }

  return (
    <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen='not main' />)}>
      <Typography sx={{ fontSize: '16px', color: 'black', px: '10px' }}>
        Are you sure you want to proceed with the addition and removal of below securities in the model update?
      </Typography>
      <Grid container sx={{ pt: '15px' }}>
        <Grid item xs={5.8}>
          <Typography sx={{ color: '#34475A', px: '10px' }}>Security Added</Typography>
          {renderDataGridPro(
            data?.filter(obj => obj?.instrChangeType === 'INSTR_ADDED'),
            modelColumns('Security Added'),
            [{ field: 'adjustedWeight', sort: 'desc' }]
          )}
        </Grid>
        <Grid item xs={0.4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Divider orientation='vertical' sx={{ width: '1px' }} />
        </Grid>
        <Grid item xs={5.8}>
          <Typography sx={{ color: '#34475A', px: '10px' }}>Security Removed</Typography>
          {renderDataGridPro(
            data?.filter(obj => obj?.instrChangeType === 'INSTR_REMOVED'),
            modelColumns('Security Removed'),
            [{ field: 'weightValue', sort: 'desc' }]
          )}
        </Grid>
      </Grid>
      <Box sx={{ float: 'right' }}>
        <Button variant='contained' onClick={() => submitStrategyPortfolio()}>
          Confirm
        </Button>
      </Box>
    </ErrorBoundary>
  )
}

const CustomNoRowsOverlay = ({ message }) => {
  return (
    <Typography sx={{ color: '#34475A', textAlign: 'center', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      {message}
    </Typography>
  )
}

export default ViewConfirmModelPopup
