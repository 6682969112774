import React, { useEffect, useRef, useState } from 'react'
import * as Sentry from '@sentry/react'
import { API } from 'aws-amplify'
import { Box } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import OnboardingStepper from './OnboardingStepper'
import UMADetailsTab from './UMADetailsTab'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import NewConfirmationTab from './Confirmation'
import UMATradingPortfolioTab from './UMATradingPortfolioTab'
import UmaTransitionPortfolioTab from './UmaTransitionPortfolioTab'
import AllocationModelTab from './AllocationModelTab'

const stepLabels = [
  'UMA Details',
  'Upload Portfolio',
  'Select Allocation Model',
  'Confirmation'
]

const UMAOnboarding = () => {
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const [activeIndex, setActiveIndex] = useState(0)
  const umaDetailsFormRef = useRef()
  const taxlotDetailsFormRef = useRef()
  const [umaDetailsFormSubmitted, setUmaDetailsFormSubmitted] = useState(false)
  const [taxlotDetailsFormSubmitted, setTaxlotDetailsFormSubmitted] = useState(false)
  const [umaDetails, setUmaDetails] = useState({})
  const [currentTransitionAllocationModel, setCurrentTransitionAllocationModel] = useState([])
  const [taxStates, setTaxStates] = useState([])
  const [sellLogicCodes, setSellLogicCodes] = useState([])
  const [custodianList, setCustodianList] = useState([])
  const [targetAllocationModel, setTargetAllocationModel] = useState([{ id: randomId(), data: [] }])
  const [uploadedPortfolioData, setUploadedPortfolioData] = useState({})
  const [fileIdExists, setFileIdExists] = useState(0)
  const [transitionPortfolioAllocationData, setTransitionPortfolioAllocationData] = useState([])
  const [personalizationData, setPersonalizationData] = useState({})
  const [transitionUploadPortfolioData, setTransitionUploadPortfolioData] = useState({})
  const [allStepsVisited, setAllStepsVisited] = useState(false)

  // GET API for UMA details screen
  const getCustodianType = () => {
    API.get('baseUriTransactionalMaster', `transactional-master/v1/${user.userGroup}/custodians`)
      .then(response => {
        if (response?.data) {
          setCustodianList(response.data)
        }
      }).catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const getTaxStates = async () => {
    API.get('baseUriTransactionalMaster', `transactional-master/v1/${user.userGroup}/tax-states`, {
      queryStringParameters: {}
    })
      .then((response) => {
        if (response.data) {
          setTaxStates([...response.data])
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const getSellLogicCodes = async () => {
    API.get('baseUriTransactionalMaster', `transactional-master/v1/${user.userGroup}/sell-logics`, {
      queryStringParameters: {}
    })
      .then((response) => {
        if (response.data) {
          setSellLogicCodes([...response.data])
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  useEffect(() => {
    if (user) {
      getTaxStates()
      getSellLogicCodes()
      getCustodianType()
    }
  }, [user])

  const gotoNextStep = () => {
    if (activeIndex === 0 && !umaDetailsFormSubmitted) {
      umaDetailsFormRef?.current?.click()
    } else if (activeIndex === 1 && !taxlotDetailsFormSubmitted && umaDetails?.accountType === 'trading') {
      taxlotDetailsFormRef.current.click()
    } else if (activeIndex === 1 && umaDetails?.accountType === 'transition') {
      const hasError = currentTransitionAllocationModel?.some((data) => data?.error) || !currentTransitionAllocationModel?.length
      if (!hasError) {
        setActiveIndex(activeIndex + 1)
      }
      if (!currentTransitionAllocationModel?.length) {
        // show error if there is no allocation and strategy
        showError('Total allocation sum should be 100')
      }
    } else if (activeIndex === 2) {
      const hasError = targetAllocationModel?.some((data) => data?.error)
      const hasStrategyError = targetAllocationModel?.some((data) => data?.data?.length === 0)
      const hasMissingTitle = targetAllocationModel?.some((data) => !data?.allocationTitle?.trim())

      // show error if any of allocation models for transition does not have strategies
      if (targetAllocationModel && targetAllocationModel?.length && umaDetails?.accountType === 'transition' && hasStrategyError) {
        showError('Total allocation sum should be 100 in each allocation')
      } else if (!targetAllocationModel?.length || hasStrategyError) {
        // show error if there is no allocation and strategy
        // showError('Total allocation sum should be 100')
        showError('At least one allocation required')
      } else if (hasMissingTitle) {
        // show error if any allocationTitle is missing
        showError('Allocation Title is required')
      }
      if (!hasError && !hasStrategyError && !hasMissingTitle && targetAllocationModel?.length) {
        setActiveIndex(activeIndex + 1)
      }
    } else {
      if (activeIndex === 0) {
        setUmaDetailsFormSubmitted(false)
      } else if (activeIndex === 1 && umaDetails?.accountType === 'trading') {
        setTaxlotDetailsFormSubmitted(false)
      }
      setActiveIndex(activeIndex + 1)
    }

    // If the final step is reached, mark all steps as visited
    if (activeIndex === stepLabels.length - 2) {
      if (targetAllocationModel?.some((data) => data?.error) ||
        targetAllocationModel?.some((data) => data?.data?.length === 0) || !targetAllocationModel?.length ||
        (umaDetails?.accountType === 'transition' && (currentTransitionAllocationModel?.some((data) => data?.error) || !currentTransitionAllocationModel?.length))) {
        setAllStepsVisited(false)
      } else {
        setAllStepsVisited(true)
      }
    }
  }

  const gotoPrevStep = () => {
    setActiveIndex(activeIndex - 1)
    setFileIdExists(uploadedPortfolioData.fileId)
  }

  const changeActiveStep = (index) => {
    if (index <= activeIndex || allStepsVisited) {
      setActiveIndex(index)
    }
  }

  const setUmaInformation = (data) => {
    if (data?.masterAccountId !== umaDetails?.masterAccountId || data?.custodianNumber !== umaDetails?.custodianNumber || data?.taxManaged !== umaDetails?.taxManaged || data?.accountType !== umaDetails?.accountType) {
      setUploadedPortfolioData({})
    }
    setUmaDetailsFormSubmitted(true)
    setUmaDetails(data)
    gotoNextStep()
  }

  const setTaxlotInformation = (data) => {
    setTaxlotDetailsFormSubmitted(true)
    setUploadedPortfolioData(data)
    gotoNextStep()
  }

  const addAllocation = () => {
    // add allocation will add new allocation card for transition accounts
    // for creating multiple allocationCardData
    setTargetAllocationModel([
      ...targetAllocationModel,
      { id: randomId(), data: [] }
    ])
  }

  return (
    <>
      <Box className='uma-page' sx={{ height: 'auto' }}>
        <OnboardingStepper
          activeIndex={activeIndex}
          stepLabels={stepLabels}
          gotoNextStep={gotoNextStep}
          umaDetails={umaDetails}
          allStepsVisited={allStepsVisited}
          gotoPrevStep={gotoPrevStep}
          changeActiveStep={changeActiveStep}
        />
        {stepLabels[activeIndex] === 'UMA Details'
          ? (
            <UMADetailsTab
              custodianList={custodianList}
              taxStates={taxStates}
              sellLogicCodes={sellLogicCodes}
              setAllStepsVisited={setAllStepsVisited}
              submitRef={umaDetailsFormRef}
              setAllocationCardData={setTargetAllocationModel}
              setTransitionPortfolioAllocationData={setTransitionPortfolioAllocationData}
              setTransitionUploadPortfolioData={setTransitionUploadPortfolioData}
              setData={setUmaInformation}
              data={umaDetails}
            />
            )
          : stepLabels[activeIndex] === 'Select Allocation Model'
            ? <AllocationModelTab
                umaDetails={umaDetails}
                addAllocation={addAllocation}
                allocationCardData={targetAllocationModel}
                setAllStepsVisited={setAllStepsVisited}
                setAllocationCardData={setTargetAllocationModel}
                personalizationData={personalizationData}
                setPersonalizationData={setPersonalizationData}
              />
            : stepLabels[activeIndex] === 'Upload Portfolio'
              ? (
                  umaDetails?.accountType === 'transition'
                    ? (
                      <UmaTransitionPortfolioTab
                        setData={setCurrentTransitionAllocationModel}
                        setTransitionUploadPortfolioData={setTransitionUploadPortfolioData}
                        transitionUploadPortfolioData={transitionUploadPortfolioData}
                        setTaxlotInformation={setTaxlotInformation}
                        umaDetails={umaDetails}
                        setAllStepsVisited={setAllStepsVisited}
                        transitionPortfolioAllocationData={transitionPortfolioAllocationData}
                        setTransitionPortfolioAllocationData={setTransitionPortfolioAllocationData}
                      />
                      )
                    : (
                      <UMATradingPortfolioTab
                        submitRef={taxlotDetailsFormRef}
                        setTaxlotInformation={setTaxlotInformation}
                        data={uploadedPortfolioData}
                        setAllStepsVisited={setAllStepsVisited}
                        setUploadedPortfolioData={setUploadedPortfolioData}
                        custodianData={{ selectedCustodian: umaDetails?.custodian, custodianNumber: umaDetails?.custodianNumber, masterAccountId: umaDetails?.masterAccountId, taxManaged: umaDetails?.taxManaged }}
                        fileIdExists={fileIdExists}
                        umaDetails={umaDetails}
                      />
                      )
                )
              : stepLabels[activeIndex] === 'Confirmation'
                ? (
                  <NewConfirmationTab
                    umaDetails={umaDetails}
                    uploadedPortfolioData={uploadedPortfolioData}
                    currentAllocation={currentTransitionAllocationModel}
                    targetAllocation={targetAllocationModel}
                    transitionUploadPortfolioData={transitionUploadPortfolioData}
                  />
                  )
                : null}
      </Box>

    </>
  )
}

export default UMAOnboarding
