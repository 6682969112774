import React, { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { API } from 'aws-amplify'
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined'
import CloseIcon from '@mui/icons-material/Close'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { isNumberOnly } from '../../../utils/NumberUtils'
import { validateExpression } from '../../../utils/ZscoreValidator'
import Industry from '../../AccountOnboarding/Industry'
import Sector from '../../AccountOnboarding/Sector'
import SecurityRestictions from '../../AccountOnboarding/SecurityRestictions'
import Substitutions from '../../AccountOnboarding/Substitution'
import { InputAdornmentTextField } from '../../AccountOnboarding/components/inputAdornmentTextfield'
import Loader from '../../Loader'
import CustomTooltipIcon from '../../Personalization/components/CustomTooltipIcon'

const decTwo = (string, decimal) => {
  return (Number(string)).toFixed(decimal).toString()
}

const headers = ['Factor', 'Strategy', 'Range', 'Default', 'Absolute/Benchmark Relative']
const cashConstraints = ['Name', 'Value']
const taxConstraints = ['Name', 'Value']
const otherConstraints = ['Name', 'Value']

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  minWidth: '800px',
  maxWidth: '1200px',
  height: '700px',
  maxHeight: '100vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  overflow: 'auto',
  borderRadius: '4px'
}

const PersonalizationModal = ({ isShown, setIsShown, strategy, setPersonalizationData, personalization }) => {
  const { user } = useAuth()
  const sectorApiRef = useGridApiRef()
  const industryApiRef = useGridApiRef()
  const [expanded, setExpanded] = React.useState(false)
  const [factorTiltsColor, setfactorTiltsColor] = useState(false)
  const [sectorList, setSectorList] = useState(personalization?.sectorData ? personalization.sectorData : [])
  const [industryList, setIndustryList] = useState(personalization?.industryData ? personalization.industryData : [])
  const [factorUtilData, setFactorUtilData] = useState(personalization?.factorUtilData ? personalization.factorUtilData : [])
  const [subColorFlag, setSubColorFlag] = useState(personalization?.subColorFlag ? personalization.subColorFlag : false)
  const { showError } = useErrorToast()
  const [totalSectorSecurities, setTotalSectorSecurities] = useState()
  const [sectorSecurities, setSectorSecurities] = useState()
  const [totalIndustrySecurities, setTotalIndustrySecurities] = useState()
  const [industrySecurities, setIndustrySecurities] = useState()
  const [securityDataColor, setSecurityDataColor] = useState(false)
  const [sectorBorderColor, setSectorBorderColor] = useState(personalization?.sectorBorderColor ? personalization.sectorBorderColor : false)
  const [industryBorderColor, setIndustryBorderColor] = useState(personalization?.industryBorderColor ? personalization.industryBorderColor : false)
  const [minCashError, setMinCashError] = useState('')
  const [maxCashError, setMaxCashError] = useState('')
  const minCashValue = 0
  const maxCashValue = 100
  const [error, setError] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [appliedPersonalizationData, setAppliedPersonalizationData] = useState(personalization || [])
  const [dataCashConstraint, setDataCashConstraint] = useState(personalization?.cashConstraintData)
  const [sectorGridState, setSectorGridState] = useState(null)
  const [industryGridState, setIndustryGridState] = useState(null)

  const restoreSectorDatagridState = () => {
    if (sectorApiRef?.current?.restoreState && sectorGridState) {
      sectorApiRef?.current?.restoreState({
        pinnedColumns: sectorGridState.pinnedColumns,
        columns: sectorGridState.columns
      })
    }
  }

  useEffect(() => {
    restoreSectorDatagridState()
  }, [error, sectorList, appliedPersonalizationData, isShown, strategy, personalization, industrySecurities, industryBorderColor, totalIndustrySecurities])

  const restoreIndustryDatagridState = () => {
    if (industryApiRef?.current?.restoreState && industryGridState) {
      industryApiRef?.current?.restoreState({
        pinnedColumns: industryGridState.pinnedColumns,
        columns: industryGridState.columns
      })
    }
  }

  useEffect(() => {
    restoreIndustryDatagridState()
  }, [error, industryList, totalIndustrySecurities, appliedPersonalizationData, isShown, strategy, personalization, sectorBorderColor, sectorSecurities, totalSectorSecurities])

  const [dataTaxConstraint, setDataTaxConstraint] = useState(personalization?.taxConstraintData
    ? personalization?.taxConstraintData
    : [
        {
          name: 'Tax Sensitivity',
          value: '',
          options: ['High', 'Medium', 'Low'] // Add options for the dropdown
        },
        {
          name: 'Max. Short Term Gain',
          value: '',
          adornment: <AttachMoneyOutlinedIcon />
        },
        {
          name: 'Max. Long Term Gain',
          value: '',
          adornment: <AttachMoneyOutlinedIcon />
        }
      ])

  const [dataOtherConstraint, setDataOtherConstraint] = useState(personalization?.constraintData)
  const [objectiveFunctionData, setObjectiveFunctionData] = useState(personalization?.objectiveConstraintData)

  useEffect(() => {
    if (user) {
      getConstraints()
      getSectorIndustry()
      getFactorUtils()
    }
  }, [user])

  const sortFunction = (data) => {
    return data?.slice()?.sort((a, b) => {
      const customOrder = [
        'Minimum Beta',
        'Maximum Beta',
        'Min Buy Size',
        'Min Buy Weight',
        'Min Trade Weight',
        'Max Holdings'
      ]
      const minIndex = customOrder.indexOf(a?.propertyDesc)
      const maxIndex = customOrder.indexOf(b?.propertyDesc)
      return minIndex - maxIndex
    })
  }

  // GET API for sector and industry data
  const getSectorIndustry = async () => {
    setIsLoading(true)
    API.get('baseStrategyURL', `strategy/v1/${user?.userGroup}/sector-industry/${strategy}`, {
      queryStringParameters: {}
    })
      ?.then((response) => {
        if (response?.data) {
          if (response?.data?.sectorData) {
            const sectorData = response?.data?.sectorData?.map((item, index) => {
              return {
                assetClass: item?.assetClass,
                sectorName: item?.assetClassDesc,
                sumWeight: (item?.sumWeight * 100).toFixed(2),
                count: item?.count,
                exclude: false,
                min: '',
                max: ''
              }
            })
            if (appliedPersonalizationData?.sectorData) {
              setSectorList(appliedPersonalizationData?.sectorData)
            } else {
              setSectorList(sectorData)
              setAppliedPersonalizationData({ ...appliedPersonalizationData, sectorData })
            }
            if (response?.data?.sectorDataInd) {
              const industryData = response?.data?.sectorDataInd?.map((item, index) => {
                return {
                  assetClass: item.assetClass,
                  sectorName: item.assetClassDesc,
                  industry: item.subIndName,
                  sumWeight: (item?.sumWeight * 100).toFixed(2),
                  count: item?.count,
                  exclude: false,
                  min: '',
                  max: ''
                }
              })
              if (appliedPersonalizationData?.industryData) {
                setIndustryList(appliedPersonalizationData?.industryData)
              } else {
                setIndustryList(industryData)
                setAppliedPersonalizationData({ ...appliedPersonalizationData, industryData })
              }
              setIsLoading(false)
            }
          }
        }
      })
      .catch((error) => {
        setIsLoading(false)
        showError(error?.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
      })
  }

  // GET API for Factor tilts data
  const getFactorUtils = async () => {
    setIsLoading(true)
    API.get('baseStrategyURL', `strategy/v1/${user?.userGroup}/models/factor-tilts/${strategy}`, {
      queryStringParameters: {}
    })
      ?.then((response) => {
        if (response?.data) {
          const factorUtils = response?.data?.map((item, index) => {
            return {
              attrLabel: item?.attrLabel,
              statsLevelValue: item?.statsLevelValue,
              benchmarkValue: item?.benchmarkValue,
              selected: false,
              minValue: item?.minValue,
              maxValue: item?.maxValue,
              value: '',
              riskModelVerId: item?.riskModelVerId
            }
          })
          if (appliedPersonalizationData?.factorUtilData) {
            setFactorUtilData(appliedPersonalizationData.factorUtilData)
          } else {
            setFactorUtilData(factorUtils)
            setAppliedPersonalizationData({ ...appliedPersonalizationData, factorUtilData: factorUtils })
          }
          setIsLoading(false)
        }
      })
      .catch((error) => {
        setIsLoading(false)
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  // GET API for Cash Constraints and Other Constraints data
  const getConstraints = async () => {
    setIsLoading(true)

    const cashQueryParams = { propertyType: 'CASH_CONSTRAINT' }
    const otherQueryParams = { propertyType: 'OTHER_CONSTRAINT' }

    const cashPromise = API.get('baseUriTransactionalMaster', `transactional-master/v1/${user?.userGroup}/property-codes`, { queryStringParameters: cashQueryParams })
    const otherPromise = API.get('baseUriTransactionalMaster', `transactional-master/v1/${user?.userGroup}/property-codes`, { queryStringParameters: otherQueryParams })

    Promise.all([cashPromise, otherPromise])
      ?.then((responses) => {
        const cashResponse = responses[0]
        const otherResponse = responses[1]
        if (appliedPersonalizationData?.cashConstraintData) {
          setDataCashConstraint(appliedPersonalizationData?.cashConstraintData)
          validateMinMaxCashLevel(appliedPersonalizationData?.cashConstraintData, true)
        } else if (cashResponse?.data) {
          setDataCashConstraint(cashResponse.data)
        }
        if (appliedPersonalizationData?.constraintData && appliedPersonalizationData?.objectiveConstraintData) {
          setDataOtherConstraint(sortFunction(appliedPersonalizationData?.constraintData))
          setObjectiveFunctionData(appliedPersonalizationData?.objectiveConstraintData)
        } else if (otherResponse?.data) {
          setDataOtherConstraint(
            sortFunction(otherResponse.data.filter(elem =>
              elem.propertyCode !== 'FIXED_TXN_COST_LAMBDA' &&
              elem.propertyCode !== 'RISK_LAMBDA' &&
              elem.propertyCode !== 'TAX_LAMBDA' &&
              elem.propertyCode !== 'TXN_COST_LAMBDA'
            )))

          setObjectiveFunctionData(
            otherResponse.data.filter(elem =>
              elem.propertyCode === 'FIXED_TXN_COST_LAMBDA' ||
              elem.propertyCode === 'RISK_LAMBDA' ||
              elem.propertyCode === 'TAX_LAMBDA' ||
              elem.propertyCode === 'TXN_COST_LAMBDA'
            )
          )
        }
      })
      .catch((error) => {
        setIsLoading(false)
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const MinTradeIcon = ({ title }) => (
    <Box
      sx={{
        width: 27,
        height: 22
      }}
    >
      {title}
    </Box>
  )

  // handle onChange for Factor Tilts and validation
  const handleChangeMinMax = (event, rowIndex) => {
    const newValue = event.target.value
    const isInValid = newValue ? validateExpression(newValue) : false
    const newData = [...factorUtilData]
    newData[rowIndex].value = newValue
    setFactorUtilData(newData)
    setAppliedPersonalizationData({ ...appliedPersonalizationData, factorUtilData: newData, error: { ...appliedPersonalizationData?.error, factorTilts: isInValid } })
    if (newValue) {
      setfactorTiltsColor(true)
    } else if (factorUtilData?.some(item => item.value !== '')) {
      setfactorTiltsColor(true)
    } else if (appliedPersonalizationData?.factorUtilData?.some(item => item.value !== '')) {
      setfactorTiltsColor(true)
    } else {
      setfactorTiltsColor(false)
    }
  }

  // Pass checkEmptyFieldError as true on blur and from useEffect
  const validateMinMaxCashLevel = (updatedCashConstraint, checkEmptyFieldError = false) => {
    // find index of min and max cash level
    const maxCashIndex = updatedCashConstraint.findIndex(item => item.propertyCode === 'MAX_CASH_LEVEL')
    const minCashIndex = updatedCashConstraint.findIndex(item => item.propertyCode === 'MIN_CASH_LEVEL')
    // parse value of min and max cash level input
    const minCashValue = parseFloat(updatedCashConstraint[minCashIndex].value)
    const maxCashValue = parseFloat(updatedCashConstraint[maxCashIndex].value)
    // validate for min cash level for value between 0 to 100
    if (minCashValue < 0 || minCashValue > 100) {
      updatedCashConstraint[minCashIndex].error = true
      setMinCashError('Value for Minimum cash level should be between 0 to 100')
    } else {
      updatedCashConstraint[minCashIndex].error = (checkEmptyFieldError && maxCashValue && !minCashValue) || false
      setMinCashError(checkEmptyFieldError && maxCashValue && !minCashValue ? 'Min cash is required' : '')
    }
    // validate for max cash level for value between 0 to 100
    if (maxCashValue < 0 || maxCashValue > 100) {
      updatedCashConstraint[maxCashIndex].error = true
      setMaxCashError('Maximum cash level should be between 0 to 100')
    } else {
      updatedCashConstraint[maxCashIndex].error = (checkEmptyFieldError && minCashValue && !maxCashValue) || false
      setMaxCashError(checkEmptyFieldError && minCashValue && !maxCashValue ? 'Max cash is required' : '')
    }
    // if either of field is valid then check for min level>= max level and difference error
    if (!updatedCashConstraint[minCashIndex].error || !updatedCashConstraint[maxCashIndex].error) {
      if (minCashValue >= maxCashValue) {
        // if there is an existing error for value between 0 to 100,this step will be skipped
        if (!updatedCashConstraint[minCashIndex].error) {
          updatedCashConstraint[minCashIndex].error = true
          setMinCashError('Minimum cash level should be less than max cash level')
        }
        if (!updatedCashConstraint[maxCashIndex].error) {
          updatedCashConstraint[maxCashIndex].error = true
          setMaxCashError('Maximum cash level should be greater than min cash level')
        }
      } else if (maxCashValue - minCashValue < 0.5) { // if max level > min cash level, check for difference error
        updatedCashConstraint[minCashIndex].error = true
        setMinCashError('Difference between min and max cash level should be minimum of 0.5%')
        updatedCashConstraint[maxCashIndex].error = true
        setMaxCashError('Difference between min and max cash level should be minimum of 0.5%')
      }
    }

    // set updated constraint to cash constraint and personalization data
    setDataCashConstraint(updatedCashConstraint)
    setAppliedPersonalizationData(prevPersonalization => ({
      ...prevPersonalization,
      cashConstraintData: updatedCashConstraint,
      error: { ...appliedPersonalizationData.error, cashConstraint: updatedCashConstraint.some(item => item.error) }
    }))
  }

  // handle onChange for Cash Constraints and validation
  const handleChangeCashConstraint = (event, rowIndex) => {
    const newData = [...dataCashConstraint]
    newData[rowIndex].value = event.target.value
    if (event?.target?.value?.split('.')[1]?.length > 2) {
      event.preventDefault()
    } else {
      if (newData[rowIndex].propertyCode === 'MIN_CASH_LEVEL' || newData[rowIndex].propertyCode === 'MAX_CASH_LEVEL') {
        validateMinMaxCashLevel(newData)
      } else {
        setDataCashConstraint(newData)
        setAppliedPersonalizationData(prevPersonalization => ({
          ...prevPersonalization,
          cashConstraintData: newData,
          error: { ...appliedPersonalizationData?.error, cashConstraint: newData.some(item => item.error) }
        }))
      }
    }
  }

  // handle onChange for Tax Constraints
  const handleChangeTaxConstraint = (event, rowIndex) => {
    const newData = [...dataTaxConstraint]
    newData[rowIndex].value = event.target.value
    setDataTaxConstraint(newData)
    setAppliedPersonalizationData({ ...appliedPersonalizationData, taxConstraintData: newData })
  }

  // handle onChange for Other Constraints and validation
  const handleChangeOtherConstraint = (event, rowIndex) => {
    const newData = [...dataOtherConstraint]
    newData[rowIndex].value = event.target.value
    // max and min Beta values to be between +2 to -2
    if (newData[rowIndex]?.value?.length > 0 && (newData[rowIndex].propertyDesc === 'Minimum Beta' || newData[rowIndex].propertyDesc === 'Maximum Beta')) {
      const isValid = parseFloat(event.target.value) >= -2 && parseFloat(event.target.value) <= 2
      newData[rowIndex].error = !isValid
    } else if (newData[rowIndex]?.value?.length > 0 && newData[rowIndex].propertyCode === 'MIN_BUY_SIZE'
    ) {
      const isValid = parseFloat(event.target.value) > 0
      newData[rowIndex].error = !isValid
    } else if (newData[rowIndex]?.value?.length > 0 && newData[rowIndex].propertyCode === 'MIN_TRD_SIZE') {
      const isValid = parseFloat(event.target.value) >= 0 && !event.target.value.includes('.')
      newData[rowIndex].error = !isValid
    } else if (newData[rowIndex]?.value?.length > 0 && newData[rowIndex].propertyCode === 'MAX_HOLDINGS') {
      const isValid = parseInt(event.target.value) >= 1 && !event.target.value.includes('.')
      newData[rowIndex].error = !isValid
    } else {
      const isValid =
        (newData[rowIndex].uom === '%' && event.target.value !== '')
          ? parseFloat(event.target.value) >= minCashValue && parseFloat(event.target.value) <= maxCashValue
          : true
      newData[rowIndex].error = !isValid
    }
    setDataOtherConstraint(newData)

    setAppliedPersonalizationData((prevPersonalization) => ({
      ...prevPersonalization,
      constraintData: newData,
      objectiveConstraintData: objectiveFunctionData,
      otherConstraintData: [...newData, ...objectiveFunctionData],
      error: { ...appliedPersonalizationData?.error, otherConstraint: newData?.some((item) => item.error) }
    }))
  }

  const handleObjectiveConstraint = (event, rowIndex) => {
    const newObjectiveData = [...objectiveFunctionData]
    newObjectiveData[rowIndex].value = event.target.value
    setObjectiveFunctionData(newObjectiveData)
    setAppliedPersonalizationData((prevPersonalization) => ({
      ...prevPersonalization,
      constraintData: dataOtherConstraint,
      objectiveConstraintData: newObjectiveData,
      otherConstraintData: [...dataOtherConstraint, ...newObjectiveData],
      error: { ...appliedPersonalizationData?.error, otherConstraint: newObjectiveData?.some((item) => item.error) }
    }))
  }

  const handleCheckboxClick = (event, row) => {
    const updatedData = factorUtilData?.map((x) => {
      if (x.attrLabel === row.attrLabel) {
        return {
          ...x,
          selected: !x.selected,
          value: x?.selected ? '' : x?.attrLabel === 'Volatility' || x?.attrLabel === 'Size' ? 'B-0.15' : 'B+0.15'
        }
      }
      return x
    })
    if (updatedData?.some(item => item.value !== '')) {
      setfactorTiltsColor(true)
    } else {
      setfactorTiltsColor(false)
    }
    setFactorUtilData(updatedData)
    setAppliedPersonalizationData({ ...appliedPersonalizationData, factorUtilData: updatedData })
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handleSecurityData = (data) => {
    setAppliedPersonalizationData({ ...appliedPersonalizationData, securityData: data })
    if (data?.length > 0) {
      setSecurityDataColor(true)
    }
  }

  const handleSubstitutionData = (data) => {
    if (data?.length > 0) {
      setSubColorFlag(true)
    }
    setAppliedPersonalizationData({ ...appliedPersonalizationData, substitutionData: data })
  }

  useEffect(() => {
    const borderColor = sectorList?.filter((sector) => sector?.exclude || sector?.min !== '' || sector?.max !== '')?.length > 0
    setSectorBorderColor(borderColor)
    setAppliedPersonalizationData(prevData => ({ ...prevData, sectorData: sectorList, sectorBorderColor: borderColor }))
    const checkedSectors = sectorList?.filter((sector) => sector.exclude)?.map((sector) => { return sector.sectorName })
    const data = industryList?.map((industry) => {
      if (checkedSectors?.length > 0 && checkedSectors.includes(industry.sectorName)) {
        return { ...industry, exclude: true, disabled: true }
      } else if (industry.disabled) {
        return { ...industry, exclude: false, disabled: false }
      } else {
        return { ...industry }
      }
    })
    setIndustryList(data)
    const allSectorSecurities = sectorList?.filter((sector) => sector.sectorName === 'All Sectors')?.map((sector) => {
      return sector.count
    })
    let count = 0
    sectorList?.filter((sector) => !sector.exclude && sector.sectorName !== 'All Sectors')?.map((sector) => {
      count = count + sector.count
    })

    setSectorSecurities(count)
    setTotalSectorSecurities(allSectorSecurities)
  }, [sectorList])

  useEffect(() => {
    if (appliedPersonalizationData?.factorUtilData) {
      setfactorTiltsColor(appliedPersonalizationData?.factorUtilData?.some(item => item.value !== '' || item.selected !== false))
    }
  }, [])

  useEffect(() => {
    setAppliedPersonalizationData(prevData => ({ ...prevData, error }))
  }, [error])

  useEffect(() => {
    const allIndustrySecurities = industryList?.filter((industry) => industry.sectorName === 'All Sectors')?.map((industry) => {
      return industry.count
    })
    let count = 0
    industryList?.filter((industry) => !industry.exclude && industry.sectorName !== 'All Sectors')?.map((industry) => {
      count = count + industry.count
    })
    const borderColor = industryList?.filter((sector) => sector?.exclude || sector?.min !== '' || sector?.max !== '')?.length > 0
    setIndustrySecurities(count)
    setTotalIndustrySecurities(allIndustrySecurities)
    setIndustryBorderColor(borderColor)
    setAppliedPersonalizationData(prevData => ({ ...prevData, industryData: industryList, industryBorderColor: borderColor }))
  }, [industryList])

  const dataFlag = (data) => {
    setSubColorFlag(data)
    setAppliedPersonalizationData(prevData => ({ ...prevData, subColorFlag: data }))
  }

  const hasErrors = Object.values(error).some(Boolean) || minCashError || maxCashError

  const handleApply = () => {
    const updatedData = {
      ...personalization,
      ...appliedPersonalizationData
    }
    setPersonalizationData(updatedData)
    setIsShown(false)
  }

  return (
    <Modal
      open={isShown}
      onClose={() => setIsShown(false)}
    >
      <Box sx={style}>
        <Box
          sx={{ flexGrow: 1 }} className='modelSection'
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
            <Box sx={{ fontSize: '22px', fontFamily: 'Open Sans', fontWeight: 400, position: 'sticky', height: '50px' }}>Personalization</Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {appliedPersonalizationData?.error && Object.values(appliedPersonalizationData?.error).some(value => value) && (
                <Alert
                  severity='warning'
                  sx={{
                    '& .MuiAlert-icon': {
                      padding: '3px 0'
                    },
                    '& .MuiAlert-message': {
                      padding: '3px 0'
                    }
                  }}
                >
                  Please check the highlighted fields for errors.
                </Alert>
              )}
              <IconButton onClick={() => setIsShown(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Grid container item spacing={3} sx={{ height: 'min(100vh - 24px - 24px - 50px - 24px , 700px - 24px - 24px - 24px - 50px)', overflow: 'auto', scrollbarWidth: 'none' }}>
            <Grid item xs={12} sm={12} order={{ xs: 2, sm: 1 }} sx={{ paddingTop: '16px !important' }}>
              <Grid container item spacing={3}>
                <Grid item xs={12}>
                  <Box px={0} py={1}>
                    <Accordion
                      className='accordion-layout'
                      expanded={expanded === 'panel1'}
                      onChange={handleChange('panel1')}
                      sx={{
                        border: appliedPersonalizationData?.error?.sector ? '1px solid red' : sectorBorderColor ? '1px solid #175CC2' : ''
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1bh-content'
                        id='panel1bh-header'
                        sx={{ height: '52px' }}
                      >
                        <Typography sx={{ width: '16%', flexShrink: 0 }}>
                          Sector
                        </Typography>
                        <Tooltip
                          disableFocusListener
                          disableTouchListener
                          placement='right'
                          title='Exclude sectors from the portfolio or restrict their Z-Score'
                        >
                          <InfoOutlinedIcon fontSize='small' />
                        </Tooltip>
                        <Typography sx={{ width: '80%', flexShrink: 0, textAlign: 'end' }}>
                          Number of securities: {sectorSecurities}/{totalSectorSecurities}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {sectorList?.length > 0 ? <Sector data={sectorList} setData={setSectorList} setError={setError} sectorGridState={sectorGridState} setSectorGridState={setSectorGridState} apiRef={sectorApiRef} /> : <></>}
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                  <Box px={0} py={1}>
                    <Accordion
                      className='accordion-layout'
                      expanded={expanded === 'panel2'}
                      onChange={handleChange('panel2')}
                      sx={{
                        border: appliedPersonalizationData?.error?.industry ? '1px solid red' : industryBorderColor ? '1px solid #175CC2' : ''
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel2bh-content'
                        id='panel2bh-header'
                        sx={{ height: '52px' }}
                      >
                        <Typography sx={{ width: '16%', flexShrink: 0 }}>
                          Industry
                        </Typography>
                        <Tooltip
                          disableFocusListener
                          disableTouchListener
                          placement='right'
                          title='Exclude sectors from the portfolio or restrict their Z-Score'
                        >
                          <InfoOutlinedIcon fontSize='small' />
                        </Tooltip>
                        <Typography sx={{ width: '80%', flexShrink: 0, textAlign: 'end' }}>
                          Number of securities: {industrySecurities}/{sectorSecurities}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Industry data={industryList} setData={setIndustryList} setError={setError} industryGridState={industryGridState} setIndustryGridState={setIndustryGridState} apiRef={industryApiRef} />
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                  <Box px={0} py={1}>
                    <Accordion
                      className='accordion-layout'
                      expanded={expanded === 'panel4'}
                      onChange={handleChange('panel4')}
                      sx={{ border: securityDataColor ? '1px solid #175CC2' : '' }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel4bh-content'
                        id='panel4bh-header'
                        sx={{ height: '52px' }}
                      >
                        <Typography sx={{ width: '16%', flexShrink: 0 }}>
                          Security
                        </Typography>
                        <Tooltip
                          disableFocusListener
                          disableTouchListener
                          placement='right'
                          title='Apply restrictions at a security level'
                        >
                          <InfoOutlinedIcon fontSize='small' />
                        </Tooltip>
                      </AccordionSummary>
                      <AccordionDetails>
                        <SecurityRestictions onData={handleSecurityData} personalization={personalization} setError={setError} />
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                  <Box px={0} py={1}>
                    <Accordion
                      className='accordion-layout'
                      expanded={expanded === 'panelS2'}
                      onChange={handleChange('panelS2')}
                      sx={{
                        border: appliedPersonalizationData?.error?.factorTilts ? '1px solid red' : factorTiltsColor ? '1px solid #175CC2' : ''
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel4bh-content'
                        id='panel4bh-header'
                        sx={{ height: '52px' }}
                      >
                        <Typography sx={{ width: '16%', flexShrink: 0 }}>
                          Factor Tilts
                        </Typography>
                        <Tooltip
                          disableFocusListener
                          disableTouchListener
                          placement='right'
                          title='Tune various portfolio factors for better risk adjusted returns'
                        >
                          <InfoOutlinedIcon fontSize='small' />
                        </Tooltip>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Table size='small'>
                          <TableHead>
                            <TableRow>
                              {headers?.map((header) => (
                                <TableCell key={header} sx={{ fontWeight: 600 }}>
                                  {header}
                                  {header === 'Absolute/Benchmark Relative' || header === 'Range'
                                    ? (
                                      <span style={{ verticalAlign: 'sub', marginLeft: '4px' }}>
                                        <Tooltip
                                          disableFocusListener
                                          disableTouchListener
                                          placement='top'
                                          title={
                                              header === 'Absolute/Benchmark Relative'
                                                ? 'You can indicate a relative value by using the format B(+/-)(value). For example,  B-0.58 or B+0.58.'
                                                : header === 'Range'
                                                  ? 'Range is based on two ETFs, find the ETFs used by hovering on numbers below'
                                                  : ''
                                            }
                                        >
                                          <InfoOutlinedIcon fontSize='small' />
                                        </Tooltip>
                                      </span>
                                      )
                                    : ''}
                                </TableCell>

                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {factorUtilData?.map((row, index) => {
                              let tooltipValue = ''
                              let textColor = ''
                              switch (row?.attrLabel) {
                                case 'Value':
                                  tooltipValue = 'S&P 500 pure growth - S&P 500 pure value'
                                  textColor = '#34475A'
                                  break
                                case 'Growth':
                                  tooltipValue = 'S&P 500 pure value - S&P 500 pure growth'
                                  textColor = '#34475A'
                                  break
                                case 'Volatility':
                                  tooltipValue = 'S&P 500 minimum volatility - S&P 500'
                                  textColor = '#34475A'
                                  break
                                case 'Boxidend Yield':
                                  tooltipValue = 'S&P 500 - S&P 500 high dividend'
                                  textColor = '#34475A'
                                  break
                                case 'Size':
                                  tooltipValue = 'S&P 500 small cap - S&P 500 mega cap'
                                  textColor = '#34475A'
                                  break
                                case 'Quality':
                                  tooltipValue = 'S&P 500 - S&P 500 quality'
                                  textColor = '#34475A'
                                  break
                                default:
                                  tooltipValue = ''
                                  textColor = '#34475A'
                                  break
                              }
                              return (
                                <TableRow key={index}>
                                  <TableCell sx={{ fontWeight: 600, color: textColor }}>{row?.attrLabel}</TableCell>
                                  <TableCell sx={{ fontWeight: 600, color: textColor }}>{decTwo(row?.benchmarkValue, 2)}</TableCell>
                                  {tooltipValue !== ''
                                    ? <Tooltip title={tooltipValue}>
                                      <TableCell sx={{ fontWeight: 600, color: textColor }}>
                                        {decTwo(row?.minValue, 2)} - {decTwo(row?.maxValue, 2)}
                                      </TableCell>
                                      </Tooltip>
                                    : <TableCell sx={{ fontWeight: 600, color: textColor }}>
                                      {decTwo(row?.minValue, 2)} - {decTwo(row?.maxValue, 2)}
                                      </TableCell>}
                                  <TableCell sx={{ fontWeight: 600, color: textColor }}>
                                    <Checkbox
                                      checked={row?.selected}
                                      onClick={(event) => handleCheckboxClick(event, row)}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      label='Z-Score'
                                      size='small'
                                      sx={{ fontWeight: 600, color: textColor }}
                                      value={row?.value}
                                      onChange={(event) => handleChangeMinMax(event, index)}
                                      error={row?.value ? validateExpression(row?.value) : ''}
                                    />
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                          </TableBody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                  <Box px={0} py={1}>
                    <Accordion
                      className='accordion-layout'
                      expanded={expanded === 'panelS1'}
                      onChange={handleChange('panelS1')}
                      sx={{ border: subColorFlag ? '1px solid #175CC2' : '' }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel4bh-content'
                        id='panel4bh-header'
                      >
                        <Typography sx={{ width: '16%', flexShrink: 0 }}>
                          Substitutions
                        </Typography>
                        <Tooltip
                          disableFocusListener
                          disableTouchListener
                          placement='right'
                          title='Replace securities in the portfolio with certain other preferred securities'
                        >
                          <InfoOutlinedIcon fontSize='small' />
                        </Tooltip>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Substitutions onData={handleSubstitutionData} personalization={personalization} funct={dataFlag} setError={setError} />
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                  <Box px={0} py={1}>
                    <Accordion
                      className='accordion-layout'
                      expanded={expanded === 'panelC1'}
                      onChange={handleChange('panelC1')}
                      sx={{
                        border: appliedPersonalizationData?.error?.cashConstraint ? '1px solid red' : dataCashConstraint?.some(item => !!item.value) ? '1px solid #175CC2' : ''
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel4bh-content'
                        id='panel4bh-header'
                        sx={{ height: '52px' }}
                      >
                        <Typography sx={{ width: '16%', flexShrink: 0 }}>
                          Cash Constraints
                        </Typography>
                        <Tooltip
                          disableFocusListener
                          disableTouchListener
                          placement='right'
                          title='Enforce constraints on cash in the portfolio'
                        >
                          <InfoOutlinedIcon fontSize='small' />
                        </Tooltip>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Table size='small' sx={{ marginTop: '-20px' }}>
                          <TableHead>
                            <TableRow>
                              {cashConstraints?.map((header) => (
                                <TableCell sx={{ fontWeight: 600, color: '#34475A' }} key={header}>{header}</TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {(dataCashConstraint || [])?.map((row, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell sx={{ color: '#34475A' }}>
                                    {row?.propertyDesc === 'Minimum Cash Level' || row?.propertyDesc === 'Maximum Cash Level'
                                      ? (
                                        <div style={{ display: 'flex', alignItems: 'center', color: '#34475A' }}>
                                          {row.propertyDesc}
                                          <Box sx={{ visibility: row.error ? 'visible' : 'hidden' }}>
                                            <CustomTooltipIcon
                                              text={
                                                  row.propertyDesc === 'Minimum Cash Level'
                                                    ? (minCashError)
                                                    : row.propertyDesc === 'Maximum Cash Level'
                                                      ? (maxCashError)
                                                      : ''
                                                } action='error'
                                            />
                                          </Box>
                                        </div>
                                        )
                                      : (
                                        <>{row?.propertyDesc || ''}</>
                                        )}
                                  </TableCell>
                                  <TableCell>
                                    {row?.uom === '$'
                                      ? <InputAdornmentTextField
                                          value={row?.value}
                                          label='Value'
                                          sx={{ fontWeight: 600, width: '220px' }}
                                          size='small'
                                          onKeyDown={(e) => isNumberOnly(e)}
                                          onChange={(event) => handleChangeCashConstraint(event, index)}
                                          onBlur={() => {
                                            validateMinMaxCashLevel(dataCashConstraint, true)
                                          }}
                                          adornment={<AttachMoneyOutlinedIcon />}
                                        />
                                      : <TextField
                                          label='Value'
                                          size='small'
                                          sx={{ fontWeight: 600, width: '220px' }}
                                          value={row?.value || ''}
                                          onKeyDown={(event) => isNumberOnly(event)}
                                          onChange={(event) => handleChangeCashConstraint(event, index)}
                                          onBlur={() => {
                                            validateMinMaxCashLevel(dataCashConstraint, true)
                                          }}
                                          error={row?.uom === '%' ? row.error : false}
                                          InputProps={{
                                            inputProps: {
                                              min: minCashValue,
                                              max: maxCashValue
                                            },
                                            endAdornment: (row?.uom === '%'
                                              ? (
                                                <InputAdornment
                                                  position='end'
                                                >
                                                  <PercentOutlinedIcon sx={{
                                                    width: 18,
                                                    height: 18
                                                  }}
                                                  />
                                                </InputAdornment>)
                                              : ''
                                            )
                                          }}
                                        />}

                                  </TableCell>
                                </TableRow>
                              )
                            })}
                          </TableBody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                  <Box px={0} py={1}>
                    <Accordion
                      className='accordion-layout'
                      expanded={expanded === 'panelT1'}
                      onChange={handleChange('panelT1')}
                      sx={{ border: dataTaxConstraint[0]?.value || dataTaxConstraint[1]?.value || dataTaxConstraint[2]?.value ? '1px solid #175CC2' : '' }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel4bh-content'
                        id='panel4bh-header'
                        sx={{ height: '52px' }}
                      >
                        <Typography sx={{ width: '16%', flexShrink: 0 }}>
                          Tax Constraints
                        </Typography>
                        <Tooltip
                          disableFocusListener
                          disableTouchListener
                          placement='right'
                          title='Enforce constraints on portfolio tax management'
                        >
                          <InfoOutlinedIcon fontSize='small' />
                        </Tooltip>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Table size='small' sx={{ marginTop: '-20px' }}>
                          <TableHead>
                            <TableRow>
                              {taxConstraints?.map((header) => (
                                <TableCell key={header} sx={{ fontWeight: 600, color: '#34475A' }}>{header}</TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {dataTaxConstraint?.map((row, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell sx={{ fontWeight: 600, color: '#34475A' }}>{row?.name}</TableCell>
                                  <TableCell sx={{ fontWeight: 600, color: '#34475A' }}>
                                    {row?.name === 'Tax Sensitivity'
                                      ? // Render a Select component for Tax Sensitivity
                                        <Select
                                          labelId='tax-sen-label'
                                          size='small'
                                          sx={{ width: '220px' }} // customizing width to match TextField
                                          value={row?.value}
                                          onChange={(event) => handleChangeTaxConstraint(event, index)}
                                        >
                                          <MenuItem value=''>
                                            <em>None</em>
                                          </MenuItem>
                                          {row?.options?.map((option, i) => (
                                            <MenuItem key={i} value={option}>{option}</MenuItem>
                                          ))}
                                        </Select>
                                      : <InputAdornmentTextField
                                          value={row?.value}
                                          label='Value'
                                          size='small'
                                          sx={{ fontWeight: 600, width: '220px', color: '#34475A' }}
                                          onKeyDown={(e) => isNumberOnly(e)}
                                          onChange={(event) => handleChangeTaxConstraint(event, index)}
                                          adornment={<AttachMoneyOutlinedIcon />}
                                        />}
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                          </TableBody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                  <Box px={0} py={1}>
                    <Accordion
                      className='accordion-layout'
                      expanded={expanded === 'panelGE1'}
                      onChange={handleChange('panelGE1')}
                      disabled
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel4bh-content'
                        id='panel4bh-header'
                        sx={{ height: '52px' }}
                      >
                        <Typography sx={{ width: '16%', flexShrink: 0 }}>
                          Geography
                        </Typography>
                        <InfoOutlinedIcon fontSize='small' />
                      </AccordionSummary>
                    </Accordion>
                  </Box>
                  <Box px={0} py={1}>
                    <Accordion
                      className='accordion-layout'
                      expanded={expanded === 'panelOC1'}
                      onChange={handleChange('panelOC1')}
                      sx={{
                        border: appliedPersonalizationData?.error?.otherConstraint ? '1px solid red' : dataOtherConstraint?.some(item => !!item.value) ? '1px solid #175CC2' : ''
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel4bh-content'
                        id='panel4bh-header'
                        sx={{ height: '52px' }}
                      >
                        <Typography sx={{ width: '16%', flexShrink: 0 }}>
                          Other Constraints
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack>
                          <Table size='small' sx={{ marginTop: '-20px' }}>
                            <TableHead>
                              <TableRow>
                                {otherConstraints?.map((header) => (
                                  <TableCell key={header} sx={{ fontWeight: 600, color: '#34475A' }}>{header}</TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {(dataOtherConstraint || [])
                                ?.map((row, index) => {
                                  return (
                                    <TableRow key={index}>
                                      <TableCell sx={{ fontWeight: 600, color: '#34475A', width: '50%', ...{ ...index === dataOtherConstraint.length - 1 ? { borderBottom: 'none' } : {} } }}>
                                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                                          {row?.propertyDesc}
                                          <CustomTooltipIcon
                                            text={
                                                (() => {
                                                  switch (row.propertyCode) {
                                                    case 'MIN_BUY_SIZE':
                                                      return 'Value must be greater than 0'
                                                    case 'MIN_BUY_WEIGHT':
                                                      return 'Value must be between 0 to 100'
                                                    case 'MAX_BETA':
                                                      return 'Value must be between -2 to 2'
                                                    case 'MIN_BETA':
                                                      return 'Value must be between -2 to 2'
                                                    default:
                                                      return row.propertyLongText
                                                  }
                                                })()
                                              } action='info'
                                          />
                                        </Box>
                                      </TableCell>
                                      <TableCell sx={{ width: '50%', ...{ ...index === dataOtherConstraint.length - 1 ? { borderBottom: 'none' } : {} } }}>
                                        {row?.uom === '$'
                                          ? <InputAdornmentTextField
                                              value={row?.value}
                                              label='Value'
                                              size='small'
                                              sx={{ fontWeight: 600, width: '220px', color: '#34475A' }}
                                              onKeyDown={(e) => isNumberOnly(e)}
                                              onChange={(event) => handleChangeOtherConstraint(event, index)}
                                              error={row?.error && row?.value}
                                              adornment={<AttachMoneyOutlinedIcon />}
                                            />
                                          : <TextField
                                              label='Value'
                                              size='small'
                                              value={row?.value}
                                              sx={{ fontWeight: 600, width: '220px', color: '#34475A' }}
                                              onKeyDown={(event) => isNumberOnly(event)}
                                              onChange={(event) => handleChangeOtherConstraint(event, index)}
                                              error={row?.error && row?.value}
                                              InputProps={{
                                                inputProps: {
                                                  min: minCashValue,
                                                  max: maxCashValue
                                                },
                                                endAdornment: (row?.uom === '%'
                                                  ? (
                                                    <InputAdornment
                                                      position='end'
                                                    >
                                                      <PercentOutlinedIcon sx={{
                                                        width: 18,
                                                        height: 18
                                                      }}
                                                      />
                                                    </InputAdornment>)
                                                  : row.propertyCode === 'MIN_TRD_SIZE'
                                                    ? <InputAdornment
                                                        position='end'
                                                      >
                                                      <MinTradeIcon title='bps' />
                                                      </InputAdornment>
                                                    : ''
                                                )
                                              }}
                                            />}
                                      </TableCell>
                                    </TableRow>
                                  )
                                })}
                            </TableBody>
                          </Table>
                          <Divider sx={{ bgcolor: 'rgba(116, 120, 141, 0.3)', margin: '10px 0' }} />
                          <Box mY={0}>Objective Function Priorities</Box>
                          <Table size='small'>
                            <TableHead>
                              <TableRow>
                                {otherConstraints?.map((header) => (
                                  <TableCell key={header} sx={{ fontWeight: 600, color: '#34475A' }}>{header}</TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {(objectiveFunctionData || [])?.map((row, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell sx={{ fontWeight: 600, color: '#34475A', width: '50%' }}>
                                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        {row?.propertyDesc}
                                        <CustomTooltipIcon
                                          text={row.propertyLongText} action='info'
                                        />
                                      </Box>
                                    </TableCell>
                                    <TableCell sx={{ width: '50%' }}>
                                      {row?.uom === '$'
                                        ? <InputAdornmentTextField
                                            value={row?.value}
                                            label='Value'
                                            size='small'
                                            sx={{ fontWeight: 600, width: '220px', color: '#34475A' }}
                                            onKeyDown={(e) => isNumberOnly(e)}
                                            onChange={(event) => handleObjectiveConstraint(event, index)}
                                            error={row?.error && row?.value}
                                            adornment={<AttachMoneyOutlinedIcon />}
                                          />
                                        : <TextField
                                            label='Value'
                                            size='small'
                                            value={row?.value}
                                            sx={{ fontWeight: 600, width: '220px', color: '#34475A' }}
                                            onKeyDown={(event) => isNumberOnly(event)}
                                            onChange={(event) => handleObjectiveConstraint(event, index)}
                                            error={row?.error && row?.value}
                                            InputProps={{
                                              inputProps: {
                                                min: minCashValue,
                                                max: maxCashValue
                                              },
                                              endAdornment: (row?.uom === '%'
                                                ? (
                                                  <InputAdornment
                                                    position='end'
                                                  >
                                                    <PercentOutlinedIcon sx={{
                                                      width: 18,
                                                      height: 18
                                                    }}
                                                    />
                                                  </InputAdornment>)
                                                : ''
                                              )
                                            }}
                                          />}
                                    </TableCell>
                                  </TableRow>
                                )
                              })}
                            </TableBody>
                          </Table>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Box sx={{ position: 'absolute', bottom: '8px', width: '100%', backgroundColor: 'white', height: '40px' }}>
              <Button
                variant='contained'
                disabled={hasErrors}
                sx={{ borderRadius: '10px', position: 'absolute', right: '32px' }}
                onClick={handleApply}
              >
                Apply
              </Button>
            </Box>
            {isLoading ? <Loader /> : ''}
          </Grid>
        </Box>
      </Box>
    </Modal>
  )
}

export default PersonalizationModal
