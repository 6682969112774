import React, { Fragment, memo } from 'react'
import { Handle, Position } from '@xyflow/react'
import { Box, Typography } from '@mui/material'

export default memo(({ data, isConnectable }) => {
  return (
    <>
      <Handle
        type='target'
        position={Position.Top}
        style={{ top: '-4px', background: '#555' }}
        isConnectable={isConnectable}
      />

      {/* <Box style={{ border: '2px dashed orange', padding: '10px 15px 20px' }}> */}
      {data?.nodeData?.strategies?.map((strategy, index) => (
        <Box key={index} className='react-flow__node-default inner-box' mt='30px' position='relative'>
          <Typography fontSize='14px'>{strategy?.name}</Typography>
          {/* <Typography fontSize='14px'>{data?.nodeData?.category}</Typography> */}
          <Typography fontSize='14px' className='row-div'>Allocation: <span>{strategy?.allocation}</span></Typography>
          {/* <Box sx={{ position: 'absolute', top: '-25px', left: 0, right: 0, display: 'flex', justifyContent: 'space-between' }}> */}
          {/* {strategy?.allocation} */}
          {/* {
                        data?.nodeData?.parentNodes && index === 0
                          ? <Box display='flex' alignItems='center'>{data?.nodeData?.parentNodes.map((obj) => (
                            <Box key={obj.id}>
                              <Box sx={{ bgcolor: obj.color, height: '12px', width: '12px', borderRadius: '50%', mr: '5px' }} />
                            </Box>
                          ))}
                          </Box>
                          : ''
                      } */}
          {/* </Box> */}
          {/* <Typography fontSize='14px'>{strategy?.name}</Typography> */}
        </Box>
      ))}

      {/* </Box> */}
      <Handle
        type='source'
        position={Position.Bottom}
        style={{ bottom: '-4px', top: 'auto', background: '#555' }}
        isConnectable={isConnectable}
      />
    </>
  )
})
