import React, { useEffect, useState } from 'react'
import { Box, Checkbox, TextField } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { validateExpression } from '../../utils/ZscoreValidator'
import CustomTooltipIcon from '../Personalization/components/CustomTooltipIcon'

const Industry = ({ data, setData, setError, industryGridState, setIndustryGridState, apiRef }) => {
  const [selectedRows, setSelectedRows] = useState([])
  const [hasError, setHasError] = useState({})

  // Function to save the current state of the DataGridPro
  const handleIndustryTableChange = () => {
    if (apiRef?.current?.exportState) {
      const state = apiRef?.current?.exportState()
      setIndustryGridState(state)
    }
  }

  // Effect to restore the grid state whenever gridState changes
  useEffect(() => {
    if (apiRef?.current?.restoreState && industryGridState) {
      const resetGridStateObj = {
        columns: industryGridState?.columns || {},
        pinnedColumns: industryGridState?.pinnedColumns || {},
        sorting: industryGridState?.sorting || []
      }
      apiRef?.current?.restoreState(resetGridStateObj)
    }
  }, [industryGridState, data, selectedRows, hasError])

  const columns = [
    { field: 'sectorName', headerName: 'Sector', flex: 1, editable: false },
    { field: 'industry', headerName: 'Industry', flex: 2, editable: false },
    { field: 'count', headerName: 'No. of Securities', flex: 1, editable: false, type: 'number', align: 'right' },
    { field: 'sumWeight', headerName: 'Weight(%)', flex: 1, editable: false, type: 'number', align: 'right' },
    {
      field: 'exclude',
      headerName: 'Exclude',
      flex: 1,
      renderCell: (params) => (
        <Checkbox
          checked={params.value}
          onClick={(event) => event.stopPropagation()}
          disabled={params.row.sectorName === 'All Sectors' || params.row.disabled}
          onChange={() => handleConfirmChange(params.row.industry)}
        />
      )
    },
    {
      field: 'min',
      headerName: 'Min',
      type: 'text',
      flex: 1,
      description: 'Min value',
      sortable: false,
      renderHeader: (params) => (
        <>
          Min
          <CustomTooltipIcon text='You can indicate a relative value by using the format B(+/-)(value). For example,  B-3 or B+3.' action='help' />
        </>
      ),
      renderCell: (params) => {
        const rowId = params?.id
        const minIsInvalid = hasError[rowId]?.min || false

        return (
          <TextField
            label='Z-Score'
            size='small'
            value={params.value}
            disabled={params.row.disabled || params.row.exclude}
            onChange={(event) => handleCellEditCommit('min', event.target.value, params.row.sectorName, params.row.industry)}
            error={!!((minIsInvalid && params.value))}
          />
        )
      }
    },
    {
      field: 'max',
      headerName: 'Max',
      type: 'text',
      flex: 1,
      description: 'Max value',
      sortable: false,
      renderHeader: (params) => (
        <>
          Max
          <CustomTooltipIcon text='You can indicate a relative value by using the format B(+/-)(value). For example,  B-3 or B+3.' action='help' />
        </>
      ),
      renderCell: (params) => {
        const rowId = params?.id || ''
        const maxIsInvalid = hasError[rowId]?.max || false

        return (
          <TextField
            label='Z-Score'
            size='small'
            value={params.value}
            disabled={params.row.disabled || params.row.exclude}
            onChange={(event) => handleCellEditCommit('max', event.target.value, params.row.sectorName, params.row.industry)}
            error={!!((maxIsInvalid && params.value))}
          />
        )
      }
    }
  ]

  useEffect(() => {
    for (const key in hasError) {
      if (hasError[key].min || hasError[key].max) {
        setError(prevError => ({ ...prevError, industry: true }))
        break
      } else {
        setError(prevError => ({ ...prevError, industry: false }))
      }
    }
  }, [hasError])

  function getRowId (row) {
    return `${row?.industry}-${row?.sectorName}`
  }

  // handle onChange for industry Data
  function handleCellEditCommit (field, value, sectorName, industry) {
    if (selectedRows?.filter((row) => row?.industry === industry)?.length > 0) {
      const updatedData = data?.map((row) => {
        if (selectedRows?.filter((item) => item?.industry === row?.industry)?.length > 0) {
          return { ...row, [field]: value }
        } else {
          return { ...row }
        }
      })
      setData(updatedData)
    } else {
      setData((prevData) => {
        const index = prevData.findIndex((row) => row?.sectorName === sectorName && row?.industry === industry)
        const updatedRow = { ...prevData[index], [field]: value }
        const updatedData = [...prevData]
        updatedData[index] = updatedRow
        return updatedData
      })
    }
    // validations for industry Data, values to be between B+3 to B-3 or -3 to +3
    const isValidExpression = validateExpression(value)
    setHasError((prevErrors) => ({
      ...prevErrors,
      [`${industry}-${sectorName}`]: {
        ...prevErrors[`${industry}-${sectorName}`],
        [field]: isValidExpression
      }
    }))

    if (!value.trim()) {
      setHasError((prevErrors) => ({
        ...prevErrors,
        [`${industry}-${sectorName}`]: {
          ...prevErrors[`${industry}-${sectorName}`],
          [field]: false
        }
      }))
    }
  }

  function handleConfirmChange (clickedRow) {
    let selectedRow = { industry: clickedRow }
    const updatedData = data?.map((x) => {
      if (x.industry === clickedRow) {
        selectedRow = { ...x, exclude: !x.exclude }
        return {
          ...x,
          exclude: !x.exclude,
          min: '',
          max: ''
        }
      }
      return x
    })
    setData(updatedData)
    setHasError((prevErrors) => ({
      ...prevErrors,
      [`${selectedRow.industry}-${selectedRow.sectorName}`]: {
        ...prevErrors[`${selectedRow.industry}-${selectedRow.sectorName}`],
        min: false,
        max: false
      }
    }))
  }

  const handleRowSelection = (industry) => {
    const selectedIDs = new Set(industry)
    const selectedRows = data.filter((row) =>
      selectedIDs.has(row.industry)
    )
    setSelectedRows(selectedRows)
  }

  return (
    <Box
      sx={{
        '.MuiDataGrid-footerContainer': {
          border: 'none'
        },
        '.MuiDataGrid-root': {
          border: 'none'
        },
        '.MuiDataGrid-columnHeaderTitle': {
          fontFamily: 'Open Sans',
          color: '#74788D',
          fontWeight: 600
        },
        '.MuiDataGrid-cellContent': {
          fontFamily: 'Open Sans',
          color: '#34475A',
          fontWeight: 600
        }
      }}
    >
      <div style={{ width: '100%' }}>
        <DataGridPro
          autoHeight
          apiRef={apiRef}
          rows={data}
          columns={columns}
          onRowSelectionModelChange={handleRowSelection}
          getRowId={getRowId}
          pagination
          pageSizeOptions={[10]}
          onColumnWidthChange={() => handleIndustryTableChange()}
          onColumnOrderChange={() => handleIndustryTableChange()}
          onSortModelChange={() => handleIndustryTableChange()}
          initialState={{
            ...industryGridState?.initialState,
            pagination: { paginationModel: { pageSize: 10 } }
          }}
          disableRowSelectionOnClick
          sx={{
            '& .MuiDataGrid-columnHeaderTitleContainerContent': {
              fontWeight: 600,
              fontFamily: 'Open Sans',
              color: '#74788d'
            }
          }}
        />
      </div>
    </Box>
  )
}

export default Industry
