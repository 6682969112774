import React, { useEffect, useState } from 'react'
import { Autocomplete, Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, Popover, TextField, Typography } from '@mui/material'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import { randomId } from '@mui/x-data-grid-generator'
import { Close, FilterAltOutlined, FilterAlt } from '@mui/icons-material'
import { formatCurrencyWithSymbol } from '../../../../utils/FormateCurrenyInMilion'
import { tradeListData as tradeList } from '../../IPSAggregate/data'

const getTradeQuantityColor = (category) => {
  if (category === 'SELL') {
    return '#FF6A6A'
  }
  else if (category === 'BUY') {
    return '#33A68D'
  }
  else return 'inherit'
}

const tradeListColumns = [
  {
    headerName: 'Security Name',
    field: 'securityName',
    flex: 1
  },
  {
    headerName: 'Local Symbol',
    field: 'localSymbol',
    flex: 1
  },
  {
    headerName: 'Category',
    field: 'category',
    flex: 1,
    renderCell: (params) => <Box sx={{ color: getTradeQuantityColor(params?.row?.category) }}>{params.value}</Box>
  },
  {
    headerName: 'Source Account',
    field: 'sourceAccountName',
    flex: 1
  },
  {
    headerName: 'Destination Account',
    field: 'destinationAccountName',
    flex: 1
  },
  {
    headerName: 'Trade Quantity',
    field: 'tradeQuantity',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    renderCell: (params) => (
      <Box>
        {params?.row?.tradeQuantity !== null && params?.row?.tradeQuantity !== undefined
          ? params?.row?.tradeQuantity
          : ''}
      </Box>
    )
  },
  {
    headerName: 'Trade Value',
    field: 'tradeValue',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    renderCell: (params) => (
      <Box>
        {params?.row?.tradeValue !== null && params?.row?.tradeValue !== undefined
          ? formatCurrencyWithSymbol(params?.row?.tradeValue, 2, '$')
          : ''}
      </Box>
    )
  }
]

const tradeListDataCopy = tradeList.map(row => ({ ...row, id: randomId() }))
const TradeList = () => {
  const [tradeListData, setTradeListData] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedSrcAcc, setSelectedSrcAcc] = useState(null)
  const [selectedDestAcc, setSelectedDestAcc] = useState(null)
  const [srcAccNames, setSrcAccNames] = useState([])
  const [destAccNames, setDestAccNames] = useState([])
  const [selectedTradeCategory, setSelectedTradeCategory] = useState({ buy: true, sell: true, transfer: true })

  useEffect(() => {
    setTradeListData(tradeListDataCopy)
    setSrcAccNames([...new Set(tradeList.map(obj => obj?.sourceAccountName).filter(names => names !== ''))])
    setDestAccNames([...new Set(tradeList.map(obj => obj?.destinationAccountName).filter(names => names !== ''))])
  }, [])

  const applyFilter = () => {
    setTradeListData(tradeListDataCopy.filter(obj => (
      (selectedSrcAcc ? selectedSrcAcc === obj?.sourceAccountName : true)
      &&
      (selectedDestAcc ? selectedDestAcc === obj?.destinationAccountName : true))))
  }

  const resetFilter = () => {
    setSelectedSrcAcc(null)
    setSelectedDestAcc(null)
    setTradeListData(tradeListDataCopy)
  }


  const handleCategoryCheckboxChange = (event) => {
    const updatedSelectedTradeCategory = {
      ...selectedTradeCategory,
      [event.target.name]: event.target.checked
    }
    setSelectedTradeCategory(updatedSelectedTradeCategory)
    setTradeListData(tradeListDataCopy?.filter(row => updatedSelectedTradeCategory[row?.category?.toLowerCase()]))
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', height: '36px' }}>
          <Typography sx={{ fontSize: '16px', color: '#74788D', fontWeight: 600 }}>Trade List</Typography>
          <Box sx={{
            display: 'inline-flex',
            alignItems: 'center',
            '& .MuiCheckbox-root': {
              padding: '4px'
            },
            '& .MuiFormControlLabel-label': {
              fontSize: '14px'
            }
          }}>
            <FormControl>
              <FormLabel id='trade-controlled-checkbox-buttons-group' />
              <FormGroup row name='trade-checkbox-group' >
                <FormControlLabel
                  value='SELL' control={<Checkbox
                    size='small'
                    checked={selectedTradeCategory.sell}
                    onChange={handleCategoryCheckboxChange}
                  />} label='Sell' name='sell'
                />
                <FormControlLabel
                  value='BUY' control={<Checkbox
                    size='small'
                    checked={selectedTradeCategory.buy}
                    onChange={handleCategoryCheckboxChange}
                  />} label='Buy' name='buy'
                />
                <FormControlLabel
                  value='TRANSFER'
                  control={<Checkbox
                    size='small'
                    checked={selectedTradeCategory.transfer}
                    onChange={handleCategoryCheckboxChange}
                  />} label='Transfer' name='transfer'
                />
              </FormGroup>
            </FormControl>
            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
              {
                selectedDestAcc || selectedSrcAcc
                  ? <FilterAlt fontSize='small' />
                  : <FilterAltOutlined fontSize='small' />
              }
            </IconButton>
          </Box>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Box sx={{ width: '420px', p: '8px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{ fontSize: '14px', color: '#34475A' }}>Add Filter</Typography>
                <IconButton onClick={() => setAnchorEl(null)}>
                  <Close sx={{ height: '18px', width: '18px' }} />
                </IconButton>
              </Box>
              <Box sx={{ display: 'flex', gap: '10px', mt: '5px' }}>
                <Autocomplete
                  value={selectedSrcAcc}
                  options={srcAccNames}
                  onChange={(e, value) => setSelectedSrcAcc(value)}
                  size='small'
                  fullWidth
                  filterSelectedOptions
                  ListboxProps={{ sx: { fontSize: 14, py: 1 } }}
                  renderInput={(params) => <TextField {...params} label='Source Account' variant='outlined' />}
                />
                <Autocomplete
                  value={selectedDestAcc}
                  options={destAccNames}
                  onChange={(e, value) => setSelectedDestAcc(value)}
                  size='small'
                  fullWidth
                  filterSelectedOptions
                  ListboxProps={{ sx: { fontSize: 14, py: 1 } }}
                  renderInput={(params) => <TextField {...params} label='Destination Account' variant='outlined' />}
                />
              </Box>
              <Divider sx={{ mt: '10px' }} />
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button onClick={applyFilter} sx={{ fontSize: '14px', textTransform: 'capitalize' }}>
                  Apply Filters
                </Button>
                <Button onClick={resetFilter} sx={{ fontSize: '14px', textTransform: 'capitalize', color: '#74788D' }}>
                  Clear Filters
                </Button>
              </Box>
            </Box>
          </Popover>
        </Box>
        <Box sx={{ position: 'relative', mt: '10px' }}>
          <DataGridPro
            autoHeight
            density='compact'
            rows={tradeListData}
            columns={tradeListColumns}
            getRowId={(row) => row?.id}
            pagination
            pageSizeOptions={[10]}
            initialState={{
              ...tradeListData?.initialState,
              pagination: { paginationModel: { pageSize: 10 } }
            }}
            sx={(theme) => ({
              '& .MuiDataGrid-cell:focus': {
                outline: 'none'
              },
              [`.${gridClasses.main}`]: {
                height: 'min(100vh - 16px - 45px - 16px - 53px, 600px - 16px - 45px - 16px - 40px - 53px)',
              },
              [`.${gridClasses.columnHeaders}`]: {
                position: 'sticky',
                backgroundColor: theme.palette.background.paper,
                top: 0,
                zIndex: 1,
              },
              [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                color: '#74788d',
                fontWeight: 600
              },
              [`.${gridClasses.virtualScroller}`]: {
                overflowY: 'auto !important',
                scrollbarGutter: 'stable',
                scrollbarWidth: 'none'
              }
            })}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

export default TradeList