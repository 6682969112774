import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { API } from 'aws-amplify'
import AddIcon from '@mui/icons-material/Add'
import { Autocomplete, Box, Button, FormControl, Grid, InputLabel, MenuItem, Modal, Select, TextField, Tooltip, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useGridApiContext } from '@mui/x-data-grid'
import * as Sentry from '@sentry/react'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import utc from 'dayjs/plugin/utc'
import { useAuth } from '../../contexts/AuthContext'
import { useErrorToast } from '../../hooks/useErrorToast'
import Loader from '../Loader'
import DataTable from './components/DataTable'
import { moduleConfig } from '../../contexts/data'
import { ACCESS_LEVEL } from '../../contstants/constants'
import { checkInstrumentSearchQuery } from '../../utils/searchQueryUtils'

dayjs.extend(isSameOrAfter) // Extend dayjs with the isSameOrAfter plugin
dayjs.extend(utc)

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  width: '500px',
  borderRadius: '4px'
}

export default function SecurityRestrictions (props) {
  const { showError } = useErrorToast()
  const { user } = useAuth()
  const [restrictedCodes, setRestrictedCodes] = useState([])
  const params = useParams()
  const { checkAccess } = useAuth()
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const [startDate, setStartDate] = useState(dayjs.utc().startOf('day')) // Use UTC for initial value
  const [endDate, setEndDate] = useState(dayjs.utc().add(1, 'day').startOf('day')) // Use UTC for initial value
  const [selectKey, setSelectKey] = useState('')
  const [selectInstrID, setSelectInstrId] = useState('')
  const [selectedValues, setSelectedValues] = useState([])
  const [selectValue, setSelectValue] = useState('')
  const [autocompleteValue, setAutocompleteValue] = useState('')
  const [customErrors, setCustomErrors] = useState({})
  const [sourceOptions, setSourceOptions] = useState([])
  const [sourceName, setSourceName] = useState([])
  const [customErrors2, setCustomErrors2] = useState({})
  const [isLoadingSource, setIsLoadingSource] = useState(false)
  const [errorName, setErrorName] = useState(false)
  const [errorCode, setErrorCode] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [rows, setRows] = React.useState(props?.securityData ? props?.securityData : [])
  const [errorEndDate, setErrorEndDate] = useState(null)
  const handleClose = () => {
    setOpen(false)
    setErrorName(false)
    setErrorCode(false)
    setAutocompleteValue('')
    setSelectValue('')
    setSelectInstrId('')
    setSelectKey('')
    setSelectedValues([])
    setStartDate(dayjs.utc().startOf('day'))
    setEndDate(dayjs.utc().add(1, 'day').startOf('day'))
    setIsLoading(false)
  }
  const dataSecure = localStorage.getItem('object')
  const getStoreData = dataSecure ? JSON.parse(dataSecure) : []
  const filterSecureData = getStoreData?.filter((item) => item?.accountId === params?.accountId)

  const addSecurityRestrictions = async () => {
    const id = randomId()
    setIsLoading(true)
    try {
      const response = await API.post(
        'baseUriAccountOptimization',
        `account-customization/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/${params.accountId}/instr-restriction`,
        {
          body: {
            startDate: startDate.toISOString().slice(0, 10),
            instrId: selectInstrID,
            restrictionId: selectKey,
            endDate: endDate.toISOString().slice(0, 10)
          }
        }
      )
      if (response.success && response?.data && response?.data?.length) {
        const updatedSecurityList = [{ id, ...response?.data[0] }, ...rows]
        setRows(updatedSecurityList)
        props?.onData && props?.onData(updatedSecurityList)
        handleClose()
      }
    } catch (error) {
      if (Array.isArray(error.response?.data?.errorInfo?.userMessage)) {
        error.response?.data?.errorInfo?.userMessage?.forEach((element) => {
          showError(element.message)
        })
      } else {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
      }
      setIsLoading(false)
    }
  }

  const onRestrictionCodeChange = (event, key) => {
    if (event.target.value !== '') {
      const temp = customErrors
      delete temp.restrictionCode
      setCustomErrors(temp)
      setSelectValue(event.target.value)
      setSelectKey(key)
      setSelectedValues([...selectedValues, selectValue])
      setErrorCode(false)
    }
  }
  const onSourceNameChange = (query, newValue) => {
    setIsLoadingSource(true)
    setAutocompleteValue(newValue)
    setSelectInstrId(newValue?.instrId || '')
    if (newValue) {
      setSelectedValues([...selectedValues, newValue])
    }
    if (errorName && newValue?.name) {
      setErrorName(false)
    }
  }

  const getRestrictedCodes = async () => {
    API.get(
      'baseUriTransactionalMaster',
      `transactional-master/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/restriction-codes`,
      { queryStringParameters: {} }
    )
      .then((response) => {
        if (response.data) {
          setRestrictedCodes([...response.data])
        }
      })
      .catch((error) => {
        showError(error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const onInputChange = (event) => {
    const query = (event?.target?.value || '').toString().trim()
    if (query === '') {
      setSourceName([])
    }
    if (sourceName?.length === 0 && query === '') {
      setCustomErrors2({ sourceName: { message: 'Source Name required' } })
    } else {
      if (checkInstrumentSearchQuery(query)) {
        setIsLoadingSource(true)
        API.get('baseUriTransactionalMaster', `transactional-master/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/instruments`, {
          queryStringParameters: { search: query }
        })
          .then((response) => {
            if (response?.data) {
              setSourceOptions([...response?.data])
              const tempErrors = customErrors2
              delete tempErrors.sourceName
              setCustomErrors2(tempErrors)
            }
          })
          .catch((error) => {
            showError(error?.response?.data?.errorInfo?.userMessage || error.message)
            Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
          })
          .finally(() => {
            setIsLoadingSource(false)
          })
      }
    }
  }

  const submitSecurityHandler = (event) => {
    if (autocompleteValue?.name && selectValue && (errorEndDate === null)) {
      event.preventDefault()
      setErrorName(false)
      setErrorCode(false)
      addSecurityRestrictions()
    } else {
      if (!autocompleteValue?.name) {
        setErrorName(true)
      }
      if (!selectValue) {
        setErrorCode(true)
      }
    }
  }

  const handleConfirmEdit = (row) => {
    setIsLoading(true)
    if (row.endDate) {
      const payload = {
        startDate: row.startDate,
        endDate: row.endDate
      }
      API
        .patch('baseUriAccountOptimization', `account-customization/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/${row.accountId}/instruments/${row.instrId}/restrictions/${row.restrictionId}`, { body: { ...payload } })
        .then(response => {
          if (response.data && response.success) {
            const updatedList = rows.map(item => {
              if (item.accountId === row.accountId && item.instrId === row.instrId && item.restrictionId === row.restrictionId) {
                return { ...item, startDate: row.startDate, endDate: row.endDate }
              }
              return item
            })
            setRows(updatedList)
            props?.onData && props?.onData(updatedList)
            setIsLoading(false)
          }
        }).catch(error => {
          showError(error.response?.data?.errorInfo?.userMessage || error.message)
          Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
          setIsLoading(false)
        })
    } else { setIsLoading(false) }
  }

  const columns = [
    {
      field: 'name',
      headerName: 'Security Name',
      flex: 1,
      editable: false
    },
    {
      field: 'restrictionDesc',
      headerName: 'Restriction Type',
      flex: 1,
      editable: false
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      flex: 1,
      editable: false,
      valueFormatter: (params) =>
        params.value ? dayjs.utc(params.value).format('ddd MMM DD YYYY') : '--------'
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      flex: 1,
      editable: true,
      valueFormatter: (params) => params.value ? dayjs.utc(params.value).format('ddd MMM DD YYYY') : '--------',
      renderEditCell: (params) => <CustomEditDate {...params} minDate={dayjs.utc().format('YYYY-MM-DD')} />,
      preProcessEditCellProps: (params) => {
        const currentDate = dayjs().startOf('day').unix() * 1000
        const startDate = dayjs(params.row.startDate).startOf('day').unix() * 1000
        const endDate = dayjs(params.props.value).startOf('day').unix() * 1000
        const isEndDateValid = (endDate >= currentDate) && (endDate >= startDate)
        const hasError = !isEndDateValid
        const style = hasError
          ? {
              border: '2px solid red',
              height: '50px'
            }
          : {}
        return { ...params.props, error: hasError, style }
      }
    }
  ]

  useEffect(() => {
    if (user) {
      getRestrictedCodes()
    }
  }, [user])

  return (
    <>
      {isLoading ? <Loader /> : ''}
      <Box
        sx={{
          width: '100%',
          '& .actions': {
            color: 'text.secondary'
          },
          '& .textPrimary': {
            color: 'text.primary'
          },
          '.MuiDataGrid-footerContainer': {
            border: 'none'
          },
          '.MuiDataGrid-root': {
            border: 'none'
          }
        }}
      >
        {
          ((filterSecureData && filterSecureData[0]?.accountType === 'TRADING' &&
          checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS,
            { subModuleName: moduleConfig.PERSONALIZATION, component_name: moduleConfig.EDIT_TRADING_PERSONALIZATION })) ||
          ((filterSecureData && filterSecureData[0]?.accountType === 'TRANSITION' &&
           checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS,
             { subModuleName: moduleConfig.PERSONALIZATION, component_name: moduleConfig.EDIT_TRANSITION_PERSONALIZATION })))) && (
               <>
                 <Tooltip
                   disableFocusListener
                   disableTouchListener
                   placement='top'
                   title='Apply restrictions at a security level'
                 >
                   <Button
                     variant='outlined'
                     color='primary'
                     startIcon={<AddIcon />}
                     onClick={handleOpen}
                     sx={{
                       fontFamily: 'Open Sans'
                     }}
                   >
                     Add Security
                   </Button>
                 </Tooltip>
                 <Modal
                   open={open}
                   onClose={handleClose}
                   aria-labelledby='modal-modal-title'
                   aria-describedby='modal-modal-description'
                 >
                   <Box sx={style}>
                     <Typography id='modal-modal-title' variant='h6' sx={{ fontWeight: 400, color: '#34475A', textAlign: 'center' }}>
                       Add Security
                     </Typography>
                     <Box id='modal-modal-description' sx={{ mt: 2 }}>
                       <Box component='form'>
                         <Grid container spacing={2}>
                           <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                             <Typography sx={{ paddingRight: '95px', color: '#74788d!important', fontSize: '16px', fontWeight: 500 }}>Security *</Typography>
                             <Autocomplete
                               autoWidth
                               id='my-autocomplete'
                               options={sourceOptions}
                               loading={isLoadingSource}
                               getOptionLabel={(option) => option.name || ''}
                               onInputChange={onInputChange}
                               onChange={onSourceNameChange}
                               value={autocompleteValue}
                               sx={{
                                 width: '250px'
                               }}
                               renderInput={(params) => (
                                 <TextField
                                   {...params}
                                   label='Security Name'
                                   error={errorName}
                                   variant='outlined'
                                   InputProps={{ ...params.InputProps, placeholder: 'Security Name here' }}
                                   required
                                   sx={{
                                     width: '250px'
                                   }}
                                 />
                               )}
                             />
                             {customErrors.securityName && (
                               <Typography color='error' variant='caption'>
                                 {customErrors.securityName.message}
                               </Typography>
                             )}
                           </Grid>
                           <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                             <Typography sx={{ paddingRight: '38px', color: '#74788d!important', fontSize: '16px', fontWeight: 500 }}>Restriction Type *</Typography>
                             <FormControl variant='outlined' error={errorCode} size='small'>
                               <InputLabel
                                 id='demo-simple-select-label'
                                 sx={{
                                   '&.MuiInputLabel-shrink': {
                                     marginTop: 0 // Margin when label is in shrinked state
                                   },
                                   '&:not(.MuiInputLabel-shrink)': {
                                     marginTop: '7px' // Margin when label is not in shrinked state
                                   }
                                 }}
                               >Action *
                               </InputLabel>
                               <Select
                                 labelId='my-dropdown-label'
                                 label='Action'
                                 id='my-dropdown'
                                 value={selectValue}
                                 onChange={(e) => onRestrictionCodeChange(e, restrictedCodes.find(option => option.restrictionDesc === e.target.value).restrictionId)}
                                 className='actions-select'
                                 native={false}
                                 required
                                 sx={{ width: '250px', height: '56px' }}
                               >
                                 {restrictedCodes?.map((option, index) => {
                                   if (option.restrictionCode !== 'ONB' && option.restrictionCode !== 'ONS') {
                                     return (
                                       <MenuItem key={option.restrictionId} value={option.restrictionDesc}>
                                         {option.restrictionDesc}
                                       </MenuItem>
                                     )
                                   }
                                 })}
                               </Select>
                             </FormControl>
                             {customErrors.restrictionCode && (
                               <Typography color='error' variant='caption'>
                                 {customErrors.restrictionCode.message}
                               </Typography>
                             )}
                           </Grid>
                           <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                             <Typography sx={{ paddingRight: '84px', color: '#74788d!important', fontSize: '16px', fontWeight: 500 }}>Start date *</Typography>
                             <LocalizationProvider dateAdapter={AdapterDayjs}>
                               <DatePicker
                                 label='Start date *'
                                 value={startDate}
                                 required
                                 disablePast
                                 onChange={setStartDate}
                                 sx={{ width: '250px' }}
                               />
                             </LocalizationProvider>
                           </Grid>
                           <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                             <Typography sx={{ paddingRight: '92px', color: '#74788d!important', fontSize: '16px', fontWeight: 500 }}>End date *</Typography>
                             <LocalizationProvider dateAdapter={AdapterDayjs}>
                               <DatePicker
                                 label='End date *'
                                 value={endDate}
                                 sx={{ width: '250px' }}
                                 onChange={
                              setEndDate
                            }
                                 minDate={startDate.add(1, 'day')}
                                 onError={setErrorEndDate}
                                 required
                                 disablePast
                               />
                             </LocalizationProvider>
                           </Grid>
                           <Grid item xs={12}>
                             <Box sx={{ textAlign: 'center' }}>
                               <Button
                                 variant='contained'
                                 onClick={submitSecurityHandler}
                               >
                                 Submit
                               </Button>
                             </Box>
                           </Grid>
                         </Grid>
                       </Box>
                     </Box>
                   </Box>
                 </Modal>
               </>
          )
        }
        <DataTable data={rows} initialColumns={columns} showPagination editData={handleConfirmEdit} />
      </Box>
    </>
  )
}

const CustomEditDate = (props) => {
  const { id, field, value: valueProp, style, minDate } = props
  const apiRef = useGridApiContext()
  const [value, setValue] = React.useState(valueProp)

  const formatDate = (date) => {
    const parsedDate = date ? date?.split('T')[0] : null
    return parsedDate
  }

  const handleDateChange = (e) => {
    const newValue = e.target.value
    apiRef.current.setEditCellValue({ id, field, value: newValue, debounceMs: 200 })
    setValue(newValue)
  }

  useEffect(() => { setValue(valueProp) }, [valueProp])

  return (
    <input
      type='date'
      value={formatDate(value)}
      onChange={handleDateChange}
      min={minDate}
      style={{ height: '100%', width: '100%', background: '#1976d214', ...style, outline: 'none' }}
    />
  )
}
